/* eslint-disable @typescript-eslint/no-unused-vars */

import { extractValue } from 'src/general/utils/helpers';
import {
  getPlayerInfo,
  getPlayInfo,
  getValues,
} from 'src/modules/searchGolazosNft';

const formatPrice = (priceValue: number) => {
  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  }).format(priceValue);
};

export const mapGolazosNftToMyCollectionMoment = () => {
  return (nft): Record<string, any> => {
    const {
      edition,
      listing,
      id: editionId,
      serial_number: serialNumber,
    } = nft;
    if (!edition) {
      return;
    }

    const {
      set,
      total_minted: totalMinted,
      total_burned: totalBurned,
    } = edition;
    const rarityTier = edition.tier;
    const { metadata } = edition.play;
    const {
      match_highlighted_team: highlightedTeam,
      play_data_id: playDataId,
      play_type: playType,
      player_known_name: playerKnownName,
      player_first_name: playerFirstName,
      player_last_name: playerLastName,
      match_season: matchSeason,
      match_home_team: homeTeam,
      match_away_team: awayTeam,
      badges,
    } = metadata;
    const playerName =
      playerKnownName || `${playerFirstName} ${playerLastName} `;
    const playName = set?.name;
    const momentImage = `https://storage.googleapis.com/dl-laliga-assets-prod/editions/${playDataId}/play_${playDataId}__capture_Hero_Black_2880_2880_default.png`;

    return {
      awayTeam,
      badges,
      editionId,
      highlightedTeam,
      homeTeam,
      listingPrice: listing?.price,
      matchSeason,
      momentImage,
      playDataId,
      playName,
      playType,
      playerName,
      rarityTier,
      serialNumber,
      totalBurned,
      totalMinted,
    };
  };
};

export const mapGolazoNfts2Marketplace = () => {
  return (nft): Record<string, any> => {
    const { edition, listing, onBuyNow } = nft;
    if (!edition) {
      return;
    }
    const lowestAsk = listing?.price?.min;
    const serialNumber = extractValue(nft?.serial_number);

    const { play, set } = edition;
    const {
      id: editionId,
      tier: rarityTier,
      total_burned,
      total_minted,
    } = getValues(edition, ['id', 'tier', 'total_burned', 'total_minted']);
    const {
      name: headliner,
      match_season,
      match_highlighted_team,
    } = getPlayerInfo(edition);
    const { metadata } = play;
    const playId = extractValue(play?.id);
    const setName = extractValue(set?.name);
    const { match_home_team, match_away_team, play_data_id, play_type } =
      getPlayInfo(metadata);
    const { badges, avgAsk } = metadata;
    const momentImage = `https://storage.googleapis.com/dl-laliga-assets-prod/editions/${play_data_id}/play_${play_data_id}__capture_Hero_Black_2880_2880_default.png`;

    return {
      avgAsk,
      badges,
      editionId,
      heading: headliner,
      line1:
        match_highlighted_team &&
        play_type &&
        `${play_type} · ${match_season} · ${setName}: ${match_home_team} Vs ${match_away_team}`,
      lowestAsk: formatPrice(lowestAsk / 1e8),
      maxMinted: total_minted,
      momentImage,
      onBuyNow,
      playId,
      playType: play_type,
      rarityTier,
      serialNumber,
      totalBurned: total_burned,
    };
  };
};
