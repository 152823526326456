import { useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { Flex, Text } from 'src/theme';
import { HERO_SECTION_THEME_KEYS } from 'src/theme/dss-skeleton/components';
import { WarningIcon } from 'src/theme/dss-skeleton/themes/icons';

interface HeroSectionWarningProps {
  text: string;
}

export const HeroSectionWarning = ({ text }: HeroSectionWarningProps) => {
  const styles = useMultiStyleConfig(HERO_SECTION_THEME_KEYS.COMMON);
  return (
    <Flex sx={styles.warningContainer}>
      <WarningIcon mt='1px' />
      <Text sx={styles.warningText}>{text}</Text>
    </Flex>
  );
};
