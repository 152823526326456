import { ApolloError } from '@apollo/client';

export enum FS {
  ERROR = 'ERROR',
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  REFETCHING = 'REFETCHING',
  SUCCESS = 'SUCCESS',
}

export type FSType = typeof FS;

interface Context {
  error?: ApolloError;
}

export interface QueryState {
  context: Context;
  value: FS;
}
