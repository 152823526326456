import { FC, ReactNode } from 'react';
import { useTranslation } from 'src/lib/translations';
import { UNIVERSAL_MEDIA } from 'src/modules/PackOpeningExperience/fsm/packOpening/constants';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Text,
  Image,
  VStack,
  Heading,
  Button,
} from 'src/theme';

type Props = {
  customProgress?: ReactNode;
  messageKey: string;
  titleKey?: string;
  txId?: string;
};

/**
 * Modal that pops up when processing transaction
 *
 * @param props
 * @param props.titleKey - optional title key
 * @param props.messageKey - required message key
 * @param props.txId - optional transaction id
 * @param props.customProgress - optional custom progress indicator
 */

export const ProcessingModal: FC<Props> = ({
  titleKey,
  messageKey,
  txId,
  customProgress,
}) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen onClose={() => {}}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody py={16}>
          <VStack textAlign='center' spacing={14}>
            <Heading size='sm' variant='tame'>
              {t(titleKey ?? 'transactionMessages.processing.title')}
            </Heading>

            <Box maxWidth='500px'>
              <Text
                data-testid='transaction-processing-message'
                size='md'
                variant='label'
              >
                {t(messageKey)}
              </Text>
            </Box>

            {customProgress ? (
              <Box width='100%'>{customProgress}</Box>
            ) : (
              <Image
                width='80%'
                maxWidth='500px'
                height='4px'
                alt='loading indicator'
                // @ts-ignore
                src={UNIVERSAL_MEDIA.LOADING_BAR}
              />
            )}

            {txId && (
              <Box>
                <Button
                  as='a'
                  sx={{
                    _hover: {
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                      textTransform: 'none',
                    },
                  }}
                  p={2}
                  textTransform='none'
                  borderRadius={0}
                  href={`${process.env.NEXT_PUBLIC_FLOWSCAN}/${txId}`}
                  rel='noreferrer noopener'
                  target='_blank'
                  variant='link'
                >
                  {t('general.view.transaction')}
                </Button>
              </Box>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
