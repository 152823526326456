import { getSession } from 'src/edge/session';

const POLL_INTERVAL = 3000;
const MAX_WAIT_TIME = 120000;

export const prepareFlowAddress =
  (sessionSend, profile, setRetrying) => async () => {
    const startTime: number = Date.now();
    let isRunning = true;
    let isRetrying = false;
    let result: any;
    do {
      const session = getSession();

      // The request to prepare the account (/update) can fail if the token expires
      // because this whole polling scenario can take a while its best we confirm
      // and refresh as necessary.
      if (!session.isValid()) {
        await session.refresh();
      }

      const response = await fetch('/api/auth/update');
      result = await response.json();
      if (result?.flowAddress) {
        isRunning = false;
        sessionSend({
          data: {
            profile: { ...profile, flowAddress: result.flowAddress },
            session: { user: session?.data?.user },
          },
          type: 'UPDATE_FLOW_ADDRESS',
        });
        // Update our user store
      } else if (Date.now() - startTime > MAX_WAIT_TIME) {
        isRunning = false;
        // force logout because there's something wrong with the session.
        // user will see an error in the prepare-modal, if they refresh they can
        // try to sign back in.
        session.logout();
      } else {
        // pass info to the modal that it hasn't succeeded after 1st attempt
        if (!isRetrying) {
          isRetrying = true;
          setRetrying(true);
        }
        // slight pause before trying again.
        await new Promise((resolve) => setTimeout(resolve, POLL_INTERVAL));
      }
    } while (isRunning);

    return result?.flowAddress;
  };
