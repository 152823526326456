import { captureException } from '@dapperlabs/core-fe';
import i18next from 'i18next';
import { useState, useEffect, useCallback } from 'react';
import { FS, QueryState } from 'src/general/constants/finiteStates';
import { getContentfulClient } from 'src/lib/contentful/utils';

const client = getContentfulClient();

/**
 * Simple kv cache map that keeps track of in-flight requests based on unique
 * identifiers
 * @type {Object}
 */
const cache = {};

/**
 * A general purpose hook for plugging into the contentful api. Caches calls in
 * a promise kv map if a unique identifier is supplied. That way, multiple
 * callers can resolve in-flight requests without re-requesting data.
 * @param {String} id Optional identifier for caching the request
 * @param {String} method Required method name for the client api
 * @param {Array} args Optional args array to pass into api call
 * @return {Object} State and data of api call
 */
function useContentful(
  id: string,
  method: 'getEntry' | 'getEntries',
  args = {},
): { data: any; refetch: () => void; state: QueryState } {
  const [state, setState] = useState(FS.IDLE);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const [language, setLanguage] = useState(i18next.language);

  i18next.on('languageChanged', (lang) => {
    setLanguage(lang);
  });

  useEffect(() => setState(FS.REFETCHING), [language]);

  if (!('locale' in args)) {
    args['locale'] = language.replace('en', 'en-US'); // TODO proper stardartized replacement?
  }

  useEffect(() => {
    async function requestData() {
      setState(FS.LOADING);
      try {
        // If a unique identifier is passed through to the hook, then let's take
        // into consideration the KV cache map. Else, just run the client api
        // call directly and return that.
        if (id) {
          // If the id does not yest exist in the cache, initialise the
          // execution of the api call and assign it to its corresponding key in
          // the cache.
          if (!cache[id]) cache[id] = client[method](args);

          // Then, await the result of the cached exectution.
          const response = await cache[id];
          setData(response);
        } else {
          const response = await client[method](args);
          setData(response);
        }
        setState(FS.SUCCESS);
      } catch (error) {
        setError(error);
        captureException(error);
        setState(FS.ERROR);
      }
    }

    if (state === FS.IDLE || state === FS.REFETCHING) {
      requestData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, method, JSON.stringify(args), state]);

  const refetch = useCallback(() => {
    if (state !== FS.REFETCHING) {
      setState(FS.REFETCHING);
    }
  }, [state]);

  return {
    data,
    refetch,
    state: {
      context: {
        error,
      },
      value: state,
    },
  };
}

export default useContentful;
