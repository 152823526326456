import { featureFlagsPath } from '@dapperlabs/core-fe';
import isEqual from 'lodash/isEqual';
import isPlainObject from 'lodash/isPlainObject';
import omit from 'lodash/omit';
import PropTypes from 'prop-types';
import React, { useRef, useCallback, useState, useContext } from 'react';
import { useTimeoutFn } from 'react-use';
import { useIsPageVisible } from 'src/general/utils/isPageVisible';

const FLAG_POLL_INTERVAL = 10000;
const DELAY = 1000;
const FACTOR = 2;
const CLIENT_KEY = '_isClient';

export const FeatureFlagsContext = React.createContext<any>({});

const calcDelay = (times) =>
  FLAG_POLL_INTERVAL + DELAY * Math.pow(FACTOR, times);

/**
 * FeatureFlagProvider will fetch new feature flags on an interval.
 * If an error is encountered, it begins to increasingly backoff on requests.
 * Whenever a successful request occurrs the backoff is reset.
 * Flags are only set when they do not match previous flags.
 *
 * Logic matches that of `nba-app` at time of this commit.
 */
export const FeatureFlagsProvider = ({ flags: initialFlags, children }) => {
  const [flags, setFlags] = useState(initialFlags);
  // number of sequential errors, for increasing backoff
  const errorCount = useRef(0);
  const hasFetchedViaClient = useRef(false);

  // check if the page is visible or not
  const isPageVisible = useIsPageVisible();

  // delay used in timeout. Using state so we can modify it as we backoff after errors.
  const [delay, setDelay] = useState(0);
  const [, , reset] = useTimeoutFn(() => {
    fetchFlags();
  }, delay);

  const fetchFlags = useCallback(async () => {
    // only re-fetch flags if the page is visible
    if (isPageVisible) {
      try {
        if (initialFlags.maintenance) return;
        const response = await fetch(featureFlagsPath());
        if (!response.ok) return;
        const json = await response.json();
        const isFlagsValid = isPlainObject(json.flags);
        const isFlagsEqual = isEqual(omit(flags, [CLIENT_KEY]), json.flags);
        if (isFlagsValid && !isFlagsEqual) {
          setFlags({
            ...json.flags,
            [CLIENT_KEY]: true,
          });
        }
        // updating the flags with _isClient property incase the flags are the
        // same. This way any place that uses this doesn't need to wait longer.
        if (isFlagsValid && isFlagsEqual && !hasFetchedViaClient.current) {
          setFlags({
            ...json.flags,
            [CLIENT_KEY]: true,
          });
        }
        errorCount.current = 0;
        hasFetchedViaClient.current = true;
      } catch (error) {
        errorCount.current += 1;
      }
    }

    // calculate the new delay, if it's the same we'll need to explicit reset the timeout
    // otherwise it resets when the delay changes in the original hook.
    const newDelay = calcDelay(errorCount.current);
    setDelay(newDelay);
    if (newDelay === delay) reset();
  }, [flags, initialFlags.maintenance, delay, reset, isPageVisible]);

  return (
    <FeatureFlagsContext.Provider children={children} value={flags || {}} />
  );
};

FeatureFlagsProvider.propTypes = {
  children: PropTypes.node.isRequired,
  flags: PropTypes.object,
};

FeatureFlagsProvider.defaultProps = {
  flags: {},
};

export const FeatureFlagsConsumer = FeatureFlagsContext.Consumer;

export const useFeatureFlags = () => {
  const flags = useContext(FeatureFlagsContext);
  return flags;
};
