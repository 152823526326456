import { caret } from './caret';
import { common } from './common';
import { statistics } from './statistics';
import { statsLine } from './statsLine';
import { timeline } from './timeline';

export enum GAME_HIGHLIGHTS_THEME_KEYS {
  CARET = 'GameHighlights/caret',
  COMMON = 'GameHighlights/common',
  STATISTICS = 'GameHighlights/statistics',
  STATS_LINE = 'GameHighlights/statsLine',
  TIMELINE = 'GameHighlights/timeline',
}

export const GameHighlightsTheme = {
  [GAME_HIGHLIGHTS_THEME_KEYS.CARET]: caret,
  [GAME_HIGHLIGHTS_THEME_KEYS.COMMON]: common,
  [GAME_HIGHLIGHTS_THEME_KEYS.STATISTICS]: statistics,
  [GAME_HIGHLIGHTS_THEME_KEYS.STATS_LINE]: statsLine,
  [GAME_HIGHLIGHTS_THEME_KEYS.TIMELINE]: timeline,
};
