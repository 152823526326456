import {
  ApolloClient,
  HttpLink,
  from,
  split,
  ApolloLink,
} from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import QueueLink from 'apollo-link-queue';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import { useMemo } from 'react';
import { getSession } from 'src/edge/session';

import { createCache } from './cache';
import { sentryLink } from './sentryLink';
import { createTimingLink } from './timingLink';

export const TOKEN_HEADER = 'x-id-token';

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__';

let apolloClient: ApolloClient<any>;

function createLink() {
  /**
   * withToken context link
   * This uses the setContext function from the apollo-link-context to get the
   * user's id token and pass it along with the req headers if it exists.
   * https://github.com/apollographql/apollo-link/tree/master/packages/apollo-link-context
   */
  const withTokenLink = setContext(async (req, { headers, idToken }) => {
    try {
      let token: string = null;

      // explicitly defined token, used in special cases.
      if (idToken) token = idToken;

      // only use session token in browser, we don't want to do authenticated reqs on ssr/ssg/isr.
      if (process.browser) {
        token = await getSession().getIdToken();
      }
      return {
        headers: {
          ...headers,
          ...(token ? { [TOKEN_HEADER]: token } : {}),
        },
      };
    } catch (error) {
      // @note: no need to catch this error
    }
    return {};
  });

  // This will log out and record times for queries. timingCache is appended to
  // client instance so it can be captured and added to prop in withApollo.
  const { timingLink = undefined } =
    process.env.NODE_ENV === 'development' ? createTimingLink() : {};

  let isRefreshing = false;

  const queueLink = new QueueLink();

  const tokenCheckLink = new ApolloLink((operation, forward) => {
    if (!process.browser) return forward(operation);
    const session = getSession();
    if (!isRefreshing && !session.isValid()) {
      isRefreshing = true;
      queueLink.close();
      session
        .refresh()
        .then(() => {
          isRefreshing = false;
          queueLink.open();
        })
        .catch(() => {
          // not doing anything here... if refresh fails i'm inclined to think we should keep the query queue
          // paused as they'll need to refresh or sign back in anyway
        });
    }
    return forward(operation);
  });

  return from([
    ...(timingLink ? [timingLink] : []),
    sentryLink(),
    tokenCheckLink,
    queueLink,
    withTokenLink,
    split(
      (operation) => operation.getContext().clientName === 'queueServiceAPI',
      new HttpLink({
        credentials: 'same-origin',
        fetch,
        headers: {
          'API-KEY': `${process.env.QUEUE_SERVICE_API_KEY}`,
          'Content-Type': 'application/json',
        },
        uri: ({ operationName }) =>
          `${process.env.NEXT_PUBLIC_QUEUE_API_URI}?${operationName}`,
      }),
    ),
    split(
      (operation) => operation.getContext().clientName === 'mockAPI',
      new HttpLink({
        credentials: 'same-origin',
        fetch,
        uri: ({ operationName }) =>
          // @NOTE: SSR requires an absolute URI
          typeof window !== 'undefined'
            ? `/api/graphql?${operationName}`
            : `${process.env.SITE_URL}/api/graphql?${operationName}`,
      }),
      split(
        (operation) => operation.getContext().clientName === 'platformAPI',
        new HttpLink({
          credentials: 'same-origin',
          fetch,
          uri: ({ operationName }) =>
            `${process.env.NEXT_PUBLIC_PLATFORM_API_URI}?${operationName}`,
        }),
        new HttpLink({
          credentials: 'same-origin',
          fetch,
          uri: ({ operationName }) =>
            `${process.env.NEXT_PUBLIC_LALIGA_API_URI}?${operationName}`,
        }),
      ),
    ),
  ]);
}

// the following is based on:
// https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js#L30-L59
// @todo: type these args
export function createApolloClient() {
  apolloClient = new ApolloClient({
    cache: createCache(),
    connectToDevTools: typeof window !== 'undefined',
    // Only connect to devtools in client
    defaultOptions: {
      mutate: {
        errorPolicy: 'all',
      },
      query: {
        errorPolicy: 'all',
      },
      watchQuery: {
        errorPolicy: 'ignore',
      },
    },
    link: createLink(),
    ssrMode: typeof window === 'undefined', // Disables forceFetch on the server (so queries are only run once),
  });

  return apolloClient;
}

export function initializeApollo(initialState = {}) {
  const _apolloClient = apolloClient ?? createApolloClient();

  // If your page has Next.js data fetching methods that use Apollo Client, the initial state
  // gets hydrated here
  if (initialState) {
    // Get existing cache, loaded during client side data fetching
    const existingCache = _apolloClient.extract();

    // Merge the existing cache into data passed from getStaticProps/getServerSideProps
    const data = merge(initialState, existingCache, {
      // combine arrays using object equality (like in sets)
      arrayMerge: (destinationArray, sourceArray) => [
        ...sourceArray,
        ...destinationArray.filter((d) =>
          sourceArray.every((s) => !isEqual(d, s)),
        ),
      ],
    });

    // Restore the cache with the merged data
    _apolloClient.cache.restore(data);
  }
  // For SSG and SSR always create a new Apollo Client
  if (typeof window === 'undefined') return _apolloClient;
  // Create the Apollo Client once in the client
  if (!apolloClient) apolloClient = _apolloClient;

  return _apolloClient;
}

export function addApolloState(client, pageProps = { props: {} }) {
  if (!pageProps.props) pageProps.props = {};
  pageProps.props[APOLLO_STATE_PROP_NAME] = client.cache.extract();

  return pageProps;
}

export function useApollo(pageProps) {
  const state = pageProps[APOLLO_STATE_PROP_NAME];
  const store = useMemo(() => initializeApollo(state), [state]);
  return store;
}
