import { ChakraTheme, Icon as ChakraIcon, IconProps } from '@chakra-ui/react';
import { InterpolationWithTheme } from '@emotion/core';

import ArrowBottom from './assets/arrow-bottom.svg';
import ArrowUpDown from './assets/arrow-up-down.svg';
import BallOutline from './assets/ball-outline.svg';
import Burger from './assets/burger.svg';
import Calendar from './assets/calendar.svg';
import Check from './assets/check.svg';
import Close from './assets/close.svg';
import CommonRibbon from './assets/common-ribbon.svg';
import Currency from './assets/currency.svg';
import Discord from './assets/discord.svg';
import Error from './assets/error.svg';
import ExternalLink from './assets/ExternalLinkIcon.svg';
import Facebook from './assets/facebook.svg';
import Fullscreen from './assets/fullscreen.svg';
import Instagram from './assets/instagram.svg';
import LaLigaLogo from './assets/la-liga-logo.svg';
import Language from './assets/language.svg';
import LegendaryRibbon from './assets/legendary-ribbon.svg';
import PlayFullVideo from './assets/play-full-video.svg';
import PlayMoment from './assets/play-moment.svg';
import RareRibbon from './assets/rare-ribbon.svg';
import RedCard from './assets/red-card.svg';
import SignOut from './assets/sign-out.svg';
import Successfully from './assets/successfull.svg';
import Twitter from './assets/twitter.svg';
import UncommonRibbon from './assets/uncommon-ribbon.svg';
import Warning from './assets/warning.svg';
import YellowCard from './assets/yellow-card.svg';
import Youtube from './assets/youtube.svg';

export type ChakraIconProps = IconProps & {
  css?: InterpolationWithTheme<ChakraTheme>;
};

export const LaLigaLogoIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={LaLigaLogo} />;
};

export const ArrowBottomIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ArrowBottom} />;
};

export const CurrencyIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Currency} />;
};

export const LanguageIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Language} />;
};

export const SignOutIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={SignOut} />;
};

export const CheckIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Check} />;
};

export const BurgerIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Burger} />;
};

export const CrossIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Close} />;
};

export const DiscordIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Discord} />;
};

export const FacebookIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Facebook} />;
};

export const InstagramIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Instagram} />;
};

export const TwitterIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Twitter} />;
};

export const YoutubeIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Youtube} />;
};
export const ExternalLinkIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={ExternalLink} />
);
export const CalendarIco = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Calendar} />;
};
export const PlayFullVideoIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PlayFullVideo} />;
};

export const FullscreenIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={Fullscreen} />
);

export const LegendaryRibbonIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={LegendaryRibbon} />
);

export const RareRibbonIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={RareRibbon} />
);

export const CommonRibbonIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={CommonRibbon} />
);
export const UncommonRibbonIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={UncommonRibbon} />
);

export const ArrowUpDownIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={ArrowUpDown} />
);

export const RedCardIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={RedCard} />
);

export const YellowCardIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={YellowCard} />
);

export const BallOutlineIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={BallOutline} />
);

export const PlayMomentIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={PlayMoment} />
);

export const SuccessfullyIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={Successfully} />
);

export const WarningIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={Warning} />
);

export const ErrorIcon = (props: ChakraIconProps) => (
  <ChakraIcon {...props} as={Error} />
);
