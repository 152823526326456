import {
  TRANSACTIONS_TYPE_MODAL,
  TRANSACTIONS_STAGES,
} from 'src/theme/dss-skeleton/components';

export const getStateListForSale = (transactionStage, typeModal) => {
  let messageKey = '';
  let titleKey = 'ListForSale.modal.defaultTitle';
  switch (transactionStage) {
    case TRANSACTIONS_STAGES.AUTHORIZING:
      messageKey = 'General.processing.authorizing.message';
      titleKey = 'General.processing.authorizing.title';
      break;
    case TRANSACTIONS_STAGES.POLLING:
      messageKey = 'General.processing.polling.message';
      titleKey = 'General.processing.title';
      break;
    case TRANSACTIONS_STAGES.SUCCESS:
      if (typeModal === TRANSACTIONS_TYPE_MODAL.PURCHASE) {
        messageKey = 'General.processing.success.message';
        titleKey = 'General.processing.success.title';
      }
      if (typeModal === TRANSACTIONS_TYPE_MODAL.LISTING) {
        messageKey = 'ListForSale.modal.listingSuccessful';
        titleKey = 'ListForSale.modal.success';
      }
      if (typeModal === TRANSACTIONS_TYPE_MODAL.DELIST) {
        messageKey = 'TransactionsModal.process.remove.success.message';
        titleKey = 'TransactionsModal.process.remove.success.title';
      }
      break;
    case TRANSACTIONS_STAGES.FINALIZING:
      messageKey = 'General.processing.finalizing.message';
      titleKey = 'General.processing.title';
      break;
    case TRANSACTIONS_STAGES.EXECUTING:
      messageKey = 'General.processing.executing.message';
      titleKey = 'General.processing.title';
      break;
    case TRANSACTIONS_STAGES.SEALING:
      messageKey = 'General.processing.sealing.message';
      titleKey = 'General.processing.title';
      break;
    case TRANSACTIONS_STAGES.ERROR:
      if (typeModal === TRANSACTIONS_TYPE_MODAL.PURCHASE) {
        messageKey = 'General.processing.error.message';
        titleKey = 'General.processing.error.title';
      }
      if (typeModal === TRANSACTIONS_TYPE_MODAL.LISTING) {
        messageKey = 'TransactionsModal.process.listing.error.message';
        titleKey = 'TransactionsModal.process.listing.error.title';
      }
      if (typeModal === TRANSACTIONS_TYPE_MODAL.DELIST) {
        messageKey = 'TransactionsModal.process.delisting.error.message';
        titleKey = 'TransactionsModal.process.delisting.error.title';
      }
      break;
    case TRANSACTIONS_STAGES.TOO_LONG_TRANSACTION_ERROR:
      if (typeModal === TRANSACTIONS_TYPE_MODAL.PURCHASE) {
        messageKey =
          'TransactionsModal.process.purchase.tooLongTransactionError.message';
        titleKey =
          'TransactionsModal.process.purchase.tooLongTransactionError.title';
      }
      if (typeModal === TRANSACTIONS_TYPE_MODAL.LISTING) {
        messageKey =
          'TransactionsModal.process.listing.tooLongTransactionError.message';
        titleKey =
          'TransactionsModal.process.listing.tooLongTransactionError.title';
      }
      if (typeModal === TRANSACTIONS_TYPE_MODAL.DELIST) {
        messageKey =
          'TransactionsModal.process.delisting.tooLongTransactionError.message';
        titleKey =
          'TransactionsModal.process.delisting.tooLongTransactionError.title';
      }
      break;
  }
  return { messageKey, titleKey };
};
