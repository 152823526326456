import React from 'react';
import {
  chakra,
  useMultiStyleConfig,
  Text,
  ChakraNextImage,
  Skeleton,
} from 'src/theme';
import { GAME_HIGHLIGHTS_THEME_KEYS } from 'src/theme/dss-skeleton/components';

import { TeamWrapper } from '../../Timeline/TimelineTeams';

type TeamInfoProps = {
  team: {
    logo?: string;
    score: number;
    title: string;
  };
};
export const TeamInfo = ({ team }: TeamInfoProps): JSX.Element => {
  const { title, score, logo } = team;

  const styles = useMultiStyleConfig(GAME_HIGHLIGHTS_THEME_KEYS.STATISTICS);

  return (
    <chakra.div sx={styles.teamInfoWrapper}>
      <chakra.div sx={styles.teamInfoHeader}>
        <TeamWrapper>
          {logo ? <ChakraNextImage src={logo} /> : <Skeleton w='70%' h='70%' />}
        </TeamWrapper>
        <Text sx={styles.teamInfoText}>{title}</Text>
      </chakra.div>
      <chakra.span sx={styles.teamInfoText}>{score}</chakra.span>
    </chakra.div>
  );
};

export default TeamInfo;
