import { gql } from '@apollo/client';
import { SearchAllDaySeasonalNftsInput } from '__generated__/globalTypes';

type Vars = {
  editionId?: string;
  flowId?: string;
  ownerAddress?: string;
} & SearchAllDaySeasonalNftsInput;

export const SEASONAL_NFT_QUERY_VARS = ({
  after,
  flowId,
  ownerAddress,
  editionId,
  first,
  filters: filtersProp = [],
  query,
  sortBy,
}: Vars) => {
  const filters = filtersProp;

  if (flowId) filters.push({ id: { eq: flowId } });
  if (editionId) filters.push({ edition: { id: { eq: editionId } } });
  if (ownerAddress) filters.push({ owner_address: { eq: ownerAddress } });

  return {
    input: {
      after,
      filters,
      first,
      query,
      sortBy,
    },
  };
};

export const SEASONAL_NFT_QUERY = gql`
  query searchAllDaySeasonalNft($input: SearchAllDaySeasonalNftsInput) {
    searchAllDaySeasonalNft(searchInput: $input) {
      edges {
        node {
          id
          owner_address
          edition {
            id
            metadata {
              description
              event
              league
              name
              tier
              type
            }
            type_name
          }
        }
      }
    }
  }
`;
