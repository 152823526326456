import { Wallet, token } from '@dapperlabs/dapper-wallet-sdk';
import * as fcl from '@onflow/fcl';
import { getDapperSessionPath } from 'src/modules/routes';

const SERVICE_TOKEN_TYPE = 'open-id';
let isRegistered = false;

export enum ConnectionMethod {
  'BACK_CHANNEL' = 'BACK_CHANNEL',
  'FRONT_CHANNEL' = 'FRONT_CHANNEL',
  'GUEST' = 'GUEST',
  'NONE' = 'NONE',
  'UNKNOWN' = 'UNKNOWN',
}

const wallet = new Wallet({
  autoEnable: true,
  fcl,
  iframeStyles: {
    backgroundColor: 'rgba(25, 25, 25, 0.7)',
    border: '0',
    colorScheme: 'normal',
    height: '100%',
    left: '0',
    position: 'fixed',
    top: '0',
    width: '100%',
    zIndex: '2147483647',
  },
  origin: process.env.NEXT_PUBLIC_DAPPER_FCL_TOKEN_API_URL,
  resolveAccessToken: token.resolver({
    referrer: typeof window !== 'undefined' && window.location.origin,
    route: getDapperSessionPath(),
  }),
  storage: true,
});

export const initDapperGuest = async () => {
  if (isRegistered) return;

  // if there's already a current user and its not a guest
  // we want to unauth them to avoid a weird scenario
  // where a user happens to be authed in fcl but not the app
  // tries to go forward as a guest
  const connectionMethod = await getWalletFclConnectionMethod();
  if (
    connectionMethod !== ConnectionMethod.GUEST &&
    connectionMethod !== ConnectionMethod.NONE
  ) {
    await fcl.unauthenticate();
  }
  isRegistered = true;
  wallet.register();
};

// we can set metadata that will be passed to the API when refreshing your access token
// this is important if you want Dapper Labs to support emailed transaction receipts and funnel tracking
// currently only `email` is supported
export const setMetadata = (email: string) => {
  wallet.setMeta({ email });
};

export const getGuestService = (services) => {
  return services?.find((s) => {
    return s.type === SERVICE_TOKEN_TYPE;
  });
};

export const getWalletFclConnectionMethod = async () => {
  const cu = await fcl.currentUser();
  const user = await cu.snapshot();
  if ((user?.services ?? []).length === 0) {
    return ConnectionMethod.NONE;
  }

  const authnService = user?.services?.find((s) => s.type === 'authn');

  if (authnService?.endpoint.endsWith('/fcl/authn-restricted')) {
    return ConnectionMethod.FRONT_CHANNEL;
  }

  if (authnService?.endpoint.endsWith('/fcl/authn')) {
    return ConnectionMethod.BACK_CHANNEL;
  }

  if (authnService?.method === 'DAPPER/SESSION') {
    return ConnectionMethod.GUEST;
  }

  return ConnectionMethod.UNKNOWN;
};
