import { Global } from '@emotion/react';

// There is no global css file so I'm doing this
export const Layout = () => {
  return (
    <Global
      styles={{
        'html, body, #__next': {
          height: 'auto',
        },
      }}
    />
  );
};
