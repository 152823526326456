import { useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { eurFormatter, usdFormatter } from 'src/lib/helpers/currencyFormatter';
import { Box, Flex, Text } from 'src/theme';
import { LIST_FOR_SALE_MODAL_CURRENCIES } from 'src/theme/dss-skeleton/components';

import { PriceItemI, PriceSectionProps } from './types';

const getFormattedUSDPrice = (price: number, currency: string): string => {
  if (!price) return null;
  return currency === LIST_FOR_SALE_MODAL_CURRENCIES.USD
    ? usdFormatter.format(Number(price))
    : eurFormatter.format(Number(price));
};

const PriceItem = ({
  item,
  currency,
}: {
  currency: string;
  item: PriceItemI;
}) => {
  const styles = useMultiStyleConfig('TransactionsModal/PriceSection');
  const mainStyles = useMultiStyleConfig('TransactionsModal');
  const formattedPrice = getFormattedUSDPrice(item.price, currency) || '--';
  return (
    <Flex sx={styles.priceItemContainer}>
      <Text sx={styles.label}>{item.label}</Text>
      <Box sx={styles.divider} />
      <Text sx={styles.price}>{formattedPrice}</Text>
      <Text sx={mainStyles.totalPriceCurrency}>{currency.toUpperCase()}</Text>
    </Flex>
  );
};

export const TransactionsModalPriceSection = ({
  prices,
  currency,
}: PriceSectionProps) => {
  const { avgSale, lowestAsk, topSale } = prices;
  return (
    <>
      <Box mb={2}>
        <PriceItem currency={currency} item={topSale} />
      </Box>
      <Box mb={2}>
        <PriceItem currency={currency} item={avgSale} />
      </Box>
      <PriceItem currency={currency} item={lowestAsk} />
    </>
  );
};
