import {
  home,
  RewardPassPersistentPresenter,
  RewardPassPersistentMobilePresenter,
  NflAllDayLogo,
} from '@dapperlabs/core-fe';
import { FC } from 'react';
import { useIsAuthed, useSession } from 'src/edge/session';
import {
  Box,
  Flex,
  HStack,
  Link,
  Stack,
  StyleProps,
  useBreakpointValue,
} from 'src/theme';

import { DesktopAuthedMenu, DesktopUnauthedMenu } from './DesktopMenu';
import MobileNavigation from './MobileNavigation';
import { NavItem, NavItems } from './navItems';
import NavLink from './NavLink';

type DefaultNavigationProps = { styles?: StyleProps };

const DefaultNavigation: FC<DefaultNavigationProps> = ({ styles }) => {
  const isAuthed = useIsAuthed();
  const { state: sessionState } = useSession();
  const { profile } = sessionState.context;

  const navItems: NavItem[] = NavItems(isAuthed, profile);
  const rewardPassPosition = useBreakpointValue(['under', 'under', 'inline']);
  const background = styles?.background ?? 'black';
  const showDesktopAuth = useBreakpointValue({
    base: false,
    md: true,
  });

  return (
    <Flex flexDir='column'>
      <Flex
        alignItems='center'
        justifyContent={['space-between', 'space-between']}
        gap={1}
        h='60px'
        padding={0}
        px={6}
        color='gray.500'
        bg={background}
        borderBottom={{ md: `1px solid` }}
      >
        <Flex
          alignItems='center'
          justifyContent={['space-between', 'space-between']}
          w={['100%', '100%', 'auto']}
          h='100%'
        >
          <HStack direction='row' spacing={[4, undefined, 0]}>
            <Box display={{ md: 'none' }}>
              <MobileNavigation />
            </Box>
            <Link display='flex' href={home()} paddingX={0} paddingY={4}>
              <NflAllDayLogo
                w='105px'
                h='24px'
                marginRight={2}
                marginLeft={{ xl: 4 }}
              />
            </Link>
          </HStack>
          {!isAuthed && (
            <Box display={{ md: 'none' }}>
              <DesktopUnauthedMenu />
            </Box>
          )}
        </Flex>
        <Stack
          direction='row'
          flex='1 0 auto'
          gridGap={{ base: 0, lg: 5 }}
          display={{ base: 'none', md: 'inherit' }}
          h='100%'
          marginLeft={{ base: 0, lg: 10 }}
        >
          {navItems.map((item) => (
            <NavLink
              key={item.href}
              {...item}
              alignItems='center'
              display='flex'
              ml={{ base: 0, lg: null }}
              px={{ base: 2, lg: null }}
            />
          ))}
        </Stack>
        {isAuthed && rewardPassPosition === 'inline' && (
          <RewardPassPersistentPresenter />
        )}
        {showDesktopAuth && (
          <>{isAuthed ? <DesktopAuthedMenu /> : <DesktopUnauthedMenu />}</>
        )}
      </Flex>
      {isAuthed && rewardPassPosition === 'under' && (
        <RewardPassPersistentMobilePresenter />
      )}
    </Flex>
  );
};

export default DefaultNavigation;
