import { gql } from '@apollo/client';

export const QUERY_MARKETPLACE = gql`
  query searchGolazosNft(
    $query: String
    $filters: [GolazosNftFilter!]
    $sortBy: GolazosNftSort
  ) {
    searchGolazosNft(
      searchInput: { query: $query, filters: $filters, sortBy: $sortBy }
    ) {
      edges {
        node {
          id
          edition {
            max_mintings
            play {
              id
              metadata {
                match_away_team
                match_highlighted_team
                match_home_team
                match_day
                match_season
                play_data_id
                play_type
                player_first_name
                player_known_name
                player_last_name
                player_number
                player_position
              }
            }
            series {
              id
              name
            }
            set {
              name
            }
            tier
            total_burned
            total_minted
            type_name
          }
          listing {
            created_at {
              block_height
              block_time
              event_index
              transaction_hash
              transaction_index
            }
            storefront_address
            listing_resource_id
            nft_type
            nft_id
            ft_vault_type
            price
            type_name
          }
          serial_number
        }
      }
    }
  }
`;

export const SEARCH_NFTS_FROM_PACK = gql`
  query searchGolazosNft__nftsInPack(
    $query: String
    $filters: [GolazosNftFilter!]
  ) {
    searchGolazosNft(searchInput: { query: $query, filters: $filters }) {
      edges {
        node {
          id
          serial_number
          edition {
            id
            max_mintings
            total_burned
            total_minted
            play {
              id
              metadata {
                match_away_score
                match_away_team
                match_date
                match_highlighted_team
                match_home_score
                match_home_team
                match_season
                play_data_id
                play_type
                player_first_name
                player_known_name
                player_last_name
                player_number
                player_position
              }
            }
            series {
              id
              name
            }
            set {
              name
              id
            }
            tier
            # total_burned note: these are erroring right now
            # total_minted
            type_name
          }
        }
      }
    }
  }
`;

export const GOLAZOS_NFT_AGGREGATION = gql`
  query searchGolazosNftAggregation(
    $searchInput: SearchGolazosNftAggregationsInput
  ) {
    searchGolazosNftAggregation(searchInput: $searchInput) {
      edges {
        node {
          id {
            count
          }
          listing {
            price {
              min
              max
              avg
              count
            }
            updated_at {
              block_height {
                max
              }
            }
            ft_vault_type {
              value
            }
          }
          edition {
            id {
              key
            }
            max_mintings {
              value
            }
            play {
              id {
                value
              }
              classification {
                value
              }
              metadata {
                match_away_score {
                  value
                }
                match_away_team {
                  value
                }
                match_day {
                  value
                }
                match_highlighted_team {
                  value
                }
                match_home_score {
                  value
                }
                match_home_team {
                  value
                }
                match_season {
                  value
                }
                play_data_id {
                  value
                }
                play_half {
                  value
                }
                play_time {
                  value
                }
                play_type {
                  value
                }
                player_country {
                  value
                }
                player_data_id {
                  value
                }
                player_first_name {
                  value
                }
                player_jersey_name {
                  value
                }
                player_known_name {
                  value
                }
                player_last_name {
                  value
                }
                player_number {
                  value
                }
                player_position {
                  value
                }
              }
            }
            series {
              id {
                value
              }
              name {
                value
              }
            }
            set {
              name {
                value
              }
            }
            tier {
              value
            }
            total_burned {
              value
            }
            total_minted {
              value
            }
          }
        }
      }
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
    }
  }
`;
