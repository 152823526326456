import type { ComponentStyleConfig } from '@chakra-ui/react';

export const common: ComponentStyleConfig = {
  baseStyle: {
    appInfo: {
      '> svg': {
        height: '2.5rem',
        width: 'auto',
      },
      alignItems: 'center',
      bgColor: '#17171B',
      borderRadius: '0.75rem 0.75rem 0 0',
      justifyContent: 'space-between',
      marginTop: '1.5rem',
      padding: '0.75rem 1rem',
    },
    icon: {
      bottom: '20px',
      pointerEvents: 'all',
      position: 'absolute',
      svg: {
        height: '1rem',
        width: '1rem',
      },
    },
    offPlayerText: {
      color: 'gray',
    },
    stadium: {
      alignItems: 'center',
      color: '#8A94A6',
      display: 'flex',
      fontSize: '18px',
      letterSpacing: '-0.16px',
      margin: { base: '12px 0 0 0', md: 'auto 16px auto auto' },
    },
    textItem: {
      padding: '0.5rem 0',
    },
    tooltip: {
      background: 'black',
    },
    tooltipBox: {
      background: 'black',
      border: '1px solid rgba(255, 255, 255, 0.15)',
      boxShadow: '6px 7px 13px 0 rgb(255 255 255 / 15%)',
      padding: '0',
    },
  },
  defaultProps: {
    variant: 'common',
  },
  parts: ['icon', 'tooltip', 'tooltipBox', 'offPlayerText', 'textItem'],
};

export default common;
