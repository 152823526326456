import React, { useContext } from 'react';

export type DSSAnalyticsEvents = {
  CAROUSEL_ITEM_CLICK?: string;
  GAME_HIGHLIGHTS_ITEM_CLICK?: string;
  GAME_HIGHLIGHTS_TOOLTIP_VIEW?: string;
  HERO_SECTION_DESCRIPTION_CLICK?: string;
  LANGUAGE_MENU_CLICK?: string;
  LANGUAGE_SETTINGS_CHANGE?: string;
  PLAYER_PERFORMANCE_ITEM_CLICK?: string;
  TOP_BAR_ITEM_CLICK?: string;
  VIDEO_CLOSE_BUTTON_CLICK?: string;
  VIDEO_OPEN_FULLSCREEN?: string;
  VOLUME_SETTINGS?: string;
};
export type DSSAnalyticsContextType = {
  events: DSSAnalyticsEvents;
  trackHandler: (trackId, props?) => void;
};
export const DssAnalyticsContext = React.createContext<DSSAnalyticsContextType>(
  {
    events: {},
    trackHandler: () => {},
  },
);

export const useDssAnalytics = () => {
  const { events, trackHandler } = useContext(DssAnalyticsContext);
  return {
    events,
    trackHandler: (trackId, ...props) => {
      if (!trackId) {
        console.warn(new Error('Unhandled track event'));
        return;
      }
      trackHandler(trackId, ...props);
    },
  };
};
