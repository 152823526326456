import React, { useState, useEffect } from 'react';
import { useGetAnnouncement } from 'src/lib/contentful/hooks/useGetAnnouncement';

import { AnnouncementCard } from './AnnouncementCard';

const LOCAL_STORAGE_KEY = 'globalAnnouncementTimestamp';

interface Props {
  locale: string;
}

export const GlobalAnnouncement = ({ locale }: Props) => {
  const { state, message, title, updatedAt, isActive } =
    useGetAnnouncement(locale);
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    if (state.value !== 'SUCCESS') return;
    // Pull saved timestamp frm local storage
    const savedTimestamp = window.localStorage.getItem(LOCAL_STORAGE_KEY);
    const unseen = savedTimestamp !== updatedAt;

    if (isActive && unseen) {
      setIsOpen(true);
    }
  }, [state.value, updatedAt, isActive]);

  // Save timestamp from Contentful's metadata on close
  const handleClose = () => {
    window.localStorage.setItem(LOCAL_STORAGE_KEY, updatedAt);
    setIsOpen(false);
  };

  return (
    <AnnouncementCard
      isOpen={isOpen}
      locale={locale}
      message={message}
      onClose={handleClose}
      title={title}
      updatedAt={updatedAt}
    />
  );
};
