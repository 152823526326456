import LaunchDarkly from 'launchdarkly-node-server-sdk';
import { captureException } from 'server/sentry';

const ldClient = LaunchDarkly.init(process.env.LAUNCHDARKLY_SDK_KEY);

type Props = {
  key?: string;
  email?: string;
  name?: string;
  anonId?: string;
  ip?: string;
};

async function getFlagsForUser({ key, email, name, anonId, ip }: Props) {
  try {
    await ldClient.waitForInitialization();
    const user = key
      ? {
          key,
          email,
          name,
          ip,
        }
      : { key: anonId || 'ANON', anonymous: true, ip };

    ldClient.identify(user);
    const flags = await ldClient.allFlagsState(user);
    return flags.allValues();
  } catch (error) {
    captureException(error);
    return {};
  }
}

export default getFlagsForUser;
