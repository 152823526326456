/* eslint-disable sort-keys-fix/sort-keys-fix, quotes, prettier/prettier */
// Autogenerated from graphql-golazos-players.js
type Players = Array<{ label: string; value: string }>;

export const PLAYER_DATA: Players = [
  { value: "1619", label: "Thierry Henry" },
  { value: "3508", label: "Xabi Alonso" },
  { value: "3884", label: "Robert Pires" },
  { value: "3889", label: "David Trezeguet" },
  { value: "3920", label: "Darko Kovacevic" },
  { value: "4001", label: "Jon Dahl Tomasson" },
  { value: "4132", label: "Fabio Cannavaro" },
  { value: "4154", label: "Valerón" },
  { value: "4157", label: "Raúl" },
  { value: "4169", label: "Gianluca Zambrotta" },
  { value: "4232", label: "Iker Casillas" },
  { value: "4857", label: "Ruud van Nistelrooy" },
  { value: "5813", label: "Carles Puyol" },
  { value: "5816", label: "Xavi" },
  { value: "5839", label: "Joan Capdevila" },
  { value: "5886", label: "Raúl Tamudo" },
  { value: "5929", label: "Rufete" },
  { value: "6077", label: "Roberto Carlos" },
  { value: "6098", label: "Mikel Aranburu" },
  { value: "6604", label: "Roberto Ayala" },
  { value: "6637", label: "Nihat Kahveci" },
  { value: "6678", label: "Eric Abidal" },
  { value: "6685", label: "Rafael Márquez" },
  { value: "6752", label: "Luis Fabiano" },
  { value: "7085", label: "Iván de la Peña" },
  { value: "7317", label: "Ronaldo" },
  { value: "8432", label: "Pepe Reina" },
  { value: "8438", label: "Eto'o" },
  { value: "8613", label: "Ronaldinho" },
  { value: "9808", label: "Zlatan Ibrahimovic" },
  { value: "10144", label: "Javier Saviola" },
  { value: "10876", label: "Ricardo" },
  { value: "10920", label: "Deco" },
  { value: "12237", label: "Andrés Iniesta" },
  { value: "12273", label: "Diego Forlán" },
  { value: "12496", label: "Víctor Valdés" },
  { value: "13135", label: "Kaká" },
  { value: "13308", label: "Maxi Rodríguez" },
  { value: "14095", label: "Jeremy Mathieu" },
  { value: "14402", label: "Fernando José Torres" },
  { value: "14464", label: "Aduriz" },
  { value: "14664", label: "Yaya Touré" },
  { value: "14937", label: "Cristiano Ronaldo" },
  { value: "15312", label: "Martín Demichelis" },
  { value: "15403", label: "Wesley Sneijder" },
  { value: "15780", label: "Dani Alves" },
  { value: "16249", label: "Maniche" },
  { value: "17268", label: "Julio Baptista" },
  { value: "17272", label: "Ricardo Oliveira" },
  { value: "17279", label: "David Villa" },
  { value: "17512", label: "Xabi Prieto" },
  { value: "17517", label: "Riki" },
  { value: "17679", label: "Sergio García" },
  { value: "17740", label: "Jesús Navas" },
  { value: "17861", label: "Sergio Ramos" },
  { value: "17871", label: "Gabi" },
  { value: "17878", label: "Cesc Fàbregas" },
  { value: "17926", label: "Roberto Soldado" },
  { value: "18221", label: "Gerard Piqué" },
  { value: "18222", label: "Giuseppe Rossi" },
  { value: "18262", label: "Renato" },
  { value: "18507", label: "Diego Costa" },
  { value: "18522", label: "Pepe" },
  { value: "18759", label: "Álvaro Arbeloa" },
  { value: "19054", label: "Lionel Messi" },
  { value: "19524", label: "Santi Cazorla" },
  { value: "19760", label: "Fernando Llorente" },
  { value: "19927", label: "Karim Benzema" },
  { value: "20151", label: "Imanol Agirretxe" },
  { value: "20664", label: "David Silva" },
  { value: "26746", label: "Diego Milito" },
  { value: "26921", label: "Arouna Koné" },
  { value: "28411", label: "Keylor Navas" },
  { value: "32281", label: "Diego Godín" },
  { value: "32644", label: "Javier Mascherano" },
  { value: "33148", label: "Claudio Bravo" },
  { value: "33376", label: "Guillermo Ochoa" },
  { value: "34703", label: "Ivan Rakitic" },
  { value: "36903", label: "Gareth Bale" },
  { value: "37055", label: "Luka Modric" },
  { value: "37064", label: "Andrés Guardado" },
  { value: "37265", label: "Alexis Sánchez" },
  { value: "37572", label: "Sergio Agüero" },
  { value: "37605", label: "Mesut Özil" },
  { value: "38401", label: "Carlos Vela" },
  { value: "39313", label: "Eliseu" },
  { value: "39336", label: "Luis Suárez" },
  { value: "39441", label: "Arda Turan" },
  { value: "39563", label: "Marcelo" },
  { value: "40232", label: "Gonzalo Higuaín" },
  { value: "40270", label: "Iago Aspas" },
  { value: "40275", label: "Giovani dos Santos" },
  { value: "40276", label: "Bojan" },
  { value: "40720", label: "Edinson Cavani" },
  { value: "40721", label: "Óscar Trejo" },
  { value: "40836", label: "Vicente Guaita" },
  { value: "41574", label: "Jozy Altidore" },
  { value: "41795", label: "Adil Rami" },
  { value: "42565", label: "Arturo Vidal" },
  { value: "42892", label: "Álvaro Negredo" },
  { value: "43020", label: "Chicharito" },
  { value: "44989", label: "Toni Kroos" },
  { value: "46197", label: "Mario Mandzukic" },
  { value: "46497", label: "Ángel Di María" },
  { value: "48847", label: "Radamel Falcao" },
  { value: "49309", label: "Éver Banega" },
  { value: "49464", label: "Cristhian Stuani" },
  { value: "49579", label: "Pedro" },
  { value: "51732", label: "José Callejón" },
  { value: "51952", label: "Daniel Parejo" },
  { value: "52356", label: "Jordi Alba" },
  { value: "54104", label: "Sergio Busquets" },
  { value: "54513", label: "Vicente Iborra" },
  { value: "54694", label: "Pierre-Emerick Aubameyang" },
  { value: "55776", label: "Papu Gómez" },
  { value: "56448", label: "Sergio Canales" },
  { value: "56764", label: "Robert Lewandowski" },
  { value: "59062", label: "De Marcos" },
  { value: "59339", label: "Alberto Perea" },
  { value: "59846", label: "Ander Herrera" },
  { value: "59891", label: "Agustín Marchesín" },
  { value: "60025", label: "James Rodríguez" },
  { value: "60772", label: "Thibaut Courtois" },
  { value: "61256", label: "Casemiro" },
  { value: "61278", label: "Neymar" },
  { value: "61316", label: "Joselu" },
  { value: "61975", label: "Nosa" },
  { value: "62974", label: "Erik Lamela" },
  { value: "68640", label: "Thomas Delaney" },
  { value: "68690", label: "Martin Braithwaite" },
  { value: "72154", label: "Nemanja Gudelj" },
  { value: "73314", label: "Willian José" },
  { value: "76012", label: "Muniain" },
  { value: "76508", label: "Hugo Mallo" },
  { value: "76610", label: "Mario Gaspar" },
  { value: "76650", label: "Antoine Griezmann" },
  { value: "77318", label: "Marc-André ter Stegen" },
  { value: "77359", label: "Florian Lejeune" },
  { value: "77390", label: "Koke" },
  { value: "78056", label: "Oriol Romeu" },
  { value: "80209", label: "Isco" },
  { value: "80287", label: "Juanmi" },
  { value: "80789", label: "Jordi Amat" },
  { value: "80791", label: "Sergi Enrich" },
  { value: "80795", label: "Francisco Portillo" },
  { value: "80906", label: "Dani Rodríguez" },
  { value: "80954", label: "Rodrigo Moreno" },
  { value: "80993", label: "Aitor Fernández" },
  { value: "81033", label: "Nicola Sansone" },
  { value: "81138", label: "Marc Bartra" },
  { value: "81352", label: "Jan Oblak" },
  { value: "82263", label: "Marcos Alonso" },
  { value: "82418", label: "Sergio León" },
  { value: "84471", label: "Ibai Gómez" },
  { value: "84583", label: "Philippe Coutinho" },
  { value: "86148", label: "Jordi Masip" },
  { value: "86157", label: "Sergi Roberto" },
  { value: "86169", label: "Cristian Tello" },
  { value: "86399", label: "Vitolo" },
  { value: "86813", label: "Aïssa Mandi" },
  { value: "88477", label: "Nacho" },
  { value: "88482", label: "Álvaro Morata" },
  { value: "88550", label: "Portu" },
  { value: "89335", label: "Saúl Ñíguez" },
  { value: "90152", label: "Raphael Varane" },
  { value: "90318", label: "Íñigo Martínez" },
  { value: "92187", label: "Abdón Prats" },
  { value: "92819", label: "Rafinha" },
  { value: "93721", label: "Gerard Moreno" },
  { value: "94273", label: "Ante Budimir" },
  { value: "94618", label: "Marko Dmitrovic" },
  { value: "94878", label: "Stole Dimitrievski" },
  { value: "96122", label: "William Carvalho" },
  { value: "96785", label: "Óscar Plano" },
  { value: "98851", label: "David García" },
  { value: "100001", label: "Zidane" },
  { value: "100058", label: "Fernando Martínez" },
  { value: "100731", label: "Franco Vázquez" },
  { value: "102380", label: "Antonio Rüdiger" },
  { value: "102884", label: "Paulo Gazzaniga" },
  { value: "103953", label: "Suso" },
  { value: "104407", label: "Predrag Rajkovic" },
  { value: "105571", label: "Antonio Raíllo" },
  { value: "106469", label: "Brian Oliván" },
  { value: "106824", label: "Memphis Depay" },
  { value: "106921", label: "Gerónimo Rulli" },
  { value: "107593", label: "Lucas Vázquez" },
  { value: "109243", label: "Moi Gómez" },
  { value: "109269", label: "Léo Baptistão" },
  { value: "110923", label: "Edgar Badia" },
  { value: "111133", label: "Darwin Machís" },
  { value: "111313", label: "Iza Carcelén" },
  { value: "112994", label: "Rubén Sobrino" },
  { value: "115860", label: "Óliver Torres" },
  { value: "116730", label: "Wu Lei" },
  { value: "119141", label: "Rodrigo de Paul" },
  { value: "119718", label: "Yannick Carrasco" },
  { value: "120026", label: "Yassine Bounou" },
  { value: "121117", label: "Lucas Ocampos" },
  { value: "121220", label: "Germán Pezzella" },
  { value: "124097", label: "Rui Silva" },
  { value: "132105", label: "José Gayà" },
  { value: "135363", label: "Andreas Christensen" },
  { value: "138039", label: "Sergio Herrera" },
  { value: "143877", label: "Alexander Sørloth" },
  { value: "151883", label: "José Giménez" },
  { value: "152723", label: "Johan Mojica" },
  { value: "153269", label: "Théo Bongonda" },
  { value: "155851", label: "Lucas Pérez" },
  { value: "156223", label: "Ángel Correa" },
  { value: "156293", label: "Vedat Muriqi" },
  { value: "158074", label: "Gabriel Paulista" },
  { value: "163711", label: "Unai García" },
  { value: "165375", label: "Morales" },
  { value: "165682", label: "Álvaro García" },
  { value: "166552", label: "Nabil Fekir" },
  { value: "167210", label: "Jeremías Ledesma" },
  { value: "168636", label: "Enes Ünal" },
  { value: "169007", label: "Sergi Darder" },
  { value: "169813", label: "Marcos Acuña" },
  { value: "170154", label: "Pablo Maffeo" },
  { value: "170864", label: "Munir El Haddadi" },
  { value: "172845", label: "Ezequiel Ponce" },
  { value: "173181", label: "Franck Kessie" },
  { value: "174292", label: "Marco Asensio" },
  { value: "174888", label: "Lucas Hernández" },
  { value: "175090", label: "Pere Milla" },
  { value: "175204", label: "David Soria" },
  { value: "176414", label: "Luca de la Torre" },
  { value: "178424", label: "Alfonso Espino" },
  { value: "178870", label: "Kike Barja" },
  { value: "178871", label: "Aleix García" },
  { value: "178880", label: "Brais Méndez" },
  { value: "179271", label: "Carles Aleñá" },
  { value: "179369", label: "Carles Pérez" },
  { value: "181284", label: "Gonçalo Guedes" },
  { value: "182539", label: "Dani Ceballos" },
  { value: "185126", label: "Cyle Larin" },
  { value: "192364", label: "Marcos Llorente" },
  { value: "193449", label: "Samu Castillejo" },
  { value: "193774", label: "Lucas Boyé" },
  { value: "194282", label: "Viktor Tsygankov" },
  { value: "195385", label: "Alex Berenguer" },
  { value: "195457", label: "Gonzalo Melero" },
  { value: "196878", label: "Umar Sadiq" },
  { value: "197024", label: "Guido Rodríguez" },
  { value: "197316", label: "Álex Remiro" },
  { value: "197334", label: "Iñaki Williams" },
  { value: "198849", label: "Lucas Torreira" },
  { value: "198906", label: "Anuar Mohamed" },
  { value: "199195", label: "Josan" },
  { value: "199248", label: "Borja Mayoral" },
  { value: "199257", label: "José Carlos Lazo" },
  { value: "199259", label: "Javi Sánchez" },
  { value: "200599", label: "Domingos Duarte" },
  { value: "201379", label: "Fabián Ruiz" },
  { value: "202044", label: "Borja Iglesias" },
  { value: "205992", label: "Chimy Avila" },
  { value: "209712", label: "Frenkie de Jong" },
  { value: "210171", label: "Joseph Aidoo" },
  { value: "210977", label: "Srdjan Babic" },
  { value: "212769", label: "Unai Simón" },
  { value: "213431", label: "Mario Hermoso" },
  { value: "215510", label: "Luiz Felipe" },
  { value: "216475", label: "Igor Zubeldia" },
  { value: "216791", label: "Pol Lirola" },
  { value: "218328", label: "Samuel Chukwueze" },
  { value: "218449", label: "Éder Militão" },
  { value: "219000", label: "Mikel Oyarzabal" },
  { value: "219271", label: "Takefusa Kubo" },
  { value: "219292", label: "Aurélien Tchouaméni" },
  { value: "219438", label: "Ousmane Dembélé" },
  { value: "219929", label: "Rafa Mir" },
  { value: "219961", label: "Raphinha" },
  { value: "220182", label: "Gorka Guruzeta" },
  { value: "220307", label: "Arnaut Danjuma" },
  { value: "220325", label: "Jules Koundé" },
  { value: "220327", label: "Eray Cömert" },
  { value: "221586", label: "Nahuel Molina" },
  { value: "222683", label: "Justin Kluivert" },
  { value: "223255", label: "Federico Valverde" },
  { value: "223757", label: "Óscar Rodríguez" },
  { value: "224444", label: "Ferran Torres" },
  { value: "224860", label: "Carlos Soler" },
  { value: "225463", label: "Amath Ndiaye" },
  { value: "226541", label: "Yangel Herrera" },
  { value: "227164", label: "Manu Morlanes" },
  { value: "230071", label: "Santi Comesaña" },
  { value: "233681", label: "Lucas Robertone" },
  { value: "234908", label: "Juan Foyth" },
  { value: "234991", label: "Youssef En-Nesyri" },
  { value: "240796", label: "Álvaro Fernández" },
  { value: "241276", label: "Jawad El Yamiq" },
  { value: "242315", label: "Álex Collado" },
  { value: "244847", label: "Yunus Musah" },
  { value: "246333", label: "Vinícius Júnior" },
  { value: "247412", label: "Jørgen Strand Larsen" },
  { value: "247636", label: "André Almeida" },
  { value: "247670", label: "Valentín Castellanos" },
  { value: "248501", label: "Álex Baena" },
  { value: "248507", label: "Sergio Camello" },
  { value: "249823", label: "Samuel Lino" },
  { value: "420909", label: "Edu Expósito" },
  { value: "421294", label: "Marcos André" },
  { value: "424001", label: "Vinicius Souza" },
  { value: "428399", label: "João Félix" },
  { value: "432656", label: "Eric García" },
  { value: "432838", label: "Luis Suárez" },
  { value: "433952", label: "Largie Ramazani" },
  { value: "434078", label: "Isi Palazón" },
  { value: "434399", label: "Reinildo" },
  { value: "435679", label: "Shon Weissman" },
  { value: "437460", label: "Hugo Guillamón" },
  { value: "437554", label: "Dani Vivian" },
  { value: "437834", label: "Rodrigo Riquelme" },
  { value: "439772", label: "Oihan Sancet" },
  { value: "440077", label: "Rodrygo" },
  { value: "441293", label: "Lázaro" },
  { value: "445087", label: "Ronald Araújo" },
  { value: "449027", label: "Giorgi Mamardashvili" },
  { value: "451358", label: "Lee Kang-In" },
  { value: "453313", label: "Gonzalo Plata" },
  { value: "456960", label: "Brian Ocampo" },
  { value: "464283", label: "Juan Iglesias" },
  { value: "465607", label: "Ansu Fati" },
  { value: "465691", label: "Tanguy Nianzou" },
  { value: "470508", label: "Julen Agirrezabala" },
  { value: "471138", label: "Ander Barrenetxea" },
  { value: "475561", label: "Eduardo Camavinga" },
  { value: "476489", label: "El Bilal Touré" },
  { value: "484702", label: "Aimar Oroz" },
  { value: "488024", label: "Yeremi Pino" },
  { value: "488591", label: "Alberto Mari" },
  { value: "490541", label: "Pedri" },
  { value: "491430", label: "Rodri" },
  { value: "493019", label: "Alejandro Balde" },
  { value: "500046", label: "Gavi" },
  { value: "500267", label: "Loïc Badé" },
  { value: "500745", label: "Ez Abde" },
  { value: "509290", label: "Luiz Henrique" },
  { value: "514189", label: "Gabriel Veiga" },
  { value: "517052", label: "Nicolas Jackson" },
  { value: "517333", label: "Arnau Martinez" },
  { value: "519373", label: "Ramón Terrats" },
  { value: "523654", label: "Nico Williams" },
  { value: "561245", label: "Álvaro Rodríguez" },
];
