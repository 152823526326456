import {
  forwardRef,
  useStyleConfig,
  HeadingProps as HeadingDeprecatedProps,
} from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import React from 'react';

export const HeadingDeprecated = forwardRef(
  (
    {
      size,
      variant,
      as = 'h1',
      color,
      sx = {},
      ...rest
    }: HeadingDeprecatedProps,
    ref,
  ) => {
    const styles = useStyleConfig('HeadingDeprecated', { size, variant });
    const element = as as string;
    const Heading = chakra[element];
    return <Heading {...rest} ref={ref} sx={{ ...styles, color, ...sx }} />;
  },
);
