import { ApolloClient } from '@apollo/client';
import { NextRouter } from 'next/router';
// import { MarketplaceEditionSortType } from 'src/edge/__generated/types/globalTypes';
import { State } from 'xstate';

export interface FiltersProps {
  [groupName: string]: { [optionName: string]: boolean };
}

export interface QueryFiltersProps {
  [groupName: string]: (string | number)[];
}

export type FilterOption = Record<
  string,
  {
    imagePath?: string | null;
    language: string;
  }
>;

export type FilterOptions = Record<
  | 'byTeamIDs'
  | 'byPlayerIDs'
  | 'byTiers'
  | 'byPlayerPositions'
  | 'byPlayTypes'
  | 'byBadgeSlugs'
  | 'bySetIDs'
  | 'byPriceRange',
  FilterOption
>;

export type TeamNFTFilterOptions = Record<'byTeamFlowIDs', FilterOption>;

interface SortOptionsType {
  [key: string]: {
    language: string;
    value: string;
  };
}

export type SortOptions = SortOptionsType;

export enum FS {
  ERROR = 'ERROR',
  GET_RESULTS = 'GET_RESULTS',
  IDLE = 'IDLE',
}
export type FStype = typeof FS;

export enum EVENTS {
  APPLY_FILTERS = 'APPLY_FILTERS',
  APPLY_SEARCH = 'APPLY_SEARCH',
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  CLEAR_SEARCH = 'CLEAR_SEARCH',
  REMOVE_FILTER = 'REMOVE_FILTER',
  UPDATE_SORTING = 'UPDATE_SORTING',
}

export type EVENTStype = typeof EVENTS;

export type Events = { data?: any; type: EVENTS };

export type FilterOptionCategories = {
  [key: string]: {
    component: string;
    currency?: string;
    currencySymbol?: string;
    elements?: any[];
    placeholder?: string;
    sortOrderArr?: string[];
    value: string;
  };
};

export interface FiltersContext {
  activeFilters?: QueryFiltersProps;
  categoryLabels: FilterOptionCategories;
  expandedFiltersLocalStorageKey?: string;
  filterOptions?: FilterOptions;
  frozenFilters?: QueryFiltersProps;
}

export interface PaginationContext {
  context?: Record<string, any>;
  limit: number;
  options?: any;
  query: any;
  queryDataPath: string;
  queryPaginationPath: string;
  variables: any;
}

export interface SortContext {
  sortBy: string;
  sortOptions?: SortOptions;
}
export interface Context {
  EVENTS: typeof EVENTS;
  FS: typeof FS;

  client: ApolloClient<any>;
  errorMessage: undefined | string;
  errorType: string;

  filters: FiltersContext;

  options?: {
    analyticsPrefix?: string;
  };

  pagination: PaginationContext;

  router: NextRouter;

  search: {
    query: string;
  };

  sort: SortContext;

  track: (trackingId: string, trackingProperties?: any) => void;
}

export interface Schema {
  states: Record<FS, {}>;
}

export interface TypeState {
  context: Context;
  value: FS;
}

export type SearchMachineState = State<Context, Events, Schema, TypeState>;
