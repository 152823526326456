export enum AssetContentType {
  IMAGE = 'image',
  VIDEO = 'video',
}

export type VideoMediaType = {
  fullscreenSrc: string;
  id: string;
  previewSrc: string;
  src: string;
  type: AssetContentType;
};

export type ImageMediaType = {
  id: string;
  src: string;
  type: AssetContentType;
};
