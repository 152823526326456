import { ComponentProps } from 'react';

import { Avatar, useBreakpointValue } from 'src/theme';
export type AvatarProps = ComponentProps<typeof Avatar>;

export interface CircleProps extends AvatarProps {
  size?: any;
}

const CircleAvatar = (props: CircleProps) => {
  const { size, ...rest } = props;
  return (
    <Avatar
      size={useBreakpointValue(size || { base: 'md', lg: 'lg', md: 'md' })}
      {...rest}
    />
  );
};

export default CircleAvatar;
