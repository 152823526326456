import * as React from 'react';
import { chakra, forwardRef, useStyles } from 'src/theme/index';
import type { HTMLChakraProps, ThemingProps } from 'src/theme/index';

export interface TopNavListItemProps
  extends HTMLChakraProps<'li'>,
    ThemingProps<'li'> {
  children: React.ReactNode;
  isSelected?: boolean;
}

export const TopNavListItem = forwardRef<TopNavListItemProps, 'li'>(
  ({ isSelected, ...props }, ref) => {
    const styles = useStyles();

    return (
      <chakra.li
        ref={ref}
        sx={isSelected ? styles.activeListItem : {}}
        __css={styles.listItem}
        {...props}
      />
    );
  },
);
