import { ActionFunctionMap, AnyEventObject, assign } from 'xstate';

import { Context, Events } from './types';

export const actions: ActionFunctionMap<Context, Events> = {
  // @todo these errors need some work/testing
  assignFetchError: assign({
    errorMessage: (_, event) => event.data?.errorMessage,
    errorType: 'FETCH',
  }),

  // @note for now we just disable fetching more
  // For some reason, TS gets confused about the types within this `assign`, so we need to explicitly provide the generics
  // again here.
  assignFetchMoreError: assign<Context, AnyEventObject>({
    errorMessage: (_, event) => event.data?.errorMessage,
    errorType: 'FETCH_MORE',
    pageInfo: (context, _) => ({
      ...context.pageInfo,
      hasNextPage: false,
    }),
  }),

  assignFetchedData: assign({
    edges: (context, event) => {
      const newEdges = event.data?.edges ?? [];

      return [...(context.edges ?? []), ...newEdges];
    },
    pageInfo: (_, { data }) => data.pageInfo,
    totalCount: (_, { data }) => data.totalCount,
  }),

  assignVariables: assign({
    variables: (context, event) => {
      return {
        ...context.variables,
        ...event.data?.variables,
      };
    },
  }),

  clearFetchedData: assign({
    edges: () => null,
    pageInfo: (_) => null,
    totalCount: (_) => null,
  }),
};
