import { useCallback } from 'react';

import { useAnalytics } from 'src/lib/segment';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';

import { useGetRewardPass } from './useGetRewardPass';

export const useRewardPassAnalytics = () => {
  const { track } = useAnalytics();
  const { data: rewardPass } = useGetRewardPass();
  const hijackedTrack = useCallback(
    (
      eventName: SEGMENT_EVENTS_ID_ENUM,
      extraProperties?: { [key: string]: string | boolean | number },
    ) => {
      try {
        const trackProps = {
          premiumProgress: null,
          rewardPassId: rewardPass?.id,
          userTier: rewardPass?.userProgress?.tier,
          userYards: rewardPass?.userProgress?.currentPoints,
          ...extraProperties,
        };
        if (rewardPass?.upgradeTask?.timesRequired) {
          trackProps.premiumProgress = {
            completed: rewardPass?.upgradeTask?.userTask?.timesCompleted,
            percent:
              rewardPass?.upgradeTask?.userTask?.timesCompleted /
              rewardPass?.upgradeTask?.timesRequired,
            required: rewardPass?.upgradeTask?.timesRequired,
          };
        }
        track(eventName, trackProps);
      } catch (error) {}
    },
    [track, rewardPass],
  );
  return { track: hijackedTrack };
};
