import { InfoOutlineIcon } from '@chakra-ui/icons';
import { Box, Flex, Heading, Text, Tooltip } from '@chakra-ui/react';
import React, { useCallback, useContext, useState } from 'react';
import { useIsClient } from 'src/general/utils/useIsClient';
import { usdFormatter } from 'src/lib/helpers/currencyFormatter';
import { useTranslation } from 'src/lib/translations';
import {
  breakpoints,
  useMediaQuery,
  useMultiStyleConfig,
  Button,
} from 'src/theme';
import {
  ImageMediaType,
  VideoMediaType,
} from 'src/theme/dss-skeleton/components/types';
import { LanguageContext } from 'src/theme/dss-skeleton/context/languageContext';

import { ReadMore } from '../ReadMore';
import {
  HERO_SECTION_THEME_KEYS,
  MomentsBurnedModal,
  HeroSectionWarning,
} from './';
import { HeroCarousel } from './Carousel';
import { RarityTier } from './RarityTier';
import { RarityTypes } from './types';

export type HeroSectionType = {
  [key: string]: any;
  avgPrice: number;
  burnedSize: number;
  buttonLabel: string;
  carouselItems: (ImageMediaType | VideoMediaType)[];
  countForSale: number | undefined | null;
  currency: { suffix: string };
  editionSize: number;
  isDisableButton?: boolean;
  isShowBtn?: boolean;
  isShowForSale?: boolean;
  isShowWarning?: boolean;
  lowestPrice: number;
  moment: {
    ownerBy?: string;
    serialNumber?: number;
    title: string;
    type: string;
  };
  onClick: (event: any) => void;
  rarityType: RarityTypes;
  summary: string;
  totalBurned?: number;
};
export const HeroSection = ({
  rarityType,
  moment,
  carouselItems,
  editionSize,
  burnedSize,
  summary,
  onClick,
  buttonLabel,
  isShowBtn = false,
  totalBurned,
  avgPrice = 0,
  lowestPrice = 0,
  currency,
  countForSale,
  isShowForSale = false,
  isDisableButton = false,
  isShowWarning = false,
  ...props
}: HeroSectionType): JSX.Element => {
  const { title, type, serialNumber, ownerBy } = moment;
  const styles = useMultiStyleConfig(HERO_SECTION_THEME_KEYS.COMMON);
  const { t } = useTranslation();
  const { selectedLanguage } = useContext(LanguageContext);
  const [isMdUp] = useMediaQuery(`screen and (min-width: ${breakpoints.md})`);
  const isClient = useIsClient();
  const [isHeroModalOpen, setIsHeroModalOpen] = useState<boolean>(false);

  const renderSummary = useCallback(() => {
    if (isMdUp) {
      return (
        <Text
          sx={{
            whiteSpace: 'pre-wrap',
          }}
          size='sm'
          variant='body'
        >
          {summary}
        </Text>
      );
    }
    return <ReadMore noOfLines={3}>{summary}</ReadMore>;
  }, [isMdUp, selectedLanguage.value, summary]);

  const toggleModal = () => {
    setIsHeroModalOpen(!isHeroModalOpen);
  };

  return (
    <Flex sx={styles.container} {...props}>
      {isClient && <HeroCarousel items={carouselItems} />}
      <Box sx={styles.details}>
        <Box sx={styles.header}>
          <Heading fontFamily='poster' fontWeight='400' size='3xl'>
            {title}
          </Heading>
          <Text size='sm'>{type}</Text>
        </Box>
        <RarityTier
          burnedSize={burnedSize}
          editionSize={editionSize}
          isShowForSale={isShowForSale}
          listingSize={countForSale}
          serialNumber={serialNumber}
          type={rarityType}
        />
        <Flex sx={styles.momentsBurnedCtn}>
          <Text sx={styles.momentsBurnedLabel}>
            {`${totalBurned} ${t('moment.totalBurnedLabel')}`}
          </Text>
          <InfoOutlineIcon
            w='13px'
            h='13px'
            ml={1}
            cursor='pointer'
            onClick={toggleModal}
          />
        </Flex>
        <MomentsBurnedModal
          closeModal={toggleModal}
          isModalOpen={isHeroModalOpen}
          modalBodyText={t('moment.totalBurned.modalContent')}
          modalTitle={t('moment.totalBurned.modalTitle')}
        />
        {ownerBy && (
          <Flex sx={styles.owner}>
            <Text sx={styles.ownerPrefix}>{t('moment.owner.ownedBy')}</Text>
            <Text sx={styles.ownerValue}>{ownerBy}</Text>
          </Flex>
        )}
        <Box sx={styles.content}>{renderSummary()}</Box>
        {/*{topPrice && (*/}
        {/*  <Flex sx={styles.priceContainer}>*/}
        {/*    <Text sx={styles.label}>*/}
        {/*      {t('heroSection.description.topPrice')}*/}
        {/*    </Text>*/}
        {/*    <Text sx={styles.priceLine} />*/}
        {/*    <Flex align='center'>*/}
        {/*      <Text sx={styles.price}>{`${currency.prefix}${topPrice}`}</Text>*/}
        {/*      <Text sx={styles.currencySuffix}>{currency.suffix}</Text>*/}
        {/*    </Flex>*/}
        {/*  </Flex>*/}
        {/*)}*/}
        {avgPrice ? (
          <Flex sx={styles.priceContainer}>
            <Text sx={styles.label}>{t('HeroSection.avgPrice.title')}</Text>
            <Box sx={styles.priceLine} />
            <Flex align='center'>
              <Text sx={styles.price}>{usdFormatter.format(avgPrice)}</Text>
              <Text sx={styles.currencySuffix}>{currency.suffix}</Text>
            </Flex>
          </Flex>
        ) : null}
        {lowestPrice ? (
          <Flex sx={styles.priceContainer}>
            <Text sx={styles.label}>{t('HeroSection.lowestAsk.title')}</Text>
            <Box sx={styles.priceLine} />
            <Flex align='center'>
              <Text sx={styles.highlightedPrice}>
                {usdFormatter.format(lowestPrice)}
              </Text>
              <Text sx={styles.currencySuffix}>{currency.suffix}</Text>
            </Flex>
          </Flex>
        ) : null}
        {isShowBtn && isDisableButton ? (
          <Tooltip
            display='flex'
            label={t(`ListForSale.button.disabled`)}
            placement='top'
          >
            <Box>
              <Button sx={styles.button} disabled onClick={onClick}>
                {buttonLabel}
              </Button>
            </Box>
          </Tooltip>
        ) : (
          isShowBtn && (
            <Button sx={styles.button} onClick={onClick}>
              {buttonLabel}
            </Button>
          )
        )}
        {isShowWarning ? (
          <HeroSectionWarning text={t('HeroSection.warning.message')} />
        ) : null}
      </Box>
    </Flex>
  );
};
