export const STAGING_ENTRIES_IDS = {
  ABOUT_PAGE: '7tpW3lNdFAJctu7oxEBmtm',
  ANNOUNCEMENT: 'zb5UebhpmUNuZgi0TIaI0',
  AUTHED_HOMEPAGE: '3eWOBESPw80achFUp9lSWH',
  CHALLENGES_HUB: '2jU0fyNI0tGohz12uQe7wk',
  CHALLENGES_PAGE: '4fIG2QAiD86cBHk2gU44jB',
  COLLECTIONS_HUB: '5UxyVA8ABceH9pt3DGthZ8',
  FOOTER: '7fEAcURdLsU1f8j2YmEICc',
  HOMEPAGE: '01fLMaSgu0TeIzdrGkiJz0',
  MY_COLLECTION_PAGE: '3PY4mLmi7fuK0lMG9zdAvQ',
  PRIVACY_POLICY: '2ZTvePD7ehTm4kNvAWlHCl',
  TERMS_OF_SERVICE: '7GclaWMJPEg3WpbqneJtwA',
  THIS_WEEK_DROPS: '5lZR52k7Camvq9vnteOGAb',
  UNAUTH_HOMEPAGE: 'IPkgW9n5DfcbAL92nzoGt',
  UPCOMING_DROPSPAGE: 'IRZ4kWOqcL3y8wSKJtBj2',
};

export const PRODUCTION_ENTRIES_IDS = {
  ABOUT_PAGE: '7tpW3lNdFAJctu7oxEBmtm',
  ANNOUNCEMENT: '4er99uYKl0WIlPmAZc0ulP',
  AUTHED_HOMEPAGE: '214CUySHiICu7naVGQ020F',
  CHALLENGES_HUB: 'rulmin0pgG0Zv6kvDFMX8',
  CHALLENGES_PAGE: '55EIEHTuK1nK5Yo2EjZi3O',
  COLLECTIONS_HUB: '4EVawbpf2PVdYyRe0Qe3fj',
  FOOTER: '7fEAcURdLsU1f8j2YmEICc',
  HOMEPAGE: '01fLMaSgu0TeIzdrGkiJz0',
  MY_COLLECTION_PAGE: '6g5eXLHoPrek7HxuLOIlC1',
  PRIVACY_POLICY: '2ZTvePD7ehTm4kNvAWlHCl',
  TERMS_OF_SERVICE: '7GclaWMJPEg3WpbqneJtwA',
  UPCOMING_DROPSPAGE: '1s1pv4cDGfjmeCZPGOxRQC',
};

export const STAGING_CONTENT_TYPE_IDS = {
  ANNOUNCEMENT: 'announcement',
  DROP: 'drop',
};

export const PRODUCTION_CONTENT_TYPE_IDS = {
  ANNOUNCEMENT: 'announcement',
  DROP: 'drop',
};

export const DROP_STATUS = {
  CURRENT: 'current',
  FUTURE: 'future',
  PAST: 'past',
};

export enum MediaContentType {
  IMAGE = 'image',
  VIDEO = 'video',
}

const isProduction =
  process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT === 'master';

export const ENTRIES_IDS = isProduction
  ? PRODUCTION_ENTRIES_IDS
  : STAGING_ENTRIES_IDS;

export const CONTENT_TYPE_IDS = isProduction
  ? PRODUCTION_CONTENT_TYPE_IDS
  : STAGING_CONTENT_TYPE_IDS;
