export enum TEXT_MARKERS {
  FT = 'Timeline.FT',
  HT = 'Timeline.HT',
  KO = 'Timeline.KO',
}

export enum TIMELINE_EVENT_TYPES {
  DISCIPLINE = 'DISCIPLINE',
  GOAL = 'GOAL',
  MOMENT = 'MOMENT',
  SUBSTITUTION = 'SUBSTITUTION',
}

export enum COLOR_CARDS {
  RED = 'Red',
  YELLOW = 'Yellow',
}
