import { ApolloClient } from '@apollo/client';
import {
  args,
  authorizations,
  authz,
  limit,
  payer,
  proposer,
  transaction,
  arg,
  withPrefix,
  sansPrefix,
} from '@onflow/fcl';
import { UInt64 } from '@onflow/types';
import { captureException } from 'src/lib/sentry';

import {
  authorizeMintFTUESeasonalNFT,
  authorizeMintFTUESeasonalNFTVariables,
} from '../__generated__/authorizeMintFTUESeasonalNFT';
import { AUTHORIZE_MINT_FTUE_SEASONAL_NFT_MUTATION } from '../mutations';

const FTUE_SEASONAL_NFT_EDITION_FLOWID =
  parseInt(process.env.NEXT_PUBLIC_FTUE_SEASONAL_NFT_EDITION_FLOWID) ?? 1;

interface GetMintFTUESeasonalNFTInteractionProps {
  client: ApolloClient<any>;
}
/**
 * Handles constructing the mint seasonal nft blockchain interaction for ftue v2
 *
 * @param props.client - apollo client
 * @returns ix - array of parameters for initiating the transaction
 */
export const getInteraction = ({
  client,
}: GetMintFTUESeasonalNFTInteractionProps) => {
  const invokeAuthorizeMintSeasonalNFTMutation = async (signable) => {
    const signableVoucher = Buffer.from(
      JSON.stringify(signable.voucher),
    ).toString('base64');

    const { errors, data } = await client.mutate<
      authorizeMintFTUESeasonalNFT,
      authorizeMintFTUESeasonalNFTVariables
    >({
      mutation: AUTHORIZE_MINT_FTUE_SEASONAL_NFT_MUTATION,
      variables: { input: { signable: signableVoucher } },
    });

    if (errors) {
      captureException(errors);
      throw errors;
    }

    return {
      addr: withPrefix(process.env.NEXT_PUBLIC_APP_AUTHZ_ADDRESS) as string,
      keyId: process.env.NEXT_PUBLIC_APP_AUTHZ_KEYID,
      signature: data.authorizeMintSeasonalNFT.signature,
    };
  };

  /**
   * Handles getting authorization from our NFL API, constructs an authorization object
   *
   * @returns The addr and keyId values in the outer scope of the returned
   *          object needs to match the addr and keyId returned from the
   *          signingFunction
   */
  const nflAuthz = async (ixAcct) => ({
    ...ixAcct,
    // backend authorizer address:
    addr: sansPrefix(process.env.NEXT_PUBLIC_SEASONAL_NFT_ADDRESS),
    // this is how the backend authorizer proves its them:
    keyId: Number(process.env.NEXT_PUBLIC_APP_AUTHZ_KEYID),
    // how we get that proof
    signingFunction: async (signable) => {
      // addr, keyId needs to be the same value as the containing scope
      const { addr, keyId, signature } =
        await invokeAuthorizeMintSeasonalNFTMutation(signable);

      return {
        addr, // with 0x prefix
        keyId,
        signature,
      };
    },
  });

  const ix = () => [
    // @TODO: the FTUE needs to get moved out of core-fe, for now using an empty
    // string instead of a deleted tx
    transaction(''),
    args([arg(FTUE_SEASONAL_NFT_EDITION_FLOWID, UInt64)]),
    proposer(authz),
    payer(authz),
    authorizations([nflAuthz, authz]),
    limit(350),
  ];

  return ix;
};
