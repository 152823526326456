import { cdc } from '@onflow/fcl';

const CODE = cdc`import PackNFT from 0xPackNFTContractAddress
import IPackNFT from 0xIPackNFT
import NonFungibleToken from 0xNonFungibleTokenContractAddress

transaction(revealID: UInt64) {
    prepare(owner: auth(BorrowValue) &Account) {
        let collectionRef = owner.storage.borrow<auth(NonFungibleToken.Update) &PackNFT.Collection>(from: PackNFT.CollectionStoragePath)
            ?? panic("could not borrow authorized collection")
        collectionRef.emitRevealRequestEvent(id: revealID, openRequest: true)
    }
}`;

export { CODE };
