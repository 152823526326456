/* eslint-disable sort-keys-fix/sort-keys-fix, quotes */
type PlayerPosition = Array<{ label: string; value: string }>;

const echoParamFunc = (key) => key;

// Note: This const file is NOT generated because:
// - We need it in this order
// - Its values will never change
// - Need to translate its values
export const POSITION_DATA = (t = echoParamFunc): PlayerPosition => [
  { value: 'Goalkeeper', label: t('MarketplaceSearch.Season.Goalkeeper') },
  { value: 'Defender', label: t('MarketplaceSearch.Season.Defender') },
  { value: 'Midfielder', label: t('MarketplaceSearch.Season.Midfielder') },
  { value: 'Forward', label: t('MarketplaceSearch.Season.Forward') },
];
