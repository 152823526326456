import {
  chakra,
  IconButton,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
} from '@chakra-ui/react';
import React, { RefObject, useEffect, useState } from 'react';
import { useMultiStyleConfig, VolumeOff, VolumeOn } from 'src/theme';
import { VOLUME_SLIDER_THEME_KEY } from 'src/theme/dss-skeleton/components';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';

export type VolumeSliderProps = {
  [key: string]: any;
  defaultVolume?: number;
  video: RefObject<HTMLVideoElement>;
};
export const VolumeSlider = ({
  video,
  defaultVolume = 50,
  ...props
}: VolumeSliderProps) => {
  const [value, setValue] = useState(defaultVolume);
  const [previousValue, setPreviousValue] = useState(defaultVolume);
  const [toggleValue, setToggleValue] = useState(defaultVolume);
  const { events, trackHandler } = useDssAnalytics();
  const styles = useMultiStyleConfig(VOLUME_SLIDER_THEME_KEY);

  const trackVolumeChange = (prevValue, currValue) => {
    trackHandler(events.VOLUME_SETTINGS, {
      item_label: 'volume',
      previous_value: String(prevValue),
      selected_value: String(currValue),
    });
  };

  useEffect(() => {
    if (video.current) {
      video.current.volume = value / 100;
    }
  }, [video, value]);

  const setTrackedValue = (newValue) => {
    trackVolumeChange(previousValue, newValue);
    setValue(newValue);
  };

  const toggleVolume = () => {
    if (value > 0) {
      setToggleValue(value);
      setValue(0);
      trackVolumeChange(value, 0);
      return;
    }
    setValue(toggleValue);
    trackVolumeChange(0, toggleValue);
  };

  return (
    <chakra.div {...props}>
      <Slider
        aria-label='volume-slider'
        onChange={setValue}
        onChangeEnd={setTrackedValue}
        onChangeStart={setPreviousValue}
        value={value}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
      <IconButton
        sx={styles.volumeIcon}
        aria-label='mute control'
        icon={value ? <VolumeOn /> : <VolumeOff />}
        onClick={toggleVolume}
        onTouchEnd={toggleVolume}
        role='button'
        tabIndex={1}
      />
    </chakra.div>
  );
};
