import {
  home,
  FTUE_EVENTS as EVENTS,
  useFTUEV2,
  NflAllDayLogo,
  UserInfoCardCompact,
} from '@dapperlabs/core-fe';
import { useRouter } from 'next/router';
import React, { useEffect } from 'react';
import { useIsAuthed, useSession } from 'src/edge/session';
import { useFeatureFlags } from 'src/lib/launchDarkly';
import { useAnalytics } from 'src/lib/segment';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';
import { useTranslation } from 'src/lib/translations';
import {
  Button,
  Divider,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  IconExternalLink,
  IconHamburgerMenu,
  Link,
  Stack,
  Text,
  useDisclosure,
} from 'src/theme';
import { IconRightArrow } from 'src/theme/Icon';

import {
  AccountMenuItems,
  NavItem,
  NavItems,
  SettingsMenuItems,
} from './navItems';

const MobileNavigation = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const isAuthed = useIsAuthed();
  const { send, state: sessionState } = useSession();
  const { send: sendFTUE } = useFTUEV2();
  const router = useRouter();

  const profile = sessionState?.context?.profile;

  const { t } = useTranslation();
  const { track } = useAnalytics();

  const NAV_ITEMS: NavItem[] = NavItems(isAuthed, profile);
  const ACCOUNT_MENU_ITEMS: NavItem[] = AccountMenuItems(isAuthed);
  const SETTINGS_MENU_ITEMS: NavItem[] = SettingsMenuItems(isAuthed);
  const { authMaintenance, isFTUEV2Enabled } = useFeatureFlags();

  // This will close the Drawer whenever the route change event fired, making sure that any links clicked in the Drawer cause it to
  // close. This is easier than passing `onClose()` to every Button and Link in this Drawer
  useEffect(() => {
    const handleRouteChange = () => {
      if (isOpen) {
        onClose();
      }
    };

    router.events.on('routeChangeStart', handleRouteChange);

    // If the component is unmounted, unsubscribe
    // from the event with the `off` method:
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router.events, onClose, isOpen]);

  const handleSignIn = () => {
    send('SIGN_IN');
    track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_SIGN_IN_CLICK);
  };

  return (
    <>
      <Flex as='button' onClick={onOpen}>
        <IconHamburgerMenu w={6} h={6} />
      </Flex>
      <Drawer isOpen={isOpen} onClose={onClose} placement='left' size='full'>
        <DrawerOverlay />
        <DrawerContent height='100%'>
          <DrawerBody px={6} py={9}>
            <Flex justifyContent='space-between' direction='column'>
              <Stack direction='column' gridGap='4'>
                <Flex alignItems='center' flexDirection='row'>
                  <DrawerCloseButton
                    position='inherit'
                    w='16px'
                    h='16px'
                    ml={1}
                    size='lg'
                  />
                  <Link alignSelf='center' href={home()}>
                    <NflAllDayLogo />
                  </Link>
                </Flex>
                {profile && (
                  <>
                    <UserInfoCardCompact
                      marginBottom='24px'
                      // @ts-ignore
                      profileImageUrl={profile.profileImageUrl}
                      // @ts-ignore
                      username={profile.username}
                    />
                    <Divider />
                  </>
                )}
                {ACCOUNT_MENU_ITEMS.map(
                  ({ children, trackingId, ...props }) => (
                    <Link
                      key={props.href}
                      trackingId={trackingId}
                      variant='nav-link-menu-item'
                      {...props}
                    >
                      {children}
                    </Link>
                  ),
                )}
                {SETTINGS_MENU_ITEMS.map(
                  ({ children, trackingId, ...props }) => (
                    <Link
                      key={props.href}
                      trackingId={trackingId}
                      variant='nav-link-menu-item'
                      {...props}
                    >
                      {children}
                    </Link>
                  ),
                )}
                {isAuthed && (
                  <>
                    <HStack>
                      <Text
                        pr='0'
                        pl='0'
                        onClick={() => {
                          send('SIGN_OUT');
                        }}
                      >
                        {t('NavigationBar.NavItem.signOut')}
                      </Text>
                      <IconRightArrow w='24px' h='24px' pl='4px' />
                    </HStack>
                    <Divider />
                  </>
                )}
                {NAV_ITEMS.map(
                  ({
                    children,
                    trackingId,
                    trackingProperties,
                    hasExternalLinkIcon,
                    ...props
                  }) => (
                    <Link
                      key={props.href}
                      trackingId={trackingId}
                      trackingProperties={trackingProperties}
                      variant='mobile-nav-link-menu-item'
                      {...props}
                    >
                      {children}
                      {hasExternalLinkIcon && (
                        <IconExternalLink
                          w='16px'
                          h='16px'
                          ml={2}
                          color='grey.text'
                        />
                      )}
                    </Link>
                  ),
                )}
              </Stack>

              {!isAuthed && (
                <Flex flexDir='column'>
                  <Button
                    display={['block', 'block', 'none']}
                    w='100%'
                    h='32px'
                    disabled={authMaintenance}
                    onClick={handleSignIn}
                    variant='ghost'
                  >
                    {t('NavigationBar.Auth.login')}
                  </Button>
                  {
                    // @ts-ignore
                    isFTUEV2Enabled && !profile?.hasCompletedFTUE ? (
                      <Button
                        display={['block', 'block', 'none']}
                        w='100%'
                        h='32px'
                        disabled={authMaintenance}
                        onClick={() => sendFTUE(EVENTS.START)}
                        trackingId={SEGMENT_EVENTS_ID_ENUM.FTUEV2_NAV_CTA}
                      >
                        {t('NavigationBar.Auth.signUp')}
                      </Button>
                    ) : (
                      <Button
                        display={['block', 'block', 'none']}
                        w='100%'
                        h='32px'
                        disabled={authMaintenance}
                        onClick={handleSignIn}
                      >
                        {t('NavigationBar.Auth.signUp')}
                      </Button>
                    )
                  }
                </Flex>
              )}
            </Flex>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  );
};

export default MobileNavigation;
