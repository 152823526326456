import React from 'react';
import { useFeatureFlags } from 'src/lib/launchDarkly';
import { useTranslation } from 'src/lib/translations';
import { Box, Button, Tooltip } from 'src/theme';
import { ListForSaleButtonProps } from 'src/theme/dss-skeleton/components';

export const TransactionsModalButton = ({
  stage,
  error,
  price,
  onClick,
  buttonProps,
  label,
  bg = 'transparent',
}: ListForSaleButtonProps) => {
  const { t } = useTranslation();
  const { isAllowedFullPlay } = useFeatureFlags();
  return isAllowedFullPlay ? (
    <Button
      {...buttonProps}
      w='100%'
      bg={bg}
      disabled={!!error || price === ''}
      onClick={onClick}
    >
      {t(label)}
    </Button>
  ) : (
    <Tooltip
      sx={{ display: 'flex' }}
      label={t(`ListForSale.button.disabled`)}
      placement='top'
    >
      <Box>
        <Button {...buttonProps} w='100%' bg={bg} disabled onClick={onClick}>
          {t(label)}
        </Button>
      </Box>
    </Tooltip>
  );
};
