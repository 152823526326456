import * as React from 'react';
import { Button, forwardRef, useStyles } from 'src/theme/index';
import type {
  HTMLChakraProps,
  ThemingProps,
  TrackingProps,
} from 'src/theme/index';

export interface TopNavButtonProps
  extends TrackingProps,
    HTMLChakraProps<'button'>,
    ThemingProps<'button'> {
  children: string | React.ReactNode;
}

export const TopNavButton = forwardRef<TopNavButtonProps, 'button'>(
  ({ variant, children, ...props }, ref) => {
    const styles = useStyles();

    return (
      <Button ref={ref} sx={styles.button} variant={variant} {...props}>
        {children}
      </Button>
    );
  },
);
