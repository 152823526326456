import { CommonTheme } from './common';
import { RarityTierTheme } from './RarityTier';

export enum HERO_SECTION_THEME_KEYS {
  COMMON = 'HeroSection/common',
  RARITY_TIER = 'HeroSection/RarityTier',
}

export const HeroSectionTheme = {
  [HERO_SECTION_THEME_KEYS.RARITY_TIER]: RarityTierTheme,
  [HERO_SECTION_THEME_KEYS.COMMON]: CommonTheme,
};
