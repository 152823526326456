import { IAnnouncementFields } from '__generated__/contentful';
import { EntryCollection } from 'contentful';
import { QueryState } from 'src/general/constants/finiteStates';

import useGetEntries from '../useGetEntries';

export interface AnnouncementReturnProps {
  isActive: boolean;
  message: string;
  state: QueryState;
  title: string;
  updatedAt: string;
}

export const useGetAnnouncement = (locale: string): AnnouncementReturnProps => {
  const { data, state } = useGetEntries('announcement', {
    include: 1,
    locale,
    'sys.id': 'ANNOUNCEMENT',
  });
  const entries = data as EntryCollection<IAnnouncementFields>;

  const title = entries?.items?.[0]?.fields?.title ?? '';
  const message = entries?.items?.[0]?.fields?.message ?? '';
  const isActive = entries?.items?.[0]?.fields.isActive ?? false;
  const updatedAt = entries?.items?.[0]?.sys.updatedAt ?? '';

  return {
    isActive,
    message,
    state,
    title,
    updatedAt,
  };
};
