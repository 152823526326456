import { gql } from '@apollo/client';
import { encodeBase64 } from '@dapperlabs/skeleton-core';
export const AUTHORIZE_RESERVATION_MUTATION = gql`
  mutation authorizePackPurchase($input: AuthorizePackPurchaseInput!) {
    authorizePackPurchase(input: $input) {
      signature
    }
  }
`;
export const AUTHORIZE_CUSTOMER_SUPPORT_RESERVATION_MUTATION = gql`
  mutation authorizeCustomerSupportReservation(
    $input: AuthorizeCustomerSupportReservationInput!
  ) {
    authorizeCustomerSupportReservation(input: $input) {
      signature
    }
  }
`;
export const AUTHORIZE_RESERVATION_VARIABLES = ({ signable }): any => {
  return {
    input: {
      signable: encodeBase64(JSON.stringify(signable.voucher)),
    },
  };
};

export const AUTHORIZE_CUSTOMER_SUPPORT_RESERVATION_VARIABLES = ({
  signable,
}): any => {
  return {
    input: {
      signable: encodeBase64(JSON.stringify(signable.voucher)),
    },
  };
};
