import { ComponentStyleConfig } from '@chakra-ui/react';

const theme: ComponentStyleConfig = {
  baseStyle: {
    block: {
      background: 'black',
      borderColor: '#454543',
      borderRadius: '16px 16px 0 0',
      borderWidth: '1px 0 0 0',
      bottom: '0',
      display: 'flex',
      flexDirection: 'column',
      left: '0',
      padding: '1.5rem 1rem',
      position: 'fixed',
      width: '100%',
      zIndex: '999999',
    },
    closeButton: {
      alignSelf: 'flex-end',
      background: 'rgba(23, 23, 27, 0.36)',
      borderRadius: '100%',
      height: '3rem',
      width: '3rem',
    },
    link: {
      marginTop: '2rem',
      textDecoration: 'underline',
    },
  },
  defaultProps: {},
  parts: ['link', 'block', 'closeButton'],
};

export const READ_MORE_LINK_THEME_KEY = 'ReadMoreLink';
export const ReadMoreLinkTheme = {
  [READ_MORE_LINK_THEME_KEY]: theme,
};
