import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import es from 'javascript-time-ago/locale/es';
import React, { useMemo } from 'react';
import { useTranslation } from 'src/lib/translations';
import { Grid, Text, Heading, CloseButton, Flex, Slide, Fade } from 'src/theme';
import { MegaphoneIcon } from 'src/theme/Icon';

TimeAgo.addLocale(es);
TimeAgo.addDefaultLocale(en);

interface AnnouncementCardProps {
  isOpen: boolean;
  locale: string;
  message: string;
  onClose: () => any;
  title: string;
  updatedAt: string;
}

export const AnnouncementCard = ({
  onClose,
  title,
  locale,
  message,
  updatedAt,
  isOpen,
}: AnnouncementCardProps) => {
  const { t } = useTranslation();

  const relativeDate = useMemo(() => {
    if (!updatedAt) return '';

    const timeAgo = new TimeAgo(locale);
    const updatedAtDate = new Date(updatedAt);

    return timeAgo.format(updatedAtDate);
  }, [locale, updatedAt]);

  return (
    <>
      <Fade in={isOpen} style={{ zIndex: 2 }} unmountOnExit>
        <Flex
          position='fixed'
          zIndex='2'
          top={0}
          right={0}
          bottom={0}
          left={0}
          display={{ base: 'flex', md: 'none' }}
          backdropFilter='blur(7.5px)'
          backgroundColor='whiteAlpha.300'
        />
      </Fade>
      <Slide direction='bottom' in={isOpen} style={{ zIndex: 3 }} unmountOnExit>
        <Flex justifyContent='flex-end'>
          <Flex
            position='relative'
            justifyContent='center'
            flexDirection='column'
            maxW={{ base: 'none', md: '300px' }}
            pt={{ base: 6, md: 9 }}
            pb={{ base: 8, md: 12 }}
            px={{ base: 4, md: 6 }}
            borderWidth='1px'
            borderColor='gray.800'
            borderBottomColor={{ base: 'none', md: 'gray.800' }}
            borderTopLeftRadius={{ base: '3xl', md: 'lg' }}
            borderTopRightRadius={{ base: '3xl', md: 'lg' }}
            backgroundColor='black'
          >
            <CloseButton
              position='absolute'
              top={{ base: 4, md: 6 }}
              right={{ base: 4, md: 3 }}
              color='white'
              onClick={onClose}
              size='lg'
            />
            <Grid gap={3}>
              <Flex
                alignItems='center'
                justifySelf='flex-start'
                px={2}
                py={2}
                borderRadius='md'
                backgroundColor='blue.800'
              >
                <MegaphoneIcon w={4} h={4} mr={1.5} color='blue.300' />
                <Text color='blue.300' fontFamily='mono' fontSize='sm'>
                  {t('GlobalAnnouncement.Announcement')}
                </Text>
              </Flex>
              <Heading size='md'>{title}</Heading>
              <Text fontSize='lg'>{message}</Text>
            </Grid>
            {!!relativeDate && (
              <Text mt={{ base: 8, md: 16 }} fontSize='sm'>
                {relativeDate}
              </Text>
            )}
          </Flex>
        </Flex>
      </Slide>
    </>
  );
};
