import {
  RewardPassTier,
  TaskCategory,
  TaskType,
} from '__generated__/globalTypes';
import { isFuture, isPast } from 'date-fns';
import { utcToZonedTime, format } from 'date-fns-tz';
import every from 'lodash/every';
import filter from 'lodash/filter';
import keyBy from 'lodash/keyBy';
import last from 'lodash/last';
import sortBy from 'lodash/sortBy';
import sumBy from 'lodash/sumBy';
import takeWhile from 'lodash/takeWhile';
import xor from 'lodash/xor';
import { imageOptimizerCDN } from 'src/lib/ImageOptimizer';
import {
  GetActiveRewardPass_getActiveRewardPass_tasks as Task,
  GetActiveRewardPass_getActiveRewardPass_levels as Level,
  GetActiveRewardPass_getActiveRewardPass_levels_rewards as LevelReward,
} from 'src/modules/RewardPass/queries/__generated__/GetActiveRewardPass';
import { GetUserProgress_getUserProgress_tasks as UserTask } from 'src/modules/RewardPass/queries/__generated__/GetUserProgress';
import {
  DecoratedUserRewardPass,
  DecoratedUserTask,
  TaskAvailability,
  TaskGroup,
} from 'src/modules/RewardPass/types';

import { searchChallenges_allChallenges_searchChallenges_edges_node } from '../Challenges/queries/__generated__/searchChallenges_allChallenges';

export const getLevelByPoints = (points: number, levels: Level[]): Level => {
  const levelsAchieved = takeWhile(
    levels,
    ({ requiredPoints }) => points >= requiredPoints,
  );
  return last(levelsAchieved);
};

export const getNextLevelWithEligibleReward = (
  levels: Level[],
  currentPoints?: number,
  userTier?: RewardPassTier,
): Level => {
  const sortedLevels = [...levels].sort((level) => level.requiredPoints);

  /**
   * if there's no user data, we return the first level
   */
  if (!userTier) return sortedLevels[0];

  /**
   * find which level has the next reward a user is eligble for
   */
  return sortedLevels.find((level) => {
    /**
     * users on the 'premium' path are eligible for all rewards
     */
    if (userTier === RewardPassTier.PREMIUM) {
      return level.requiredPoints > currentPoints;
    }

    /**
     * checks if the level has a reward matching the user's tier
     */
    return (
      level.requiredPoints > currentPoints &&
      !!level.rewards.find((reward) => reward.tier === userTier)
    );
  });
};

export const getBestEligibleReward = (
  rewards: LevelReward[],
  userTier?: RewardPassTier,
): LevelReward => {
  if (!rewards?.length) return;
  if (!userTier) {
    // if there is no user data, prefer to return a free reward
    const freeReward = rewards.find(
      (reward) => reward.tier === RewardPassTier.GRATIS,
    );
    if (freeReward) return freeReward;
    return rewards[0];
  }

  // if there's a reward matching the user's tier, return it
  const rewardMatchingUserTier = rewards.find(
    (reward) => reward.tier === userTier,
  );

  if (rewardMatchingUserTier) {
    return rewardMatchingUserTier;
  }

  // if the user is premium and there's no premium reward, return the free one
  if (userTier === RewardPassTier.PREMIUM) {
    return rewards[0];
  }
};

export const getUpgradeTask = (
  tasks: DecoratedUserTask[],
): DecoratedUserTask => {
  return tasks.find((task) => task.type === TaskType.UPGRADE);
};

export const getMergedTasks = (
  tasks: Task[],
  userTasks?: UserTask[],
  challenges?: searchChallenges_allChallenges_searchChallenges_edges_node[],
): DecoratedUserTask[] => {
  const challengesMap = keyBy(challenges, 'id');
  const userTasksBySlug = keyBy(userTasks, 'taskSlug');
  return tasks?.map((task) => {
    const userTask = userTasksBySlug[task.slug];
    const challenge =
      task.category === TaskCategory.COMPLETE_CHALLENGE
        ? challengesMap[task.referenceID]
        : null;

    const startDate = new Date(
      challenge ? challenge.startDate : task.validFrom,
    );
    const endDate = new Date(
      challenge
        ? challenge.endDate
        : task.validTo ?? Date.now() + 60 * 60 * 1000,
    );

    let availability = TaskAvailability.AVAILABLE;
    if (userTask?.completedAt) {
      availability = TaskAvailability.COMPLETED;
    } else if (isFuture(startDate)) {
      availability = TaskAvailability.UPCOMING;
    } else if (isPast(endDate)) {
      availability = TaskAvailability.EXPIRED;
    }

    return {
      ...task,
      availability,
      challenge,
      userTask,
    };
  });
};

export const getGroupedTasks = (
  tasks: DecoratedUserTask[],
): (DecoratedUserTask | TaskGroup)[] => {
  const loginTasks = sortBy(filter(tasks, { category: TaskCategory.LOGIN }), [
    'task',
    'validFrom',
  ]);

  const parsedTasks: (DecoratedUserTask | TaskGroup)[] = xor(tasks, loginTasks);
  if (loginTasks.length) {
    const isCompleted = every(
      loginTasks,
      ({ userTask }) => !!userTask?.completedAt,
    );
    parsedTasks.push({
      availability: isCompleted
        ? TaskAvailability.COMPLETED
        : TaskAvailability.AVAILABLE,
      category: TaskCategory.LOGIN,
      description: 'playbook.taskGroups.dailyVisits.description',
      id: 'grouped-login',
      rewardPoints: sumBy(loginTasks, 'rewardPoints'),
      subtasks: loginTasks,
      title: 'playbook.taskGroups.dailyVisits.title',
      userTask: {
        completedAt: isCompleted && Date.toString(),
      },
    });
  }
  return parsedTasks;
};

export const userHasOpenLoginTasks = (
  rewardPass: DecoratedUserRewardPass,
): boolean => {
  return rewardPass.tasks.some((task) => {
    const now = new Date(Date.now());
    const validFrom = new Date(task.validFrom);
    const validTo = new Date(task.validTo ?? Date.now() + 60 * 60 * 1000); // If there is no validTo date we use a time in the future

    return (
      task.category === TaskCategory.LOGIN &&
      now > validFrom &&
      now < validTo &&
      !task.userTask
    );
  });
};

export const containerAnimation = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      delay: 0.3,
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

// @note: we want to cache the image for when the user pops the modal
export const getRewardImage = (imageUrl: string): string => {
  if (!imageUrl) return null;

  return imageOptimizerCDN({
    height: 200,
    quality: 80,
    src: imageUrl,
    width: 200,
  });
};

export const getRewardPassDate = (date, pattern = 'MMMM d, h:mm aa zzz') => {
  const timeZone = 'America/New_York';
  const zonedDate = utcToZonedTime(date, timeZone);
  return format(zonedDate, pattern, { timeZone });
};
