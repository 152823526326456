import {
  // DistributionState,
  // DistributionTier,
  // QueueStatus,
  EditionTier,
  DistributionImageType,
} from '__generated__/globalTypes';
import merge from 'lodash/merge';
import { dateExampleGenerator } from 'src/lib/helpers/dateLib';
import type { PartialDeep } from 'type-fest';

import { searchDistributionsV2_withoutEditions_searchDistributionsV2_edges } from '../queries/__generated__/searchDistributionsV2_withoutEditions';

const DISTRIBUTION_ID = 123;

const preQueueStartTime = dateExampleGenerator(5, 'hours', 'past');
const endTime = dateExampleGenerator(1, 'months', 'future');

export const distributionFactory = (
  overrides: PartialDeep<searchDistributionsV2_withoutEditions_searchDistributionsV2_edges> = {},
): searchDistributionsV2_withoutEditions_searchDistributionsV2_edges =>
  merge(
    {},
    {
      __typename: 'DistributionEdge',
      cursor: '',
      node: {
        __typename: 'Distribution',
        code: 'sample_pack_historic_premium',
        description: '',
        editionIds: [20, 22, 21],
        endTime,
        id: DISTRIBUTION_ID,
        images: [
          {
            __typename: 'DistributionImage',
            type: DistributionImageType.DEFAULT,
            url: 'https://assets.laligagolazos.com/packs/eterno_rival_historic_premium/images/pack.png',
          },
          {
            __typename: 'DistributionImage',
            type: DistributionImageType.BACKGROUND,
            url: 'https://assets.laligagolazos.com/packs/eterno_rival_historic_premium/images/pack_background.png',
          },
        ],
        numberOfPackSlots: 3000,
        packOdds: [
          {
            __typename: 'DistributionOdds' as const,
            displayValue: '0 out of 100 packs',
            tier: EditionTier.LEGENDARY,
            value: 0,
          },
          {
            __typename: 'DistributionOdds' as const,
            displayValue: '0 out of 100 packs',
            tier: EditionTier.RARE,
            value: 0,
          },
          {
            __typename: 'DistributionOdds' as const,
            displayValue: '100 out of 100 packs',
            tier: EditionTier.COMMON,
            value: 100,
          },
        ],
        price: {
          currency: 'USD',
          value: '199.99000000',
        },
        queueID: '3d5bef9e-a22b-4cf7-a51a-3b2147bc49a4',
        requirements: null,
        startTime: preQueueStartTime,
        state: 'Complete',
        tier: 'HISTORIC_PREMIUM',
        title: 'Sample Pack2',
        txHash:
          '462702fde4181e46895eba1046d52e80808b118138071908c59af1ccef25731c',
        videos: [
          {
            __typename: 'DistributionVideo',
            type: 'RIP',
            url: 'https://assets.laligagolazos.com/packs/eterno_rival_historic_premium/videos/pack_opening.mp4',
          },
        ],
      },
    },
    overrides,
  );
