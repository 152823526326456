import React from 'react';
import CircleAvatar from 'src/general/components/CircleAvatar/CircleAvatar';
import { Flex, TextDeprecated, Stack } from 'src/theme';

export const smallGreyText = {
  color: 'grey.text',
  minW: 0,
  textStyle: 'body14',
  variant: 'light',
};

/**
 * Consolidated UserInfoCardColumn + UserInfoCardRow
 *
 * @param props.profileImageUrl - the image URL
 * @param props.username - the user's username
 */
export const UserInfoCardCompact = ({
  profileImageUrl,
  username,
  ...rest
}): JSX.Element => (
  <Flex align='center' gridGap={{ base: 4, sm: 6 }} {...rest}>
    {/* Avatar */}
    {!!profileImageUrl && (
      <CircleAvatar
        name={username}
        size={{ base: 'md' }}
        src={profileImageUrl}
      />
    )}

    {/* User info */}
    <Stack overflow='hidden'>
      <TextDeprecated as='h2' fontSize='xl' fontWeight={600} noOfLines={1}>
        {username}
      </TextDeprecated>

      <TextDeprecated {...smallGreyText} textStyle='body12' noOfLines={1}>
        @{username}
      </TextDeprecated>
    </Stack>
  </Flex>
);
