import { ComponentStyleConfig } from '@chakra-ui/react';

const theme: ComponentStyleConfig = {
  baseStyle: {
    badgeIcon: {
      height: '100%',
      maxWidth: '350px',
      width: '100%',
    },
    copyrightTextWrapper: {
      columnSpan: { base: 3, lg: 3, md: 2, sm: 4 },
      display: 'flex',
      flexDir: 'column',
      gridArea: 'copyright',
      marginBottom: { base: '16px', md: '0px' },
      padding: { base: '0px 16px', md: '0px 24px' },
    },
    gridWrapper: {
      gridTemplateAreas: {
        base: `"icons socialMediaIcons"
                "secondRow secondRow"
                "copyright copyright"
                "policyTerms policyTerms"`,
        md: `"icons icons socialMediaIcons socialMediaIcons"
                        "secondRow secondRow secondRow secondRow"
                        "copyright copyright copyright policyTerms"`,
        sm: `"icons icons socialMediaIcons socialMediaIcons"
                        "secondRow secondRow secondRow secondRow"
                        "copyright copyright copyright copyright"
                        "policyTerms policyTerms policyTerms policyTerms"`,
      },
      gridTemplateColumns: { base: 'auto auto', sm: 'repeat(4, 1fr)' },
      gridTemplateRows: 'auto',
      overflow: 'hidden',
      padding: {
        base: '32px 0px',
      },
    },
    linkText: {
      color: 'brand.base',
      fontWeight: '800',
      marginBottom: '12px',
      textDecoration: 'none',
      textTransform: 'uppercase',
    },
    linksRow: {
      gridTemplateAreas: {
        base: `"navItems"
                     "navLinks"
                     "badgeIcon"`,
        lg: `"navItems navLinks badgeIcon badgeIcon"`,
        md: `"navItems navLinks"
                   "badgeIcon badgeIcon"`,
        sm: `"navItems navLinks"
                   "badgeIcon badgeIcon"`,
      },
      gridTemplateColumns: {
        base: '1fr',
        lg: 'auto auto 1fr',
        md: 'auto 1fr',
        sm: 'auto 1fr',
      },
      gridTemplateRows: {
        base: 'repeat(3, auto)',
        lg: '1fr',
        md: '1fr 1fr',
        sm: '1fr 1fr',
      },
    },
    linksRowWrapper: {
      borderColor: 'gray.700',
      borderWidth: '1px 0 1px 0',
      columnSpan: '4',
      gridArea: 'secondRow',
      margin: { base: '24px 0px' },
      padding: { base: '32px 16px', md: '42px 24px' },
    },
    linksTextWrapper: {
      display: 'flex',
      flexDir: 'column',
      gridArea: 'navItems',
      marginRight: { base: '12px', sm: '64px' },
    },
    linksWrapper: {
      display: 'flex',
      flexDir: 'column',
      gridArea: 'navLinks',
    },
    logoIcon: {
      alignItems: 'center',
      display: 'flex',
      gridArea: 'icons',
      height: '32px',
      padding: { base: '0px 16px', md: '0px 24px' },
      svg: {
        height: '32px',
        width: '130px',
      },
      width: '130px',
    },
    navLink: {
      alignItems: 'center',
      color: 'brand.base',
      display: 'flex',
      letterSpacing: '0.5px',
      marginBottom: '12px',
      svg: {
        height: '12px',
        marginLeft: '12px',
        width: '12px',
      },
      textDecoration: 'none',
    },
    policyTerms: {
      columnSpan: { base: 4, lg: 1, md: 2, sm: 4 },
      display: 'flex',
      flexDirection: {
        base: 'column',
        sm: 'row',
      },
      gridArea: 'policyTerms',
      height: 'fit-content',
      justifySelf: { base: 'start', md: 'end' },
      padding: { base: '0px 16px', md: '0px 24px' },
    },
    policyTermsDivider: {
      display: {
        base: 'none',
        sm: 'inline',
      },
    },
    rightPartWrapper: {
      display: 'flex',
      gridArea: 'badgeIcon',
      justifyContent: {
        base: 'flex-start',
        lg: 'flex-end',
        md: 'flex-start',
      },
      marginTop: {
        base: '18px',
        lg: '0px',
        md: '18px',
      },
    },
    root: {
      margin: '0 auto',
      maxWidth: '1236px',
    },
    socialMediaIcon: {
      '&:hover': {
        path: {
          fill: 'brand.base',
        },
      },
      '&:not(:last-child)': {
        marginRight: '18px',
      },
      cursor: 'pointer',
      height: '20px',
      svg: {
        color: 'gray.300',
        height: '100%',
        path: {
          fill: '#8a94a6',
          transition: 'fill 0.15s',
        },
        width: '100%',
      },
      width: '20px',
    },
  },
};

export const FOOTER_THEME_KEY = 'Footer';
export const FooterTheme = {
  [FOOTER_THEME_KEY]: theme,
};
