import React, { useMemo } from 'react';
import {
  Button,
  forwardRef,
  useStyles,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Flex,
} from 'src/theme/index';

import { ArrowBottomIcon, CheckIcon } from '../../themes/icons';

type Value = {
  label: string;
  value: string;
};

export interface TopNavSelectProps {
  icon: React.ReactNode;
  items?: Array<Value>;
  onChange: (value: string) => void;
  onMenuClick?: () => void;
  style?: any;
  value: string;
}

export const TopNavSelect = forwardRef<TopNavSelectProps, 'select'>(
  (
    { items = [], value, icon, onChange, onMenuClick = () => {}, style },
    _ref,
  ) => {
    const styles = useStyles();

    const _renderMenuItems = () => {
      return items.map((item) => (
        <MenuItem key={item?.value} onClick={() => onChange(item.value)}>
          {item?.label}
          {item.value === value && (
            <Flex alignItems='center' marginLeft='auto'>
              <CheckIcon width='16px' height='16px' />
            </Flex>
          )}
        </MenuItem>
      ));
    };

    const selectedItem = useMemo(
      () => items.find((item) => item.value === value),
      [items, value],
    );

    return (
      <Menu>
        <MenuButton
          as={Button}
          sx={styles.menuButton}
          width={['100%', 'auto', 'auto']}
          px={[8, 0, 0]}
          leftIcon={icon ? icon : null}
          onClick={onMenuClick}
          rightIcon={<ArrowBottomIcon sx={styles.menuButtonIcon} ml='10px' />}
          style={style}
        >
          <Text sx={styles.selectLabel}>{selectedItem?.label}</Text>
        </MenuButton>
        <MenuList sx={styles.menuList}>{_renderMenuItems()}</MenuList>
      </Menu>
    );
  },
);
