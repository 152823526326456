import { gql } from '@apollo/client';
import { sansPrefix } from '@onflow/fcl';

import { SortDirection } from './generated';
import { SearchGolazosNftAggregationQueryVariables } from './searchAggregation.generated';
import { NonEmptyString } from './utilTypes';

export const SEARCH_AGGREGATION = gql`
  query searchGolazosNftAggregation(
    $searchInput: SearchGolazosNftAggregationsInput
  ) {
    searchGolazosNftAggregation(searchInput: $searchInput) {
      edges {
        node {
          id {
            count
          }
          listing {
            price {
              min
              max
              avg
              count
            }
          }
          edition {
            id {
              key
            }
            max_mintings {
              value
            }
            play {
              id {
                value
              }
              classification {
                value
              }
              metadata {
                match_away_score {
                  value
                }
                match_away_team {
                  value
                }
                match_day {
                  value
                }
                match_highlighted_team {
                  value
                }
                match_home_score {
                  value
                }
                match_home_team {
                  value
                }
                match_season {
                  value
                }
                play_data_id {
                  value
                }
                play_half {
                  value
                }
                play_time {
                  value
                }
                play_type {
                  value
                }
                player_country {
                  value
                }
                player_data_id {
                  value
                }
                player_first_name {
                  value
                }
                player_jersey_name {
                  value
                }
                player_known_name {
                  value
                }
                player_last_name {
                  value
                }
                player_number {
                  value
                }
                player_position {
                  value
                }
              }
            }
            series {
              id {
                value
              }
              name {
                value
              }
            }
            set {
              name {
                value
              }
            }
            tier {
              value
            }
            total_burned {
              value
            }
            total_minted {
              value
            }
          }
        }
      }
      totalCount
    }
  }
`;

export const SEARCH_AGGREGATION_COUNT = gql`
  query searchGolazosNftAggregation_count(
    $searchInput: SearchGolazosNftAggregationsInput
  ) {
    searchGolazosNftAggregation(searchInput: $searchInput) {
      edges {
        node {
          id {
            count
          }
          edition {
            id {
              key
            }
          }
        }
      }
    }
  }
`;

export const searchAggregationByEditionID = (
  momentEditionID: NonEmptyString<string>,
): SearchGolazosNftAggregationQueryVariables => {
  const dapperUtilityCoinAddress =
    process.env.NEXT_PUBLIC_DAPPER_UTILITY_COIN_ADDRESS.split('0x')[1];
  const vault_type = `A.${dapperUtilityCoinAddress}.DapperUtilityCoin.Vault`;
  return {
    searchInput: {
      filters: [
        {
          edition: {
            id: {
              eq: momentEditionID,
            },
          },
          listing: {
            custom_id: { eq: 'DAPPER_MARKETPLACE', ignore_case: true },
            exists: true,
            ft_vault_type: {
              eq: vault_type,
            },
          },
          type_name: {
            eq: `A.${sansPrefix(
              process.env.NEXT_PUBLIC_NFT_ADDRESS,
            )}.Golazos.NFT`,
          },
        },
      ],
      first: 1,
    },
  };
};

export const searchAggregationByEditionIDs = (
  editionIds: NonEmptyString<string>[],
): SearchGolazosNftAggregationQueryVariables => {
  return {
    searchInput: {
      filters: [
        {
          edition: {
            id: {
              in: editionIds,
            },
          },
          type_name: {
            eq: `A.${sansPrefix(
              process.env.NEXT_PUBLIC_NFT_ADDRESS,
            )}.Golazos.NFT`,
          },
        },
      ],
      first: 1000,
      sortBy: {
        edition: {
          id: { key: { direction: SortDirection.Asc, priority: 3 } },
          play: {
            metadata: {
              player_first_name: {
                key: {
                  direction: SortDirection.Asc,
                  ignore_case: true,
                  priority: 2,
                },
              },
            },
          },
          tier: {
            key: {
              direction: SortDirection.Asc,
              ignore_case: true,
              priority: 1,
            },
          },
        },
      },
    },
  };
};

const searchAggregationByEditionIDandOwnerAddresses = (
  momentEditionID: NonEmptyString<string>,
  ownerAddress: string[],
): SearchGolazosNftAggregationQueryVariables => ({
  searchInput: {
    filters: [
      {
        burned_at: { exists: false },
        edition: {
          id: {
            eq: momentEditionID,
          },
        },
        owner_address: {
          in: ownerAddress,
        },
        type_name: {
          eq: `A.${sansPrefix(
            process.env.NEXT_PUBLIC_NFT_ADDRESS,
          )}.Golazos.NFT`,
        },
      },
    ],
  },
});

export const searchForSaleAggregationByEditionID = (
  momentEditionID: NonEmptyString<string>,
): SearchGolazosNftAggregationQueryVariables => {
  return {
    searchInput: {
      filters: [
        {
          burned_at: { exists: false },
          edition: {
            id: {
              eq: momentEditionID,
            },
          },
          listing: { exists: true },
          type_name: {
            eq: `A.${sansPrefix(
              process.env.NEXT_PUBLIC_NFT_ADDRESS,
            )}.Golazos.NFT`,
          },
        },
      ],
    },
  };
};

export const searchHiddenAggregationByEditionID = (
  momentEditionID: NonEmptyString<string>,
) => {
  return searchAggregationByEditionIDandOwnerAddresses(momentEditionID, [
    sansPrefix(process.env.NEXT_PUBLIC_IPACK_NFT_ADDRESS),
  ]);
};

export const searchReservedAggregationByEditionID = (
  momentEditionID: NonEmptyString<string>,
) => {
  return searchAggregationByEditionIDandOwnerAddresses(momentEditionID, [
    sansPrefix(process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS),
  ]);
};

export const searchOwnedAggregationByEditionID = (
  momentEditionID: NonEmptyString<string>,
) => {
  return searchAggregationByEditionIDandOwnerAddresses(momentEditionID, [
    sansPrefix(process.env.NEXT_PUBLIC_IPACK_NFT_ADDRESS),
    sansPrefix(process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS),
  ]);
};
