import { ComponentStyleConfig } from '@chakra-ui/react';

const theme: ComponentStyleConfig = {
  baseStyle: {
    container: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      gap: '4',
      justifyContent: 'center',
      overflow: 'hidden',
      width: 'calc(100%)',
    },
    paginationItem: {
      '&.activeSlide': {
        backgroundColor: 'gray.50',
      },
      backgroundColor: 'gray.700',
      cursor: 'pointer',
      display: 'block',
      flex: '1 1 0',
      flexBasis: '0',
      height: '4px',
    },
    paginationNav: {
      cursor: 'pointer',
      display: ['none', 'none', 'unset'],
      height: '8',
      width: '8',
    },
    paginationWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      gap: '2',
      justifyContent: 'center',
      width: '100%',
    },
    preview: {
      alignItems: 'center',
      bottom: '0.5rem',
      display: 'flex',
      height: '80px',
      justifyContent: 'center',
      left: 'calc(1.5rem - 40px)',
      position: 'absolute',
      width: '80px',
    },
    previewBg: {
      position: 'absolute',
      zIndex: 10,
    },
    previewImg: {
      height: '50px',
      objectFit: 'contain',
      width: '50px',
      zIndex: 20,
    },
    slide: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      minWidth: 'calc(100%)',
      position: 'relative',
      width: 'calc(100%)',
    },
    slidesWrapper: {
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'row',
      width: 'calc(100%)',
    },
  },
  defaultProps: {},
  parts: [
    'container',
    'slidesWrapper',
    'slide',
    'preview',
    'previewBg',
    'previewImg',
    'paginationItem',
    'paginationNav',
    'paginationWrapper',
  ],
};

export const CAROUSEL_THEME_KEY = 'CarouselDeprecated';
export const CarouselTheme = {
  [CAROUSEL_THEME_KEY]: theme,
};
