export enum Status {
  ENDED = 'ENDED',
  LIVE = 'LIVE',
  PREVIEW = 'PREVIEW',
  SOLD_OUT = 'SOLD_OUT',
}

// # derived based on availableSupply and endTime
export enum DistributionAvailability {
  // availableSupply > 0 & endTime in the past
  DISCONTINUED = 'DISCONTINUED',
  // availableSupply > 0
  IN_STOCK = 'IN_STOCK',
  // availableSupply = 0
  OUT_OF_STOCK = 'OUT_OF_STOCK',
}

// # derived based on startTime and endTime
export enum DistributionPhase {
  ENDED = 'ENDED',
  PREVIEW = 'PREVIEW',
  PRE_QUEUE = 'PRE_QUEUE',
  STARTED = 'STARTED',
}
