import { useAnalytics } from '@dapperlabs/react-analytics';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { useSession } from 'src/edge/session';

import sendTrackPage from './sendTrackPage';

const useTrackPage = (): void => {
  const { trackPageView, analyticsReady: isAnalyticsReady } = useAnalytics();
  const { pathname } = useRouter();

  const { state } = useSession();
  const isLoading = state.matches('LOADING');

  // Client side tracking of the page.
  useEffect(() => {
    if (!pathname || !isAnalyticsReady) {
      return;
    }
    trackPageView();
  }, [pathname, isAnalyticsReady, trackPageView]);

  // Server side tracking of the page.
  useEffect(() => {
    if (isLoading || !pathname) {
      return;
    }

    try {
      sendTrackPage();
    } catch (e) {
      //empty catch
    }
  }, [isLoading, pathname]);
};
export { useTrackPage };
