import NextJsLink, { LinkProps as NextLinkProps } from 'next/link';
import { forwardRef, PropsWithChildren } from 'react';

import {
  LinkDeprecatedProps as ThemeLinkProps,
  LinkDeprecated as ThemeLink,
} from 'src/theme';

export type Props = PropsWithChildren<
  Omit<NextLinkProps, 'passHref'> & Omit<ThemeLinkProps, 'as'>
>;

// Based on:
// https://github.com/chakra-ui/chakra-ui/blob/bb0356f10e14886a19072b68f9e4999c6a3bfc1d/examples/nextjs-typescript/components/NextChakraLink.tsx
//

// Has to be a new component because both chakra and next share the `as` keyword.
const Link = forwardRef<HTMLAnchorElement, Props>(
  (
    {
      href,
      as,
      replace,
      scroll,
      shallow,
      prefetch,
      locale,
      children,
      ...themeLinkProps
    },
    ref,
  ) => (
    <NextJsLink
      as={as}
      href={href}
      locale={locale}
      passHref
      prefetch={prefetch}
      replace={replace}
      scroll={scroll}
      shallow={shallow}
    >
      <ThemeLink ref={ref} {...themeLinkProps}>
        {children}
      </ThemeLink>
    </NextJsLink>
  ),
);
Link.displayName = 'Link';

export { Link };
