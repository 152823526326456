import {
  packs,
  userProfile,
  dapperWallet,
  dapperSettings,
  allDayMarketplace,
  marketplace,
  blog,
  challenges,
  challengesActive,
} from '@dapperlabs/core-fe';
import { useMemo } from 'react';
import { useSession } from 'src/edge/session';
import { useFeatureFlags } from 'src/lib/launchDarkly';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';
import { useTranslation } from 'src/lib/translations';
import { Props as LinkProps } from 'src/theme';

export type NavItem = LinkProps & {
  hasExternalLinkIcon?: boolean;
  isDecorated?: boolean;
};

export const NavItems = (isAuthed, profile) => {
  const { t } = useTranslation();

  const {
    isMarketplaceVisible,
    isChallengesEnabled,
    isVendorMarketplaceEnabled,
  } = useFeatureFlags();

  const AuthNavItems = {
    collection: {
      children: t('NavigationBar.NavItem.collection'),
      href: userProfile(profile?.username),
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_COLLECTION_CLICK,
    },
    marketplace: {
      children: t('NavigationBar.NavItem.marketplace'),
      href: allDayMarketplace(),
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_MARKETPLACE_CLICK,
    },
  };

  const AUTH_NAV_ITEMS: NavItem[] = useMemo(() => {
    const result = [];

    if (isMarketplaceVisible) {
      result.push(AuthNavItems.marketplace);
    }

    if (isAuthed) {
      result.push(AuthNavItems.collection);
    }

    return result;
  }, [isAuthed, isMarketplaceVisible]);

  // marketplace links and call outs are behind a feature flag: isVendorMarketplaceEnabled
  // https://app.zenhub.com/workspaces/nfl-engagement-squad--61de0aa283fa9500110c31af/issues/dapperlabs/nfl-api/1260
  const MARKETPLACE_NAV_ITEMS: NavItem[] = isVendorMarketplaceEnabled
    ? [
        {
          children: t('NavigationBar.NavItem.marketplace'),
          hasExternalLinkIcon: true,
          href: marketplace,
          target: '_blank',
          trackingId:
            SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_MARKETPLACE_CLICK,
        },
      ]
    : [];

  // inhouse challenge link is behind a feature flag: isChallengesEnabled
  const CHALLENGE_NAV_ITEMS: NavItem[] = isChallengesEnabled
    ? [
        {
          children: t('NavigationBar.NavItem.challenges'),
          href: challengesActive(),
          trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_CHALLENGES_CLICK,
        },
      ]
    : [
        {
          children: t('NavigationBar.NavItem.challenges'),
          href: challenges,
          trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_CHALLENGES_CLICK,
        },
      ];

  const NAV_ITEMS: NavItem[] = [
    {
      children: t('NavigationBar.NavItem.packs'),
      href: packs(),
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_PACKS_CLICK,
    },
    ...MARKETPLACE_NAV_ITEMS,
    ...AUTH_NAV_ITEMS,
    ...CHALLENGE_NAV_ITEMS,
    {
      children: t('NavigationBar.NavItem.blog'),
      href: blog,
      target: '_blank',
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_BLOG_CLICK,
    },
  ];

  return NAV_ITEMS;
};

export const AccountMenuItems = (isAuthed) => {
  const { t } = useTranslation();
  const { state } = useSession();
  // @ts-ignore
  const username = state?.context?.profile?.username;

  const ACCOUNT_MENU_ITEMS: NavItem[] = [
    {
      children: t('NavigationBar.NavItem.profile'),
      href: userProfile(username),
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_MYPROFILE_CLICK,
    },
    {
      children: t('NavigationBar.NavItem.dapperWallet'),
      href: dapperWallet,
      target: '_blank',
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_DAPPER_WALLET_CLICK,
    },
  ];
  return isAuthed ? ACCOUNT_MENU_ITEMS : [];
};

export const SettingsMenuItems = (isAuthed) => {
  const { t } = useTranslation();
  const SETTINGS_MENU_ITEMS: NavItem[] = [
    {
      children: t('NavigationBar.NavItem.settings'),
      href: dapperSettings,
      target: '_blank',
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_SETTINGS_CLICK,
    },
  ];
  return isAuthed ? SETTINGS_MENU_ITEMS : [];
};
