import Script from 'next/script';
import { useAnalytics } from 'src/lib/segment';

const MixpanelLastTouch = (): JSX.Element => {
  /* https://help.mixpanel.com/hc/en-us/articles/360001337103 */
  const script = `
    function getQueryParam(url, param) {
      // Expects a raw URL
      param = param.replace(/[[]/, "\\[").replace(/[]]/, "\\]");
      var regexS = "[\\?&]" + param + "=([^&#]*)",
          regex = new RegExp( regexS ),
          results = regex.exec(url);
      if (results === null || (results && typeof(results[1]) !== 'string' && results[1].length)) {
        return '';
        } else {
          return decodeURIComponent(results[1]).replace(/\\W/gi, ' ');
        }
    };
    function campaignParams() {
      var campaign_keywords = 'utm_source utm_medium utm_campaign utm_content utm_term'.split(' ')
          , kw = ''
          , params = {}
          , first_params = {};
      var index;
      for (index = 0; index < campaign_keywords.length; ++index) {
        kw = getQueryParam(document.URL, campaign_keywords[index]);
        if (kw.length) {
          params[campaign_keywords[index] + ' [last touch]'] = kw;
        }
      }
      for (index = 0; index < campaign_keywords.length; ++index) {
        kw = getQueryParam(document.URL, campaign_keywords[index]);
        if (kw.length) {
          first_params[campaign_keywords[index] + ' [first touch]'] = kw;
        }
      }
      mixpanel.people.set(params);
      mixpanel.people.set_once(first_params);
      mixpanel.register(params);
    }
    try {
      campaignParams();
    } catch (err) {}
    `;

  return (
    <Script
      // eslint-disable-next-line jam3/no-sanitizer-with-danger
      dangerouslySetInnerHTML={{ __html: script }}
      id='mixpanel-last-touch'
      strategy='afterInteractive'
    />
  );
};

const MixpanelIdentify = (): JSX.Element => {
  const script = `try {
    mixpanel.identify()
  } catch (err) {}`;

  return (
    <Script
      // eslint-disable-next-line jam3/no-sanitizer-with-danger
      dangerouslySetInnerHTML={{ __html: script }}
      id='mixpanel-identify'
      strategy='afterInteractive'
    />
  );
};

export const MixpanelScript = () => {
  const { isAnalyticsReady } = useAnalytics();

  if (!isAnalyticsReady) return null;

  return (
    <>
      <MixpanelIdentify />
      <MixpanelLastTouch />
    </>
  );
};
