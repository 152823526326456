/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

export enum AccoladeGroupType {
  BY_TIER_ASC = "BY_TIER_ASC",
  BY_TIER_DESC = "BY_TIER_DESC",
}

export enum AccoladeSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  TIER_ASC = "TIER_ASC",
  TIER_DESC = "TIER_DESC",
}

export enum AccoladeStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum AccoladeTier {
  GOLD = "GOLD",
  GRAPHITE = "GRAPHITE",
  PLATINUM = "PLATINUM",
  SILVER = "SILVER",
}

export enum BadgeSlug {
  ALL_DAY_DEBUT = "ALL_DAY_DEBUT",
  CHAMPIONSHIP_YEAR = "CHAMPIONSHIP_YEAR",
  FIRST_SERIAL = "FIRST_SERIAL",
  LOW_SERIAL = "LOW_SERIAL",
  PLAYER_NUMBER = "PLAYER_NUMBER",
  ROOKIE_MINT = "ROOKIE_MINT",
  ROOKIE_YEAR = "ROOKIE_YEAR",
}

export enum BannerStatus {
  ACTIVE = "ACTIVE",
  INACTIVE = "INACTIVE",
}

export enum BannerTier {
  BRONZE = "BRONZE",
  GOLD = "GOLD",
  PLATINUM = "PLATINUM",
  SILVER = "SILVER",
}

export enum BurnedMomentNFTSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  BURNED_AT_ASC = "BURNED_AT_ASC",
  BURNED_AT_DESC = "BURNED_AT_DESC",
  BURN_CREDIT_ASC = "BURN_CREDIT_ASC",
  BURN_CREDIT_ASC_NULLS_FIRST = "BURN_CREDIT_ASC_NULLS_FIRST",
  BURN_CREDIT_ASC_NULLS_LAST = "BURN_CREDIT_ASC_NULLS_LAST",
  BURN_CREDIT_DESC = "BURN_CREDIT_DESC",
  BURN_CREDIT_DESC_NULLS_FIRST = "BURN_CREDIT_DESC_NULLS_FIRST",
  BURN_CREDIT_DESC_NULLS_LAST = "BURN_CREDIT_DESC_NULLS_LAST",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DEFAULT = "DEFAULT",
  SERIAL_NUMBER_ASC = "SERIAL_NUMBER_ASC",
  SERIAL_NUMBER_DESC = "SERIAL_NUMBER_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum ChallengeCategory {
  BURN = "BURN",
  PREDICTIVE = "PREDICTIVE",
  PREDICTIVE_FREE = "PREDICTIVE_FREE",
  PRESCRIPTIVE = "PRESCRIPTIVE",
  PRESCRIPTIVE_FREE = "PRESCRIPTIVE_FREE",
}

export enum ChallengeImageType {
  BACKGROUND = "BACKGROUND",
  DEFAULT = "DEFAULT",
  REWARD = "REWARD",
}

export enum ChallengeSortType {
  END_TIME_ASC = "END_TIME_ASC",
  END_TIME_DESC = "END_TIME_DESC",
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
}

export enum ChallengeSubmissionStatus {
  BURNING_PENDING = "BURNING_PENDING",
  COMPLETE = "COMPLETE",
  MISSING_REQUIREMENTS = "MISSING_REQUIREMENTS",
  PREDICTIVE_PENDING = "PREDICTIVE_PENDING",
  PREDICTIVE_REJECTED = "PREDICTIVE_REJECTED",
  VALID = "VALID",
}

export enum ChallengeVideoType {
  DEFAULT = "DEFAULT",
}

export enum ChallengeVisibility {
  HIDDEN = "HIDDEN",
  PUBLIC = "PUBLIC",
}

export enum Comparator {
  EQUAL = "EQUAL",
  GREATER_THAN = "GREATER_THAN",
  GREATER_THAN_OR_EQUAL = "GREATER_THAN_OR_EQUAL",
  LESS_THAN = "LESS_THAN",
  LESS_THAN_OR_EQUAL = "LESS_THAN_OR_EQUAL",
  NOT_EQUAL = "NOT_EQUAL",
}

export enum ContainsType {
  ALL = "ALL",
  ANY = "ANY",
}

export enum Currency {
  USD = "USD",
}

export enum CursorDirection {
  LEFT = "LEFT",
  RIGHT = "RIGHT",
}

export enum DistributionImageType {
  BACKGROUND = "BACKGROUND",
  DEFAULT = "DEFAULT",
}

export enum DistributionSortType {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  END_TIME_ASC = "END_TIME_ASC",
  END_TIME_DESC = "END_TIME_DESC",
  START_TIME_ASC = "START_TIME_ASC",
  START_TIME_DESC = "START_TIME_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum DistributionState {
  COMPLETE = "COMPLETE",
  INITIALIZED = "INITIALIZED",
  INVALID = "INVALID",
}

export enum DistributionTier {
  COMMON = "COMMON",
  FANDOM = "FANDOM",
  LEGENDARY = "LEGENDARY",
  PLAYOFFS = "PLAYOFFS",
  PREMIUM = "PREMIUM",
  RARE = "RARE",
  STANDARD = "STANDARD",
  ULTIMATE = "ULTIMATE",
}

export enum DistributionVideoType {
  RIP = "RIP",
}

export enum EditionTier {
  COMMON = "COMMON",
  FANDOM = "FANDOM",
  LEGENDARY = "LEGENDARY",
  RARE = "RARE",
  ULTIMATE = "ULTIMATE",
}

export enum MarketplaceEditionSortType {
  LISTED_DATE_ASC = "LISTED_DATE_ASC",
  LISTED_DATE_DESC = "LISTED_DATE_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  SERIAL_ASC = "SERIAL_ASC",
}

export enum MarketplaceTeamNFTSetsSortType {
  EDITION_NUMBER_ASC = "EDITION_NUMBER_ASC",
  LISTED_DATE_ASC = "LISTED_DATE_ASC",
  LISTED_DATE_DESC = "LISTED_DATE_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
}

export enum MomentNFTListingsSortType {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  SERIAL_NUMBER_ASC = "SERIAL_NUMBER_ASC",
  SERIAL_NUMBER_DESC = "SERIAL_NUMBER_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum MomentNFTSaleSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
}

export enum MomentNFTSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DEFAULT = "DEFAULT",
  LISTED_PRICE_ASC = "LISTED_PRICE_ASC",
  LISTED_PRICE_ASC_NULLS_FIRST = "LISTED_PRICE_ASC_NULLS_FIRST",
  LISTED_PRICE_ASC_NULLS_LAST = "LISTED_PRICE_ASC_NULLS_LAST",
  LISTED_PRICE_DESC = "LISTED_PRICE_DESC",
  LISTED_PRICE_DESC_NULLS_FIRST = "LISTED_PRICE_DESC_NULLS_FIRST",
  LISTED_PRICE_DESC_NULLS_LAST = "LISTED_PRICE_DESC_NULLS_LAST",
  SERIAL_NUMBER_ASC = "SERIAL_NUMBER_ASC",
  SERIAL_NUMBER_DESC = "SERIAL_NUMBER_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum PackNFTSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  OPENED_AT_ASC = "OPENED_AT_ASC",
  OPENED_AT_ASC_NULLS_FIRST = "OPENED_AT_ASC_NULLS_FIRST",
  OPENED_AT_ASC_NULLS_LAST = "OPENED_AT_ASC_NULLS_LAST",
  OPENED_AT_DESC = "OPENED_AT_DESC",
  OPENED_AT_DESC_NULLS_FIRST = "OPENED_AT_DESC_NULLS_FIRST",
  OPENED_AT_DESC_NULLS_LAST = "OPENED_AT_DESC_NULLS_LAST",
  REVEALED_AT_ASC = "REVEALED_AT_ASC",
  REVEALED_AT_DESC = "REVEALED_AT_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum PackNFTStatus {
  OPENED = "OPENED",
  REVEALED = "REVEALED",
  SEALED = "SEALED",
}

export enum PlayClassification {
  PLAYER_GAME = "PLAYER_GAME",
  PLAYER_MELT = "PLAYER_MELT",
  TEAM_GAME = "TEAM_GAME",
  TEAM_MELT = "TEAM_MELT",
}

export enum PlayImageType {
  PLAY_IMAGE_TYPE_CROPPED_ASSET = "PLAY_IMAGE_TYPE_CROPPED_ASSET",
  PLAY_IMAGE_TYPE_NIL = "PLAY_IMAGE_TYPE_NIL",
}

export enum PlayState {
  PLAY_STATUS_DRAFT = "PLAY_STATUS_DRAFT",
  PLAY_STATUS_PUBLISHED = "PLAY_STATUS_PUBLISHED",
}

export enum PlayType {
  BLOCK = "BLOCK",
  BLOCKED_KICK = "BLOCKED_KICK",
  FIELD_GOAL = "FIELD_GOAL",
  FORCED_FUMBLE = "FORCED_FUMBLE",
  FUMBLE_RECOVERY = "FUMBLE_RECOVERY",
  INTERCEPTION = "INTERCEPTION",
  KICK_RETURN = "KICK_RETURN",
  PASS = "PASS",
  PASS_DEFENSE = "PASS_DEFENSE",
  PLAYER_MELT = "PLAYER_MELT",
  PRESSURE = "PRESSURE",
  PUNT = "PUNT",
  PUNT_RETURN = "PUNT_RETURN",
  RECEPTION = "RECEPTION",
  RUSH = "RUSH",
  SACK = "SACK",
  SAFETY = "SAFETY",
  STRIP_SACK = "STRIP_SACK",
  TACKLE = "TACKLE",
  TEAM_MELT = "TEAM_MELT",
  TWO_POINT_ATTEMPT = "TWO_POINT_ATTEMPT",
}

export enum PlayVideoType {
  PLAY_VIDEO_TYPE_NIL = "PLAY_VIDEO_TYPE_NIL",
  PLAY_VIDEO_TYPE_SQUARE = "PLAY_VIDEO_TYPE_SQUARE",
  PLAY_VIDEO_TYPE_VERTICAL = "PLAY_VIDEO_TYPE_VERTICAL",
}

export enum PlayerPosition {
  DB = "DB",
  DL = "DL",
  K = "K",
  LB = "LB",
  MELT = "MELT",
  OL = "OL",
  P = "P",
  QB = "QB",
  RB = "RB",
  TE = "TE",
  WR = "WR",
}

export enum QueueStatus {
  ENDED = "ENDED",
  PAUSED = "PAUSED",
  RUNNING = "RUNNING",
}

export enum RewardPassTaskCategory {
  DROP_PARTICIPATION = "DROP_PARTICIPATION",
  LOGIN = "LOGIN",
  LOGIN_STREAK = "LOGIN_STREAK",
}

export enum RewardPassTier {
  GRATIS = "GRATIS",
  PREMIUM = "PREMIUM",
}

export enum RewardType {
  MOMENT_NFT = "MOMENT_NFT",
  XP = "XP",
}

export enum SetSortType {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum SortDirection {
  ASC = "ASC",
  DESC = "DESC",
}

export enum TaskCategory {
  BURN_MOMENT = "BURN_MOMENT",
  COMPLETE_CHALLENGE = "COMPLETE_CHALLENGE",
  DROP_PARTICIPATION = "DROP_PARTICIPATION",
  FAVORITE_TEAM_WIN = "FAVORITE_TEAM_WIN",
  LOGIN = "LOGIN",
  LOGIN_STREAK = "LOGIN_STREAK",
  PURCHASE_MOMENT = "PURCHASE_MOMENT",
}

export enum TaskType {
  POINTS = "POINTS",
  UPGRADE = "UPGRADE",
}

export enum TeamNFTListingsSortType {
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  EDITION_NUMBER_ASC = "EDITION_NUMBER_ASC",
  EDITION_NUMBER_DESC = "EDITION_NUMBER_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_DESC = "PRICE_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum TeamNFTSaleSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  PRICE_ASC = "PRICE_ASC",
  PRICE_ASC_NULLS_FIRST = "PRICE_ASC_NULLS_FIRST",
  PRICE_ASC_NULLS_LAST = "PRICE_ASC_NULLS_LAST",
  PRICE_DESC = "PRICE_DESC",
  PRICE_DESC_NULLS_FIRST = "PRICE_DESC_NULLS_FIRST",
  PRICE_DESC_NULLS_LAST = "PRICE_DESC_NULLS_LAST",
}

export enum TeamNFTSortType {
  ACQUIRED_AT_ASC = "ACQUIRED_AT_ASC",
  ACQUIRED_AT_DESC = "ACQUIRED_AT_DESC",
  CREATED_AT_ASC = "CREATED_AT_ASC",
  CREATED_AT_DESC = "CREATED_AT_DESC",
  DEFAULT = "DEFAULT",
  EDITION_NUMBER_ASC = "EDITION_NUMBER_ASC",
  EDITION_NUMBER_DESC = "EDITION_NUMBER_DESC",
  LISTED_PRICE_ASC = "LISTED_PRICE_ASC",
  LISTED_PRICE_DESC = "LISTED_PRICE_DESC",
  UPDATED_AT_ASC = "UPDATED_AT_ASC",
  UPDATED_AT_DESC = "UPDATED_AT_DESC",
}

export enum TeamSortType {
  NAME_ASC = "NAME_ASC",
  NAME_DESC = "NAME_DESC",
}

export enum ValueType {
  BOOLEAN = "BOOLEAN",
  INT = "INT",
  STRING = "STRING",
}

export interface AllDaySeasonalEditionFilter {
  active?: BooleanFilter | null;
  created_at?: BlockInfoFilter | null;
  id?: UInt64Filter | null;
  metadata?: AllDaySeasonalEditionMetadataFilter | null;
  type_name?: StringFilter | null;
  updated_at?: BlockInfoFilter | null;
  exists?: boolean | null;
}

export interface AllDaySeasonalEditionMetadataFilter {
  description?: StringFilter | null;
  event?: StringFilter | null;
  league?: StringFilter | null;
  name?: StringFilter | null;
  tier?: StringFilter | null;
  type?: StringFilter | null;
  exists?: boolean | null;
}

export interface AllDaySeasonalEditionMetadataSort {
  description?: SortString | null;
  event?: SortString | null;
  league?: SortString | null;
  name?: SortString | null;
  tier?: SortString | null;
  type?: SortString | null;
}

export interface AllDaySeasonalEditionSort {
  active?: Sort | null;
  created_at?: BlockInfoSort | null;
  id?: Sort | null;
  metadata?: AllDaySeasonalEditionMetadataSort | null;
  type_name?: SortString | null;
  updated_at?: BlockInfoSort | null;
}

export interface AllDaySeasonalNftFilter {
  burned_at?: BlockInfoFilter | null;
  created_at?: BlockInfoFilter | null;
  edition?: AllDaySeasonalEditionFilter | null;
  id?: UInt64Filter | null;
  listing?: ListingFilter | null;
  owner_address?: StringFilter | null;
  type_name?: StringFilter | null;
  updated_at?: BlockInfoFilter | null;
  exists?: boolean | null;
}

export interface AllDaySeasonalNftSort {
  burned_at?: BlockInfoSort | null;
  created_at?: BlockInfoSort | null;
  edition?: AllDaySeasonalEditionSort | null;
  id?: Sort | null;
  listing?: ListingSort | null;
  owner_address?: SortString | null;
  type_name?: SortString | null;
  updated_at?: BlockInfoSort | null;
}

export interface AuthorizeBurnMomentsInPackInput {
  signable: string;
  packNFTFlowID: number;
}

export interface AuthorizeChallengeSubmissionBurnInput {
  signable: string;
  challengeID: string;
}

export interface AuthorizeCustomerSupportPackNFTReservationInput {
  signable: string;
}

export interface AuthorizeMintSeasonalNFTInput {
  signable: string;
}

export interface AuthorizeMomentNFTPurchaseInput {
  signable: string;
}

export interface AuthorizePackNFTReservationInput {
  signable: string;
}

export interface AuthorizeTeamNFTPurchaseInput {
  signable: string;
}

export interface BaseSearchInput {
  pagination: PaginationInput;
}

export interface BlockInfoFilter {
  block_height?: UInt64Filter | null;
  event_index?: UIntFilter | null;
  transaction_index?: UIntFilter | null;
  transaction_hash?: StringFilter | null;
  block_time?: TimeFilter | null;
  exists?: boolean | null;
}

export interface BlockInfoSort {
  block_height?: Sort | null;
  event_index?: Sort | null;
  transaction_index?: Sort | null;
  transaction_hash?: SortString | null;
  block_time?: Sort | null;
}

export interface BooleanFilter {
  eq?: boolean | null;
  ne?: boolean | null;
  exists?: boolean | null;
}

export interface ChallengeSlotRequest {
  slotID: string;
  nftID: string;
}

export interface DistributionFilters {
  byIDs?: (string | null)[] | null;
  byFlowIDs?: number[] | null;
  byStartTime?: TimeRange | null;
  byEndTime?: TimeRange | null;
}

export interface GetActiveRewardPassInput {
  rewardPassID?: string | null;
}

export interface ListingFilter {
  storefront_address?: StringFilter | null;
  listing_resource_id?: UInt64Filter | null;
  nft_type?: StringFilter | null;
  nft_id?: UInt64Filter | null;
  ft_vault_type?: StringFilter | null;
  price?: UInt64Filter | null;
  created_at?: BlockInfoFilter | null;
  updated_at?: BlockInfoFilter | null;
  type_name?: StringFilter | null;
  exists?: boolean | null;
  nft_uuid?: UInt64Filter | null;
  custom_id?: StringFilter | null;
  commission_amount?: UInt64Filter | null;
  commission_receivers?: StringListFilter | null;
  commission_receiver?: StringFilter | null;
  expiry?: UInt64Filter | null;
  sales_price?: UInt64Filter | null;
  sale_payment_vault_type?: StringFilter | null;
}

export interface ListingSort {
  storefront_address?: SortString | null;
  listing_resource_id?: Sort | null;
  nft_type?: SortString | null;
  nft_id?: Sort | null;
  ft_vault_type?: SortString | null;
  price?: Sort | null;
  created_at?: BlockInfoSort | null;
  updated_at?: BlockInfoSort | null;
  type_name?: SortString | null;
  nft_uuid?: Sort | null;
  custom_id?: SortString | null;
  commission_amount?: Sort | null;
  expiry?: Sort | null;
  sales_price?: Sort | null;
  sale_payment_vault_type?: SortString | null;
}

export interface PaginationInput {
  cursor: any;
  direction: CursorDirection;
  limit: number;
}

export interface PriceInput {
  value?: any | null;
  currency?: Currency | null;
}

export interface ReservePackNFTInput {
  distributionFlowID: number;
  fullQueueID?: string | null;
}

export interface SearchAllDaySeasonalNftsInput {
  after?: string | null;
  first?: number | null;
  filters?: AllDaySeasonalNftFilter[] | null;
  sortBy?: AllDaySeasonalNftSort | null;
  query?: string | null;
}

export interface SearchDistributionsInputV2 {
  after?: string | null;
  first?: number | null;
  filters?: DistributionFilters | null;
  sortBy?: DistributionSortType | null;
}

export interface SearchUserProfilesFilters {
  byUsername?: string | null;
  byFlowAddresses?: (string | null)[] | null;
}

export interface SearchUserProfilesInput {
  after?: string | null;
  first?: number | null;
  filters?: SearchUserProfilesFilters | null;
}

export interface Sort {
  priority: number;
  direction: SortDirection;
}

export interface SortString {
  priority: number;
  direction: SortDirection;
  ignore_case?: boolean | null;
}

export interface StringFilter {
  eq?: string | null;
  ne?: string | null;
  in?: string[] | null;
  ignore_case?: boolean | null;
  exists?: boolean | null;
}

export interface StringListFilter {
  contains?: string[] | null;
  contains_type?: ContainsType | null;
  exists?: boolean | null;
}

export interface SubmitUserRewardPassTaskInput {
  taskCategory: RewardPassTaskCategory;
}

export interface TimeFilter {
  eq?: any | null;
  ne?: any | null;
  in?: any[] | null;
  before?: any | null;
  after?: any | null;
  exists?: boolean | null;
}

export interface TimeRange {
  timeRangeStart: any;
  timeRangeEnd: any;
}

export interface TimeRangeOptional {
  before?: any | null;
  after?: any | null;
}

export interface UInt64Filter {
  eq?: any | null;
  ne?: any | null;
  gt?: any | null;
  lt?: any | null;
  gte?: any | null;
  lte?: any | null;
  in?: any[] | null;
  exists?: boolean | null;
}

export interface UIntFilter {
  eq?: any | null;
  ne?: any | null;
  gt?: any | null;
  lt?: any | null;
  gte?: any | null;
  lte?: any | null;
  in?: any[] | null;
  exists?: boolean | null;
}

export interface UpsertChallengeSubmissionInput {
  challengeID: string;
  slots: (ChallengeSlotRequest | null)[];
}

export interface UpsertUserProfileInput {
  dapperID: string;
  email?: string | null;
  phoneNumber?: string | null;
  username?: string | null;
  flowAddress?: string | null;
  profileImageUrl?: string | null;
  isSigned?: boolean | null;
  isMarketingAllowed?: boolean | null;
  hasCompletedFTUE?: boolean | null;
  favoriteTeamIDs?: (string | null)[] | null;
  selectedBannerID?: string | null;
  playbookSeen?: string | null;
}

export interface ValidateCustomerSupportPackNFTReservationInput {
  reservationID: string;
  distributionFlowID: number;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
export enum UFCEditionTier {
  CHALLENGER = "CHALLENGER",
  FANDOM = "FANDOM",
  ULTIMATE = "ULTIMATE",
  CHAMPION = "CHAMPION",
  CONTENDER = "CONTENDER"
}

export enum GolazoEditionTier {
  LEGENDARY = "LEGENDARY",
  RARE = "RARE",
  COMMON = "COMMON",
  UNCOMMON = "UNCOMMON",
  FANDOM = "FANDOM"
}

export enum GolazoCategory {
  KO = "KO/TKO"
}

export enum UFCCategory {
  KO = "KO/TKO"
}

