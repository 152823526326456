import { Card, CardContent } from '@dapperlabs/skeleton-ui';
import { useRef, useState } from 'react';
import { ErrorBoundary } from 'src/general/components/ErrorBoundary/ErrorBoundary';
import { Video } from 'src/general/components/Video';
import { useAnalytics } from 'src/lib/segment';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';
import { useTranslation } from 'src/lib/translations';
import {
  Flex,
  NextImage,
  Heading,
  Box,
  Text,
  useMediaQuery,
  breakpoints,
  keyframes,
} from 'src/theme';

const animateLetterSpacing = keyframes`
0% { letter-spacing: 0.1em; }
50% { letter-spacing: 0.2em; }
100% { letter-spacing: 0.1em; }
`;

export const SeasonalNFTVideoCard = ({
  nftSubtitle = null,
  nftTitle = null,
  video,
  videoOverlayImage,
  mediaDesktopDimensions = null,
  thumbnailScale = 1,
}) => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const [isMdUp] = useMediaQuery(`screen and (min-width: ${breakpoints.md})`);
  const [showVideoOverlay, setShowVideoOverlay] = useState(true);
  const [shouldShowPlayPrompt, setShouldShowPlayPrompt] = useState(
    mediaDesktopDimensions !== null,
  );
  const [thumbnailAnimation, setThumbnailAnimation] = useState(null);
  const videoRef = useRef(null);

  const animateThumbnailOnStart = keyframes`
    0% { opacity: 1; }
    100% { transform: scale(1); opacity: 0;  }
    `;
  const animateThumbnailOnEnd = keyframes`
    0% { transform: scale(1); opacity: 0;  }
    100% { transform: scale(${thumbnailScale}); opacity: 1; }
    `;

  const handleVideoPlay = () => {
    track(SEGMENT_EVENTS_ID_ENUM.FTUEV2_VIDEO_PLAY);
    setThumbnailAnimation(
      `${animateThumbnailOnStart} 1 1s forwards ease-in-out`,
    );
    setShouldShowPlayPrompt(false);
    videoRef.current.play();
  };

  const handleVideoEnded = () => {
    setShowVideoOverlay(true);
    setThumbnailAnimation(`${animateThumbnailOnEnd} 1 1s forwards ease-in-out`);
  };

  const dimensions = isMdUp ? mediaDesktopDimensions || 361 : 279;

  return (
    <ErrorBoundary renderError={() => <div>{t('general.errorBoundary')}</div>}>
      <Box overflow='hidden' borderRadius={8}>
        <Card layout='portrait' variant='bare'>
          <Flex justify='center' p={1}>
            <Box position='relative' width={dimensions} height={dimensions}>
              <Video
                ref={videoRef}
                controls
                mp4={video?.fields?.file?.url}
                onEnded={handleVideoEnded}
                playsInline
              />
              {showVideoOverlay && (
                <Box
                  position='absolute'
                  top={0}
                  left={0}
                  cursor='pointer'
                  onClick={handleVideoPlay}
                >
                  {shouldShowPlayPrompt && (
                    <Flex
                      position='absolute'
                      zIndex='1'
                      alignItems='flex-end'
                      justifyContent='center'
                      w='100%'
                      h='100%'
                      p='6'
                    >
                      <Heading
                        animation={`${animateLetterSpacing} infinite 3s ease-in-out`}
                        size='lg'
                        variant='tall'
                      >
                        {t('FTUEV2.hero.playVideo')}
                      </Heading>
                    </Flex>
                  )}
                  <Box
                    background='black'
                    animation={thumbnailAnimation}
                    transform={`scale(${thumbnailScale})`}
                    onAnimationEnd={() => {
                      if (!videoRef.current?.paused) {
                        setShowVideoOverlay(false);
                      }
                    }}
                  >
                    <NextImage
                      width={dimensions}
                      height={dimensions}
                      alt={videoOverlayImage?.fields?.title}
                      src={videoOverlayImage?.fields?.file?.url}
                    />
                  </Box>
                </Box>
              )}
            </Box>
          </Flex>
          <CardContent>
            {nftTitle && (
              <Heading
                mb={{ base: 1, md: 1.5 }}
                size={isMdUp ? 'lg' : 'md'}
                variant='tall'
              >
                {t(`${nftTitle}`)}
              </Heading>
            )}
            {nftSubtitle && (
              <Text
                mb={{ base: 0.5, md: 0 }}
                size={isMdUp ? 'sm' : 'xs'}
                variant='currency'
              >
                {t(`${nftSubtitle}`)}
              </Text>
            )}
          </CardContent>
        </Card>
      </Box>
    </ErrorBoundary>
  );
};
