import { graphql } from 'msw';
import { searchPackNFTsHandler, packNFTFactory } from 'src/edge/searchPackNfts';

export const handlers = {
  noReservation: () => {
    return [
      getUserActiveReservation(
        null,
        'getUserActiveReservation__getReservation',
      ),
    ];
  },
  packNotFound: () => {
    return [
      getUserActiveReservation(
        {
          distribution_id: 4024224,
          expired_at: '3023-09-30T19:00:00Z',
          listing_resource_id: 32142,
          price: '1000.00',
          user_id: '123',
        },
        'getUserActiveReservation__getReservation',
      ),
      searchPackNFTsHandler([null], 'searchPackNft__getReservation'),
    ];
  },
  purchasePack: () => {
    return [
      getUserActiveReservation(
        {
          distribution_id: 4024224,
          expired_at: '3023-09-30T19:00:00Z',
          listing_resource_id: 32142,
          price: '1000.00',
          user_id: '123',
        },
        'getUserActiveReservation__getReservation',
      ),
      searchPackNFTsHandler(
        [
          packNFTFactory({
            id: 123,
            listing: {
              __typename: 'Listing',
              storefront_address: '0x9999999999',
            },
            type_name: 'A.44477dcb6fb36f14.PackNFT.NFT',
          }),
        ],
        'searchPackNft__getReservation',
      ),
      searchPackNFTsHandler(
        [
          packNFTFactory({
            id: 123,
            owner_address: '0x123',
            type_name: 'A.44477dcb6fb36f14.PackNFT.NFT',
          }),
        ],
        'searchPackNft__pollOwnership',
      ),
    ];
  },
};

export const getUserActiveReservation = (data, name) =>
  graphql.query(name, (req, res, ctx) => {
    return res(
      ctx.data({
        getUserActiveReservation: {
          __typename: 'GetUserActiveReservationResponse',
          reservation: data,
        },
      }),
    );
  });
