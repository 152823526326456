import { TransactionsModalInputTheme } from './TransactionsModal.Input.theme';
import { TransactionsModalPriceSectionTheme } from './TransactionsModal.PriceSection.theme';
import { TransactionsModalTheme } from './TransactionsModal.theme';

export enum TRANSACTIONS_MODAL_THEME_KEYS {
  COMMON = 'TransactionsModal',
  INPUT = 'TransactionsModal/Input',
  PRICE_SECTION = 'TransactionsModal/PriceSection',
}

export const TransactionsModalThemes = {
  [TRANSACTIONS_MODAL_THEME_KEYS.COMMON]: TransactionsModalTheme,
  [TRANSACTIONS_MODAL_THEME_KEYS.INPUT]: TransactionsModalInputTheme,
  [TRANSACTIONS_MODAL_THEME_KEYS.PRICE_SECTION]:
    TransactionsModalPriceSectionTheme,
};
