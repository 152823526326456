import { useQuery } from '@apollo/client';
import { ApolloError } from '@apollo/client/errors';
import reduce from 'lodash/reduce';
import { useRouter } from 'next/router';
import { useEffect, useMemo } from 'react';
import { useIsAuthed, useIsSessionLoading } from 'src/edge/session';
import {
  searchChallenges_allChallenges,
  searchChallenges_allChallenges_searchChallenges_edges_node,
} from 'src/modules/Challenges/queries/__generated__/searchChallenges_allChallenges';
import {
  QUERY_SEARCH_ALL_CHALLENGES,
  VARIABLES_SEARCH_CHALLENGES,
} from 'src/modules/Challenges/queries/searchChallenges';
import {
  GET_ACTIVE_REWARD_PASS,
  GET_USER_PROGRESS,
} from 'src/modules/RewardPass/queries/rewardPass';
import { DecoratedUserRewardPass } from 'src/modules/RewardPass/types';
import { getUpgradeTask, getMergedTasks } from 'src/modules/RewardPass/utils';

import { GetActiveRewardPass } from '../queries/__generated__/GetActiveRewardPass';
import { GetUserProgress } from '../queries/__generated__/GetUserProgress';

type UseGetRewardPassResult = {
  data?: DecoratedUserRewardPass;
  error?: ApolloError;
  loading: boolean;
  refetchProgress: () => void;
};

/**
 * - fetches both the active reward pass and the user's progress
 * - merges them together for easier use in UI
 * - poll for new rewardpass every 30 minutes just to be sure cache doesn't get stale
 * - poll for new user progress every 10 seconds, for showing progress modals and keeping
 * progress up to date
 */
export const useGetRewardPass = (): UseGetRewardPassResult => {
  const isSessionLoading = useIsSessionLoading();
  const isAuthed = useIsAuthed();
  const router = useRouter();
  const isPreview = !!router.query.previewID;

  const activeResponse = useQuery<GetActiveRewardPass>(GET_ACTIVE_REWARD_PASS, {
    variables: {
      input: {
        rewardPassID: isPreview ? router.query.previewID : '',
      },
    },
  });
  const progressResponse = useQuery<GetUserProgress>(GET_USER_PROGRESS, {
    pollInterval: 20000, // 20 seconds
    skip: isSessionLoading || !isAuthed || isPreview,
  });
  const activePassData = activeResponse.data?.getActiveRewardPass;
  const userPassData = progressResponse.data?.getUserProgress;

  const challengeIds = reduce(
    activePassData?.tasks,
    (acc, { referenceID }) => {
      referenceID && acc.add(referenceID);
      return acc;
    },
    new Set(),
  );

  // We fetch challenges in order to fetch them all in a single query and
  // be able to associate them with their tasks so that we can group them
  // appropriately for rendering
  const challengesResponse = useQuery<searchChallenges_allChallenges>(
    QUERY_SEARCH_ALL_CHALLENGES,
    {
      skip: !activePassData || isPreview,
      variables: VARIABLES_SEARCH_CHALLENGES({
        byIDs: Array.from(challengeIds.keys()),
        // @NOTE: deprecated
        // byState: null,
      }),
    },
  );

  useEffect(() => {
    if (!activePassData && !activeResponse.loading) {
      progressResponse.stopPolling();
    }
  }, [activePassData, activeResponse.loading, progressResponse]);

  /**
   * if the IDs don't match between active and progress, its likely because
   * progress is updated more frequently and we transitioned to a new Reward Pass
   * so we should re-fetch the active reward pass data.
   * Needs some testing :D
   */
  useEffect(() => {
    if (!activePassData || !userPassData) return;
    if (activeResponse.loading || progressResponse.loading) return;
    if (userPassData && activePassData.id !== userPassData.rewardPassID)
      activeResponse.refetch();
  }, [activePassData, activeResponse, progressResponse.loading, userPassData]);

  return useMemo(() => {
    const refetchProgress = () => {
      progressResponse.refetch();
    };
    const isLoading =
      activeResponse.loading ||
      progressResponse.loading ||
      challengesResponse.loading ||
      isSessionLoading;

    const error =
      activeResponse.error ||
      progressResponse.error ||
      challengesResponse.error;

    if (isLoading || error || !activePassData) {
      return {
        error: isLoading ? null : error,
        loading: isLoading,
        refetchProgress,
      };
    }

    const mergedTasks = getMergedTasks(
      activePassData.tasks,
      userPassData?.tasks,
      challengesResponse?.data?.searchChallenges?.edges?.map(
        ({ node }) =>
          node as searchChallenges_allChallenges_searchChallenges_edges_node,
      ),
    );
    const upgradeTask = getUpgradeTask(mergedTasks);
    return {
      data: {
        ...activePassData,
        isPreview,
        tasks: mergedTasks,
        upgradeTask,
        userProgress: userPassData,
      },
      loading: false,
      refetchProgress,
    };
  }, [
    activePassData,
    activeResponse,
    challengesResponse,
    isPreview,
    isSessionLoading,
    progressResponse,
    userPassData,
  ]);
};
