import { useEffect } from 'react';
import { useSession } from 'src/edge/session';
import { Video } from 'src/general/components/Video';
import { SEGMENT_EVENTS_ID_ENUM, useAnalytics } from 'src/lib/segment';
import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from 'src/theme';

import { TermsAndMarketing } from './components/TermsAndMarketing';
export const Onboarding = () => {
  const { t } = useTranslation();
  const { send, state } = useSession();
  const { track } = useAnalytics();

  const showOnboarding = state.matches('ONBOARDING');

  // tracking sign ups
  useEffect(() => {
    if (state.matches('ONBOARDING.CREATING_PROFILE')) {
      // avoid tracking for existing users
      if (!state.context.profile) {
        track(SEGMENT_EVENTS_ID_ENUM.WALLET_AUTH_RESPONSE, {
          auth_result: 'success',
          auth_type: 'sign_up',
        });
      }
    }
  }, [state, track]);
  const creatingProfile = state.matches('ONBOARDING.CREATING_PROFILE');
  const title = t('Onboarding.Welcome.Title');
  const video = '/video/onboarding.mp4';

  const dismiss = () => {
    send('DISMISS');
  };

  const acceptTerms = (marketingEmail) =>
    send('ACCEPT_TERMS', { marketingEmail });

  return (
    <Modal
      closeOnEsc
      closeOnOverlayClick
      isCentered
      isOpen={showOnboarding}
      onClose={() => dismiss()}
      size='lg'
    >
      <ModalOverlay
        sx={{
          backdropFilter: 'blur(6px)',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: ['end'],
        }}
      />
      <ModalContent
        position={{ base: 'fixed' }}
        zIndex='9999'
        bottom={['0px', 'auto']}
        overflow='hidden'
        w='100%'
        maxW='756px'
        maxH={{ md: '660px' }}
        mb={{ base: '0px' }}
        p='0'
        bg='#000'
        borderRadius={['16px 16px 0 0', '16px']}
      >
        <ModalCloseButton
          width='48px'
          height='48px'
          background='#17171B5C'
          borderRadius='100%'
          backdropFilter={{ base: 'blur(10px)', md: 'none' }}
        />

        <ModalBody maxHeight='660px' px='0' py='0'>
          <Flex direction={{ base: 'column', md: 'row' }} height='100%'>
            <Box
              sx={{
                '> video': {
                  transform: ['translateY(-235px)', 'none'],
                },
              }}
              overflow='hidden'
              maxWidth={{ base: '100%', md: '100%' }}
              height={{ base: '228px', md: 'auto' }}
              maxH='660px'
              objectFit='cover'
            >
              <Video key={video} autoPlay loop mp4={video} muted playsInline />
            </Box>

            <Flex
              justifyContent='space-around'
              direction='column'
              padding={{ base: '16px', md: '24px' }}
              background='#000'
            >
              {title && (
                <Text
                  mt='8px'
                  color='#F8F8F8'
                  fontFamily='heading'
                  fontSize={['32px', '36px']}
                >
                  {title}
                </Text>
              )}

              <TermsAndMarketing
                acceptTerms={acceptTerms}
                loading={creatingProfile}
              />
            </Flex>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
