import * as React from 'react';
import { chakra, forwardRef, useStyles } from 'src/theme/index';
import type { HTMLChakraProps, ThemingProps } from 'src/theme/index';

export interface TopNavListProps
  extends HTMLChakraProps<'ul'>,
    ThemingProps<'ul'> {
  children: React.ReactNode;
}

export const TopNavList = forwardRef<TopNavListProps, 'ul'>((props, ref) => {
  const styles = useStyles();

  return <chakra.ul ref={ref} __css={styles.list} {...props} />;
});
