// @ts-nocheck
import React, { useCallback } from 'react';
import { useTranslation } from 'src/lib/translations';
import { Box, Tooltip, Flex, chakra, useMultiStyleConfig } from 'src/theme';
import { TIMELINE_THEME_KEY } from 'src/theme/dss-skeleton/components';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';

import { useVideo } from '../Video';
import { COLOR_CARDS, TIMELINE_EVENT_TYPES } from './enums';
import {
  TimelineItemProps,
  TimelineItemTooltipLabelProps,
  TimelineItemI,
} from './types';

const Substitution = ({ item }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <chakra.span>
        <chakra.strong>{t('Timeline.On')}: </chakra.strong>
      </chakra.span>
      <chakra.span>{item.subbedOn}</chakra.span>
      <chakra.span>
        <chakra.strong> | </chakra.strong>
      </chakra.span>
      <chakra.span>
        <chakra.strong>{t('Timeline.Off')}: </chakra.strong>
      </chakra.span>
      <chakra.span>{item.subbedOff}</chakra.span>
    </Box>
  );
};

const Discipline = ({ item }) => {
  const { t } = useTranslation();
  return (
    <Box>
      <chakra.strong>
        {t(`Timeline.ColorCard.${COLOR_CARDS[item.card]}`)} {t('Timeline.Card')}
        :{' '}
      </chakra.strong>
      {item.player}
    </Box>
  );
};

const TimelineItemTooltipLabel = ({
  item,
  momentSrc,
  isGroup,
  groupItem,
}: TimelineItemTooltipLabelProps) => {
  const { t } = useTranslation();
  const { view } = useVideo({
    fullScreenVideoSrc: momentSrc,
    id: 'moment-video',
    paddingTop: '0',
    videoProps: {
      display: 'block',
      h: '100%',
      loop: true,
      objectFit: 'cover',
      opacity: '0.6',
      w: '100%',
    },
    videoSrc: momentSrc,
  });
  const _renderTimelineItemTooltipLabelProps = (view) => {
    switch (item.eventType) {
      case TIMELINE_EVENT_TYPES.DISCIPLINE:
        return isGroup ? (
          <Flex direction='column'>
            {groupItem.map((item: TimelineItemI, index: number) => (
              <Discipline key={index + item.min} item={item} />
            ))}
          </Flex>
        ) : (
          <Discipline item={item} />
        );
      case TIMELINE_EVENT_TYPES.SUBSTITUTION:
        return isGroup ? (
          <Flex direction='column'>
            {groupItem.map((item: TimelineItemI, index: number) => (
              <Substitution key={index + item.min} item={item} />
            ))}
          </Flex>
        ) : (
          <Substitution item={item} />
        );
      case TIMELINE_EVENT_TYPES.GOAL:
        return (
          <Box>
            <chakra.strong>{t('Timeline.Goal')}:</chakra.strong> {item.scoredBy}
          </Box>
        );
      case TIMELINE_EVENT_TYPES.MOMENT:
        return <Box>{view}</Box>;
    }
  };
  return (
    <Flex
      direction='row'
      color='#F8F8F8'
      fontSize='14px'
      letterSpacing='0.16px'
    >
      {_renderTimelineItemTooltipLabelProps(view)}
    </Flex>
  );
};

export const TimelineItem = ({
  item,
  icon,
  momentSrc,
  isBottom = false,
  isGroup,
  groupItem,
}: TimelineItemProps) => {
  const styles = useMultiStyleConfig(TIMELINE_THEME_KEY);
  const { events, trackHandler } = useDssAnalytics();

  const Icon = icon;

  const useTrackOpenTooltip = (analyticsId: string, cardType?: string) =>
    useCallback(() => {
      trackHandler(events.GAME_HIGHLIGHTS_TOOLTIP_VIEW, {
        item_label: `${analyticsId}${cardType ? `_${cardType}` : ''}`,
      });
    }, [analyticsId, cardType]);

  return (
    <Tooltip
      sx={
        item.eventType === TIMELINE_EVENT_TYPES.MOMENT
          ? styles.momentPreview
          : {}
      }
      label={
        <TimelineItemTooltipLabel
          groupItem={groupItem}
          isGroup={isGroup}
          item={item}
          momentSrc={momentSrc}
        />
      }
      onOpen={useTrackOpenTooltip(item.eventType, item.card)}
      placement='top'
    >
      <Box
        sx={styles.timelineItemContentWrapper}
        left={`${item.position}px`}
        flexDirection={isBottom ? 'column-reverse' : 'column'}
        marginTop={isBottom ? { base: '15px', md: '10px' } : 0}
        marginBottom={isBottom ? 0 : { base: '15px', md: '10px' }}
      >
        {isBottom ? null : <Box sx={styles.timelineGoalMin}>{item.min}'</Box>}
        <Box
          className='pulse transparent'
          sx={item.eventType === TIMELINE_EVENT_TYPES.MOMENT && styles.pulse}
          display='flex'
          width='fit-content'
          margin='auto'
          cursor='pointer'
        >
          <Icon w='23.5px' h='24px' margin='auto' />
        </Box>
        {isBottom && <Box sx={styles.timelineGoalMin}>{item.min}'</Box>}
      </Box>
    </Tooltip>
  );
};
