import { InterpolationWithTheme } from '@emotion/core';

import { Icon as ChakraIcon, IconProps, ChakraTheme } from 'src/theme';

import AllDaySvg from './allDay.svg';
import FieldSvg from './field.svg';
import FootballGreatsSvg from './football-greats.svg';
import NflAllDaySvg from './nfl-all-day.svg';
import NflSvg from './nfl.svg';
import NFLPASvg from './nflpa.svg';

type ChakraIconProps = IconProps & {
  css?: InterpolationWithTheme<ChakraTheme>;
};

export const FootballGreatsLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...{ ...props, as: FootballGreatsSvg }} />;
};

export const FieldMarkers = (props: ChakraIconProps) => {
  return <ChakraIcon {...{ ...props, as: FieldSvg }} />;
};

export const NflLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...{ ...props, as: NflSvg }} />;
};

export const NflpaLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...{ ...props, as: NFLPASvg }} />;
};

export const AllDay = (props: ChakraIconProps) => {
  return <ChakraIcon {...{ ...props, as: AllDaySvg }} />;
};

export const NflAllDayLogo = (props: ChakraIconProps) => {
  // Default dimensions for convenience
  return (
    <ChakraIcon w='8.5rem' h='1.5rem' {...{ ...props, as: NflAllDaySvg }} />
  );
};
