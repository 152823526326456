export const analyticsItemLabels = {
  FOOTER_ABOUT: 'about',
  FOOTER_BLOG: 'blog',
  FOOTER_CHALLENGES: 'challenges',
  FOOTER_COLLECTION: 'collection',
  FOOTER_DISCORD: 'social_discord',
  FOOTER_FACEBOOK: 'social_facebook',
  FOOTER_HC_LINK1: 'hc_link1',
  FOOTER_HC_LINK2: 'hc_link2',
  FOOTER_HC_LINK3: 'hc_link3',
  FOOTER_HELP_CENTER: 'help_center',
  FOOTER_HOME: 'home',
  FOOTER_INSTAGRAM: 'social_instagram',
  FOOTER_MARKETPLACE: 'marketplace',
  FOOTER_PACKS: 'packs',
  FOOTER_PRIVACY_POLICY: 'privacy_policy',
  FOOTER_TERMS_AND_CONDITIONS: 'terms_and_conditions',
  FOOTER_TWITTER: 'social_twitter',
  FOOTER_YOUTUBE: 'social_youtube',
};
