import isEmpty from 'lodash/isEmpty';

export function parseOptions({ players, sets }) {
  const options = {};

  if (players != null) {
    const playerOptions = players?.reduce((acc, player) => {
      if (isEmpty(player)) return acc;
      return {
        ...acc,
        [player?.id]: `${player?.playerFullName} - ${player?.playerPosition}`,
      };
    }, {});

    if (!isEmpty(playerOptions)) {
      options['byPlayerIDs'] = playerOptions;
    }
  }

  if (sets != null) {
    const setsOptions = sets?.reduce((acc, set) => {
      if (isEmpty(set)) return acc;
      return { ...acc, [set?.id]: set?.name };
    }, {});

    if (!isEmpty(setsOptions)) {
      options['bySetIDs'] = setsOptions;
    }
  }
  return options;
}
