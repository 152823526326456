export enum ASSET_TYPES {
  ANIMATED_VIDEO_IDLE_BLACK_1080_1080_DEFAULT = 'Animated_Video_Idle_Black_1080_1080_default.mp4',
  ANIMATED_VIDEO_IDLE_BLACK_1080_1080_ES = 'Animated_Video_Idle_Black_1080_1080_es.mp4',
  ANIMATED_VIDEO_POPOUT_BLACK_1080_1080_DEFAULT = 'Animated_Video_Popout_Black_1080_1080_default.mp4',
  ANIMATED_VIDEO_POPOUT_BLACK_1080_1080_ES = 'Animated_Video_Popout_Black_1080_1080_es.mp4',
  ANIMATED_VIDEO_POPOUT_BLACK_720_1280_DEFAULT = 'Animated_Video_Popout_Black_720_1280_default.mp4',
  ANIMATED_VIDEO_POPOUT_BLACK_720_1280_ES = 'Animated_Video_Popout_Black_720_1280_es.mp4',
  DETAILS_BLACK_2880_2880_DEFAULT = 'Details_Black_2880_2880_default.png',
  DETAILS_BLACK_2880_2880_ES = 'Details_Black_2880_2880_es.png',
  FRONT_BLACK_2880_2880_DEFAULT = 'Front_Black_2880_2880_default.png',
  FRONT_BLACK_2880_2880_ES = 'Front_Black_2880_2880_es.png',
  FRONT_TRANSPARENT_2880_2880_DEFAULT = 'Front_Transparent_2880_2880_default.png',
  FRONT_TRANSPARENT_2880_2880_ES = 'Front_Transparent_2880_2880_es.png',
  HERO_BLACK_2880_2880_DEFAULT = 'Hero_Black_2880_2880_default.png',
  HERO_BLACK_2880_2880_ES = 'Hero_Black_2880_2880_es.png',
  HERO_LANDSCAPE_3840_2160_DEFAULT = 'Hero_Landscape_3840_2160_default.png',
  HERO_LANDSCAPE_3840_2160_ES = 'Hero_Landscape_3840_2160_es.png',
  HERO_TRANSPARENT_2880_2880_DEFAULT = 'Hero_Transparent_2880_2880_default.png',
  HERO_TRANSPARENT_2880_2880_ES = 'Hero_Transparent_2880_2880_es.png',
  LEGAL_BLACK_2880_2880_DEFAULT = 'Legal_Black_2880_2880_default.png',
  LEGAL_BLACK_2880_2880_ES = 'Legal_Black_2880_2880_es.png',
  VIDEO_BLACK_2880_2880_DEFAULT = 'Video_Black_2880_2880_default.png',
  VIDEO_BLACK_2880_2880_ES = 'Video_Black_2880_2880_es.png',
}

export const EDITION_TIER = {
  // @NOTE: THIS 2 ARE HERE AS A TEMPORARY WAY OF TRANSITION TO THE NEW TIERS
  BASE: 'BASE',
  //
  COMMON: 'COMMON',
  FANDOM: 'FANDOM',
  LEGENDARY: 'LEGENDARY',
  RARE: 'RARE',
  SPECIAL_EDITION: 'SPECIAL_EDITION',
  ULTIMATE: 'ULTIMATE',
  UNCOMMON: 'UNCOMMON',
};
