import { useApolloClient } from '@apollo/client';
import { useMachine } from '@xstate/react';
import { useRouter } from 'next/router';
import { createMachine, MachineConfig } from 'xstate';

import { useFeatureFlags } from 'src/lib/launchDarkly';
import { useAnalytics } from 'src/lib/segment';
import { useAccoladeToast } from 'src/modules/Accolade/AccoladeToast';

import { actions, guards, services } from './options';
import { Context, EVENTS, Events, Schema, TypeState, FS } from './types';

// https://stately.ai/viz/1c27177d-faff-465b-a748-6e0f75f98b80
export const definition: MachineConfig<Context, Schema, Events> = {
  context: {
    client: undefined,
    error: undefined,
    favoriteTeamId: undefined,
    flags: undefined,
    interaction: undefined,
    profile: undefined,
    router: undefined,
    seasonalNFT: undefined,
    seasonalNFTEdition: undefined,
    showAccoladeToast: undefined,
    track: undefined,
    txId: undefined,
  },
  id: 'ftue',
  initial: FS.LOADING_ASSETS,
  on: { [EVENTS.RESET]: FS.IDLE },
  states: {
    [FS.LOADING_ASSETS]: {
      always: {
        cond: 'shouldSkipFTUE',
        target: FS.IDLE,
      },
      invoke: {
        onDone: {
          actions: 'assignEditionData',
          target: FS.LOADING,
        },
        onError: {
          actions: 'assignError',
          target: FS.IDLE,
        },
        src: 'loadEditionData',
      },
    },
    [FS.LOADING]: {
      invoke: {
        onDone: {
          actions: 'assignUserData',
          target: FS.PREPARING_ACCOUNT,
        },
        onError: {
          actions: 'assignError',
          target: FS.IDLE,
        },
        src: 'loadUserData',
      },
    },
    [FS.IDLE]: {
      on: { [EVENTS.START]: FS.FTUE_MODAL },
    },
    [FS.PREPARING_ACCOUNT]: {
      always: {
        cond: 'shouldSkipPreparingAccount',
        target: FS.TERMS_CONDITIONS,
      },
      on: {
        [EVENTS.GO_TO_TOS]: FS.TERMS_CONDITIONS,
      },
    },
    [FS.TERMS_CONDITIONS]: {
      always: {
        cond: 'shouldSkipTOS',
        target: FS.FTUE_LEGACY,
      },
      on: {
        [EVENTS.NEXT]: FS.FTUE_LEGACY,
      },
    },
    [FS.FTUE_LEGACY]: {
      always: {
        cond: 'shouldSkipFTUELegacy',
        target: FS.FTUE_MODAL,
      },
      on: {
        NEXT: FS.FTUE_MODAL,
      },
    },
    [FS.FTUE_MODAL]: {
      always: {
        cond: 'shouldSkipFTUE',
        target: FS.IDLE,
      },
      id: 'ftueModal',
      initial: 'TEAM_PICKER',
      states: {
        [FS.TEAM_PICKER]: {
          always: { cond: 'shouldSkipTeamPicker', target: FS.SIGN_UP },
          on: {
            [EVENTS.SELECT_TEAM]: [
              { actions: 'storeTeam', target: FS.SIGN_UP },
            ],
          },
        },
        [FS.SIGN_UP]: {
          always: {
            cond: 'shouldSkipSignUp',
            target: FS.CLAIM_REWARD,
          },
          on: {
            [EVENTS.SIGN_UP]: FS.SIGNING_UP,
          },
        },
        [FS.SIGNING_UP]: {
          invoke: {
            src: 'signUp',
          },
        },
        [FS.CLAIM_REWARD]: {
          id: 'claimReward',
          initial: FS.STORING_TEAM,
          states: {
            [FS.STORING_TEAM]: {
              invoke: {
                onDone: { target: FS.IDLE },
                onError: {
                  actions: 'assignError',
                  target: `#ftueModal.ERROR`,
                },
                src: 'addTeamToProfile',
              },
            },
            [FS.IDLE]: {
              on: {
                [EVENTS.CLAIM_REWARD]: {
                  target: FS.TRANSACTION,
                },
                [EVENTS.RESET]: `#ftue.${FS.IDLE}`,
              },
            },
            [FS.TRANSACTION]: {
              entry: 'assignInteraction',
              id: 'TRANSACTION',
              initial: 'AUTHORIZING',
              states: {
                [FS.AUTHORIZING]: {
                  invoke: {
                    onDone: {
                      actions: 'assignTransactionId',
                      target: FS.FINALIZING,
                    },
                    onError: {
                      actions: 'assignError',
                      target: `#ftueModal.ERROR`,
                    },
                    src: 'sendTransaction',
                  },
                },
                [FS.FINALIZING]: {
                  invoke: {
                    onDone: { target: FS.EXECUTING },
                    onError: {
                      actions: 'assignError',
                      target: `#ftueModal.ERROR`,
                    },
                    src: 'awaitTransactionFinalized',
                  },
                },
                [FS.EXECUTING]: {
                  invoke: {
                    onDone: { target: FS.SEALING },
                    onError: {
                      actions: 'assignError',
                      target: `#ftueModal.ERROR`,
                    },
                    src: 'awaitTransactionExecuted',
                  },
                },
                [FS.SEALING]: {
                  invoke: {
                    onDone: {
                      target: `#ftueModal.${FS.AWARD_ACCOLADE}`,
                    },
                    onError: {
                      actions: 'assignError',
                      target: `#ftueModal.ERROR`,
                    },
                    src: 'awaitTransactionSealed',
                  },
                },
              },
            },
          },
        },
        [FS.AWARD_ACCOLADE]: {
          invoke: {
            onDone: {
              target: `#ftueModal.SUCCESS`,
            },
            onError: {
              actions: 'assignError',
              target: `#ftueModal.ERROR`,
            },
            src: 'sendAccoladeAndCompleteFTUE',
          },
        },
        [FS.SUCCESS]: {},
        [FS.ERROR]: {},
      },
    },
  },
};
const machine = createMachine<Context, Events, TypeState>(definition, {
  actions,
  guards,
  services,
});

export const useFTUEV2Machine = () => {
  const client = useApolloClient();
  const flags = useFeatureFlags();
  const { track } = useAnalytics();
  const { showAccoladeToast } = useAccoladeToast();
  const router = useRouter();

  return useMachine(machine, {
    context: {
      client,
      flags,
      router,
      showAccoladeToast,
      track,
    },
  });
};
