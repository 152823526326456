import { graphql } from 'msw';

export const getUserActiveReservationErrorFactory = (
  overrides: [Record<string, any>?] = [],
): any => {
  return [
    {
      message:
        'error getting users active reservation: graphql: no reservations found for user 0c029c67c7c9584d',
      path: ['getUserActiveReservation'],
    },
    ...overrides,
  ];
};

export const getUserActiveReservationFactory = (
  overrides: Record<string, any> = {},
): any => {
  return {
    getUserActiveReservation: {
      __typename: 'GetUserActiveReservationResponse',
      reservation: {
        distribution_id: 19,
        expired_at: '3023-09-30T19:00:00Z',
        listing_resource_id: '5',
        price: '30',
        user_id: '5',
        ...overrides,
      },
    },
  };
};

export const getUserActiveReservationHandler = (data = {}) => {
  return graphql.query<any>('GET_USER_RESERVATION', (req, res, ctx) => {
    return res(ctx.data(getUserActiveReservationFactory(data)));
  });
};

export const getUserActiveReservationNoReservationHandler = (
  data: [Record<string, any>?] = [],
) => {
  return graphql.query<any>('GET_USER_RESERVATION', (req, res, ctx) => {
    return res(ctx.errors(getUserActiveReservationErrorFactory(data)));
  });
};
