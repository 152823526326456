import { ComponentStyleConfig } from '@chakra-ui/react';

const theme: ComponentStyleConfig = {
  baseStyle: {
    volumeIcon: {
      MsTransition: 'background 400ms linear',
      WebkitTransition: 'background 400ms linear',
      _active: {
        background: '#373B42',
      },
      _hover: {
        background: '#373B42',
      },
      background: 'transparent',
      cursor: 'pointer',
      height: '3rem',
      position: 'absolute',
      right: '-4rem',
      top: '-13px',
      transition: 'background 400ms linear',
      width: '3rem',
    },
  },
  defaultProps: {},
  parts: ['volumeIcon'],
};

export const VOLUME_SLIDER_THEME_KEY = 'VolumeSlider';
export const VolumeSliderTheme = {
  [VOLUME_SLIDER_THEME_KEY]: theme,
};
