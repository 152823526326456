import {
  DocumentNode,
  QueryHookOptions,
  QueryResult,
  LazyQueryHookOptions,
  LazyQueryResult,
  OperationVariables,
  QueryLazyOptions,
  TypedDocumentNode,
  useQuery as useApolloQuery,
  useLazyQuery as useApolloLazyQuery,
} from '@apollo/client';
import { captureException } from '@dapperlabs/core-fe';
import { useMemo } from 'react';
import { QueryState, FS } from 'src/general/constants/finiteStates';

interface QueryResponse<TData, TVariables>
  extends Pick<QueryResult<TData, TVariables>, 'data'> {
  result: QueryResult<TData, TVariables>;
  state: QueryState;
}

export function useQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: QueryHookOptions<TData, TVariables> & {
    origin?: string;
  },
): QueryResponse<TData, TVariables> {
  const queryName = (query.definitions[0] as any).name.value;

  const result = useApolloQuery<TData, TVariables>(query, {
    errorPolicy: 'all',
    ssr: false,
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
      },
      origin: `${options.origin || 'anonymous'}/${queryName}`,
    },
  });

  const { loading, error, data } = result;

  const value = useMemo(() => {
    let stateValue = FS.IDLE;

    if (loading) {
      stateValue = FS.LOADING;
    } else if (error) {
      stateValue = FS.ERROR;
    } else if (data?.[queryName] === null) {
      // @NOTE: there's a chance we *wont* want to consider the following an error
      const err = new Error(
        `API Endpoint ${queryName} unexpectedly returning null`,
      );
      captureException(err);
      stateValue = FS.ERROR;
    } else if (data) {
      // @NOTE:  there are times we can expect that `data === null` after a
      // "successful" request. we will probably continue to not handle that here.
      stateValue = FS.SUCCESS;
    }

    return {
      data,
      result,
      state: {
        context: {
          error,
        },
        value: stateValue,
      },
    };
  }, [result, data, error, loading, queryName]);

  return value;
}

interface LazyQueryResponse<TData, TVariables>
  extends Pick<
    LazyQueryResult<TData, TVariables>,
    'data' | 'startPolling' | 'stopPolling'
  > {
  exec: (_options?: QueryLazyOptions<TVariables>) => void;
  result: LazyQueryResult<TData, TVariables>;
  state: QueryState;
}

export function useLazyQuery<TData = any, TVariables = OperationVariables>(
  query: DocumentNode | TypedDocumentNode<TData, TVariables>,
  options?: LazyQueryHookOptions<TData, TVariables> & {
    origin?: string;
  },
): LazyQueryResponse<TData, TVariables> {
  const queryName = (query.definitions[0] as any).name.value;

  const result = useApolloLazyQuery<TData, TVariables>(query, {
    errorPolicy: 'all',
    ssr: false,
    ...options,
    context: {
      ...options?.context,
      headers: {
        ...options?.context?.headers,
      },
      origin: `${options.origin || 'anonymous'}/${queryName}`,
    },
  });

  const [, { data, error, loading, startPolling, stopPolling }] = result;

  const value = useMemo(() => {
    let stateValue = FS.IDLE;

    if (loading) {
      stateValue = FS.LOADING;
    } else if (error) {
      stateValue = FS.ERROR;
    } else if (data?.[queryName] === null) {
      // @NOTE: there's a chance we *wont* want to consider the following an error
      const err = new Error(
        `API Endpoint ${queryName} unexpectedly returning null`,
      );
      captureException(err);
      stateValue = FS.ERROR;
    } else if (data) {
      // @NOTE:  there are times we can expect that `data === null` after a
      // "successful" request. we will probably continue to not handle that here.
      stateValue = FS.SUCCESS;
    }

    return {
      data,
      exec: result[0],
      result: result[1],
      startPolling,
      state: {
        context: {
          error,
        },
        value: stateValue,
      },
      stopPolling,
    };
  }, [result, data, error, loading, queryName, startPolling, stopPolling]);

  return value;
}
