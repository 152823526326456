import { useMemo } from 'react';

import { QueryState, FS } from '../constants/finiteStates';

export const useQueryStates = ({
  states,
  data,
  enforceData = false,
}: {
  data?: any[] | null;
  enforceData?: boolean;
  states: QueryState[];
}): FS => {
  const isIdle = states.every(({ value }) => value === FS.IDLE);
  const isError = states.some(({ value }) => value === FS.ERROR);
  const isLoading = states.some(({ value }) => value === FS.LOADING);
  const isDataIncomplete = enforceData ? !data : false;

  const currentState = useMemo(() => {
    if (isIdle) return FS.IDLE;
    if (isError) return FS.ERROR;
    if (isLoading) return FS.LOADING;
    if (isDataIncomplete) return FS.ERROR;
    return FS.SUCCESS;
  }, [isIdle, isLoading, isError, isDataIncomplete]);
  return currentState;
};
