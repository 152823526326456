import i18next from 'i18next';
import { useCallback, useMemo, useState } from 'react';

const DEFAULT_LANGUAGES = [
  { label: 'English', urlValue: '_default', value: 'en' },
  { label: 'Español', urlValue: '_es', value: 'es' },
];

export const useLanguage = (
  onChangeLanguage?,
  languages = DEFAULT_LANGUAGES,
) => {
  const [currentLanguage, setCurrentLanguage] = useState(i18next.language);

  const changeLanguageHandler = useCallback(
    ({ value }) => {
      if (onChangeLanguage instanceof Function) {
        onChangeLanguage(value);
      }
      setCurrentLanguage(value);
    },
    [onChangeLanguage],
  );

  i18next.on('languageChanged', (lang) => {
    setCurrentLanguage(lang);
  });

  const handleLanguage = useMemo(
    () => ({
      languages,
      onChangeLanguage: changeLanguageHandler,
      selectedLanguage: languages.find(
        (lang) => lang.value === currentLanguage,
      ),
    }),
    [changeLanguageHandler, currentLanguage, languages],
  );

  return { handleLanguage, languages };
};
