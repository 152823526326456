/* eslint-disable sort-keys-fix/sort-keys-fix, quotes, prettier/prettier */
// Autogenerated from graphql-golazos-player-country.js
type Countries = Array<{ label: string; value: string }>;

export const COUNTRY_DATA: Countries = [
  { value: "Algeria", label: "Algeria" },
  { value: "Argentina", label: "Argentina" },
  { value: "Belgium", label: "Belgium" },
  { value: "Brazil", label: "Brazil" },
  { value: "Cameroon", label: "Cameroon" },
  { value: "Canada", label: "Canada" },
  { value: "Chile", label: "Chile" },
  { value: "China PR", label: "China PR" },
  { value: "Colombia", label: "Colombia" },
  { value: "Congo DR", label: "Congo DR" },
  { value: "Costa Rica", label: "Costa Rica" },
  { value: "Croatia", label: "Croatia" },
  { value: "Côte d'Ivoire", label: "Côte d'Ivoire" },
  { value: "Denmark", label: "Denmark" },
  { value: "Ecuador", label: "Ecuador" },
  { value: "France", label: "France" },
  { value: "Gabon", label: "Gabon" },
  { value: "Georgia", label: "Georgia" },
  { value: "Germany", label: "Germany" },
  { value: "Ghana", label: "Ghana" },
  { value: "Israel", label: "Israel" },
  { value: "Italy", label: "Italy" },
  { value: "Japan", label: "Japan" },
  { value: "Korea Republic", label: "Korea Republic" },
  { value: "Kosovo", label: "Kosovo" },
  { value: "Mali", label: "Mali" },
  { value: "Mexico", label: "Mexico" },
  { value: "Morocco", label: "Morocco" },
  { value: "Mozambique", label: "Mozambique" },
  { value: "Netherlands", label: "Netherlands" },
  { value: "Nigeria", label: "Nigeria" },
  { value: "North Macedonia", label: "North Macedonia" },
  { value: "Norway", label: "Norway" },
  { value: "Poland", label: "Poland" },
  { value: "Portugal", label: "Portugal" },
  { value: "Republic of Korea", label: "Republic of Korea" },
  { value: "Senegal", label: "Senegal" },
  { value: "Serbia", label: "Serbia" },
  { value: "Slovenia", label: "Slovenia" },
  { value: "Spain", label: "Spain" },
  { value: "Sweden", label: "Sweden" },
  { value: "Switzerland", label: "Switzerland" },
  { value: "Turkey", label: "Turkey" },
  { value: "USA", label: "USA" },
  { value: "Ukraine", label: "Ukraine" },
  { value: "Uruguay", label: "Uruguay" },
  { value: "Venezuela", label: "Venezuela" },
  { value: "Wales", label: "Wales" },
];
