import { ReactElement } from 'react';

import { Flex, HeadingDeprecated, TextDeprecated, VStack } from 'src/theme';

interface StatusInfoProps {
  children?: ReactElement;
  description?: string;
  title?: string;
}

export default function StatusInfo({
  title = '',
  description = '',
  children,
}: StatusInfoProps): JSX.Element {
  return (
    <VStack align='stretch'>
      <Flex flexDir='column' mb={10}>
        <HeadingDeprecated lineHeight='90%' size='4xl' variant='title'>
          {title}
        </HeadingDeprecated>
        {description ? (
          <TextDeprecated
            mt={4}
            mb={10}
            color='grey.text'
            lineHeight={1.3}
            size='lg'
            variant='light'
          >
            {description}
          </TextDeprecated>
        ) : null}
        {children}
      </Flex>
    </VStack>
  );
}
