import { cdc } from '@onflow/fcl';

const CODE = cdc`import NFTStorefrontV2 from 0xNFTStorefrontContractAddress

transaction(listingResourceID: UInt64) {
    let storefront: auth(NFTStorefrontV2.RemoveListing) &{NFTStorefrontV2.StorefrontManager}

    prepare(acct: auth(BorrowValue) &Account) {
        self.storefront = acct.storage.borrow<auth(NFTStorefrontV2.RemoveListing) &{NFTStorefrontV2.StorefrontManager}>(from: NFTStorefrontV2.StorefrontStoragePath)
            ?? panic("Missing or mis-typed NFTStorefrontV2.Storefront resource")
    }

    execute {
        self.storefront.removeListing(listingResourceID: listingResourceID)
    }
}`;

export { CODE };
