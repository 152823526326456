import { FLAG_ICONS } from 'src/general/utils/flags';
import { extractValue } from 'src/general/utils/helpers';

const getCountryFlagIcon = (country) => {
  return country ? FLAG_ICONS[country.toLowerCase()] : null;
};

// scoped to the edition.play.metadata aggregation.
export const getPlayerName = (metadata) => {
  return (
    extractValue(metadata.player_known_name) ||
    `${extractValue(metadata.player_first_name)} ${extractValue(
      metadata.player_last_name,
    )}`
  );
};

export const getValues = (
  obj: Record<string, any>,
  values: string[],
): Record<string, any> =>
  values.reduce((acc, val) => {
    acc[val] = extractValue(obj[val]);
    return acc;
  }, {});

export const getPlayerInfo = (edition: any): Record<string, any> => {
  return {
    countryIcon: getCountryFlagIcon(
      extractValue(edition?.play?.metadata?.player_country),
    ),
    name: getPlayerName(edition?.play?.metadata),
    ...getValues(edition?.play?.metadata, [
      'match_season',
      'match_highlighted_team',
    ]),
  };
};

export const getPlayInfo = (metadata): Record<string, any> =>
  getValues(metadata, [
    'match_home_team',
    'match_away_team',
    'play_data_id',
    'play_type',
  ]);
