import type { ComponentStyleConfig } from 'src/theme/index';

export const Components: ComponentStyleConfig = {
  baseStyle: {
    carouselVideo: {
      margin: 'auto',
      maxHeight: '540px',
      width: {
        base: 'calc(100% - 2rem)',
        md: '100%',
      },
    },
    dateOpeningAdditionalText: {
      color: 'rgb(159, 153, 178)',
      fontSize: '14px',
      letterSpacing: '0.16px',
      lineHeight: '24px',
    },
    dateOpeningContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      mt: { base: '24px', md: 5 },
    },
    dateOpeningText: {
      color: '#F8F8F8',
      fontSize: '14px',
      fontWeight: '700',
      letterSpacing: '-0.16px',
      lineHeight: '24px',
      ml: '14px',
      mr: '4px',
    },
    fullscreenButton: {
      alignItems: 'center',
      backdropFilter: 'blur(15px)',
      background: 'rgba(138, 148, 166, 0.25)',
      borderRadius: '40px',
      bottom: '24px',
      color: '#F0F2FD',
      display: 'flex',
      fontFamily: 'mono',
      fontSize: '14px',
      gap: 2,
      height: '44px',
      justifyContent: 'center',
      left: 'calc(50% - 93px)',
      letterSpacing: '0.6px',
      paddingX: '2',
      position: 'absolute',
      zIndex: '1',
    },
    signatureCompanyName: {
      fontWeight: '800',
    },
    signatureContainer: {
      alignItems: 'center',
      color: '#F8F8F8',
      flexDirection: 'row',
      letterSpacing: '0.16px',
      mt: 8,
    },
    signatureText: {
      fontSize: '14px',
      mr: 0.5,
    },
    video: {
      height: 'auto',
      margin: 'auto',
      objectFit: 'contain',
      width: '100%',
    },
    videoPlaceholderImage: {
      backgroundSize: 'contain',
      height: '100%',
      minHeight: '290px',
      width: '100%',
    },
  },
  parts: [
    'dateOpeningAdditionalText',
    'dateOpeningText',
    'dateOpeningContainer',
    'signatureCompanyName',
    'signatureContainer',
    'signatureText',
    'video',
    'videoPlaceholderImage',
    'carouselVideo',
    'fullscreenButton',
  ],
};
