import { gql } from '@apollo/client';

export const AUTHORIZE_MINT_FTUE_SEASONAL_NFT_MUTATION = gql`
  mutation authorizeMintFTUESeasonalNFT(
    $input: AuthorizeMintSeasonalNFTInput!
  ) {
    authorizeMintSeasonalNFT(input: $input) {
      signature
    }
  }
`;

export const COMPLETE_USER_FTUE_AND_SEND_ACCOLADE = gql`
  mutation completeUserFTUE {
    completeUserFTUE {
      accolade {
        name
        id
        assetUrls {
          iconDesktopUrl
          iconMobileUrl
        }
      }
    }
  }
`;
