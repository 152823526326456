const Sentry = require('@sentry/nextjs');

const logger = require('shared/logger');

/**
 * For use outside of application context (i.e. server.js)
 */
export const captureException = (exception, tags) => {
  try {
    const message = exception.message || '[no message]';

    Sentry.withScope((scope) => {
      Object.entries(tags || {}).forEach(([k, v]) => scope.setTag(k, v));
      const errorId = Sentry.captureException(exception);

      // Log it out so it's visible in pod logs.
      logger.error({ errorId, message });
    });
  } catch (error) {
    logger.error(error);
  }
};
