import { editionSalesHistory } from '@dapperlabs/core-fe';
import { useRouter } from 'next/router';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Flex,
  Button,
  Grid,
  GridItem,
  useMultiStyleConfig,
} from 'src/theme';

import {
  getFormattedCount,
  getFormattedDate,
} from '../../utils/stringFormatter';

const MAX_SALES_COUNT = 12;
const INITIAL_SALES_COUNT = 6;

export type SalesDataItem = {
  buyerUsername: string;
  id: string;
  price: number;
  serial: number | string;
  timestamp: string;
};
type SalesTableProps = {
  count?: number;
  data: SalesDataItem[];
  editionId: number | string;
  showAll?: boolean;
  tableId: string;
};
export const SalesTable = ({
  data,
  tableId,
  editionId,
  showAll = false,
  count,
}: SalesTableProps): JSX.Element => {
  const { t } = useTranslation();
  const router = useRouter();

  const [dataToRender, setDataToRender] = useState(data);
  const [showMoreBtn, setShowMoreBtn] = useState(false);

  useEffect(() => {
    if (showAll) {
      return;
    }

    if (count > INITIAL_SALES_COUNT) {
      setDataToRender(data.slice(0, INITIAL_SALES_COUNT));
      setShowMoreBtn(true);
    }
  }, [count, data, showAll]);

  const handleLoadMoreBtn = () => {
    if (showAll) {
      return;
    }
    if (count <= MAX_SALES_COUNT) {
      setShowMoreBtn(false);
      setDataToRender(data);
      return;
    }
    router.push(editionSalesHistory(editionId));
  };

  const { tableRow, tableHeader } = useMultiStyleConfig('SalesHistory');

  const renderRow = ({
    id,
    buyerUsername,
    price,
    serial,
    timestamp,
  }: SalesDataItem) => (
    <React.Fragment key={`${tableId}-${id}-${timestamp}`}>
      <GridItem sx={tableRow}>{buyerUsername}</GridItem>
      <GridItem sx={tableRow}>$ {getFormattedCount(price)}</GridItem>
      <GridItem sx={tableRow}>#{serial}</GridItem>
      <GridItem sx={tableRow}>{getFormattedDate(timestamp)}</GridItem>
    </React.Fragment>
  );

  return (
    <Flex direction='column'>
      <Box overflow='auto'>
        <Grid templateColumns='repeat(4, 1fr)' whiteSpace='nowrap'>
          <GridItem sx={tableHeader}>{t('moment.salesHistory.buyer')}</GridItem>
          <GridItem sx={tableHeader}>{t('moment.salesHistory.price')}</GridItem>
          <GridItem sx={tableHeader}>
            {t('moment.salesHistory.serial')}
          </GridItem>
          <GridItem sx={tableHeader}>
            {t('moment.salesHistory.datetime')}
          </GridItem>
          {dataToRender.map(renderRow)}
        </Grid>
      </Box>
      {showMoreBtn && (
        <Button
          margin='1rem auto'
          onClick={handleLoadMoreBtn}
          variant='outline'
        >
          {t('moment.salesHistory.loadMore')}
        </Button>
      )}
    </Flex>
  );
};
