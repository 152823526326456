import { gql } from '@apollo/client';

export const QueueFragment = gql`
  fragment QueueFragment on Queue {
    waitingRoomId
    preQueueStartTime
    queueStartTime
    queueStatus
  }
`;
