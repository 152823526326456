// eslint-disable-next-line no-restricted-imports
import {
  Box,
  Avatar,
  AvatarGroup,
  Heading,
  Image,
  Text,
  Button,
  Flex,
  LinkBox,
  LinkOverlay,
  useMediaQuery,
} from '@chakra-ui/react';
import {
  Card,
  DescriptionList,
  DescriptionDetails,
  DescriptionDetailsUnit,
  DescriptionTerm,
  BoxGlow,
  useHover,
  CardMedia,
  CardContent,
  CardFooter,
} from '@dapperlabs/skeleton-ui';
import { motion, AnimatePresence } from 'framer-motion';
import { imageOptimizerCDN } from 'src/lib/ImageOptimizer';
import { editionDetail } from 'src/modules/routes';
import { NextLink } from 'src/theme';

const capitalize = (string: string): string =>
  string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();

const slideUp = {
  closed: { transition: { type: 'tween' }, y: '0rem' },
  open: { transition: { type: 'tween' }, y: '-4rem', zIndex: 2 },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type NFTListingEditionCardProps = Record<string, any>;

export const NFTListingEditionCard = ({
  rarityTier,
  serialNumber,
  maxMinted,
  totalBurned,
  heading,
  line1,
  line2,
  lowestAsk,
  // avgAsk,
  momentImage,
  badges,
  onBuyNow,
  editionId,
  copy,
}: NFTListingEditionCardProps) => {
  const [isTablet] = useMediaQuery(`screen and (min-width: 48em)`);
  const [hoverRef, isHovered] = useHover();
  const totalBurnedLine =
    totalBurned === 1
      ? `1 ${copy.MomentsBurned}`
      : `${totalBurned} ${copy.MomentsBurned_plural}`;

  const optimizedMomentImage = imageOptimizerCDN({
    // @ts-ignore
    format: 'webp',
    height: 800,
    quality: 50,
    src: momentImage,
    width: 800,
  });

  return (
    <LinkBox key={editionId} display='flex' data-testid={editionId}>
      <BoxGlow isAnimated={isHovered} variant={rarityTier.toLowerCase()}>
        <Card
          ref={hoverRef}
          gridTemplateRows='[footer] 1fr'
          borderRadius='xl'
          layout={isTablet ? 'portrait' : 'landscape'}
        >
          <NextLink href={editionDetail(editionId)} legacyBehavior passHref>
            <CardMedia as='a' alignItems='center'>
              {optimizedMomentImage && (
                <Image
                  sx={{
                    aspectRatio: '1 / 1',
                  }}
                  w={800}
                  h='auto'
                  bg='black'
                  transform='scale(1.5)'
                  alt='NFT Moment picturing an illustration of a Skeleton'
                  src={optimizedMomentImage}
                />
              )}
            </CardMedia>
          </NextLink>
          <CardContent
            as={motion.div}
            pt={!isTablet && 3}
            animate={isHovered && isTablet ? 'open' : 'closed'}
            variants={slideUp}
          >
            <NextLink href={editionDetail(editionId)} legacyBehavior passHref>
              <Box as='a'>
                <Heading
                  mb={isTablet ? 1.5 : '0.5'}
                  color='white'
                  fontSize={isTablet ? '4xl' : 'lg'}
                  fontWeight='normal'
                  lineHeight={1}
                >
                  <Box>{heading}</Box>
                </Heading>
                <Flex
                  align={isTablet ? 'center' : 'start'}
                  justify='space-between'
                  direction={isTablet ? 'row' : 'column-reverse'}
                >
                  {(serialNumber || rarityTier || maxMinted) && (
                    <Text
                      mb={isTablet ? 1.5 : '0.5'}
                      fontSize='sm'
                      fontWeight='bold'
                      size={isTablet ? 'sm' : 'xs'}
                    >
                      {rarityTier && (
                        <Text
                          as='span'
                          textStyle={rarityTier.toLowerCase()}
                          fontSize='md'
                        >
                          {`${capitalize(rarityTier)} `}
                        </Text>
                      )}
                      #{serialNumber && <>{`${serialNumber}`}</>}
                      {(serialNumber || maxMinted) && <>/</>}
                      {maxMinted && <>{maxMinted}</>}
                    </Text>
                  )}

                  {badges && (
                    <AvatarGroup size='sm' spacing={-2}>
                      <Avatar name='Skeleton' src={badges} />
                    </AvatarGroup>
                  )}
                </Flex>
                <Box>
                  {totalBurnedLine && (
                    <Text
                      color='gray.300'
                      fontSize={isTablet ? 'md' : 'xs'}
                      noOfLines={1}
                    >
                      {totalBurnedLine}
                    </Text>
                  )}
                  {line1 && (
                    <Text
                      color='gray.300'
                      fontSize={isTablet ? 'md' : 'xs'}
                      // noOfLines={1}
                    >
                      {line1}
                    </Text>
                  )}
                  {line2 && (
                    <Text
                      color='gray.300'
                      fontSize={isTablet ? 'sm' : 'xs'}
                      noOfLines={2}
                    >
                      {line2}
                    </Text>
                  )}
                </Box>
              </Box>
            </NextLink>
          </CardContent>
          <CardFooter
            as={motion.div}
            animate={isHovered && isTablet ? 'open' : 'closed'}
            variants={slideUp}
          >
            <NextLink href={editionDetail(editionId)} legacyBehavior passHref>
              <DescriptionList size={isTablet ? 'md' : 'sm'} variant='meta'>
                <DescriptionTerm>{copy.LowestAsk}</DescriptionTerm>
                <DescriptionDetails
                  color={lowestAsk ? 'white' : 'gray.300'}
                  fontFamily='body'
                  fontSize={lowestAsk && 'md'}
                >
                  {lowestAsk ? `${lowestAsk}` : '—'}
                  {lowestAsk && (
                    <DescriptionDetailsUnit color='white'>
                      {` USD`}
                    </DescriptionDetailsUnit>
                  )}
                </DescriptionDetails>
                {/* <DescriptionTerm>Avg Ask</DescriptionTerm>
                <DescriptionDetails color={avgAsk ? 'white' : 'gray.300'}>
                  {avgAsk ? `${avgAsk}` : '—'}{' '}
                  {avgAsk && (
                    <DescriptionDetailsUnit>USD</DescriptionDetailsUnit>
                  )}
                </DescriptionDetails> */}
              </DescriptionList>
            </NextLink>
            <AnimatePresence>
              {isHovered && isTablet && (
                <motion.div
                  key={`buy-cta-${editionId}`}
                  transition={{ delay: 0.15 }}
                  animate={{
                    height: 'auto',
                    opacity: 1,
                    visibility: 'visible',
                    y: '0%',
                    zIndex: 2,
                  }}
                  exit={{
                    opacity: 0,
                  }}
                  initial={{
                    height: 0,
                    opacity: 0,
                    visibility: 'hidden',
                    y: '100%',
                  }}
                >
                  <Box pos='absolute' right={0} left={0}>
                    <NextLink
                      href={editionDetail(editionId)}
                      legacyBehavior
                      passHref
                    >
                      <Button
                        as='a'
                        zIndex='docked'
                        w='full'
                        mt={4}
                        data-edition-id={editionId}
                      >
                        {copy.ViewMomentDetails}
                      </Button>
                    </NextLink>
                  </Box>
                </motion.div>
              )}
            </AnimatePresence>
            {!isTablet && (
              <NextLink href={editionDetail(editionId)} legacyBehavior passHref>
                <LinkOverlay display='block' overflow='hidden' w='0' h='0'>
                  {copy.ViewMomentDetails}
                </LinkOverlay>
              </NextLink>
            )}
          </CardFooter>
        </Card>
      </BoxGlow>
    </LinkBox>
  );
};
