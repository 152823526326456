// @TODO: copy this over to laliga. clear our unnecessary routes here in core-fe
// and in laliga:

import { path } from './utils';

export function siteUrl() {
  return process.browser ? window.location.origin : process.env.SITE_URL;
}

// NAVBAR LINKS

// DEFAULT
export const home = () => '/';

export const storefront = (params = {}) => {
  return path(['storefront'], params);
};
export const momentDetail = (id: string, params = {}) => {
  return path(['moments', id], params);
};

export const seasonalDetail = (id: string, params = {}) => {
  return path(['seasonal', id], params);
};

export const teamnftDetail = (username: string, id: string, params = {}) => {
  return path(['user', username, 'team-nfts', id], params);
};

export const allDayMarketplace = (params = {}) => {
  return path(['marketplace', 'moments'], params);
};

export const teamNFTsMarketplace = (params = {}) => {
  return path(['marketplace', 'team-nfts'], params);
};

export const playbook = (params = {}) => {
  return path(['playbook'], params);
};

const API_PATH = 'api';

export const apiFavouriteTeam = (params = {}) =>
  path([API_PATH, 'favouriteTeam'], params);

export const apiSelectedBanner = (params = {}) =>
  path([API_PATH, 'selectedBanner'], params);

export const featureFlagsPath = (params = {}) =>
  path([API_PATH, 'flags'], params);

export const updatePlaybookSeenPath = (params = {}) => {
  return path([API_PATH, 'updatePlaybookSeen'], params);
};
export const appVersionApiPath = (params = {}) =>
  path([API_PATH, 'version'], params);

export const ftueApiPath = (params = {}) => path([API_PATH, 'ftue'], params);

export const signInPath = (params = {}) =>
  path(['api', 'auth', 'login'], params);

export const termsApiPath = (params = {}) =>
  path([API_PATH, 'termsOfService'], params);

export const obliviousPandaPath = (params = {}) =>
  path([API_PATH, 'obliviousPanda'], params);

export const queueStatusPath = (params = {}) =>
  path([API_PATH, 'queueService/queueStatus'], params);

// TAKEOVERS
export const siteMaintenance = (params = {}) => {
  return path(['maintenance'], params);
};

/**
 * Footer - TO-DO: These links go nowhere at the moment.
 */

// BOTTOM_LINKS_PROPS
export const terms = path(['terms']);
export const privacy = path(['privacy']);
export const ccpa = path(['ccpa']);
export const gdpr = path(['gdpr']);

// CONNECT_LINKS_PROPS
export const discord = 'https://discord.com/invite/5K6qyTzj2k';
export const instagram = 'https://www.instagram.com/nflallday/';
export const twitter = 'https://twitter.com/NFLAllDay';
export const statusPage = 'https://status.nflallday.com';
export const linkedIn = 'https://ca.linkedin.com/company/dapper-labs';
export const waitlist = 'https://nfl.dapperlabs.com/';
export const blog = 'http://blog.nflallday.com/';
export const basics = 'https://blog.nflallday.com/category/education';
export const challenges = 'https://challenges.nflallday.com/';
export const marketplace = process.env.NEXT_PUBLIC_MARKETPLACE_URL;
export const weeklyPlaybookLander = 'http://playbook.nflallday.com/';
export const trainingCamp = 'https://about.nflallday.com/';

// DAPPER
// @TODO: Update callback URL when user profiles are shareable
export const dapperSettings = `${process.env.DAPPER_ACCOUNT_URL}settings?action=clientAppCallback&clientAppID=${process.env.AUTH0_CLIENT_ID}&callbackUri=${process.env.NEXT_PUBLIC_SITE_URL}%2Fprofile`;
export const dapperWallet = `${process.env.DAPPER_ACCOUNT_URL}home`;
export const dapperWalletIdentityCheck = `${process.env.DAPPER_ACCOUNT_URL}security`;
export const help = 'https://support.nflallday.com/';
export const eliasSportsBureau = 'https://www.esb.com/';

// HELP_LINKS_PROPS
export const helpCenter = path(['helpCenter']);
export const faqs = path(['faqs']);
export const nflPrivacy = 'https://www.nfl.com/legal/privacy/';

// NFL_LINKS_PROPS
export const about = path(['about']);
export const developers = path(['developers']);
export const press = path(['press']);
export const investors = path(['investors']);
export const careers = path(['careers']);
export const hiring = path(['hiring']);

/**
 * Routes
 */

export const lock = (params = {}) => {
  return path(['lock'], params);
};

export const drops = (params = {}) => {
  return path(['drops'], params);
};

export const burn = (params = {}) => {
  return path(['moments', 'burn'], params);
};

export const dropDetails = (id: string | number, params = {}) => {
  return path(['drops', id], params);
};

export const pack = (
  username: string,
  flowID: string | number,
  params = {},
) => {
  return path(['user', username, 'packs', flowID], params);
};

export const editionDetails = (editionId: string | number, params = {}) => {
  return path(['editions', editionId], params);
};

export const packs = (params = {}) => {
  return path(['packs'], params);
};

export const packDetails = (flowId: string | number, params = {}) => {
  return path(['packs', flowId], params);
};

export const playerDetails = (playerName: string | number, params = {}) => {
  return path(['player', playerName], params);
};
export const playerFanLeaderboard = (
  playerName: string | number,
  params = {},
) => {
  return path(['player', playerName, 'player-leaderboard'], params);
};
export const playerInfo = (playerName: string | number, params = {}) => {
  return path(['player', playerName, 'player-stats'], params);
};

export const teamsOverview = (params = {}) => {
  return path(['teams'], params);
};

export const teamPageDetails = (teamName: string | number, params = {}) => {
  return path(['teams', teamName], params);
};
export const teamPageLeaderBoard = (teamName: string | number, params = {}) => {
  return path(['teams', teamName, 'team-leaderboard'], params);
};

export const teamPageTeamNFTs = (teamName: string | number, params = {}) => {
  return path(['teams', teamName, 'team-nfts'], params);
};

export const teamRoster = (teamName: string | number, params = {}) => {
  return path(['teams', teamName, 'player-rosters'], params);
};

export const setDetails = (
  flowId: string | number,
  username: string,
  params = {},
) => {
  return path(['user', username, 'sets', flowId], params);
};

export const editionDetail = (flowId: string | number, params = {}) => {
  return path(['editions', flowId], params);
};

export const editionSalesHistory = (flowId: string | number, params = {}) => {
  return path(['editions', flowId, 'history'], params);
};

export const teamDetail = (
  flowId: string | number,
  username: string,
  params = {},
) => {
  return path(['user', username, 'team-nft', flowId], params);
};

export const teamNFTDetailPage = (flowId: number, params = {}) => {
  return path(['team-nfts', flowId], params);
};

export const userProfile = (username: string, params = {}) => {
  return path(['user', username], params);
};

export const userProfileEdit = (username, params = {}) => {
  return path(['user', username, 'edit'], params);
};

export const userProfileAccolades = (username, params = {}) => {
  return path(['user', username, 'accolades'], params);
};

export const userProfileSeasonal = (username, params = {}) => {
  return path(['user', username, 'seasonal-nfts'], params);
};

export const userProfilePacks = (username, params = {}) => {
  return path(['user', username, 'packs'], params);
};

export const userMySets = (username: string, params = {}) => {
  return path(['user', username, 'my-sets'], params);
};

export const userProfileSets = (username, params = {}) => {
  return path(['user', username, 'sets'], params);
};

export const profileTeamNFTs = (username, params = {}) => {
  return path(['user', username, 'team-nfts'], params);
};

export const profileSeasonalNFTs = (username, params = {}) => {
  return path(['user', username, 'seasonal-nfts'], params);
};

export const userProfilePathBuilder = (
  username: string,
  params = {},
  subpath: string[] = [],
) => {
  return path(['user', username, ...subpath], params);
};

export const momentListingDetail = (editionFlowID: number, params = {}) => {
  return path(['listing', 'moment', editionFlowID], params);
};

export const teamNFTListingDetail = (setFlowID: number, params = {}) => {
  return path(['listing', 'team-nft', setFlowID], params);
};

export const momentNFTListingsSelect = (editionFlowID: number, params = {}) => {
  return path(['listing', 'moment', editionFlowID, 'select'], params);
};

export const teamNFTListingsSelect = (setFlowID: number, params = {}) => {
  return path(['listing', 'team-nft', setFlowID, 'select'], params);
};

export const momentNFTListingsSalesHistory = (
  editionFlowID,
  type: 'recent' | 'top',
) => `/listing/moment/${editionFlowID}/history/${type}`;

export const teamNFTListingsSalesHistory = (
  setFlowID,
  type: 'recent' | 'top',
) => `/listing/team-nft/${setFlowID}/history/${type}`;

export const maintenance = () => '/maintenance';

export const settings = (params = {}) => {
  return path(['settings'], params);
};

export const error = (params = {}) => {
  return path(['error'], params);
};

export const errorPath = (errorCode: (string | number)[], params = {}) => {
  return path(['error', ...errorCode], params);
};

/**
 * Challenges
 */

export const challengesActive = (params = {}) => {
  return path(['challenges', 'active'], params);
};

export const challengesPrevious = (params = {}) => {
  return path(['challenges', 'previous'], params);
};

export const challengesCompleted = (params = {}) => {
  return path(['challenges', 'completed'], params);
};

export const challengesUpcoming = (params = {}) => {
  return path(['challenges', 'upcoming'], params);
};

export const challengesDetail = (challengeID: string, params = {}) => {
  return path(['challenges', challengeID], params);
};

export const challengeSubmission = (challengeID: string, params = {}) => {
  return path(['challenges', challengeID, 'submit'], params);
};

export const challengeSuccess = (challengeID: string, params = {}) => {
  return path(['challenges', challengeID, 'success'], params);
};

export const challengeSaved = (challengeID: string, params = {}) => {
  return path(['challenges', challengeID, 'saved'], params);
};

/**
 * Queue paths
 */
export const goToQueuePath = (params = {}) => {
  return path([API_PATH, 'queue', 'go-to-queue'], params);
};

export const cancelQueuePath = (params = {}) => {
  return path([API_PATH, 'queue', 'go-to-queue'], {
    ...params,
    action: 'cancel',
  });
};

export const queueCallbackPath = (params = {}) => {
  return path([API_PATH, 'queue', 'queue-callback'], params);
};

export const queueItUserTokenPath = (params = {}) => {
  return path([API_PATH, 'queue', 'queue-it-user-token'], params);
};

export const clearQueueCookiePath = (params = {}) => {
  return path([API_PATH, 'queue', 'clear-queue-id'], params);
};

/**
 * Guest paths
 */
export const getDapperSessionPath = (params = {}) =>
  path([API_PATH, 'guest', 'dapper-session'], params);

export const getFinishSignupUrlPath = (params = {}) =>
  path([API_PATH, 'guest', 'finish-signup-url'], params);
