export enum FS {
  APPEARING = 'APPEARING',
  DETAIL_VIEW = 'DETAIL_VIEW',
  FRONT_FACE = 'FRONT_FACE',
  HERO_FACE = 'HERO_FACE',
  IDLE = 'IDLE',
  IN_VIEW = 'IN_VIEW',
  REVEALED = 'REVEALED',
  REVEALING = 'REVEALING',
  SCORES_FACE = 'SCORES_FACE',
  UNVEILING = 'UNVEILING',
}
export type FStype = typeof FS;

export enum EVENTS {
  AUTO_CLOSE = 'AUTO_CLOSE',
  CLOSE_DETAILS = 'CLOSE_DETAILS',
  FORCE_REVEAL = 'FORCE_REVEAL',
  REVEAL = 'REVEAL',
  SKIP_DETAIL_VIEW = 'SKIP_DETAIL_VIEW',
  START = 'START',
  UNVEIL = 'UNVEIL',
  VIEW_DETAILS = 'VIEW_DETAILS',
}
export type EVENTStype = typeof EVENTS;

export type Events = { data?: any; type: EVENTS };

export interface Context {
  EVENTS: typeof EVENTS;
  FS: typeof FS;
  REVEAL_FACE_DELAY: number;
  //
  autoAppearDelay: number;
  getPreload: (string) => {};

  media: any;
  momentNFT: any;
  momentRarity: string;
  // media:
  preloads: any;
}

export interface Schema {
  states: any;
}

export interface TypeState {
  context: Context;
  value: any;
}
