import { createMachine } from 'xstate';

function getDefinition(definition, { context }) {
  return {
    ...definition,
    context: {
      ...definition.context,
      ...context,
    },
  };
}

function getInterpreterOptions({
  custom,
  actions,
  delays,
  guards,
  services,
  ...rest
}) {
  return {
    actions: {
      ...actions,
      ...custom.actions,
    },
    delays: {
      ...delays,
      ...custom.delays,
    },
    guards: {
      ...guards,
      ...custom.guards,
    },
    services: {
      ...services,
      ...custom.services,
    },
    ...rest,
  };
}

export function getMachine<T>(
  definition: any,
  { context }: { context: T },
  {
    custom,
    actions,
    delays,
    guards,
    services,
    ...rest
  }: { actions: any; custom: any; delays?: any; guards: any; services: any },
) {
  return createMachine<T>(
    getDefinition(definition, { context }),
    getInterpreterOptions({
      actions,
      custom,
      delays,
      guards,
      services,
      ...rest,
    }),
  );
}

/**
 * Shallow comparison for complex selectors via useSelector
 * Will iterate over top level of keys and compare via ===
 *
 * If we just used the default comparison, the object is always
 * new so will trigger a re-render.
 *
 * @param previous - previous selector value
 * @param next - next selector value
 * @returns boolean (true if ALL k/v are equal)
 */
export const shallowCompare = (previous, next) => {
  return Object.keys(previous).every((k) => {
    return previous[k] === next[k];
  });
};
