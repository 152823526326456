import type { ContainerProps } from '@chakra-ui/react';
import {
  CloseButton,
  Container,
  useStyles,
  chakra,
  useMultiStyleConfig,
  forwardRef,
  StylesProvider,
} from '@chakra-ui/react';
import { AnimatePresence, motion } from 'framer-motion';
import { useContext, createContext } from 'react';
import type { ReactNode } from 'react';
import { FILTER_DRAWER_THEME_KEY } from 'src/theme/dss-skeleton/components';

interface FilterDrawerProviderProps {
  children: ReactNode;
  isOpen: boolean;
}

export interface FilterDrawerContextOptions {
  isOpen: boolean;
}

export interface FilterShellProps {
  children: ReactNode;
  isOpen: boolean;
}

export interface FilterDrawerProps {
  children: ReactNode;
}

export interface FilterTopBarProps {
  children: ReactNode;
  onCloseComplete?: () => void;
}

export interface FilterDrawerCloseButtonProps {
  onClose: () => void;
}

export interface FilterContentProps extends ContainerProps {
  children: ReactNode;
}

/**
 * FilterDrawerContext is responsible for providing the visibility
 * of the FilterDrawer component.
 */
const FilterDrawerContext = createContext<FilterDrawerContextOptions>({
  isOpen: true,
});

/**
 * Provides you with the current `layout` prop for the card.
 */
export const useFilterDrawer = () => {
  const context = useContext(FilterDrawerContext);

  if (context === undefined) {
    throw new Error(
      'useFilterDrawer must be used within a FilterShellProvider',
    );
  }

  return context;
};

/**
 * `FilterDrawerProvider` provides the FilterDrawer visibility to child components.
 *
 * @param {props.themeObject} isOpen The current visibility of the FilterDrawer
 * @namespace
 */
export const FilterDrawerProvider = (props: FilterDrawerProviderProps) => {
  const { isOpen, ...rest } = props;

  return <FilterDrawerContext.Provider value={{ isOpen }} {...rest} />;
};

export const FilterShell = forwardRef<FilterShellProps, 'div'>(
  ({ children, isOpen = false, ...props }, ref) => {
    /**
     * @todo Should probably be named FilterShell, something to think about a bit more.
     */
    const styles = useMultiStyleConfig(FILTER_DRAWER_THEME_KEY);

    return (
      <StylesProvider value={styles}>
        <FilterDrawerProvider isOpen={isOpen}>
          <chakra.div __css={styles.shell} {...props}>
            {children}
          </chakra.div>
        </FilterDrawerProvider>{' '}
      </StylesProvider>
    );
  },
);

export const FilterDrawer = ({ children }: FilterDrawerProps) => {
  const { isOpen } = useFilterDrawer();
  const styles = useStyles();

  return (
    <AnimatePresence>
      {isOpen && (
        <chakra.div
          key='drawer'
          as={motion.aside}
          __css={styles.drawer}
          animate={{
            transition: {
              duration: 0.25,
              type: 'tween',
              when: 'beforeChildren',
            },
            width: 380,
          }}
          exit={{
            transition: {
              delay: 0.33,
              duration: 0.25,
              type: 'tween',
              when: 'afterChildren',
            },
            width: 0,
          }}
          initial={{
            width: 380,
          }}
        >
          <chakra.div
            key='drawer-content'
            as={motion.div}
            sx={{
              '&::-webkit-scrollbar': {
                backgroundColor: `surface0`,
                borderRadius: 'full',
                padding: '1rem',
                width: '0.33rem',
              },
              '&::-webkit-scrollbar-thumb': {
                backgroundColor: `surface0`,
              },
            }}
            __css={styles.drawerContent}
            animate={{
              opacity: 1,
              transition: { delay: 0.25 },
            }}
            exit={{
              opacity: 0,
              transition: { duration: 0.25 },
            }}
            initial={{ opacity: 0 }}
          >
            {children}
          </chakra.div>
        </chakra.div>
      )}
    </AnimatePresence>
  );
};

export const FilterTopBar = (props: FilterTopBarProps) => {
  const { children } = props;
  const styles = useStyles();

  return <chakra.div __css={styles.topBar}>{children}</chakra.div>;
};

export const FilterDrawerCloseButton = ({
  onClose,
}: FilterDrawerCloseButtonProps) => <CloseButton onClick={onClose} />;

export const FilterContent = (props: FilterContentProps) => (
  <chakra.div gridArea='content' p={{ base: 1, lg: 4 }}>
    <Container maxW='auto' {...props} />
  </chakra.div>
);
