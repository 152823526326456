import { motion, Variants } from 'framer-motion';
import React from 'react';
import { useMeasure } from 'react-use';

import { RewardPassTier } from '__generated__/globalTypes';
import { useTranslation } from 'src/lib/translations';
import { GetActiveRewardPass_getActiveRewardPass_levels as Level } from 'src/modules/RewardPass/queries/__generated__/GetActiveRewardPass';
import { Box, BoxProps, Flex, FlexProps, VisuallyHidden } from 'src/theme';

import { containerAnimation } from '../utils';
import { ProgressBarMarkers } from './ProgressBarMarkers';

const diamond = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
  },
};

const Diamond = ({
  points,
  isRewardLocked,
  isLevelCompleted,
  isSmall,
  variants,
  ...props
}: {
  isLevelCompleted: boolean;
  isRewardLocked?: boolean;
  isSmall: boolean;
  points: number;
  variants: Variants;
} & FlexProps) => {
  // @note if we want to show the completed change this color and remove the condition in `outline`
  const diamondColor = isLevelCompleted
    ? 'transparent'
    : isRewardLocked
    ? 'special.playbook'
    : 'white';
  return (
    <Box
      {...props}
      position='absolute'
      top='50%'
      left={`${points}%`}
      alignItems='center'
      justifyContent='center'
      w={isSmall ? '6px' : '8px'}
      h={isSmall ? '6px' : '8px'}
      bg={diamondColor}
      transform='translateX(calc(-50% + 0.5px)) translateY(-50%) rotate(45deg)'
      outline={!isLevelCompleted && '2px solid black'}
      variants={variants}
    />
  );
};

const bar = {
  hidden: { opacity: 1, scaleX: 0 },
  visible: {
    opacity: 1,
    scaleX: 1,
  },
};

type LevelMarker = {
  isLevelCompleted: boolean;
  isRewardLocked: boolean;
  requiredPoints: number;
};

const Bar = ({
  value = 0,
  max = 100,
  levelMarkers = [],
  ...props
}: {
  levelMarkers?: LevelMarker[];
  max?: number;
  value?: number;
} & BoxProps) => {
  const { t } = useTranslation();
  const [ref, { width }] = useMeasure();
  const isSmall = width < 500;
  const progress = `${(value / max) * 100}%`;

  return (
    <Box {...props} ref={ref}>
      <Box
        className='containerAnimation'
        as={motion.div}
        position='relative'
        h='7px'
        animate='visible'
        initial='hidden'
        variants={containerAnimation}
      >
        {/* Background / Unfinished */}
        <Box
          position='absolute'
          zIndex='0'
          top='0'
          left='-4px' // offset for border radius
          w='calc(100% + 8px)' // offset for border radius
          h='100%'
          borderRadius='full'
          bgColor='gray.700'
        />
        {!!value && (
          <Box
            className='bar'
            as={motion.div}
            position='absolute'
            zIndex='1'
            top='0'
            left='-4px' // we add 4px/8px to offset the border radius
            w={`calc(${progress} + ${value === 100 ? '8px' : '4px'})`} // we offset 4px to account for the border radius
            minW={1}
            h='100%'
            bgGradient='linear(to-r, #3751D7,#6ED25D)'
            borderRadius='full'
            transformOrigin='top left'
            variants={bar}
          />
        )}
        <VisuallyHidden id='graphic-description'>
          {t('playbook.progressBar.description', {
            progress,
          })}
        </VisuallyHidden>
        {levelMarkers &&
          levelMarkers.map(
            ({ requiredPoints, isRewardLocked, isLevelCompleted }) => {
              return (
                <Diamond
                  className='diamond'
                  key={requiredPoints}
                  as={motion.div}
                  position='relative'
                  zIndex='2'
                  isLevelCompleted={isLevelCompleted}
                  isRewardLocked={isRewardLocked}
                  isSmall={isSmall}
                  points={requiredPoints}
                  variants={diamond}
                />
              );
            },
          )}
      </Box>
    </Box>
  );
};

export const ProgressBar = ({
  levels,
  userTier,
  userCurrentPoints,
  ...props
}: {
  levels?: Level[];
  userCurrentPoints: number;
  userTier: RewardPassTier;
} & BoxProps) => {
  /**
   * @note we return all the markers but we decorate them so the consuming
   * component can choose how to display them.
   */
  const levelMarkers = levels?.map((level) => {
    const eligibleReward = level.rewards.find((r) => {
      return r.tier === RewardPassTier.GRATIS || r.tier === userTier;
    });
    const levelCompleted = level.requiredPoints <= userCurrentPoints;
    return {
      isLevelCompleted: levelCompleted,
      isRewardLocked: !eligibleReward,
      requiredPoints: level.requiredPoints,
    };
  });

  return (
    <Box {...props}>
      <Flex position='relative' direction='column' gap={2}>
        <ProgressBarMarkers />
        <Bar levelMarkers={levelMarkers} max={100} value={userCurrentPoints} />
      </Flex>
    </Box>
  );
};
