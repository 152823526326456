import { sansPrefix, send, tx, decode, withPrefix } from '@onflow/fcl';
import {
  RESERVED_PACK_NFT,
  GET_USER_RESERVATION,
  SEARCH_PACK_NFT_OWNERSHIP,
} from 'src/edge/purchase/queries';
import { convertPrice, getInteraction } from 'src/edge/purchase/utils';
import { AnyEventObject, assign } from 'xstate';

import { Context } from './types';

export const actions = {
  assignError: assign({
    error: (_, event: any) => {
      return event;
    },
  }),
  assignPackNFT: assign({
    packNFT: (_: Context, event: any) => {
      return event.data.packNFT;
    },
  }),
  assignQueueToken: assign({
    queueToken: (_: Context, event: any) => {
      return event.data.queueToken;
    },
  }),
  assignReservationData: assign<Context, AnyEventObject>({
    interaction: (context: Context, event) => {
      const interaction = getInteraction({
        client: context.client,
        distribution: context.distribution,
        isCustomerSupportPack: context.isCustomerSupportPack,
        nftResourceIdentifer: parseInt(event.data.listing_resource_id),
        packNFTID: event.data.packNFTID,
        price: event.data.price,
        purchaseTx: context.purchaseTx,
        sellerAddress: withPrefix(event.data.storefront_address),
      });

      return interaction;
    },
    nftResourceIdentifer: (_: Context, event: any) => {
      return event.data.listing_resource_id;
    },
    packNFTID: (_: Context, event: any) => {
      return event.data.packNFTID;
    },
    sellerAddress: (_: Context, event: any) => {
      return event.data.storefront_address;
    },
  }),
  assignTransactionID: assign({
    transactionID: (_: Context, event: AnyEventObject) => {
      return event.data;
    },
  }),
};

export const guards = {
  isCustomerSupportReservation: (ctx) => ctx.isCustomerSupportPack,
  isReservationRequired: (ctx) => true,
};

export const services = {
  awaitTransactionExecuted: async (ctx: Context) => {
    return await tx(ctx.transactionID).onceExecuted();
  },
  awaitTransactionFinalized: async (ctx: Context) => {
    return await tx(ctx.transactionID).onceFinalized();
  },
  awaitTransactionSealed: async (ctx: Context) => {
    return await tx(ctx.transactionID).onceSealed();
  },
  pollPurchaseStatus: (context: Context) => (callback, onReceive) => {
    const buyerAddress = context.buyerAddress;

    const q = context.client.watchQuery<any, any>({
      context: { clientName: 'platformAPI' },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      pollInterval: 2000,
      query: SEARCH_PACK_NFT_OWNERSHIP,
      variables: {
        filters: {
          id: {
            eq: String(context.packNFTID),
          },
          type_name: {
            eq: `A.${sansPrefix(
              process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS,
            )}.PackNFT.NFT`,
          },
        },
      },
    });
    const subscription = q.subscribe(({ loading, data }) => {
      if (loading) return;

      const packNFT = data?.searchPackNft?.edges?.[0]?.node;

      const isOwnedByUser =
        packNFT != null &&
        sansPrefix(packNFT?.owner_address) === sansPrefix(buyerAddress);

      // Continue polling
      if (!isOwnedByUser) return;

      // Success
      callback({
        data: {
          ok: true,
          packNFT,
        },
        type: 'POLL_SUCCESS',
      });
    });

    onReceive((event) => {
      if (event.type === 'STOP') {
        return callback({
          type: 'POLL_ERROR',
        });
      }
    });

    return () => {
      q.stopPolling();
      subscription.unsubscribe();
    };
  },

  reserveCustomerSupportPack: async (context: Context) => {
    const { client, nftResourceIdentifer } = context;
    const { listing, id: packNFTID } = await getPackNft(
      client,
      String(nftResourceIdentifer),
    );
    const { storefront_address, price } = listing;
    return {
      listing_resource_id: nftResourceIdentifer,
      packNFTID,
      price: convertPrice(price),
      storefront_address,
    };
  },
  reservePackNFT: async (context: Context) => {
    const { client } = context;

    const { price, listing_resource_id } = await getUserReservation(
      client,
      context.distribution.id,
    );

    const { listing, id: packNFTID } = await getPackNft(
      client,
      String(listing_resource_id),
    );
    const { storefront_address } = listing;
    return {
      listing_resource_id,
      packNFTID,
      price,
      storefront_address,
    };
  },
  sendTransaction: async (ctx: Context) => {
    const interaction = await ctx.interaction();
    const transactionID = await send(interaction).then(decode);

    return transactionID;
  },
};
const getUserReservation = async (client, distributionId: number) => {
  const { errors, data } = await client.query({
    fetchPolicy: 'network-only',
    query: GET_USER_RESERVATION,
    variables: {
      distributionID: distributionId,
    },
  });
  const reservation = data?.getUserActiveReservation?.reservation;
  if (errors) {
    throw errors?.[0]?.message;
  }
  if (!reservation) {
    throw new Error('reservation not found');
  }
  return reservation;
};

const getPackNft = async (client, listing_resource_id: string) => {
  const { errors, data } = await client.query({
    context: { clientName: 'platformAPI' },
    query: RESERVED_PACK_NFT,
    variables: {
      filters: {
        listing: {
          listing_resource_id: {
            eq: listing_resource_id,
          },
        },
        type_name: {
          eq: `A.${sansPrefix(
            process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS,
          )}.PackNFT.NFT`,
        },
      },
    },
  });
  const packNFT = data?.searchPackNft?.edges?.[0]?.node;

  if (errors) {
    throw errors?.[0]?.message;
  }
  if (!packNFT) {
    throw new Error('packNFT not found');
  }
  return packNFT;
};
