import { ComponentStyleConfig } from 'src/theme';

export const CommonTheme: ComponentStyleConfig = {
  baseStyle: {
    burnedTooltip: {
      alignItems: 'center',
      display: 'flex',
      height: '1rem',
      marginLeft: '0.5rem',
      minWidth: '1rem',
      svg: {
        cursor: 'pointer',
        fill: '#8A94A6',
      },
    },
    chartWrapper: {
      maxWidth: {
        base: '100%',
        md: '32rem',
      },
      padding: '2rem',
      width: {
        lg: '32rem',
      },
    },
    descriptionItem: {
      _last: {
        borderRight: 'none',
        textAlign: 'right',
      },
      borderRight: '1px solid #393B45',
      flex: '1',
      padding: '0.75rem',
    },
    descriptionLabel: {
      color: '#8A94A6',
      fontSize: 'lg',
    },
    descriptionValue: {
      '&[data-type="burned"]': {
        color: '#EB3B62',
      },
      color: 'white',
      fontFamily: 'heading',
      fontSize: '3xl',
    },
    descriptionWrapper: {
      border: '1px solid #393B45',
      borderRadius: '0.75rem',
      justifyContent: 'space-between',
    },
    sectionHeader: {
      color: '#F8F8F8',
      fontSize: '4xl',
      fontWeight: '400',
      paddingBottom: '1.5rem',
    },
    statisticsCount: {
      color: 'white',
      fontFamily: 'poster',
      fontSize: '7xl',
      lineHeight: '4.5rem',
    },
    statisticsMore: {
      color: '#8A94A6',
      fontSize: 'sm',
      paddingTop: '0.5rem',
    },
    statisticsTitle: {
      color: 'white',
      fontFamily: 'heading',
      fontSize: {
        base: 'lg',
        sm: '2xl',
      },
      lineHeight: '1.5rem',
      paddingTop: '0.5rem',
      whiteSpace: 'nowrap',
    },
    updateText: {
      color: '#8A94A6',
      fontSize: 'sm',
    },
  },
  defaultProps: {},
  parts: [
    'burnedTooltip',
    'chartWrapper',
    'descriptionItem',
    'descriptionLabel',
    'descriptionValue',
    'descriptionWrapper',
    'sectionHeader',
    'statisticsCount',
    'statisticsMore',
    'statisticsTitle',
    'updateText',
  ],
};
