import { useApolloClient } from '@apollo/client';
import { useMachine } from '@xstate/react';
import { NextRouter } from 'next/router';
import { useMemo } from 'react';
import { useAnalytics } from 'src/lib/segment';
import { MomentsFilterOptions } from 'src/modules/Challenges/constants';

import { getFilterMachine } from '../fsm/machine';
import {
  FilterOptions,
  QueryFiltersProps,
  SortOptions,
  TeamNFTFilterOptions,
} from '../fsm/types';

export interface SearchProps {
  filters?: {
    activeFilters?: QueryFiltersProps;
    expandedFiltersLocalStorageKey?: string;
    filterOptions?: FilterOptions | TeamNFTFilterOptions | MomentsFilterOptions;
    frozenFilters?: QueryFiltersProps;
  };
  options: {
    analyticsPrefix: string;
  };
  pagination: {
    limit?: number;
    options?: any;
    query: any;
    queryDataPath: string;
    queryPaginationPath: string;
    variables: any;
  };
  router?: NextRouter;
  skip?: boolean;
  sort: {
    sortBy: string;
    sortOptions: SortOptions;
  };
}

export function useSearch({
  pagination,
  filters,
  sort,
  options,
  router = null,
  skip,
}: SearchProps) {
  const client = useApolloClient();
  const { track } = useAnalytics();

  const machine = useMemo(
    () =>
      getFilterMachine({
        custom: {
          context: {
            client,
            filters,
            options,
            pagination,
            router,
            skip,
            sort,
            track,
          },
        },
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const [state, send, actor] = useMachine(machine);

  return {
    actor,
    send,
    state,
  };
}
