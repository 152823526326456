import { CloseButton, Heading, useMediaQuery } from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import type { ReactNode } from 'react';
import { useTranslation } from 'src/lib/translations/useTranslation';
import { breakpoints, chakra, Text, useMultiStyleConfig } from 'src/theme';
import { READ_MORE_LINK_THEME_KEY } from 'src/theme/dss-skeleton/components';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';

interface Props {
  children?: ReactNode;
  labelReadLess?: string;
  labelReadMore?: string;
  noOfLines?: number;
}

export const ReadMore = ({
  children,
  noOfLines: lines = 3,
  labelReadMore,
  labelReadLess,
}: Props) => {
  const { t } = useTranslation();
  const { events, trackHandler } = useDssAnalytics();

  const ref = useRef(null);
  const [noOfLines, setNoOfLines] = useState(lines);
  const [showCta, setShowCta] = useState(true);
  const [showReadMoreBlock, setShowReadMoreBlock] = useState(false);
  const labelMore = labelReadMore || t('heroSection.description.readMore');
  const labelLess = labelReadLess || t('heroSection.description.readLess');
  const styles = useMultiStyleConfig(READ_MORE_LINK_THEME_KEY);

  const [isMdUp] = useMediaQuery(`screen and (min-width: ${breakpoints.md})`);

  useEffect(() => {
    const element = ref.current!;

    if (element.scrollHeight > element.clientHeight) {
      setNoOfLines(lines);
      setShowCta(true);
    } else {
      setShowCta(false);
    }
  }, [lines, showCta]);

  const toggleLines = () => {
    trackHandler(events.HERO_SECTION_DESCRIPTION_CLICK, {
      is_cta: false,
      item_label: noOfLines ? 'read_more' : 'read_less',
    });
    if (!noOfLines) {
      setNoOfLines(lines);
      return;
    }
    if (!isMdUp) {
      setShowReadMoreBlock(true);
      return;
    }
    setNoOfLines(null);
  };

  useEffect(() => {
    if (!isMdUp && !noOfLines) {
      setNoOfLines(3);
      setShowReadMoreBlock(true);
      return;
    }
    if (isMdUp && showReadMoreBlock) {
      setNoOfLines(null);
      setShowReadMoreBlock(false);
    }
  }, [isMdUp]);

  const closeBtnHandler = () => {
    trackHandler(events.HERO_SECTION_DESCRIPTION_CLICK, {
      is_cta: false,
      item_label: 'read_less',
    });
    setNoOfLines(lines);
    setShowReadMoreBlock(false);
  };

  return (
    <>
      <Text
        ref={ref}
        sx={{
          whiteSpace: 'pre-wrap',
        }}
        noOfLines={noOfLines}
        size='sm'
        variant='body'
      >
        {children}
      </Text>
      {showCta && (
        <Text
          sx={styles.link}
          cursor='pointer'
          onClick={toggleLines}
          size='sm'
          variant='value'
        >
          {noOfLines ? labelMore : labelLess}
        </Text>
      )}
      {showReadMoreBlock && (
        <chakra.div sx={styles.block}>
          <CloseButton
            sx={styles.closeButton}
            onClick={closeBtnHandler}
            size='lg'
          />
          <Heading mb='1.5rem' size='2xl'>
            {labelMore}
          </Heading>
          {children}
        </chakra.div>
      )}
    </>
  );
};
