// @ts-nocheck @todo remove this after extendTheme()'s typing is fixed
import { extendSkeleton } from '@dapperlabs/skeleton-ui';
import {
  BannerSectionTheme,
  CarouselTheme,
  CounterTheme,
  FilterDrawerTheme,
  GameHighlightsTheme,
  HeroSectionTheme,
  NavTheme,
  PlayerPerformanceTheme,
  TopNavTheme,
  VideoTheme,
  WaitlistModalTheme,
  LegalListTheme,
  TimelineTheme,
  LanguagePickerTheme,
  VolumeSliderTheme,
  ReadMoreLinkTheme,
  FooterTheme,
  TransactionsModalThemes,
  SelectSidebarTheme,
} from 'src/theme/dss-skeleton/components';
import { default as appThemes } from 'src/theme/dss-skeleton/themes';

// @todo this should not be exported. Chakra supports this without an export
export const breakpoints = {
  '2xl': '96em',
  lg: '62em',
  md: '48em',
  sm: '30em',
  tablet: '52em',
  xl: '80em',
};

// @todo: TS is unhappy about this, skeleton needs to be updated to accept a
// partial theme
const extendTheme = (...themes) =>
  extendSkeleton(
    {
      components: {
        ...BannerSectionTheme,
        ...CarouselTheme,
        ...CounterTheme,
        ...FilterDrawerTheme,
        ...FooterTheme,
        ...GameHighlightsTheme,
        ...HeroSectionTheme,
        ...LanguagePickerTheme,
        ...LegalListTheme,
        ...NavTheme,
        ...PlayerPerformanceTheme,
        ...ReadMoreLinkTheme,
        ...WaitlistModalTheme,
        ...TopNavTheme,
        ...TimelineTheme,
        ...VideoTheme,
        ...VolumeSliderTheme,
        ...SelectSidebarTheme,
        ...TransactionsModalThemes,
      },
    },
    appThemes.laliga,
    ...themes,
  );

export default extendTheme;
