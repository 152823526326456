import { ReactNode } from 'react';

import { Footer } from 'src/general/components/Footer';
import DefaultNavigation from 'src/general/components/Navigations/Default';
import { Box, Flex } from 'src/theme';

const FullWidthDefaultLayout = ({ children }: { children: ReactNode }) => {
  return (
    <Flex className='default-layout' flexDirection='column' h='100%'>
      <DefaultNavigation />
      <Box
        flexGrow={1}
        flexShrink={0}
        alignSelf='center'
        w='100%'
        minHeight={{ base: '40vh', md: '60vh' }}
      >
        {children}
      </Box>
      <Footer />
    </Flex>
  );
};

export default FullWidthDefaultLayout;
