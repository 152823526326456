import { config } from '@onflow/fcl';

export const toFclUFix64 = (price) => {
  const priceStr = price.toString();
  return priceStr.includes('.') ? priceStr : `${priceStr}.00`;
};

function withLocalStorage() {
  const LOCAL_STORAGE = {
    can: true,
    get: (key) => JSON.parse(localStorage.getItem(key)),
    put: (key, value) => localStorage.setItem(key, JSON.stringify(value)),
  };

  config().put('fcl.storage', LOCAL_STORAGE);
}

config()
  .put('app.detail.title', 'LaLiga Golazos')
  .put('discovery.wallet', process.env.NEXT_PUBLIC_WALLET_DISCOVERY) // Configure FCL's Wallet Discovery mechanism
  .put(
    'discovery.wallet.method',
    process.env.NEXT_PUBLIC_WALLET_DISCOVERY_METHOD,
  )
  .put('accessNode.api', process.env.NEXT_PUBLIC_ACCESS_NODE)
  // @NOTE: You are manually enabling a very experimental feature that is not
  // yet standard, use at your own risk. You can disable this warning by setting
  // fcl.warning.suppress.redir to true in your config
  .put('fcl.warning.suppress.redir', true)
  .put(
    '0xFungibleTokenContractAddress',
    process.env.NEXT_PUBLIC_FUNGIBLE_TOKEN_ADDRESS,
  )
  .put(
    '0xNonFungibleTokenContractAddress',
    process.env.NEXT_PUBLIC_NON_FUNGIBLE_TOKEN_ADDRESS,
  )
  .put(
    '0xNFTStorefrontContractAddress',
    process.env.NEXT_PUBLIC_NFT_STOREFRONT_ADDRESS,
  )
  .put('0xPackNFTContractAddress', process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS)
  .put('0xNFTContractAddress', process.env.NEXT_PUBLIC_NFT_ADDRESS)
  .put('0xNFTContractAddress2', process.env.NEXT_PUBLIC_NFT_ADDRESS)
  .put('0xPackNFTContractAddress', process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS)
  .put('0xMetadataViews', process.env.NEXT_PUBLIC_METADATA_VIEWS)
  .put(
    '0xDapperUtilityCoinContractAddress',
    process.env.NEXT_PUBLIC_DAPPER_UTILITY_COIN_ADDRESS,
  )
  .put(
    '0xDapperUtilityCoinContractAddress2',
    process.env.NEXT_PUBLIC_DAPPER_UTILITY_COIN_ADDRESS,
  )
  .put('0xMerchantAccountAddress', process.env.NEXT_PUBLIC_MERCHANT_ADDRESS)
  .put('0xTokenForwarding', process.env.NEXT_PUBLIC_TOKEN_FORWARDING)
  .put('0xIPackNFT', process.env.NEXT_PUBLIC_IPACK_NFT_ADDRESS);

if (typeof window !== 'undefined') {
  withLocalStorage();
}
