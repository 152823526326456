import { RewardPassTier } from '__generated__/globalTypes';
import last from 'lodash/last';
import { useRouter } from 'next/router';
import { ErrorBoundary } from 'src/general/components/ErrorBoundary/ErrorBoundary';
import { zIndexOf, Z_INDEX_TYPES } from 'src/general/utils/zIndex';
import { useFeatureFlags } from 'src/lib/launchDarkly';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';
import { useTranslation } from 'src/lib/translations';
import {
  useGetRewardPass,
  useGetNextEligibleRewardLevel,
  useRewardPassAnalytics,
  useUserHasProgress,
} from 'src/modules/RewardPass/hooks';
import { playbook } from 'src/modules/routes';
import {
  ButtonProps,
  Button,
  Flex,
  Heading,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Link,
  VisuallyHidden,
  forwardRef,
  Box,
  Text,
} from 'src/theme';
import { IconPlaybook } from 'src/theme/Icon';

import { Maintenance, RewardPassStatus, ProgressBar } from './';

const ViewPlaybookButton = forwardRef<ButtonProps, 'div'>((props, ref) => {
  const { t } = useTranslation();
  return (
    <Button
      {...props}
      ref={ref}
      as={Link}
      href={playbook()}
      size='xs'
      variant='outline'
    >
      {t('playbook.persistent.cta')}
    </Button>
  );
});

const Dot = () => {
  return (
    <Box
      pos='absolute'
      top='8px'
      right='8px'
      w='8px'
      h='8px'
      bg='special.playbook'
      borderRadius='50%'
      outline='3px solid black'
    />
  );
};

const PersistentNav = () => {
  const { t } = useTranslation();
  const { data: rewardPass } = useGetRewardPass();
  const { hasProgress, clearProgress } = useUserHasProgress();
  const { track } = useRewardPassAnalytics();
  const { nextLevelWithEligibleReward, nextEligibleReward } =
    useGetNextEligibleRewardLevel();

  if (!rewardPass) return null;

  return (
    <Menu
      onClose={() => {
        clearProgress();
      }}
    >
      <MenuButton
        bg='black'
        _hover={{ background: 'black' }}
        onClick={() => {
          track(SEGMENT_EVENTS_ID_ENUM.PLAYBOOK_PERSISTENT_DESKTOP_OPEN);
        }}
        paddingX='0'
      >
        {hasProgress && <Dot />}
        <IconPlaybook
          boxSize='20px'
          color='white'
          aria-describedby='playbook-icon'
        />
        <VisuallyHidden id='playbook-icon'>
          {t('playbook.icon.description')}
        </VisuallyHidden>
      </MenuButton>
      <MenuList
        zIndex={zIndexOf(Z_INDEX_TYPES.MENU)}
        overflow='hidden'
        w='303px'
        p={0}
        bg='black'
        borderRadius='8px'
      >
        <RewardPassStatus
          expiresAt={rewardPass.endAt}
          forceCompact
          levels={rewardPass.levels}
          nextRewardImageUrl={nextEligibleReward?.imageUrl}
          nextRewardRequiredPoints={nextLevelWithEligibleReward?.requiredPoints}
          nextRewardTitle={nextEligibleReward?.title}
          title={rewardPass.title}
          userCurrentPoints={rewardPass.userProgress?.currentPoints}
          userTier={rewardPass.userProgress?.tier}
        />
        <Flex
          alignItems='center'
          justifyContent='center'
          flexDir='column'
          p={3}
          borderTop='1px'
          borderTopColor='gray.600'
        >
          {hasProgress && (
            <Box
              mb={2}
              px={2}
              py={1}
              borderStyle='solid'
              borderColor='special.playbook'
              borderLeftWidth='3px'
            >
              <Text size='sm'>
                {rewardPass.userProgress.tier === RewardPassTier.GRATIS
                  ? t('playbook.notifier.unlock.gratis')
                  : t('playbook.notifier.unlock.premium')}
              </Text>
            </Box>
          )}
          <MenuItem
            as={ViewPlaybookButton}
            width='auto' // @note: the theme doesn't like this
            _hover={{ background: 'white' }}
            onClick={() => {
              track(
                SEGMENT_EVENTS_ID_ENUM.PLAYBOOK_PERSISTENT_DESKTOP_CTA_CLICK,
              );
            }}
          />
        </Flex>
      </MenuList>
    </Menu>
  );
};

export const MaintenancePersistentNav = () => {
  const { t } = useTranslation();
  return (
    <Menu>
      <MenuButton bg='black' _hover={{ background: 'black' }} paddingX='0'>
        <IconPlaybook
          boxSize='20px'
          color='white'
          aria-describedby='playbook-icon'
        />
        <VisuallyHidden id='playbook-icon'>
          {t('playbook.icon.description')}
        </VisuallyHidden>
      </MenuButton>
      <MenuList
        zIndex={zIndexOf(Z_INDEX_TYPES.MENU)}
        overflow='hidden'
        w='303px'
        px={0}
        py={4}
        bg='black'
        borderRadius='8px'
      >
        <Maintenance />
      </MenuList>
    </Menu>
  );
};

export const RewardPassPersistentPresenter = () => {
  const { isPlaybookEnabled, isPlaybookMaintenanceEnabled } = useFeatureFlags();

  if (!isPlaybookEnabled) return null;

  if (isPlaybookMaintenanceEnabled) return <MaintenancePersistentNav />;

  return (
    <ErrorBoundary renderError={() => null}>
      <PersistentNav />
    </ErrorBoundary>
  );
};

// This is a super pared-down placeholderish version of the reward pass status we can expand on.
// needs a cta to click i think, but for now whole thing clickable
const PersistentMobileNav = () => {
  const { t } = useTranslation();

  const router = useRouter();
  const { hasProgress, clearProgress } = useUserHasProgress();
  const { data: rewardPass } = useGetRewardPass();
  const { track } = useRewardPassAnalytics();
  const { nextLevelWithEligibleReward } = useGetNextEligibleRewardLevel();

  const userPointsToNextLevel =
    nextLevelWithEligibleReward?.requiredPoints -
    (rewardPass?.userProgress?.currentPoints || 0);

  const finalLevelRequiredPoints = last(rewardPass?.levels)?.requiredPoints;

  // @todo add loading state
  if (!rewardPass) return null;

  if (router.pathname.includes('/playbook')) return null;

  return (
    <Flex
      as={Link}
      alignItems='center'
      gap={3}
      px={6}
      py={3}
      bg='black'
      borderWidth='1px'
      borderStyle='solid none solid none'
      _hover={{
        textDecoration: 'none',
      }}
      href={playbook()}
      onClick={() => {
        track(SEGMENT_EVENTS_ID_ENUM.PLAYBOOK_PERSISTENT_MOBILE_CTA_CLICK);
        clearProgress();
      }}
    >
      <Box>
        {hasProgress && <Dot />}
        <IconPlaybook
          boxSize='20px'
          color='white'
          aria-describedby='playbook-icon2'
        />
        <VisuallyHidden id='playbook-icon2'>
          {t('playbook.icon.description')}
        </VisuallyHidden>
      </Box>

      <Flex
        height='100%'
        borderWidth='1px'
        borderStyle='none none none solid'
      />

      <ProgressBar
        flex='1 1 auto'
        levels={rewardPass.levels}
        userCurrentPoints={rewardPass?.userProgress?.currentPoints}
        userTier={rewardPass?.userProgress?.tier}
      />

      <Heading
        as='span'
        fontStyle='italic'
        whiteSpace='nowrap'
        size='xxs'
        variant='tall'
      >
        {rewardPass?.userProgress?.currentPoints < finalLevelRequiredPoints
          ? t('playbook.persistent.rewardIn', { count: userPointsToNextLevel })
          : t('playbook.persistent.complete')}
      </Heading>
    </Flex>
  );
};

export const RewardPassPersistentMobilePresenter = () => {
  const { isPlaybookEnabled, isPlaybookMaintenanceEnabled } = useFeatureFlags();

  if (!isPlaybookEnabled || isPlaybookMaintenanceEnabled) return null;

  return (
    <ErrorBoundary renderError={() => null}>
      <PersistentMobileNav />
    </ErrorBoundary>
  );
};
