import {
  editionDetail,
  NFTListingEditionCard,
  LanguageContext,
} from '@dapperlabs/core-fe';
import { useAnalytics } from '@dapperlabs/react-analytics';
import { useRouter } from 'next/router';
import React, { useContext } from 'react';
import { useGetEditionByIds } from 'src/general/hooks/useGetEditionByIds';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment';
import { useTranslation } from 'src/lib/translations';
import { Box, Flex, Heading, Grid, Link, useMultiStyleConfig } from 'src/theme';

type FeaturedMomentsProps = {
  [key: string]: unknown;
  data: {
    editionIds: string[];
    link: {
      label: string;
      url: string;
    };
    title: string;
  };
};

export const FeaturedMoments = ({
  data: { title, link, editionIds },
  ...props
}: FeaturedMomentsProps) => {
  const styles = useMultiStyleConfig('FeaturedMoments');

  const { languages, selectedLanguage } = useContext(LanguageContext);
  const { t } = useTranslation();
  const { editionData } = useGetEditionByIds(editionIds, t, languages);
  const router = useRouter();
  const { track } = useAnalytics();

  //ToDo: refactor
  const translationLabels = {
    BuyNow: t('MarketplaceSearch.BuyNow'),
    LowestAsk: t('MarketplaceSearch.LowestAsk'),
    MomentsBurned: t('MarketplaceSearch.MomentsBurned'),
    MomentsBurned_plural: t('MarketplaceSearch.MomentsBurned_plural'),
    ViewMomentDetails: t('MarketplaceSearch.ViewMomentDetails'),
    clearFilters: t('MarketplaceSearch.CTA.ClearAll'),
    filters: t('MarketplaceSearch.Filters'),
    result: t('MarketplaceSearch.SearchResults'),
    results: t('MarketplaceSearch.SearchResults_plural'),
    resultsEmpty: t('MarketplaceSearch.NoResults'),
    sortBy: t('MarketplaceSearch.SortBy'),
  };

  if (!editionData.length) {
    return null;
  }

  const trackLink = () => {
    track(SEGMENT_EVENTS_ID_ENUM.HOMEPAGE_ITEM_CLICK, {
      item_label: link.label,
      item_url_to: link.url,
      section_id: title,
    });
  };

  const trackEditionCard = (event) => {
    const editionId = event.target.dataset.editionId;

    if (editionId) {
      track(SEGMENT_EVENTS_ID_ENUM.HOMEPAGE_ITEM_CLICK, {
        item_label: translationLabels.BuyNow,
        item_url_to: editionDetail(editionId),
        section_id: title,
      });
      router.push(editionDetail(editionId));
    }
  };

  return (
    <Box {...props}>
      <Flex sx={styles.header}>
        <Heading sx={styles.title}>{title}</Heading>
        <Link sx={styles.link} href={link.url} onClick={trackLink}>
          {link.label}
        </Link>
      </Flex>
      <Grid sx={styles.grid}>
        {editionData.map((edition) => (
          <NFTListingEditionCard
            key={`featured-moment-${edition.id}`}
            copy={translationLabels}
            editionId={edition.id}
            heading={edition.title}
            line1={edition.type}
            lowestAsk={edition.lowestAsk}
            maxMinted={edition.maxMinted}
            momentImage={edition.momentImage[selectedLanguage.value].src}
            onBuyNow={trackEditionCard}
            rarityTier={edition.rarityTier}
            serialNumber={edition.serialNumber}
            totalBurned={edition.totalBurned}
          />
        ))}
      </Grid>
    </Box>
  );
};
