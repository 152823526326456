import type { ComponentStyleConfig } from '@chakra-ui/react';

export const timeline: ComponentStyleConfig = {
  baseStyle: {
    container: {
      alignItems: 'center',
      display: 'flex',
    },
    gamePeriod: {
      margin: '8',
      textAlign: 'center',
      width: '8',
    },
    highlights: {
      _after: {
        backgroundColor: 'gray.800',
        content: '""',
        pointerEvents: 'none',
        height: '5px',
        position: 'absolute',
        top: 'calc(50% - 5px / 2)',
        width: '100%',
      },
      display: 'flex',
      flexDirection: 'row',
      minHeight: '150px',
      minWidth: '450px',
      position: 'relative',
    },
    line: {
      backgroundColor: 'gray.800',
      height: '20px',
      pointerEvents: 'all',
      width: '5px',
    },
    moment: {
      alignItems: 'center',
      alignSelf: 'flex-start',
      display: 'flex',
      flexDirection: 'column',
      height: '50%',
      justifyContent: 'flex-en',
      pointerEvents: 'none',
      paddingRight: 0,
      position: 'absolute',
    },
    wrapper: {
      background: 'gray.900',
      border: '1px',
      padding: '4',
      display: 'inline-block',
      position: 'relative',
      borderColor: 'gray.500',
      borderRadius: '12px',
      minWidth: '100%',
    },
  },
  defaultProps: {
    variant: 'common',
  },
  parts: ['wrapper', 'container', 'gamePeriod', 'highlights', 'moment', 'line'],
};

export default timeline;
