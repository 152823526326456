import { gql } from '@apollo/client';

export const GET_USER_ACTIVE_RESERVATION = gql`
  query GET_USER_RESERVATION($searchInput: GetUserActiveReservationInput) {
    getUserActiveReservation(input: $searchInput) {
      reservation {
        user_id
        distribution_id
        price
        listing_resource_id
        expired_at
      }
    }
  }
`;
