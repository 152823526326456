import { gql } from '@apollo/client';

export const QUERY_MARKETPLACE_EDITIONS = gql`
  query searchMarketplaceEditions_listingDetails(
    $byEditionFlowIDs: [Int]
    $after: String
    $sortBy: MarketplaceEditionSortType
  ) {
    searchMarketplaceEditions(
      input: {
        after: $after
        first: 1
        filters: { byEditionFlowIDs: $byEditionFlowIDs }
        sortBy: $sortBy
      }
    ) {
      edges {
        node {
          id
          editionFlowID
          lowestPrice
          averageSale
          highestSale
          totalListings
          edition {
            id
            flowID
            series {
              id
              name
              offChainMetadata {
                description
              }
            }
            set {
              id
              name
              offChainMetadata {
                description
              }
            }
            play {
              id
              metadata {
                classification
                state
                description
                playType
                videos {
                  type
                  url
                }
                audio {
                  url
                  narrator {
                    profilePicture
                    name
                    position
                    organization
                  }
                }
                images {
                  type
                  url
                }
                playerFullName
                playerBirthdate
                playerBirthplace
                playerDraftYear
                playerDraftRound
                playerDraftNumber
                playerHeight
                playerWeight
                playerNumber
                playerPosition
                gameDate
                week
                homeTeamScore
                awayTeamScore
                teamName
                teamID
                homeTeamID
                awayTeamID
              }
            }
            maxMintSize
            currentMintSize
            tier
            numMomentsOwned
            numMomentsInPacks
            numMomentsUnavailable
            numMomentsBurned
            badges {
              id
              title
              slug
              description
            }
          }
        }
        cursor
      }
    }
  }
`;

export const VARIABLES_MARKETPLACE_EDITIONS = ({
  byEditionFlowIDs = [],
  after = '',
  sortBy = undefined,
} = {}) => {
  return {
    after,
    byEditionFlowIDs,
    sortBy,
  };
};
