import type { ComponentStyleConfig } from 'src/theme/index';

export const theme: ComponentStyleConfig = {
  baseStyle: {
    btnBuyNow: {
      fontFamily: 'mono',
      fontSize: '18px',
      fontWeight: '400',
      height: '48px',
      letterSpacing: '-0.16px',
      marginTop: 'auto',
      padding: '14px',
    },
    btnClose: {
      backdropFilter: 'blur(15px)',
      background: 'rgba(138, 148, 166, 0.25)',
      color: '#D6DAEB',
      fontSize: '12px',
      height: '24px',
      letterSpacing: '0.32px',
      marginLeft: 'auto',
      minHeight: '24px',
      width: '54px',
    },
    column: {
      color: '#8A94A6',
      fontFamily: 'mono',
      fontSize: '12px',
      letterSpacing: '0.32px',
    },
    container: {
      height: '100vh',
      position: 'fixed',
      top: '0',
      transition: 'background 0.25s linear, visibility 0.25s linear',
      width: '100vw',
      willChange: 'transform',
      zIndex: 1400,
    },
    editionInfoContainer: {
      borderBottom: '1px solid #393B45',
      height: '72px',
      marginLeft: '-24px',
      paddingBottom: '15px',
      paddingLeft: '24px',
      width: '100vw',
    },
    editionInfoEditionSize: {
      color: '#F0F2FD',
      fontFamily: 'main',
      fontSize: '12px',
      fontWeight: '400',
      letterSpacing: '0.45px',
      lineHeight: '16px',
      marginLeft: '4px',
    },
    editionInfoName: {
      color: '#F0F2FD',
      fontFamily: 'main',
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '0.3px',
      lineHeight: '19px',
    },
    editionInfoViewMore: {
      color: '#D6DAEB',
      cursor: 'pointer',
      fontFamily: 'main',
      fontSize: '12px',
      letterSpacing: '0.32px',
      lineHeight: '18px',
      textDecoration: 'underline',
    },
    itemCommon: {
      alignItems: 'center',
      color: '#F0F2FD',
      fontFamily: 'main',
      fontSize: '14px',
      lineHeight: '44px',
      paddingLeft: '12px',
    },
    itemContainer: {
      bg: '#17171B',
      borderRadius: '8px',
      cursor: 'pointer',
      h: '44px',
      mt: '4px',
    },
    itemId: {
      fontWeight: '400',
      letterSpacing: '0.3px',
      width: '120px',
    },
    itemNumber: {
      width: { base: '100px', md: '100px' },
    },
    itemPrice: {
      fontWeight: 'bold',
      letterSpacing: '0.16px',
      width: { base: '100%', md: '120px' },
    },
    selectContainer: {
      alignItems: 'center',
      background: '#17171B',
      border: '1px solid #393B45',
      borderRadius: '8px',
      color: '#8A94A6',
      cursor: 'pointer',
      fontFamily: 'main',
      fontSize: '14px',
      fontWeight: '400',
      height: '40px',
      letterSpacing: '0.3px',
      padding: '0 12px',
      position: 'relative',
      width: '100%',
    },
    selectItemWrapper: {
      _hover: {
        backgroundColor: '#393B45',
      },
      alignItems: 'center',
      height: '44px',
      padding: '0 9px',
      width: '100%',
    },
    selectItemsWrapper: {
      background: '#17171B',
      border: '1px solid #1F1F1D',
      borderRadius: '8px',
      boxShadow: '0px 0px 24px rgba(240, 242, 253, 0.2)',
      flexDirection: 'column',
      left: 0,
      padding: '8px 0',
      position: 'absolute',
      top: '45px',
      width: '100%',
    },
    selectedItem: {
      backgroundColor: '#212127',
      border: '1px solid #F0F2FD',
    },
    sidebarWrapper: {
      backdropFilter: 'blur(15px)',
      backgroundColor: 'black',
      borderLeft: { base: 'none', md: '2px solid #393B45' },
      flexDirection: 'column',
      height: '100%',
      padding: '24px',
      position: 'absolute',
      right: 0,
      shadow: '-4px 8px 20px rgba(0, 0, 0, 0.8)',
      transition: 'transform 0.25s ease-in-out',
      width: { base: '100%', md: '512px' },
      zIndex: 9999,
    },
    title: {
      color: '#FFFFFF',
      fontFamily: 'main',
      fontSize: '18px',
      fontWeight: '600',
      lineHeight: '24px',
      margin: '16px 0 0 0',
    },
  },
  parts: [],
};

export const SELECT_SIDEBAR_THEME_KEY = 'SelectSidebar';
export const SelectSidebarTheme = {
  [SELECT_SIDEBAR_THEME_KEY]: theme,
};
