import { DonutChartTheme } from './DonutChart';
import { FeaturedMomentsTheme } from './FeaturedMoments';
import { MomentCirculationTheme } from './MomentCirculation';
import { SalesHistoryTheme } from './SalesHistory';

export const LaligaComponentsTheme = {
  components: {
    DonutChart: DonutChartTheme,
    ...MomentCirculationTheme,
    FeaturedMoments: FeaturedMomentsTheme,
    SalesHistory: SalesHistoryTheme,
  },
};
