import { documentToHtmlString } from '@contentful/rich-text-html-renderer';
import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import {
  BannerSectionProps,
  Text,
  Heading,
  Link,
  shapiro,
  UnorderedList,
  OrderedList,
  ListItem,
} from '@dapperlabs/core-fe';
import map from 'lodash/map';
import { ENTRIES_IDS, MediaContentType } from 'src/lib/contentful/entries';
import { HeadingDeprecated, TextDeprecated } from 'src/theme';

let contentfulClient = null;

export const getContentfulClient = () => {
  if (contentfulClient) {
    return contentfulClient;
  }

  contentfulClient = require('contentful').createClient({
    accessToken: process.env.NEXT_PUBLIC_CONTENTFUL_ACCESS_TOKEN,
    environment: process.env.NEXT_PUBLIC_CONTENTFUL_ENVIRONMENT,
    space: process.env.NEXT_PUBLIC_CONTENTFUL_SPACE_ID,
  });

  return contentfulClient;
};

/**
 * This function will return you the right contentful entry ID based on your current environment
 * @param {String} label - contentful entry constant name
 * @return {String} contentful ID for a specific label from the constant file
 */
export const getContentfulEntryID = (label: keyof typeof ENTRIES_IDS) => {
  if (!label) return;

  return ENTRIES_IDS[label];
};

export const richTextOptions = {
  renderMark: {
    [MARKS.BOLD]: (text) => <b>{text}</b>,
  },
  renderNode: {
    [BLOCKS.HEADING_2]: (node, children) => (
      <HeadingDeprecated as='h2' mt='64px' mb='24px' size='xs'>
        {children}
      </HeadingDeprecated>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <TextDeprecated color='grey.text' fontFamily='main' marginY={4}>
        {children}
      </TextDeprecated>
    ),
    [BLOCKS.OL_LIST]: (node, children) => (
      <HeadingDeprecated as='h2' color='white'>
        {children}
      </HeadingDeprecated>
    ),
  },
  renderText: (text) =>
    text.split('\n').flatMap((line, i) => [i > 0 && <br />, line]),
};

const getMediaType = (type: string) => {
  if (type?.includes(MediaContentType.IMAGE)) {
    return MediaContentType.IMAGE;
  }
  if (type?.includes(MediaContentType.VIDEO)) {
    return MediaContentType.VIDEO;
  }

  return type;
};

export const reformattingBannersData = (
  contentfulFields = [],
): Array<BannerSectionProps> => {
  return contentfulFields.map((item) => {
    const { fields: banner } = item;

    return {
      additionalLink: {
        link: banner?.additionalLinkPath ?? '',
        text: banner?.additionalLinkLabel ?? '',
      },
      analytics: banner?.analyticEvent,
      announcement: {
        additionalText: banner?.announcementText ?? '',
        text: banner?.announcementTitle ?? '',
      },
      bannerBackground: banner?.backgroundColorLeftPart ?? 'black',
      bannerBackgroundRightPart:
        banner?.backgroundColorRightPart ?? banner?.backgroundColorLeftPart,
      button: {
        link: banner?.buttonLink ?? '',
        text: banner?.buttonText ?? '',
        variant: banner?.buttonVariant ?? null,
      },
      description: documentToHtmlString(banner?.description),
      eventName: banner?.eventName ?? '',
      isMainBanner: banner?.isMainBanner,
      media: {
        fullscreenMediaUrl: banner?.fullscreenMediaUrl?.fields?.file?.url,
        fullscreenVideoList:
          banner?.fullscreenVideos?.map((mediaItem) => {
            return {
              fullscreenSrc:
                mediaItem?.fields?.fullscreenSrc?.fields?.file?.url,
              id: mediaItem?.sys?.id,
              previewSrc: mediaItem?.fields?.previewSrc?.fields?.file?.url,
              src: mediaItem?.fields?.src?.fields?.file?.url,
              type: MediaContentType.VIDEO,
            };
          }) ?? [],
        isVideo: banner?.isVideo ?? false,
        placeholderImage:
          banner?.placeholderImageForMedia?.fields?.file?.url ?? '',
        position: banner?.mediaPosition ?? 'left',
        stickedPosition: banner.stickedPosition ?? '',
        urlList: map(banner?.media, (mediaItem) => ({
          id: mediaItem?.sys?.id,
          src: mediaItem?.fields?.file?.url,
          type: getMediaType(mediaItem?.fields?.file?.contentType),
        })),
      },
      sizeBanner: banner?.sizeBanner ?? 'small',
      title: banner?.title ?? '',
    };
  });
};

export const mapFeatureMoments = (contentfulField: any): any => ({
  editionIds: contentfulField?.editionIds || [],
  link: contentfulField?.link?.fields || { url: '' },
  title: contentfulField?.title || '',
});

export const packOddsReduceSum = (acc: number, curr: any) =>
  (acc += curr.value);

export const getBlockOptions = ({
  color = '#D6DAEB',
  fontSize = '14px',
}: {
  color?: string;
  fontSize?: string;
}) => {
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text
          mt={1}
          color={color}
          {...shapiro}
          fontSize={fontSize}
          letterSpacing='0.16px'
          whiteSpace='pre-line'
        >
          {children}
        </Text>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
      [BLOCKS.HEADING_1]: (node, children) => (
        <Heading as='h1'>{children}</Heading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading as='h2'>{children}</Heading>
      ),
      [BLOCKS.HEADING_3]: (node, children) => (
        <Heading as='h3'>{children}</Heading>
      ),
      [BLOCKS.HEADING_2]: (node, children) => (
        <Heading as='h4'>{children}</Heading>
      ),
      [INLINES.HYPERLINK]: (node, children) => (
        // eslint-disable-next-line react/destructuring-assignment
        <Link href={node?.data?.uri || ''}>{children}</Link>
      ),
    },
  };
};
