import { gql } from '@apollo/client';

import { UserProfileFragment } from 'src/edge/getMyProfile';

export const UPSERT_USER_PROFILE_MUTATION_V3 = gql`
  mutation UpsertUserProfileV3($input: UpsertUserProfileInput!) {
    upsertUserProfileV3(input: $input) {
      isCreated
      userProfile {
        ...UserProfileFragment
      }
    }
  }
  ${UserProfileFragment}
`;
