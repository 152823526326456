import merge from 'lodash/merge';
import { graphql } from 'msw';
import type { PartialDeep } from 'type-fest';

export const MOCK_USER_FLOW_ADDRESS = '0c029c67c7c9584d';

export const handlers = {
  getMyProfile: () => {
    return getMyProfileHandler(userProfileFactory());
  },
};

export const getMyProfileHandler = (userProfile: any, name = 'GetMyProfile') =>
  graphql.query<any>(name, (req, res, ctx) => {
    return res.once(
      ctx.data({
        getMyProfile: {
          __typename: 'GetMyProfileResponse',
          userProfile,
        },
      }),
    );
  });

export const userProfileFactory = (overrides: PartialDeep<any> = {}): any =>
  merge(
    {},
    {
      ...MOCK_USER_PROFILE(),
    } as any,
    overrides,
  );

export function MOCK_USER_PROFILE() {
  return {
    __typename: 'UserProfile',
    createdAt: '2022-10-18T14:44:55.998458Z',
    dapperID: 'google-oauth2|107569743084164988022',
    email: 'test.user@dapperlabs.com',
    flowAddress: MOCK_USER_FLOW_ADDRESS,
    phoneNumber: null,
    preferredCurrency: 'EUROS',
    preferredLanguage: 'ENG',
    profileImageUrl:
      'https://lh3.googleusercontent.com/a/AATXAJxKABeqPV9TspoB7uyZXQECBonY1-h7ar8_xK44=s96-c',
    username: 'test_user',
  };
}
