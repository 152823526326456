import {
  captureException,
  MaintenanceBannerProvider,
  themes,
} from '@dapperlabs/core-fe';
import { getCookie } from 'cookies-next';
import type { AppProps } from 'next/app';
import App, { NextWebVitalsMetric } from 'next/app';
import { useRouter } from 'next/router';
import PropTypes from 'prop-types';
import { useEffect } from 'react';

import 'src/lib/fcl';
import 'src/lib/translations/i18n';
import { SessionProvider } from 'src/edge/session';
import { ErrorBoundaryPage } from 'src/general/components/ErrorBoundary/ErrorBoundaryPage';
import LayoutWrapper from 'src/general/components/Layouts/LayoutWrapper';
import { CustomModal } from 'src/general/components/Modal/Modal';
import { ModalContextProvider } from 'src/general/components/Modal/ModalProvider';
import { welcomeConsole } from 'src/general/utils/welcome';
import { ApolloProvider } from 'src/lib/apollo';
import { FeatureFlagsProvider, GatingWrapper } from 'src/lib/launchDarkly';
import { MixpanelScript } from 'src/lib/mixpanel';
import { OneTrustScript } from 'src/lib/oneTrust';
import { AnalyticsProvider, ServiceIdentifier } from 'src/lib/segment';
import { Onboarding } from 'src/modules/Onboarding';
import { PrepareFlowAddressModal } from 'src/modules/PrepareFlowAddressModal';
import { GlobalAnnouncement } from 'src/modules/Shared';
import { initMSW } from 'src/test-utils/msw/initMSW';
import { ChakraProvider, Fonts, Layout, theme } from 'src/theme';

import 'src/theme/dss-skeleton/themes/fonts/state-wide/600.css';
import 'src/theme/dss-skeleton/themes/fonts/state-wide/800.css';
import 'src/theme/dss-skeleton/themes/fonts/simplon-mono/400.css';
import 'src/theme/dss-skeleton/themes/fonts/trim/400.css';
import 'src/theme/dss-skeleton/themes/fonts/trim-poster/500.css';
import 'src/theme/dss-skeleton/themes/fonts/roboto-flex/600.css';
import { WaitingForTransferModal } from 'src/modules/Guest';
type MarketplaceAppProps = AppProps & { flags: never; locale: string };

initMSW();

const MarketplaceApp = ({
  Component,
  pageProps,
  flags,
  locale,
}: MarketplaceAppProps): JSX.Element => {
  useEffect(() => {
    welcomeConsole();
  }, []);
  const router = useRouter();

  return (
    <ApolloProvider pageProps={pageProps}>
      <SessionProvider>
        <FeatureFlagsProvider flags={flags}>
          <AnalyticsProvider
            segmentWriteToken={process.env.NEXT_PUBLIC_SEGMENT_WRITE_TOKEN}
          >
            <ServiceIdentifier />
            <OneTrustScript
              token={process.env.NEXT_PUBLIC_ONE_TRUST_ACCESS_TOKEN}
            />
            <ChakraProvider theme={theme(themes[router.query.type as string])}>
              <Fonts />
              <Layout />
              <ErrorBoundaryPage>
                <ModalContextProvider>
                  <MaintenanceBannerProvider>
                    <GatingWrapper>
                      <LayoutWrapper Component={Component} {...pageProps}>
                        <Component {...pageProps} />
                      </LayoutWrapper>
                    </GatingWrapper>
                    <Onboarding />
                    <WaitingForTransferModal redirectEnabled={false} />
                    <PrepareFlowAddressModal />
                    <GlobalAnnouncement locale={locale} />
                    <MixpanelScript />
                  </MaintenanceBannerProvider>
                  <CustomModal />
                </ModalContextProvider>
              </ErrorBoundaryPage>
            </ChakraProvider>
          </AnalyticsProvider>
        </FeatureFlagsProvider>
      </SessionProvider>
    </ApolloProvider>
  );
};

MarketplaceApp.getInitialProps = async (appContext) => {
  let flags;
  const locale =
    getCookie('language', {
      req: appContext?.ctx?.req,
      res: appContext?.ctx?.res,
    }) || 'en-US';

  try {
    // We only ever want to fetch anonymous flags on ssr
    if (appContext?.ctx?.req) {
      const getFlagsForUser =
        await require('shared/launchDarkly/getFlagsForUser').default;
      flags = await getFlagsForUser({});
    }
  } catch (error) {
    captureException(error, {
      req: appContext?.ctx?.req,
      res: appContext?.ctx?.res,
    });
  }

  const appProps = await App.getInitialProps(appContext);

  return {
    ...appProps,
    flags,
    locale,
  };
};

MarketplaceApp.propTypes = {
  Component: PropTypes.oneOfType([PropTypes.node, PropTypes.func]).isRequired,
  pageProps: PropTypes.object.isRequired,
};

export function siteUrl(): string {
  return process.browser ? window.location.origin : process.env.SITE_URL;
}

export function reportWebVitals(metric: NextWebVitalsMetric): void {
  const body = JSON.stringify(metric);
  const url = `${siteUrl()}/api/metrics`;

  // Use `navigator.sendBeacon()` if available, falling back to `fetch()`.
  if (navigator.sendBeacon) {
    navigator.sendBeacon(url, body);
  } else {
    fetch(url, { body, keepalive: true, method: 'POST' });
  }
}
export default MarketplaceApp;
