import React from 'react';
import {
  Box,
  Flex,
  useMultiStyleConfig,
  ChakraNextImage,
  chakra,
  useBreakpointValue,
} from 'src/theme';
import { TIMELINE_THEME_KEY } from 'src/theme/dss-skeleton/components';

import { TimelineTeamsProps } from './types';

export const TeamWrapper = ({ children }) => {
  return (
    <Flex
      position='relative'
      display='flex'
      minWidth={{ base: '40px', md: '66px' }}
      minHeight={{ base: '40px', md: '66px' }}
      borderRadius='100px'
    >
      {children}
    </Flex>
  );
};

export const TimelineTeams = ({ teams }: TimelineTeamsProps) => {
  const styles = useMultiStyleConfig(TIMELINE_THEME_KEY);
  const isMobile = useBreakpointValue({ base: true, md: false });
  const { homeTeam, awayTeam } = teams;

  return (
    <Flex sx={styles.timelineTeamsContainer}>
      <TeamWrapper>
        <ChakraNextImage
          sx={styles.teamLogo}
          alt='home-team-logo'
          imageStyle={{
            height: isMobile ? '38px' : '72px',
            width: isMobile ? '38px' : '72px',
          }}
          src={homeTeam.logo}
        />
      </TeamWrapper>
      <Box
        justifyContent='space-between'
        display='flex'
        width={{ base: '50px', md: '64px' }}
        margin={{ base: '0 16px', md: '8px 0' }}
        color='#F0F2FD'
        fontFamily='Trim'
        fontSize={{ base: '18px', md: '24px' }}
      >
        <chakra.span>{homeTeam.score}</chakra.span>
        <chakra.span>&#8212;</chakra.span>
        <chakra.span>{awayTeam.score}</chakra.span>
      </Box>
      <TeamWrapper>
        <ChakraNextImage
          sx={styles.teamLogo}
          alt='away-team-logo'
          imageStyle={{
            height: isMobile ? '38px' : '72px',
            width: isMobile ? '38px' : '72px',
          }}
          src={awayTeam.logo}
        />
      </TeamWrapper>
    </Flex>
  );
};
