import DOMPurify from 'dompurify';

import { useIsClient } from 'src/general/utils/useIsClient';

export const sanitizer = DOMPurify.sanitize;

type SanitizedProps = React.PropsWithChildren<{
  _dangerouslySetInnerHTML: string;
  options?: any;
}> &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

export const Sanitized = ({
  _dangerouslySetInnerHTML,
  options,
  ...props
}: SanitizedProps) => {
  const isClient = useIsClient();
  if (!isClient) return null;
  return (
    <div
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{
        __html: sanitizer(_dangerouslySetInnerHTML, options),
      }}
      {...props}
    />
  );
};
