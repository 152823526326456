import { useMemo } from 'react';

import { NextEligibleRewardLevel } from 'src/modules/RewardPass/types';
import {
  getBestEligibleReward,
  getNextLevelWithEligibleReward,
} from 'src/modules/RewardPass/utils';

import { useGetRewardPass } from './useGetRewardPass';

export const useGetNextEligibleRewardLevel = (): NextEligibleRewardLevel => {
  const { data: rewardPass } = useGetRewardPass();
  const nextLevelWithEligibleReward = useMemo(
    () =>
      rewardPass &&
      getNextLevelWithEligibleReward(
        rewardPass.levels,
        rewardPass?.userProgress?.currentPoints,
        rewardPass?.userProgress?.tier,
      ),
    [rewardPass],
  );

  const nextEligibleReward = useMemo(
    () =>
      rewardPass &&
      getBestEligibleReward(
        nextLevelWithEligibleReward?.rewards,
        rewardPass?.userProgress?.tier,
      ),
    [nextLevelWithEligibleReward?.rewards, rewardPass],
  );

  return {
    nextEligibleReward,
    nextLevelWithEligibleReward,
  };
};
