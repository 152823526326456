import { gql } from '@apollo/client';

export const RequirementFragment = gql`
  fragment RequirementFragment on Requirement {
    id
    name
    value
    valueType
    comparator
  }
`;
