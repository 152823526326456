import { onError } from '@apollo/client/link/error';
import { captureException } from '@dapperlabs/core-fe';

const shouldCapture = (policy, { networkError, graphQLErrors }) => {
  if (!policy) return !!networkError || !!graphQLErrors;
  switch (policy) {
    case 'none':
      return false;
    case 'network-only':
      return !!networkError;
    case 'graphql-only':
      return !!graphQLErrors;
    default:
      return false;
  }
};

/**
 * sentry link
 * Formats and sends errors to sentry.
 */
export const sentryLink = () =>
  onError(({ operation, graphQLErrors, networkError }) => {
    const operationName = operation?.operationName;
    const context = operation.getContext();

    if (shouldCapture(context?.capturePolicy, { graphQLErrors })) {
      graphQLErrors.forEach((graphQLError) => {
        const statusCode = graphQLError?.extensions?.status_code;
        if (context.ignoreStatusCodes?.includes(statusCode)) return;

        captureException(
          new Error(
            `[GraphQL error] ${operationName}: ${graphQLError.message}${
              statusCode ? `, status_code: ${statusCode}` : ''
            }`,
          ),
          {
            ...(context?.req ? { req: context?.req } : {}),
            errorInfo: {
              graphQLError,
              operation: operationName,
              variables: operation?.variables,
            },
            tags: {
              ...(context?.captureTags || {}),
              operation: operationName,
              origin: context.origin,
            },
          },
        );
      });
    }

    if (shouldCapture(context?.capturePolicy, { networkError })) {
      captureException(networkError, {
        ...(context?.req ? { req: context?.req } : {}),
        errorInfo: {
          operation: operationName,
          variables: operation?.variables,
        },
        tags: {
          ...(context?.captureTags || {}),
          operation: operationName,
          origin: context.origin,
        },
      });
    }
  });
