import { useState } from 'react';
import reactStringReplace from 'react-string-replace';
import { useTranslation } from 'src/lib/translations';
import { Text, Checkbox, Box, Link, Button, Spinner } from 'src/theme';

export const TermsAndMarketing = ({
  acceptTerms,
  loading,
}: {
  acceptTerms: (marketingEmail) => void;
  loading: boolean;
}) => {
  const { t } = useTranslation();
  const [signedTOS, setSignedTOS] = useState(false);
  const [marketingEmail, setMarketingEmail] = useState(false);

  const termsLink = (
    <Link key='terms' color='#FFF' href='/terms' target='_blank'>
      {t('Onboarding.Link.TOS')}
    </Link>
  );

  const privacyLink = (
    <Link key='privacy' color='#FFF' href='/privacy' target='_blank'>
      {t('Onboarding.Link.Privacy')}
    </Link>
  );

  const acceptTermsLabel = reactStringReplace(
    reactStringReplace(t('Onboarding.TOS.Accept'), '{TERMS}', () => termsLink),
    '{PRIVACY}',
    () => privacyLink,
  );

  return (
    <>
      <Box my={['16px', '24px']}>
        <Checkbox
          alignItems='flex-start'
          mb='16px'
          isChecked={signedTOS}
          onChange={() => setSignedTOS(!signedTOS)}
          size='sm'
        >
          <Text
            as='span'
            color='#FFF'
            fontFamily={`'Shapiro', sans-serif`}
            fontSize='12px'
            letterSpacing='0.7px'
          >
            {acceptTermsLabel}
          </Text>
          <Text as='span' color='#D6DAEB' fontSize='12px'>
            {' *'}
            {t('general.Required')}
          </Text>
        </Checkbox>
        <Checkbox
          alignItems='flex-start'
          isChecked={marketingEmail}
          onChange={() => setMarketingEmail(!marketingEmail)}
          size='sm'
        >
          <Text
            as='span'
            color='#FFF'
            fontFamily={`'Shapiro', sans-serif`}
            fontSize='12px'
            letterSpacing='0.7px'
          >
            {t('Onboarding.Marketing.Accept')}
          </Text>
        </Checkbox>
      </Box>
      <Box my='20px'>
        <Button
          w='100%'
          disabled={!signedTOS || loading}
          onClick={() => acceptTerms(marketingEmail)}
          variant='primary'
        >
          {loading ? <Spinner /> : t('Onboarding.Continue')}
        </Button>
      </Box>
    </>
  );
};
