import { AnalyticsContext } from '@dapperlabs/react-analytics';
import { useCallback, useContext, useEffect, useState } from 'react';
import { useIsAuthed } from 'src/edge/session';
import { FS } from 'src/general/constants/finiteStates';
import { captureException } from 'src/lib/sentry';

type UseAnalyticsResults = {
  isAnalyticsReady: boolean;
  state: FS;
  track: (
    trackingId?: string,
    trackingProperties?: Record<string, any>,
  ) => void;
  trackPageView: (trackingProperties?: Record<string, any>) => void;
};

function useAnalytics(): UseAnalyticsResults {
  const [fsm, setFsm] = useState(FS.IDLE);
  const {
    analyticsReady: isReady,
    track,
    trackPageView,
  } = useContext(AnalyticsContext);
  const isAuthenticated = useIsAuthed();

  useEffect(() => {
    if (!isReady) setFsm(FS.LOADING);
    else if (isReady) setFsm(FS.SUCCESS);
  }, [isReady]);

  const trackClick = useCallback(
    (trackingId, trackingProperties = {}) => {
      try {
        if (trackingId) {
          track(trackingId, {
            isAuthenticated,
            userAgent: navigator.userAgent,
            ...trackingProperties,
          });
        }
      } catch (error) {
        captureException(error);
      }
    },
    [track, isAuthenticated],
  );

  const trackPage = (trackingProperties = {}) => {
    try {
      trackPageView({
        userAgent: navigator.userAgent,
        ...trackingProperties,
      });
    } catch (error) {
      captureException(error);
    }
  };

  return {
    isAnalyticsReady: isReady,
    state: fsm,
    track: trackClick,
    trackPageView: trackPage,
  };
}

export { useAnalytics };
