export const flowAddressSansPrefix = (flowAddress: string) => {
  return flowAddress?.replace(/^0x/, '');
};

export const isFlowAddressEqual = (addressA: string, addressB: string) => {
  return flowAddressSansPrefix(addressA) === flowAddressSansPrefix(addressB);
};

export const flowAddressWithPrefix = (flowAddress: string) => {
  return isFlowAddress(flowAddress) ? flowAddress : `0x${flowAddress}`;
};

export const isFlowAddress = (flowAddress: string) => {
  return flowAddress?.startsWith('0x') ?? false;
};
