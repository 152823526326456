export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  Boolean: boolean;
  Byte: any;
  Float: number;
  ID: string;
  Int: number;
  Int16: any;
  Int64: any;
  Int8: any;
  PriceScalar: any;
  Short: any;
  String: string;
  Time: any;
  UInt: any;
  UInt16: any;
  UInt64: any;
  UInt8: any;
  Word: any;
  Word16: any;
  Word64: any;
  Word8: any;
};

export type AddToQueueInput = {
  distributionID: Scalars['String'];
  eligibility: Array<Scalars['Int']>;
  userID: Scalars['String'];
};

export type AddToQueueResponse = {
  __typename?: 'AddToQueueResponse';
  queueEntry?: Maybe<QueueEntry>;
  success: Scalars['Boolean'];
};

export type AirdropPackNftInput = {
  airdropEventID: Scalars['String'];
  distributionID: Scalars['Int'];
  flowAddress: Array<Scalars['String']>;
};

export type AirdropPackNftResponse = {
  __typename?: 'AirdropPackNFTResponse';
  batchCount?: Maybe<Scalars['Int']>;
  batchKey?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type AllDayEdition = {
  __typename?: 'AllDayEdition';
  closed?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  max_mintings?: Maybe<Scalars['UInt64']>;
  play?: Maybe<AllDayPlay>;
  series?: Maybe<AllDaySeries>;
  set?: Maybe<AllDaySet>;
  tier?: Maybe<Scalars['String']>;
  total_burned?: Maybe<Scalars['UInt64']>;
  total_minted?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDayEditionAggregation = {
  __typename?: 'AllDayEditionAggregation';
  closed?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  max_mintings?: Maybe<UInt64Aggregation>;
  play?: Maybe<AllDayPlayAggregation>;
  series?: Maybe<AllDaySeriesAggregation>;
  set?: Maybe<AllDaySetAggregation>;
  tier?: Maybe<StringAggregation>;
  total_burned?: Maybe<StatsInfoAggregation>;
  total_minted?: Maybe<StatsInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDayEditionFilter = {
  closed?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  max_mintings?: InputMaybe<UInt64Filter>;
  play?: InputMaybe<AllDayPlayFilter>;
  series?: InputMaybe<AllDaySeriesFilter>;
  set?: InputMaybe<AllDaySetFilter>;
  tier?: InputMaybe<StringFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDayEditionSort = {
  closed?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  max_mintings?: InputMaybe<Sort>;
  play?: InputMaybe<AllDayPlaySort>;
  series?: InputMaybe<AllDaySeriesSort>;
  set?: InputMaybe<AllDaySetSort>;
  tier?: InputMaybe<SortString>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDayEditionSortAggregation = {
  closed?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  max_mintings?: InputMaybe<SortAggregation>;
  play?: InputMaybe<AllDayPlaySortAggregation>;
  series?: InputMaybe<AllDaySeriesSortAggregation>;
  set?: InputMaybe<AllDaySetSortAggregation>;
  tier?: InputMaybe<SortStringKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AllDayMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
  edition_id?: InputMaybe<UInt64Filter>;
};

export type AllDayMarketplaceTransaction = {
  __typename?: 'AllDayMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<AllDayNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDayMarketplaceTransactionEdge = {
  __typename?: 'AllDayMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AllDayMarketplaceTransaction>;
};

export type AllDayNft = {
  __typename?: 'AllDayNft';
  burned_at?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  edition?: Maybe<AllDayEdition>;
  id?: Maybe<Scalars['UInt64']>;
  listing?: Maybe<Listing>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  serial_number?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type AllDayNftAggregation = {
  __typename?: 'AllDayNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  edition?: Maybe<AllDayEditionAggregation>;
  id?: Maybe<UInt64Aggregation>;
  listing?: Maybe<ListingAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  serial_number?: Maybe<UInt64Aggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDayNftAggregationEdge = {
  __typename?: 'AllDayNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AllDayNftAggregation>;
};

export type AllDayNftCount = {
  edition_id: Scalars['UInt64'];
  quantity: Scalars['Int'];
};

export type AllDayNftEdge = {
  __typename?: 'AllDayNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AllDayNft>;
};

export type AllDayNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  edition?: InputMaybe<AllDayEditionFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  serial_number?: InputMaybe<UInt64Filter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDayNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  edition?: InputMaybe<AllDayEditionSort>;
  id?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  serial_number?: InputMaybe<Sort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDayNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  edition?: InputMaybe<AllDayEditionSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  serial_number?: InputMaybe<SortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AllDayPlay = {
  __typename?: 'AllDayPlay';
  classification?: Maybe<Scalars['String']>;
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  metadata?: Maybe<AllDayPlayMetadata>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDayPlayAggregation = {
  __typename?: 'AllDayPlayAggregation';
  classification?: Maybe<StringAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  metadata?: Maybe<AllDayPlayMetadataAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDayPlayFilter = {
  classification?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  metadata?: InputMaybe<AllDayPlayMetadataFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDayPlayMetadata = {
  __typename?: 'AllDayPlayMetadata';
  away_team_name?: Maybe<Scalars['String']>;
  away_team_score?: Maybe<Scalars['String']>;
  home_team_name?: Maybe<Scalars['String']>;
  home_team_score?: Maybe<Scalars['String']>;
  match_date?: Maybe<Scalars['Time']>;
  play_type?: Maybe<Scalars['String']>;
  player_first_name?: Maybe<Scalars['String']>;
  player_last_name?: Maybe<Scalars['String']>;
  player_number?: Maybe<Scalars['Int']>;
  player_position?: Maybe<Scalars['String']>;
  team_name?: Maybe<Scalars['String']>;
};

export type AllDayPlayMetadataAggregation = {
  __typename?: 'AllDayPlayMetadataAggregation';
  away_team_name?: Maybe<StringAggregation>;
  away_team_score?: Maybe<StringAggregation>;
  home_team_name?: Maybe<StringAggregation>;
  home_team_score?: Maybe<StringAggregation>;
  match_date?: Maybe<TimeAggregation>;
  play_type?: Maybe<StringAggregation>;
  player_first_name?: Maybe<StringAggregation>;
  player_last_name?: Maybe<StringAggregation>;
  player_number?: Maybe<IntAggregation>;
  player_position?: Maybe<StringAggregation>;
  team_name?: Maybe<StringAggregation>;
};

export type AllDayPlayMetadataFilter = {
  away_team_name?: InputMaybe<StringFilter>;
  away_team_score?: InputMaybe<StringFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  home_team_name?: InputMaybe<StringFilter>;
  home_team_score?: InputMaybe<StringFilter>;
  match_date?: InputMaybe<TimeFilter>;
  play_type?: InputMaybe<StringFilter>;
  player_first_name?: InputMaybe<StringFilter>;
  player_last_name?: InputMaybe<StringFilter>;
  player_number?: InputMaybe<IntFilter>;
  player_position?: InputMaybe<StringFilter>;
  team_name?: InputMaybe<StringFilter>;
};

export type AllDayPlayMetadataSort = {
  away_team_name?: InputMaybe<SortString>;
  away_team_score?: InputMaybe<SortString>;
  home_team_name?: InputMaybe<SortString>;
  home_team_score?: InputMaybe<SortString>;
  match_date?: InputMaybe<Sort>;
  play_type?: InputMaybe<SortString>;
  player_first_name?: InputMaybe<SortString>;
  player_last_name?: InputMaybe<SortString>;
  player_number?: InputMaybe<Sort>;
  player_position?: InputMaybe<SortString>;
  team_name?: InputMaybe<SortString>;
};

export type AllDayPlayMetadataSortAggregation = {
  away_team_name?: InputMaybe<SortStringKey>;
  away_team_score?: InputMaybe<SortStringKey>;
  home_team_name?: InputMaybe<SortStringKey>;
  home_team_score?: InputMaybe<SortStringKey>;
  match_date?: InputMaybe<SortKey>;
  play_type?: InputMaybe<SortStringKey>;
  player_first_name?: InputMaybe<SortStringKey>;
  player_last_name?: InputMaybe<SortStringKey>;
  player_number?: InputMaybe<SortAggregation>;
  player_position?: InputMaybe<SortStringKey>;
  team_name?: InputMaybe<SortStringKey>;
};

export type AllDayPlaySort = {
  classification?: InputMaybe<SortString>;
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  metadata?: InputMaybe<AllDayPlayMetadataSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDayPlaySortAggregation = {
  classification?: InputMaybe<SortStringKey>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<AllDayPlayMetadataSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AllDaySeasonalEdition = {
  __typename?: 'AllDaySeasonalEdition';
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  metadata?: Maybe<AllDaySeasonalEditionMetadata>;
  metadata_last_updated_at?: Maybe<BlockInfo>;
  total_burned?: Maybe<Scalars['UInt64']>;
  total_minted?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDaySeasonalEditionAggregation = {
  __typename?: 'AllDaySeasonalEditionAggregation';
  active?: Maybe<BooleanAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  metadata?: Maybe<AllDaySeasonalEditionMetadataAggregation>;
  metadata_last_updated_at?: Maybe<BlockInfoAggregation>;
  total_burned?: Maybe<StatsInfoAggregation>;
  total_minted?: Maybe<StatsInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDaySeasonalEditionFilter = {
  active?: InputMaybe<BooleanFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  metadata?: InputMaybe<AllDaySeasonalEditionMetadataFilter>;
  metadata_last_updated_at?: InputMaybe<BlockInfoFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDaySeasonalEditionMetadata = {
  __typename?: 'AllDaySeasonalEditionMetadata';
  description?: Maybe<Scalars['String']>;
  event?: Maybe<Scalars['String']>;
  league?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type AllDaySeasonalEditionMetadataAggregation = {
  __typename?: 'AllDaySeasonalEditionMetadataAggregation';
  description?: Maybe<StringAggregation>;
  event?: Maybe<StringAggregation>;
  league?: Maybe<StringAggregation>;
  name?: Maybe<StringAggregation>;
  tier?: Maybe<StringAggregation>;
  type?: Maybe<StringAggregation>;
};

export type AllDaySeasonalEditionMetadataFilter = {
  description?: InputMaybe<StringFilter>;
  event?: InputMaybe<StringFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  league?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  tier?: InputMaybe<StringFilter>;
  type?: InputMaybe<StringFilter>;
};

export type AllDaySeasonalEditionMetadataInput = {
  description: Scalars['String'];
  event: Scalars['String'];
  league: Scalars['String'];
  name: Scalars['String'];
  tier: Scalars['String'];
  type: Scalars['String'];
};

export type AllDaySeasonalEditionMetadataSort = {
  description?: InputMaybe<SortString>;
  event?: InputMaybe<SortString>;
  league?: InputMaybe<SortString>;
  name?: InputMaybe<SortString>;
  tier?: InputMaybe<SortString>;
  type?: InputMaybe<SortString>;
};

export type AllDaySeasonalEditionMetadataSortAggregation = {
  description?: InputMaybe<SortStringKey>;
  event?: InputMaybe<SortStringKey>;
  league?: InputMaybe<SortStringKey>;
  name?: InputMaybe<SortStringKey>;
  tier?: InputMaybe<SortStringKey>;
  type?: InputMaybe<SortStringKey>;
};

export type AllDaySeasonalEditionSort = {
  active?: InputMaybe<Sort>;
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  metadata?: InputMaybe<AllDaySeasonalEditionMetadataSort>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDaySeasonalEditionSortAggregation = {
  active?: InputMaybe<SortKey>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<AllDaySeasonalEditionMetadataSortAggregation>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AllDaySeasonalMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
  edition_id?: InputMaybe<UInt64Filter>;
};

export type AllDaySeasonalMarketplaceTransaction = {
  __typename?: 'AllDaySeasonalMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<AllDaySeasonalNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDaySeasonalMarketplaceTransactionEdge = {
  __typename?: 'AllDaySeasonalMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AllDaySeasonalMarketplaceTransaction>;
};

export type AllDaySeasonalNft = {
  __typename?: 'AllDaySeasonalNft';
  burned_at?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  edition?: Maybe<AllDaySeasonalEdition>;
  id?: Maybe<Scalars['UInt64']>;
  listing?: Maybe<Listing>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type AllDaySeasonalNftAggregation = {
  __typename?: 'AllDaySeasonalNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  edition?: Maybe<AllDaySeasonalEditionAggregation>;
  id?: Maybe<UInt64Aggregation>;
  listing?: Maybe<ListingAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDaySeasonalNftAggregationEdge = {
  __typename?: 'AllDaySeasonalNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AllDaySeasonalNftAggregation>;
};

export type AllDaySeasonalNftCount = {
  edition_id: Scalars['UInt64'];
  quantity: Scalars['Int'];
};

export type AllDaySeasonalNftEdge = {
  __typename?: 'AllDaySeasonalNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AllDaySeasonalNft>;
};

export type AllDaySeasonalNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  edition?: InputMaybe<AllDaySeasonalEditionFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDaySeasonalNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  edition?: InputMaybe<AllDaySeasonalEditionSort>;
  id?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDaySeasonalNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  edition?: InputMaybe<AllDaySeasonalEditionSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AllDaySeries = {
  __typename?: 'AllDaySeries';
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  name?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDaySeriesAggregation = {
  __typename?: 'AllDaySeriesAggregation';
  active?: Maybe<BooleanAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  name?: Maybe<StringAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDaySeriesFilter = {
  active?: InputMaybe<BooleanFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  name?: InputMaybe<StringFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDaySeriesSort = {
  active?: InputMaybe<Sort>;
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  name?: InputMaybe<SortString>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDaySeriesSortAggregation = {
  active?: InputMaybe<SortKey>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  name?: InputMaybe<SortStringKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AllDaySet = {
  __typename?: 'AllDaySet';
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  name?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDaySetAggregation = {
  __typename?: 'AllDaySetAggregation';
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  name?: Maybe<StringAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDaySetFilter = {
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  name?: InputMaybe<StringFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDaySetSort = {
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  name?: InputMaybe<SortString>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDaySetSortAggregation = {
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  name?: InputMaybe<SortStringKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AllDayTeamMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
  set_id?: InputMaybe<UIntFilter>;
};

export type AllDayTeamMarketplaceTransaction = {
  __typename?: 'AllDayTeamMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<AllDayTeamNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDayTeamMarketplaceTransactionEdge = {
  __typename?: 'AllDayTeamMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AllDayTeamMarketplaceTransaction>;
};

export type AllDayTeamNft = {
  __typename?: 'AllDayTeamNft';
  burned_at?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  edition_num?: Maybe<Scalars['UInt']>;
  id?: Maybe<Scalars['UInt64']>;
  listing?: Maybe<Listing>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  set?: Maybe<AllDayTeamSet>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type AllDayTeamNftAggregation = {
  __typename?: 'AllDayTeamNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  edition_num?: Maybe<UIntAggregation>;
  id?: Maybe<UInt64Aggregation>;
  listing?: Maybe<ListingAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  set?: Maybe<AllDayTeamSetAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDayTeamNftAggregationEdge = {
  __typename?: 'AllDayTeamNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AllDayTeamNftAggregation>;
};

export type AllDayTeamNftEdge = {
  __typename?: 'AllDayTeamNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AllDayTeamNft>;
};

export type AllDayTeamNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  edition_num?: InputMaybe<UIntFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  set?: InputMaybe<AllDayTeamSetFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDayTeamNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  edition_num?: InputMaybe<Sort>;
  id?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  set?: InputMaybe<AllDayTeamSetSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDayTeamNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  edition_num?: InputMaybe<SortAggregation>;
  id?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  set?: InputMaybe<AllDayTeamSetSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AllDayTeamSeries = {
  __typename?: 'AllDayTeamSeries';
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt']>;
  metadata?: Maybe<AllDayTeamSeriesMetadata>;
  metadata_last_updated_at?: Maybe<BlockInfo>;
  sealed?: Maybe<Scalars['Boolean']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDayTeamSeriesAggregation = {
  __typename?: 'AllDayTeamSeriesAggregation';
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UIntAggregation>;
  metadata?: Maybe<AllDayTeamSeriesMetadataAggregation>;
  metadata_last_updated_at?: Maybe<BlockInfoAggregation>;
  sealed?: Maybe<BooleanAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDayTeamSeriesFilter = {
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UIntFilter>;
  metadata?: InputMaybe<AllDayTeamSeriesMetadataFilter>;
  metadata_last_updated_at?: InputMaybe<BlockInfoFilter>;
  sealed?: InputMaybe<BooleanFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDayTeamSeriesMetadata = {
  __typename?: 'AllDayTeamSeriesMetadata';
  series_description?: Maybe<Scalars['String']>;
  series_name?: Maybe<Scalars['String']>;
};

export type AllDayTeamSeriesMetadataAggregation = {
  __typename?: 'AllDayTeamSeriesMetadataAggregation';
  series_description?: Maybe<StringAggregation>;
  series_name?: Maybe<StringAggregation>;
};

export type AllDayTeamSeriesMetadataFilter = {
  exists?: InputMaybe<Scalars['Boolean']>;
  series_description?: InputMaybe<StringFilter>;
  series_name?: InputMaybe<StringFilter>;
};

export type AllDayTeamSeriesMetadataSort = {
  series_description?: InputMaybe<SortString>;
  series_name?: InputMaybe<SortString>;
};

export type AllDayTeamSeriesMetadataSortAggregation = {
  series_description?: InputMaybe<SortStringKey>;
  series_name?: InputMaybe<SortStringKey>;
};

export type AllDayTeamSeriesSort = {
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  metadata?: InputMaybe<AllDayTeamSeriesMetadataSort>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSort>;
  sealed?: InputMaybe<Sort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDayTeamSeriesSortAggregation = {
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<AllDayTeamSeriesMetadataSortAggregation>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  sealed?: InputMaybe<SortKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AllDayTeamSet = {
  __typename?: 'AllDayTeamSet';
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt']>;
  max_mintings?: Maybe<Scalars['UInt64']>;
  metadata?: Maybe<AllDayTeamSetMetadata>;
  metadata_last_updated_at?: Maybe<BlockInfo>;
  series?: Maybe<AllDayTeamSeries>;
  total_burned?: Maybe<Scalars['UInt64']>;
  total_minted?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AllDayTeamSetAggregation = {
  __typename?: 'AllDayTeamSetAggregation';
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UIntAggregation>;
  max_mintings?: Maybe<UInt64Aggregation>;
  metadata?: Maybe<AllDayTeamSetMetadataAggregation>;
  metadata_last_updated_at?: Maybe<BlockInfoAggregation>;
  series?: Maybe<AllDayTeamSeriesAggregation>;
  total_burned?: Maybe<StatsInfoAggregation>;
  total_minted?: Maybe<StatsInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AllDayTeamSetFilter = {
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UIntFilter>;
  max_mintings?: InputMaybe<UInt64Filter>;
  metadata?: InputMaybe<AllDayTeamSetMetadataFilter>;
  metadata_last_updated_at?: InputMaybe<BlockInfoFilter>;
  series?: InputMaybe<AllDayTeamSeriesFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AllDayTeamSetMetadata = {
  __typename?: 'AllDayTeamSetMetadata';
  additional_images?: Maybe<Array<Maybe<Scalars['String']>>>;
  creator_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_file_type?: Maybe<Scalars['String']>;
  ipfs_image_hash?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  sha256_image_hash?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['UInt64']>;
};

export type AllDayTeamSetMetadataAggregation = {
  __typename?: 'AllDayTeamSetMetadataAggregation';
  creator_name?: Maybe<StringAggregation>;
  description?: Maybe<StringAggregation>;
  external_url?: Maybe<StringAggregation>;
  image?: Maybe<StringAggregation>;
  image_file_type?: Maybe<StringAggregation>;
  ipfs_image_hash?: Maybe<StringAggregation>;
  name?: Maybe<StringAggregation>;
  preview?: Maybe<StringAggregation>;
  sha256_image_hash?: Maybe<StringAggregation>;
  team_id?: Maybe<UInt64Aggregation>;
};

export type AllDayTeamSetMetadataFilter = {
  additional_images?: InputMaybe<StringListFilter>;
  creator_name?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  external_url?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringFilter>;
  image_file_type?: InputMaybe<StringFilter>;
  ipfs_image_hash?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  preview?: InputMaybe<StringFilter>;
  sha256_image_hash?: InputMaybe<StringFilter>;
  team_id?: InputMaybe<UInt64Filter>;
};

export type AllDayTeamSetMetadataSort = {
  creator_name?: InputMaybe<SortString>;
  description?: InputMaybe<SortString>;
  external_url?: InputMaybe<SortString>;
  image?: InputMaybe<SortString>;
  image_file_type?: InputMaybe<SortString>;
  ipfs_image_hash?: InputMaybe<SortString>;
  name?: InputMaybe<SortString>;
  preview?: InputMaybe<SortString>;
  sha256_image_hash?: InputMaybe<SortString>;
  team_id?: InputMaybe<Sort>;
};

export type AllDayTeamSetMetadataSortAggregation = {
  creator_name?: InputMaybe<SortStringKey>;
  description?: InputMaybe<SortStringKey>;
  external_url?: InputMaybe<SortStringKey>;
  image?: InputMaybe<SortStringKey>;
  image_file_type?: InputMaybe<SortStringKey>;
  ipfs_image_hash?: InputMaybe<SortStringKey>;
  name?: InputMaybe<SortStringKey>;
  preview?: InputMaybe<SortStringKey>;
  sha256_image_hash?: InputMaybe<SortStringKey>;
  team_id?: InputMaybe<SortAggregation>;
};

export type AllDayTeamSetSort = {
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  max_mintings?: InputMaybe<Sort>;
  metadata?: InputMaybe<AllDayTeamSetMetadataSort>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSort>;
  series?: InputMaybe<AllDayTeamSeriesSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AllDayTeamSetSortAggregation = {
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  max_mintings?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<AllDayTeamSetMetadataSortAggregation>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  series?: InputMaybe<AllDayTeamSeriesSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AthleteStudioEdition = {
  __typename?: 'AthleteStudioEdition';
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  metadata?: Maybe<AthleteStudioEditionMetadata>;
  metadata_last_updated_at?: Maybe<BlockInfo>;
  size?: Maybe<Scalars['UInt64']>;
  total_burned?: Maybe<Scalars['UInt64']>;
  total_minted?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AthleteStudioEditionAggregation = {
  __typename?: 'AthleteStudioEditionAggregation';
  active?: Maybe<BooleanAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  metadata?: Maybe<AthleteStudioEditionMetadataAggregation>;
  metadata_last_updated_at?: Maybe<BlockInfoAggregation>;
  size?: Maybe<UInt64Aggregation>;
  total_burned?: Maybe<StatsInfoAggregation>;
  total_minted?: Maybe<StatsInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AthleteStudioEditionFilter = {
  active?: InputMaybe<BooleanFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  metadata?: InputMaybe<AthleteStudioEditionMetadataFilter>;
  metadata_last_updated_at?: InputMaybe<BlockInfoFilter>;
  size?: InputMaybe<UInt64Filter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AthleteStudioEditionMetadata = {
  __typename?: 'AthleteStudioEditionMetadata';
  asset?: Maybe<Scalars['String']>;
  asset_type?: Maybe<Scalars['String']>;
  athlete_id?: Maybe<Scalars['Int64']>;
  athlete_name?: Maybe<Scalars['String']>;
  athlete_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  event_date?: Maybe<Scalars['String']>;
  event_name?: Maybe<Scalars['String']>;
  event_type?: Maybe<Scalars['String']>;
  item_category?: Maybe<Scalars['String']>;
  item_type?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['String']>;
  signed?: Maybe<Scalars['Boolean']>;
  thumbnail?: Maybe<Scalars['String']>;
};

export type AthleteStudioEditionMetadataAggregation = {
  __typename?: 'AthleteStudioEditionMetadataAggregation';
  asset?: Maybe<StringAggregation>;
  asset_type?: Maybe<StringAggregation>;
  athlete_id?: Maybe<Int64Aggregation>;
  athlete_name?: Maybe<StringAggregation>;
  athlete_url?: Maybe<StringAggregation>;
  description?: Maybe<StringAggregation>;
  event_date?: Maybe<StringAggregation>;
  event_name?: Maybe<StringAggregation>;
  event_type?: Maybe<StringAggregation>;
  item_category?: Maybe<StringAggregation>;
  item_type?: Maybe<StringAggregation>;
  name?: Maybe<StringAggregation>;
  series?: Maybe<StringAggregation>;
  signed?: Maybe<BooleanAggregation>;
  thumbnail?: Maybe<StringAggregation>;
};

export type AthleteStudioEditionMetadataFilter = {
  asset?: InputMaybe<StringFilter>;
  asset_type?: InputMaybe<StringFilter>;
  athlete_id?: InputMaybe<Int64Filter>;
  athlete_name?: InputMaybe<StringFilter>;
  athlete_url?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  event_date?: InputMaybe<StringFilter>;
  event_name?: InputMaybe<StringFilter>;
  event_type?: InputMaybe<StringFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  item_category?: InputMaybe<StringFilter>;
  item_type?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  series?: InputMaybe<StringFilter>;
  signed?: InputMaybe<BooleanFilter>;
  thumbnail?: InputMaybe<StringFilter>;
};

export type AthleteStudioEditionMetadataSort = {
  asset?: InputMaybe<SortString>;
  asset_type?: InputMaybe<SortString>;
  athlete_id?: InputMaybe<Sort>;
  athlete_name?: InputMaybe<SortString>;
  athlete_url?: InputMaybe<SortString>;
  description?: InputMaybe<SortString>;
  event_date?: InputMaybe<SortString>;
  event_name?: InputMaybe<SortString>;
  event_type?: InputMaybe<SortString>;
  item_category?: InputMaybe<SortString>;
  item_type?: InputMaybe<SortString>;
  name?: InputMaybe<SortString>;
  series?: InputMaybe<SortString>;
  signed?: InputMaybe<Sort>;
  thumbnail?: InputMaybe<SortString>;
};

export type AthleteStudioEditionMetadataSortAggregation = {
  asset?: InputMaybe<SortStringKey>;
  asset_type?: InputMaybe<SortStringKey>;
  athlete_id?: InputMaybe<SortAggregation>;
  athlete_name?: InputMaybe<SortStringKey>;
  athlete_url?: InputMaybe<SortStringKey>;
  description?: InputMaybe<SortStringKey>;
  event_date?: InputMaybe<SortStringKey>;
  event_name?: InputMaybe<SortStringKey>;
  event_type?: InputMaybe<SortStringKey>;
  item_category?: InputMaybe<SortStringKey>;
  item_type?: InputMaybe<SortStringKey>;
  name?: InputMaybe<SortStringKey>;
  series?: InputMaybe<SortStringKey>;
  signed?: InputMaybe<SortKey>;
  thumbnail?: InputMaybe<SortStringKey>;
};

export type AthleteStudioEditionSort = {
  active?: InputMaybe<Sort>;
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  metadata?: InputMaybe<AthleteStudioEditionMetadataSort>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSort>;
  size?: InputMaybe<Sort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AthleteStudioEditionSortAggregation = {
  active?: InputMaybe<SortKey>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<AthleteStudioEditionMetadataSortAggregation>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  size?: InputMaybe<SortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AthleteStudioMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
  edition_id?: InputMaybe<UInt64Filter>;
};

export type AthleteStudioMarketplaceTransaction = {
  __typename?: 'AthleteStudioMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<AthleteStudioNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type AthleteStudioMarketplaceTransactionEdge = {
  __typename?: 'AthleteStudioMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AthleteStudioMarketplaceTransaction>;
};

export type AthleteStudioNft = {
  __typename?: 'AthleteStudioNft';
  burned_at?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  edition?: Maybe<AthleteStudioEdition>;
  id?: Maybe<Scalars['UInt64']>;
  listing?: Maybe<Listing>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  serial_number?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type AthleteStudioNftAggregation = {
  __typename?: 'AthleteStudioNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  edition?: Maybe<AthleteStudioEditionAggregation>;
  id?: Maybe<UInt64Aggregation>;
  listing?: Maybe<ListingAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  serial_number?: Maybe<UInt64Aggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type AthleteStudioNftAggregationEdge = {
  __typename?: 'AthleteStudioNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AthleteStudioNftAggregation>;
};

export type AthleteStudioNftEdge = {
  __typename?: 'AthleteStudioNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<AthleteStudioNft>;
};

export type AthleteStudioNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  edition?: InputMaybe<AthleteStudioEditionFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  serial_number?: InputMaybe<UInt64Filter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type AthleteStudioNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  edition?: InputMaybe<AthleteStudioEditionSort>;
  id?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  serial_number?: InputMaybe<Sort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type AthleteStudioNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  edition?: InputMaybe<AthleteStudioEditionSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  serial_number?: InputMaybe<SortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type AuthorizeAllDaySeasonalTransactionInput = {
  signable: Scalars['String'];
};

export type AuthorizeAllDaySeasonalTransactionResponse = {
  __typename?: 'AuthorizeAllDaySeasonalTransactionResponse';
  signature: Scalars['String'];
};

export type AuthorizeCustomerSupportPackNftReservationInput = {
  signable: Scalars['String'];
  userFlowAddress: Scalars['String'];
};

export type AuthorizeCustomerSupportPackNftReservationResponse = {
  __typename?: 'AuthorizeCustomerSupportPackNFTReservationResponse';
  signature: Scalars['String'];
};

export type AuthorizeCustomerSupportReservationInput = {
  signable: Scalars['String'];
};

export type AuthorizeCustomerSupportReservationResponse = {
  __typename?: 'AuthorizeCustomerSupportReservationResponse';
  signature: Scalars['String'];
};

export type AuthorizeGolazosTransactionInput = {
  signable: Scalars['String'];
};

export type AuthorizeGolazosTransactionResponse = {
  __typename?: 'AuthorizeGolazosTransactionResponse';
  signature: Scalars['String'];
};

export type AuthorizePackNftReservationInput = {
  purchaserID: Scalars['String'];
  signable: Scalars['String'];
};

export type AuthorizePackNftReservationResponse = {
  __typename?: 'AuthorizePackNFTReservationResponse';
  signature: Scalars['String'];
};

export type AuthorizePackNftsMintingInput = {
  signable: Scalars['String'];
  type: SignableType;
};

export type AuthorizePackNftsMintingResponse = {
  __typename?: 'AuthorizePackNftsMintingResponse';
  signature: Scalars['String'];
};

export type AuthorizePackPurchaseInput = {
  signable: Scalars['String'];
};

export type AuthorizePackPurchaseResponse = {
  __typename?: 'AuthorizePackPurchaseResponse';
  signature: Scalars['String'];
};

export type BlockInfo = {
  __typename?: 'BlockInfo';
  block_height?: Maybe<Scalars['UInt64']>;
  block_time?: Maybe<Scalars['Time']>;
  event_index?: Maybe<Scalars['UInt']>;
  transaction_hash?: Maybe<Scalars['String']>;
  transaction_index?: Maybe<Scalars['UInt']>;
};

export type BlockInfoAggregation = {
  __typename?: 'BlockInfoAggregation';
  block_height?: Maybe<UInt64Aggregation>;
  block_time?: Maybe<TimeAggregation>;
  event_index?: Maybe<UIntAggregation>;
  transaction_hash?: Maybe<StringAggregation>;
  transaction_index?: Maybe<UIntAggregation>;
};

export type BlockInfoFilter = {
  block_height?: InputMaybe<UInt64Filter>;
  block_time?: InputMaybe<TimeFilter>;
  event_index?: InputMaybe<UIntFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  transaction_hash?: InputMaybe<StringFilter>;
  transaction_index?: InputMaybe<UIntFilter>;
};

export type BlockInfoSort = {
  block_height?: InputMaybe<Sort>;
  block_time?: InputMaybe<Sort>;
  event_index?: InputMaybe<Sort>;
  transaction_hash?: InputMaybe<SortString>;
  transaction_index?: InputMaybe<Sort>;
};

export type BlockInfoSortAggregation = {
  block_height?: InputMaybe<SortAggregation>;
  block_time?: InputMaybe<SortKey>;
  event_index?: InputMaybe<SortAggregation>;
  transaction_hash?: InputMaybe<SortStringKey>;
  transaction_index?: InputMaybe<SortAggregation>;
};

export type BooleanAggregation = {
  __typename?: 'BooleanAggregation';
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Boolean']>;
  value?: Maybe<Scalars['Boolean']>;
};

export type BooleanFilter = {
  eq?: InputMaybe<Scalars['Boolean']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export enum Card {
  Red = 'RED',
  Yellow = 'YELLOW',
}

export enum CardType {
  SecondYellow = 'SECOND_YELLOW',
  StraightRed = 'STRAIGHT_RED',
}

export type CollectionGroup = {
  __typename?: 'CollectionGroup';
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['Time']>;
  flow_id?: Maybe<Scalars['UInt64']>;
  id?: Maybe<Scalars['String']>;
  metadata?: Maybe<Array<Maybe<MetadataEntry>>>;
  name?: Maybe<Scalars['String']>;
  open?: Maybe<Scalars['Boolean']>;
  product_name?: Maybe<Scalars['String']>;
  slots?: Maybe<Array<Maybe<CollectionGroupSlot>>>;
  styles?: Maybe<Array<Maybe<KeyValueEntry>>>;
};

export type CollectionGroupEdge = {
  __typename?: 'CollectionGroupEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CollectionGroup>;
};

export type CollectionGroupEdition = {
  __typename?: 'CollectionGroupEdition';
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<BlockInfo>;
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['UInt64']>;
  id?: Maybe<Scalars['UInt64']>;
  metadata_last_updated_at?: Maybe<BlockInfo>;
  name?: Maybe<Scalars['String']>;
  num_minted?: Maybe<Scalars['UInt64']>;
  product_name?: Maybe<Scalars['String']>;
  slots?: Maybe<Array<Maybe<CollectionGroupEditionMetadataSlots>>>;
  total_burned?: Maybe<Scalars['UInt64']>;
  total_minted?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type CollectionGroupEditionAggregation = {
  __typename?: 'CollectionGroupEditionAggregation';
  active?: Maybe<BooleanAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  description?: Maybe<StringAggregation>;
  end_time?: Maybe<UInt64Aggregation>;
  id?: Maybe<UInt64Aggregation>;
  metadata_last_updated_at?: Maybe<BlockInfoAggregation>;
  name?: Maybe<StringAggregation>;
  num_minted?: Maybe<UInt64Aggregation>;
  product_name?: Maybe<StringAggregation>;
  total_burned?: Maybe<StatsInfoAggregation>;
  total_minted?: Maybe<StatsInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type CollectionGroupEditionFilter = {
  active?: InputMaybe<BooleanFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  description?: InputMaybe<StringFilter>;
  end_time?: InputMaybe<UInt64Filter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  metadata_last_updated_at?: InputMaybe<BlockInfoFilter>;
  name?: InputMaybe<StringFilter>;
  num_minted?: InputMaybe<UInt64Filter>;
  product_name?: InputMaybe<StringFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type CollectionGroupEditionMetadata = {
  __typename?: 'CollectionGroupEditionMetadata';
  description?: Maybe<Scalars['String']>;
  end_time?: Maybe<Scalars['UInt64']>;
  name?: Maybe<Scalars['String']>;
  num_minted?: Maybe<Scalars['UInt64']>;
  product_name?: Maybe<Scalars['String']>;
  slots?: Maybe<Array<Maybe<CollectionGroupEditionMetadataSlots>>>;
};

export type CollectionGroupEditionMetadataAggregation = {
  __typename?: 'CollectionGroupEditionMetadataAggregation';
  description?: Maybe<StringAggregation>;
  end_time?: Maybe<UInt64Aggregation>;
  name?: Maybe<StringAggregation>;
  num_minted?: Maybe<UInt64Aggregation>;
  product_name?: Maybe<StringAggregation>;
};

export type CollectionGroupEditionMetadataFilter = {
  description?: InputMaybe<StringFilter>;
  end_time?: InputMaybe<UInt64Filter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringFilter>;
  num_minted?: InputMaybe<UInt64Filter>;
  product_name?: InputMaybe<StringFilter>;
};

export type CollectionGroupEditionMetadataSlots = {
  __typename?: 'CollectionGroupEditionMetadataSlots';
  id?: Maybe<Scalars['UInt64']>;
  items?: Maybe<Array<Maybe<CollectionGroupEditionMetadataSlotsItems>>>;
  logical_operator?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  type_name?: Maybe<Scalars['String']>;
};

export type CollectionGroupEditionMetadataSlotsAggregation = {
  __typename?: 'CollectionGroupEditionMetadataSlotsAggregation';
  id?: Maybe<UInt64Aggregation>;
  logical_operator?: Maybe<StringAggregation>;
  required?: Maybe<BooleanAggregation>;
  type_name?: Maybe<StringAggregation>;
};

export type CollectionGroupEditionMetadataSlotsFilter = {
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  logical_operator?: InputMaybe<StringFilter>;
  required?: InputMaybe<BooleanFilter>;
  type_name?: InputMaybe<StringFilter>;
};

export type CollectionGroupEditionMetadataSlotsItems = {
  __typename?: 'CollectionGroupEditionMetadataSlotsItems';
  comparator?: Maybe<Scalars['String']>;
  item_id?: Maybe<Scalars['String']>;
  item_type?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['UInt64']>;
};

export type CollectionGroupEditionMetadataSlotsItemsAggregation = {
  __typename?: 'CollectionGroupEditionMetadataSlotsItemsAggregation';
  comparator?: Maybe<StringAggregation>;
  item_id?: Maybe<StringAggregation>;
  item_type?: Maybe<StringAggregation>;
  points?: Maybe<UInt64Aggregation>;
};

export type CollectionGroupEditionMetadataSlotsItemsFilter = {
  comparator?: InputMaybe<StringFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  item_id?: InputMaybe<StringFilter>;
  item_type?: InputMaybe<StringFilter>;
  points?: InputMaybe<UInt64Filter>;
};

export type CollectionGroupEditionMetadataSlotsItemsSort = {
  comparator?: InputMaybe<SortString>;
  item_id?: InputMaybe<SortString>;
  item_type?: InputMaybe<SortString>;
  points?: InputMaybe<Sort>;
};

export type CollectionGroupEditionMetadataSlotsItemsSortAggregation = {
  comparator?: InputMaybe<SortStringKey>;
  item_id?: InputMaybe<SortStringKey>;
  item_type?: InputMaybe<SortStringKey>;
  points?: InputMaybe<SortAggregation>;
};

export type CollectionGroupEditionMetadataSlotsSort = {
  id?: InputMaybe<Sort>;
  logical_operator?: InputMaybe<SortString>;
  required?: InputMaybe<Sort>;
  type_name?: InputMaybe<SortString>;
};

export type CollectionGroupEditionMetadataSlotsSortAggregation = {
  id?: InputMaybe<SortAggregation>;
  logical_operator?: InputMaybe<SortStringKey>;
  required?: InputMaybe<SortKey>;
  type_name?: InputMaybe<SortStringKey>;
};

export type CollectionGroupEditionMetadataSort = {
  description?: InputMaybe<SortString>;
  end_time?: InputMaybe<Sort>;
  name?: InputMaybe<SortString>;
  num_minted?: InputMaybe<Sort>;
  product_name?: InputMaybe<SortString>;
};

export type CollectionGroupEditionMetadataSortAggregation = {
  description?: InputMaybe<SortStringKey>;
  end_time?: InputMaybe<SortAggregation>;
  name?: InputMaybe<SortStringKey>;
  num_minted?: InputMaybe<SortAggregation>;
  product_name?: InputMaybe<SortStringKey>;
};

export type CollectionGroupEditionSort = {
  active?: InputMaybe<Sort>;
  created_at?: InputMaybe<BlockInfoSort>;
  description?: InputMaybe<SortString>;
  end_time?: InputMaybe<Sort>;
  id?: InputMaybe<Sort>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSort>;
  name?: InputMaybe<SortString>;
  num_minted?: InputMaybe<Sort>;
  product_name?: InputMaybe<SortString>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type CollectionGroupEditionSortAggregation = {
  active?: InputMaybe<SortKey>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  description?: InputMaybe<SortStringKey>;
  end_time?: InputMaybe<SortAggregation>;
  id?: InputMaybe<SortAggregation>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  name?: InputMaybe<SortStringKey>;
  num_minted?: InputMaybe<SortAggregation>;
  product_name?: InputMaybe<SortStringKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type CollectionGroupMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
  edition_id?: InputMaybe<UInt64Filter>;
};

export type CollectionGroupMarketplaceTransaction = {
  __typename?: 'CollectionGroupMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<CollectionGroupNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type CollectionGroupMarketplaceTransactionEdge = {
  __typename?: 'CollectionGroupMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CollectionGroupMarketplaceTransaction>;
};

export type CollectionGroupNft = {
  __typename?: 'CollectionGroupNft';
  burned_at?: Maybe<BlockInfo>;
  completed_by?: Maybe<Scalars['String']>;
  completion_date?: Maybe<Scalars['UInt64']>;
  created_at?: Maybe<BlockInfo>;
  edition?: Maybe<CollectionGroupEdition>;
  id?: Maybe<Scalars['UInt64']>;
  level?: Maybe<Scalars['UInt']>;
  listing?: Maybe<Listing>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  serial_number?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type CollectionGroupNftAggregation = {
  __typename?: 'CollectionGroupNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  completed_by?: Maybe<StringAggregation>;
  completion_date?: Maybe<UInt64Aggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  edition?: Maybe<CollectionGroupEditionAggregation>;
  id?: Maybe<UInt64Aggregation>;
  level?: Maybe<UIntAggregation>;
  listing?: Maybe<ListingAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  serial_number?: Maybe<UInt64Aggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type CollectionGroupNftAggregationEdge = {
  __typename?: 'CollectionGroupNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CollectionGroupNftAggregation>;
};

export type CollectionGroupNftEdge = {
  __typename?: 'CollectionGroupNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<CollectionGroupNft>;
};

export type CollectionGroupNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  completed_by?: InputMaybe<StringFilter>;
  completion_date?: InputMaybe<UInt64Filter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  edition?: InputMaybe<CollectionGroupEditionFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  level?: InputMaybe<UIntFilter>;
  listing?: InputMaybe<ListingFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  serial_number?: InputMaybe<UInt64Filter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type CollectionGroupNftMetadata = {
  __typename?: 'CollectionGroupNftMetadata';
  completed_by?: Maybe<Scalars['String']>;
  completion_date?: Maybe<Scalars['UInt64']>;
  level?: Maybe<Scalars['UInt']>;
};

export type CollectionGroupNftMetadataAggregation = {
  __typename?: 'CollectionGroupNftMetadataAggregation';
  completed_by?: Maybe<StringAggregation>;
  completion_date?: Maybe<UInt64Aggregation>;
  level?: Maybe<UIntAggregation>;
};

export type CollectionGroupNftMetadataFilter = {
  completed_by?: InputMaybe<StringFilter>;
  completion_date?: InputMaybe<UInt64Filter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  level?: InputMaybe<UIntFilter>;
};

export type CollectionGroupNftMetadataSort = {
  completed_by?: InputMaybe<SortString>;
  completion_date?: InputMaybe<Sort>;
  level?: InputMaybe<Sort>;
};

export type CollectionGroupNftMetadataSortAggregation = {
  completed_by?: InputMaybe<SortStringKey>;
  completion_date?: InputMaybe<SortAggregation>;
  level?: InputMaybe<SortAggregation>;
};

export type CollectionGroupNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  completed_by?: InputMaybe<SortString>;
  completion_date?: InputMaybe<Sort>;
  created_at?: InputMaybe<BlockInfoSort>;
  edition?: InputMaybe<CollectionGroupEditionSort>;
  id?: InputMaybe<Sort>;
  level?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  serial_number?: InputMaybe<Sort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type CollectionGroupNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  completed_by?: InputMaybe<SortStringKey>;
  completion_date?: InputMaybe<SortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  edition?: InputMaybe<CollectionGroupEditionSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  level?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  serial_number?: InputMaybe<SortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type CollectionGroupSlot = {
  __typename?: 'CollectionGroupSlot';
  collection_group_flow_id?: Maybe<Scalars['UInt64']>;
  collection_group_id?: Maybe<Scalars['String']>;
  flow_id?: Maybe<Scalars['UInt64']>;
  id?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<CollectionGroupSlotItem>>>;
  logical_operator?: Maybe<CollectionGroupSlotLogicalOperator>;
  sort_order?: Maybe<Scalars['UInt16']>;
  submission?: Maybe<Array<Maybe<CollectionGroupSlotSubmission>>>;
  type_name?: Maybe<Scalars['String']>;
};

export type CollectionGroupSlotItem = {
  __typename?: 'CollectionGroupSlotItem';
  item_id?: Maybe<Scalars['UInt64']>;
  item_type?: Maybe<Scalars['String']>;
  points?: Maybe<Scalars['UInt64']>;
};

export enum CollectionGroupSlotLogicalOperator {
  And = 'AND',
  Or = 'OR',
}

export type CollectionGroupSlotSubmission = {
  __typename?: 'CollectionGroupSlotSubmission';
  id?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
};

export enum Comparator {
  Eq = 'EQ',
  Ge = 'GE',
  Gt = 'GT',
  Le = 'LE',
  Lt = 'LT',
  Ne = 'NE',
}

export enum ContainsType {
  All = 'ALL',
  Any = 'ANY',
}

export type CreateDistributionInput = {
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  endTime?: InputMaybe<Scalars['Time']>;
  images?: InputMaybe<Array<InputMaybe<DistributionImageInput>>>;
  numberOfPackSlots?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['Float']>;
  startTime?: InputMaybe<Scalars['Time']>;
  tier?: InputMaybe<DistributionTier>;
  title?: InputMaybe<Scalars['String']>;
  uuid?: InputMaybe<Scalars['ID']>;
  videos?: InputMaybe<Array<InputMaybe<DistributionVideoInput>>>;
};

export type CreateDistributionResponse = {
  UUID?: Maybe<Scalars['String']>;
  __typename?: 'CreateDistributionResponse';
};

export type CreateMarketplaceReservationCriteriaInput = {
  parentID?: InputMaybe<Scalars['Int']>;
  price?: InputMaybe<Scalars['UInt64']>;
};

export type CreateMarketplaceReservationInput = {
  buyerAddress: Scalars['String'];
  criteria?: InputMaybe<CreateMarketplaceReservationCriteriaInput>;
  nftID?: InputMaybe<Scalars['Int']>;
  nftType: Scalars['String'];
};

export type CreateMarketplaceReservationResponse = {
  __typename?: 'CreateMarketplaceReservationResponse';
  expiresAt: Scalars['Time'];
  listingResourceID: Scalars['Int'];
  nftID: Scalars['Int'];
};

export type CreatePackNft = {
  collectibles: Array<Scalars['String']>;
  distributionFlowId: Scalars['UInt64'];
  fulfillFromIssuer?: InputMaybe<Scalars['Boolean']>;
  packId?: InputMaybe<Scalars['ID']>;
  packReference: PackReference;
};

export type CreatePackNftsInput = {
  packs?: InputMaybe<Array<CreatePackNft>>;
};

export type CreatePackNftsResponse = {
  __typename?: 'CreatePackNftsResponse';
  packs?: Maybe<Array<CreatedPackNft>>;
};

export type CreateReservationLinkForUserInput = {
  distributionID: Scalars['Int'];
  expiryTime: Scalars['String'];
  userFlowAddress: Scalars['String'];
};

export type CreateReservationLinkForUserResponse = {
  __typename?: 'CreateReservationLinkForUserResponse';
  reservationLink?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type CreatedPackNft = {
  ID: Scalars['ID'];
  __typename?: 'CreatedPackNFT';
  collectibles?: Maybe<Array<Scalars['String']>>;
  commitmentHash: Scalars['String'];
  distributionFlowID: Scalars['UInt64'];
};

export enum Currency {
  Usd = 'USD',
}

export type DapperUser = UserDetails & {
  __typename?: 'DapperUser';
  avatar?: Maybe<Scalars['String']>;
  deactivated: Scalars['Boolean'];
  flowAddress: Scalars['String'];
  source: Scalars['String'];
  username: Scalars['String'];
};

export type Distribution = {
  __typename?: 'Distribution';
  acceptReservations?: Maybe<Scalars['Boolean']>;
  availableSupply?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editionIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  endTime?: Maybe<Scalars['Time']>;
  id?: Maybe<Scalars['Int']>;
  images?: Maybe<Array<Maybe<DistributionImage>>>;
  numberOfPackSlots?: Maybe<Scalars['Int']>;
  packNftTypename?: Maybe<Scalars['String']>;
  packOdds?: Maybe<Array<Maybe<DistributionOdds>>>;
  price?: Maybe<Price>;
  queueID?: Maybe<Scalars['String']>;
  seriesIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  startTime?: Maybe<Scalars['Time']>;
  state?: Maybe<DistributionState>;
  tier?: Maybe<DistributionTier>;
  title?: Maybe<Scalars['String']>;
  totalSupply?: Maybe<Scalars['Int']>;
  txHash?: Maybe<Scalars['String']>;
  uuid: Scalars['ID'];
  videos?: Maybe<Array<Maybe<DistributionVideo>>>;
};

export type DistributionEdge = {
  __typename?: 'DistributionEdge';
  cursor: Scalars['String'];
  node?: Maybe<Distribution>;
};

export type DistributionFilters = {
  byEndTime?: InputMaybe<TimeRange>;
  byIDs?: InputMaybe<Array<Scalars['Int']>>;
  byPackNftTypename?: InputMaybe<Scalars['String']>;
  byProductID?: InputMaybe<Scalars['String']>;
  byQueueID?: InputMaybe<Scalars['String']>;
  byStartTime?: InputMaybe<TimeRange>;
  byTier?: InputMaybe<Scalars['String']>;
  byUUIDs?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
};

export type DistributionImage = {
  __typename?: 'DistributionImage';
  type?: Maybe<DistributionImageType>;
  url?: Maybe<Scalars['String']>;
};

export type DistributionImageInput = {
  type?: InputMaybe<DistributionImageType>;
  url?: InputMaybe<Scalars['String']>;
};

export enum DistributionImageType {
  Background = 'BACKGROUND',
  Default = 'DEFAULT',
}

export type DistributionOdds = {
  __typename?: 'DistributionOdds';
  displayValue?: Maybe<Scalars['String']>;
  tier?: Maybe<EditionTier>;
  value?: Maybe<Scalars['Float']>;
};

export enum DistributionSortType {
  CreatedAtAsc = 'CREATED_AT_ASC',
  CreatedAtDesc = 'CREATED_AT_DESC',
  EndTimeAsc = 'END_TIME_ASC',
  EndTimeDesc = 'END_TIME_DESC',
  StartTimeAsc = 'START_TIME_ASC',
  StartTimeDesc = 'START_TIME_DESC',
  UpdatedAtAsc = 'UPDATED_AT_ASC',
  UpdatedAtDesc = 'UPDATED_AT_DESC',
}

export enum DistributionState {
  Complete = 'COMPLETE',
  Initialized = 'INITIALIZED',
  Invalid = 'INVALID',
}

export enum DistributionTier {
  Anthology = 'ANTHOLOGY',
  Common = 'COMMON',
  Fandom = 'FANDOM',
  HistoricPremium = 'HISTORIC_PREMIUM',
  HistoricStandard = 'HISTORIC_STANDARD',
  HistoricStarter = 'HISTORIC_STARTER',
  InSeasonPremium = 'IN_SEASON_PREMIUM',
  InSeasonStandard = 'IN_SEASON_STANDARD',
  InSeasonStarter = 'IN_SEASON_STARTER',
  Legendary = 'LEGENDARY',
  Playoffs = 'PLAYOFFS',
  Premium = 'PREMIUM',
  Rare = 'RARE',
  Special_11V11 = 'SPECIAL_11V11',
  Standard = 'STANDARD',
  Ultimate = 'ULTIMATE',
}

export type DistributionVideo = {
  __typename?: 'DistributionVideo';
  type?: Maybe<DistributionVideoType>;
  url?: Maybe<Scalars['String']>;
};

export type DistributionVideoInput = {
  type?: InputMaybe<DistributionVideoType>;
  url?: InputMaybe<Scalars['String']>;
};

export enum DistributionVideoType {
  Rip = 'RIP',
}

export enum EditionTier {
  Common = 'COMMON',
  Fandom = 'FANDOM',
  Legendary = 'LEGENDARY',
  Rare = 'RARE',
  Ultimate = 'ULTIMATE',
  Uncommon = 'UNCOMMON',
}

export type EntryTier = {
  __typename?: 'EntryTier';
  entryID: Scalars['ID'];
  tierIndex: Scalars['Int'];
};

export type Event = {
  __typename?: 'Event';
  assistedBy?: Maybe<Scalars['String']>;
  card?: Maybe<Card>;
  cardType?: Maybe<CardType>;
  eventType: EventType;
  half: Scalars['Int'];
  min: Scalars['Int'];
  player?: Maybe<Scalars['String']>;
  reason?: Maybe<Scalars['String']>;
  scoredBy?: Maybe<Scalars['String']>;
  subbedOff?: Maybe<Scalars['String']>;
  subbedOn?: Maybe<Scalars['String']>;
  teamName?: Maybe<Scalars['String']>;
};

export enum EventType {
  Discipline = 'DISCIPLINE',
  Goal = 'GOAL',
  Substitution = 'SUBSTITUTION',
}

export type FetchBatchStatusInput = {
  batchKey: Scalars['String'];
};

export type FetchBatchStatusResponse = {
  __typename?: 'FetchBatchStatusResponse';
  batchKey: Scalars['String'];
  totalTransactionsCount: Scalars['Int'];
  transactionsFailedCount: Scalars['Int'];
  transactionsPendingCount: Scalars['Int'];
  transactionsSucceededCount: Scalars['Int'];
};

export type FloatAggregation = {
  __typename?: 'FloatAggregation';
  avg?: Maybe<Scalars['Float']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Float']>;
  max?: Maybe<Scalars['Float']>;
  min?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['Float']>;
};

export type FloatFilter = {
  eq?: InputMaybe<Scalars['Float']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Float']>;
  gte?: InputMaybe<Scalars['Float']>;
  in?: InputMaybe<Array<Scalars['Float']>>;
  lt?: InputMaybe<Scalars['Float']>;
  lte?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
};

export type FloatListFilter = {
  contains?: InputMaybe<Array<Scalars['Float']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type GetMyProfileResponse = {
  __typename?: 'GetMyProfileResponse';
  userProfile?: Maybe<UserProfile>;
};

export type GetPlayInput = {
  playID: Scalars['String'];
};

export type GetPlayResponse = {
  __typename?: 'GetPlayResponse';
  play: Play;
};

export type GetPlayerInput = {
  playerID: Scalars['String'];
};

export type GetPlayerResponse = {
  __typename?: 'GetPlayerResponse';
  player: Player;
};

export type GetTransactionHashesInput = {
  batchKey: Scalars['String'];
};

export type GetTransactionHashesResponse = {
  __typename?: 'GetTransactionHashesResponse';
  transactionHashes?: Maybe<Array<Scalars['String']>>;
};

export type GetUserActiveReservationInput = {
  distributionID: Scalars['Int'];
};

export type GetUserActiveReservationResponse = {
  __typename?: 'GetUserActiveReservationResponse';
  reservation: Reservation;
};

export type GetUserActiveReservationsInput = {
  distributionID: Scalars['Int'];
  flowAddress: Scalars['String'];
};

export type GetUserActiveReservationsResponse = {
  __typename?: 'GetUserActiveReservationsResponse';
  reservations?: Maybe<Array<Maybe<Reservation>>>;
};

export type GolazosEdition = {
  __typename?: 'GolazosEdition';
  closed?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  max_mintings?: Maybe<Scalars['UInt64']>;
  play?: Maybe<GolazosPlay>;
  series?: Maybe<GolazosSeries>;
  set?: Maybe<GolazosSet>;
  tier?: Maybe<Scalars['String']>;
  total_burned?: Maybe<Scalars['UInt64']>;
  total_minted?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type GolazosEditionAggregation = {
  __typename?: 'GolazosEditionAggregation';
  closed?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  max_mintings?: Maybe<UInt64Aggregation>;
  play?: Maybe<GolazosPlayAggregation>;
  series?: Maybe<GolazosSeriesAggregation>;
  set?: Maybe<GolazosSetAggregation>;
  tier?: Maybe<StringAggregation>;
  total_burned?: Maybe<StatsInfoAggregation>;
  total_minted?: Maybe<StatsInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type GolazosEditionFilter = {
  closed?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  max_mintings?: InputMaybe<UInt64Filter>;
  play?: InputMaybe<GolazosPlayFilter>;
  series?: InputMaybe<GolazosSeriesFilter>;
  set?: InputMaybe<GolazosSetFilter>;
  tier?: InputMaybe<StringFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type GolazosEditionSort = {
  closed?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  max_mintings?: InputMaybe<Sort>;
  play?: InputMaybe<GolazosPlaySort>;
  series?: InputMaybe<GolazosSeriesSort>;
  set?: InputMaybe<GolazosSetSort>;
  tier?: InputMaybe<SortString>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type GolazosEditionSortAggregation = {
  closed?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  max_mintings?: InputMaybe<SortAggregation>;
  play?: InputMaybe<GolazosPlaySortAggregation>;
  series?: InputMaybe<GolazosSeriesSortAggregation>;
  set?: InputMaybe<GolazosSetSortAggregation>;
  tier?: InputMaybe<SortStringKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type GolazosMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
  edition_id?: InputMaybe<UInt64Filter>;
};

export type GolazosMarketplaceTransaction = {
  __typename?: 'GolazosMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<GolazosNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type GolazosMarketplaceTransactionEdge = {
  __typename?: 'GolazosMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<GolazosMarketplaceTransaction>;
};

export type GolazosNft = {
  __typename?: 'GolazosNft';
  burned_at?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  edition?: Maybe<GolazosEdition>;
  id?: Maybe<Scalars['UInt64']>;
  listing?: Maybe<Listing>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  serial_number?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type GolazosNftAggregation = {
  __typename?: 'GolazosNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  edition?: Maybe<GolazosEditionAggregation>;
  id?: Maybe<UInt64Aggregation>;
  listing?: Maybe<ListingAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  serial_number?: Maybe<UInt64Aggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type GolazosNftAggregationEdge = {
  __typename?: 'GolazosNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<GolazosNftAggregation>;
};

export type GolazosNftCount = {
  edition_id: Scalars['UInt64'];
  quantity: Scalars['Int'];
};

export type GolazosNftEdge = {
  __typename?: 'GolazosNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<GolazosNft>;
};

export type GolazosNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  edition?: InputMaybe<GolazosEditionFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  serial_number?: InputMaybe<UInt64Filter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type GolazosNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  edition?: InputMaybe<GolazosEditionSort>;
  id?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  serial_number?: InputMaybe<Sort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type GolazosNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  edition?: InputMaybe<GolazosEditionSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  serial_number?: InputMaybe<SortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type GolazosPlay = {
  __typename?: 'GolazosPlay';
  classification?: Maybe<Scalars['String']>;
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  metadata?: Maybe<GolazosPlayMetadata>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type GolazosPlayAggregation = {
  __typename?: 'GolazosPlayAggregation';
  classification?: Maybe<StringAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  metadata?: Maybe<GolazosPlayMetadataAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type GolazosPlayFilter = {
  classification?: InputMaybe<StringFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  metadata?: InputMaybe<GolazosPlayMetadataFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type GolazosPlayMetadata = {
  __typename?: 'GolazosPlayMetadata';
  match_away_score?: Maybe<Scalars['String']>;
  match_away_team?: Maybe<Scalars['String']>;
  match_date?: Maybe<Scalars['Time']>;
  match_day?: Maybe<Scalars['String']>;
  match_highlighted_team?: Maybe<Scalars['String']>;
  match_home_score?: Maybe<Scalars['String']>;
  match_home_team?: Maybe<Scalars['String']>;
  match_season?: Maybe<Scalars['String']>;
  play_data_id?: Maybe<Scalars['String']>;
  play_half?: Maybe<Scalars['Int']>;
  play_time?: Maybe<Scalars['String']>;
  play_type?: Maybe<Scalars['String']>;
  player_country?: Maybe<Scalars['String']>;
  player_data_id?: Maybe<Scalars['String']>;
  player_first_name?: Maybe<Scalars['String']>;
  player_jersey_name?: Maybe<Scalars['String']>;
  player_known_name?: Maybe<Scalars['String']>;
  player_last_name?: Maybe<Scalars['String']>;
  player_number?: Maybe<Scalars['Int']>;
  player_position?: Maybe<Scalars['String']>;
};

export type GolazosPlayMetadataAggregation = {
  __typename?: 'GolazosPlayMetadataAggregation';
  match_away_score?: Maybe<StringAggregation>;
  match_away_team?: Maybe<StringAggregation>;
  match_date?: Maybe<TimeAggregation>;
  match_day?: Maybe<StringAggregation>;
  match_highlighted_team?: Maybe<StringAggregation>;
  match_home_score?: Maybe<StringAggregation>;
  match_home_team?: Maybe<StringAggregation>;
  match_season?: Maybe<StringAggregation>;
  play_data_id?: Maybe<StringAggregation>;
  play_half?: Maybe<IntAggregation>;
  play_time?: Maybe<StringAggregation>;
  play_type?: Maybe<StringAggregation>;
  player_country?: Maybe<StringAggregation>;
  player_data_id?: Maybe<StringAggregation>;
  player_first_name?: Maybe<StringAggregation>;
  player_jersey_name?: Maybe<StringAggregation>;
  player_known_name?: Maybe<StringAggregation>;
  player_last_name?: Maybe<StringAggregation>;
  player_number?: Maybe<IntAggregation>;
  player_position?: Maybe<StringAggregation>;
};

export type GolazosPlayMetadataFilter = {
  exists?: InputMaybe<Scalars['Boolean']>;
  match_away_score?: InputMaybe<StringFilter>;
  match_away_team?: InputMaybe<StringFilter>;
  match_date?: InputMaybe<TimeFilter>;
  match_day?: InputMaybe<StringFilter>;
  match_highlighted_team?: InputMaybe<StringFilter>;
  match_home_score?: InputMaybe<StringFilter>;
  match_home_team?: InputMaybe<StringFilter>;
  match_season?: InputMaybe<StringFilter>;
  play_data_id?: InputMaybe<StringFilter>;
  play_half?: InputMaybe<IntFilter>;
  play_time?: InputMaybe<StringFilter>;
  play_type?: InputMaybe<StringFilter>;
  player_country?: InputMaybe<StringFilter>;
  player_data_id?: InputMaybe<StringFilter>;
  player_first_name?: InputMaybe<StringFilter>;
  player_jersey_name?: InputMaybe<StringFilter>;
  player_known_name?: InputMaybe<StringFilter>;
  player_last_name?: InputMaybe<StringFilter>;
  player_number?: InputMaybe<IntFilter>;
  player_position?: InputMaybe<StringFilter>;
};

export type GolazosPlayMetadataInput = {
  match_away_score: Scalars['String'];
  match_away_team: Scalars['String'];
  match_date: Scalars['Time'];
  match_day: Scalars['String'];
  match_highlighted_team: Scalars['String'];
  match_home_score: Scalars['String'];
  match_home_team: Scalars['String'];
  match_season: Scalars['String'];
  play_data_id: Scalars['String'];
  play_half: Scalars['Int'];
  play_time: Scalars['String'];
  play_type: Scalars['String'];
  player_country: Scalars['String'];
  player_data_id: Scalars['String'];
  player_first_name: Scalars['String'];
  player_jersey_name: Scalars['String'];
  player_known_name: Scalars['String'];
  player_last_name: Scalars['String'];
  player_number: Scalars['Int'];
  player_position: Scalars['String'];
};

export type GolazosPlayMetadataSort = {
  match_away_score?: InputMaybe<SortString>;
  match_away_team?: InputMaybe<SortString>;
  match_date?: InputMaybe<Sort>;
  match_day?: InputMaybe<SortString>;
  match_highlighted_team?: InputMaybe<SortString>;
  match_home_score?: InputMaybe<SortString>;
  match_home_team?: InputMaybe<SortString>;
  match_season?: InputMaybe<SortString>;
  play_data_id?: InputMaybe<SortString>;
  play_half?: InputMaybe<Sort>;
  play_time?: InputMaybe<SortString>;
  play_type?: InputMaybe<SortString>;
  player_country?: InputMaybe<SortString>;
  player_data_id?: InputMaybe<SortString>;
  player_first_name?: InputMaybe<SortString>;
  player_jersey_name?: InputMaybe<SortString>;
  player_known_name?: InputMaybe<SortString>;
  player_last_name?: InputMaybe<SortString>;
  player_number?: InputMaybe<Sort>;
  player_position?: InputMaybe<SortString>;
};

export type GolazosPlayMetadataSortAggregation = {
  match_away_score?: InputMaybe<SortStringKey>;
  match_away_team?: InputMaybe<SortStringKey>;
  match_date?: InputMaybe<SortKey>;
  match_day?: InputMaybe<SortStringKey>;
  match_highlighted_team?: InputMaybe<SortStringKey>;
  match_home_score?: InputMaybe<SortStringKey>;
  match_home_team?: InputMaybe<SortStringKey>;
  match_season?: InputMaybe<SortStringKey>;
  play_data_id?: InputMaybe<SortStringKey>;
  play_half?: InputMaybe<SortAggregation>;
  play_time?: InputMaybe<SortStringKey>;
  play_type?: InputMaybe<SortStringKey>;
  player_country?: InputMaybe<SortStringKey>;
  player_data_id?: InputMaybe<SortStringKey>;
  player_first_name?: InputMaybe<SortStringKey>;
  player_jersey_name?: InputMaybe<SortStringKey>;
  player_known_name?: InputMaybe<SortStringKey>;
  player_last_name?: InputMaybe<SortStringKey>;
  player_number?: InputMaybe<SortAggregation>;
  player_position?: InputMaybe<SortStringKey>;
};

export type GolazosPlaySort = {
  classification?: InputMaybe<SortString>;
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  metadata?: InputMaybe<GolazosPlayMetadataSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type GolazosPlaySortAggregation = {
  classification?: InputMaybe<SortStringKey>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<GolazosPlayMetadataSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type GolazosSeries = {
  __typename?: 'GolazosSeries';
  active?: Maybe<Scalars['Boolean']>;
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  name?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type GolazosSeriesAggregation = {
  __typename?: 'GolazosSeriesAggregation';
  active?: Maybe<BooleanAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  name?: Maybe<StringAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type GolazosSeriesFilter = {
  active?: InputMaybe<BooleanFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  name?: InputMaybe<StringFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type GolazosSeriesSort = {
  active?: InputMaybe<Sort>;
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  name?: InputMaybe<SortString>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type GolazosSeriesSortAggregation = {
  active?: InputMaybe<SortKey>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  name?: InputMaybe<SortStringKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type GolazosSet = {
  __typename?: 'GolazosSet';
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt64']>;
  name?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type GolazosSetAggregation = {
  __typename?: 'GolazosSetAggregation';
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  name?: Maybe<StringAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type GolazosSetFilter = {
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  name?: InputMaybe<StringFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type GolazosSetSort = {
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  name?: InputMaybe<SortString>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type GolazosSetSortAggregation = {
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  name?: InputMaybe<SortStringKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type Int8Aggregation = {
  __typename?: 'Int8Aggregation';
  avg?: Maybe<Scalars['Int8']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int8']>;
  max?: Maybe<Scalars['Int8']>;
  min?: Maybe<Scalars['Int8']>;
  value?: Maybe<Scalars['Int8']>;
};

export type Int8Filter = {
  eq?: InputMaybe<Scalars['Int8']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Int8']>;
  gte?: InputMaybe<Scalars['Int8']>;
  in?: InputMaybe<Array<Scalars['Int8']>>;
  lt?: InputMaybe<Scalars['Int8']>;
  lte?: InputMaybe<Scalars['Int8']>;
  ne?: InputMaybe<Scalars['Int8']>;
  notIn?: InputMaybe<Array<Scalars['Int8']>>;
};

export type Int8ListFilter = {
  contains?: InputMaybe<Array<Scalars['Int8']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type Int16Aggregation = {
  __typename?: 'Int16Aggregation';
  avg?: Maybe<Scalars['Int16']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int16']>;
  max?: Maybe<Scalars['Int16']>;
  min?: Maybe<Scalars['Int16']>;
  value?: Maybe<Scalars['Int16']>;
};

export type Int16Filter = {
  eq?: InputMaybe<Scalars['Int16']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Int16']>;
  gte?: InputMaybe<Scalars['Int16']>;
  in?: InputMaybe<Array<Scalars['Int16']>>;
  lt?: InputMaybe<Scalars['Int16']>;
  lte?: InputMaybe<Scalars['Int16']>;
  ne?: InputMaybe<Scalars['Int16']>;
  notIn?: InputMaybe<Array<Scalars['Int16']>>;
};

export type Int16ListFilter = {
  contains?: InputMaybe<Array<Scalars['Int16']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type Int64Aggregation = {
  __typename?: 'Int64Aggregation';
  avg?: Maybe<Scalars['Int64']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int64']>;
  max?: Maybe<Scalars['Int64']>;
  min?: Maybe<Scalars['Int64']>;
  value?: Maybe<Scalars['Int64']>;
};

export type Int64Filter = {
  eq?: InputMaybe<Scalars['Int64']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Int64']>;
  gte?: InputMaybe<Scalars['Int64']>;
  in?: InputMaybe<Array<Scalars['Int64']>>;
  lt?: InputMaybe<Scalars['Int64']>;
  lte?: InputMaybe<Scalars['Int64']>;
  ne?: InputMaybe<Scalars['Int64']>;
  notIn?: InputMaybe<Array<Scalars['Int64']>>;
};

export type Int64ListFilter = {
  contains?: InputMaybe<Array<Scalars['Int64']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type IntAggregation = {
  __typename?: 'IntAggregation';
  avg?: Maybe<Scalars['Int']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Int']>;
  max?: Maybe<Scalars['Int']>;
  min?: Maybe<Scalars['Int']>;
  value?: Maybe<Scalars['Int']>;
};

export type IntFilter = {
  eq?: InputMaybe<Scalars['Int']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Int']>;
  gte?: InputMaybe<Scalars['Int']>;
  in?: InputMaybe<Array<Scalars['Int']>>;
  lt?: InputMaybe<Scalars['Int']>;
  lte?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
  notIn?: InputMaybe<Array<Scalars['Int']>>;
};

export type IntListFilter = {
  contains?: InputMaybe<Array<Scalars['Int']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type JoinDropInput = {
  distributionID: Scalars['String'];
};

export type JoinDropResponse = {
  __typename?: 'JoinDropResponse';
  success?: Maybe<Scalars['Boolean']>;
};

export type KeyValueEntry = {
  __typename?: 'KeyValueEntry';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type ListNfTsInput = {
  flowIDs: Array<Scalars['Int']>;
  price: Scalars['Float'];
};

export type ListPackNfTsByDistributionInput = {
  distributionID: Scalars['Int'];
  quantity: Scalars['Int'];
};

export type ListPackNfTsByDistributionResponse = {
  __typename?: 'ListPackNFTsByDistributionResponse';
  batchKey: Scalars['String'];
  count: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type Listing = {
  __typename?: 'Listing';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type ListingAggregation = {
  __typename?: 'ListingAggregation';
  commission_amount?: Maybe<UInt64Aggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  custom_id?: Maybe<StringAggregation>;
  expiry?: Maybe<UInt64Aggregation>;
  ft_vault_type?: Maybe<StringAggregation>;
  listing_resource_id?: Maybe<UInt64Aggregation>;
  nft_id?: Maybe<UInt64Aggregation>;
  nft_type?: Maybe<StringAggregation>;
  nft_uuid?: Maybe<UInt64Aggregation>;
  price?: Maybe<UInt64Aggregation>;
  sale_payment_vault_type?: Maybe<StringAggregation>;
  sales_price?: Maybe<UInt64Aggregation>;
  storefront_address?: Maybe<StringAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type ListingFilter = {
  commission_amount?: InputMaybe<UInt64Filter>;
  commission_receivers?: InputMaybe<StringListFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  custom_id?: InputMaybe<StringFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  expiry?: InputMaybe<UInt64Filter>;
  ft_vault_type?: InputMaybe<StringFilter>;
  listing_resource_id?: InputMaybe<UInt64Filter>;
  nft_id?: InputMaybe<UInt64Filter>;
  nft_type?: InputMaybe<StringFilter>;
  nft_uuid?: InputMaybe<UInt64Filter>;
  price?: InputMaybe<UInt64Filter>;
  sale_payment_vault_type?: InputMaybe<StringFilter>;
  sales_price?: InputMaybe<UInt64Filter>;
  storefront_address?: InputMaybe<StringFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type ListingSort = {
  commission_amount?: InputMaybe<Sort>;
  created_at?: InputMaybe<BlockInfoSort>;
  custom_id?: InputMaybe<SortString>;
  expiry?: InputMaybe<Sort>;
  ft_vault_type?: InputMaybe<SortString>;
  listing_resource_id?: InputMaybe<Sort>;
  nft_id?: InputMaybe<Sort>;
  nft_type?: InputMaybe<SortString>;
  nft_uuid?: InputMaybe<Sort>;
  price?: InputMaybe<Sort>;
  sale_payment_vault_type?: InputMaybe<SortString>;
  sales_price?: InputMaybe<Sort>;
  storefront_address?: InputMaybe<SortString>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type ListingSortAggregation = {
  commission_amount?: InputMaybe<SortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  custom_id?: InputMaybe<SortStringKey>;
  expiry?: InputMaybe<SortAggregation>;
  ft_vault_type?: InputMaybe<SortStringKey>;
  listing_resource_id?: InputMaybe<SortAggregation>;
  nft_id?: InputMaybe<SortAggregation>;
  nft_type?: InputMaybe<SortStringKey>;
  nft_uuid?: InputMaybe<SortAggregation>;
  price?: InputMaybe<SortAggregation>;
  sale_payment_vault_type?: InputMaybe<SortStringKey>;
  sales_price?: InputMaybe<SortAggregation>;
  storefront_address?: InputMaybe<SortStringKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type MarketplaceHistoryFilter = {
  commission_amount?: InputMaybe<UInt64Filter>;
  commission_receivers?: InputMaybe<StringListFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  custom_id?: InputMaybe<StringFilter>;
  expiry?: InputMaybe<UInt64Filter>;
  ft_vault_type?: InputMaybe<StringFilter>;
  listing_resource_id?: InputMaybe<UInt64Filter>;
  nft_id?: InputMaybe<UInt64Filter>;
  nft_type?: InputMaybe<StringFilter>;
  nft_uuid?: InputMaybe<UInt64Filter>;
  price?: InputMaybe<UInt64Filter>;
  purchased?: InputMaybe<BooleanFilter>;
  receiver_address?: InputMaybe<StringFilter>;
  sale_payment_vault_type?: InputMaybe<StringFilter>;
  sales_price?: InputMaybe<UInt64Filter>;
  storefront_address?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type MarketplaceHistorySort = {
  commission_amount?: InputMaybe<Sort>;
  created_at?: InputMaybe<BlockInfoSort>;
  custom_id?: InputMaybe<SortString>;
  expiry?: InputMaybe<Sort>;
  listing_resource_id?: InputMaybe<Sort>;
  nft_id?: InputMaybe<Sort>;
  nft_type?: InputMaybe<Sort>;
  nft_uuid?: InputMaybe<Sort>;
  price?: InputMaybe<Sort>;
  purchased?: InputMaybe<Sort>;
  sale_payment_vault_type?: InputMaybe<SortString>;
  sales_price?: InputMaybe<Sort>;
  storefront_address?: InputMaybe<Sort>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type MetadataEntry = {
  __typename?: 'MetadataEntry';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type MetadataFilter = {
  comparator: Comparator;
  field: Scalars['String'];
  in?: InputMaybe<Array<Value>>;
  value: Value;
};

export type MintAllDayNftsInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  counts: Array<InputMaybe<AllDayNftCount>>;
};

export type MintAllDayNftsResponse = {
  __typename?: 'MintAllDayNftsResponse';
  batchKey: Scalars['String'];
  transactionsCount: Scalars['Int'];
};

export type MintAllDaySeasonalEditionInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  metadata: AllDaySeasonalEditionMetadataInput;
};

export type MintAllDaySeasonalEditionResponse = {
  __typename?: 'MintAllDaySeasonalEditionResponse';
  batchKey: Scalars['String'];
  transactionsCount: Scalars['Int'];
};

export type MintAllDaySeasonalNftsInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  counts: Array<InputMaybe<AllDaySeasonalNftCount>>;
};

export type MintAllDaySeasonalNftsResponse = {
  __typename?: 'MintAllDaySeasonalNftsResponse';
  batchKey: Scalars['String'];
  transactionsCount: Scalars['Int'];
};

export type MintDistributionInput = {
  distributionUUID: Scalars['ID'];
};

export type MintDistributionResponse = {
  __typename?: 'MintDistributionResponse';
  batchKey: Scalars['String'];
  success: Scalars['Boolean'];
};

export type MintGolazosEditionInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  max_mintings?: InputMaybe<Scalars['UInt64']>;
  play_id: Scalars['UInt64'];
  series_id: Scalars['UInt64'];
  set_id: Scalars['UInt64'];
  tier: Scalars['String'];
};

export type MintGolazosEditionResponse = {
  __typename?: 'MintGolazosEditionResponse';
  batchKey: Scalars['String'];
  transactionsCount: Scalars['Int'];
};

export type MintGolazosNftsInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  counts: Array<InputMaybe<GolazosNftCount>>;
};

export type MintGolazosNftsResponse = {
  __typename?: 'MintGolazosNftsResponse';
  batchKey: Scalars['String'];
  transactionsCount: Scalars['Int'];
};

export type MintGolazosPlayInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  classification: Scalars['String'];
  metadata: GolazosPlayMetadataInput;
};

export type MintGolazosPlayResponse = {
  __typename?: 'MintGolazosPlayResponse';
  batchKey: Scalars['String'];
  transactionsCount: Scalars['Int'];
};

export type MintGolazosSeriesInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MintGolazosSeriesResponse = {
  __typename?: 'MintGolazosSeriesResponse';
  batchKey: Scalars['String'];
  transactionsCount: Scalars['Int'];
};

export type MintGolazosSetInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
};

export type MintGolazosSetResponse = {
  __typename?: 'MintGolazosSetResponse';
  batchKey: Scalars['String'];
  transactionsCount: Scalars['Int'];
};

export type MintSportMomentsPackNftsInput = {
  distributionID: Scalars['Int'];
  packNftTemplate: SportMomentsPackNftTemplate;
};

export type MintSportMomentsPackNftsResponse = {
  __typename?: 'MintSportMomentsPackNftsResponse';
  distributionID: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type Mutation = {
  NoOp?: Maybe<Scalars['Boolean']>;
  __typename?: 'Mutation';
  addToQueue?: Maybe<AddToQueueResponse>;
  airdropPackNFT?: Maybe<AirdropPackNftResponse>;
  authorizeAllDaySeasonalTransaction?: Maybe<AuthorizeAllDaySeasonalTransactionResponse>;
  authorizeCustomerSupportPackNFTReservation: AuthorizeCustomerSupportPackNftReservationResponse;
  authorizeCustomerSupportReservation: AuthorizeCustomerSupportReservationResponse;
  authorizeGolazosTransaction?: Maybe<AuthorizeGolazosTransactionResponse>;
  authorizePackNFTReservation: AuthorizePackNftReservationResponse;
  authorizePackNftsMinting: AuthorizePackNftsMintingResponse;
  authorizePackPurchase: AuthorizePackPurchaseResponse;
  createDistribution?: Maybe<CreateDistributionResponse>;
  createMarketplaceReservation?: Maybe<CreateMarketplaceReservationResponse>;
  createPackNfts?: Maybe<CreatePackNftsResponse>;
  createReservationLinkForUser: CreateReservationLinkForUserResponse;
  /** Decrement gauge by value specified in input */
  decGauge?: Maybe<PromMetricResponse>;
  /** Increment counter by value specified in input */
  incCounter?: Maybe<PromMetricResponse>;
  /** Increment guage by value specified in input */
  incGauge?: Maybe<PromMetricResponse>;
  joinDrop: JoinDropResponse;
  listPackNFTsByDistribution?: Maybe<ListPackNfTsByDistributionResponse>;
  mintAllDayNfts?: Maybe<MintAllDayNftsResponse>;
  mintAllDaySeasonalEdition?: Maybe<MintAllDaySeasonalEditionResponse>;
  mintAllDaySeasonalNfts?: Maybe<MintAllDaySeasonalNftsResponse>;
  mintDistribution?: Maybe<MintDistributionResponse>;
  mintGolazosEdition?: Maybe<MintGolazosEditionResponse>;
  mintGolazosNfts?: Maybe<MintGolazosNftsResponse>;
  mintGolazosPackNfts?: Maybe<MintSportMomentsPackNftsResponse>;
  mintGolazosPlay?: Maybe<MintGolazosPlayResponse>;
  mintGolazosSeries?: Maybe<MintGolazosSeriesResponse>;
  mintGolazosSet?: Maybe<MintGolazosSetResponse>;
  mintSportMomentsPackNfts?: Maybe<MintSportMomentsPackNftsResponse>;
  /** Observe a latency or size by the value specified in input for a Histogram metric */
  observeHistogram?: Maybe<PromMetricResponse>;
  /** Observe a latency or size by the value specified in input for a Summary metric */
  observeSummary?: Maybe<PromMetricResponse>;
  releaseQueueBatch?: Maybe<ReleaseQueueBatchResponse>;
  reservePackNFTsForCustomerSupport: ReservePackNfTsForCustomerSupportResponse;
  /** Set gauge to value specified in input */
  setGauge?: Maybe<PromMetricResponse>;
  transferAllDayNfts?: Maybe<TransferAllDayNftsResponse>;
  transferPackNfts?: Maybe<TransferPackNftsResponse>;
  updateDistribution?: Maybe<UpdateDistributionResponse>;
  upsertAirdropEvent: UpsertAirdropEventResponse;
  upsertUserProfile: UpsertUserProfileResponse;
};

export type MutationAddToQueueArgs = {
  input: AddToQueueInput;
};

export type MutationAirdropPackNftArgs = {
  input: AirdropPackNftInput;
};

export type MutationAuthorizeAllDaySeasonalTransactionArgs = {
  input: AuthorizeAllDaySeasonalTransactionInput;
};

export type MutationAuthorizeCustomerSupportPackNftReservationArgs = {
  input: AuthorizeCustomerSupportPackNftReservationInput;
};

export type MutationAuthorizeCustomerSupportReservationArgs = {
  input?: InputMaybe<AuthorizeCustomerSupportReservationInput>;
};

export type MutationAuthorizeGolazosTransactionArgs = {
  input: AuthorizeGolazosTransactionInput;
};

export type MutationAuthorizePackNftReservationArgs = {
  input: AuthorizePackNftReservationInput;
};

export type MutationAuthorizePackNftsMintingArgs = {
  input: AuthorizePackNftsMintingInput;
};

export type MutationAuthorizePackPurchaseArgs = {
  input?: InputMaybe<AuthorizePackPurchaseInput>;
};

export type MutationCreateDistributionArgs = {
  input: CreateDistributionInput;
};

export type MutationCreateMarketplaceReservationArgs = {
  input?: InputMaybe<CreateMarketplaceReservationInput>;
};

export type MutationCreatePackNftsArgs = {
  input: CreatePackNftsInput;
};

export type MutationCreateReservationLinkForUserArgs = {
  input: CreateReservationLinkForUserInput;
};

export type MutationDecGaugeArgs = {
  input: PromMetricsInput;
};

export type MutationIncCounterArgs = {
  input: PromMetricsInput;
};

export type MutationIncGaugeArgs = {
  input: PromMetricsInput;
};

export type MutationJoinDropArgs = {
  input: JoinDropInput;
};

export type MutationListPackNfTsByDistributionArgs = {
  input: ListPackNfTsByDistributionInput;
};

export type MutationMintAllDayNftsArgs = {
  input: MintAllDayNftsInput;
};

export type MutationMintAllDaySeasonalEditionArgs = {
  input: MintAllDaySeasonalEditionInput;
};

export type MutationMintAllDaySeasonalNftsArgs = {
  input: MintAllDaySeasonalNftsInput;
};

export type MutationMintDistributionArgs = {
  input: MintDistributionInput;
};

export type MutationMintGolazosEditionArgs = {
  input: MintGolazosEditionInput;
};

export type MutationMintGolazosNftsArgs = {
  input: MintGolazosNftsInput;
};

export type MutationMintGolazosPackNftsArgs = {
  input: MintSportMomentsPackNftsInput;
};

export type MutationMintGolazosPlayArgs = {
  input: MintGolazosPlayInput;
};

export type MutationMintGolazosSeriesArgs = {
  input: MintGolazosSeriesInput;
};

export type MutationMintGolazosSetArgs = {
  input: MintGolazosSetInput;
};

export type MutationMintSportMomentsPackNftsArgs = {
  input: MintSportMomentsPackNftsInput;
};

export type MutationObserveHistogramArgs = {
  input: PromMetricsInput;
};

export type MutationObserveSummaryArgs = {
  input: PromMetricsInput;
};

export type MutationReleaseQueueBatchArgs = {
  input: ReleaseQueueBatchInput;
};

export type MutationReservePackNfTsForCustomerSupportArgs = {
  input: ReservePackNfTsForCustomerSupportInput;
};

export type MutationSetGaugeArgs = {
  input: PromMetricsInput;
};

export type MutationTransferAllDayNftsArgs = {
  input: TransferAllDayNftsInput;
};

export type MutationTransferPackNftsArgs = {
  input: TransferPackNftsInput;
};

export type MutationUpdateDistributionArgs = {
  input: UpdateDistributionInput;
};

export type MutationUpsertAirdropEventArgs = {
  input: UpsertAirdropEventInput;
};

export type MutationUpsertUserProfileArgs = {
  input: UpsertUserProfileInput;
};

export type Nft = {
  __typename?: 'Nft';
  id: Scalars['UInt64'];
  metadata?: Maybe<Array<MetadataEntry>>;
};

export type NftEdge = {
  __typename?: 'NftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<Nft>;
};

export type NftFilter = {
  and?: InputMaybe<Array<NftFilter>>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  metadata_filter?: InputMaybe<MetadataFilter>;
  or?: InputMaybe<Array<NftFilter>>;
};

export type NftFilters = {
  nft_filter?: InputMaybe<NftFilter>;
  nft_type_filter?: InputMaybe<NftTypeFilter>;
};

export type NftSort = {
  direction?: InputMaybe<SortDirection>;
  field?: InputMaybe<Scalars['String']>;
  priority?: InputMaybe<Scalars['Int']>;
};

export type NftTypeFilter = {
  nft_types?: InputMaybe<Array<Scalars['String']>>;
  type_filter_type?: InputMaybe<TypeFilterType>;
};

export type PackMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
  dist_id?: InputMaybe<Scalars['String']>;
};

export type PackMarketplaceTransaction = {
  __typename?: 'PackMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<PackNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type PackMarketplaceTransactionEdge = {
  __typename?: 'PackMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PackMarketplaceTransaction>;
};

export type PackNft = {
  __typename?: 'PackNft';
  burned_at?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  dist_id?: Maybe<Scalars['UInt64']>;
  hash?: Maybe<Array<Maybe<Scalars['UInt8']>>>;
  id?: Maybe<Scalars['UInt64']>;
  listing?: Maybe<Listing>;
  metadata_updated_at?: Maybe<BlockInfo>;
  nfts?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  salt?: Maybe<Array<Maybe<Scalars['UInt8']>>>;
  status?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type PackNftAggregation = {
  __typename?: 'PackNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  dist_id?: Maybe<UInt64Aggregation>;
  id?: Maybe<UInt64Aggregation>;
  listing?: Maybe<ListingAggregation>;
  metadata_updated_at?: Maybe<BlockInfoAggregation>;
  nfts?: Maybe<StringAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  status?: Maybe<StringAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type PackNftAggregationEdge = {
  __typename?: 'PackNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PackNftAggregation>;
};

export type PackNftEdge = {
  __typename?: 'PackNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<PackNft>;
};

export type PackNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  dist_id?: InputMaybe<UInt64Filter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  hash?: InputMaybe<UInt8ListFilter>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  metadata_updated_at?: InputMaybe<BlockInfoFilter>;
  nfts?: InputMaybe<StringFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  reserved?: InputMaybe<BooleanFilter>;
  salt?: InputMaybe<UInt8ListFilter>;
  status?: InputMaybe<StringFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type PackNftMetadata = {
  __typename?: 'PackNftMetadata';
  dist_id?: Maybe<Scalars['UInt64']>;
  nfts?: Maybe<Scalars['String']>;
  salt?: Maybe<Array<Maybe<Scalars['UInt8']>>>;
  status?: Maybe<Scalars['String']>;
};

export type PackNftMetadataAggregation = {
  __typename?: 'PackNftMetadataAggregation';
  dist_id?: Maybe<UInt64Aggregation>;
  nfts?: Maybe<StringAggregation>;
  status?: Maybe<StringAggregation>;
};

export type PackNftMetadataFilter = {
  dist_id?: InputMaybe<UInt64Filter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  nfts?: InputMaybe<StringFilter>;
  reserved?: InputMaybe<BooleanFilter>;
  salt?: InputMaybe<UInt8ListFilter>;
  status?: InputMaybe<StringFilter>;
};

export type PackNftMetadataSort = {
  dist_id?: InputMaybe<Sort>;
  nfts?: InputMaybe<SortString>;
  status?: InputMaybe<SortString>;
};

export type PackNftMetadataSortAggregation = {
  dist_id?: InputMaybe<SortAggregation>;
  nfts?: InputMaybe<SortStringKey>;
  status?: InputMaybe<SortStringKey>;
};

export type PackNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  dist_id?: InputMaybe<Sort>;
  id?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  metadata_updated_at?: InputMaybe<BlockInfoSort>;
  nfts?: InputMaybe<SortString>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  status?: InputMaybe<SortString>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type PackNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  dist_id?: InputMaybe<SortAggregation>;
  id?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  metadata_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  nfts?: InputMaybe<SortStringKey>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  status?: InputMaybe<SortStringKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type PackReference = {
  address?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage?: Maybe<Scalars['Boolean']>;
};

export type Play = {
  __typename?: 'Play';
  awayTeamAccuratePasses?: Maybe<Scalars['Int']>;
  awayTeamCornerTaken?: Maybe<Scalars['Int']>;
  awayTeamFreekickFoulLost?: Maybe<Scalars['Int']>;
  awayTeamGoals?: Maybe<Scalars['Int']>;
  awayTeamPossessionPercentage?: Maybe<Scalars['Int']>;
  awayTeamShotsOnTarget?: Maybe<Scalars['Int']>;
  awayTeamTotalPasses?: Maybe<Scalars['Int']>;
  awayTeamTotalRedCard?: Maybe<Scalars['Int']>;
  awayTeamTotalShots?: Maybe<Scalars['Int']>;
  awayTeamTotalYellowCard?: Maybe<Scalars['Int']>;
  descriptionEN: Scalars['String'];
  descriptionES: Scalars['String'];
  homeTeamAccuratePasses?: Maybe<Scalars['Int']>;
  homeTeamCornerTaken?: Maybe<Scalars['Int']>;
  homeTeamFreekickFoulLost?: Maybe<Scalars['Int']>;
  homeTeamGoals?: Maybe<Scalars['Int']>;
  homeTeamPossessionPercentage?: Maybe<Scalars['Int']>;
  homeTeamShotsOnTarget?: Maybe<Scalars['Int']>;
  homeTeamTotalPasses?: Maybe<Scalars['Int']>;
  homeTeamTotalRedCard?: Maybe<Scalars['Int']>;
  homeTeamTotalShots?: Maybe<Scalars['Int']>;
  homeTeamTotalYellowCard?: Maybe<Scalars['Int']>;
  playID?: Maybe<Scalars['String']>;
  player?: Maybe<Player>;
  playerAccuratePasses?: Maybe<Scalars['Int']>;
  playerAerialDuelLost?: Maybe<Scalars['Int']>;
  playerAerialDuelWon?: Maybe<Scalars['Int']>;
  playerAssists?: Maybe<Scalars['Int']>;
  playerDuelsLost?: Maybe<Scalars['Int']>;
  playerDuelsWon?: Maybe<Scalars['Int']>;
  playerEffectiveClearance?: Maybe<Scalars['Int']>;
  playerFouledFinalThird?: Maybe<Scalars['Int']>;
  playerGoals?: Maybe<Scalars['Int']>;
  playerID?: Maybe<Scalars['String']>;
  playerInterception?: Maybe<Scalars['Int']>;
  playerPosition?: Maybe<PlayerPosition>;
  playerPunches?: Maybe<Scalars['Int']>;
  playerSaves?: Maybe<Scalars['Int']>;
  playerSavesInTheBox?: Maybe<Scalars['Int']>;
  playerShotsBlocked?: Maybe<Scalars['Int']>;
  playerShotsOffTarget?: Maybe<Scalars['Int']>;
  playerShotsOnTarget?: Maybe<Scalars['Int']>;
  playerSuccessfulFinalThirdPasses?: Maybe<Scalars['Int']>;
  playerTotalHighClaim?: Maybe<Scalars['Int']>;
  playerTotalPasses?: Maybe<Scalars['Int']>;
  playerTotalShots?: Maybe<Scalars['Int']>;
  timeline?: Maybe<Array<Maybe<Event>>>;
};

export type Player = {
  __typename?: 'Player';
  id?: Maybe<Scalars['String']>;
  position?: Maybe<PlayerPosition>;
  seasonStats?: Maybe<Array<Maybe<PlayerSeasonStats>>>;
};

export enum PlayerPosition {
  Defender = 'DEFENDER',
  Forward = 'FORWARD',
  Goalkeeper = 'GOALKEEPER',
  Midfielder = 'MIDFIELDER',
}

export type PlayerSeasonStats = {
  __typename?: 'PlayerSeasonStats';
  accuratePasses?: Maybe<Scalars['Int']>;
  aerialDuelsWon?: Maybe<Scalars['Int']>;
  assists?: Maybe<Scalars['Int']>;
  cleanSheets?: Maybe<Scalars['Int']>;
  duelsWon?: Maybe<Scalars['Int']>;
  failedDribbles?: Maybe<Scalars['Int']>;
  gamesPlayed?: Maybe<Scalars['Int']>;
  gamesStarted?: Maybe<Scalars['Int']>;
  goals?: Maybe<Scalars['Int']>;
  goalsConceded?: Maybe<Scalars['Int']>;
  groundDuelsWon?: Maybe<Scalars['Int']>;
  interceptions?: Maybe<Scalars['Int']>;
  keyPasses?: Maybe<Scalars['Int']>;
  penaltiesConceded?: Maybe<Scalars['Int']>;
  penaltiesSaved?: Maybe<Scalars['Int']>;
  playerID?: Maybe<Scalars['String']>;
  saves?: Maybe<Scalars['Int']>;
  season?: Maybe<Scalars['String']>;
  shotsOnTarget?: Maybe<Scalars['Int']>;
  successfulDribbles?: Maybe<Scalars['Int']>;
  totalAerialDuels?: Maybe<Scalars['Int']>;
  totalDuels?: Maybe<Scalars['Int']>;
  totalGroundDuels?: Maybe<Scalars['Int']>;
  totalPasses?: Maybe<Scalars['Int']>;
  totalShots?: Maybe<Scalars['Int']>;
  totalTimePlayed?: Maybe<Scalars['Int']>;
};

export enum PreferredCurrency {
  CurrNoop = 'CURR_NOOP',
  Dollars = 'DOLLARS',
  Euros = 'EUROS',
}

export enum PreferredLanguage {
  Eng = 'ENG',
  Esp = 'ESP',
  LangNoop = 'LANG_NOOP',
}

export type Price = {
  __typename?: 'Price';
  currency?: Maybe<Currency>;
  value?: Maybe<Scalars['PriceScalar']>;
};

export type PriceFilter = {
  amount?: InputMaybe<UInt64Filter>;
  currency?: InputMaybe<StringFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type PriceInput = {
  currency?: InputMaybe<Currency>;
  value?: InputMaybe<Scalars['PriceScalar']>;
};

export enum Project {
  AllDay = 'AllDay',
  AllDaySeasonal = 'AllDaySeasonal',
  Golazos = 'Golazos',
  Queue = 'Queue',
  TopShot = 'TopShot',
}

export type PromMetricResponse = {
  __typename?: 'PromMetricResponse';
  success: Scalars['Boolean'];
};

export type PromMetricsInput = {
  /**
   * Labels to apply to this metric sample. Labels should have low-cardinality, and there is a hard limit to the number of
   * distinct label/value key-pairs allowed per metric, defaults to 10.
   */
  labels?: InputMaybe<Array<PromMetricsLabel>>;
  /** Metric name */
  name: Scalars['String'];
  /** Value to use for metric operation (e.g. increment or decrement by) */
  value: Scalars['Float'];
};

export type PromMetricsLabel = {
  /** Name for this label (e.g. "path") */
  name: Scalars['String'];
  /** Value for this label (e.g. "/login") */
  value: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  fetchBatchStatus?: Maybe<FetchBatchStatusResponse>;
  getMyProfile: GetMyProfileResponse;
  getPlay?: Maybe<GetPlayResponse>;
  getPlayer?: Maybe<GetPlayerResponse>;
  getTransactionHashes?: Maybe<GetTransactionHashesResponse>;
  getUserActiveReservation: GetUserActiveReservationResponse;
  getUserActiveReservations?: Maybe<GetUserActiveReservationsResponse>;
  searchAllDayMarketplaceHistory?: Maybe<SearchAllDayMarketplaceHistoryResponse>;
  searchAllDayNft?: Maybe<SearchAllDayNftsResponse>;
  searchAllDayNftAggregation?: Maybe<SearchAllDayNftAggregationsResponse>;
  searchAllDaySeasonalMarketplaceHistory?: Maybe<SearchAllDaySeasonalMarketplaceHistoryResponse>;
  searchAllDaySeasonalNft?: Maybe<SearchAllDaySeasonalNftsResponse>;
  searchAllDaySeasonalNftAggregation?: Maybe<SearchAllDaySeasonalNftAggregationsResponse>;
  searchAllDayTeamMarketplaceHistory?: Maybe<SearchAllDayTeamMarketplaceHistoryResponse>;
  searchAllDayTeamNft?: Maybe<SearchAllDayTeamNftsResponse>;
  searchAllDayTeamNftAggregation?: Maybe<SearchAllDayTeamNftAggregationsResponse>;
  searchAthleteStudioMarketplaceHistory?: Maybe<SearchAthleteStudioMarketplaceHistoryResponse>;
  searchAthleteStudioNft?: Maybe<SearchAthleteStudioNftsResponse>;
  searchAthleteStudioNftAggregation?: Maybe<SearchAthleteStudioNftAggregationsResponse>;
  searchCollectionGroupMarketplaceHistory?: Maybe<SearchCollectionGroupMarketplaceHistoryResponse>;
  searchCollectionGroupNft?: Maybe<SearchCollectionGroupNftsResponse>;
  searchCollectionGroupNftAggregation?: Maybe<SearchCollectionGroupNftAggregationsResponse>;
  searchCollectionGroups?: Maybe<SearchCollectionGroupsResponse>;
  searchDistributions?: Maybe<SearchDistributionsResponse>;
  searchGolazosMarketplaceHistory?: Maybe<SearchGolazosMarketplaceHistoryResponse>;
  searchGolazosNft?: Maybe<SearchGolazosNftsResponse>;
  searchGolazosNftAggregation?: Maybe<SearchGolazosNftAggregationsResponse>;
  searchNfts?: Maybe<SearchNfTsResponse>;
  searchPackMarketplaceHistory?: Maybe<SearchPackMarketplaceHistoryResponse>;
  searchPackNft?: Maybe<SearchPackNftsResponse>;
  searchPackNftAggregation?: Maybe<SearchPackNftAggregationsResponse>;
  searchSeedsOfHappinessMarketplaceHistory?: Maybe<SearchSeedsOfHappinessMarketplaceHistoryResponse>;
  searchSeedsOfHappinessNft?: Maybe<SearchSeedsOfHappinessNftsResponse>;
  searchSeedsOfHappinessNftAggregation?: Maybe<SearchSeedsOfHappinessNftAggregationsResponse>;
  searchTopShotVipMarketplaceHistory?: Maybe<SearchTopShotVipMarketplaceHistoryResponse>;
  searchTopShotVipNft?: Maybe<SearchTopShotVipNftsResponse>;
  searchTopShotVipNftAggregation?: Maybe<SearchTopShotVipNftAggregationsResponse>;
  searchUFCMarketplaceHistory?: Maybe<SearchUfcMarketplaceHistoryResponse>;
  searchUFCNft?: Maybe<SearchUfcNftsResponse>;
  searchUFCNftAggregation?: Maybe<SearchUfcNftAggregationsResponse>;
  searchUsers: SearchUsersResponse;
  validateCustomerSupportPackNFTReservation: ValidateCustomerSupportPackNftReservationResponse;
  validateCustomerSupportReservation: ValidateCustomerSupportReservationResponse;
};

export type QueryFetchBatchStatusArgs = {
  input: FetchBatchStatusInput;
};

export type QueryGetPlayArgs = {
  input: GetPlayInput;
};

export type QueryGetPlayerArgs = {
  input: GetPlayerInput;
};

export type QueryGetTransactionHashesArgs = {
  input: GetTransactionHashesInput;
};

export type QueryGetUserActiveReservationArgs = {
  input?: InputMaybe<GetUserActiveReservationInput>;
};

export type QueryGetUserActiveReservationsArgs = {
  input: GetUserActiveReservationsInput;
};

export type QuerySearchAllDayMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchAllDayMarketplaceHistoryInput>;
};

export type QuerySearchAllDayNftArgs = {
  searchInput?: InputMaybe<SearchAllDayNftsInput>;
};

export type QuerySearchAllDayNftAggregationArgs = {
  searchInput?: InputMaybe<SearchAllDayNftAggregationsInput>;
};

export type QuerySearchAllDaySeasonalMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchAllDaySeasonalMarketplaceHistoryInput>;
};

export type QuerySearchAllDaySeasonalNftArgs = {
  searchInput?: InputMaybe<SearchAllDaySeasonalNftsInput>;
};

export type QuerySearchAllDaySeasonalNftAggregationArgs = {
  searchInput?: InputMaybe<SearchAllDaySeasonalNftAggregationsInput>;
};

export type QuerySearchAllDayTeamMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchAllDayTeamMarketplaceHistoryInput>;
};

export type QuerySearchAllDayTeamNftArgs = {
  searchInput?: InputMaybe<SearchAllDayTeamNftsInput>;
};

export type QuerySearchAllDayTeamNftAggregationArgs = {
  searchInput?: InputMaybe<SearchAllDayTeamNftAggregationsInput>;
};

export type QuerySearchAthleteStudioMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchAthleteStudioMarketplaceHistoryInput>;
};

export type QuerySearchAthleteStudioNftArgs = {
  searchInput?: InputMaybe<SearchAthleteStudioNftsInput>;
};

export type QuerySearchAthleteStudioNftAggregationArgs = {
  searchInput?: InputMaybe<SearchAthleteStudioNftAggregationsInput>;
};

export type QuerySearchCollectionGroupMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchCollectionGroupMarketplaceHistoryInput>;
};

export type QuerySearchCollectionGroupNftArgs = {
  searchInput?: InputMaybe<SearchCollectionGroupNftsInput>;
};

export type QuerySearchCollectionGroupNftAggregationArgs = {
  searchInput?: InputMaybe<SearchCollectionGroupNftAggregationsInput>;
};

export type QuerySearchCollectionGroupsArgs = {
  searchInput?: InputMaybe<SearchCollectionGroupsInput>;
};

export type QuerySearchDistributionsArgs = {
  input?: InputMaybe<SearchDistributionsInput>;
};

export type QuerySearchGolazosMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchGolazosMarketplaceHistoryInput>;
};

export type QuerySearchGolazosNftArgs = {
  searchInput?: InputMaybe<SearchGolazosNftsInput>;
};

export type QuerySearchGolazosNftAggregationArgs = {
  searchInput?: InputMaybe<SearchGolazosNftAggregationsInput>;
};

export type QuerySearchNftsArgs = {
  searchInput?: InputMaybe<SearchNfTsInput>;
};

export type QuerySearchPackMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchPackMarketplaceHistoryInput>;
};

export type QuerySearchPackNftArgs = {
  searchInput?: InputMaybe<SearchPackNftsInput>;
};

export type QuerySearchPackNftAggregationArgs = {
  searchInput?: InputMaybe<SearchPackNftAggregationsInput>;
};

export type QuerySearchSeedsOfHappinessMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchSeedsOfHappinessMarketplaceHistoryInput>;
};

export type QuerySearchSeedsOfHappinessNftArgs = {
  searchInput?: InputMaybe<SearchSeedsOfHappinessNftsInput>;
};

export type QuerySearchSeedsOfHappinessNftAggregationArgs = {
  searchInput?: InputMaybe<SearchSeedsOfHappinessNftAggregationsInput>;
};

export type QuerySearchTopShotVipMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchTopShotVipMarketplaceHistoryInput>;
};

export type QuerySearchTopShotVipNftArgs = {
  searchInput?: InputMaybe<SearchTopShotVipNftsInput>;
};

export type QuerySearchTopShotVipNftAggregationArgs = {
  searchInput?: InputMaybe<SearchTopShotVipNftAggregationsInput>;
};

export type QuerySearchUfcMarketplaceHistoryArgs = {
  searchInput?: InputMaybe<SearchUfcMarketplaceHistoryInput>;
};

export type QuerySearchUfcNftArgs = {
  searchInput?: InputMaybe<SearchUfcNftsInput>;
};

export type QuerySearchUfcNftAggregationArgs = {
  searchInput?: InputMaybe<SearchUfcNftAggregationsInput>;
};

export type QuerySearchUsersArgs = {
  input: SearchUsersRequest;
};

export type QueryValidateCustomerSupportPackNftReservationArgs = {
  input: ValidateCustomerSupportPackNftReservationInput;
};

export type QueryValidateCustomerSupportReservationArgs = {
  input?: InputMaybe<ValidateCustomerSupportReservationInput>;
};

export type QueueEntry = {
  __typename?: 'QueueEntry';
  entryID: Scalars['ID'];
  queueID: Scalars['String'];
  tiers?: Maybe<Array<Maybe<EntryTier>>>;
  userID: Scalars['String'];
};

export type ReleaseQueueBatchInput = {
  outflow: Scalars['Int'];
  queueId: Scalars['String'];
  userQueues: Array<UserQueue>;
};

export type ReleaseQueueBatchResponse = {
  __typename?: 'ReleaseQueueBatchResponse';
  /** The number of entries that were actually accepted in this batch */
  acceptedEntries: Scalars['Int'];
  /** The number of pendingSupply reservations after entries were accepted */
  pendingReservations: Scalars['Int'];
  /** The remaining supply, excluding accepted entries and completed / pendingSupply reservations */
  remainingSupply: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type Reservation = {
  __typename?: 'Reservation';
  distribution_id: Scalars['Int'];
  expired_at: Scalars['Time'];
  listing_resource_id: Scalars['Int'];
  price: Scalars['String'];
  user_id: Scalars['String'];
};

export type ReservePackNfTsForCustomerSupportInput = {
  count: Scalars['Int'];
  distributionID: Scalars['Int'];
};

export type ReservePackNfTsForCustomerSupportResponse = {
  __typename?: 'ReservePackNFTsForCustomerSupportResponse';
  packNFTIDs?: Maybe<Array<Maybe<Scalars['Int']>>>;
  success?: Maybe<Scalars['Boolean']>;
};

export type SearchAllDayMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AllDayMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchAllDayMarketplaceHistoryResponse = {
  __typename?: 'SearchAllDayMarketplaceHistoryResponse';
  edges?: Maybe<Array<AllDayMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAllDayNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AllDayNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AllDayNftSortAggregation>;
};

export type SearchAllDayNftAggregationsResponse = {
  __typename?: 'SearchAllDayNftAggregationsResponse';
  edges?: Maybe<Array<AllDayNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAllDayNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AllDayNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AllDayNftSort>;
};

export type SearchAllDayNftsResponse = {
  __typename?: 'SearchAllDayNftsResponse';
  edges?: Maybe<Array<AllDayNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAllDaySeasonalMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AllDaySeasonalMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchAllDaySeasonalMarketplaceHistoryResponse = {
  __typename?: 'SearchAllDaySeasonalMarketplaceHistoryResponse';
  edges?: Maybe<Array<AllDaySeasonalMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAllDaySeasonalNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AllDaySeasonalNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AllDaySeasonalNftSortAggregation>;
};

export type SearchAllDaySeasonalNftAggregationsResponse = {
  __typename?: 'SearchAllDaySeasonalNftAggregationsResponse';
  edges?: Maybe<Array<AllDaySeasonalNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAllDaySeasonalNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AllDaySeasonalNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AllDaySeasonalNftSort>;
};

export type SearchAllDaySeasonalNftsResponse = {
  __typename?: 'SearchAllDaySeasonalNftsResponse';
  edges?: Maybe<Array<AllDaySeasonalNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAllDayTeamMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AllDayTeamMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchAllDayTeamMarketplaceHistoryResponse = {
  __typename?: 'SearchAllDayTeamMarketplaceHistoryResponse';
  edges?: Maybe<Array<AllDayTeamMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAllDayTeamNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AllDayTeamNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AllDayTeamNftSortAggregation>;
};

export type SearchAllDayTeamNftAggregationsResponse = {
  __typename?: 'SearchAllDayTeamNftAggregationsResponse';
  edges?: Maybe<Array<AllDayTeamNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAllDayTeamNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AllDayTeamNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AllDayTeamNftSort>;
};

export type SearchAllDayTeamNftsResponse = {
  __typename?: 'SearchAllDayTeamNftsResponse';
  edges?: Maybe<Array<AllDayTeamNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAthleteStudioMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AthleteStudioMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchAthleteStudioMarketplaceHistoryResponse = {
  __typename?: 'SearchAthleteStudioMarketplaceHistoryResponse';
  edges?: Maybe<Array<AthleteStudioMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAthleteStudioNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AthleteStudioNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AthleteStudioNftSortAggregation>;
};

export type SearchAthleteStudioNftAggregationsResponse = {
  __typename?: 'SearchAthleteStudioNftAggregationsResponse';
  edges?: Maybe<Array<AthleteStudioNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchAthleteStudioNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<AthleteStudioNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<AthleteStudioNftSort>;
};

export type SearchAthleteStudioNftsResponse = {
  __typename?: 'SearchAthleteStudioNftsResponse';
  edges?: Maybe<Array<AthleteStudioNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchCollectionGroupMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<CollectionGroupMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchCollectionGroupMarketplaceHistoryResponse = {
  __typename?: 'SearchCollectionGroupMarketplaceHistoryResponse';
  edges?: Maybe<Array<CollectionGroupMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchCollectionGroupNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<CollectionGroupNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CollectionGroupNftSortAggregation>;
};

export type SearchCollectionGroupNftAggregationsResponse = {
  __typename?: 'SearchCollectionGroupNftAggregationsResponse';
  edges?: Maybe<Array<CollectionGroupNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchCollectionGroupNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<CollectionGroupNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CollectionGroupNftSort>;
};

export type SearchCollectionGroupNftsResponse = {
  __typename?: 'SearchCollectionGroupNftsResponse';
  edges?: Maybe<Array<CollectionGroupNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchCollectionGroupsFilter = {
  collection_group_id?: InputMaybe<StringFilter>;
  completed?: InputMaybe<Scalars['Boolean']>;
  end_time?: InputMaybe<TimeFilter>;
  project?: InputMaybe<StringFilter>;
};

export type SearchCollectionGroupsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<SearchCollectionGroupsFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<SearchCollectionGroupsSort>;
  userFlowAddress?: InputMaybe<Scalars['String']>;
};

export type SearchCollectionGroupsResponse = {
  __typename?: 'SearchCollectionGroupsResponse';
  edges?: Maybe<Array<CollectionGroupEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchCollectionGroupsSort = {
  created_at?: InputMaybe<Sort>;
};

export type SearchDistributionsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<DistributionFilters>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<DistributionSortType>;
};

export type SearchDistributionsResponse = {
  __typename?: 'SearchDistributionsResponse';
  edges?: Maybe<Array<Maybe<DistributionEdge>>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchGolazosMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<GolazosMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchGolazosMarketplaceHistoryResponse = {
  __typename?: 'SearchGolazosMarketplaceHistoryResponse';
  edges?: Maybe<Array<GolazosMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchGolazosNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<GolazosNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<GolazosNftSortAggregation>;
};

export type SearchGolazosNftAggregationsResponse = {
  __typename?: 'SearchGolazosNftAggregationsResponse';
  edges?: Maybe<Array<GolazosNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchGolazosNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<GolazosNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<GolazosNftSort>;
};

export type SearchGolazosNftsResponse = {
  __typename?: 'SearchGolazosNftsResponse';
  edges?: Maybe<Array<GolazosNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchNfTsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<NftFilters>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<Array<NftSort>>;
};

export type SearchNfTsResponse = {
  __typename?: 'SearchNFTsResponse';
  edges?: Maybe<Array<NftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchPackMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<PackMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchPackMarketplaceHistoryResponse = {
  __typename?: 'SearchPackMarketplaceHistoryResponse';
  edges?: Maybe<Array<PackMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchPackNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<PackNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PackNftSortAggregation>;
};

export type SearchPackNftAggregationsResponse = {
  __typename?: 'SearchPackNftAggregationsResponse';
  edges?: Maybe<Array<PackNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchPackNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<PackNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<PackNftSort>;
};

export type SearchPackNftsResponse = {
  __typename?: 'SearchPackNftsResponse';
  edges?: Maybe<Array<PackNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchSeedsOfHappinessMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<SeedsOfHappinessMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchSeedsOfHappinessMarketplaceHistoryResponse = {
  __typename?: 'SearchSeedsOfHappinessMarketplaceHistoryResponse';
  edges?: Maybe<Array<SeedsOfHappinessMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchSeedsOfHappinessNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<SeedsOfHappinessNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SeedsOfHappinessNftSortAggregation>;
};

export type SearchSeedsOfHappinessNftAggregationsResponse = {
  __typename?: 'SearchSeedsOfHappinessNftAggregationsResponse';
  edges?: Maybe<Array<SeedsOfHappinessNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchSeedsOfHappinessNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<SeedsOfHappinessNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<SeedsOfHappinessNftSort>;
};

export type SearchSeedsOfHappinessNftsResponse = {
  __typename?: 'SearchSeedsOfHappinessNftsResponse';
  edges?: Maybe<Array<SeedsOfHappinessNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchTopShotVipMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<TopShotVipMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchTopShotVipMarketplaceHistoryResponse = {
  __typename?: 'SearchTopShotVipMarketplaceHistoryResponse';
  edges?: Maybe<Array<TopShotVipMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchTopShotVipNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<TopShotVipNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<TopShotVipNftSortAggregation>;
};

export type SearchTopShotVipNftAggregationsResponse = {
  __typename?: 'SearchTopShotVipNftAggregationsResponse';
  edges?: Maybe<Array<TopShotVipNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchTopShotVipNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<TopShotVipNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<TopShotVipNftSort>;
};

export type SearchTopShotVipNftsResponse = {
  __typename?: 'SearchTopShotVipNftsResponse';
  edges?: Maybe<Array<TopShotVipNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchUfcMarketplaceHistoryInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<UfcMarketplaceHistoryFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MarketplaceHistorySort>;
};

export type SearchUfcMarketplaceHistoryResponse = {
  __typename?: 'SearchUFCMarketplaceHistoryResponse';
  edges?: Maybe<Array<UfcMarketplaceTransactionEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchUfcNftAggregationsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<UfcNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<UfcNftSortAggregation>;
};

export type SearchUfcNftAggregationsResponse = {
  __typename?: 'SearchUFCNftAggregationsResponse';
  edges?: Maybe<Array<UfcNftAggregationEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchUfcNftsInput = {
  after?: InputMaybe<Scalars['String']>;
  filters?: InputMaybe<Array<UfcNftFilter>>;
  first?: InputMaybe<Scalars['Int']>;
  query?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<UfcNftSort>;
};

export type SearchUfcNftsResponse = {
  __typename?: 'SearchUFCNftsResponse';
  edges?: Maybe<Array<UfcNftEdge>>;
  pageInfo?: Maybe<PageInfo>;
  totalCount?: Maybe<Scalars['Int']>;
};

export type SearchUsersRequest = {
  flowAddress?: InputMaybe<Scalars['String']>;
  username?: InputMaybe<Scalars['String']>;
};

export type SearchUsersResponse = {
  __typename?: 'SearchUsersResponse';
  users?: Maybe<Array<Maybe<UserProfileWithoutPii>>>;
};

export type SeedsOfHappinessMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
};

export type SeedsOfHappinessMarketplaceTransaction = {
  __typename?: 'SeedsOfHappinessMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<SeedsOfHappinessNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type SeedsOfHappinessMarketplaceTransactionEdge = {
  __typename?: 'SeedsOfHappinessMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SeedsOfHappinessMarketplaceTransaction>;
};

export type SeedsOfHappinessNft = {
  __typename?: 'SeedsOfHappinessNft';
  burned_at?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  hash?: Maybe<Array<Maybe<Scalars['UInt8']>>>;
  id?: Maybe<Scalars['UInt64']>;
  listing?: Maybe<Listing>;
  metadata?: Maybe<SeedsOfHappinessNftMetadata>;
  metadata_updated_at?: Maybe<BlockInfo>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type SeedsOfHappinessNftAggregation = {
  __typename?: 'SeedsOfHappinessNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UInt64Aggregation>;
  listing?: Maybe<ListingAggregation>;
  metadata?: Maybe<SeedsOfHappinessNftMetadataAggregation>;
  metadata_updated_at?: Maybe<BlockInfoAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type SeedsOfHappinessNftAggregationEdge = {
  __typename?: 'SeedsOfHappinessNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SeedsOfHappinessNftAggregation>;
};

export type SeedsOfHappinessNftEdge = {
  __typename?: 'SeedsOfHappinessNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<SeedsOfHappinessNft>;
};

export type SeedsOfHappinessNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  hash?: InputMaybe<UInt8ListFilter>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  metadata?: InputMaybe<SeedsOfHappinessNftMetadataFilter>;
  metadata_updated_at?: InputMaybe<BlockInfoFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type SeedsOfHappinessNftMetadata = {
  __typename?: 'SeedsOfHappinessNftMetadata';
  birthmark?: Maybe<Scalars['String']>;
  color?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  emboss?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  outline?: Maybe<Scalars['String']>;
  redeemed?: Maybe<Scalars['String']>;
  salt?: Maybe<Array<Maybe<Scalars['UInt8']>>>;
  serial_number?: Maybe<Scalars['UInt64']>;
  shape?: Maybe<Scalars['String']>;
  smile?: Maybe<Scalars['String']>;
};

export type SeedsOfHappinessNftMetadataAggregation = {
  __typename?: 'SeedsOfHappinessNftMetadataAggregation';
  birthmark?: Maybe<StringAggregation>;
  color?: Maybe<StringAggregation>;
  description?: Maybe<StringAggregation>;
  emboss?: Maybe<StringAggregation>;
  image?: Maybe<StringAggregation>;
  name?: Maybe<StringAggregation>;
  outline?: Maybe<StringAggregation>;
  redeemed?: Maybe<StringAggregation>;
  serial_number?: Maybe<UInt64Aggregation>;
  shape?: Maybe<StringAggregation>;
  smile?: Maybe<StringAggregation>;
};

export type SeedsOfHappinessNftMetadataFilter = {
  birthmark?: InputMaybe<StringFilter>;
  color?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  emboss?: InputMaybe<StringFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  image?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  outline?: InputMaybe<StringFilter>;
  redeemed?: InputMaybe<StringFilter>;
  salt?: InputMaybe<UInt8ListFilter>;
  serial_number?: InputMaybe<UInt64Filter>;
  shape?: InputMaybe<StringFilter>;
  smile?: InputMaybe<StringFilter>;
};

export type SeedsOfHappinessNftMetadataSort = {
  birthmark?: InputMaybe<SortString>;
  color?: InputMaybe<SortString>;
  description?: InputMaybe<SortString>;
  emboss?: InputMaybe<SortString>;
  image?: InputMaybe<SortString>;
  name?: InputMaybe<SortString>;
  outline?: InputMaybe<SortString>;
  redeemed?: InputMaybe<SortString>;
  serial_number?: InputMaybe<Sort>;
  shape?: InputMaybe<SortString>;
  smile?: InputMaybe<SortString>;
};

export type SeedsOfHappinessNftMetadataSortAggregation = {
  birthmark?: InputMaybe<SortStringKey>;
  color?: InputMaybe<SortStringKey>;
  description?: InputMaybe<SortStringKey>;
  emboss?: InputMaybe<SortStringKey>;
  image?: InputMaybe<SortStringKey>;
  name?: InputMaybe<SortStringKey>;
  outline?: InputMaybe<SortStringKey>;
  redeemed?: InputMaybe<SortStringKey>;
  serial_number?: InputMaybe<SortAggregation>;
  shape?: InputMaybe<SortStringKey>;
  smile?: InputMaybe<SortStringKey>;
};

export type SeedsOfHappinessNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  metadata?: InputMaybe<SeedsOfHappinessNftMetadataSort>;
  metadata_updated_at?: InputMaybe<BlockInfoSort>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type SeedsOfHappinessNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  metadata?: InputMaybe<SeedsOfHappinessNftMetadataSortAggregation>;
  metadata_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export enum SignableType {
  MarketplacePacks = 'MARKETPLACE_PACKS',
  Repack = 'REPACK',
}

export type Sort = {
  direction: SortDirection;
  missing?: InputMaybe<SortMissing>;
  priority: Scalars['Int'];
};

export type SortAggregation = {
  avg?: InputMaybe<Sort>;
  count?: InputMaybe<Sort>;
  key?: InputMaybe<Sort>;
  max?: InputMaybe<Sort>;
  min?: InputMaybe<Sort>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type SortKey = {
  key: Sort;
};

export enum SortMissing {
  First = 'FIRST',
  Last = 'LAST',
}

export type SortString = {
  direction: SortDirection;
  ignore_case?: InputMaybe<Scalars['Boolean']>;
  missing?: InputMaybe<SortMissing>;
  priority: Scalars['Int'];
};

export type SortStringKey = {
  key: SortString;
};

export type SportMomentsNftSelection = {
  count: Scalars['Int'];
  editionID: Scalars['Int'];
  serialNumEnd: Scalars['Int'];
  serialNumStart: Scalars['Int'];
};

export type SportMomentsPackNftSlot = {
  SportMomentsNftSelections: Array<SportMomentsNftSelection>;
};

export type SportMomentsPackNftTemplate = {
  packNftCount: Scalars['Int'];
  packNftSlots?: InputMaybe<Array<SportMomentsPackNftSlot>>;
};

export type StatsInfoAggregation = {
  __typename?: 'StatsInfoAggregation';
  value?: Maybe<Scalars['UInt64']>;
};

export type StringAggregation = {
  __typename?: 'StringAggregation';
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['String']>;
  lower_case_key?: Maybe<Scalars['String']>;
  lower_case_value?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type StringFilter = {
  eq?: InputMaybe<Scalars['String']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  ignore_case?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<Scalars['String']>>;
  ne?: InputMaybe<Scalars['String']>;
};

export type StringListFilter = {
  contains?: InputMaybe<Array<Scalars['String']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type TimeAggregation = {
  __typename?: 'TimeAggregation';
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Time']>;
  value?: Maybe<Scalars['Time']>;
};

export type TimeFilter = {
  after?: InputMaybe<Scalars['Time']>;
  before?: InputMaybe<Scalars['Time']>;
  eq?: InputMaybe<Scalars['Time']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  in?: InputMaybe<Array<Scalars['Time']>>;
  ne?: InputMaybe<Scalars['Time']>;
};

export type TimeListFilter = {
  contains?: InputMaybe<Array<Scalars['Time']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type TimeRange = {
  timeRangeEnd?: InputMaybe<Scalars['Time']>;
  timeRangeStart?: InputMaybe<Scalars['Time']>;
};

export type TopShotVipMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
  set_id?: InputMaybe<UIntFilter>;
};

export type TopShotVipMarketplaceTransaction = {
  __typename?: 'TopShotVipMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<TopShotVipNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type TopShotVipMarketplaceTransactionEdge = {
  __typename?: 'TopShotVipMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<TopShotVipMarketplaceTransaction>;
};

export type TopShotVipNft = {
  __typename?: 'TopShotVipNft';
  burned_at?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  edition_num?: Maybe<Scalars['UInt']>;
  id?: Maybe<Scalars['UInt64']>;
  listing?: Maybe<Listing>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  set?: Maybe<TopShotVipSet>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type TopShotVipNftAggregation = {
  __typename?: 'TopShotVipNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  edition_num?: Maybe<UIntAggregation>;
  id?: Maybe<UInt64Aggregation>;
  listing?: Maybe<ListingAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  set?: Maybe<TopShotVipSetAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type TopShotVipNftAggregationEdge = {
  __typename?: 'TopShotVipNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<TopShotVipNftAggregation>;
};

export type TopShotVipNftEdge = {
  __typename?: 'TopShotVipNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<TopShotVipNft>;
};

export type TopShotVipNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  edition_num?: InputMaybe<UIntFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  set?: InputMaybe<TopShotVipSetFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type TopShotVipNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  edition_num?: InputMaybe<Sort>;
  id?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  set?: InputMaybe<TopShotVipSetSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type TopShotVipNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  edition_num?: InputMaybe<SortAggregation>;
  id?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  set?: InputMaybe<TopShotVipSetSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type TopShotVipSeries = {
  __typename?: 'TopShotVipSeries';
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt']>;
  metadata?: Maybe<TopShotVipSeriesMetadata>;
  metadata_last_updated_at?: Maybe<BlockInfo>;
  sealed?: Maybe<Scalars['Boolean']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type TopShotVipSeriesAggregation = {
  __typename?: 'TopShotVipSeriesAggregation';
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UIntAggregation>;
  metadata?: Maybe<TopShotVipSeriesMetadataAggregation>;
  metadata_last_updated_at?: Maybe<BlockInfoAggregation>;
  sealed?: Maybe<BooleanAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type TopShotVipSeriesFilter = {
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UIntFilter>;
  metadata?: InputMaybe<TopShotVipSeriesMetadataFilter>;
  metadata_last_updated_at?: InputMaybe<BlockInfoFilter>;
  sealed?: InputMaybe<BooleanFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type TopShotVipSeriesMetadata = {
  __typename?: 'TopShotVipSeriesMetadata';
  series_description?: Maybe<Scalars['String']>;
  series_name?: Maybe<Scalars['String']>;
};

export type TopShotVipSeriesMetadataAggregation = {
  __typename?: 'TopShotVipSeriesMetadataAggregation';
  series_description?: Maybe<StringAggregation>;
  series_name?: Maybe<StringAggregation>;
};

export type TopShotVipSeriesMetadataFilter = {
  exists?: InputMaybe<Scalars['Boolean']>;
  series_description?: InputMaybe<StringFilter>;
  series_name?: InputMaybe<StringFilter>;
};

export type TopShotVipSeriesMetadataSort = {
  series_description?: InputMaybe<SortString>;
  series_name?: InputMaybe<SortString>;
};

export type TopShotVipSeriesMetadataSortAggregation = {
  series_description?: InputMaybe<SortStringKey>;
  series_name?: InputMaybe<SortStringKey>;
};

export type TopShotVipSeriesSort = {
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  metadata?: InputMaybe<TopShotVipSeriesMetadataSort>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSort>;
  sealed?: InputMaybe<Sort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type TopShotVipSeriesSortAggregation = {
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<TopShotVipSeriesMetadataSortAggregation>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  sealed?: InputMaybe<SortKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type TopShotVipSet = {
  __typename?: 'TopShotVipSet';
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt']>;
  max_mintings?: Maybe<Scalars['UInt64']>;
  metadata?: Maybe<TopShotVipSetMetadata>;
  metadata_last_updated_at?: Maybe<BlockInfo>;
  series?: Maybe<TopShotVipSeries>;
  total_burned?: Maybe<Scalars['UInt64']>;
  total_minted?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type TopShotVipSetAggregation = {
  __typename?: 'TopShotVipSetAggregation';
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UIntAggregation>;
  max_mintings?: Maybe<UInt64Aggregation>;
  metadata?: Maybe<TopShotVipSetMetadataAggregation>;
  metadata_last_updated_at?: Maybe<BlockInfoAggregation>;
  series?: Maybe<TopShotVipSeriesAggregation>;
  total_burned?: Maybe<StatsInfoAggregation>;
  total_minted?: Maybe<StatsInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type TopShotVipSetFilter = {
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UIntFilter>;
  max_mintings?: InputMaybe<UInt64Filter>;
  metadata?: InputMaybe<TopShotVipSetMetadataFilter>;
  metadata_last_updated_at?: InputMaybe<BlockInfoFilter>;
  series?: InputMaybe<TopShotVipSeriesFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type TopShotVipSetMetadata = {
  __typename?: 'TopShotVipSetMetadata';
  additional_images?: Maybe<Array<Maybe<Scalars['String']>>>;
  creator_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  external_url?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_file_type?: Maybe<Scalars['String']>;
  ipfs_image_hash?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  sha256_image_hash?: Maybe<Scalars['String']>;
  team_id?: Maybe<Scalars['UInt64']>;
};

export type TopShotVipSetMetadataAggregation = {
  __typename?: 'TopShotVipSetMetadataAggregation';
  creator_name?: Maybe<StringAggregation>;
  description?: Maybe<StringAggregation>;
  external_url?: Maybe<StringAggregation>;
  image?: Maybe<StringAggregation>;
  image_file_type?: Maybe<StringAggregation>;
  ipfs_image_hash?: Maybe<StringAggregation>;
  name?: Maybe<StringAggregation>;
  preview?: Maybe<StringAggregation>;
  sha256_image_hash?: Maybe<StringAggregation>;
  team_id?: Maybe<UInt64Aggregation>;
};

export type TopShotVipSetMetadataFilter = {
  additional_images?: InputMaybe<StringListFilter>;
  creator_name?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  external_url?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringFilter>;
  image_file_type?: InputMaybe<StringFilter>;
  ipfs_image_hash?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  preview?: InputMaybe<StringFilter>;
  sha256_image_hash?: InputMaybe<StringFilter>;
  team_id?: InputMaybe<UInt64Filter>;
};

export type TopShotVipSetMetadataSort = {
  creator_name?: InputMaybe<SortString>;
  description?: InputMaybe<SortString>;
  external_url?: InputMaybe<SortString>;
  image?: InputMaybe<SortString>;
  image_file_type?: InputMaybe<SortString>;
  ipfs_image_hash?: InputMaybe<SortString>;
  name?: InputMaybe<SortString>;
  preview?: InputMaybe<SortString>;
  sha256_image_hash?: InputMaybe<SortString>;
  team_id?: InputMaybe<Sort>;
};

export type TopShotVipSetMetadataSortAggregation = {
  creator_name?: InputMaybe<SortStringKey>;
  description?: InputMaybe<SortStringKey>;
  external_url?: InputMaybe<SortStringKey>;
  image?: InputMaybe<SortStringKey>;
  image_file_type?: InputMaybe<SortStringKey>;
  ipfs_image_hash?: InputMaybe<SortStringKey>;
  name?: InputMaybe<SortStringKey>;
  preview?: InputMaybe<SortStringKey>;
  sha256_image_hash?: InputMaybe<SortStringKey>;
  team_id?: InputMaybe<SortAggregation>;
};

export type TopShotVipSetSort = {
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  max_mintings?: InputMaybe<Sort>;
  metadata?: InputMaybe<TopShotVipSetMetadataSort>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSort>;
  series?: InputMaybe<TopShotVipSeriesSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type TopShotVipSetSortAggregation = {
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  max_mintings?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<TopShotVipSetMetadataSortAggregation>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  series?: InputMaybe<TopShotVipSeriesSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type TransferAllDayNftsInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  nftIDs: Array<Scalars['UInt64']>;
  recipientAddress: Scalars['String'];
};

export type TransferAllDayNftsResponse = {
  __typename?: 'TransferAllDayNftsResponse';
  batchKey: Scalars['String'];
};

export type TransferPackNftsInput = {
  callbackURL?: InputMaybe<Scalars['String']>;
  nftIDs: Array<Scalars['UInt64']>;
  recipientsAddresses: Array<Scalars['String']>;
};

export type TransferPackNftsResponse = {
  __typename?: 'TransferPackNftsResponse';
  batchKey: Scalars['String'];
};

export enum TypeFilterType {
  Exclude = 'EXCLUDE',
  Include = 'INCLUDE',
}

export type UfcMarketplaceHistoryFilter = {
  base_filter?: InputMaybe<MarketplaceHistoryFilter>;
  set_id?: InputMaybe<UIntFilter>;
};

export type UfcMarketplaceTransaction = {
  __typename?: 'UFCMarketplaceTransaction';
  commission_amount?: Maybe<Scalars['UInt64']>;
  commission_receiver?: Maybe<Scalars['String']>;
  commission_receivers?: Maybe<Array<Maybe<Scalars['String']>>>;
  created_at?: Maybe<BlockInfo>;
  custom_id?: Maybe<Scalars['String']>;
  expiry?: Maybe<Scalars['UInt64']>;
  ft_vault_type?: Maybe<Scalars['String']>;
  listing_resource_id?: Maybe<Scalars['UInt64']>;
  nft?: Maybe<UfcNft>;
  nft_id?: Maybe<Scalars['UInt64']>;
  nft_type?: Maybe<Scalars['String']>;
  nft_uuid?: Maybe<Scalars['UInt64']>;
  price?: Maybe<Scalars['UInt64']>;
  purchased?: Maybe<Scalars['Boolean']>;
  receiver?: Maybe<UserDetails>;
  receiver_address?: Maybe<Scalars['String']>;
  sale_payment_vault_type?: Maybe<Scalars['String']>;
  sales_price?: Maybe<Scalars['UInt64']>;
  seller?: Maybe<UserDetails>;
  storefront_address?: Maybe<Scalars['String']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type UfcMarketplaceTransactionEdge = {
  __typename?: 'UFCMarketplaceTransactionEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<UfcMarketplaceTransaction>;
};

export type UfcNft = {
  __typename?: 'UFCNft';
  burned_at?: Maybe<BlockInfo>;
  created_at?: Maybe<BlockInfo>;
  edition_num?: Maybe<Scalars['UInt']>;
  id?: Maybe<Scalars['UInt64']>;
  listing?: Maybe<Listing>;
  owner_address?: Maybe<Scalars['String']>;
  owner_updated_at?: Maybe<BlockInfo>;
  set?: Maybe<UfcSet>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
  user?: Maybe<UserDetails>;
};

export type UfcNftAggregation = {
  __typename?: 'UFCNftAggregation';
  burned_at?: Maybe<BlockInfoAggregation>;
  created_at?: Maybe<BlockInfoAggregation>;
  edition_num?: Maybe<UIntAggregation>;
  id?: Maybe<UInt64Aggregation>;
  listing?: Maybe<ListingAggregation>;
  owner_address?: Maybe<StringAggregation>;
  owner_updated_at?: Maybe<BlockInfoAggregation>;
  set?: Maybe<UfcSetAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type UfcNftAggregationEdge = {
  __typename?: 'UFCNftAggregationEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<UfcNftAggregation>;
};

export type UfcNftEdge = {
  __typename?: 'UFCNftEdge';
  cursor?: Maybe<Scalars['String']>;
  node?: Maybe<UfcNft>;
};

export type UfcNftFilter = {
  burned_at?: InputMaybe<BlockInfoFilter>;
  created_at?: InputMaybe<BlockInfoFilter>;
  edition_num?: InputMaybe<UIntFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UInt64Filter>;
  listing?: InputMaybe<ListingFilter>;
  owner_address?: InputMaybe<StringFilter>;
  owner_updated_at?: InputMaybe<BlockInfoFilter>;
  set?: InputMaybe<UfcSetFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type UfcNftSort = {
  burned_at?: InputMaybe<BlockInfoSort>;
  created_at?: InputMaybe<BlockInfoSort>;
  edition_num?: InputMaybe<Sort>;
  id?: InputMaybe<Sort>;
  listing?: InputMaybe<ListingSort>;
  owner_address?: InputMaybe<SortString>;
  owner_updated_at?: InputMaybe<BlockInfoSort>;
  set?: InputMaybe<UfcSetSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type UfcNftSortAggregation = {
  burned_at?: InputMaybe<BlockInfoSortAggregation>;
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  edition_num?: InputMaybe<SortAggregation>;
  id?: InputMaybe<SortAggregation>;
  listing?: InputMaybe<ListingSortAggregation>;
  owner_address?: InputMaybe<SortStringKey>;
  owner_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  set?: InputMaybe<UfcSetSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type UfcSeries = {
  __typename?: 'UFCSeries';
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt']>;
  metadata?: Maybe<UfcSeriesMetadata>;
  metadata_last_updated_at?: Maybe<BlockInfo>;
  sealed?: Maybe<Scalars['Boolean']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type UfcSeriesAggregation = {
  __typename?: 'UFCSeriesAggregation';
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UIntAggregation>;
  metadata?: Maybe<UfcSeriesMetadataAggregation>;
  metadata_last_updated_at?: Maybe<BlockInfoAggregation>;
  sealed?: Maybe<BooleanAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type UfcSeriesFilter = {
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UIntFilter>;
  metadata?: InputMaybe<UfcSeriesMetadataFilter>;
  metadata_last_updated_at?: InputMaybe<BlockInfoFilter>;
  sealed?: InputMaybe<BooleanFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type UfcSeriesMetadata = {
  __typename?: 'UFCSeriesMetadata';
  name?: Maybe<Scalars['String']>;
  series_description?: Maybe<Scalars['String']>;
};

export type UfcSeriesMetadataAggregation = {
  __typename?: 'UFCSeriesMetadataAggregation';
  name?: Maybe<StringAggregation>;
  series_description?: Maybe<StringAggregation>;
};

export type UfcSeriesMetadataFilter = {
  exists?: InputMaybe<Scalars['Boolean']>;
  name?: InputMaybe<StringFilter>;
  series_description?: InputMaybe<StringFilter>;
};

export type UfcSeriesMetadataSort = {
  name?: InputMaybe<SortString>;
  series_description?: InputMaybe<SortString>;
};

export type UfcSeriesMetadataSortAggregation = {
  name?: InputMaybe<SortStringKey>;
  series_description?: InputMaybe<SortStringKey>;
};

export type UfcSeriesSort = {
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  metadata?: InputMaybe<UfcSeriesMetadataSort>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSort>;
  sealed?: InputMaybe<Sort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type UfcSeriesSortAggregation = {
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<UfcSeriesMetadataSortAggregation>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  sealed?: InputMaybe<SortKey>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type UfcSet = {
  __typename?: 'UFCSet';
  created_at?: Maybe<BlockInfo>;
  id?: Maybe<Scalars['UInt']>;
  max_mintings?: Maybe<Scalars['UInt64']>;
  metadata?: Maybe<UfcSetMetadata>;
  metadata_last_updated_at?: Maybe<BlockInfo>;
  series?: Maybe<UfcSeries>;
  total_burned?: Maybe<Scalars['UInt64']>;
  total_minted?: Maybe<Scalars['UInt64']>;
  type_name?: Maybe<Scalars['String']>;
  updated_at?: Maybe<BlockInfo>;
};

export type UfcSetAggregation = {
  __typename?: 'UFCSetAggregation';
  created_at?: Maybe<BlockInfoAggregation>;
  id?: Maybe<UIntAggregation>;
  max_mintings?: Maybe<UInt64Aggregation>;
  metadata?: Maybe<UfcSetMetadataAggregation>;
  metadata_last_updated_at?: Maybe<BlockInfoAggregation>;
  series?: Maybe<UfcSeriesAggregation>;
  total_burned?: Maybe<StatsInfoAggregation>;
  total_minted?: Maybe<StatsInfoAggregation>;
  type_name?: Maybe<StringAggregation>;
  updated_at?: Maybe<BlockInfoAggregation>;
};

export type UfcSetFilter = {
  created_at?: InputMaybe<BlockInfoFilter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<UIntFilter>;
  max_mintings?: InputMaybe<UInt64Filter>;
  metadata?: InputMaybe<UfcSetMetadataFilter>;
  metadata_last_updated_at?: InputMaybe<BlockInfoFilter>;
  series?: InputMaybe<UfcSeriesFilter>;
  type_name?: InputMaybe<StringFilter>;
  updated_at?: InputMaybe<BlockInfoFilter>;
};

export type UfcSetMetadata = {
  __typename?: 'UFCSetMetadata';
  additional_images?: Maybe<Array<Maybe<Scalars['String']>>>;
  athlete_1?: Maybe<Scalars['String']>;
  athlete_2?: Maybe<Scalars['String']>;
  athlete_name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  creator_name?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  division?: Maybe<Scalars['String']>;
  edition_size?: Maybe<Scalars['UInt64']>;
  external_url?: Maybe<Scalars['String']>;
  highlight_category?: Maybe<Scalars['String']>;
  highlight_type?: Maybe<Scalars['String']>;
  image?: Maybe<Scalars['String']>;
  image_file_type?: Maybe<Scalars['String']>;
  ipfs_image_hash?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  preview?: Maybe<Scalars['String']>;
  series?: Maybe<Scalars['UInt']>;
  set?: Maybe<Scalars['String']>;
  sha256_image_hash?: Maybe<Scalars['String']>;
  tier?: Maybe<Scalars['String']>;
  weight_class?: Maybe<Scalars['String']>;
};

export type UfcSetMetadataAggregation = {
  __typename?: 'UFCSetMetadataAggregation';
  athlete_1?: Maybe<StringAggregation>;
  athlete_2?: Maybe<StringAggregation>;
  athlete_name?: Maybe<StringAggregation>;
  category?: Maybe<StringAggregation>;
  creator_name?: Maybe<StringAggregation>;
  description?: Maybe<StringAggregation>;
  division?: Maybe<StringAggregation>;
  edition_size?: Maybe<UInt64Aggregation>;
  external_url?: Maybe<StringAggregation>;
  highlight_category?: Maybe<StringAggregation>;
  highlight_type?: Maybe<StringAggregation>;
  image?: Maybe<StringAggregation>;
  image_file_type?: Maybe<StringAggregation>;
  ipfs_image_hash?: Maybe<StringAggregation>;
  name?: Maybe<StringAggregation>;
  preview?: Maybe<StringAggregation>;
  series?: Maybe<UIntAggregation>;
  set?: Maybe<StringAggregation>;
  sha256_image_hash?: Maybe<StringAggregation>;
  tier?: Maybe<StringAggregation>;
  weight_class?: Maybe<StringAggregation>;
};

export type UfcSetMetadataFilter = {
  additional_images?: InputMaybe<StringListFilter>;
  athlete_1?: InputMaybe<StringFilter>;
  athlete_2?: InputMaybe<StringFilter>;
  athlete_name?: InputMaybe<StringFilter>;
  category?: InputMaybe<StringFilter>;
  creator_name?: InputMaybe<StringFilter>;
  description?: InputMaybe<StringFilter>;
  division?: InputMaybe<StringFilter>;
  edition_size?: InputMaybe<UInt64Filter>;
  exists?: InputMaybe<Scalars['Boolean']>;
  external_url?: InputMaybe<StringFilter>;
  highlight_category?: InputMaybe<StringFilter>;
  highlight_type?: InputMaybe<StringFilter>;
  image?: InputMaybe<StringFilter>;
  image_file_type?: InputMaybe<StringFilter>;
  ipfs_image_hash?: InputMaybe<StringFilter>;
  name?: InputMaybe<StringFilter>;
  preview?: InputMaybe<StringFilter>;
  series?: InputMaybe<UIntFilter>;
  set?: InputMaybe<StringFilter>;
  sha256_image_hash?: InputMaybe<StringFilter>;
  tier?: InputMaybe<StringFilter>;
  weight_class?: InputMaybe<StringFilter>;
};

export type UfcSetMetadataSort = {
  athlete_1?: InputMaybe<SortString>;
  athlete_2?: InputMaybe<SortString>;
  athlete_name?: InputMaybe<SortString>;
  category?: InputMaybe<SortString>;
  creator_name?: InputMaybe<SortString>;
  description?: InputMaybe<SortString>;
  division?: InputMaybe<SortString>;
  edition_size?: InputMaybe<Sort>;
  external_url?: InputMaybe<SortString>;
  highlight_category?: InputMaybe<SortString>;
  highlight_type?: InputMaybe<SortString>;
  image?: InputMaybe<SortString>;
  image_file_type?: InputMaybe<SortString>;
  ipfs_image_hash?: InputMaybe<SortString>;
  name?: InputMaybe<SortString>;
  preview?: InputMaybe<SortString>;
  series?: InputMaybe<Sort>;
  set?: InputMaybe<SortString>;
  sha256_image_hash?: InputMaybe<SortString>;
  tier?: InputMaybe<SortString>;
  weight_class?: InputMaybe<SortString>;
};

export type UfcSetMetadataSortAggregation = {
  athlete_1?: InputMaybe<SortStringKey>;
  athlete_2?: InputMaybe<SortStringKey>;
  athlete_name?: InputMaybe<SortStringKey>;
  category?: InputMaybe<SortStringKey>;
  creator_name?: InputMaybe<SortStringKey>;
  description?: InputMaybe<SortStringKey>;
  division?: InputMaybe<SortStringKey>;
  edition_size?: InputMaybe<SortAggregation>;
  external_url?: InputMaybe<SortStringKey>;
  highlight_category?: InputMaybe<SortStringKey>;
  highlight_type?: InputMaybe<SortStringKey>;
  image?: InputMaybe<SortStringKey>;
  image_file_type?: InputMaybe<SortStringKey>;
  ipfs_image_hash?: InputMaybe<SortStringKey>;
  name?: InputMaybe<SortStringKey>;
  preview?: InputMaybe<SortStringKey>;
  series?: InputMaybe<SortAggregation>;
  set?: InputMaybe<SortStringKey>;
  sha256_image_hash?: InputMaybe<SortStringKey>;
  tier?: InputMaybe<SortStringKey>;
  weight_class?: InputMaybe<SortStringKey>;
};

export type UfcSetSort = {
  created_at?: InputMaybe<BlockInfoSort>;
  id?: InputMaybe<Sort>;
  max_mintings?: InputMaybe<Sort>;
  metadata?: InputMaybe<UfcSetMetadataSort>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSort>;
  series?: InputMaybe<UfcSeriesSort>;
  type_name?: InputMaybe<SortString>;
  updated_at?: InputMaybe<BlockInfoSort>;
};

export type UfcSetSortAggregation = {
  created_at?: InputMaybe<BlockInfoSortAggregation>;
  id?: InputMaybe<SortAggregation>;
  max_mintings?: InputMaybe<SortAggregation>;
  metadata?: InputMaybe<UfcSetMetadataSortAggregation>;
  metadata_last_updated_at?: InputMaybe<BlockInfoSortAggregation>;
  series?: InputMaybe<UfcSeriesSortAggregation>;
  type_name?: InputMaybe<SortStringKey>;
  updated_at?: InputMaybe<BlockInfoSortAggregation>;
};

export type UInt8Aggregation = {
  __typename?: 'UInt8Aggregation';
  avg?: Maybe<Scalars['UInt8']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['UInt8']>;
  max?: Maybe<Scalars['UInt8']>;
  min?: Maybe<Scalars['UInt8']>;
  value?: Maybe<Scalars['UInt8']>;
};

export type UInt8Filter = {
  eq?: InputMaybe<Scalars['UInt8']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['UInt8']>;
  gte?: InputMaybe<Scalars['UInt8']>;
  in?: InputMaybe<Array<Scalars['UInt8']>>;
  lt?: InputMaybe<Scalars['UInt8']>;
  lte?: InputMaybe<Scalars['UInt8']>;
  ne?: InputMaybe<Scalars['UInt8']>;
  notIn?: InputMaybe<Array<Scalars['UInt8']>>;
};

export type UInt8ListFilter = {
  contains?: InputMaybe<Array<Scalars['UInt8']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type UInt16Aggregation = {
  __typename?: 'UInt16Aggregation';
  avg?: Maybe<Scalars['UInt16']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['UInt16']>;
  max?: Maybe<Scalars['UInt16']>;
  min?: Maybe<Scalars['UInt16']>;
  value?: Maybe<Scalars['UInt16']>;
};

export type UInt16Filter = {
  eq?: InputMaybe<Scalars['UInt16']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['UInt16']>;
  gte?: InputMaybe<Scalars['UInt16']>;
  in?: InputMaybe<Array<Scalars['UInt16']>>;
  lt?: InputMaybe<Scalars['UInt16']>;
  lte?: InputMaybe<Scalars['UInt16']>;
  ne?: InputMaybe<Scalars['UInt16']>;
  notIn?: InputMaybe<Array<Scalars['UInt16']>>;
};

export type UInt16ListFilter = {
  contains?: InputMaybe<Array<Scalars['UInt16']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type UInt64Aggregation = {
  __typename?: 'UInt64Aggregation';
  avg?: Maybe<Scalars['UInt64']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['UInt64']>;
  max?: Maybe<Scalars['UInt64']>;
  min?: Maybe<Scalars['UInt64']>;
  value?: Maybe<Scalars['UInt64']>;
};

export type UInt64Filter = {
  eq?: InputMaybe<Scalars['UInt64']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['UInt64']>;
  gte?: InputMaybe<Scalars['UInt64']>;
  in?: InputMaybe<Array<Scalars['UInt64']>>;
  lt?: InputMaybe<Scalars['UInt64']>;
  lte?: InputMaybe<Scalars['UInt64']>;
  ne?: InputMaybe<Scalars['UInt64']>;
  notIn?: InputMaybe<Array<Scalars['UInt64']>>;
};

export type UInt64ListFilter = {
  contains?: InputMaybe<Array<Scalars['UInt64']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type UIntAggregation = {
  __typename?: 'UIntAggregation';
  avg?: Maybe<Scalars['UInt']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['UInt']>;
  max?: Maybe<Scalars['UInt']>;
  min?: Maybe<Scalars['UInt']>;
  value?: Maybe<Scalars['UInt']>;
};

export type UIntFilter = {
  eq?: InputMaybe<Scalars['UInt']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['UInt']>;
  gte?: InputMaybe<Scalars['UInt']>;
  in?: InputMaybe<Array<Scalars['UInt']>>;
  lt?: InputMaybe<Scalars['UInt']>;
  lte?: InputMaybe<Scalars['UInt']>;
  ne?: InputMaybe<Scalars['UInt']>;
  notIn?: InputMaybe<Array<Scalars['UInt']>>;
};

export type UIntListFilter = {
  contains?: InputMaybe<Array<Scalars['UInt']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type UpdateDistributionInput = {
  acceptReservations?: InputMaybe<Scalars['Boolean']>;
  code?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  distributionUUID: Scalars['ID'];
  endTime?: InputMaybe<Scalars['Time']>;
  images?: InputMaybe<Array<InputMaybe<DistributionImageInput>>>;
  price?: InputMaybe<Scalars['Float']>;
  queueID?: InputMaybe<Scalars['String']>;
  seriesIDs?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  startTime?: InputMaybe<Scalars['Time']>;
  tier?: InputMaybe<DistributionTier>;
  title?: InputMaybe<Scalars['String']>;
  videos?: InputMaybe<Array<InputMaybe<DistributionVideoInput>>>;
};

export type UpdateDistributionResponse = {
  __typename?: 'UpdateDistributionResponse';
  success: Scalars['Boolean'];
};

export type UpsertAirdropEventInput = {
  id?: InputMaybe<Scalars['String']>;
  title?: InputMaybe<Scalars['String']>;
};

export type UpsertAirdropEventResponse = {
  __typename?: 'UpsertAirdropEventResponse';
  id?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type UpsertUserProfileInput = {
  email?: InputMaybe<Scalars['String']>;
  favoriteClub?: InputMaybe<Scalars['String']>;
  flowAddress?: InputMaybe<Scalars['String']>;
  marketingEmail?: InputMaybe<Scalars['Boolean']>;
  phoneNumber?: InputMaybe<Scalars['String']>;
  preferredCurrency?: InputMaybe<PreferredCurrency>;
  preferredLanguage?: InputMaybe<PreferredLanguage>;
  profileImageUrl?: InputMaybe<Scalars['String']>;
  signedTOS?: InputMaybe<Scalars['Boolean']>;
  username?: InputMaybe<Scalars['String']>;
};

export type UpsertUserProfileResponse = {
  __typename?: 'UpsertUserProfileResponse';
  isCreated?: Maybe<Scalars['Boolean']>;
  userProfile?: Maybe<UserProfile>;
};

export type UserDetails = {
  avatar?: Maybe<Scalars['String']>;
  flowAddress: Scalars['String'];
  source: Scalars['String'];
  username: Scalars['String'];
};

export type UserProfile = {
  __typename?: 'UserProfile';
  createdAt?: Maybe<Scalars['Time']>;
  dapperID?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  favoriteClub?: Maybe<Scalars['String']>;
  flowAddress?: Maybe<Scalars['String']>;
  marketingEmail?: Maybe<Scalars['Boolean']>;
  phoneNumber?: Maybe<Scalars['String']>;
  preferredCurrency?: Maybe<PreferredCurrency>;
  preferredLanguage?: Maybe<PreferredLanguage>;
  profileImageUrl?: Maybe<Scalars['String']>;
  signedTos?: Maybe<Scalars['Boolean']>;
  username?: Maybe<Scalars['String']>;
};

export type UserProfileWithoutPii = {
  __typename?: 'UserProfileWithoutPII';
  createdAt?: Maybe<Scalars['Time']>;
  dapperID?: Maybe<Scalars['String']>;
  favoriteClub?: Maybe<Scalars['String']>;
  flowAddress?: Maybe<Scalars['String']>;
  profileImageUrl?: Maybe<Scalars['String']>;
  username?: Maybe<Scalars['String']>;
};

export type UserQueue = {
  entryId: Scalars['String'];
  userId: Scalars['String'];
};

export type ValidateCustomerSupportPackNftReservationInput = {
  distributionID: Scalars['Int'];
  reservationID: Scalars['ID'];
  userFlowAddress: Scalars['String'];
};

export type ValidateCustomerSupportPackNftReservationResponse = {
  __typename?: 'ValidateCustomerSupportPackNFTReservationResponse';
  listingResourceFlowID?: Maybe<Scalars['Int']>;
  packNFTID?: Maybe<Scalars['Int']>;
  success?: Maybe<Scalars['Boolean']>;
};

export type ValidateCustomerSupportReservationInput = {
  distribution_id: Scalars['Int'];
  reservation_id: Scalars['String'];
  user_flow_address: Scalars['String'];
};

export type ValidateCustomerSupportReservationResponse = {
  __typename?: 'ValidateCustomerSupportReservationResponse';
  listing_resource_flow_id: Scalars['Int'];
  pack_nft_id: Scalars['Int'];
  success: Scalars['Boolean'];
};

export type Value = {
  UInt64_value?: InputMaybe<Scalars['UInt64']>;
  int_value?: InputMaybe<Scalars['Int']>;
  string_value?: InputMaybe<Scalars['String']>;
  time?: InputMaybe<Scalars['Time']>;
  uint_value?: InputMaybe<Scalars['UInt']>;
};

export type Word8Aggregation = {
  __typename?: 'Word8Aggregation';
  avg?: Maybe<Scalars['Word8']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Word8']>;
  max?: Maybe<Scalars['Word8']>;
  min?: Maybe<Scalars['Word8']>;
  value?: Maybe<Scalars['Word8']>;
};

export type Word8Filter = {
  eq?: InputMaybe<Scalars['Word8']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Word8']>;
  gte?: InputMaybe<Scalars['Word8']>;
  in?: InputMaybe<Array<Scalars['Word8']>>;
  lt?: InputMaybe<Scalars['Word8']>;
  lte?: InputMaybe<Scalars['Word8']>;
  ne?: InputMaybe<Scalars['Word8']>;
  notIn?: InputMaybe<Array<Scalars['Word8']>>;
};

export type Word8ListFilter = {
  contains?: InputMaybe<Array<Scalars['Word8']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type Word16Aggregation = {
  __typename?: 'Word16Aggregation';
  avg?: Maybe<Scalars['Word16']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Word16']>;
  max?: Maybe<Scalars['Word16']>;
  min?: Maybe<Scalars['Word16']>;
  value?: Maybe<Scalars['Word16']>;
};

export type Word16Filter = {
  eq?: InputMaybe<Scalars['Word16']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Word16']>;
  gte?: InputMaybe<Scalars['Word16']>;
  in?: InputMaybe<Array<Scalars['Word16']>>;
  lt?: InputMaybe<Scalars['Word16']>;
  lte?: InputMaybe<Scalars['Word16']>;
  ne?: InputMaybe<Scalars['Word16']>;
  notIn?: InputMaybe<Array<Scalars['Word16']>>;
};

export type Word16ListFilter = {
  contains?: InputMaybe<Array<Scalars['Word16']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type Word64Aggregation = {
  __typename?: 'Word64Aggregation';
  avg?: Maybe<Scalars['Word64']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Word64']>;
  max?: Maybe<Scalars['Word64']>;
  min?: Maybe<Scalars['Word64']>;
  value?: Maybe<Scalars['Word64']>;
};

export type Word64Filter = {
  eq?: InputMaybe<Scalars['Word64']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Word64']>;
  gte?: InputMaybe<Scalars['Word64']>;
  in?: InputMaybe<Array<Scalars['Word64']>>;
  lt?: InputMaybe<Scalars['Word64']>;
  lte?: InputMaybe<Scalars['Word64']>;
  ne?: InputMaybe<Scalars['Word64']>;
  notIn?: InputMaybe<Array<Scalars['Word64']>>;
};

export type Word64ListFilter = {
  contains?: InputMaybe<Array<Scalars['Word64']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};

export type WordAggregation = {
  __typename?: 'WordAggregation';
  avg?: Maybe<Scalars['Word']>;
  count?: Maybe<Scalars['Int']>;
  key?: Maybe<Scalars['Word']>;
  max?: Maybe<Scalars['Word']>;
  min?: Maybe<Scalars['Word']>;
  value?: Maybe<Scalars['Word']>;
};

export type WordFilter = {
  eq?: InputMaybe<Scalars['Word']>;
  exists?: InputMaybe<Scalars['Boolean']>;
  gt?: InputMaybe<Scalars['Word']>;
  gte?: InputMaybe<Scalars['Word']>;
  in?: InputMaybe<Array<Scalars['Word']>>;
  lt?: InputMaybe<Scalars['Word']>;
  lte?: InputMaybe<Scalars['Word']>;
  ne?: InputMaybe<Scalars['Word']>;
  notIn?: InputMaybe<Array<Scalars['Word']>>;
};

export type WordListFilter = {
  contains?: InputMaybe<Array<Scalars['Word']>>;
  contains_type?: InputMaybe<ContainsType>;
  exists?: InputMaybe<Scalars['Boolean']>;
};
