import React from 'react';

import { CENTER } from './DonutChart';

interface DonutSegmentProps {
  [key: string]: unknown;
  radius: number;
  strokeColor: string;
  strokeDasharray: string;
  strokeDashoffset: number;
  strokeWidth: number;
  value?: number;
}

export const DonutSegment: React.FC<DonutSegmentProps> = ({
  strokeWidth,
  strokeDasharray,
  strokeDashoffset,
  strokeColor,
  radius,
  value,
  ...props
}) => {
  if (!value) {
    return null;
  }
  return (
    <circle
      {...props}
      fill='none'
      stroke={strokeColor}
      cx={CENTER.x}
      cy={CENTER.y}
      r={radius}
      strokeDasharray={strokeDasharray}
      strokeDashoffset={strokeDashoffset}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={strokeWidth}
    />
  );
};
