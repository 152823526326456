/* eslint-disable sort-keys-fix/sort-keys-fix */
import { ApolloClient } from '@apollo/client';
import { GuestSession } from 'src/lib/guest';
import { UserProfile } from 'src/modules/Queries/generated';
import { State } from 'xstate';

export enum FS {
  AUTHENTICATED = 'AUTHENTICATED',
  LOADING = 'LOADING',
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

export enum EVENTS {
  ACCEPT_TERMS = 'ACCEPT_TERMS',
  CHOOSE_CLUB = 'CHOOSE_CLUB',
  CHOOSE_CLUB_PACK_DROP = 'CHOOSE_CLUB_PACK_DROP',
  DISMISS = 'DISMISS',
  SIGN_IN = 'SIGN_IN',
  SIGN_OUT = 'SIGN_OUT',
  UPDATE_CURRENCY = 'UPDATE_CURRENCY',
  UPDATE_FLOW_ADDRESS = 'UPDATE_FLOW_ADDRESS',
  UPDATE_LANGUAGE = 'UPDATE_LANGUAGE',
}

export type Events = {
  data?: any;
  returnTo?: string;
  type: EVENTS | keyof typeof EVENTS;
};

export interface Context {
  auth: any;
  client: ApolloClient<object>;
  error: any;
  favoriteClub: string;
  guest: GuestSession;
  i18n: any;
  marketingEmail: boolean;
  preferredCurrency: 'DOLLARS' | 'EUROS';
  preferredLanguage: 'ENG' | 'ESP';
  profile: UserProfile;
}

export interface Schema {
  states: {
    AUTHENTICATED: {
      states: {
        IDLE: {};
        SIGNING_OUT: {};
        UPSERTING_PREFERENCES: {};
      };
    };
    LOADING: {
      states: {
        LOCAL_PREFERENCES: {};
        PROFILE: {};
        SESSION: {};
      };
    };
    ONBOARDING: {
      states: {
        AWAITING_TERMS_ACCEPTANCE: {};
        CREATING_PROFILE: {};
      };
    };
    UNAUTHENTICATED: {
      states: {
        IDLE: {};
        SIGNING_IN: {};
      };
    };
  };
}

export interface TypeState {
  context: Context;
  value:
    | 'LOADING'
    | 'ONBOARDING'
    | 'AUTHENTICATED'
    | 'UNAUTHENTICATED'
    | 'ONBOARDING.AWAITING_TERMS_ACCEPTANCE'
    | 'ONBOARDING.AWAITING_FAVOURITE_CLUB'
    | 'ONBOARDING.CREATING_PROFILE'
    | 'ONBOARDING.UPSERTING_FAVOURITE_CLUB'
    | 'ONBOARDING.UPSERTING_FAVOURITE_CLUB_PACK_DROP'
    | 'ONBOARDING.FINAL'
    | 'ONBOARDING.FINAL_PACK_DROP';
}

export type MachineType = State<Context, Events, Schema, TypeState>;
