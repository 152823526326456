import { send, tx, decode } from '@onflow/fcl';
import { addBreadcrumb } from '@sentry/nextjs';
import { AnyEventObject, assign } from 'xstate';

import { QUERY as GET_MY_PROFILE } from 'src/edge/getMyProfile';
import { getMyProfile } from 'src/edge/getMyProfile/__generated__/getMyProfile';
import { getSession } from 'src/edge/session';
import { getItem, setItem } from 'src/general/utils/localStorage';
import { captureException } from 'src/lib/sentry';
import { COMPLETE_USER_FTUE_AND_SEND_ACCOLADE } from 'src/modules/FTUEV2/mutations';
import { updateFavouriteTeam } from 'src/modules/profile';
import { signInPath } from 'src/modules/routes';
import {
  searchAllDaySeasonalNft,
  SEASONAL_NFT_QUERY,
  SEASONAL_NFT_QUERY_VARS,
} from 'src/modules/SeasonalNFT';

import { completeUserFTUE } from '../__generated__/completeUserFTUE';
import { Context } from './types';
import { getInteraction } from './utils';

export const actions = {
  assignEditionData: assign({
    seasonalNFTEdition: (_, event: AnyEventObject) =>
      event?.data.seasonalNFTEdition,
  }),
  assignError: assign({
    error: (_: Partial<Context>, event: AnyEventObject) => event.data,
  }),
  assignInteraction: assign({
    interaction: (context: Partial<Context>) =>
      getInteraction({ client: context.client }),
  }),
  assignTransactionId: assign({
    txId: (_: Partial<Context>, event: AnyEventObject) => event.data,
  }),
  assignUserData: assign({
    profile: (_, event: any) => event?.data?.profile,
  }),
  storeTeam: assign({
    favoriteTeamId: (context: Partial<Context>, event: AnyEventObject) => {
      setItem('teamId', event.teamId);
      return (context.favoriteTeamId = event?.teamId);
    },
  }),
};

export const guards = {
  shouldSkipFTUE: (context: any) => {
    const { profile, flags, router } = context;
    const { skipFTUE } = router.query;

    return (
      flags?.isFlowMaintenanceEnabled ||
      flags?.authMaintenance ||
      profile?.hasCompletedFTUE ||
      !flags?.isFTUEV2Enabled ||
      skipFTUE
    );
  },
  shouldSkipFTUELegacy: (context: any) => {
    const { profile, flags, router } = context;
    const { skipFTUE } = router.query;

    return (
      flags?.isFlowMaintenanceEnabled ||
      flags?.authMaintenance ||
      profile?.hasCompletedFTUE ||
      flags?.isFTUEV2Enabled ||
      !flags?.isFTUEEnabled ||
      skipFTUE
    );
  },
  shouldSkipPreparingAccount: (context: any) => {
    const { profile } = context;
    return profile?.flowAddress !== null;
  },
  shouldSkipSignUp: (context: any) => {
    const { profile } = context;
    return profile;
  },
  shouldSkipTOS: (context: any) => {
    const { profile } = context;
    return profile?.isCurrentTOSSigned;
  },
  shouldSkipTeamPicker: (context: any) => {
    const { profile } = context;
    const favoriteTeamID = getItem('teamId');
    return profile?.favoriteTeamIDs || favoriteTeamID !== null;
  },
};

const sendAccoladeAndCompleteFTUE = async (context: Context) => {
  const { client } = context;
  const { data, errors } = await client.mutate<completeUserFTUE>({
    mutation: COMPLETE_USER_FTUE_AND_SEND_ACCOLADE,
  });

  if (errors) {
    captureException(errors);
    throw new Error('Unable to complete ftue');
  }

  context.showAccoladeToast(data.completeUserFTUE.accolade);
};

export const services = {
  addTeamToProfile: async (context: Context) => {
    const favoriteTeamID = getItem('teamId');

    if (!favoriteTeamID || context?.profile?.favoriteTeamIDs !== null)
      return null;

    try {
      const { data: profile } = await updateFavouriteTeam(favoriteTeamID);

      return {
        profile,
      };
    } catch (error) {
      captureException(error);
      throw new Error('Unable to update user profile with favorite team');
    }
  },
  awaitTransactionExecuted: async (context: Context) => {
    return await tx(context.txId).onceExecuted();
  },
  awaitTransactionFinalized: async (context: Context) => {
    return await tx(context.txId).onceFinalized();
  },
  awaitTransactionSealed: async (context: Context) => {
    return await tx(context.txId).onceSealed();
  },
  loadEditionData: async (context: Context) => {
    const { client } = context;

    const { data, errors } = await client.query<searchAllDaySeasonalNft>({
      context: {
        capturePolicy: 'none',
        clientName: 'platformAPI',
      },
      query: SEASONAL_NFT_QUERY,
      variables: SEASONAL_NFT_QUERY_VARS({
        editionId: process.env.NEXT_PUBLIC_FTUE_SEASONAL_NFT_EDITION_FLOWID,
        first: 1,
      }),
    });

    if (errors) {
      captureException(errors);
      throw new Error('Unable to fetch Seasonal NFT Edition');
    }

    return {
      seasonalNFTEdition:
        data?.searchAllDaySeasonalNft?.edges?.[0]?.node?.edition,
    };
  },
  loadUserData: async (context: Context) => {
    const { client } = context;

    const session = getSession();

    const isAuthenticated = await session.getIsAuthenticated();
    if (!isAuthenticated) throw new Error('User is unauthenticated');

    const { errors, data } = await client.query<getMyProfile>({
      context: {
        capturePolicy: 'none',
      },
      query: GET_MY_PROFILE,
    });

    if (errors) {
      captureException(errors);
      throw new Error('Unable to fetch user profile');
    }

    const profile = { ...data.getMyProfile };

    if (!profile?.hasCompletedFTUE && profile.flowAddress) {
      // fetch user seasonal NFT data
      const { errors: seasonalNFTErrors, data: seasonalNFTData } =
        await client.query<searchAllDaySeasonalNft>({
          context: { clientName: 'platformAPI' },
          query: SEASONAL_NFT_QUERY,
          variables: SEASONAL_NFT_QUERY_VARS({
            first: 1,
            ownerAddress: data?.getMyProfile?.flowAddress,
          }),
        });

      if (seasonalNFTErrors) {
        captureException(seasonalNFTErrors);
        throw new Error('Unable to fetch user seasonal NFTs');
      } else if (
        seasonalNFTData?.searchAllDaySeasonalNft?.edges?.length &&
        data?.getMyProfile?.hasCompletedFTUE === false
      ) {
        // user owns one or more seasonal NFTs, but hasCompletedFTUE is false. This indicates the user has completed the ftue so we should update the flag
        captureException(
          'User with one or more seasonal NFTs and hasCompletedFTUE set to false detected, setting flag to true',
          { level: 'warning' },
        );

        // set user hasCompletedFTUE to true, it is wrapped in a try/catch as this mutation could partially fail but only runs a maximum of one time per user. This is expected.
        try {
          await sendAccoladeAndCompleteFTUE(context);
          // eslint-disable-next-line no-empty
        } catch {}

        // set profile data sent to machine context with hasCompletedFTUE flag set to true
        profile.hasCompletedFTUE = true;
      }
    }

    return {
      profile,
    };
  },
  sendAccoladeAndCompleteFTUE: async (context: Context) => {
    await sendAccoladeAndCompleteFTUE(context);
  },
  sendTransaction: async (context: Context) => {
    const interaction = await context.interaction();
    const txId = await send(interaction).then(decode);

    addBreadcrumb({
      category: 'response',
      data: txId,
      level: 'info',
    });

    return txId;
  },
  signUp: async (context: Context, { returnTo = null }) => {
    const params: { returnTo?: string } = {};

    if (returnTo) {
      params.returnTo = returnTo;
    } else {
      const fullPath = window.location.href.replace(window.location.origin, '');
      if (fullPath !== '/') params.returnTo = fullPath;
    }

    return window?.location.replace(signInPath(params));
  },
};
