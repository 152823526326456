/* eslint-disable sort-keys-fix/sort-keys-fix */
import merge from 'lodash/merge';
import { MachineConfig, createMachine, sendUpdate } from 'xstate';

import { REVEAL_FACE_DELAY } from './constants';
import { actions, guards, services } from './options';
import { FS, EVENTS, Context, Schema, Events } from './types';

// https://stately.ai/viz/fe2f8c7b-3b40-41aa-9fb0-c707e3b9c48f
export const definition: MachineConfig<Context, Schema, Events> = {
  id: 'momentNFT',
  context: {
    EVENTS,
    FS,
    REVEAL_FACE_DELAY,
    autoAppearDelay: 2000,
    getPreload: undefined,
    media: undefined,
    momentNFT: undefined,
    momentRarity: undefined,
    preloads: undefined,
  },
  initial: 'IN_VIEW',
  on: {
    FORCE_REVEAL: {
      target: 'REVEALED',
    },
  },
  states: {
    // @NOTE, not using IDLE for now:
    IDLE: {
      on: {
        START: 'IN_VIEW',
      },
    },
    IN_VIEW: {
      after: {
        AUTO_APPEAR_DELAY: 'APPEARING',
      },
    },
    APPEARING: {
      on: {
        UNVEIL: 'UNVEILING',
      },
    },
    UNVEILING: {
      entry: 'onUnveil',
      on: {
        REVEAL: {
          target: 'REVEALING',
          actions: [sendUpdate()],
        },
      },
    },
    REVEALING: {
      initial: 'FRONT_FACE',
      states: {
        FRONT_FACE: {
          after: {
            [REVEAL_FACE_DELAY]: [
              {
                cond: 'isMultiStepReveal',
                target: 'SCORES_FACE',
              },
              {
                target: 'HERO_FACE',
              },
            ],
          },
        },
        SCORES_FACE: {
          after: {
            [REVEAL_FACE_DELAY]: 'HERO_FACE',
          },
        },
        HERO_FACE: {
          after: {
            [REVEAL_FACE_DELAY]: `#DETAIL_VIEW`,
          },
        },
      },
    },
    DETAIL_VIEW: {
      id: 'DETAIL_VIEW',
      on: {
        CLOSE_DETAILS: 'REVEALED',
        AUTO_CLOSE: 'REVEALED',
      },
    },
    REVEALED: {
      entry: 'onRevealed',
      id: 'REVEALED',
      initial: 'IDLE',
      states: {
        IDLE: {
          id: `REVEALED.IDLE`,
          on: {
            VIEW_DETAILS: `#REVEALED.DETAIL_VIEW`,
          },
        },
        DETAIL_VIEW: {
          id: `REVEALED.DETAIL_VIEW`,
          on: {
            CLOSE_DETAILS: `#REVEALED.IDLE`,
          },
        },
      },
    },
  },
};

export const getMomentNFTMachine = ({ custom = {} }: any = {}) => {
  return createMachine(
    merge(
      {},
      {
        ...definition,
      },
      custom?.definition,
    ),
    merge(
      {},
      {
        actions,
        guards,
        services,
      },
      custom?.options,
    ),
  );
};
