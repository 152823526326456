import { distributionFactory } from '@dapperlabs/core-fe';
import {
  DistributionImageType,
  DistributionVideoType,
} from '__generated__/globalTypes';
import { searchPackNFTs__ForPackOpening_searchPackNFTs_packNFTs_momentNFTs as TMomentNFT } from '__generated__/searchPackNFTs__ForPackOpening';
import merge from 'lodash/merge';
import { searchPackNFTsHandler, packNFTFactory } from 'src/edge/searchPackNfts';
import type { PartialDeep } from 'type-fest';

export const handlers = {
  polling: {
    sealed: {
      searchPackNFTs: () => {
        // @ts-ignore
        return [
          searchPackNFTsHandler(
            [packNFTFactory({ status: 'SEALED' })],
            'searchPackNft__ForPackOpening',
          ),
        ];
      },
    },
    success: {
      searchPackNFTs: () => {
        // @ts-ignore
        return [
          searchPackNFTsHandler(
            [packNFTFactory()],
            'searchPackNft__ForPackOpening',
          ),
        ];
      },
    },
  },
};

export const momentNFTFactory = (
  overrides: PartialDeep<TMomentNFT> = {},
): TMomentNFT =>
  merge(
    {},
    {
      ...MOCK_MOMENT_NFT(),
    } as TMomentNFT,
    overrides,
  );

export const DISTRIBUTION_NFT = distributionFactory({
  node: {
    images: [
      {
        __typename: 'DistributionImage',
        type: DistributionImageType.DEFAULT,
        url: 'https://assets.laligagolazos.com/packs/eterno_rival_historic_premium/images/pack.png',
      },
      {
        __typename: 'DistributionImage',
        type: DistributionImageType.BACKGROUND,
        url: 'https://assets.laligagolazos.com/packs/eterno_rival_historic_premium/images/pack_background.png',
      },
    ],
    videos: [
      {
        __typename: 'DistributionVideo',
        type: DistributionVideoType.RIP,
        url: 'https://assets.laligagolazos.com/packs/eterno_rival_historic_premium/videos/pack_opening.mp4',
      },
    ],
  },
});

// @TEMPORARY until i set up auto-mocks or msw for the POE story/ies
// function MOCK_MOMENT_NFTS() {
//   return [
//     {
//       __typename: 'MomentNFT',
//       edition: {
//         __typename: 'Edition',
//         currentMintSize: 10000,
//         id: 'f952820e-907f-4e25-9b27-30c685327a1d',
//         maxMintSize: 10000,
//         play: {
//           __typename: 'Play',
//           id: '2858f239-14d6-422f-9845-d0e7d2bc52c5',
//           metadata: {
//             __typename: 'PlayMetadata',
//             awayTeamID: '5182',
//             awayTeamName: 'Cleveland Browns',
//             awayTeamScore: '10',
//             gameDate: '11/28/2021',
//             homeTeamID: '5156',
//             homeTeamName: 'Baltimore Ravens',
//             homeTeamScore: '16',
//             playType: 'Sack',
//             playerFullName: 'Myles Garrett',
//             playerLastName: 'Garrett',
//             teamName: 'Cleveland Browns',
//           },
//         },
//         series: {
//           __typename: 'Series',
//           id: '830eb375-06bf-4a63-823d-75db2b1b21be',
//           name: 'Series 1',
//         },
//         set: {
//           __typename: 'Set',
//           id: '22ff5663-706b-46fa-88cc-6a2f0d92b63c',
//           name: 'Base',
//         },
//         tier: 'COMMON',
//       },
//       id: 'ece5b0c9-48c8-433e-96c1-d35d8e8ca1c0',
//       serialNumber: 8742,
//     },
//     {
//       __typename: 'MomentNFT',
//       edition: {
//         __typename: 'Edition',
//         currentMintSize: 10000,
//         id: '0bf04ba9-508b-4fd9-b8ba-1196bc5aaae4',
//         maxMintSize: 10000,
//         play: {
//           __typename: 'Play',
//           id: 'g707661_18507_recdFG9DvFgRb8rYq',
//           metadata: {
//             __typename: 'PlayMetadata',
//             awayTeamID: '5167',
//             awayTeamName: 'Minnesota Vikings',
//             awayTeamScore: '26',
//             gameDate: '11/28/2021',
//             homeTeamID: '5176',
//             homeTeamName: 'San Francisco 49ers',
//             homeTeamScore: '34',
//             playType: 'Field Goal',
//             playerFullName: 'Robbie Gould',
//             playerLastName: 'Gould',
//             teamName: 'San Francisco 49ers',
//           },
//         },
//         series: {
//           __typename: 'Series',
//           id: '830eb375-06bf-4a63-823d-75db2b1b21be',
//           name: 'Series 1',
//         },
//         set: {
//           __typename: 'Set',
//           id: '22ff5663-706b-46fa-88cc-6a2f0d92b63c',
//           name: 'Base',
//         },
//         tier: 'COMMON',
//       },
//       id: '08c543a0-6214-4b9f-905a-9c0f46d074f1',
//       serialNumber: 502,
//     },
//     {
//       __typename: 'MomentNFT',
//       edition: {
//         __typename: 'Edition',
//         currentMintSize: 999,
//         id: 'f7e3f81b-4738-4299-a2b2-fd4aa5661563',
//         maxMintSize: 999,
//         play: {
//           __typename: 'Play',
//           id: '2f3980cd-0650-4d04-bc96-6ab5eab2e8e5',
//           metadata: {
//             __typename: 'PlayMetadata',
//             awayTeamID: '5165',
//             awayTeamName: 'Los Angeles Rams',
//             awayTeamScore: '28',
//             gameDate: '11/28/2021',
//             homeTeamID: '5160',
//             homeTeamName: 'Green Bay Packers',
//             homeTeamScore: '36',
//             playType: 'Reception',
//             playerFullName: 'Odell Beckham Jr.',
//             playerLastName: 'Beckham Jr.',
//             teamName: 'Los Angeles Rams',
//           },
//         },
//         series: {
//           __typename: 'Series',
//           id: '830eb375-06bf-4a63-823d-75db2b1b21be',
//           name: 'Series 1',
//         },
//         set: {
//           __typename: 'Set',
//           id: 'e6654720-923d-4f69-b3e8-d4adcb021ee5',
//           name: 'Launch Codes',
//         },
//         tier: 'RARE',
//       },
//       id: '826e48b8-28f0-49be-9d67-69c1c79dfb32',
//       serialNumber: 681,
//     },
//     {
//       __typename: 'MomentNFT',
//       badges: [
//         {
//           __typename: 'Badge',
//           description:
//             'Marks the first Moment ever minted on NFL ALL DAY for a particular player, excluding the 1/1 Genesis Moments minted prior to Week 13 of the 2021 NFL season',
//           id: '45bd8495-e583-4175-afb5-03041f4ebb08',
//           slug: 'all-day-debut',
//           title: 'ALL DAY Debut',
//           visible: true,
//         },
//       ],
//       edition: {
//         __typename: 'Edition',
//         currentMintSize: 1200,
//         id: '9f44b267-e229-4a5f-bde1-6a4ae7ffbf35',
//         maxMintSize: 1200,
//         play: {
//           __typename: 'Play',
//           id: 'f56b67e9-014a-4ed7-ace3-e014728a3f5c',
//           metadata: {
//             __typename: 'PlayMetadata',
//             awayTeamID: '5176',
//             awayTeamName: 'San Francisco 49ers',
//             awayTeamScore: '13',
//             classification: 'TEAM_MELT',
//             gameDate: '01/22/2022',
//             homeTeamID: '5160',
//             homeTeamName: 'Green Bay Packers',
//             homeTeamScore: '10',
//             playType: '',
//             playerFullName: '',
//             playerLastName: '',
//             teamName: 'San Francisco 49ers',
//           },
//         },
//         series: {
//           __typename: 'Series',
//           id: '830eb375-06bf-4a63-823d-75db2b1b21be',
//           name: 'Series 1',
//         },
//         set: {
//           __typename: 'Set',
//           id: 'a62565ea-447d-482d-aa5e-cfe89019c8f4',
//           name: 'Divisional Round',
//         },
//         tier: 'RARE',
//       },
//       id: '5170b629-d8c4-4bfe-8b08-840dcdee1201',
//       serialNumber: 1096,
//     },
//   ];
// }

function MOCK_MOMENT_NFT() {
  return {
    __typename: 'MomentNFT',
    edition: {
      __typename: 'Edition',
      currentMintSize: 10000,
      id: '0bf04ba9-508b-4fd9-b8ba-1196bc5aaae4',
      maxMintSize: 10000,
      play: {
        __typename: 'Play',
        id: 'g707661_18507_recdFG9DvFgRb8rYq',
        metadata: {
          __typename: 'PlayMetadata',
          awayTeamID: '5167',
          awayTeamName: 'Minnesota Vikings',
          awayTeamScore: '26',
          gameDate: '11/28/2021',
          homeTeamID: '5176',
          homeTeamName: 'San Francisco 49ers',
          homeTeamScore: '34',
          playType: 'Field Goal',
          playerFullName: 'Robbie Gould',
          playerLastName: 'Gould',
          teamName: 'San Francisco 49ers',
        },
      },
      series: {
        __typename: 'Series',
        id: '830eb375-06bf-4a63-823d-75db2b1b21be',
        name: 'Series 1',
      },
      set: {
        __typename: 'Set',
        id: '22ff5663-706b-46fa-88cc-6a2f0d92b63c',
        name: 'Base',
      },
      tier: 'COMMON',
    },
    id: '08c543a0-6214-4b9f-905a-9c0f46d074f1',
    serialNumber: 502,
  };
}
