import { gql } from '@apollo/client';

export const GetQueueStatus = gql`
  query GET_MY_QUEUE_STATUS($searchInput: GetQueueStatusRequest) {
    getQueueStatus(input: $searchInput) {
      queue {
        name
        remaining
        startTime
        drawTime
        pending
        processed
        statusMessage
        statusMessageTime
        state
      }
      entry {
        id
        key
        queueId
        placeInLine
        turnStartAt
        tier
        joinedAt
        eligibility
        queueId
        startingPosition
      }
    }
  }
`;
