import type { ComponentStyleConfig } from 'src/theme/index';

export const Common: ComponentStyleConfig = {
  baseStyle: {
    contentWrapper: {
      height: '100%',
      width: { base: '100%', md: '50%' },
    },
  },
  parts: ['contentWrapper'],
};
