import React from 'react';
import { Slide, Box, useStyles, Flex, Divider, Link } from 'src/theme/index';

import { CrossIcon, LaLigaLogoIcon } from '../../themes/icons';
import { useTopNavContext } from './TopNav.context';
import { TopNavAuth } from './TopNavAuth';
import { TopNavIconButton } from './TopNavIconButton';
import { TopNavLink } from './TopNavLink';
import { TopNavList } from './TopNavList';
import { TopNavListItem } from './TopNavListItem';
import { TopNovMobileProps, NavItemI } from './types';

const _renderNavItems = (
  navList: Array<NavItemI>,
  trackHandler: ({ href, label }: { href: string; label: string }) => void,
) => {
  return navList.map(({ href, label }) => {
    return (
      <TopNavListItem key={label} onClick={() => trackHandler({ href, label })}>
        <TopNavLink href={href}>{label}</TopNavLink>
      </TopNavListItem>
    );
  });
};

const CustomDivider = () => {
  return <Divider my={4} color='#5B5E6C' borderColor='#5B5E6C' />;
};

export const TopNavMobile = ({
  isOpen,
  onToggle,
  navList,
  trackHandler,
  socialMedias,
}: TopNovMobileProps) => {
  const styles = useStyles();
  const { additionalControls } = useTopNavContext();
  return (
    <Slide direction='left' in={isOpen}>
      <Flex flexDirection='row' height='100%' bg='black'>
        <Box sx={styles.slideContainer}>
          <TopNavLink href='#home'>
            {/* move laliga icon outside of the component */}
            <LaLigaLogoIcon w='105px' h='24px' />{' '}
          </TopNavLink>
          <TopNavList gridArea='mid'>
            {_renderNavItems(navList, trackHandler)}
          </TopNavList>
          <CustomDivider />
          <TopNavAuth isMobile />
          <CustomDivider />
          {additionalControls}
          <CustomDivider />
          <Flex direction='row'>
            {socialMedias.map(({ icon: Icon, url, label }, i) => (
              <Link
                key={i}
                sx={styles.socialMediaIcon}
                href={url}
                target='_blank'
              >
                <Icon width='24px' height='24px' mr='16px' color='#989896' />
              </Link>
            ))}
          </Flex>
        </Box>
        <Box>
          <TopNavIconButton
            sx={styles.btnCloseMobileNav}
            icon={<CrossIcon />}
            onClick={onToggle}
          />
        </Box>
      </Flex>
    </Slide>
  );
};
