import { chakra, useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { Box, Grid, GridItem, Link, Text } from 'src/theme';
import { FOOTER_THEME_KEY } from 'src/theme/dss-skeleton/components';

import { ExternalLinkIcon, LaLigaLogoIcon } from '../../themes/icons';

export interface IFooterProps {
  bottomLinks: { href: string; label: string }[];
  copyrights: string[];
  helpLinks: { title: string; url: string }[];
  mainNav: {
    href: string;
    label: string;
  }[];
  onLinksClick: ({ label, url }: { label: string; url: string }) => void;
  onMainNavLinkClick?: ({
    label,
    href,
  }: {
    href: string;
    label: string;
  }) => void;
  onSocialIconLinkClick?: ({ url, label }) => void;
  rightPart?: React.ReactNode;
  socialMediaIcons: { icon: any; label: string; url: string }[];
}

/**
 * Footer
 */
export const Footer = (props: IFooterProps) => {
  const {
    bottomLinks,
    mainNav,
    copyrights,
    rightPart,
    helpLinks,
    socialMediaIcons,
    onMainNavLinkClick = () => {},
    onSocialIconLinkClick = () => {},
    onLinksClick = () => {},
  } = props;
  const logoIcons = [LaLigaLogoIcon];

  const styles = useMultiStyleConfig(FOOTER_THEME_KEY);

  return (
    <chakra.div sx={styles.root}>
      <Grid sx={styles.gridWrapper}>
        <GridItem sx={styles.logoIcon}>
          {logoIcons.map((Icon, i) => (
            <Icon key={i} />
          ))}
        </GridItem>
        <GridItem
          alignItems='center'
          justifyContent={{ base: 'flex-start', sm: 'flex-end' }}
          flexDir='row'
          area='socialMediaIcons'
          display='flex'
          padding={{ base: '0px 16px', md: '0px 24px' }}
        >
          {socialMediaIcons.map(({ icon: Icon, url, label }, i) => (
            <Link
              key={i}
              sx={styles.socialMediaIcon}
              href={url}
              onClick={() => onSocialIconLinkClick({ label, url })}
              target='_blank'
            >
              <Icon />
            </Link>
          ))}
        </GridItem>
        <GridItem sx={styles.linksRowWrapper}>
          <Grid sx={styles.linksRow}>
            <GridItem sx={styles.linksTextWrapper}>
              {mainNav.map(({ href = '', label = '' }) => (
                <Link
                  key={href}
                  sx={styles.linkText}
                  href={href}
                  onClick={() => onMainNavLinkClick({ href, label })}
                >
                  {label}
                </Link>
              ))}
            </GridItem>
            <GridItem sx={styles.linksWrapper}>
              {helpLinks.map(({ url, title }) => (
                <Link
                  key={url}
                  sx={styles.navLink}
                  fontFamily='mono'
                  href={url}
                  onClick={() => onLinksClick({ label: title, url })}
                >
                  {title} <ExternalLinkIcon />
                </Link>
              ))}
            </GridItem>
            <GridItem sx={styles.rightPartWrapper}>{rightPart}</GridItem>
          </Grid>
        </GridItem>
        <GridItem sx={styles.copyrightTextWrapper}>
          {copyrights.map((copyright) => (
            <Text key={copyright} marginBottom='2px'>
              {copyright}
            </Text>
          ))}
        </GridItem>
        <GridItem sx={styles.policyTerms}>
          {bottomLinks.map((link, index) => (
            <Box key={link.href}>
              {index > 0 && (
                <chakra.span
                  sx={styles.policyTermsDivider}
                  marginRight='12px'
                  marginLeft='12px'
                >
                  |
                </chakra.span>
              )}
              <Link
                textDecoration='none'
                whiteSpace='nowrap'
                href={link.href}
                onClick={() =>
                  onLinksClick({
                    label: link?.label ?? '',
                    url: link?.href ?? '',
                  })
                }
              >
                {link.label}
              </Link>
            </Box>
          ))}
        </GridItem>
      </Grid>
    </chakra.div>
  );
};
