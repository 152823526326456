import {
  isBefore,
  isAfter,
  isEqual,
  compareAsc,
  addYears,
  subYears,
  addMonths,
  subMonths,
  addDays,
  subDays,
  addHours,
  subHours,
  addMinutes,
  subMinutes,
  addSeconds,
  subSeconds,
  format,
  formatISO,
  differenceInYears,
  sub,
} from 'date-fns';

export * from 'date-fns';

export type DateArg = number | Date;

export const gethmmaaMMMdyyyy = (date: DateArg) =>
  format(date, `h:mm aa MMMM d, yyyy`);

export const getMMMdyyyyhaaDate = (date: DateArg) =>
  format(date, `MMM d yyyy 'at' h aa`);

export const getiiiiMMMdHHmmaDate = (date: DateArg) =>
  format(date, `iiii MMM d 'at' HH':'mm a`);

export const getYYYYMMdd = (date: DateArg) => format(date, `yyyy-MM-dd`);
export const getMMddHH = (date: DateArg) => format(date, `MM/dd, haa`);

export const getMMMMdo = (date: DateArg) => format(date, 'MMMM do');
export const getddMMMyyyy = (date: DateArg) => format(date, 'dd MMM yyyy');
export const getMMMMdyyyy = (date: DateArg) => format(date, 'MMMM d, yyyy');
export const getMMMdyyyyhmmaa = (date: DateArg) =>
  format(date, 'MMM d, yyyy h:mm aa');
export const getMMMdyy = (date: DateArg) => format(date, 'MMM d/yy');
export const getMMMdyyyy = (date: Date) => format(date, 'MMM d, yyyy');
export const getMMMMyyyy = (date: Date) => format(date, 'MMMM, yyyy');

export const isFutureDate = (date: Date): boolean =>
  compareAsc(date, new Date()) === 1;

export const isPastDate = (date: Date): boolean =>
  compareAsc(date, new Date()) === -1;

export const getAgeFromBirthDate = (date: Date) =>
  differenceInYears(new Date(), new Date(date));

/**
 * Calculate the players age at the time of the moment
 *
 * @param birthdate - the players birthdate
 * @param gameDate - the date of the moment's game
 * @returns {age}
 */
export const getAgeAtGameDate = (
  birthdate: Date | string,
  gameDate: Date | string,
) => Math.abs(differenceInYears(new Date(gameDate), new Date(birthdate)));

export const isBeforeOrEqual = (t1, t2) => isBefore(t1, t2) || isEqual(t1, t2);

export const isEqualOrAfter = (t1, t2) => isEqual(t1, t2) || isAfter(t1, t2);

export const dateExampleGenerator = (
  amount: number,
  denomination: 'years' | 'months' | 'days' | 'hours' | 'minutes' | 'seconds',
  direction: 'future' | 'past',
): string => {
  const func = {
    days: direction === 'future' ? addDays : subDays,
    hours: direction === 'future' ? addHours : subHours,
    minutes: direction === 'future' ? addMinutes : subMinutes,
    months: direction === 'future' ? addMonths : subMonths,
    seconds: direction === 'future' ? addSeconds : subSeconds,
    years: direction === 'future' ? addYears : subYears,
  }[denomination];

  return formatISO(func(new Date(Date.now()), amount));
};

export const getDynamicBirthday = (years: number, days: number) => {
  const birthDay = sub(new Date(), {
    days,
    years,
  });

  return birthDay;
};

export type FormatTimeLeftRoundedReturnType = {
  day: number;
  hour: number;
  minute: number;
  second: number;
};
export const formatTimeLeftRounded = (
  timeLeftInMilliseconds: number,
): FormatTimeLeftRoundedReturnType => {
  let timeLeft = { day: 0, hour: 0, minute: 0, second: 0 };

  if (timeLeftInMilliseconds > 0) {
    timeLeft = {
      day: Math.floor(timeLeftInMilliseconds / (1000 * 60 * 60 * 24)),
      hour: Math.floor((timeLeftInMilliseconds / (1000 * 60 * 60)) % 24),
      minute: Math.floor((timeLeftInMilliseconds / 1000 / 60) % 60),
      second: Math.floor((timeLeftInMilliseconds / 1000) % 60),
    };
  }

  return timeLeft;
};
