import { gql } from '@apollo/client';
import { DistributionOddsFragment } from 'src/edge/NFT__deprecated/queries/fragments/DistributionOdds';
import { EditionFragment } from 'src/edge/NFT__deprecated/queries/fragments/Edition';
import { QueueFragment } from 'src/edge/Queue';
import { RequirementFragment } from 'src/edge/Requirement';

export const CompleteDistributionFragment = gql`
  fragment DistributionFragment on Distribution {
    id
    flowID
    title
    code
    description
    txHash
    state
    tier
    price
    numberOfPackSlots
    images {
      type
      url
    }
    videos {
      type
      url
    }
    totalSupply
    startTime
    endTime
    distributionEditionsV2 {
      edges {
        node {
          id
          distributionFlowID
          editionFlowID
          serialNumStart
          serialNumEnd
          count
          edition {
            ...EditionFragment
          }
        }
      }
    }
    queue {
      ...QueueFragment
    }
    requirements {
      ...RequirementFragment
    }
    packOdds {
      ...DistributionOddsFragment
    }
  }
  ${QueueFragment}
  ${RequirementFragment}
  ${DistributionOddsFragment}
  ${EditionFragment}
`;
