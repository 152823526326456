import { cdc } from '@onflow/fcl';

const CODE = cdc`import FungibleToken from 0xFungibleTokenContractAddress
import NonFungibleToken from 0xNonFungibleTokenContractAddress
import DapperUtilityCoin from 0xDapperUtilityCoinContractAddress
import Golazos from 0xNFTContractAddress
import NFTStorefrontV2 from 0xNFTStorefrontContractAddress
import MetadataViews from 0xMetadataViews
import TokenForwarding from 0xTokenForwarding

// This transaction facilitates the listing of an NFT with the StorefrontV2 contract
// 
// Collection Identifier: Golazos
// Vault Identifier: duc
//
// Version: 0.1.1

/// 'saleItemID' - ID of the NFT that is put on sale by the seller.
/// 'saleItemPrice' - Amount of tokens (FT) buyer needs to pay for the purchase of listed NFT.
/// 'expiry' - Unix timestamp at which created listing become expired.

transaction(saleItemID: UInt64, saleItemPrice: UFix64, expiry: UInt64) {
    var ftReceiver: Capability<&{FungibleToken.Receiver}>?
    var nftProvider: Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Collection}>?
    let storefront: auth(NFTStorefrontV2.CreateListing) &NFTStorefrontV2.Storefront
    var saleCuts: [NFTStorefrontV2.SaleCut]
    var marketplacesCapability: [Capability<&{FungibleToken.Receiver}>]

    /// 'customID' - Optional string to represent identifier of the dapp.
    let customID: String
    /// 'commissionAmount' - Commission amount that will be taken away by the purchase facilitator i.e marketplacesAddress.
    let commissionAmount: UFix64
    /// 'marketplacesAddress' - List of addresses that are allowed to get the commission.
    let marketplaceAddress: [Address]
    // we only ever want to use DapperUtilityCoin
    let universalDucReceiver: Address

    prepare(acct: auth(Storage, Capabilities) &Account) {
        /// 'customID' - Optional string to represent identifier of the dapp.
        self.customID = "DAPPER_MARKETPLACE"
        /// 'commissionAmount' - Commission amount that will be taken away by the purchase facilitator i.e marketplacesAddress.
        self.commissionAmount = 0.0
        /// 'marketplacesAddress' - List of addresses that are allowed to get the commission.
        self.marketplaceAddress = [0xMerchantAccountAddress]
        // we only ever want to use DapperUtilityCoin
        self.universalDucReceiver = 0xDapperUtilityCoinContractAddress2
        
        self.saleCuts = []
        self.marketplacesCapability = []
        let GolazosPrivateCollectionPath = /private/GolazosNFTCollectionProviderForNFTStorefront

        // ************************* Handling of DUC Recevier *************************** //
        
        // Fetch the capability of the universal DUC receiver
        let recipient = getAccount(self.universalDucReceiver).capabilities.get<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)!
        assert(recipient.borrow() != nil, message: "Missing or mis-typed Fungible Token receiver for the DUC recipient")

        self.ftReceiver = acct.capabilities.get<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)

        // Validate the marketplaces capability before submiting to 'createListing'.
        for mp in self.marketplaceAddress {
            let marketplaceReceiver = getAccount(mp).capabilities.get<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
            assert(marketplaceReceiver.borrow() != nil && marketplaceReceiver.borrow()!.isInstance(Type<@TokenForwarding.Forwarder>()), message: "Marketplaces does not possess the valid receiver type for DUC")
            self.marketplacesCapability.append(marketplaceReceiver)
        }

        // *************************** Seller account interactions  *************************** //

        if !acct.capabilities.get<&Golazos.Collection>(Golazos.CollectionPublicPath)!.check() {
            acct.capabilities.unpublish(Golazos.CollectionPublicPath)
            acct.capabilities.publish(
                acct.capabilities.storage.issue<&Golazos.Collection>(Golazos.CollectionStoragePath),
                at: Golazos.CollectionPublicPath
            )
        }

        self.nftProvider = acct.storage.copy<Capability<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Collection}>>(from: /storage/GolazosCollectionStorageCap)

        if self.nftProvider == nil {
            self.nftProvider = acct.capabilities.storage.issue<auth(NonFungibleToken.Withdraw) &{NonFungibleToken.Collection}>(Golazos.CollectionStoragePath)
            acct.storage.save(self.nftProvider!, to: /storage/GolazosCollectionStorageCap)
        }

        let collectionRef = acct.capabilities.borrow<&Golazos.Collection>(Golazos.CollectionPublicPath)
            ?? panic("Could not borrow a reference to the collection")
        
        var totalRoyaltyCut = 0.0
        let effectiveSaleItemPrice = saleItemPrice - self.commissionAmount
        
        let nft = collectionRef.borrowMomentNFT(id: saleItemID)!
        // Check whether the NFT implements the MetadataResolver or not.
        if nft.getViews().contains(Type<MetadataViews.Royalties>()) {
            let royaltiesRef = nft.resolveView(Type<MetadataViews.Royalties>()) ?? panic("Unable to retrieve the royalties")
            let royalties = (royaltiesRef as! MetadataViews.Royalties).getRoyalties()
            for royalty in royalties {
                let royaltyReceiver = royalty.receiver
                assert(royaltyReceiver.borrow() != nil && royaltyReceiver.borrow()!.isInstance(Type<@TokenForwarding.Forwarder>()), message: "Royalty receiver does not have a valid receiver type")
                self.saleCuts.append(NFTStorefrontV2.SaleCut(receiver: royalty.receiver, amount: royalty.cut * effectiveSaleItemPrice))
                totalRoyaltyCut = totalRoyaltyCut + royalty.cut * effectiveSaleItemPrice
            }
        }
        
        // Append the cut for the seller.
        self.saleCuts.append(NFTStorefrontV2.SaleCut(
            receiver: self.ftReceiver!,
            amount: effectiveSaleItemPrice - totalRoyaltyCut
        ))

        if acct.storage.borrow<&NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath) == nil {
            // Create a new empty Storefront
            let storefront <- NFTStorefrontV2.createStorefront() as! @NFTStorefrontV2.Storefront
            // save it to the account
            acct.storage.save(<-storefront, to: NFTStorefrontV2.StorefrontStoragePath)
            // create a public capability for the Storefront
             acct.capabilities.publish(
                acct.capabilities.storage.issue<&NFTStorefrontV2.Storefront>(NFTStorefrontV2.StorefrontStoragePath),
                at: NFTStorefrontV2.StorefrontPublicPath
            )
        }
        self.storefront = acct.storage.borrow<auth(NFTStorefrontV2.CreateListing) &NFTStorefrontV2.Storefront>(from: NFTStorefrontV2.StorefrontStoragePath)!
    }

    execute {
        // Create listing
        self.storefront.createListing(
            nftProviderCapability: self.nftProvider!,
            nftType: Type<@Golazos.NFT>(),
            nftID: saleItemID,
            salePaymentVaultType: Type<@DapperUtilityCoin.Vault>(),
            saleCuts: self.saleCuts,
            marketplacesCapability: self.marketplacesCapability.length == 0 ? nil : self.marketplacesCapability,
            customID: self.customID,
            commissionAmount: self.commissionAmount,
            expiry: expiry
        )
    }
}`;

export { CODE };
