import { gql } from '@apollo/client';

export const SEARCH_NFT_WITH_LISTING = gql`
  query searchGolazosNft__nftWithListing(
    $query: String
    $filters: [GolazosNftFilter!]
  ) {
    searchGolazosNft(searchInput: { query: $query, filters: $filters }) {
      edges {
        node {
          id
          listing {
            expiry
            listing_resource_id
            sales_price
            storefront_address
          }
          owner_address
        }
      }
    }
  }
`;

export type SearchNftWithListingsInput = {
  nftID: string;
  typeName: string;
};

export type SearchNftWithListingFilters = {
  filters: {
    id: {
      eq: string;
    };
    type_name: {
      eq: string;
    };
  };
};

export const SEARCH_NFT_WITH_LISTING_VARIABLES = ({
  nftID,
  typeName,
}: SearchNftWithListingsInput): SearchNftWithListingFilters => ({
  filters: {
    id: {
      eq: nftID,
    },
    type_name: {
      eq: typeName,
    },
  },
});
