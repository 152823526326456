import { home as gatingRoute } from '@dapperlabs/core-fe';
import { useRouter } from 'next/router';
import { useEffect, useState, useMemo } from 'react';
import { useSession } from 'src/edge/session';
import { useFeatureFlags } from 'src/lib/launchDarkly';
import Maintenance from 'src/pages/maintenance';

const GATED_ROUTES = [];

const isPageRouteGated = (route) => GATED_ROUTES.includes(route);

/**
 * This component watches for a takeover flag (ex. comingSoon) and then redirects.
 * This ensures people who are sitting on active client get forced off as necessary.
 */
export const GatingWrapper = ({ children }) => {
  const router = useRouter();
  const { fullMaintenance = false, gating = false } = useFeatureFlags();
  const { state: sessionState } = useSession();
  const routeName = router.pathname;

  const isUserGatedForRoute = useMemo(
    () => () => {
      const isUnAuthenticatedGatedRoute =
        sessionState.matches('UNAUTHENTICATED') && isPageRouteGated(routeName);
      const isAuthenticatedGatedRoute =
        sessionState.matches('AUTHENTICATED') &&
        gating &&
        isPageRouteGated(routeName);
      return isUnAuthenticatedGatedRoute || isAuthenticatedGatedRoute;
    },
    [gating, routeName, sessionState],
  );

  const INITIAL_GATED_VAL = isUserGatedForRoute();

  const [isPageGated, setIsPageGated] = useState(INITIAL_GATED_VAL);

  useEffect(() => {
    const isGated = isUserGatedForRoute();

    setIsPageGated(isGated);
  }, [gating, isUserGatedForRoute, routeName]);

  useEffect(() => {
    if (isPageGated) {
      router.push({
        pathname: gatingRoute(),
        query: {
          showGatedModal: true,
        },
      });
    }
  }, [isPageGated]);

  /* Need to check for gatedState before and after transition to avoid flashes of gated page before user to transitioned
    Can potentially replace null value with a loading spinner
  */

  if (fullMaintenance) return <Maintenance />;
  return isPageGated ? null : children;
};
