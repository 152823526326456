import React from 'react';

import { useSession } from 'src/edge/session';
import { useFeatureFlags } from 'src/lib/launchDarkly';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';
import { useTranslation } from 'src/lib/translations/useTranslation';
import { useRewardPassAnalytics } from 'src/modules/RewardPass/hooks';
import { Button, Flex, Text } from 'src/theme';

export const Auth = ({
  trackingProperties,
}: {
  trackingProperties?: { [key: string]: string | number | boolean };
}) => {
  const { t } = useTranslation();
  const { send, state } = useSession();
  const { authMaintenance } = useFeatureFlags();
  const { track } = useRewardPassAnalytics();

  const handleSignIn = () => {
    send('SIGN_IN');
    track(SEGMENT_EVENTS_ID_ENUM.PLAYBOOK_SIGN_IN_CLICK, trackingProperties);
  };

  return (
    <Flex justifyContent='center' direction='column' gap='3'>
      <Flex alignItems='center' justifyContent='center' gap='2'>
        <Button
          disabled={authMaintenance}
          isLoading={state.value === 'LOADING'}
          onClick={handleSignIn}
          size='sm'
          variant='ghost'
        >
          {t('NavigationBar.Auth.login')}
        </Button>
        <Button
          disabled={authMaintenance}
          isLoading={state.value === 'LOADING'}
          onClick={handleSignIn}
          size='sm'
        >
          {t('NavigationBar.Auth.signUp')}
        </Button>
      </Flex>
      <Text align='center' size='sm'>
        {t('playbook.auth.description')}
      </Text>
    </Flex>
  );
};
