import { ApolloClient } from '@apollo/client';
import { NextRouter } from 'next/router';

import { getMyProfile_getMyProfile } from 'src/edge/getMyProfile/__generated__/getMyProfile';
import {
  searchAllDaySeasonalNft_searchAllDaySeasonalNft_edges_node,
  searchAllDaySeasonalNft_searchAllDaySeasonalNft_edges_node_edition,
} from 'src/modules/SeasonalNFT';

export enum FS {
  AUTHORIZING = 'AUTHORIZING',
  AWARD_ACCOLADE = 'AWARD_ACCOLADE',
  CLAIM_REWARD = 'CLAIM_REWARD',
  ERROR = 'ERROR',
  EXECUTING = 'EXECUTING',
  FINALIZING = 'FINALIZING',
  FTUE_LEGACY = 'FTUE_LEGACY',
  FTUE_MODAL = 'FTUE_MODAL',
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  LOADING_ASSETS = 'LOADING_ASSETS',
  PREPARING_ACCOUNT = 'PREPARING_ACCOUNT',
  SEALING = 'SEALING',
  SIGNING_UP = 'SIGNING_UP',
  SIGN_UP = 'SIGN_UP',
  STORING_TEAM = 'STORING_TEAM',
  SUCCESS = 'SUCCESS',
  TEAM_PICKER = 'TEAM_PICKER',
  TERMS_CONDITIONS = 'TERMS_CONDITIONS',
  TRANSACTION = 'TRANSACTION',
}
export type FStype = typeof FS;

export enum EVENTS {
  CLAIM_REWARD = 'CLAIM_REWARD',
  GO_TO_TOS = 'GO_TO_TOS',
  NEXT = 'NEXT',
  RESET = 'RESET',
  SELECT_TEAM = 'SELECT_TEAM',
  SIGN_UP = 'SIGN_UP',
  START = 'START',
  UPDATE_USER_DATA = 'UPDATE_USER_DATA',
}

export type Events = {
  data?: any;
  returnTo?: string;
  type: EVENTS | keyof typeof EVENTS;
};

export interface Context {
  client: ApolloClient<any>;
  error: any;
  favoriteTeamId: string;
  flags: undefined;
  interaction: any;
  profile: getMyProfile_getMyProfile;
  router: NextRouter;
  seasonalNFT: searchAllDaySeasonalNft_searchAllDaySeasonalNft_edges_node;
  seasonalNFTEdition: searchAllDaySeasonalNft_searchAllDaySeasonalNft_edges_node_edition;
  showAccoladeToast: (accolade) => void;
  track: (trackingId: string, trackingProperties?: any) => void;
  txId: string; // TODO https://github.com/dapperlabs/nfl-app/issues/2015: set type to ReturnType<typeof getInteraction>
}

export interface Schema {
  states: any;
}

export interface TypeState {
  context: Context;
  value: any;
}
