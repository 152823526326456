import type { ComponentStyleConfig } from 'src/theme';

export const TransactionsModalPriceSectionTheme: ComponentStyleConfig = {
  baseStyle: {
    divider: {
      background: '#393B45',
      height: '1px',
      margin: '0 12px',
      width: '100%',
    },
    label: {
      color: '#8A94A6',
      minWidth: 'fit-content',
    },
    price: {
      color: '#8A94A6',
      fontWeight: '600',
      letterSpacing: '0.3px',
      minWidth: 'fit-content',
    },
    priceItemContainer: {
      alignItems: 'center',
      fontFamily: 'mono',
      fontSize: '14px',
      fontWeight: '400',
      height: '20px',
      justifyContent: 'center',
      letterSpacing: '0.16px',
      lineHeight: '20px',
    },
  },
  parts: [],
};
