import { ApolloClient } from '@apollo/client';
import { tApp } from 'src/modules/PackOpeningExperience/types';

export enum FS {
  ALL_REVEALED = 'ALL_REVEALED',
  AUTHORIZING = 'AUTHORIZING',
  ERROR = 'ERROR',
  EXECUTING = 'EXECUTING',
  FINALIZING = 'FINALIZING',
  FINISHED = 'FINISHED',
  IDLE = 'IDLE',
  INITIAL_PRELOAD = 'INITIAL_PRELOAD',
  LOAD_NFTS = 'LOAD_NFTS',
  POLLING = 'POLLING',
  PRELOAD_ASSETS = 'PRELOAD_ASSETS',
  READY_TO_OPEN = 'READY_TO_OPEN',
  REVEAL = 'REVEAL',
  REVEALING = 'REVEALING',
  SEALING = 'SEALING',
  TEARING_OPEN = 'TEARING_OPEN',
  TRANSACTION = 'TRANSACTION',
}
export type FStype = typeof FS;

export enum EVENTS {
  CLAIM = 'CLAIM',
  DONE = 'DONE',
  FORCE_REVEAL = 'FORCE_REVEAL',
  OPEN_PACK = 'OPEN_PACK',
  POLL_ERROR = 'POLL_ERROR',
  POLL_STOP = 'POLL_STOP',
  POLL_SUCCESS = 'POLL_SUCCESS',
  PRELOAD_SUCCESS = 'PRELOAD_SUCCESS',
  REPLAY = 'REPLAY',
  RETRY = 'RETRY',
  TORN_OPEN = 'TORN_OPEN',
  // @NOTE: 'xstate.update' is the event that is
  // sent when a spawned actor calls sendUpdate()
  XSTATE_UPDATE = 'xstate.update',
}
export type EVENTStype = typeof EVENTS;

export type Events = { data?: any; type: EVENTS };

export enum ERRORS {}

export enum ERROR_MESSAGES {}

export enum PROCESSING_MESSAGES {}

export interface Context {
  ERRORS: typeof ERRORS;
  ERROR_MESSAGES: typeof ERROR_MESSAGES;
  EVENTS: typeof EVENTS;
  FS: typeof FS;

  MAX_POLL_DURATION: number;
  PROCESSING_MESSAGES: typeof PROCESSING_MESSAGES;

  app: tApp;
  client: ApolloClient<any>;
  distribution: any;

  error?: {
    data: any;
    type: ERRORS;
    value: ERROR_MESSAGES;
  };

  getPreload: (string) => {};
  interaction: any /* ReturnType<typeof getInteraction> */;

  isPreloadError: boolean;

  media: any;
  momentNFTActors: {
    id: string;
    ref: any;
  }[];
  momentNFTs: {
    id: string;
  }[];

  nfts: [any];

  packNFT: any;

  preloads: any;

  transactionID: string;
}

export interface Schema {
  states: any;
}

export interface TypeState {
  context: Context;
  value: any;
}
