import {
  useTrackPage,
  captureException,
  setExceptionUser,
} from '@dapperlabs/core-fe';
import { useAnalytics } from '@dapperlabs/react-analytics';
import { useState, useEffect } from 'react';
import { useSession } from 'src/edge/session';

export const ServiceIdentifier = () => {
  const [isIdentified, setIsIdentified] = useState(false);
  const { identify, alias } = useAnalytics();
  const { state } = useSession();

  // Server Side Analytics
  useTrackPage();

  useEffect(() => {
    if (isIdentified) return;
    if (state.matches('LOADING')) return;

    const profile = state.context.profile;
    const guest = state.context.guest;

    try {
      if (profile?.flowAddress) {
        const userId = profile.flowAddress;
        setIsIdentified(true);
        alias(userId);
        identify(userId, profile);
        setExceptionUser(userId);
      } else if (guest?.flowAddress) {
        const userId = guest.flowAddress;
        setIsIdentified(true);
        alias(userId);
        identify(userId, { isGuest: true });
        setExceptionUser(userId);
      }
    } catch (error) {
      captureException(error);
    }
  }, [isIdentified, alias, identify, state]);
  return null;
};
