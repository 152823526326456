import type { ComponentStyleConfig } from 'src/theme/index';

export const Content: ComponentStyleConfig = {
  baseStyle: {
    button: {
      fontFamily: 'mono',
      fontSize: '18px',
      fontWeight: '400',
      height: 12,
      letterSpacing: '-0.16px',
      maxWidth: { base: '100%', md: '420px' },
      mt: 6,
    },
    container: {
      flexDirection: 'column',
      margin: { base: '32px 16px', lg: 'auto 100px', md: 'auto' },
      py: { base: 4, lg: 12, md: 8 },
      width: { base: '100%', md: '72%' },
    },
    description: {
      '> a': {
        textDecoration: 'underline',
      },
      color: '#F8F8F8',
      fontSize: { base: '14px', md: '18px' },
      fontWeight: '500',
      letterSpacing: { base: '0.16px', md: '-0.16px' },
      lineHeight: { base: '20px', md: '24px' },
      mt: 4,
    },
    image: {
      maxWidth: '100%',
      objectFit: 'cover',
    },
    link: {
      marginTop: '20px',
      textDecoration: 'underline',
    },
    mainDescription: {
      '> a': {
        textDecoration: 'underline',
      },
      color: '#F8F8F8',
      fontFamily: 'heading',
      fontSize: { base: '24px', md: '28px' },
      fontWeight: '500',
      letterSpacing: { base: '-0.32px', md: '-0.64px' },
      lineHeight: { base: '24px', md: '24px' },
      mt: 4,
    },
    mainTitle: {
      color: '#F8F8F8',
      fontFamily: 'poster',
      fontSize: { base: '48px', md: '70px' },
      fontWeight: '500',
      lineHeight: { base: '48px', md: '70px' },
    },
    title: {
      color: '#F8F8F8',
      fontFamily: 'heading',
      fontSize: { base: '28px', md: '36px' },
      fontWeight: '500',
      letterSpacing: { base: '-0.64px', md: '-1px' },
      lineHeight: { base: '28px', md: '36px' },
    },
  },
  parts: ['container', 'title', 'description', 'button', 'image', 'link'],
};
