// @ts-nocheck
import { TimelineItemI } from './types';

const INACCURACY = 40;
const DEFAULT_TIMELINE_WIDTH = 450;

export const calcPositionRegardingOtherEvents = (
  item: TimelineItemI,
  index,
  arr,
) => {
  if (
    index !== 0 &&
    item.min === arr[index - 1].min &&
    item.teamName !== arr[index - 1].teamName
  ) {
    item.position = arr[index - 1].position;
    return;
  }
  if (index !== 0 && item.position - arr[index - 1].position < INACCURACY) {
    item.position = arr[index - 1].position + INACCURACY;
  }
};

export const getMaxPosition = (arr: Array<TimelineItemI>) =>
  arr.length > 0
    ? Array.isArray(arr[arr.length - 1])
      ? arr[arr.length - 1][0].position
      : arr[arr.length - 1].position
    : DEFAULT_TIMELINE_WIDTH;

export const mergeSameEvents = (timelinePeriod) => {
  return timelinePeriod.reduce(
    (
      accum: Array<TimelineItemI | Array<TimelineItemI>>,
      item: TimelineItemI,
    ) => {
      if (accum.length === 0) {
        return [item];
      } else {
        const newAccum = [...accum];
        const prevElem = Array.isArray(accum[accum.length - 1])
          ? accum[accum.length - 1][0]
          : accum[accum.length - 1];
        if (
          item.min === prevElem.min &&
          item.eventType === prevElem.eventType &&
          item.teamName === prevElem.teamName
        ) {
          Array.isArray(accum[accum.length - 1])
            ? newAccum[accum.length - 1].push(item)
            : (newAccum[accum.length - 1] = [prevElem, item]);
        } else {
          newAccum.push(item);
        }
        return newAccum;
      }
    },
    [],
  );
};
