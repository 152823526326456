import { ASSET_TYPES } from 'src/edge/Edition/constants';
import { imageOptimizerCDN } from 'src/lib/ImageOptimizer';

export const getMomentMedia = ({
  // @TODO: should be lowercased edition
  Edition,
  assetType,
  height,
  width,
  quality = 50,
}: {
  Edition: { play: { metadata: { play_data_id: string } } };
  assetType: ASSET_TYPES;
  height?: number;
  quality?: number;
  width?: number;
}) => {
  const playId = Edition?.play?.metadata?.play_data_id;
  if (!playId) return null;

  const src = `https://assets.laligagolazos.com/editions/${playId}/play_${playId}__capture_${assetType}`;

  if (isVideo(assetType)) return src;

  const optimizedImage = imageOptimizerCDN({
    // @ts-ignore
    format: 'webp',
    height,
    quality,
    src,
    width,
  });
  return optimizedImage;
};

function isVideo(assetType: ASSET_TYPES) {
  return [
    ASSET_TYPES.ANIMATED_VIDEO_IDLE_BLACK_1080_1080_DEFAULT,
    ASSET_TYPES.ANIMATED_VIDEO_IDLE_BLACK_1080_1080_ES,
    ASSET_TYPES.ANIMATED_VIDEO_POPOUT_BLACK_1080_1080_DEFAULT,
    ASSET_TYPES.ANIMATED_VIDEO_POPOUT_BLACK_1080_1080_ES,
    ASSET_TYPES.ANIMATED_VIDEO_POPOUT_BLACK_720_1280_DEFAULT,
    ASSET_TYPES.ANIMATED_VIDEO_POPOUT_BLACK_720_1280_ES,
  ].includes(assetType);
}

export function getMintSize(edition) {
  if (!edition) return '';
  const { currentMintSize, maxMintSize } = edition;
  return currentMintSize === maxMintSize
    ? currentMintSize
    : `${currentMintSize}+`;
}

export function calcEditionTypeAndPercentage(params, size) {
  if (size <= 0) return [];
  const editionDetailsArray = params.map(({ value, Icon }) => {
    return { Icon, value: Math.round((value * 100) / size) };
  });
  const total = editionDetailsArray.reduce(
    (acc, { value }) => (acc += value),
    0,
  );
  if (total === 100) return editionDetailsArray;
  if (total > 100) {
    let leftovers = total - 100;
    return editionDetailsArray.map((details, i) => {
      const { value } = details;
      const decimal = ((params[i].value / size) * 100) % 1;
      if (leftovers === 0) return details;
      if (decimal >= 0.5) {
        leftovers -= 1;
        return { ...details, value: value - 1 };
      }
      return details;
    });
  }

  if (total < 100) {
    let leftovers = 100 - total;
    return editionDetailsArray.map((details, i) => {
      const decimal = ((params[i].value / size) * 100) % 1;
      const { value } = details;

      if (leftovers === 0) return details;
      if (decimal < 0.5) {
        leftovers -= 1;
        return { ...details, value: value + 1 };
      }
      return details;
    });
  }
  return editionDetailsArray;
}
