/* eslint-disable sort-keys-fix/sort-keys-fix */

import { buyItem } from 'src/edge/flow';
import { Context as PurchaseContext } from 'src/edge/purchase';
import { getMachine } from 'src/lib/xstate/utils';
import { MachineConfig, send } from 'xstate';

import { actions, guards, services } from './options';
import { FS, EVENTS, Context, Schema, Events } from './types';

const MAX_POLL_DURATION = 1000 * 60 * 10;

// https://stately.ai/viz/c9d3320c-8b50-413f-8a10-1b978ca353fd
export const definition: MachineConfig<Context, Schema, Events> = {
  id: 'purchase',
  context: {
    EVENTS,
    FS,
    buyerAddress: undefined,
    client: undefined,
    distribution: undefined,
    error: undefined,
    interaction: undefined,
    nftResourceIdentifer: undefined,
    packNFT: undefined,
    packNFTID: undefined,
    price: undefined,
    purchaseTx: buyItem,
    sellerAddress: undefined,
    transactionID: undefined,
    isCustomerSupportPack: undefined,
  },
  initial: 'RESERVING',
  states: {
    RESERVING: {
      invoke: {
        onDone: {
          actions: 'assignReservationData',
          target: 'IDLE',
        },
        onError: {
          actions: 'assignError',
          target: `ERROR`,
        },
        src: (context) => {
          const { isCustomerSupportPack } = context;
          if (isCustomerSupportPack) {
            return services.reserveCustomerSupportPack(context);
          } else {
            return services.reservePackNFT(context);
          }
        },
      },
    },
    IDLE: {
      on: {
        AUTHORIZE: 'TRANSACTION',
      },
    },
    TRANSACTION: {
      initial: 'AUTHORIZING',
      states: {
        ERROR: {
          entry: 'assignError',
          on: {
            AUTHORIZE: '#purchase.RESERVING',
          },
        },
        AUTHORIZING: {
          invoke: {
            onDone: {
              actions: ['assignTransactionID'],
              target: 'FINALIZING',
            },
            onError: {
              actions: 'assignError',
              target: `#purchase.TRANSACTION.ERROR`,
            },
            src: 'sendTransaction',
          },
        },
        FINALIZING: {
          invoke: {
            onDone: { target: 'EXECUTING' },
            onError: {
              actions: 'assignError',
              target: `#purchase.TRANSACTION.ERROR`,
            },
            src: 'awaitTransactionFinalized',
          },
        },
        EXECUTING: {
          invoke: {
            onDone: { target: 'SEALING' },
            onError: {
              actions: 'assignError',
              target: `#purchase.TRANSACTION.ERROR`,
            },
            src: 'awaitTransactionExecuted',
          },
        },
        SEALING: {
          invoke: {
            onDone: {
              target: `#purchase.POLLING`,
            },
            onError: {
              actions: 'assignError',
              target: `#purchase.TRANSACTION.ERROR`,
            },
            src: 'awaitTransactionSealed',
          },
        },
      },
    },
    POLLING: {
      after: {
        [MAX_POLL_DURATION]: {
          actions: send('STOP', { to: 'poll' }),
        },
      },
      invoke: {
        id: 'poll',
        onError: {
          actions: 'assignError',
          target: 'ERROR',
        },
        src: 'pollPurchaseStatus',
      },
      on: {
        POLL_ERROR: {
          actions: 'assignError',
          target: 'ERROR',
        },
        POLL_SUCCESS: {
          actions: 'assignPackNFT',
          target: 'SUCCESS',
        },
      },
    },
    SUCCESS: {
      data: {
        packNFT: ({ packNFT }: Context) => packNFT,
      },
      type: 'final',
    },
    ERROR: {
      on: {
        AUTHORIZE: 'RESERVING',
      },
    },
  },
};

type BuyMachineParams = {
  custom?: any;
};
export const getBuyMachine = ({ custom = {} }: BuyMachineParams = {}) => {
  return getMachine<Partial<PurchaseContext>>(
    definition,
    {
      context: {
        EVENTS,
        FS,
        purchaseTx: buyItem,
        ...custom.context,
      },
    },
    {
      actions,
      custom,
      guards,
      services: {
        ...services,
        ...custom?.options?.services,
      },
    },
  );
};
