import { home } from '@dapperlabs/core-fe';
import { useRouter } from 'next/router';
import { useTranslation } from 'src/lib/translations';
import { Button, Container, Link, Heading } from 'src/theme';

export const ErrorPage = ({ title }: { title: string }) => {
  const { t } = useTranslation();
  const { push } = useRouter();

  return (
    <Container
      justifyContent='center'
      flexDirection='column'
      gridGap={4}
      display='flex'
      height='100%'
    >
      <Heading textAlign='center' size='md'>
        {title}
      </Heading>
      <Button
        as={Link}
        mt={2}
        href={home()}
        onClick={() => {
          push(home()).then(() => window.location.reload());
        }}
        size='md'
        variant='solid'
      >
        {t('ErrorPage.backHome')}
      </Button>
    </Container>
  );
};
