import { useRouter } from 'next/router';
import React, { useEffect, useRef } from 'react';
import {
  Text,
  Button,
  Flex,
  useMultiStyleConfig,
  Link,
  Box,
  ChakraNextImage,
} from 'src/theme';

import { AssetCarousel, useVideo } from '../';
import { PlayFullVideoIcon, CalendarIco } from '../../themes/icons';
import { BANNER_SECTION_COMPONENTS, BANNER_SECTION_CONTENT } from './themes';
import { DateOpeningProps, BannerContentProps, VideoI, MediasI } from './types';

enum IMAGE_POSITION {
  bottom = 'auto auto 0 auto',
  left = 'auto auto auto 0',
  right = 'auto 0 auto auto',
  top = '0 auto auto auto',
}

const Media = ({ medias, styles, heightImage, stickedPosition }: MediasI) => {
  return medias.length <= 1 ? (
    <ChakraNextImage
      sx={{
        ...styles.image,
        width: { base: '100%!important', md: 'initial!important' },
      }}
      maxHeight={heightImage}
      margin={IMAGE_POSITION[stickedPosition]}
      alt='banner-image'
      src={medias[0].src}
    />
  ) : (
    <AssetCarousel
      assets={medias}
      carouselId='banner-carousel'
      imageProps={{ sx: styles.image }}
      showControls
      showNav
      videoProps={{ sx: styles.video }}
    />
  );
};

const Video = ({ media, copies, trackHandler }: VideoI) => {
  const { fullscreenButton } = useMultiStyleConfig(BANNER_SECTION_COMPONENTS);
  const { fullscreenMediaUrl, urlList, placeholderImage } = media;
  const { view, enterFullScreen } = useVideo({
    fullScreenVideoSrc: fullscreenMediaUrl,
    id: 'banner-video',
    previewSrc: placeholderImage,
    showLanguagePicker: false,
    videoProps: {
      loop: true,
      width: '100%',
    },
    videoSrc: urlList?.[0].src,
  });
  return (
    <Flex
      position='relative'
      align='center'
      justifyContent='center'
      overflow='hidden'
      width='100%'
    >
      {fullscreenMediaUrl && (
        <Flex
          as='button'
          sx={fullscreenButton}
          onClick={() => {
            enterFullScreen();
            trackHandler && trackHandler({ item_label: 'watch_video' });
          }}
        >
          <PlayFullVideoIcon />
          {copies.watchFullVideoCTA}
        </Flex>
      )}
      {view}
    </Flex>
  );
};

const DapperSignature = () => {
  const styles = useMultiStyleConfig(BANNER_SECTION_COMPONENTS);
  return (
    <Flex sx={styles.signatureContainer}>
      <Text sx={styles.signatureText}>Powered by</Text>
      <Text sx={styles.signatureCompanyName}>Dapper</Text>
    </Flex>
  );
};

const DateOpening = ({ announcement }: DateOpeningProps) => {
  const styles = useMultiStyleConfig(BANNER_SECTION_COMPONENTS);
  const { text, additionalText } = announcement;
  return (
    <Flex sx={styles.dateOpeningContainer}>
      <CalendarIco w={6} h={6} />
      {text && <Text sx={styles.dateOpeningText}>{text}</Text>}
      {additionalText && (
        <Text sx={styles.dateOpeningAdditionalText}>{additionalText}</Text>
      )}
    </Flex>
  );
};

export const BannerContent = ({
  title,
  description,
  button,
  media,
  additionalLink,
  heightBanner,
  announcement,
  signatureDapper,
  isMainBanner,
  copies,
  trackHandler,
  additionalLinkLabelEvent,
}: BannerContentProps) => {
  const styles = useMultiStyleConfig(BANNER_SECTION_CONTENT);
  const router = useRouter();

  const descriptionRef = useRef(null);
  const { carouselVideo } = useMultiStyleConfig(BANNER_SECTION_COMPONENTS);

  useEffect(() => {
    if ('current' in descriptionRef && descriptionRef?.current !== null) {
      descriptionRef.current.innerHTML = description;
    }
  }, [descriptionRef, description]);

  const clickHandler = (event) => {
    const url = event.target.dataset.url;
    const label = event.target.dataset.label;

    trackHandler({
      item_label: label,
      link: url,
    });
    router.push(url);
  };

  if (!media) {
    return (
      <Flex sx={styles.container}>
        <Box>
          {title && (
            <Text sx={isMainBanner ? styles.mainTitle : styles.title}>
              {title}
            </Text>
          )}
          {description.length > 0 && (
            <Text
              ref={descriptionRef}
              sx={isMainBanner ? styles.mainDescription : styles.description}
            />
          )}
        </Box>
        {additionalLink && additionalLink?.text && (
          <Link
            sx={styles.link}
            data-label={additionalLinkLabelEvent}
            data-url={additionalLink?.link}
            href={additionalLink?.link}
            onClick={clickHandler}
          >
            {additionalLink?.text}
          </Link>
        )}
        {announcement && announcement.text && (
          <DateOpening announcement={announcement} />
        )}
        {button && button?.text && (
          <Button
            sx={styles.button}
            data-label={button.text}
            data-url={button.link}
            onClick={clickHandler}
            {...button}
          >
            {button.text}
          </Button>
        )}
        {signatureDapper && <DapperSignature />}
      </Flex>
    );
  }

  if (media.isVideo) {
    return <Video copies={copies} media={media} trackHandler={trackHandler} />;
  }

  if (media.fullscreenVideoList.length) {
    return (
      <AssetCarousel
        assets={media.fullscreenVideoList}
        carouselId='fullscreen-video'
        marginX='1rem'
        showControls
        showLanguagePicker={false}
        showNav
        videoProps={{
          sx: carouselVideo,
        }}
      />
    );
  }

  return (
    <Media
      heightImage={heightBanner}
      medias={media?.urlList}
      stickedPosition={media.stickedPosition}
      styles={{ image: styles.image }}
      trackHandler={trackHandler}
    />
  );
};
