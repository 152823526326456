import type { ComponentStyleConfig } from 'src/theme/index';

const theme: ComponentStyleConfig = {
  baseStyle: {
    activeListItem: {
      borderBottom: '2px solid #F0F2FD',
      color: '#F0F2FD',
    },
    betaBlock: {
      backgroundColor: '#393b45',
      color: 'gray.50',
      fontSize: '11px',
      fontWeight: '600',
      height: '100%',
      letterSpacing: '3.2px',
      lineHeight: '17px',
      position: 'absolute',
      right: '0px',
      textAlign: 'center',
      textTransform: 'uppercase',
      transform: 'rotate(-180deg)',
      width: '17px',
      writingMode: 'vertical-rl',
      zIndex: '-1',
    },
    btnCloseMobileNav: {
      borderBottom: '1px solid #454543',
      borderRadius: 0,
      boxSizing: 'border-box',
      h: '40px',
      minHeight: '40px',
      mt: 'auto',
      w: '44px',
    },
    btnLoginMobile: {
      fontWeight: 100,
      height: '40px',
      mt: 2,
      order: 2,
      width: '100%',
    },
    btnLogoutMobile: {
      fontWeight: '100',
      height: '40px',
      minHeight: '40px',
      my: 12,
      width: '100%',
    },
    btnSignupMobile: {
      fontWeight: '100',
      height: '40px',
      order: 1,
      width: '100%',
    },
    button: {
      fontFamily: 'mono',
      fontSize: 20,
      fontWeight: 100,
      height: 8,
      width: '79px',
    },
    container: {
      flexDirection: 'column',
      minWidth: '100%',
      position: 'sticky',
      top: '0px',
      width: 'fit-content',
      zIndex: '100',
    },
    link: {
      alignItems: 'center',
      display: 'flex',
      fontFamily: 'main',
      fontSize: { base: 18, md: 14 },
      fontWeight: { base: 700, md: 'inherit' },
      letterSpacing: { base: '-0.16px', md: 'inherit' },
      lineHeight: { base: '24px', md: '20px' },
      textTransform: { base: 'uppercase', md: 'none' },
    },
    list: {
      display: { base: 'block', md: 'flex' },
      gridTemplateColumns: 'minmax(min-content, max-content)',
      mt: { base: '4px', md: 0 },
    },
    listItem: {
      color: { base: 'white', md: '#8A94A6' },
      fontFamily: 'main',
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '0.3px',
      lineHeight: '19px',
      listStyleType: 'none',
      mt: { base: 7, md: 0 },
      p: { base: 0, md: '8px 16px' },
      textTransform: 'none',
    },
    menuButton: {
      _expanded: { bg: 'transparent' },
      _focus: { bg: 'transparent', boxShadow: 'transparent' },
      _hover: { bg: 'transparent' },
      backgroundColor: 'transparent',
    },
    menuList: {
      backgroundColor: '#171715',
      border: '1px solid #1F1F1D',
      boxShadow: '0px 0px 24px rgba(240, 242, 253, 0.2)',
      mt: '2px',
    },
    nav: {
      alignItems: 'center',
      bg: 'black',
      borderBottom: '1px solid #1F1F1D',
      display: 'grid',
      gridArea: 'actions',
      gridColumnGap: 4,
      gridTemplateAreas: "'start mid end'",
      gridTemplateColumns: 'max-content 1fr max-content',
      pb: { base: 3.5, md: '5px' },
      pl: { base: 4, md: 8 },
      position: 'relative',
      pt: { base: 3.5, md: '9px' },
      width: '100%',
      zIndex: 'docked',
    },
    profileNavItem: {
      color: '#F0F2FD',
      cursor: 'pointer',
      fontSize: '14px',
      fontWeight: '600',
      lineHeight: '20px',
    },
    selectLabel: {
      color: '#F8F8F8',
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: '0.16px',
    },
    shortcode: {
      color: '#B0B7C3',
      fontWeight: '600',
      lineHeight: '18px',
    },
    slideContainer: {
      bg: '#171715',
      color: 'white',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      overflow: 'auto',
      p: '12px 16px 24px 16px',
      shadow: 'md',
      width: 'calc(100% - 44px)',
    },
    username: {
      color: 'white',
      fontWeight: '600',
      letterSpacing: '1.75px',
      lineHeight: '18px',
      textTransform: 'uppercase',
    },
  },
  defaultProps: {
    variant: 'sub',
  },

  parts: [
    'nav',
    'list',
    'listItem',
    'link',
    'button',
    'menuList',
    'selectLabel',
    'betaBlock',
    'profileButton',
    'slideContainer',
    'btnLogoutMobile',
    'profileNavItem',
    'username',
    'shortcode',
    'btnCloseMobileNav',
    'menuButton',
    'activeListItem',
  ],
};

export const TOP_NAV_THEME_KEY = 'TopNav';
export const TopNavTheme = {
  [TOP_NAV_THEME_KEY]: theme,
};
