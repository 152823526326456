import { ApolloClient, QueryOptions } from '@apollo/client';

export enum FS {
  EMPTY = 'empty',
  ERROR = 'error',
  FETCH = 'fetch',
  FETCH_MORE = 'fetch_more',
  IDLE = 'idle',
  INITIAL = 'initial',
  REFETCH = 'refetch',
}

export enum EVENTS {
  FETCH_MORE = 'FETCH_MORE',
  REFETCH = 'REFETCH',
  RETRY = 'RETRY',
}

type EventType = 'RETRY' | 'FETCH_MORE' | 'REFETCH';

export type Events = { data?: any; type: EventType };
export interface Options extends Omit<QueryOptions, 'query' | 'variables'> {
  origin?: string;
  ssr?: boolean;
}
export interface Context {
  EVENTS: typeof EVENTS;
  FS: typeof FS;
  client: ApolloClient<any>;
  edges: any[];
  errorMessage: undefined | string;
  errorType: string;
  limit?: number;
  options?: Options;
  pageInfo: {
    endCursor: string;
    hasNextPage: boolean;
  };
  //input
  query: any;
  queryDataPath: any;
  queryPaginationPath: string;
  skip?: boolean;
  totalCount: number;
  variables?: any;
}

export interface Schema {
  states: Record<FS, {}>;
}

export interface TypeState {
  context: Context;
  value: FS;
}
