import { gql } from '@apollo/client';

export const GET_ACTIVE_REWARD_PASS = gql`
  query GetActiveRewardPass($input: GetActiveRewardPassInput) {
    getActiveRewardPass(input: $input) {
      id
      title
      description
      imageUrl
      startAt
      endAt
      tasks {
        rewardPassID
        id
        slug
        title
        type
        description
        category
        rewardPoints
        referenceID
        timesRequired
        streakIndex
        streakCountRequired
        validFrom
        validTo
      }
      levels {
        id
        rewardPassID
        requiredPoints
        rewards {
          id
          levelID
          rewardID
          tier
          title
          description
          imageUrl
          skipClaim
        }
      }
    }
  }
`;

export const GET_USER_PROGRESS = gql`
  query GetUserProgress {
    getUserProgress {
      rewardPassID
      dapperID
      currentLevel
      currentPoints
      tier
      tasks {
        taskSlug
        rewardedPoints
        timesCompleted
        completedAt
      }
    }
  }
`;
