import { useApolloClient } from '@apollo/client';
import { useMachine } from '@xstate/react';
import { useMemo } from 'react';

import { getQueueMachine } from '../fsm/machine';

// TODO: Initialize with reservationData & queueStatus
function useQueue() {
  const client = useApolloClient();

  const machine = useMemo(
    () =>
      getQueueMachine({
        custom: {
          context: {
            client,
          },
        },
      }),
    [],
  );
  const [state, send, actor] = useMachine(machine);

  return {
    actor,
    send,
    state,
  };
}

export { useQueue };
