import { useApolloClient } from '@apollo/client';
import { useMachine } from '@xstate/react';
import { useMemo } from 'react';
import { Distribution } from 'src/edge/purchase';
import { getBuyMachine } from 'src/edge/purchase/fsm/machine';

interface PackPurchase {
  buyerAddress: string;
  custom: any;
  distribution: Distribution;
}

export const usePackPurchase = ({
  distribution,
  buyerAddress,
  custom,
}: PackPurchase) => {
  const client = useApolloClient();

  const buyMachine = useMemo(
    () =>
      getBuyMachine({
        custom: {
          ...custom,
          context: {
            buyerAddress,
            client,
            distribution,
          },
        },
      }),
    [],
  );

  const [state, send] = useMachine(buyMachine);

  const onAuthorize = () => send('AUTHORIZE');

  return {
    onAuthorize,
    state: {
      context: state.context,
      event: state.event,
      matches: state.matches,
      value: state.value,
    },
  };
};
