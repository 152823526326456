import { analyticsItemLabels } from '@dapperlabs/core-fe';
import { useAnalytics } from '@dapperlabs/react-analytics';
import { useSession } from 'src/edge/session';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment';
import { useTranslation } from 'src/lib/translations';

export const toSnakeCase = (str) =>
  str &&
  str
    .match(/[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g)
    .map((x) => x.toLowerCase())
    .join('_');

const socialIcons = {
  discord: analyticsItemLabels.FOOTER_DISCORD,
  facebook: analyticsItemLabels.FOOTER_FACEBOOK,
  instagram: analyticsItemLabels.FOOTER_INSTAGRAM,
  twitter: analyticsItemLabels.FOOTER_TWITTER,
  youtube: analyticsItemLabels.FOOTER_YOUTUBE,
};

const useFooterAnalytics = () => {
  const { t } = useTranslation();
  const { track } = useAnalytics();
  const { state } = useSession();
  const userEmail = state?.context?.auth?.user?.email ?? null;
  const isSignedIn = !!userEmail;

  const handleTrackFooterMainNav = ({
    label,
    href,
  }: {
    href: string;
    label: string;
  }) => {
    let itemLabel;
    switch (label) {
      case t('Navigation.About.Label'): {
        itemLabel = analyticsItemLabels.FOOTER_ABOUT;
      }
      case t('Navigation.Blog.Label'): {
        itemLabel = analyticsItemLabels.FOOTER_BLOG;
      }
      default: {
        itemLabel = label;
      }
    }
    track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_ITEM_CLICK, {
      component_variant: isSignedIn ? 'authenticated' : 'visitor',
      item_label: itemLabel,
      item_url_to: href,
    });
  };

  const handleTrackFooterSocialIcons = ({
    url = 'no_url',
    label,
  }: {
    label: string;
    url: string;
  }) => {
    track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_ITEM_CLICK, {
      component_variant: isSignedIn ? 'authenticated' : 'visitor',
      item_label: socialIcons[label] ?? url,
      item_url_to: url,
    });
  };

  const handleTrackFootersLinksClick = ({
    label = 'no_label',
    url = 'no_url',
  }: {
    label: string;
    url: string;
  }) => {
    track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_ITEM_CLICK, {
      component_variant: isSignedIn ? 'authenticated' : 'visitor',
      item_label: toSnakeCase(label),
      item_url_to: url,
    });
  };

  return {
    handleTrackFooterMainNav,
    handleTrackFooterSocialIcons,
    handleTrackFootersLinksClick,
  };
};

export default useFooterAnalytics;
