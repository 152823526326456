import { EditionTier } from '__generated__/globalTypes';
import { sendUpdate } from 'xstate';

import { Context } from './types';

// @TODO: get from edge
export const SET_VISUAL = {
  COMMON: 'SET_VISUAL_COMMON',
  FANDOM: 'SET_VISUAL_FANDOM',
  LEGENDARY: 'SET_VISUAL_LEGENDARY',
  NIL: 'SET_VISUAL_NIL',
  RARE: 'SET_VISUAL_RARE',
  ULTIMATE: 'SET_VISUAL_ULTIMATE',
};

export const actions = {
  onRevealed: sendUpdate(),
  onUnveil: () => {},
};

export const delays = {
  AUTO_APPEAR_DELAY: (context: Context) => context.autoAppearDelay,
};

export const guards = {
  isMultiStepReveal: (context) => {
    const { RARE, LEGENDARY, ULTIMATE } = EditionTier;
    return [RARE, LEGENDARY, ULTIMATE].includes(
      context.momentNFT.edition?.tier,
    );
  },
};

export const services = {};
