import * as React from 'react';
import {
  forwardRef,
  useStyles,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  ButtonProps,
  Flex,
} from 'src/theme/index';

export interface TopNavDropdownProps {
  items?: Array<any>;
  menuButtonComponent?: React.ReactNode;
  menuButtonProps?: ButtonProps;
}

export const TopNavDropdown = forwardRef<TopNavDropdownProps, 'select'>(
  (
    { items = [], menuButtonComponent, menuButtonProps = {}, disabled = false },
    _ref,
  ) => {
    const styles = useStyles();
    return (
      <Menu>
        <MenuButton
          sx={styles.menuButton}
          height={6}
          pr={4}
          disabled={disabled}
          {...menuButtonProps}
        >
          {menuButtonComponent}
        </MenuButton>
        <MenuList sx={styles.menuList}>
          {items.map(({ href, onClick, label, icon }) => {
            const Icon: any = icon;
            return (
              <a
                key={label}
                {...(href ? { href, target: '_blank' } : {})}
                {...(onClick ? { onClick } : {})}
              >
                <MenuItem>
                  {label}
                  {icon && (
                    <Flex alignItems='center' marginLeft='auto'>
                      <Icon width='16px' height='16px' />
                    </Flex>
                  )}
                </MenuItem>
              </a>
            );
          })}
        </MenuList>
      </Menu>
    );
  },
);
