/* eslint-disable sort-keys-fix/sort-keys-fix */
import { useApolloClient } from '@apollo/client';
import { useMachine } from '@xstate/react';
import { useTranslation } from 'src/lib/translations';
import { createMachine, MachineConfig } from 'xstate';

import { actions, guards, services } from './options';
import { Context, Events, Schema, TypeState } from './types';

// https://stately.ai/viz/4922ba42-d0aa-49f6-9b6e-d7d0b8e88b3e
export const definition: MachineConfig<Context, Schema, Events> = {
  context: {
    auth: undefined,
    client: undefined,
    error: undefined,
    i18n: undefined,
    preferredCurrency: 'DOLLARS',
    preferredLanguage: 'ENG',
    marketingEmail: undefined,
    profile: undefined,
    favoriteClub: undefined,
    guest: undefined,
  },
  id: 'session',
  initial: 'LOADING',
  states: {
    LOADING: {
      initial: 'LOCAL_PREFERENCES',
      states: {
        LOCAL_PREFERENCES: {
          invoke: {
            src: 'preloadPreferences',
            onDone: {
              actions: ['assignLanguage', 'i18nUpdate'],
              target: 'SESSION',
            },
            onError: {
              target: 'SESSION',
            },
          },
        },
        SESSION: {
          invoke: {
            src: 'loadSession',
            onDone: [
              {
                actions: 'assignSession',
                cond: 'gotValidSession',
                target: 'PROFILE',
              },
              {
                target: '#session.UNAUTHENTICATED',
                actions: 'assignGuestData',
              },
            ],
            onError: {
              target: '#session.UNAUTHENTICATED',
            },
          },
        },
        PROFILE: {
          invoke: {
            src: 'loadProfile',
            onDone: [
              {
                actions: ['assignProfile', 'assignLanguage', 'i18nUpdate'],
                // if passed onboarding earlier
                cond: (_, event) =>
                  !!event.data.profile.signedTos ||
                  !!event.data.profile.favoriteClub ||
                  !!event.data.profile.marketingEmail,
                target: '#session.AUTHENTICATED',
              },
              {
                actions: ['assignProfile', 'assignLanguage', 'i18nUpdate'],
                target: '#session.ONBOARDING',
              },
            ],
            onError: [
              {
                // signed up for wallet but no profile yet
                cond: 'hasAuth',
                target: '#session.ONBOARDING',
              },
              { target: '#session.UNAUTHENTICATED' },
            ],
          },
        },
      },
    },
    ONBOARDING: {
      initial: 'AWAITING_TERMS_ACCEPTANCE',
      states: {
        AWAITING_TERMS_ACCEPTANCE: {
          on: {
            ACCEPT_TERMS: {
              actions: 'assignMarketingEmail',
              target: 'CREATING_PROFILE',
            },
          },
        },
        CREATING_PROFILE: {
          invoke: {
            src: 'createProfile',
            onDone: {
              actions: 'assignProfile',
              target: '#session.AUTHENTICATED',
            },
            onError: {
              actions: 'assignError',
              target: 'AWAITING_TERMS_ACCEPTANCE',
            },
          },
        },
      },
    },
    AUTHENTICATED: {
      initial: 'IDLE',
      states: {
        IDLE: {
          on: {
            SIGN_OUT: 'SIGNING_OUT',
            UPDATE_CURRENCY: {
              actions: 'assignCurrency',
              target: 'UPSERTING_PREFERENCES',
            },
            UPDATE_FLOW_ADDRESS: {
              actions: ['assignProfile', 'assignSession'],
              target: 'IDLE',
            },
            UPDATE_LANGUAGE: [
              {
                actions: ['assignLanguage', 'i18nUpdate'],
                cond: 'hasProfile',
                target: 'UPSERTING_PREFERENCES',
              },
              {
                actions: ['assignLanguage', 'i18nUpdate'],
              },
            ],
          },
        },
        SIGNING_OUT: {
          invoke: {
            src: 'signOut',
            // @note: this transition does not currently occur because the page will redirect.
            // onDone: {
            //   target: '#session.UNAUTHENTICATED',
            // },
          },
        },
        UPSERTING_PREFERENCES: {
          invoke: {
            onDone: 'IDLE',
            onError: {
              actions: 'assignError',
              target: 'IDLE',
            },
            src: 'upsertPreferences',
          },
        },
      },
    },
    UNAUTHENTICATED: {
      initial: 'IDLE',
      states: {
        IDLE: {
          on: {
            SIGN_IN: 'SIGNING_IN',
            UPDATE_LANGUAGE: {
              actions: ['assignLanguage', 'i18nUpdate'],
              target: 'IDLE',
            },
          },
        },
        SIGNING_IN: {
          invoke: {
            src: 'signIn',
            // @note: this transition does not currently occur because the page will redirect.
            // onDone: {
            //   target: '#session.LOADING',
            // },
          },
        },
      },
    },
  },
};

const machine = createMachine<Context, Events, TypeState>(definition, {
  actions,
  guards,
  services,
});

export const useSessionMachine = () => {
  const client = useApolloClient();
  const { i18n } = useTranslation();
  return useMachine(machine, {
    context: {
      client,
      i18n,
    },
  });
};
