import { isEmpty } from 'lodash';
import { getSession } from 'src/edge/session';
import { getFinishSignupUrlPath } from 'src/modules/routes';

export type GuestSession = {
  flowAddress?: string;
  token?: string;
};

export const FCL_OPEN_ID_HEADER = 'x-fcl-open-id';

export const redirectToGuestSignUp = async () => {
  const { flowAddress } = await getSession().getGuestData();
  const { url } = await getFinishSignupUrl(flowAddress);
  window.location.href = url;
};

const getFinishSignupUrl = async (
  address: string,
): Promise<{
  url?: string;
}> => {
  const response = await fetch(getFinishSignupUrlPath(), {
    body: JSON.stringify({ address }),
    headers: { 'Content-Type': 'application/json' },
    method: 'POST',
  });
  if (!response.ok) return null;
  const json = await response.json();
  return isEmpty(json) ? null : json;
};
