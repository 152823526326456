import { captureException, getPlayerName } from '@dapperlabs/core-fe';
import { useEffect, useState } from 'react';
import { extractValue } from 'src/general/utils/helpers';
import {
  getAssetUrl,
  getImage,
  HERO_VIEW_IMG,
} from 'src/general/utils/moments/momentDetails';
import { createApolloClient } from 'src/lib/apollo';
import {
  SEARCH_AGGREGATION,
  searchAggregationByEditionIDs,
} from 'src/modules/Queries';
import {
  SearchGolazosNftAggregationQuery,
  SearchGolazosNftAggregationQueryVariables,
} from 'src/modules/Queries/searchAggregation.generated';

const formatPrice = (priceValue: number) => {
  return new Intl.NumberFormat('en-US', {
    currency: 'USD',
    style: 'currency',
  }).format(priceValue);
};

const getMomentImage = (playDataId, languages) => {
  const localisedItems = {};

  languages.forEach(({ urlValue, value }) => {
    localisedItems[value] = getImage(
      `moment-hero-${playDataId}`,
      getAssetUrl(HERO_VIEW_IMG, playDataId, urlValue),
    );
  });
  return localisedItems;
};

const getMomentType = (metadata, set, t) => {
  const type = [];
  let teams = '';

  if (extractValue(metadata?.play_type)) {
    type.push(
      t(`moment.type.${extractValue(metadata?.play_type).toLowerCase()}`),
    );
  }
  if (extractValue(metadata?.match_season)) {
    type.push(extractValue(metadata?.match_season));
  }
  if (extractValue(set?.name)) {
    type.push(extractValue(set?.name));
  }
  if (
    extractValue(metadata?.match_home_team) &&
    extractValue(metadata.match_away_team)
  ) {
    teams = `${extractValue(metadata?.match_home_team)} ${t(
      'moment.type.vs',
    )} ${extractValue(metadata.match_away_team)}`;
  }
  const typeMetadata = type.join(' · ');
  return teams ? `${typeMetadata}: ${teams}` : typeMetadata;
};

const formatEditionData = (data, languages, t) => {
  return data.map(({ node: { edition, listing } }) => ({
    avgAsk:
      extractValue(listing?.price?.avg) &&
      formatPrice(extractValue(listing?.price?.avg) / 1e8),
    id: extractValue(edition.id),
    lowestAsk:
      extractValue(listing?.price?.min) &&
      formatPrice(extractValue(listing?.price?.min) / 1e8),
    maxMinted: extractValue(edition.max_mintings),
    momentImage: getMomentImage(
      extractValue(edition?.play?.metadata?.play_data_id),
      languages,
    ),
    rarityTier: extractValue(edition.tier),
    serialNumber: '',
    title: getPlayerName(edition?.play?.metadata),
    totalBurned: extractValue(edition.total_burned),
    type: getMomentType(edition?.play?.metadata, edition?.set, t),
  }));
};

export const useGetEditionByIds = (editionIds, t, languages) => {
  const [editionData, setEditionData] = useState([]);

  useEffect(() => {
    if (!editionIds.length) {
      return;
    }

    const client = createApolloClient();

    const aggregationPromise = client.query<
      SearchGolazosNftAggregationQuery,
      SearchGolazosNftAggregationQueryVariables
    >({
      context: {
        clientName: 'platformAPI',
      },
      query: SEARCH_AGGREGATION,
      variables: searchAggregationByEditionIDs(editionIds),
    });
    aggregationPromise
      .then((res) => {
        const data = formatEditionData(
          res.data.searchGolazosNftAggregation.edges,
          languages,
          t,
        );
        setEditionData(data);
      })
      .catch((err) => {
        captureException(err);
      });
  }, [editionIds, languages, t]);

  return { editionData };
};
