import { FC, ReactElement } from 'react';
import { Flex } from 'src/theme';

type Props = {
  children: ReactElement;
};

export const FullScreen: FC<Props> = (props) => (
  <Flex direction='column' height='100%' grow='1' id='main' shrink='1'>
    {props.children}
  </Flex>
);
