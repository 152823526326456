import { Box } from '@dapperlabs/core-fe';
import React from 'react';
import { Text, Button, Heading } from 'src/theme';

interface MessageComponentProps {
  CTA: string;
  header: string;
  onClick: () => unknown;
  subheader: string;
}

export const MessageComponent = ({
  header,
  subheader,
  CTA,
  onClick,
}: MessageComponentProps) => {
  return (
    <Box
      justifyContent='center'
      flexDirection='column'
      display='flex'
      mt={['3.75rem']}
      textAlign='center'
    >
      <Heading
        mb={['1rem']}
        color='#ffffff'
        fontSize={['1.75rem', '1.75rem', '2rem']}
        fontWeight='500'
      >
        {header}
      </Heading>
      <Text
        mb={['2rem']}
        color='#ffffff'
        fontSize={['0.875rem', '0.875rem', '1.125rem']}
      >
        {subheader}
      </Text>
      {CTA && (
        <Button
          width={['100%', 'fit-content']}
          margin='auto'
          px={[0, '7.25rem']}
          py='0.75rem'
          color='black'
          fontFamily='mono'
          fontSize='1.125rem'
          fontWeight={400}
          lineHeight='1.5rem'
          letterSpacing='-0.16px'
          textAlign='center'
          textDecoration='none'
          borderRadius='40px'
          backgroundColor='#F8F8F8'
          onClick={onClick}
        >
          {CTA}
        </Button>
      )}
    </Box>
  );
};
