import { useRouter } from 'next/router';

import { IconExternalLink, Link, TextDeprecated } from 'src/theme';

import { NavItem } from './navItems';

const NavLink = ({
  href,
  children,
  isDecorated,
  hasExternalLinkIcon,
  ...rest
}: NavItem) => {
  const router = useRouter();
  const isOnPath = router.pathname === href;
  const textVariant = isDecorated ? 'sparkle' : '';

  return (
    <Link
      key={href}
      href={href}
      variant={`nav-link${isOnPath ? '-current' : ''}`}
      {...rest}
    >
      <TextDeprecated as='span' textStyle='body12' variant={textVariant}>
        {children}
      </TextDeprecated>
      {hasExternalLinkIcon && (
        <IconExternalLink w='16px' h='16px' ml={2} color='grey.text' />
      )}
    </Link>
  );
};

export default NavLink;
