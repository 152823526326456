import React, { createContext, useContext } from 'react';

import { ValueContextI, TopNavContextProviderProps } from './types';

export const TopNavContext = createContext<ValueContextI>({
  activeHref: null,
});

export const useTopNavContext = () => {
  const context = useContext(TopNavContext);

  if (context === undefined) {
    throw new Error('useNav must be used within a TopNavContextProvider');
  }

  return context;
};

export const TopNavContextProvider = ({
  value,
  children,
}: TopNavContextProviderProps) => (
  <TopNavContext.Provider value={value}>{children}</TopNavContext.Provider>
);
