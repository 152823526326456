import { useState } from 'react';

import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
  Text,
  VStack,
  Heading,
} from 'src/theme';

export const ErrorModal = ({
  // @TRANSLATE
  title,
  message,
  buttonMessage,
  onButtonClick = undefined,
  txId,
}: {
  buttonMessage?: string;
  message: string;
  onButtonClick?: () => void;
  title?: string;
  txId?: string;
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState(true);

  const closeModal = () => setIsOpen(false);

  const tTitle = title ?? t('transactionMessages.error.title');
  const tButton = buttonMessage ?? t('transactionMessages.error.buttonMessage');

  return (
    <Modal isCentered isOpen={isOpen} onClose={closeModal}>
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={16}>
          <VStack textAlign='center' spacing={8}>
            <Heading size='sm' variant='tame'>
              {t(tTitle)}
            </Heading>

            <Box maxWidth='500px'>
              <Text size='md' variant='label'>
                {t(message)}
              </Text>
            </Box>

            <Button
              width='80%'
              onClick={() => (onButtonClick ? onButtonClick() : closeModal())}
            >
              {t(tButton)}
            </Button>

            {txId && (
              <Box>
                <Button
                  as='a'
                  sx={{
                    _hover: {
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                      textTransform: 'none',
                    },
                  }}
                  p={2}
                  textTransform='none'
                  borderRadius={0}
                  href={`${process.env.NEXT_PUBLIC_FLOWSCAN}/${txId}`}
                  rel='noreferrer noopener'
                  target='_blank'
                  variant='link'
                >
                  {t('general.view.transaction')}
                </Button>
              </Box>
            )}
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
