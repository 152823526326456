import { graphql } from 'msw';
import type { PartialDeep } from 'type-fest';

let searchGolazosNft;

export const searchMarketplaceEditionsFactory = (
  overrides: Record<string, any> = {},
): any => {
  return {
    ...searchGolazosNft,
    ...overrides,
  };
};

export const searchMarketplaceEditionsHandler = (data = {}) => {
  try {
    searchGolazosNft = require('./fixtures/searchGolazosNft-mocks.json');
  } catch (err) {}
  return graphql.query<any>('searchGolazosNft', (req, res, ctx) => {
    return res(
      ctx.data({
        searchGolazosNft: searchMarketplaceEditionsFactory(),
      }),
    );
  });
};

let searchGolazosNftFromPack;

export const searchGolazosNftFactory = (
  overrides: PartialDeep<any> = {},
): any => {
  return {
    ...searchGolazosNftFromPack,
    ...overrides,
  };
};

export const searchGolazosNftHandler = ({
  name = 'searchGolazosNft__nftsInPack',
  data = {},
} = {}) => {
  try {
    searchGolazosNftFromPack = require('./fixtures/mocks-nfts-in-pack.json');
  } catch (err) {}
  return graphql.query<any>(name, (req, res, ctx) => {
    return res(
      ctx.data({
        searchGolazosNft: searchGolazosNftFactory(data),
      }),
    );
  });
};

let searchGolazosNftAggregation;

export const searchGolazosNftAggregationFactory = (
  overrides: PartialDeep<any> = {},
): any => {
  return {
    ...searchGolazosNftAggregation,
    ...overrides,
  };
};

export const searchGolazosNftAggregationHandler = ({
  name = 'searchGolazosNftAggregation',
  data = {},
} = {}) => {
  try {
    searchGolazosNftAggregation = require('./fixtures/searchGolazosNftAggregation-mocks.json');
  } catch (err) {}
  return graphql.query<any>(name, (req, res, ctx) => {
    return res(
      ctx.data({
        searchGolazosNftAggregation: searchGolazosNftAggregationFactory(data),
      }),
    );
  });
};
