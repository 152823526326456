import type { ComponentStyleConfig } from '@chakra-ui/react';

const lineBackground = {
  common: 'gray.900',
  primary: 'cyan.300',
  side: 'gray.50',
};
export const statsLine: ComponentStyleConfig = {
  baseStyle: ({ variant, align }) => ({
    container: {
      flexDirection: align === 'right' ? 'row-reverse' : 'row',
      margin: '0.75rem 0',
      position: 'relative',
    },
    line: {
      '&.common': {
        background: lineBackground.common,
      },
      background: lineBackground[variant],
      borderRadius: '0.5rem',
      height: '0.5rem',
      position: 'absolute',
      width: '100%',
    },
  }),
  defaultProps: {
    variant: 'common',
  },
  parts: ['container', 'line'],
};
