import { ApolloClient } from '@apollo/client';
import { State } from 'xstate';
export enum EVENTS {
  JOIN = 'JOIN',
  UPDATE = 'UPDATE',
}
export type EVENTStype = typeof EVENTS;

export type Event = { data?: any; type: EVENTS };

export interface Schema {
  states: {
    CAN_BUY: {};
    CAN_JOIN: {};
    ENDED: {};
    GETTING_POSITION: {};
    // queueStatus error
    IN_QUEUE: {};

    JOINING: {};
    // joinDrop or queueStatus error
    JOIN_ERROR: {};
    LOADING: {};
    WAITING_ROOM: {};
  };
}

export enum FS {
  CAN_BUY = 'CAN_BUY',
  CAN_JOIN = 'CAN_JOIN',
  CAN_JOIN_ERROR = 'CAN_JOIN_ERROR',
  ENDED = 'ENDED',
  GETTING_POSITION = 'GETTING_POSITION',
  IN_QUEUE = 'IN_QUEUE',
  JOINING = 'JOINING',
  JOIN_ERROR = 'JOIN_ERROR',
  WAITING_ROOM = 'WAITING_ROOM',
}

// export type FS = Schema['states'];

export interface Context {
  EVENTS: typeof EVENTS;
  FS: typeof FS;
  client: ApolloClient<any>;
  error: any;
}
export interface TypeState {
  context: Partial<Context>;
  value: FS;
}

export type QueueMachineState = State<
  Partial<Context>,
  Event,
  Schema,
  TypeState
>;
