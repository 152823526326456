import React, { useCallback, useEffect, useState } from 'react';
import { useMultiStyleConfig, chakra } from 'src/theme';

import { DonutSegment } from './DonutSegment';

export const WIDTH = 50;
export const CENTER = {
  x: WIDTH / 2,
  y: WIDTH / 2,
};

export type DonutData = {
  id: string;
  strokeColor: string;
  value: number;
};

type DonutRenderData = DonutData & {
  strokeDasharray: string;
  strokeDashoffset: number;
};

// Data example
/*const testdata = [
  { id: 'test', strokeColor: '#22594e', value: 100 },
  { id: 'test2', strokeColor: '#69c2b0', value: 50 },
  { id: 'test2', strokeColor: '#69c2b0', value: 0 },
  { id: 'test2', strokeColor: '#69c2b0', value: 25 },
  { id: 'test2', strokeColor: '#69c2b0', value: 1 },
  { id: 'test2', strokeColor: '#69c2b0', value: 2 },
];*/

interface DonutChartProps {
  [key: string]: unknown;
  data: DonutData[];
  id: string;
  renderStatistics?: () => JSX.Element;
  strokeWidth: number;
}

export const DonutChart: React.FC<DonutChartProps> = ({
  data,
  strokeWidth,
  renderStatistics,
  id,
  ...props
}) => {
  const [renderData, setRenderData] = useState<DonutRenderData[]>([]);
  const total = data.reduce((prev, current) => current.value + prev, 0);
  const radius = WIDTH / 2 - strokeWidth;
  const base_circle_size = radius * (2 * Math.PI);
  const spacing = strokeWidth * 2;

  const styles = useMultiStyleConfig('DonutChart');

  const calculatePercentAcc = useCallback(() => {
    let percentAcc = 0;

    const filteredData = data.filter(({ value }) => value);
    return filteredData.map((item, index) => {
      const percent =
        (item.value / total) *
        (base_circle_size - spacing * filteredData.length);
      const DashArrayPercent = percent;

      const DashArrayLength = base_circle_size - percent + spacing;
      const strokeDasharray = `${DashArrayPercent} ${DashArrayLength}`;

      const strokeDashoffset =
        index === 0 ? base_circle_size : base_circle_size - percentAcc;

      percentAcc += percent + spacing;
      return {
        ...item,
        strokeDasharray,
        strokeDashoffset,
      };
    });
  }, [data, total, base_circle_size, spacing]);

  useEffect(() => {
    setRenderData(calculatePercentAcc());
  }, [calculatePercentAcc]);

  const { sx, ...otherProps } = { sx: {}, ...props };

  return (
    <chakra.div sx={{ ...styles.wrapper, ...sx }} {...otherProps}>
      <svg viewBox={`0 0 ${WIDTH} ${WIDTH}`}>
        {renderData.map(
          ({
            value,
            strokeDashoffset,
            strokeDasharray,
            id: segmentId,
            strokeColor,
          }) => (
            <DonutSegment
              className={segmentId}
              key={`donut-${id}-${segmentId}`}
              radius={radius}
              strokeColor={strokeColor}
              strokeDasharray={strokeDasharray}
              strokeDashoffset={strokeDashoffset}
              strokeWidth={strokeWidth}
              value={value}
            />
          ),
        )}
      </svg>
      {renderStatistics && (
        <chakra.div sx={styles.statistics}>{renderStatistics()}</chakra.div>
      )}
    </chakra.div>
  );
};
