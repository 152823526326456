import { captureException } from '@dapperlabs/core-fe';

const MEDIA_TYPES = {
  AUDIO: 'audio',
  IMAGE: 'image',
  VIDEO: 'video',
};

export const getPreload = ({
  preloads,
  src,
}: {
  preloads: any;
  src: string;
}) => {
  const isPreloadAvailable = !!preloads?.[src];

  if (!isPreloadAvailable) {
    captureException(new Error('no preload found in pack opening experience'), {
      errorInfo: {
        src,
      },
    });
  }

  return isPreloadAvailable ? preloads[src] : src;
};

//This hacked solution which help get profile Image as blob for sharing feature
export function convertToCDNLink(str): string {
  return `https://nflallday.com/cdn-cgi/image/width=500,quality=50/${str}`;
}

export async function preloadMedia(
  { imageUrls = [], videoUrls = [], audioUrls = [] },
  wait = false,
) {
  if (wait) return false;

  try {
    const results = await Promise.allSettled([
      ...videoUrls.map((src) => getMediaElement(src, MEDIA_TYPES.VIDEO)),
      ...imageUrls.map((src) => getMediaElement(src, MEDIA_TYPES.IMAGE)),
      ...audioUrls.map((src) => getMediaElement(src, MEDIA_TYPES.AUDIO)),
    ]);

    // Only return successful items
    return results.reduce((acc, r) => {
      if (r.status !== 'rejected') {
        return {
          ...acc,
          [r.value[0]]: r.value[1],
        };
      }
      return acc;
    }, {});
  } catch (error) {
    captureException(error);
  }
  return true;
}

const getMediaElement = (src, type) => {
  return new Promise((resolve, reject) => {
    const el =
      type === MEDIA_TYPES.IMAGE ? new Image() : document.createElement(type);
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'blob';
    xhr.open('GET', src);
    xhr.send();
    xhr.onload = (e: any) => {
      if (xhr.status === 200 || xhr.status === 206) {
        const type = e.target.response.type;
        const blob = new Blob([e.target.response], { type });
        const blobUrl = URL.createObjectURL(blob);
        el.src = blobUrl;
        // @ts-ignore
        URL.revokeObjectURL(blob);
        return resolve([src, blobUrl]);
      }
      reject({ src, status: xhr.status });
    };
    xhr.onerror = (error) => {
      reject(error);
    };
  });
};
