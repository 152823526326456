import { forwardRef } from 'react';
import { useTeamPicker } from 'src/modules/FTUEV2/components/TeamPicker';
import {
  getTeamLogo,
  getTeamNameFromId,
} from 'src/modules/teams.golazos/utils';
import { IconButton, Image, useTab } from 'src/theme';

export const TeamTab = forwardRef<HTMLButtonElement, { team: string }>(
  (props, ref) => {
    const { team, ...rest } = props;
    const tabProps = useTab({ team, ...rest, ref });
    const teamName = getTeamNameFromId(team);
    const imageSrc = getTeamLogo(team);
    const { storeTeamId } = useTeamPicker();

    return (
      <IconButton
        w='fit-content'
        m='4px 3px' // Weird Chakra behaviour with padding/margins and box-shadow.
        p='28px 2px'
        background='transparent'
        opacity='.7'
        borderRadius='inherit'
        _hover={{ background: 'transparent', opacity: '.8' }}
        _selected={{
          background: 'black',
          boxShadow: 'var(--chakra-shadows-outline)',
          opacity: 1,
        }}
        cursor='pointer'
        aria-label={`Select ${teamName}`}
        icon={
          <Image
            w={12}
            h={12}
            alt={`${teamName} Logo`}
            data-team={team}
            onClick={(e) => {
              const el = e.target as HTMLButtonElement;
              const id = el.getAttribute('data-team');
              storeTeamId(id);
            }}
            src={imageSrc}
          />
        }
        role='tab'
        {...tabProps}
      />
    );
  },
);

TeamTab.displayName = 'Team Tab';
