import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { chakra, useMultiStyleConfig } from 'src/theme';
import { VIDEO_THEME_KEY } from 'src/theme/dss-skeleton/components';
import { VIDEO_EVENTS } from 'src/theme/dss-skeleton/components/Video/helpers';

type DocumentWithFullScreen = typeof document & {
  mozFullScreenElement: typeof document.fullscreenElement;
  webkitFullscreenElement: typeof document.fullscreenElement;
};

export type useVideoProps = {
  fullScreenVideoProps?: React.ComponentProps<any>;
  fullScreenVideoSrc: string;
  id: string;
  previewSrc: string;
};
export const useDefaultFullscreenVideo = ({
  id,
  fullScreenVideoSrc,
  previewSrc,
  // @ts-ignore
  fullScreenVideoProps = {},
}: useVideoProps) => {
  const fullScreenVideoRef = useRef(null);

  const [enterFullScreen, setEnterFullScreen] = useState(null);

  const styles = useMultiStyleConfig(VIDEO_THEME_KEY);

  const enterFullScreenHandler = () => {
    if (enterFullScreen instanceof Function) {
      enterFullScreen.call(fullScreenVideoRef.current);
      window.dispatchEvent(new CustomEvent(VIDEO_EVENTS.FULLSCREEN_START));
      fullScreenVideoRef.current?.play();
    }
  };

  const changeFullScreenHandler = useCallback(
    (event) => {
      if (event.target.id !== fullScreenVideoRef.current?.id) {
        return;
      }

      const fullscreenElement =
        document.fullscreenElement ||
        (document as DocumentWithFullScreen).webkitFullscreenElement ||
        (document as DocumentWithFullScreen).mozFullScreenElement;

      if (!fullscreenElement) {
        if (fullScreenVideoRef.current) {
          fullScreenVideoRef.current.pause();
          fullScreenVideoRef.current.currentTime = 0;
        }
        window.dispatchEvent(new CustomEvent(VIDEO_EVENTS.FULLSCREEN_END));
        return;
      }
    },
    [fullScreenVideoRef],
  );

  useLayoutEffect(() => {
    const videos = document.querySelectorAll(`#autoplay-${id}`);
    if (videos.length > 1) {
      throw new Error(`Duplicated video id: ${id}`);
    }
  }, [id]);

  useEffect(() => {
    const currentRef = fullScreenVideoRef.current;
    document.addEventListener('fullscreenchange', changeFullScreenHandler);
    document.addEventListener('mozfullscreenchange', changeFullScreenHandler);
    document.addEventListener('MSFullscreenChange', changeFullScreenHandler);
    document.addEventListener(
      'webkitfullscreenchange',
      changeFullScreenHandler,
      false,
    );
    fullScreenVideoRef.current?.addEventListener(
      'webkitendfullscreen',
      changeFullScreenHandler,
    );

    const requestFullscreen =
      fullScreenVideoRef.current?.requestFullscreen ||
      fullScreenVideoRef.current?.webkitRequestFullscreen ||
      fullScreenVideoRef.current?.msRequestFullscreen ||
      fullScreenVideoRef.current?.webkitEnterFullScreen;

    setEnterFullScreen(() => requestFullscreen);

    return () => {
      document.removeEventListener('fullscreenchange', changeFullScreenHandler);
      document.removeEventListener(
        'mozfullscreenchange',
        changeFullScreenHandler,
      );
      document.removeEventListener(
        'MSFullscreenChange',
        changeFullScreenHandler,
      );
      document.removeEventListener(
        'webkitfullscreenchange',
        changeFullScreenHandler,
      );
      currentRef?.removeEventListener(
        'webkitendfullscreen',
        changeFullScreenHandler,
      );
      currentRef?.removeEventListener(
        'onwebkitfullscreenchange',
        changeFullScreenHandler,
      );
    };
  }, []);

  return {
    openFullscreen: enterFullScreenHandler,
    view: (
      <chakra.video
        ref={fullScreenVideoRef}
        sx={styles.defaultFullScreenVideo}
        id={`fullscreen-${id}`}
        playsInline
        poster={previewSrc}
        src={fullScreenVideoSrc}
        {...fullScreenVideoProps}
      />
    ),
  };
};
