// https://www.notion.so/dapperlabs/What-s-a-Presenter-f2cff1d93bb247a3a9513ffaf693c19f
//
import { useActor } from '@xstate/react';
import { useRouter } from 'next/router';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import {
  FiltersContext,
  FiltersProps,
  PaginationContext,
  SortContext,
} from 'src/modules/search/fsm/types';
import {
  getActiveFiltersFromURLParams,
  getSortFromURLParams,
  getQueryFromURLParams,
  getActiveFiltersFromSelectedFilters,
} from 'src/modules/search/helpers';
import { useSearch, SearchProps } from 'src/modules/search/hooks/useSearch';

export type SearchController = {
  onClearAllFilters: () => void;
  onFetchMore: (any) => void;
  onRemoveFilter: (groupName: string, optionName: string | number) => void;
  onUpdateFilters: (any) => void;
  onUpdateSearch: (any) => void;
  onUpdateSort: (any) => void;
  state: {
    filters: FiltersContext;
    isNextPageAvailable: boolean;
    paginationState: PaginationContext;
    sort: SortContext;
  };
};
export type PaginationOptions = {
  itemPerPage: number;
  queryPaginationPath: string;
};

// ensure keys but not values.
export type SearchControllerProps = {
  browseSortOptions: any;
  expandedFiltersLocalStorageKey: any;
  filterOptions: any;
  options?: any;
  paginationOptions?: PaginationOptions;
  parseOptions?: any;
  query: any;
  queryDataPath: any;
  variables: any;
};

export const useSearchController = ({
  browseSortOptions,
  expandedFiltersLocalStorageKey,
  filterOptions,
  paginationOptions,
  options,
  parseOptions,
  query,
  queryDataPath,
  variables,
}: SearchControllerProps): SearchController => {
  const router = useRouter();
  const initialFilters = getActiveFiltersFromURLParams(router.query);
  const initialQuery = getQueryFromURLParams(router.query);
  const initialSort = getSortFromURLParams(router.query);
  // TODO: typechecking isn't working here.  changing any keys isn't caught.
  const searchProps: SearchProps = useMemo(() => {
    return {
      filters: {
        activeFilters: initialFilters,
        expandedFiltersLocalStorageKey,
        filterOptions: {
          ...filterOptions,
          // @ts-ignore
          ...parseOptions({}),
        },
        // @EXAMPLE, frozen filters will always be apart of the request payload
        // and cannot be changed by the user:
        // frozenFilters: {
        //   bySetIDs: ['123'],
        // },
      },
      options,
      pagination: {
        limit: paginationOptions?.itemPerPage,
        query,
        queryDataPath,
        queryPaginationPath: paginationOptions?.queryPaginationPath,
        variables,
      },
      search: {
        query: initialQuery,
      },
      sort: {
        // @ts-ignore
        sortBy: initialSort ?? Object.keys(browseSortOptions)[0],
        // @ts-ignore
        sortOptions: browseSortOptions,
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const { send: searchSend, state } = useSearch(searchProps);

  const [paginationState, paginationSend] = useActor(
    state?.children?.['infinite-scroll-machine'],
  );

  const [search, setSearch] = useState();

  const filters = state?.context?.filters;
  const sort = state?.context?.sort;
  // const totalCount = paginationState.context.totalCount;
  const isNextPageAvailable = paginationState?.context?.pageInfo?.hasNextPage;

  const onUpdateSearch = (event) => {
    setSearch(event.target.value);
  };
  useEffect(() => {
    searchSend({
      data: { query: search, router },
      type: state?.context.EVENTS.APPLY_SEARCH,
    });
    // strangely, the state.context object is changing constantly and can't be used as dep
    // onClose();
    // router is changing constantly so do not depend upon it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSend, search, state?.context.EVENTS.APPLY_SEARCH]);

  // Updating languages changes the categoryLabels as they are translated.
  // Ensure the categoryLabels are memoized as each change does refresh & requery
  useEffect(() => {
    searchSend({
      data: { filterOptions },
      type: state?.context.EVENTS.APPLY_FILTERS,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSend, filterOptions]);

  // Updating languages changes the browseSortOptions as they are translated.
  // Ensure the categoryLabels are memoized as each change does refresh & requery
  useEffect(() => {
    searchSend({
      data: { browseSortOptions },
      type: state?.context.EVENTS.UPDATE_SORTING,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchSend, browseSortOptions]);
  const onUpdateFilters = (newFilters: FiltersProps) => {
    searchSend({
      data: {
        activeFilters: getActiveFiltersFromSelectedFilters(newFilters),
        router,
      },
      type: state?.context.EVENTS.APPLY_FILTERS,
    });
  };
  const onRemoveFilter = useCallback(
    (groupName: string, optionName: string | number) =>
      searchSend({
        data: { groupName, optionName, router },
        type: state?.context.EVENTS.REMOVE_FILTER,
      }),
    // router is changing constantly so do not depend upon it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchSend, state?.context.EVENTS.REMOVE_FILTER],
  );
  const onClearAllFilters = useCallback(
    () =>
      searchSend({
        data: { router },
        type: state?.context.EVENTS.CLEAR_FILTERS,
      }),
    // router is changing constantly so do not depend upon it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchSend, state],
  );
  const updateSortingEvent = state?.context?.EVENTS.UPDATE_SORTING;

  const onUpdateSort = useCallback(
    (event: ChangeEvent<HTMLSelectElement>) => {
      searchSend({
        data: { router, sortBy: event.target.value },
        type: updateSortingEvent,
      });
    },
    // router is changing constantly so do not depend upon it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [searchSend, updateSortingEvent],
  );
  const onFetchMore = useCallback(() => {
    paginationSend({ type: paginationState?.context.EVENTS.FETCH_MORE });
  }, [paginationSend, paginationState]);

  return {
    onClearAllFilters,
    onFetchMore,
    onRemoveFilter,
    onUpdateFilters,
    onUpdateSearch,
    onUpdateSort,
    state: {
      filters,
      isNextPageAvailable,
      paginationState,
      sort,
    },
  };
};
