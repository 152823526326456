import { Common } from './common';
import { Components } from './components';
import { Content } from './content';

export const BANNER_SECTION_COMMON = 'BannerSection/common';
export const BANNER_SECTION_CONTENT = 'BannerSection/content';
export const BANNER_SECTION_COMPONENTS = 'BannerSection/components';

export const BannerSectionTheme = {
  [BANNER_SECTION_COMMON]: Common,
  [BANNER_SECTION_COMPONENTS]: Components,
  [BANNER_SECTION_CONTENT]: Content,
};
