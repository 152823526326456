import { gql } from '@apollo/client';

export const SUBMIT_USER_REWARD_PASS_TASK = gql`
  mutation SubmitUserRewardPassTask($input: SubmitUserRewardPassTaskInput) {
    submitUserRewardPassTask(input: $input) {
      success
      tasks {
        taskSlug
        rewardedPoints
        timesCompleted
        completedAt
      }
    }
  }
`;
