import {
  searchMarketplaceEditionsHandler,
  searchGolazosNftHandler,
  // @NOTE: these turn out undefined when imported from `@dapperlabs/core/fe`
  // (@dapperlabs/platform-services#1673)
  // getUserActiveReservationHandler,
  // getUserActiveReservationNoReservationHandler,
  // queueStatusHandler,
  // searchHandlers,
  dropHandlers,
} from '@dapperlabs/core-fe';
import {
  getUserActiveReservationHandler,
  getUserActiveReservationNoReservationHandler,
} from '@dapperlabs/core-fe/src/edge/Reservations/Queries/mocks';
import { queueStatusHandler } from '@dapperlabs/core-fe/src/modules/queueStatusMocks';
import { handlers as searchHandlers } from '@dapperlabs/core-fe/src/modules/search/components.useSearchController/mocks';
import base64url from 'base64url';
import { rest, graphql } from 'msw';
import {
  handlers as profileHandlers,
  MOCK_USER_FLOW_ADDRESS,
} from 'src/edge/getUserProfile/mocks';
import { joinDropHandler } from 'src/modules/Mutations/QueueService/mocks';
import { handlers as searchPackNFTsHandler } from 'src/modules/PackOpeningExperience/mocks';
import { handlers as packPurchaseHandlers } from 'src/modules/PackPurchase/mocks';
import searchAggregation from 'src/modules/Queries/searchAggregationMock.json';
// Default session handlers from nextauth
// https://next-auth.js.org/getting-started/client

// david orchard stage id
const validSession = {
  idToken:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6IkFyR215S3VxemotZHN1Qmw4eXRtVSJ9.eyJwcmVmZXJyZWRfdXNlcm5hbWUiOiJibGlzc2Z1bF9yZWRfd29sZjU4NzYiLCJwaWN0dXJlIjoiaHR0cHM6Ly9saDMuZ29vZ2xldXNlcmNvbnRlbnQuY29tL2EvQUFUWEFKeEtBQmVxUFY5VHNwb0I3dXlaWFFFQ0JvblkxLWg3YXI4X3hLNDQ9czk2LWMiLCJodHRwczovL2FjY291bnRzLm1lZXRkYXBwZXIuY29tL2lkZW50aXR5X3ZlcmlmaWVkIjpmYWxzZSwiaHR0cHM6Ly9hY2NvdW50cy5tZWV0ZGFwcGVyLmNvbS9mbG93X2FjY291bnRfaWQiOiIwYzAyOWM2N2M3Yzk1ODRkIiwiZ2l2ZW5fbmFtZSI6IkRhdmlkIiwiZmFtaWx5X25hbWUiOiJPcmNoYXJkIiwibmlja25hbWUiOiJkYXZpZC5vcmNoYXJkIiwibmFtZSI6IkRhdmlkIE9yY2hhcmQiLCJsb2NhbGUiOiJlbiIsInVwZGF0ZWRfYXQiOiIyMDIyLTEwLTIwVDE5OjEzOjI5Ljk0NloiLCJlbWFpbCI6ImRhdmlkLm9yY2hhcmRAZGFwcGVybGFicy5jb20iLCJlbWFpbF92ZXJpZmllZCI6dHJ1ZSwiaXNzIjoiaHR0cHM6Ly9hdXRoLnN0YWdpbmcubWVldGRhcHBlci5jb20vIiwic3ViIjoiZ29vZ2xlLW9hdXRoMnwxMDc1Njk3NDMwODQxNjQ5ODgwMjIiLCJhdWQiOiJDZ294TUdRVTZIbnl5TkhqdnYzaDJGR1p2eFhXOFNvciIsImlhdCI6MTY2NjM4NTUyNSwiZXhwIjoxNjY2Mzg2NDI1LCJub25jZSI6ImtYSGNXazdvZWdQOXZLMV9xd2hqVVM3YWNMa1NkREVITnNMYm15aTlpcWsifQ.chbn-m-A-iDjp3w73vIHCRvKNMjkPD_ngrJ-sqmpjDOoCsDNx0JPSOZwfTcX7Tu2liM2HjCuInlnbLpn0VHrNq5YZaRuIpzgnCBsKr-g9zQSbYDBtbvrkQlOdKrsbJlgmdJoqh8USZsHIJflSSVpbm0SRZbmAonbo9LC9rx5UC4F1oGvOYzAPdfIljJqpqNVJm6zUEiT0C5sSYHG_W5_xMV9pRYTIIIRNGGGUDN1j9ZF03fnitfAe8f6DCIwrol9Ls1krL8Klnloke1ueoQlpnFQNulaVFSTzTFbFx6uF5kF88AD5F_jWvvA1ZZjSzPGZMZE7n32O-pE8K9fhHRMfg',
  user: {
    email: 'test.user@dapperlabs.com',
    email_verified: true,
    family_name: 'User',
    given_name: 'Test',
    'https://accounts.meetdapper.com/flow_account_id': MOCK_USER_FLOW_ADDRESS,
    'https://accounts.meetdapper.com/identity_verified': false,
    locale: 'en',
    name: 'Test User',
    nickname: 'test_user',
    picture:
      'https://lh3.googleusercontent.com/a/AATXAJxKABeqPV9TspoB7uyZXQECBonY1-h7ar8_xK44=s96-c',
    preferred_username: 'blissful_red_wolf5876',
    sub: 'google-oauth2|107569743084164988022',
    updated_at: '2022-10-20T19:13:29.946Z',
  },
};

const flags = {
  isAllowedToBeta: true,
  isDropsEnabled: true,
  isFlowMaintenanceEnabled: false,
  isMarketplaceEnabled: true,
};

export function flagsHandler(overrides: any = {}) {
  return rest.get('/api/flags', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ flags: { ...flags, ...overrides } })),
  );
}

export const defaultSessionHandlers = [
  rest.get('/api/auth/me', (req, res, ctx) =>
    res(ctx.status(200), ctx.json(validSession.user)),
  ),
  rest.get('/api/auth/signin', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({})),
  ),
  rest.get('/api/auth/refresh', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({})),
  ),
  rest.get('/api/auth/signout', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({})),
  ),
  rest.post('/api/auth/signout', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({})),
  ),
  rest.get('/api/auth/session', (req, res, ctx) =>
    res(ctx.status(200), ctx.json(validSession)),
  ),
  rest.get('/api/auth/csrf', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({})),
  ),
  rest.get('/api/auth/providers', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({})),
  ),
  rest.post('/api/auth/_log', (req, res, ctx) => res(ctx.status(200))),
];

export const validSessionHandler = () =>
  rest.get('/api/auth/session', (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        idToken: `1.${base64url.encode(`{"exp":"${Date.now() + 1000}"}`)}`,
        user: validSession.user,
      }),
    ),
  );

export const invalidSessionHandler = () =>
  rest.get('/api/auth/session', (req, res, ctx) =>
    res(ctx.status(200), ctx.json({})),
  );

const queueOpenToPurchase = [
  // determining position
  queueStatusHandler({ entry: { placeInLine: 0 } }, { isOnce: true }),

  // in queue position 2
  queueStatusHandler({}, { isOnce: true }),

  // has a turn at first placeInLine
  queueStatusHandler({
    entry: { placeInLine: 1, turnStartAt: '2022-10-20T16:30:00Z' },
  }),
];

const boughtItem = [
  queueStatusHandler({
    entry: { placeInLine: 0, turnStartAt: '2022-10-20T16:30:00Z' },
  }),
  getUserActiveReservationNoReservationHandler(),
];

const queueInWaitingRoom = [
  queueStatusHandler({
    entry: {
      placeInLine: 0,
      startingPosition: 0,
    },
    queue: {
      drawTime: new Date(Date.now() + 1000 * 60 * 60 * 2).toISOString(),
      state: 'PREQUEUE',
    },
  }),
];
const queueEnded = [queueStatusHandler({ queue: { state: 'ENDED' } })];
const queueEndedYourTurn = [
  queueStatusHandler({
    entry: { turnStartAt: new Date(Date.now() - 60000).toISOString() },
    queue: { state: 'ENDED' },
  }),
];

// primitive workflows, should be in a mocks.tsx file eventually

const workflows = {
  boughtItem: [...boughtItem],
  happyPathPurchase: [
    ...queueOpenToPurchase,
    joinDropHandler(),
    getUserActiveReservationHandler({
      expired_at: new Date(Date.now() + 60000).toISOString(),
    }),
  ],
  queueEndedNoReservation: [
    ...queueEnded,
    getUserActiveReservationNoReservationHandler(),
  ],
  queueEndedReservation: [
    ...queueEndedYourTurn,
    getUserActiveReservationHandler({
      expired_at: new Date(Date.now() + 300000).toISOString(),
    }),
  ],
  queueInWaitingRoom: [...queueInWaitingRoom],
  searchBasic: searchHandlers.basic(),
  searchMarketplaceClosed: searchHandlers.closed(),
};

// default handlers
export const handlers = (options: any = {}) => {
  const name = options?.workflow;
  const workflow = workflows?.[name] ?? [];
  if (workflow?.length) {
    return workflow;
  }
  return [
    flagsHandler(),
    rest.get('https://cdn.contentful.com/spaces/*', (req, res, ctx) =>
      res(ctx.status(200), ctx.json({})),
    ),
    profileHandlers.getMyProfile(),
    graphql.query('searchAggregation', (req, res, ctx) =>
      res(ctx.data(searchAggregation)),
    ),
    ...defaultSessionHandlers,
    searchMarketplaceEditionsHandler(),
    searchGolazosNftHandler(),
    searchPackNFTsHandler.polling.sealed.searchPackNFTs()[0],
    ...dropHandlers.started.inStock.withQueue.queueRunning.authAndEligible(),
    ...packPurchaseHandlers.purchasePack(),

    // use regular status handler for in queue but no turn yet.
    // queueStatusHandler(),

    // can join queue as noentry.
    // queueStatusHandler({}, { isNoEntry: true }),

    // has a turn at 2 placeInLine
    // queueStatusHandler({ entry: { turnStartAt: '2022-10-20T16:30:00Z' } }),

    joinDropHandler(),
  ];
};
