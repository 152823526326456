import {
  Box,
  Button,
  Footer,
  IconDiscordLogo,
  IconFacebookLogo,
  IconInstagramLogo,
  IconTwitterLogo,
  IconYouTubeLogo,
  LanguageContext,
  LanguageIcon,
  SignOutIcon,
  TopNav,
  TopNavSelect,
  breakpoints,
  useFooterContentful,
  useMediaQuery,
} from '@dapperlabs/core-fe';
import { getCookie, setCookie } from 'cookies-next';
import { useRouter } from 'next/router';
import { ReactNode, useMemo } from 'react';
import { useSession } from 'src/edge/session';
import { EVENTS } from 'src/edge/session/fsm/types';
import useFooterAnalytics from 'src/general/hooks/useFooterAnalytics';
import { useLanguage } from 'src/general/hooks/useLanguage';
import useTopNavAnalytics from 'src/general/hooks/useTopNavAnalytics';
import { redirectToGuestSignUp } from 'src/lib/guest';
import { useFeatureFlags } from 'src/lib/launchDarkly';
import { languages, useTranslation } from 'src/lib/translations';
import { FinishSignUpAlert } from 'src/modules/Guest';
import { Flex, Image } from 'src/theme';

import {
  LALIGA_EMBLEM_ICON,
  LALIGA_TEAMS_ICONS,
} from './assets/png/football-teams';

enum PROFILE_MENU_KEYS {
  LOGOUT = 'LOGOUT',
  MY_WALLET = 'MY_WALLET',
  SETTINGS = 'SETTINGS',
}

export const socialMediaIconsUrls = {
  discord: 'https://discord.gg/LaLigaGolazos',
  facebook: 'https://www.facebook.com/LaLigaGolazos/',
  instagram: 'https://www.instagram.com/laligagolazos',
  twitter: 'https://twitter.com/LaLigaGolazos',
  youtube: 'https://www.youtube.com/channel/UC8P89zHH5oJWRmzK249KlIQ',
};

enum LANGUAGES {
  ENG = 'en-US',
  ESP = 'es',
}

const footerSocialMediaIcons = [
  {
    icon: IconTwitterLogo,
    label: 'twitter',
    url: socialMediaIconsUrls.twitter,
  },
  {
    icon: IconDiscordLogo,
    label: 'discord',
    url: socialMediaIconsUrls.discord,
  },
  {
    icon: IconInstagramLogo,
    label: 'instagram',
    url: socialMediaIconsUrls.instagram,
  },
  {
    icon: IconFacebookLogo,
    label: 'facebook',
    url: socialMediaIconsUrls.facebook,
  },
  {
    icon: IconYouTubeLogo,
    label: 'youtube',
    url: socialMediaIconsUrls.youtube,
  },
];

const LaligaEmblems = () => {
  return (
    <Flex flexDirection='column'>
      <Image
        width='auto'
        height={10}
        margin='0 auto 16px auto'
        objectPosition='top'
        alt='liga-emblem'
        src={LALIGA_EMBLEM_ICON.src}
      />
      <Flex flexWrap='wrap' flexDirection='row' maxWidth={400}>
        {LALIGA_TEAMS_ICONS.map((icon) => {
          return (
            <Image
              key={icon.src}
              width={10}
              height={10}
              objectPosition='top'
              alt='emblem'
              src={icon.src}
            />
          );
        })}
      </Flex>
    </Flex>
  );
};

export const Layout = (props: { children: ReactNode }) => {
  const { children } = props;
  const {
    send,
    state: {
      context: { preferredLanguage, auth, profile, guest },
    },
  } = useSession();

  const userProfile = useMemo(
    () => ({
      ...profile,
      name: auth?.user?.name,
      nickname: auth?.user?.nickname,
    }),
    [profile, auth],
  );

  const { t } = useTranslation();

  const { handleLanguage } = useLanguage();
  const {
    isDropsEnabled,
    isMarketplaceEnabled,
    isFlowMaintenanceEnabled,
    isGuestNavOverrideEnabled,
  } = useFeatureFlags();
  const router = useRouter();
  const [isMdUp] = useMediaQuery(`screen and (min-width: ${breakpoints.md})`);
  const userEmail = auth?.user?.email;
  const isSignedIn = !!userEmail;
  const locale =
    typeof getCookie('language') === 'string'
      ? String(getCookie('language'))
      : LANGUAGES.ENG;
  const { helpLinks: footerHelpLinks } = useFooterContentful({ locale });
  const {
    handleTrackFooterMainNav,
    handleTrackFooterSocialIcons,
    handleTrackFootersLinksClick,
  } = useFooterAnalytics();
  const {
    handleTrackTopNavMainNavLinks,
    handleTrackTopNavLanguageClick,
    handleTrackTopNavLanguageChange,
  } = useTopNavAnalytics();

  const initProfileNav = [
    {
      href: process.env.NEXT_PUBLIC_DAPPER_ACCOUNT_URL,
      key: PROFILE_MENU_KEYS.MY_WALLET,
      label: t('Navigation.MyWallet.Label'),
      onClick: handleTrackTopNavMainNavLinks.bind(null, {
        href: process.env.NEXT_PUBLIC_DAPPER_ACCOUNT_URL,
        label: t('Navigation.MyWallet.Label'),
      }),
    },
    {
      href: process.env.NEXT_PUBLIC_DAPPER_SETTINGS_URL,
      key: PROFILE_MENU_KEYS.SETTINGS,
      label: t('Navigation.Settings.Label'),
      onClick: handleTrackTopNavMainNavLinks.bind(null, {
        href: process.env.NEXT_PUBLIC_DAPPER_SETTINGS_URL,
        label: t('Navigation.Settings.Label'),
      }),
    },
    {
      icon: SignOutIcon,
      key: PROFILE_MENU_KEYS.LOGOUT,
      label: t('Logout.CTA'),
      onClick: () => {
        handleTrackTopNavMainNavLinks({
          label: t('Logout.CTA'),
        });
        send('SIGN_OUT');
      },
    },
  ];

  const mainNav = [
    {
      href: t('Navigation.Blog.URL'),
      label: t('Navigation.Blog.Label'),
    },
  ];

  if (profile) {
    mainNav.unshift({
      href: '/myCollection',
      label: t('Navigation.Collection.Label'),
    });
  }

  if (isDropsEnabled) {
    mainNav.unshift({
      href: '/drops',
      label: t('Navigation.Drop.Label'),
    });
  }

  if (isMarketplaceEnabled) {
    mainNav.unshift({
      href: '/marketplace/moments',
      label: t('Navigation.Marketplace.Label'),
    });
  }

  const profileNav = useMemo(
    () =>
      initProfileNav.filter((item) =>
        isMdUp ? true : item?.key !== PROFILE_MENU_KEYS.LOGOUT,
      ),
    [isMdUp],
  );

  const bottomLinks = [
    { href: '/privacy', label: t('Navigation.Privacy.Label') },
    { href: '/terms', label: t('Navigation.Terms.Label') },
  ];

  const handleAuth = () => {
    send(EVENTS.SIGN_IN);
  };

  const isGuestOverride = !!guest && isGuestNavOverrideEnabled;

  return (
    <LanguageContext.Provider value={handleLanguage}>
      <TopNav
        activeHref={router.asPath}
        additionalControls={
          <Flex direction='column' height='100%'>
            <TopNavSelect
              icon={<LanguageIcon boxSize='20px' />}
              items={languages}
              onChange={(value: string) => {
                handleTrackTopNavLanguageChange(value);
                send('UPDATE_LANGUAGE', { preferredLanguage: value });
                setCookie('language', LANGUAGES[value]);
                window.location.reload();
              }}
              onMenuClick={() => handleTrackTopNavLanguageClick()}
              value={preferredLanguage}
            />
            {isSignedIn && !isMdUp && (
              <Button
                mt='auto'
                onClick={() => send('SIGN_OUT')}
                variant='outline'
              >
                {t('Logout.CTA')}
              </Button>
            )}
          </Flex>
        }
        alert={isFlowMaintenanceEnabled ? t('FlowMaintenance.message') : null}
        isAuthed={isSignedIn}
        isBeta
        isFlowMaintenanceEnabled={isFlowMaintenanceEnabled}
        isMdUp={isMdUp}
        isSingleSignUpButton={isGuestOverride}
        navList={mainNav}
        onNavLinksClick={(item) => handleTrackTopNavMainNavLinks(item)}
        onSignUp={isGuestOverride ? redirectToGuestSignUp : handleAuth}
        profile={userProfile}
        profileNav={profileNav}
        socialMedias={footerSocialMediaIcons}
        textLogin={t('Login.CTA')}
        textSignUp={
          isGuestOverride ? t('Guest.nav.finishSignUp') : t('SignUp.CTA')
        }
      />
      <FinishSignUpAlert />
      <Box
        minHeight={{ base: '40vh', md: '60vh' }}
        borderBottom='1px solid #8A94A6'
      >
        {children}
      </Box>
      <Footer
        bottomLinks={bottomLinks}
        copyrights={[t('Footer.DapperCopyright'), t('Footer.LaligaCopyright')]}
        helpLinks={footerHelpLinks}
        mainNav={mainNav}
        onLinksClick={handleTrackFootersLinksClick}
        onMainNavLinkClick={handleTrackFooterMainNav}
        onSocialIconLinkClick={handleTrackFooterSocialIcons}
        rightPart={<LaligaEmblems />}
        socialMediaIcons={footerSocialMediaIcons}
      />
    </LanguageContext.Provider>
  );
};
