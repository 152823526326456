import { Global } from '@emotion/react';

import { BUCKET_STATIC_URL } from 'src/general/constants/assets';

// @NOTE: you can add fonts in here as necessary, see code samples at the bottom of the file:

const Fonts = () => {
  return (
    <Global
      styles={`
        /* Shapiro */
        
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-feather';
          src: url('${BUCKET_STATIC_URL}/fonts/Shapiro/Shapiro-35-Feather.otf') format('opentype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-light';
          src: url('${BUCKET_STATIC_URL}/fonts/Shapiro/Shapiro-65-Light.otf') format('opentype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-heavy';
          src: url('${BUCKET_STATIC_URL}/fonts/Shapiro/Shapiro-75-Heavy.otf') format('opentype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-super';
          src: url('${BUCKET_STATIC_URL}/fonts/Shapiro/Shapiro-95-Super.otf') format('opentype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-air';
          src: url('${BUCKET_STATIC_URL}/fonts/Shapiro/Shapiro-97-Air.ttf') format('truetype');
        }
        @font-face {
          font-display: swap;
          font-family: 'Shapiro-light-heavy';
          src: url('${BUCKET_STATIC_URL}/fonts/Shapiro/Shapiro-65-Light-Heavy.otf') format('opentype');
        }

        /* Druk */
        
        @font-face {
          font-display: swap;
          font-family: 'Druk';
          font-weight: 400;
          font-style: normal;
          src: url('${BUCKET_STATIC_URL}/fonts/Druk/Druk-Medium-Web.woff2') format('woff2');
        }
        @font-face {
          font-display: swap;
          font-family: 'Druk';
          font-weight: 700;
          font-style: normal;
          src: url('${BUCKET_STATIC_URL}/fonts/Druk/Druk-Bold-Web.woff2') format('woff2');
        }
        @font-face {
          font-display: swap;
          font-family: 'Druk';
          font-weight: 700;
          font-style: italic;
          src: url('${BUCKET_STATIC_URL}/fonts/Druk/Druk-BoldItalic-Web.woff2') format('woff2');
        }
      `}
    />
  );
};
export { Fonts };
