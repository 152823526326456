import React, { useContext } from 'react';
import { chakra, useMultiStyleConfig, Text } from 'src/theme';
import { GAME_HIGHLIGHTS_THEME_KEYS } from 'src/theme/dss-skeleton/components';

import { GameContext } from '../GameHighlights';
import { StatsItem } from './StatsItem';
import { TeamInfo } from './TeamInfo';

export const Statistics = (): JSX.Element => {
  const { statistics, homeTeam, awayTeam } = useContext(GameContext);
  const styles = useMultiStyleConfig(GAME_HIGHLIGHTS_THEME_KEYS.STATISTICS);

  return (
    <div>
      <chakra.div sx={styles.teamInfo}>
        <TeamInfo team={homeTeam} />
        <Text sx={styles.statsItemText}>&#8212;</Text>
        <TeamInfo team={awayTeam} />
      </chakra.div>
      {statistics && (
        <chakra.div sx={styles.statsWrapper}>
          {statistics.map(
            ({
              homeTeamValue,
              homeTeamTotalValue,
              awayTeamTotalValue,
              awayTeamValue,
              label,
            }) => (
              <StatsItem
                key={`stats-${label}`}
                awayTeamTotalValue={awayTeamTotalValue}
                awayTeamValue={awayTeamValue}
                homeTeamTotalValue={homeTeamTotalValue}
                homeTeamValue={homeTeamValue}
                label={label}
              />
            ),
          )}
        </chakra.div>
      )}
    </div>
  );
};
