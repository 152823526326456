import {
  DistributionImageType,
  DistributionVideoType,
} from '__generated__/globalTypes';
import { searchDistributionsV2_justDroppedCard_searchDistributionsV2_edges_node as TDistributionJustDropped } from 'src/edge/Distribution/queries/__generated__/searchDistributionsV2_justDroppedCard';
import { searchDistributionsV2_packs_searchDistributionsV2_edges_node as TDistributionPacks } from 'src/edge/Distribution/queries/__generated__/searchDistributionsV2_packs';
import { DistributionPhase } from 'src/modules/distribution/types';
import { getDistributionPhase } from 'src/modules/distribution/utils';

export const getIsDistributionSoldOut = (distribution: {
  availableSupply: number | null;
}) => {
  return distribution?.availableSupply === 0;
};

export const getIsDistributionExpired = (
  distribution: TDistributionPacks | TDistributionJustDropped,
) => {
  return getDistributionPhase(distribution) === DistributionPhase.ENDED;
};

/**
 *
 * @note: the typing on the distributions has changed so I'm making these
 * functions temporarily work both ways so I don't cause any regressions
 */
export function getImageURL(
  distribution: {
    images?:
      | ({ type: DistributionImageType | null; url: string | null } | null)[]
      | null;
    node?: {
      images:
        | ({ type: DistributionImageType | null; url: string | null } | null)[]
        | null;
    };
  },
  type: DistributionImageType,
) {
  const imagePath = distribution?.images ?? distribution?.node?.images;
  const image = imagePath?.find((img) => img.type.search(type) !== -1);
  return image?.url;
}

export function getVideoURL(
  distribution: {
    node?: {
      videos?:
        | ({ type: DistributionVideoType | null; url: string | null } | null)[]
        | null;
    };
    videos?:
      | ({ type: DistributionVideoType | null; url: string | null } | null)[]
      | null;
  },
  type: DistributionVideoType,
) {
  const videoPath = distribution?.videos ?? distribution?.node?.videos;
  const video = videoPath?.find((v) => v.type === type);
  return video?.url;
}
