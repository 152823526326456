// eslint-disable-next-line no-restricted-imports
import { Box, Text, Button, Flex } from '@chakra-ui/react';
import {
  LoadMorePresenter,
  LoadMoreMachineState,
} from '@dapperlabs/skeleton-core';
import { Search, SearchProps } from '@dapperlabs/skeleton-ux';
import merge from 'lodash/merge';
import React from 'react';
import { FS } from 'src/edge/infiniteScroll';
import { useIsClient } from 'src/general/utils/useIsClient';
import {
  SearchDefaultCopy,
  NFTListingEditionCardProps,
} from 'src/modules/search/components.useSearchController';
import { getSelectedFiltersFromActiveFilters } from 'src/modules/search/helpers';
import {
  useSearchController,
  SearchControllerProps,
} from 'src/modules/search/hooks/useSearchController';
import { CardGrid } from 'src/theme/dss-skeleton/components';

interface cardLanguage {
  BuyNow: string;
  LowestAsk: string;
  MomentsBurned: string;
  MomentsBurned_plural: string;
  ViewMomentDetails: string;
}

type Language = cardLanguage & SearchProps['language'];
export interface SearchModuleProps {
  NFTListingEditionCard: React.FC<NFTListingEditionCardProps>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  copy?: Language;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  search2NFTListingEditionCard: (string) => any;
  searchControllerOptions: SearchControllerProps;
}

// This component is a marketplace panel in a search page
// It binds the searchController and NFTListingEditionCard to skeleton Search component

export const SearchModule = ({
  NFTListingEditionCard,
  searchControllerOptions,
  search2NFTListingEditionCard,
  copy,
}: SearchModuleProps) => {
  // In SSR, Checkbox generates Unhandled Runtime Error
  // Error: Hydration failed because the initial UI does not match what was rendered on the server.
  // Note: this could also be fixed by putting this all in a component and using a dynamic import
  const isClient = useIsClient();
  const context = useSearchController(searchControllerOptions);

  if (!isClient) return;
  const {
    state,
    onUpdateFilters,
    onUpdateSort,
    onClearAllFilters,
    onFetchMore,
  } = context;
  const { filters, isNextPageAvailable } = state;
  const { activeFilters } = filters;

  const location = window?.location;

  const { edges: moments, totalCount } = state.paginationState.context;

  const paginationState = state.paginationState as LoadMoreMachineState;

  const nftListingCopy = merge({}, SearchDefaultCopy, copy);

  // Convert the searchController filter & sort options to skeleton-ux search filter & sort
  // Generally, searchController uses objects and skux search uses arrays
  // This could be refactored to make more functional/re-usable/cleaner, but we're not sure
  // how long this will continue to live, and if SC should change to match skux.
  const filterOptions = context.state.filters.filterOptions;
  const filterProps = Object.keys(filterOptions).map((key) => {
    const option = filterOptions[key];

    let options = option.options;

    // Sort labels when keys aren't in alphabetical order
    if (option?.sortLabels) {
      options = options.sort((a, b) => a.label.localeCompare(b.label));
    }

    const updatedFilters = getSelectedFiltersFromActiveFilters(activeFilters);

    // values are controlled and are an array of keys
    const value = activeFilters[key];
    const callback = (param) => {
      // param is an array of selected values
      // convert to searchController style of object with keys and true
      const selectedValues = param.reduce(
        (acc, key2) => ({
          ...acc,
          [key2]: true,
        }),
        {},
      );
      // object with key the name of the filter, the value is an object with
      // each selected key have value true, ie
      // {byTiers: {LEGENDARY: true}}
      const newFilters = {
        ...updatedFilters,
        [key]: selectedValues,
      };
      if (param.length === 0) {
        delete newFilters[key];
      }
      onUpdateFilters(newFilters);
    };

    return {
      callback,
      ...option,
      options,
      value,
    };
  });

  const controllerSortOptions = context.state.sort.sortOptions;
  const sortOptions = Object.keys(controllerSortOptions).map((key) => ({
    label: controllerSortOptions[key].language,
    value: key,
  }));
  const sortProps = {
    callback: onUpdateSort,
    label: 'Sort Results',
    options: sortOptions,
    value: context.state.sort.sortBy,
  };

  return (
    <Search
      count={totalCount}
      filters={filterProps}
      language={copy}
      navHeight={51}
      onClear={onClearAllFilters}
      sort={sortProps}
    >
      <LoadMorePresenter
        empty={
          <Text data-testid='card-grid-empty-result' variant='value'>
            {copy?.resultsEmpty ?? 'No Results'}
          </Text>
        }
        isEmpty={moments && moments.length === 0}
        state={paginationState}
      >
        <Box>
          <CardGrid
            sx={{ position: 'relative', top: '-65px' }}
            data-testid='card-grid'
          >
            {moments?.map((marketplaceEdition) => {
              const moment = marketplaceEdition.node;
              if (!moment) return null;
              const marketplaceData =
                search2NFTListingEditionCard(location)(moment);
              if (!marketplaceData) return null;

              return (
                <NFTListingEditionCard
                  key={`type-${marketplaceData?.editionId}`}
                  test-dataid={`type-${marketplaceData?.editionId}`}
                  {...marketplaceData}
                  copy={nftListingCopy}
                />
              );
            })}
          </CardGrid>
          {isNextPageAvailable && (
            <Flex align='center' justify='center' mt={8} mb={8}>
              <Button
                isLoading={paginationState?.value === FS.FETCH_MORE}
                onClick={onFetchMore}
              >
                {nftListingCopy.fetchMore}
              </Button>
            </Flex>
          )}
        </Box>
      </LoadMorePresenter>
    </Search>
  );
};
