import { DistributionImageType, EditionTier } from '__generated__/globalTypes';
import {
  searchDistributionsV2_packs_searchDistributionsV2_edges_node as TDistributionPacks,
  searchDistributionsV2_packs_searchDistributionsV2_edges_node_distributionEditionsV2_edges_node as TDistributionEditions,
} from 'src/edge/Distribution/queries/__generated__/searchDistributionsV2_packs';
import {
  DateArg,
  differenceInHours,
  getMonth,
  intervalToDuration,
  isBefore,
  isEqualOrAfter,
} from 'src/lib/helpers/dateLib';
import {
  DistributionAvailability,
  DistributionPhase,
} from 'src/modules/distribution/types';

export const getDistributionAvailability = ({
  endTime,
}: TDistributionPacks): keyof typeof DistributionAvailability => {
  // const isAvailable = availableSupply > 0;
  // TODO: determine availability
  const now = new Date(Date.now());
  const end = endTime ? new Date(endTime) : null;
  // if (!isAvailable) return DistributionAvailability.OUT_OF_STOCK;

  if (isBefore(end, now)) {
    return DistributionAvailability.DISCONTINUED;
  }

  return DistributionAvailability.IN_STOCK;
};

export const getDistributionPhase = (
  distribution: Partial<TDistributionPacks>,
): DistributionPhase | undefined => {
  const now = new Date(Date.now());
  const startTime = distribution?.startTime
    ? new Date(distribution.startTime)
    : null;
  const endTime = distribution?.endTime ? new Date(distribution.endTime) : null;
  const preQueueStartTime = distribution?.queue?.preQueueStartTime
    ? new Date(distribution.queue.preQueueStartTime)
    : null;

  if (!startTime && !endTime) return;

  if (isBefore(now, preQueueStartTime) && isBefore(now, startTime)) {
    return DistributionPhase.PREVIEW;
  }

  if (
    distribution?.queue &&
    isEqualOrAfter(now, preQueueStartTime) &&
    isBefore(now, startTime)
  ) {
    return DistributionPhase.PRE_QUEUE;
  }

  if (isBefore(now, startTime)) {
    return DistributionPhase.PREVIEW;
  }

  if (isEqualOrAfter(now, startTime) && isBefore(now, endTime)) {
    return DistributionPhase.STARTED;
  }

  if (isEqualOrAfter(now, endTime)) {
    return DistributionPhase.ENDED;
  }
};

const append0IfSingleDigit = (num) =>
  num.toString().length === 1 ? `0${num}` : `${num}`;

export const getFormattedDuration = (start: DateArg, end: DateArg) => {
  const interval = intervalToDuration({
    end,
    start,
  });

  const hoursInterval = Math.abs(differenceInHours(start, end));

  return {
    date: {
      // months are 0 indexed
      days: append0IfSingleDigit(new Date(end).getDate()),
      months: append0IfSingleDigit(getMonth(end) + 1), // calendar day date (ex. 1-31)
    },
    interval: {
      hours: append0IfSingleDigit(hoursInterval), // number of hours total (ex. 0-1000+)
      minutes: append0IfSingleDigit(interval.minutes), // clock minutes (ex. 0-60)
      seconds: append0IfSingleDigit(interval.seconds), // clock seconds (ex. 0-60)
    },
  };
};

export const getBackgroundImage = (images = []) =>
  images.reduce((acc, image) => {
    return image.type === DistributionImageType.BACKGROUND ? image.url : acc;
  }, '');

export const getItemImage = (images = []) =>
  images.reduce((acc, image) => {
    return image.type === DistributionImageType.DEFAULT ? image.url : acc;
  }, '');

export const getDistributionEditionsTiersCount = (
  distribution: TDistributionPacks,
) => {
  const DEFAULT_TIER_COUNT = {
    [EditionTier.COMMON]: { amount: 0, titleList: [] },
    [EditionTier.RARE]: { amount: 0, titleList: [] },
    [EditionTier.LEGENDARY]: { amount: 0, titleList: [] },
    [EditionTier.ULTIMATE]: { amount: 0, titleList: [] },
    [EditionTier.FANDOM]: { amount: 0, titleList: [] },
  };

  if (!distribution?.distributionEditionsV2?.edges) return DEFAULT_TIER_COUNT;

  return distribution.distributionEditionsV2?.edges.reduce(
    (acc, { node: distributionEdition }) => {
      if (!distributionEdition?.edition) return acc;

      const tier = distributionEdition?.edition?.tier;
      const name = distributionEdition?.edition?.series?.name;
      const { amount = 0, titleList = [] } = acc[tier];

      return {
        ...acc,
        [tier]: {
          amount: amount + 1,
          titleList: [...titleList, name],
        },
      };
    },
    DEFAULT_TIER_COUNT,
  );
};
export const EditionTierValue = {
  [EditionTier.COMMON]: 0,
  [EditionTier.RARE]: 1,
  [EditionTier.LEGENDARY]: 2,
  [EditionTier.FANDOM]: 3,
  [EditionTier.ULTIMATE]: 4,
};
export const sortEditionsByTier = (
  editions: TDistributionEditions[] = [],
  direction = 'asc',
): TDistributionEditions[] => {
  const sortableArray = [...editions];
  return sortableArray?.sort((a, b) => {
    return direction === 'desc'
      ? EditionTierValue[a.edition.tier] - EditionTierValue[b.edition.tier]
      : EditionTierValue[b.edition.tier] - EditionTierValue[a.edition.tier];
  });
};
