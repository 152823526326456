import { ComponentStyleConfig } from '@chakra-ui/react';

const theme: ComponentStyleConfig = {
  baseStyle: {
    controlWrapper: {
      alignItems: 'center',
      justifyContent: 'space-between',
      left: '0',
      position: 'absolute',
      top: '0',
      width: '100%',
    },
    defaultFullScreenVideo: {
      _fullScreen: {
        display: 'initial',
      },
      display: 'none',
      height: '100%',
      objectFit: 'contain',
      width: '100%',
    },
    fullScreenBody: {
      alignItems: 'center',
      display: 'flex',
      height: '100%',
      justifyContent: 'center',
      overflow: 'hidden',
      padding: '0',
      width: '100%',
    },
    fullScreenVideo: {
      height: 'calc(100vh - 6rem)',
      objectFit: 'contain',
      width: 'calc(100vw - 1rem)',
    },
    languagePicker: {
      position: 'absolute',
    },
    propertiesButton: {
      MsTransition: 'background 400ms linear',
      WebkitTransition: 'background 400ms linear',
      _active: {
        background: '#373B42',
      },
      _hover: {
        background: '#373B42',
      },
      background: 'transparent',
      height: '3rem',
      transition: 'background 400ms linear',
      width: '3rem',
      zIndex: '100',
    },
    volumeSlider: {
      bottom: '1rem',
      position: 'absolute',
      width: '30%',
    },
  },
  defaultProps: {},
  parts: [
    'closeButton',
    'controlWrapper',
    'defaultFullScreenVideo',
    'fullScreenBody',
    'fullScreenVideo',
    'fullscreenIcon',
    'languagePicker',
    'propertiesButton',
    'volumeSlider',
  ],
};

export const VIDEO_THEME_KEY = 'Video';
export const VideoTheme = {
  [VIDEO_THEME_KEY]: theme,
};
