import * as React from 'react';
import {
  chakra,
  forwardRef,
  useStyles,
  useTrackedOnClick,
} from 'src/theme/index';
import type {
  HTMLChakraProps,
  ThemingProps,
  TrackingProps,
} from 'src/theme/index';

export interface TopNavLinkProps
  extends TrackingProps,
    HTMLChakraProps<'a'>,
    ThemingProps<'a'> {
  children: React.ReactNode;
  href?: string;
}

export const TopNavLink = forwardRef<TopNavLinkProps, 'a'>(
  (
    {
      href,
      trackingId,
      trackingProperties,
      onClick: originalOnClick,
      ...props
    },
    ref,
  ) => {
    const styles = useStyles();

    const onClick = useTrackedOnClick({
      onClick: originalOnClick,
      trackingId,
      trackingProperties,
    });

    return (
      <chakra.a
        ref={ref}
        __css={styles.link}
        href={href}
        onClick={onClick}
        {...props}
      />
    );
  },
);
