import type { PackNFTFragment } from '__generated__/PackNFTFragment';
import type {
  searchPackNFTs as SearchPackNFTs,
  searchPackNFTsVariables as SearchPackNFTsVariables,
} from '__generated__/searchPackNFTs';
import {
  QUERY_PACK_NFTS,
  VARIABLES,
} from 'src/edge/searchPackNft/queries/searchPackNft';
import { useQuery } from 'src/lib/apollo';

export const useSearchPackNft = ({
  byIDs = [],
  query = QUERY_PACK_NFTS,
  variables: variablesParam = VARIABLES,
  options = {},
} = {}) => {
  const { data, state } = useQuery<SearchPackNFTs, SearchPackNFTsVariables>(
    query,
    {
      ...options,
      context: { clientName: 'platformAPI' },
      variables: variablesParam({ byIDs }),
    },
  );

  // @ts-ignore
  const packNfts: PackNFTFragment = data?.searchPackNft?.edges;
  return { packNfts, state };
};
