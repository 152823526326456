import React from 'react';

export type LanguageContextType = {
  languages: any[];
  onChangeLanguage?: (any) => void;
  selectedLanguage: any;
};
export const LanguageContext = React.createContext({
  languages: [],
  onChangeLanguage: null,
  selectedLanguage: {},
} as LanguageContextType);
