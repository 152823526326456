import { ComponentStyleConfig } from 'src/theme';

export const SalesHistoryTheme: ComponentStyleConfig = {
  baseStyle: {
    sectionHeader: {
      color: '#F8F8F8',
      fontSize: '4xl',
      fontWeight: '400',
      paddingBottom: '1.5rem',
    },
    tableHeader: {
      color: '#8A94A6',
      fontFamily: 'mono',
      fontSize: {
        base: 'xs',
        md: 'sm',
      },
      lineHeight: {
        base: '1.125rem',
        md: '1.3rem',
      },
      padding: '0.75rem',
    },
    tableRow: {
      '&:nth-of-type(4n)': {
        borderRadius: '0 0.75rem 0.75rem 0',
      },
      '&:nth-of-type(4n+1)': {
        borderRadius: '0.75rem 0 0 0.75rem',
      },
      background: '#17171B',
      color: 'white',
      fontSize: {
        base: 'xs',
        md: 'sm',
      },
      lineHeight: {
        base: '1.125rem',
        md: '1.3rem',
      },
      marginBottom: '0.25rem',
      paddingLeft: '0.75rem',
      paddingRight: '1.5rem',
      paddingY: '0.75rem',
    },
  },
  parts: ['sectionHeader', 'tableHeader', 'tableRow'],
};
