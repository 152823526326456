import { obliviousPandaPath } from 'src/modules/routes';

/**
 * Client side only function that builds up the parameters needed to send a page tracking event on our API.
 */
export default function sendTrackPage(): Promise<Response> {
  const properties = {
    path: window?.location.pathname ?? '',
    referrer: window?.document.referrer ?? '',
    search: window?.location.search ?? '',
    title: window?.document.title ?? '',
    url: window?.location.href ?? '',
  };

  return fetch(obliviousPandaPath(), {
    body: JSON.stringify(properties),
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });
}
