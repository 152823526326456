export enum VIDEO_EVENTS {
  FULLSCREEN_END = 'DSS-VIDEO-fullscreenEnd',
  FULLSCREEN_START = 'DSS-VIDEO-fullscreenStart',
}

export const isInViewport = (elem) => {
  const bounding = elem?.getBoundingClientRect();
  return (
    bounding.top >= 0 &&
    bounding.left >= 0 &&
    bounding.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    bounding.right <=
      (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const isApplePortableDevice = () => {
  if (/iPad|iPhone|iPod/.test(navigator.platform)) {
    return true;
  } else {
    return (
      !!navigator.maxTouchPoints &&
      navigator.maxTouchPoints > 2 &&
      /MacIntel/.test(navigator.platform)
    );
  }
};
