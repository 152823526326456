import { useApolloClient } from '@apollo/client';
import { useMachine } from '@xstate/react';
import { getMachine } from 'src/lib/xstate';
import { createMachine, State } from 'xstate';

import { actions } from './actions';
import { services } from './services';
import { Context, FS, Schema, Events, TypeState, EVENTS } from './types';

// https://xstate.js.org/viz/?gist=2dd10851563116be301b88438d203af5
export const machineDef = {
  context: {
    EVENTS,
    FS,
    client: undefined,
    edges: [],
    errorMessage: undefined,
    errorType: undefined,
    limit: undefined,
    pageInfo: undefined,
    query: undefined,
    queryDataPath: undefined,
    queryPaginationPath: undefined,
    totalCount: undefined,
  } as Context,
  id: 'infinite-scroll-machine',
  initial: FS.INITIAL,
  on: {
    REFETCH: {
      actions: ['clearFetchedData', 'assignVariables'],
      target: FS.FETCH,
    },
  },
  states: {
    [FS.INITIAL]: {
      always: [{ cond: 'skip', target: FS.IDLE }, { target: FS.FETCH }],
    },
    [FS.FETCH]: {
      invoke: {
        onDone: [
          {
            actions: ['assignFetchedData'],
            cond: 'hasData',
            target: FS.IDLE,
          },
          {
            target: FS.EMPTY,
          },
        ],
        onError: {
          actions: ['assignFetchError'],
          target: FS.ERROR,
        },
        src: 'fetch',
      },
    },
    [FS.FETCH_MORE]: {
      invoke: {
        onDone: [
          {
            actions: ['assignFetchedData'],
            target: FS.IDLE,
          },
        ],
        onError: {
          actions: ['assignFetchMoreError'],
          target: FS.IDLE,
        },
        src: 'fetch',
      },
    },
    [FS.IDLE]: {
      on: {
        FETCH_MORE: FS.FETCH_MORE,
      },
    },
    [FS.EMPTY]: {
      on: { RETRY: FS.FETCH },
    },
    [FS.ERROR]: {
      on: { RETRY: FS.FETCH },
    },
  },
};

const machine = createMachine<Context>(machineDef, {
  actions,
  guards: {
    hasData: (_, { data }) => typeof data?.edges !== 'undefined',
    skip: (context) => context?.skip,
  },
  services,
});

export const getInfiniteScrollMachine = ({ custom = {} }: any = {}) => {
  return getMachine<Context>(
    machineDef,
    {
      context: custom.context,
    },
    {
      actions,
      custom,
      guards: {
        hasData: (_, { data }) => typeof data?.edges !== 'undefined',
        skip: (context) => context?.skip,
      },
      services,
    },
  );
};

export type InfiniteScrollMachineState = State<
  Context,
  Events,
  Schema,
  TypeState,
  any
>;

export const useInfiniteScrollMachine = (
  context: Pick<
    Context,
    | 'query'
    | 'queryDataPath'
    | 'queryPaginationPath'
    | 'variables'
    | 'options'
    | 'limit'
  >,
) => {
  const client = useApolloClient();
  return useMachine(machine, {
    context: {
      client,
      ...context,
    },
  });
};

export type MachineArgs = Parameters<typeof useInfiniteScrollMachine>;
