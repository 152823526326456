import isEmpty from 'lodash/isEmpty';

import { FiltersProps, QueryFiltersProps } from './fsm/types';

export function getURLParamsFromActiveFilters(
  activeFilters: QueryFiltersProps,
  sortBy?: string,
) {
  let query = {};

  if (!isEmpty(activeFilters)) {
    for (const groupName in activeFilters) {
      const options = activeFilters[groupName];

      if (options.length > 0) {
        const encodedSymbol = encodeURIComponent(',');
        query = {
          ...query,
          [groupName]: options.join(encodedSymbol),
        };
      } else {
        query = {
          ...query,
          [groupName]: '',
        };
      }
    }
  }

  if (sortBy) {
    query = { ...query, sortBy };
  }

  return query;
}

export function getActiveFiltersFromSelectedFilters(
  filters: FiltersProps,
): QueryFiltersProps {
  const groups = {};

  for (const groupName in filters) {
    const options = getOptionsToApply(filters[groupName]);

    if (options?.length > 0) {
      groups[groupName] = options;
    }
  }

  return groups;
}

export function getOptionsToApply(
  options: {
    [optionName: string]: boolean;
  },
  toNumber?: boolean,
): (string | number)[] {
  if (!options) return null;

  return Object.keys(options)
    .filter((key) => options[key])
    .map((key) => (toNumber ? Number(key) : key));
}

export function getActiveFiltersFromURLParams(query: {
  [groupName: string]: string | string[];
}): QueryFiltersProps {
  const groups: QueryFiltersProps = {};

  for (const groupName in query) {
    const group = query[groupName];

    if (typeof group !== 'string') continue;
    if (groupName === 'sortBy') continue;
    if (groupName === 'username') continue;

    // treat commas in url same as encoded comma
    const parsedGroup = group.replace(/,/g, encodeURIComponent(','));
    const options: (string | number)[] = parsedGroup.split(
      encodeURIComponent(','),
    );

    groups[groupName] = options;
  }

  return groups;
}

export function getSelectedFiltersFromActiveFilters(
  activeFilters: QueryFiltersProps,
): FiltersProps {
  const groups: FiltersProps = {};

  for (const groupName in activeFilters) {
    const group = activeFilters[groupName];

    for (const value of group) {
      groups[groupName] = { ...groups[groupName], [value]: true };
    }
  }

  return groups;
}

export function getSortFromURLParams(query: {
  [groupName: string]: string | string[];
}) {
  const { sortBy } = query;

  if (typeof sortBy === 'string') {
    return sortBy;
  }
}

export function getQueryFromURLParams(query: {
  [groupName: string]: string | string[];
}) {
  const { query: queryParam } = query;

  if (typeof queryParam === 'string') {
    return queryParam;
  }
}
