import {
  statusPage,
  useSubscribeEmail,
  useMaintenanceBannerContext,
  Socials,
  StatusInfo,
} from '@dapperlabs/core-fe';
import { useEffect } from 'react';
import Head from 'src/general/components/Head/Head';
import { BUCKET_STATIC_URL } from 'src/general/constants/assets';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';
import { useTranslation } from 'src/lib/translations/useTranslation';
import {
  Grid,
  GridItem,
  Link,
  Stack,
  TextDeprecated,
  NextImage,
} from 'src/theme';

const Maintenance = () => {
  const { t } = useTranslation();
  const { state: subscribeState } = useSubscribeEmail();
  const { setBannerToasts } = useMaintenanceBannerContext();
  // const [email, setEmail] = useState('');

  useEffect(() => {
    const isSubscribeSuccess =
      subscribeState.value === subscribeState.context.FS.SUCCESS;
    const isSubscribeError =
      subscribeState.value === subscribeState.context.FS.ERROR;

    if (isSubscribeSuccess || isSubscribeError) {
      setBannerToasts({
        body: (
          <TextDeprecated color='black' size='sm' variant='light'>
            {t(
              isSubscribeSuccess
                ? 'Maintenance.EmailToast.success.body'
                : 'Maintenance.EmailToast.error.body',
            )}
          </TextDeprecated>
        ),
        status: isSubscribeSuccess ? 'success' : 'warning',
        title: t(isSubscribeSuccess ? 'general.success' : 'general.oops'),
      });

      // if (isSubscribeSuccess) setEmail('');
    }
  }, [subscribeState, setBannerToasts, t]);

  return (
    <Grid
      flexGrow='1'
      gridGap={8}
      gridTemplateColumns={{
        base: '1fr',
        lg: '1fr 1fr',
      }}
      mt={24}
    >
      <GridItem
        px={{ base: 6, lg: 14, md: 12 }}
        py={{ base: 0, lg: 20, md: 16 }}
      >
        <Head subtitle={t('Head.Maintenance.subtitle')} />
        <StatusInfo title={t('Maintenance.StatusInfo.title')} />
        {/* <form
        onSubmit={(e) => {
          e.preventDefault();
          subscribeUser({ userEmail: email });
        }}
      >
        <FormControl>
          <FormLabel id="email" htmlFor="email">
            <TextDeprecated variant="bold" size="md">
              {t('Maintenance.StatusInfo.inputDescription')}
            </TextDeprecated>
          </FormLabel>

          <HStack spacing={2} mt={4}>
            <InputField
              id="email"
              value={email}
              onChange={({ target: { value } }) => {
                setEmail(value);
              }}
              placeholder="Enter your email"
            />
            <Button type="submit" flexGrow={1} width="143px">
              {t('StatusInfo.InputField.buttonText')}
            </Button>
          </HStack>
        </FormControl>
      </form> */}
        <Stack alignItems='center' direction='row' gridGap={4}>
          <Socials />
          <Link
            href={statusPage}
            rel='noreferrer'
            target='_blank'
            trackingId={SEGMENT_EVENTS_ID_ENUM.MAINTENANCE_ERROR_GETNOTIFIED}
          >
            <TextDeprecated size='sm' variant='link'>
              {t('Maintenance.Status.linkText')}
            </TextDeprecated>
          </Link>
        </Stack>
      </GridItem>
      <GridItem
        position='relative'
        display={{
          base: 'none',
          lg: 'block',
        }}
      >
        <NextImage
          width='100%'
          objectFit='cover'
          objectPosition='center center'
          aria-hidden='true'
          layout='fill'
          src={`${BUCKET_STATIC_URL}/images/banner.png`}
        />
      </GridItem>
    </Grid>
  );
};

Maintenance.layout = 'takeover';

export default Maintenance;
