import type { ComponentStyleConfig } from '@chakra-ui/react';

export const legalListItem: ComponentStyleConfig = {
  // style all pack cards have in common
  baseStyle: {
    accordionButton: {
      _expanded: {
        backgroundColor: 'black',
      },
    },
    accordionPanel: {
      color: '#FFFFFF',
      flex: 'none',
      flexGrow: 0,
      fontFamily: 'main',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '203',
      letterSpacing: '0.16px',
      lineGeight: '20px',
      order: 1,
    },
    boxContainer: {
      flex: 1,
      textAlign: 'left',
    },
    headingText: {
      color: '#F8F8F8',
      fontFamily: 'heading',
      fontSize: '1.5rem',
      fontStyle: 'normal',
      fontWeight: '500',
      letterSpacing: '-0.02rem',
      lineHeight: '1.5rem',
    },
  },
  parts: ['accordionButton', 'boxContainer', 'headingText', 'accordionPanel'],
};
