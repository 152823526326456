/* eslint-disable sort-keys-fix/sort-keys-fix */
import { sansPrefix } from '@onflow/fcl';
import { GolazoEditionTier } from '__generated__/globalTypes';
import set from 'lodash/set';
import {
  getAllTeamsFilterData,
  getAllPlayerCountryData,
  getAllSetsData,
  getAllPlayersData,
  getAllPositionsData,
  getAllSeasonsData,
  getAllPlayTypesData,
} from 'src/modules/teams.golazos/utils';

const echoParamFunc = (key) => key;

type FilterOptions = (
  t?: any,
  options?: { excludeFilterKeys?: Array<string> },
) => any;

export const BROWSE_FILTER_OPTIONS: FilterOptions = (
  t = echoParamFunc,
  { excludeFilterKeys = [] } = {},
) => {
  const filterOptions = {
    byPlayerIds: {
      label: t('MarketplaceSearch.Player'),
      options: getAllPlayersData(),
      placeholder: t('MarketplaceSearch.Player.Placeholder'),
      query: { field: 'edition.play.metadata.player_data_id.in' },
      sortLabels: true,
      type: 'multi-select',
    },
    byTeamName: {
      label: t('MarketplaceSearch.Team'),
      options: getAllTeamsFilterData(),
      placeholder: t('MarketplaceSearch.Team.Placeholder'),
      query: { field: 'edition.play.metadata.match_highlighted_team.in' },
      type: 'multi-select',
    },
    byTiers: {
      label: t('MarketplaceSearch.Tier'),
      options: [
        {
          value: GolazoEditionTier.LEGENDARY,
          label: 'Legendary',
        },
        { value: GolazoEditionTier.RARE, label: 'Rare' },
        {
          value: GolazoEditionTier.UNCOMMON,
          label: 'Uncommon',
        },
        { value: GolazoEditionTier.COMMON, label: 'Common' },
        {
          value: GolazoEditionTier.FANDOM,
          label: 'Fandom',
        },
      ],
      query: { field: 'edition.tier.in' },
      type: 'checkbox',
    },
    bySet: {
      label: t('MarketplaceSearch.Set'),
      options: getAllSetsData(),
      placeholder: t('MarketplaceSearch.Set.Placeholder'),
      query: { field: 'edition.set.name.in' },
      type: 'multi-select',
    },
    byPlayType: {
      label: t('MarketplaceSearch.PlayType'),
      options: getAllPlayTypesData(t),
      query: { field: 'edition.play.metadata.play_type.in' },
      type: 'multi-select',
    },
    byPosition: {
      label: t('MarketplaceSearch.Position'),
      options: getAllPositionsData(t),
      query: { field: 'edition.play.metadata.player_position.in' },
      type: 'multi-select',
    },
    byPlayerCountry: {
      label: t('MarketplaceSearch.Nationality'),
      options: getAllPlayerCountryData(),
      placeholder: t('MarketplaceSearch.Nationality.Placeholder'),
      query: { field: 'edition.play.metadata.player_country.in' },
      type: 'multi-select',
    },
    bySeason: {
      label: t('MarketplaceSearch.Season'),
      options: getAllSeasonsData(),
      query: { field: 'edition.play.metadata.match_season.in' },
      type: 'multi-select',
    },
    forSale: {
      label: t('MarketplaceSearch.ForSale'),
      options: [{ value: 'true', label: t('MarketplaceSearch.ListedForSale') }],
      query: { field: 'listing.exists' },
      type: 'checkbox',
    },
  };

  // Since this configuration is shared, we can pass
  // an option to exclude any filter keys
  if (excludeFilterKeys.length > 0) {
    excludeFilterKeys.forEach((key) => {
      delete filterOptions[key];
    });
  }

  return filterOptions;
};

// Boolean filters that need custom query manipulation
const BOOLEAN_FILTER_KEYS = ['forSale'];

export const BROWSE_SORT_OPTIONS = (t = echoParamFunc) => ({
  LISTED_DATE_DESC: {
    language: t('MarketplaceSearch.Sort.Newest'),
    value: {
      listing: {
        updated_at: {
          block_height: { max: { direction: 'DESC', priority: 1 } },
        },
      },
    },
  },
  PRICE_ASC: {
    language: t('MarketplaceSearch.Sort.PriceAsc'),
    value: {
      listing: {
        price: {
          min: {
            direction: 'ASC',
            priority: 1,
          },
        },
      },
    },
  },
  PRICE_DESC: {
    language: t('MarketplaceSearch.Sort.PriceDesc'),
    value: {
      listing: {
        price: {
          min: {
            direction: 'DESC',
            priority: 1,
          },
        },
      },
    },
  },
});

export const VARIABLES_MARKETPLACE = ({
  filters,
  // search = {},
  first = 72, // per https://dapperlabs.slack.com/archives/C03QWEECMT4/p1669392909958139?thread_ts=1669382598.757029&cid=C03QWEECMT4
  // after = '',
  sortBy = undefined,
}) => {
  const nextPublicDapperUtilityCoinAddress =
    process.env.NEXT_PUBLIC_DAPPER_UTILITY_COIN_ADDRESS.split('0x')[1];
  const vault_type = `A.${nextPublicDapperUtilityCoinAddress}.DapperUtilityCoin.Vault`;

  const activeFiltersKeys = Object.keys(filters.activeFilters);
  const filtersQuery = activeFiltersKeys.reduce(
    (acc, currentKey) => {
      // Custom boolean key logic
      if (BOOLEAN_FILTER_KEYS.includes(currentKey)) {
        set(
          acc,
          filters.filterOptions[currentKey]?.query?.field,
          filters.activeFilters[currentKey]?.[0] === 'true',
        );
        return acc;
      }

      set(
        acc,
        filters.filterOptions[currentKey]?.query?.field,
        filters.activeFilters[currentKey],
      );

      return acc;
    },
    {
      burned_at: { exists: false },
      edition: {
        play: { metadata: {} },
        series: {},
        set: {},
        tier: {},
      },
      listing: {
        ft_vault_type: {
          eq: vault_type,
        },
        custom_id: { eq: 'DAPPER_MARKETPLACE', ignore_case: true },
      },
      type_name: {
        eq: `A.${sansPrefix(process.env.NEXT_PUBLIC_NFT_ADDRESS)}.Golazos.NFT`,
      },
    },
  );
  return {
    searchInput: {
      filters: filtersQuery,
      // ...search,
      ...(sortBy && { sortBy }),
      ...(first && { first }),
    },
  };
};

export const MY_COLLECTION_MOMENTS_SORT_OPTIONS = (t = echoParamFunc) =>
  //eslint-disable-next-line sort-keys-fix/sort-keys-fix
  ({
    UPDATE_DATE_DESC: {
      language: t('MyCollection.Sort.Newest'),
      value: {
        updated_at: {
          block_height: { direction: 'DESC', priority: 1 },
        },
      },
    },
    UPDATE_DATE_ASC: {
      language: t('MyCollection.Sort.Oldest'),
      value: {
        updated_at: {
          block_height: { direction: 'ASC', priority: 1 },
        },
      },
    },
    SERIAL_NUMBER_DESC: {
      language: t('MyCollection.Sort.HighestSerial'),
      value: {
        serial_number: {
          direction: 'DESC',
          priority: 1,
        },
      },
    },
    SERIAL_NUMBER_ASC: {
      language: t('MyCollection.Sort.LowestSerial'),
      value: {
        serial_number: {
          direction: 'ASC',
          priority: 1,
        },
      },
    },
    PLAYER_NAME_ASC: {
      language: t('MyCollection.Sort.PlayerNameAlphabetical'),
      value: {
        edition: {
          play: {
            metadata: {
              player_first_name: {
                direction: 'ASC',
                ignore_case: true,
                priority: 2,
              },
              player_known_name: {
                direction: 'ASC',
                ignore_case: true,
                priority: 1,
              },
              player_last_name: {
                direction: 'ASC',
                ignore_case: true,
                priority: 3,
              },
            },
          },
        },
      },
    },
    PRICE_ASC: {
      language: t('MyCollection.Sort.PriceAsc'),
      value: {
        listing: {
          price: {
            direction: 'ASC',
            priority: 1,
            missing: 'LAST',
          },
        },
      },
    },
    PRICE_DESC: {
      language: t('MyCollection.Sort.PriceDesc'),
      value: {
        listing: {
          price: {
            direction: 'DESC',
            priority: 1,
            missing: 'LAST',
          },
        },
      },
    },
  });

export const VARIABLES_MY_COLLECTION_MOMENTS = (ownerAddress) => {
  return ({
    filters,
    // search = {},
    first = 1000, // per https://dapperlabs.slack.com/archives/C03QWEECMT4/p1669392909958139?thread_ts=1669382598.757029&cid=C03QWEECMT4
    // after = '',
    sortBy = undefined,
  }) => {
    const activeFiltersKeys = Object.keys(filters.activeFilters);
    const filtersQuery = activeFiltersKeys.reduce(
      (acc, currentKey) => {
        // Custom boolean key logic
        if (BOOLEAN_FILTER_KEYS.includes(currentKey)) {
          set(
            acc,
            filters.filterOptions[currentKey]?.query?.field,
            filters.activeFilters[currentKey]?.[0] === 'true',
          );
          return acc;
        }

        set(
          acc,
          filters.filterOptions[currentKey]?.query?.field,
          filters.activeFilters[currentKey],
        );
        return acc;
      },
      {
        edition: { play: { metadata: { match_day: { exists: true } } } },
        owner_address: { eq: ownerAddress },
        type_name: {
          eq: `A.${sansPrefix(
            process.env.NEXT_PUBLIC_NFT_ADDRESS,
          )}.Golazos.NFT`,
        },
      },
    );
    return {
      searchInput: {
        filters: filtersQuery,
        // ...search,
        ...(sortBy && { sortBy }),
        ...(first && { first }),
      },
    };
  };
};
