import { FC, ReactElement } from 'react';
import FullWidthDefaultLayout from 'src/general/components/Layouts/FullWidth';
import { GradientSpillOver } from 'src/general/components/Layouts/GradientSpillOver';
import { Layout as DefaultLayout } from 'src/modules/Layout/Layout';

import { FullScreen } from './FullScreen';
import Takeover from './Takeover';

const layouts = {
  fullScreen: FullScreen,
  fullWidth: FullWidthDefaultLayout,
  gradientSpillOver: GradientSpillOver,
  takeover: Takeover,
  temporaryLaLiga: DefaultLayout,
};

type Props = {
  children: ReactElement;
};

// @NOTE: this could be a pretty general component. However the layouts it
// consumes (const layouts) might contain components that are quite specific to
// the la liga app.
const LayoutWrapper: FC<Props> = ({ children, ...rest }) => {
  // to get the text value of the assigned layout of each component
  const Layout = layouts[(children.type as any).layout];
  // if we have a registered layout render children with said layout
  if (Layout != null) {
    return <Layout {...rest}>{children}</Layout>;
  }
  // if not render children with fragment
  return <DefaultLayout {...rest}>{children}</DefaultLayout>;
};

export default LayoutWrapper;
