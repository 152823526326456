export const COUNTER_THEME_KEY = 'Counter';

const theme = {
  baseStyle: {
    container: {
      position: 'relative',
    },
    indicator: {
      bg: 'brand.base',
      border: '2px solid',
      borderColor: 'surface0',
      borderRadius: 'full',
      color: 'surface0',
      display: 'inline-block',
      fontSize: 'xs',
      fontWeight: 'bold',
      position: 'absolute',
      px: 1,
      right: `-${1.5}`,
      top: `-${1.5}`,
      zIndex: 2,
    },
  },
  defaultProps: {
    variant: 'solid',
  },
  variants: {
    solid: {
      bg: 'white',
    },
  },
};

export const CounterTheme = {
  [COUNTER_THEME_KEY]: theme,
};
