import { useRouter } from 'next/router';
import React, {
  ChangeEventHandler,
  KeyboardEventHandler,
  useEffect,
  useMemo,
} from 'react';
import { eurFormatter, usdFormatter } from 'src/lib/helpers/currencyFormatter';
import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Divider,
  Flex,
  ChakraNextImage,
  Link,
  Modal,
  ModalContent,
  ModalOverlay,
  Text,
  useBreakpointValue,
  useMultiStyleConfig,
  ModalCloseButton,
  Stack,
} from 'src/theme';
import {
  TRANSACTIONS_STAGES_PROGRESS,
  TransactionsModalI,
} from 'src/theme/dss-skeleton/components';
import { getStateListForSale } from 'src/theme/dss-skeleton/components/TransactionsModal/helpers';
import {
  ErrorIcon,
  SuccessfullyIcon,
  CrossIcon,
} from 'src/theme/dss-skeleton/themes/icons';

import {
  LIST_FOR_SALE_MODAL_CURRENCIES,
  TRANSACTIONS_STAGES,
  LIST_FOR_SALE_MODAL_RARITIES,
  LIST_FOR_SALE_MODAL_RARITIES_BACKGROUND,
  LIST_FOR_SALE_MODAL_STAGES,
} from './enums';
import { TransactionsModalActions } from './TransactionsModal.Actions';
import { TransactionsModalCircleTimer } from './TransactionsModal.CircleTimer';
import { TransactionsModalInput } from './TransactionsModal.Input';
import { TransactionsModalPriceSection } from './TransactionsModal.PriceSection';

const ADDITIONAL_INFO_COLOR = '#8A94A6';
const MIN_PRICE = 1.0;
const MAX_PRICE = 1000000;

const MARKETPLACE_PERCENTAGE_FEE = 0.05;

export function getListForSaleMarketplaceFee(inputPrice: number): number {
  return MARKETPLACE_PERCENTAGE_FEE * inputPrice;
}

export function getListForSaleTotalPayout(
  marketplaceFee: number,
  inputPrice: number,
): number {
  return inputPrice - marketplaceFee;
}

const TotalPrice = ({
  currency,
  formattedPrice,
}: {
  currency: string;
  formattedPrice: string;
}) => {
  const styles = useMultiStyleConfig('TransactionsModal');
  return (
    <Flex sx={styles.totalPrice}>
      {formattedPrice}
      <Text sx={styles.totalPriceCurrency}>{currency.toUpperCase()}</Text>
    </Flex>
  );
};

const Payout = ({ formattedMarketplaceFee, formattedTotalPayout }) => {
  const styles = useMultiStyleConfig('TransactionsModal');
  const { t } = useTranslation();
  return (
    <Stack sx={styles.payoutContainer}>
      <Flex justifyContent='space-between'>
        <Text color='#8A94A6' size='sm' variant='label'>
          {t('ListForSale.modal.marketplaceFee')}
        </Text>
        <Text color='#8A94A6' size='sm' variant='value'>
          {formattedMarketplaceFee}
        </Text>
      </Flex>
      <Divider />
      <Flex justifyContent='space-between'>
        <Text color='#8A94A6' fontWeight='600' size='sm' variant='label'>
          {t('ListForSale.modal.totalPayout')}
        </Text>
        <Text color='#8A94A6' size='sm' variant='body'>
          {formattedTotalPayout}
        </Text>
      </Flex>
    </Stack>
  );
};

export const TransactionsModal = ({
  imageSrc,
  isOpen = false,
  setIsOpen,
  prices,
  currency = LIST_FOR_SALE_MODAL_CURRENCIES.USD,
  momentInfo,
  state,
  setState,
  stage,
  setStage,
  typeModal,
  transactionStage,
  setTransactionStage,
  txId,
  additionalButtonHandler,
  submitModal,
}: TransactionsModalI) => {
  const {
    rarity = LIST_FOR_SALE_MODAL_RARITIES.RARE,
    tags = [],
    nameFootballer,
    currentPosition = 0,
    totalAmountMoments = 0,
    amountMomentsBurned = 0,
  } = momentInfo;

  const { price, error } = state;
  const router = useRouter();
  const { t } = useTranslation();
  const styles = useMultiStyleConfig('TransactionsModal');
  const isCentered = useBreakpointValue({ base: false, md: true });
  const isMobile = useBreakpointValue({ base: true, md: false });
  const marketplaceFee = getListForSaleMarketplaceFee(Number(price));

  const totalPayout = useMemo(
    () => getListForSaleTotalPayout(marketplaceFee, Number(price)),
    [marketplaceFee, price],
  );

  const formattedTotalPayout = totalPayout
    ? currency === LIST_FOR_SALE_MODAL_CURRENCIES.USD
      ? usdFormatter.format(totalPayout)
      : eurFormatter.format(totalPayout)
    : '--';
  const formattedMarketplaceFee = marketplaceFee
    ? `-${
        currency === LIST_FOR_SALE_MODAL_CURRENCIES.USD
          ? usdFormatter.format(marketplaceFee)
          : eurFormatter.format(marketplaceFee)
      }`
    : '--';

  useEffect(() => {
    if (TRANSACTIONS_STAGES.ERROR === transactionStage) {
      setStage(LIST_FOR_SALE_MODAL_STAGES.ERROR);
    }
    if (
      TRANSACTIONS_STAGES.SUCCESS === transactionStage ||
      TRANSACTIONS_STAGES.TOO_LONG_TRANSACTION_ERROR === transactionStage
    ) {
      setStage(LIST_FOR_SALE_MODAL_STAGES.SUCCESS);
    }
  }, [transactionStage]);

  const progress = useMemo(
    () => TRANSACTIONS_STAGES_PROGRESS[transactionStage],
    [transactionStage],
  );

  const validatePrice = (value: string) => {
    const validationRegex = /^(\d+)$/;

    if (!validationRegex.exec(value)) {
      return t('ListForSale.modal.invalidValue');
    }

    const amount = Math.round(parseFloat(value) * 100) / 100;

    if (amount < MIN_PRICE) {
      return t('ListForSale.modal.amountLessMin', {
        price: usdFormatter.format(MIN_PRICE),
      });
    }

    if (amount > MAX_PRICE) {
      return t('ListForSale.modal.amountMoreThan', {
        price: usdFormatter.format(MAX_PRICE),
      });
    }

    return null;
  };

  const handlePriceChange: ChangeEventHandler<HTMLInputElement> = ({
    target,
  }) => {
    const { value } = target;
    const normalizedValue = `${parseInt(value)}`;
    // The aim is to return an empty string if value is NAN.
    // This will prevent getting NAN in the input value.
    const formatedValue = isNaN(Number(normalizedValue)) ? '' : normalizedValue;

    setState({
      error: validatePrice(normalizedValue),
      price: formatedValue,
    });
  };

  const handleInput: KeyboardEventHandler<HTMLInputElement> = (event) => {
    if (event.key === '.') {
      event.preventDefault();
    }
  };

  const formattedPrice = useMemo(() => {
    return usdFormatter.format(Number(price));
  }, [price]);

  const stateListForSale = useMemo(() => {
    return getStateListForSale(transactionStage, typeModal);
  }, [transactionStage, typeModal]);

  const joinedTags = useMemo(() => tags.join(' · '), [tags]);

  const viewFlowLink = useMemo(
    () => `${process.env.NEXT_PUBLIC_FLOW_VIEW_SOURCE_URL}/tx/${txId}`,
    [txId],
  );

  const reset = () => {
    setState({ ...state, price: '' });
    setStage(LIST_FOR_SALE_MODAL_STAGES.INIT);
    setTransactionStage('');
  };

  const handleClose = () => {
    if (stage === LIST_FOR_SALE_MODAL_STAGES.SUCCESS) {
      return router.reload();
    }
    setIsOpen(!isOpen);
    reset();
  };

  return (
    <Modal
      isCentered={isCentered}
      isOpen={isOpen}
      onClose={handleClose}
      variant='listing'
    >
      <ModalOverlay bg='rgba(0, 0, 0, 0.6)' />
      <ModalContent sx={styles.container}>
        {isMobile && stage !== LIST_FOR_SALE_MODAL_STAGES.PROCESSING && (
          <ModalCloseButton
            zIndex={1}
            top='19px'
            right='12px'
            background='rgba(23, 23, 27, 0.36)'
            size='lg'
          />
        )}
        <Flex
          flexDirection={{ base: 'column', md: 'row' }}
          minHeight='inherit'
          margin={{ base: 0, md: 'auto' }}
        >
          <Flex sx={styles.imageWrapper}>
            <ChakraNextImage
              alignItems='center'
              display='flex'
              margin={isMobile ? 'auto' : 0}
              imageStyle={{
                height: isMobile ? '100%' : 'auto',
                width: isMobile ? 'auto' : '100%',
              }}
              src={imageSrc}
            />
          </Flex>
          <Box sx={styles.contentWrapper}>
            {!isMobile && stage !== LIST_FOR_SALE_MODAL_STAGES.PROCESSING && (
              <Box sx={styles.closeBtnWrapper} onClick={handleClose}>
                <CrossIcon sx={styles.crossIcon} />
              </Box>
            )}
            <Text
              sx={styles.title}
              width={LIST_FOR_SALE_MODAL_STAGES.SUCCESS ? '330px' : 'auto'}
              marginTop={{
                base: 0,
                md:
                  stage !== LIST_FOR_SALE_MODAL_STAGES.PROCESSING ? 0 : '63px',
              }}
            >
              {t(stateListForSale.titleKey)}
            </Text>
            {stage === LIST_FOR_SALE_MODAL_STAGES.PROCESSING && (
              <Flex alignItems='center' marginTop={2} marginBottom={4}>
                <TransactionsModalCircleTimer progress={Number(progress)} />
                <Text sx={styles.processingHelper}>
                  {t(stateListForSale.messageKey)}
                </Text>
              </Flex>
            )}
            {(stage === LIST_FOR_SALE_MODAL_STAGES.SUCCESS ||
              stage === LIST_FOR_SALE_MODAL_STAGES.ERROR) && (
              <Flex alignItems='center' marginTop='16px' marginBottom='16px'>
                {stage === LIST_FOR_SALE_MODAL_STAGES.ERROR ? (
                  <ErrorIcon width='29px' height='29px' />
                ) : (
                  <SuccessfullyIcon width='29px' height='29px' />
                )}
                <Text sx={styles.listingSuccessful}>
                  {t(stateListForSale.messageKey)}
                </Text>
              </Flex>
            )}
            {stage !== LIST_FOR_SALE_MODAL_STAGES.INIT && <Divider />}
            <Box padding='24px 0 35px 0'>
              <Text sx={styles.nameFootballer}>{nameFootballer}</Text>
              <Flex sx={styles.additionalInfo} mb={2}>
                <Text
                  sx={styles.typeRarity}
                  background={LIST_FOR_SALE_MODAL_RARITIES_BACKGROUND[rarity]}
                >
                  {t(`ListForSale.modal.rarity.${rarity.toLowerCase()}`)}
                </Text>
                <Text>{` #${currentPosition}/${totalAmountMoments}`}</Text>
              </Flex>
              <Box sx={styles.additionalInfo}>
                <Text color={ADDITIONAL_INFO_COLOR}>
                  {`${amountMomentsBurned} ${t(
                    'ListForSale.modal.momentsBurned',
                  )}`}
                </Text>
                {joinedTags && (
                  <Text color={ADDITIONAL_INFO_COLOR}>{joinedTags}</Text>
                )}
                {/*{showdown && (*/}
                {/*  <Text color={ADDITIONAL_INFO_COLOR}>*/}
                {/*    {`${t('ListForSale.modal.showdown')}: ${showdown}`}*/}
                {/*  </Text>*/}
                {/*)}*/}
              </Box>
            </Box>
            {stage !== LIST_FOR_SALE_MODAL_STAGES.INIT && (
              <>
                <TotalPrice
                  currency={currency}
                  formattedPrice={formattedPrice}
                />
                {txId && (
                  <Link
                    sx={styles.viewFlowLink}
                    href={viewFlowLink}
                    target='_blank'
                  >
                    {t('ListForSale.modal.viewFlow')}
                  </Link>
                )}
              </>
            )}

            {stage === LIST_FOR_SALE_MODAL_STAGES.INIT && <Divider />}
            {stage === LIST_FOR_SALE_MODAL_STAGES.INIT && (
              <>
                <Box padding='24px 0'>
                  <TransactionsModalPriceSection
                    currency={currency}
                    prices={prices}
                  />
                </Box>
                <Box pb={6}>
                  <TransactionsModalInput
                    error={error}
                    handleInput={handleInput}
                    handlePriceChange={handlePriceChange}
                    inputLabel={t('ListForSale.modal.inputLabel')}
                    price={price}
                  />
                </Box>
                <Payout
                  formattedMarketplaceFee={formattedMarketplaceFee}
                  formattedTotalPayout={formattedTotalPayout}
                />
              </>
            )}
            <TransactionsModalActions
              additionalButtonHandler={additionalButtonHandler}
              stage={stage}
              state={state}
              submitModal={submitModal}
              typeModal={typeModal}
            />
          </Box>
        </Flex>
      </ModalContent>
    </Modal>
  );
};
