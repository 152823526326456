import { graphql } from 'msw';

export const joinDropFactory = (overrides: Record<string, any> = {}): any => {
  return {
    joinDrop: { __typename: 'JoinDropResponse', success: true, ...overrides },
  };
};

export const joinDropHandler = (data = {}) => {
  return graphql.mutation<any>('JoinDropLaliga', (req, res, ctx) => {
    return res(ctx.data(joinDropFactory(data)));
  });
};
