import { InMemoryCache } from '@apollo/client';

// @NOTE: to generate mocks from the latest schema, run `npm run schema:download`

// @NOTE: if a field does not exist in the schema, extend it from its parent
// type in `src/lib/apollo/schema-local.graphql`

export const createCache = () =>
  new InMemoryCache({
    typePolicies: {
      GolazosNftAggregation: {
        keyFields: ['edition', ['id']],
      },
      MarketplaceTeamNFTSets: {
        keyFields: ['set', ['id']],
      },
      MomentListingsByEdition: {
        keyFields: ['edition', ['id']],
      },
      UserTask: {
        keyFields: ['taskSlug'],
      },
    },
  });
