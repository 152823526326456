import { useEffect, useContext, createContext, FC, ReactNode } from 'react';

import { useSessionMachine } from '../fsm/machine';
import { getSession, SessionEvent } from '../session-class';

type SessionMachineReturnType = ReturnType<typeof useSessionMachine>;
interface Context {
  send: SessionMachineReturnType[1];
  // there is an odd TS issue where the it keep expecting `value` to be a `string`, even though we have two substate nodes
  // in this case, e.g. `{ value: { auth, wallet }}`. We override to `any` for now
  state: SessionMachineReturnType[0] & { value: any };
}

export const SessionContext = createContext<Context>({} as Context);

export const SessionProvider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [state, send] = useSessionMachine();

  // subscribe to events from session so that we can do a thing when token refresh fails
  // like  show a modal and prompt the user to sign back in!
  useEffect(
    () => {
      const unsub = getSession().subscribe((eventName: SessionEvent) => {
        if (eventName === SessionEvent.REFRESH_FAIL) {
          alert('There was a problem with your session, please sign back in.');
        }
      });
      return () => unsub();
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [],
  );

  const sessionValue = {
    send,
    state,
  };

  return (
    <SessionContext.Provider value={sessionValue}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => useContext(SessionContext);
