import type { ComponentStyleConfig } from '@chakra-ui/react';

export const statistics: ComponentStyleConfig = {
  baseStyle: {
    awayTeam: {
      '> div': {
        alignItems: 'center',
        display: 'flex',
        gap: '0.5rem',
        justifyContent: 'flex-start',
      },
      alignSelf: 'start',
      justifyContent: 'start',
      justifySelf: 'start',
      svg: {
        margin: '0',
        order: '-1',
      },
    },
    homeTeam: {
      justifySelf: 'end',
      textAlign: 'right',
    },
    icon: {
      svg: {
        display: 'inline-block',
        height: '1rem',
        margin: '0 0.3rem',
        verticalAlign: 'middle',
        width: '1rem',
      },
    },
    row: {
      _first: {
        borderBottom: 'none',
      },
      _last: {
        borderBottomWidth: '1px',
      },
      borderColor: 'gray',
      borderStyle: 'solid',
      borderWidth: '1px 1px 0 1px',
      display: 'grid',
      gap: '4',
      gridTemplateColumns: '4rem 1fr 1fr',
      padding: '4',
    },
    statsItemHeader: { display: 'flex', justifyContent: 'space-between' },
    statsItemLabel: {
      color: 'gray.300',
      fontFamily: 'mono',
      fontSize: {
        base: 'xs',
        sm: 'sm',
      },
    },
    statsItemLineWrapper: {
      display: 'grid',
      gridGap: '0.5rem',
      gridTemplateColumns: '1fr 1fr',
    },
    statsItemText: {
      '&[data-winner="true"]': {
        color: '#F0F2FD',
      },
      alignSelf: 'center',
      fontSize: { base: '18px', md: '24px' },
      justifySelf: 'center',
    },
    statsItemWrapper: { margin: '0.75rem 0' },
    statsWrapper: {
      backgroundColor: 'black',
      padding: '2rem',
    },
    teamInfo: {
      '& > div': {
        _last: {
          '& > div': {
            flexDirection: 'row-reverse',
          },
          flexDirection: 'row-reverse',
        },
        gap: '3',
      },
      backgroundColor: 'black',
      borderBottom: '1px solid',
      borderColor: 'gray.600',
      // foundation/border (gray.600)
      display: 'grid',
      gap: '4',
      gridTemplateColumns: { base: '1fr 0 1fr', md: '1fr 1rem 1fr' },
      minHeight: { base: '64px', md: '115px' },
      padding: { base: 0, md: '0 20px' },
    },
    teamInfoHeader: {
      alignItems: 'center',
      display: 'flex',
      gap: '1rem',
    },
    teamInfoText: {
      alignSelf: 'center',
      fontFamily: 'heading',
      fontSize: { base: '18px', md: '24px' },
      justifySelf: 'center',
    },
    teamInfoWrapper: {
      alignItems: 'center',
      color: 'white',
      display: 'flex',
      justifyContent: 'space-between',
    },
    teamLogo: {
      '&.winner': {
        _after: {
          color: 'white',
          content: '"▼"',
          fontSize: {
            base: '0.7rem',
            sm: '1rem',
          },
          left: {
            base: '9px',
            sm: '22px',
          },
          position: 'absolute',
          top: {
            base: '-5px',
            sm: '-7px',
          },
        },
        borderColor: 'white',
        borderWidth: '1px',
      },
      alignItems: 'center',
      border: '2px solid',
      borderColor: 'gray.900',
      borderRadius: '50%',
      display: 'flex',
      flex: {
        base: '0 0 2rem',
        sm: '0 0 4rem',
      },
      height: {
        base: '2rem',
        sm: '4rem',
      },
      img: {
        height: '100%',
        maxWidth: {
          base: '2rem',
          sm: '4rem',
        },
        width: '100%',
      },
      justifyContent: 'center',
      position: 'relative',
      width: {
        base: '2rem',
        sm: '4rem',
      },
    },
  },
  defaultProps: {
    variant: 'common',
  },
  parts: [
    'teamInfoHeader',
    'statsItemLineWrapper',
    'statsItemText',
    'statsItemLabel',
    'statsItemHeader',
    'statsItemWrapper',
    'teamInfoWrapper',
    'teamLogo',
    'teamInfo',
    'row',
    'icon',
    'homeTeam',
    'awayTeam',
    'teamInfoText',
    'appInfo',
  ],
};

export default statistics;
