import { Box, BoxProps, useMultiStyleConfig } from 'src/theme';
import { CAROUSEL_THEME_KEY } from 'src/theme/dss-skeleton/components';

export type CarouselSlideProps = {
  onSlideLeave?: () => void;
  onSlideShow?: () => void;
  previewSrc?: string;
} & BoxProps;

export const CarouselSlide: React.FC<CarouselSlideProps> = ({
  children,
  onSlideShow,
  onSlideLeave,
  previewSrc,
  ...rest
}: CarouselSlideProps) => {
  const styles = useMultiStyleConfig(CAROUSEL_THEME_KEY);

  return (
    <Box sx={styles.slide} {...rest}>
      {children}
    </Box>
  );
};
