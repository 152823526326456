export enum LIST_FOR_SALE_MODAL_CURRENCIES {
  EUR = 'eur',
  USD = 'usd',
}

export enum LIST_FOR_SALE_MODAL_STAGES {
  ERROR = 'error',
  INIT = 'init',
  PROCESSING = 'processing',
  SUCCESS = 'success',
}

export enum LIST_FOR_SALE_MODAL_RARITIES {
  LEGENDARY = 'legendary',
  RARE = 'rare',
  UNCOMMON = 'uncommon',
}

export enum LIST_FOR_SALE_MODAL_RARITIES_BACKGROUND {
  LEGENDARY = 'linear-gradient(95.26deg, rgb(255, 231, 209) 0%, rgb(0, 255, 255) 45.83%, rgb(90, 127, 255) 100%)',
  RARE = 'linear-gradient(84.74deg, #FFEAB5 0%, #DFA821 100%)',
  UNCOMMON = 'linear-gradient(94.05deg, rgb(235, 242, 252) 1.53%, rgb(169, 185, 203) 100%)',
}

export enum TRANSACTIONS_STAGES {
  AUTHORIZING = 'AUTHORIZING',
  ERROR = 'ERROR',
  EXECUTING = 'EXECUTING',
  FINALIZING = 'FINALIZING',
  POLLING = 'POLLING',
  SEALING = 'SEALING',
  SUCCESS = 'SUCCESS',
  TOO_LONG_TRANSACTION_ERROR = 'TOO_LONG_TRANSACTION_ERROR',
}

export enum TRANSACTIONS_STAGES_PROGRESS {
  IDLE = 0,
  AUTHORIZING = 0,
  EXECUTING = 0.15,
  FINALIZING = 0.35,
  SEALING = 0.55,
  POLLING = 0.76,
  SUCCESS = 1,
  ERROR = 0,
  TOO_LONG_TRANSACTION_ERROR = 0,
}

export enum TRANSACTIONS_TYPE_MODAL {
  DELIST = 'DELIST',
  LISTING = 'LISTING',
  PURCHASE = 'PURCHASE',
}
