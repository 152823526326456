import React from 'react';
import { useTranslation } from 'src/lib/translations';
import {
  chakra,
  Flex,
  Heading,
  NextImage,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  useMultiStyleConfig,
} from 'src/theme';
import { GAME_HIGHLIGHTS_THEME_KEYS } from 'src/theme/dss-skeleton/components';
import { VideoMediaType } from 'src/theme/dss-skeleton/components/types';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';

import { Timeline } from '../Timeline';
import { TimelineItemI } from '../Timeline/types';
import { Statistics } from './statistics';

type TeamInfoType = {
  logo: string;
  score: number;
  title: string;
};
type PlayType = {
  awayTeam?: TeamInfoType;
  awayTeamGoals?: number;
  homeTeam?: TeamInfoType;
  homeTeamGoals?: number;
  momentMainVideo?: VideoMediaType;
  statistics: any;
  timeline?: Array<TimelineItemI>;
};
export type GameContextType = {
  awayTeam: TeamInfoType;
  checkWinner: (score: number) => boolean;
  duration: number;
  halfTime: number;
  homeTeam: TeamInfoType;
  logo: any;
  statistics: any[];
};
export const GameContext = React.createContext({} as GameContextType);

type gameHighlightsProps = {
  [key: string]: any;
  duration: number;
  logo: any;
  playData: PlayType;
};
export const GameHighlights = ({
  duration,
  playData,
  logo,
  ...props
}: gameHighlightsProps): JSX.Element => {
  const { t } = useTranslation();
  const { tab, tabList } = useMultiStyleConfig('Tab');
  const styles = useMultiStyleConfig(GAME_HIGHLIGHTS_THEME_KEYS.COMMON);
  const { events, trackHandler } = useDssAnalytics();
  const {
    homeTeam,
    awayTeam,
    statistics,
    homeTeamGoals,
    awayTeamGoals,
    timeline,
  } = playData;
  const halfTime = Math.ceil(duration / 2);
  const gameContextValue: GameContextType = {
    awayTeam,
    checkWinner: (score: number) =>
      score === Math.max(homeTeamGoals, awayTeamGoals),
    duration,
    halfTime,
    homeTeam,
    logo,
    statistics,
  };
  const showTimeline = Array.isArray(timeline) && timeline.length > 0;

  const teams = { awayTeam, homeTeam };
  const onTabChange = (event) => {
    if (!event.target.dataset.analyticId) {
      return;
    }
    trackHandler(events.GAME_HIGHLIGHTS_ITEM_CLICK, {
      item_label: `tab_${event.target.dataset.analyticId}`,
      tab_id: event.target.dataset.analyticId,
    });
  };

  return (
    <chakra.div {...props}>
      <Heading marginBottom='8' color='#F8F8F8' size='lg'>
        {t('gameHighlights.title')}
      </Heading>
      {/* To set Statistics tab active */}
      <Tabs onClick={onTabChange}>
        <TabList sx={tabList}>
          {showTimeline && (
            <Tab sx={tab} data-analytic-id='match'>
              {t('gameHighlights.timelineTab')}
            </Tab>
          )}
          <Tab sx={tab} data-analytic-id='statistics'>
            {t('gameHighlights.statisticsTab')}
          </Tab>
        </TabList>
        <GameContext.Provider value={gameContextValue}>
          <TabPanels>
            {showTimeline && (
              <TabPanel pt={6} pb={0} px={0}>
                <Flex sx={styles.appInfo}>
                  <NextImage width={98} height={39} src={logo.src} />
                  {/*<Box sx={styles.stadium}>
                      <CompassIcon mr='8px' />
                      Santiago Bernabéu Stadium
                    </Box>*/}
                </Flex>
                <Timeline
                  momentSrc={playData?.momentMainVideo.src}
                  teams={teams}
                  timeline={timeline}
                />
              </TabPanel>
            )}
            <TabPanel pt={6} pb={0} px={0}>
              <Flex sx={styles.appInfo}>
                <NextImage width={98} height={39} src={logo.src} />
                {/*<Box sx={styles.stadium}>
                  <CompassIcon mr='8px' />
                  Santiago Bernabéu Stadium
                </Box>*/}
              </Flex>
              <Statistics />
            </TabPanel>
          </TabPanels>
        </GameContext.Provider>
      </Tabs>
    </chakra.div>
  );
};
