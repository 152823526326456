import { gql } from '@apollo/client';

export const OffChainSetMetadataFragment = gql`
  fragment OffChainSetMetadataFragment on OffChainSetMetadata {
    description
  }
`;

export const SetFragment = gql`
  fragment SetFragment on Set {
    id
    flowID
    name
    offChainMetadata {
      ...OffChainSetMetadataFragment
    }
  }

  ${OffChainSetMetadataFragment}
`;
