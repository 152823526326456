import { FC } from 'react';
import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  Heading,
  Button,
  ModalHeader,
  ModalCloseButton,
  Badge,
  ModalProps,
} from 'src/theme';

type Props = {
  hasCloseButton?: boolean;
  titleKey?: string;
  txId?: string;
} & ModalProps;

export const SuccessModal: FC<Props> = ({
  titleKey,
  txId,
  children,
  hasCloseButton = true,
  ...rest
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      isCentered
      isOpen
      onClose={() => {}}
      scrollBehavior='outside'
      variant='listing'
      {...rest}
    >
      <ModalOverlay />
      <ModalContent
        maxW={{ base: 'sm', lg: 'xl', md: 'lg', sm: 'md' }}
        borderRadius={0}
      >
        {hasCloseButton && (
          <ModalHeader>
            <ModalCloseButton size='lg' />
          </ModalHeader>
        )}
        <ModalBody>
          <Box textAlign='center'>
            <Badge
              mb={8}
              px={6}
              py={3}
              borderRadius={8}
              colorScheme='badge.success'
            >
              {t('general.success')}
            </Badge>
            <Box mb={10}>
              {titleKey && (
                <Heading size='sm' variant='tame'>
                  {t(titleKey)}
                </Heading>
              )}
            </Box>
            {children}
            {txId && (
              <Box mt={8}>
                <Button
                  as='a'
                  sx={{
                    _hover: {
                      backgroundColor: 'transparent',
                      textDecoration: 'underline',
                      textTransform: 'none',
                    },
                  }}
                  p={2}
                  textTransform='none'
                  borderRadius={0}
                  href={`${process.env.NEXT_PUBLIC_FLOWSCAN}/${txId}`}
                  rel='noreferrer noopener'
                  target='_blank'
                  variant='link'
                >
                  {t('general.view.transaction')}
                </Button>
              </Box>
            )}
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
