import React, { createContext, useContext } from 'react';

import {
  TextDeprecated,
  Flex,
  HStack,
  Box,
  IconAlert,
  CloseButton,
  IconCheck,
  IconInfo,
  useToast,
} from 'src/theme';

type bannerType = {
  body: JSX.Element;
  position?: string;
  status: 'warning' | 'success' | 'info';
  title: string;
};

interface Context {
  setBannerToasts: ({ title, status, body }: bannerType) => void;
}

// create toast context
// using chakra
const MaintenanceBannerContext = createContext<Context>({} as Context);

export function useMaintenanceBannerContext(): Context {
  return useContext(MaintenanceBannerContext);
}

// create provider
export const MaintenanceBannerProvider = ({ children }) => {
  const toast = useToast();

  function close() {
    toast.closeAll();
  }

  const bannerContextValue = {
    setBannerToasts: ({ title, status, body, position }) => {
      let StatusIcon;

      switch (status) {
        case 'warning':
          StatusIcon = IconAlert;
          break;
        case 'success':
          StatusIcon = IconCheck;
          break;
        case 'info':
          StatusIcon = IconInfo;
          break;
        default:
          StatusIcon = IconAlert;
      }

      toast({
        duration: 10000,
        isClosable: true,
        position: position || 'bottom',
        render: () => (
          <Flex
            alignItems='center'
            justifyContent='space-between'
            px={[4, 16]}
            py={4}
            bg={status === 'success' ? 'success' : 'soldout'}
          >
            <HStack>
              <StatusIcon boxSize={6} mr={2} fill='black' />
              <Box>
                <TextDeprecated
                  mb={2}
                  color='black'
                  size='md'
                  variant='labelBold'
                >
                  {title}
                </TextDeprecated>
                {body}
              </Box>
            </HStack>

            <CloseButton color='black' onClick={close} />
          </Flex>
        ),
      });
    },
  };

  return (
    <MaintenanceBannerContext.Provider value={bannerContextValue}>
      {children}
    </MaintenanceBannerContext.Provider>
  );
};
