import { gql } from '@apollo/client';

export const SEARCH_PACK_NFT_OWNERSHIP = gql`
  query searchPackNft__pollOwnership(
    $query: String
    $filters: [PackNftFilter!]
  ) {
    searchPackNft(searchInput: { query: $query, filters: $filters }) {
      edges {
        node {
          id
          owner_address
          type_name
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;

export const GET_USER_RESERVATION = gql`
  query getUserActiveReservation__getReservation($distributionID: Int!) {
    getUserActiveReservation(input: { distributionID: $distributionID }) {
      reservation {
        distribution_id
        expired_at
        listing_resource_id
        price
        user_id
      }
    }
  }
`;

export const RESERVED_PACK_NFT = gql`
  query searchPackNft__getReservation(
    $query: String
    $filters: [PackNftFilter!]
  ) {
    searchPackNft(searchInput: { query: $query, filters: $filters }) {
      edges {
        node {
          id
          listing {
            storefront_address
            price
          }
          type_name
          __typename
        }
        __typename
      }
      __typename
    }
  }
`;
