import type { ComponentStyleConfig } from '@chakra-ui/react';

import { RarityTypes } from '../types';

export const rarityBackgroundValues = {
  [RarityTypes.COMMON]: '#888888',
  [RarityTypes.RARE]: 'linear-gradient(84.74deg, #FFEAB5 0%, #DFA821 100%)',
  [RarityTypes.FANDOM]: 'linear-gradient(95.26deg, #ADF85A 0%, #FDED45 100%)',
  [RarityTypes.UNCOMMON]:
    'linear-gradient(94.05deg, #EBF2FC 1.53%, #A9B9CB 100%)',
  [RarityTypes.LEGENDARY]:
    'linear-gradient(95.26deg, #FFE7D1 0%, #00FFFF 45.83%, #5A7FFF 100%)',
  [RarityTypes.ULTIMATE]:
    'linear-gradient(90deg, #FA00FF 7.29%, #B400FF 43.75%, #00FCFF 95.83%)',
};
export const RarityTierTheme: ComponentStyleConfig = {
  baseStyle: ({ variant }) => ({
    burnedContainer: {
      '& > p': {
        color: 'gray.200',
        fontSize: 'xs',
      },
      backgroundColor: 'gray.900',
      borderRadius: '0 0 12px 12px',
      paddingX: '3',
      paddingY: '1',
    },
    container: {
      border: '1px solid',
      borderColor: 'gray.900',
      borderTopLeftRadius: '12px',
      borderTopRightRadius: '12px',
    },
    editionDetails: {
      '& > div': {
        _last: {
          borderRight: 'none',
          marginRight: 'auto',
        },
        borderColor: 'gray.900 !important',
        borderRight: '1px solid',
        padding: '3',
      },
      width: '100%',
    },
    label: {
      color: 'gray.300',
      fontFamily: 'mono',
      fontSize: 'sm',
      whiteSpace: 'nowrap',
    },
    listingText: {
      color: '#F0F2FD',
      fontFamily: 'main',
      fontSize: '18px',
      letterSpacing: '-0.16px',
      marginRight: 'auto',
      width: 'fit-content',
    },
    rarityColumn: {
      borderColor: 'gray.800',
      borderRight: '1px solid',
      flexDirection: 'column',
      marginBottom: '1.5rem',
      paddingRight: '1.5rem',
    },
    rarityText: {
      background: `${rarityBackgroundValues[variant]}`,
      backgroundClip: 'text !important',
      fontSize: 'lg',
      textFillColor: 'transparent',
    },
    text: {
      color: 'white',
      fontSize: 'lg',
    },
  }),
  defaultProps: {
    variant: 'legendary',
  },
  parts: [
    'text',
    'rarityText',
    'label',
    'rarityColumn',
    'editionDetails',
    'container',
    'burnedContainer',
  ],
};
