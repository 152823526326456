/**
 * hook will return true when in client because useEffect
 * will only fire when mounted in browser.
 * process.browser condition is insufficient because
 * it breaks react hydration expectations.
 */
import { useEffect, useDebugValue, useState } from 'react';
export const useIsClient = () => {
  const [isClient, setIsClient] = useState(false);
  useDebugValue(isClient ? 'CSR' : 'SSR');
  useEffect(() => {
    setIsClient(true);
  }, []);
  return isClient;
};
