import type { ComponentStyleConfig } from '@chakra-ui/react';

export const header: ComponentStyleConfig = {
  baseStyle: {
    container: {
      paddingTop: '0.75rem',
    },
    nameContainer: {
      alignItems: 'center',
      justifyContent: 'space-between',

      svg: {
        height: '1.5rem',
        width: 'auto',
      },
    },
    playerImage: {
      borderRadius: '0.75rem 0.75rem 0 0',
      objectFit: 'cover',
      objectPosition: 'top',
      width: '100%',
    },
    playerImageContainer: {
      border: '1px solid',
      borderColor: '#2B2C33',
      borderRadius: '0.75rem',
      maxWidth: {
        lg: '38rem',
      },
      padding: '3',
      width: {
        md: '38rem',
      },
    },
    playerPositionText: {
      color: '#D6DAEB',
      fontSize: 'lg',
    },
  },
  defaultProps: {
    variant: 'common',
  },
  parts: [
    'container',
    'playerPositionText',
    'playerImageContainer',
    'playerImage',
    'nameContainer',
  ],
};
