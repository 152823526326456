/* eslint-disable no-async-promise-executor */
import { setExceptionUser } from '@dapperlabs/core-fe';
import * as fcl from '@onflow/fcl';
import base64url from 'base64url';
import { isEmpty, pick } from 'lodash';
import { flowAddressSansPrefix } from 'src/general/utils/flowAddress';
import { deleteAllCookies } from 'src/general/utils/helpers';
import {
  ConnectionMethod,
  getGuestService,
  getWalletFclConnectionMethod,
  initDapperGuest,
  setMetadata,
} from 'src/lib/fcl/dapper';
import { GuestSession } from 'src/lib/guest';

export enum SessionEvent {
  REFRESH_FAIL = 'REFRESH_FAIL',
  REFRESH_SUCCESS = 'REFRESH_SUCCESS',
}

/**
 * Session
 *
 * This class should be used as a singleton by acquiring the instance via getSession.
 * this ensures a single source of truth in an instance of the application.
 * Session information is used across apollo and SessionMachine so this guarantees
 * a single reference to idToken and a single path to fetch and refresh.
 */
export class Session {
  _sessionReadyPromise: Promise<void>;
  _isSessionPromiseActive: boolean;
  _guestData: GuestSession;
  idToken: string;
  data: any;
  _fclUnsubscribe: () => unknown;

  TOKEN_EXPIRY_BUFFER_SECONDS = 15;

  subs: Array<Function> = [];

  constructor() {
    this._sessionReadyPromise = new Promise(async (resolve, reject) => {
      this._isSessionPromiseActive = true;
      try {
        await this._loadSession();

        // if the initial session is invalid, attempt a refresh.
        // this is possible, because fetching a session does NOT perform an implicit refresh.
        if (!this.isValid()) {
          await this._refresh();
          await this._loadSession();
        }

        if ((await getWalletFclConnectionMethod()) === ConnectionMethod.GUEST) {
          if (!this.idToken) {
            // the user doesn't have a session but has a guest fcl session so lets get the data
            await this.initializeGuest();
          } else {
            // they have a normal session and an fcl guest session, lets kill the guest fcl session
            fcl.unauthenticate();
          }
        }

        // done loading the session, regardless of its validity, so anything waiting can resume
        resolve();
      } catch (error) {
        reject(error);
      } finally {
        this._isSessionPromiseActive = false;
      }
    });
  }

  /**
   * _loadSession
   * private method that fetches the latest session information from node server
   * and populates local idToken and data properties
   */
  private async _loadSession(): Promise<void> {
    const res = await fetch('/api/auth/session');
    const session = await res.json();
    this.data = !isEmpty(session)
      ? pick(session, ['idToken', 'user', 'error'])
      : null;
    this.idToken = this.data?.idToken;
  }

  /**
   * _refresh
   * @returns Promise<Response>
   * Refreshes the session on the server.
   * Because of logout-on-error behaviour, we need to use no-cors and we cannot
   * read any of the returned data.
   */
  private _refresh(): Promise<Response> {
    return fetch('/api/auth/refresh', { mode: 'no-cors' });
  }

  /**
   * sendEvent
   * @param eventName SessionEvent - the event to alert subscriptions of
   * @param rest any - any extra data to send along with the event.
   * notify any subscriptions of an event. Used for watching for a refresh failure
   * so we can kill the session.
   */
  private sendEvent(eventName: SessionEvent, ...rest): void {
    this.subs.forEach((c: Function) => {
      c(...[eventName, ...rest]);
    });
  }

  /** public methods **/

  /**
   * logout

   * logs the user out via some redirects. Deletes the cookie.
   * Because this method doesn't control whether anything is keeping
   * a copy of the token, you should assume the app state is unstable.
   */
  logout() {
    window?.analytics?.reset();
    localStorage?.clear();
    sessionStorage?.clear();
    deleteAllCookies();

    window?.location.replace('/api/auth/logout');
  }

  /**
   * refresh
   * @returns void
   *
   * request the Session to refresh itself. Calls for a refresh and a new load of session data.
   * If there is an idToken present after, we naievely assume the new session is valid.
   * If there is NO idToken present after, we assume the session is invalid.
   */
  async refresh(): Promise<void> {
    if (this._isSessionPromiseActive) return this._sessionReadyPromise;
    this._sessionReadyPromise = new Promise(async (resolve, reject) => {
      this._isSessionPromiseActive = true;
      try {
        await this._refresh();
        await this._loadSession();

        if (!this.idToken) {
          this.sendEvent(SessionEvent.REFRESH_FAIL);
          this.logout();
          throw new Error('Token refresh failed.');
        } else {
          this.sendEvent(SessionEvent.REFRESH_SUCCESS);
        }
        resolve();
      } catch (error) {
        reject(error);
      } finally {
        this._isSessionPromiseActive = false;
      }
    });
    return this._sessionReadyPromise;
  }

  async initializeGuest(email?: string) {
    // ensure the sdk is registered.
    await initDapperGuest();

    // set the email meta if present
    if (email) setMetadata(email);

    const user = await fcl.authenticate();
    const service = getGuestService(user?.services);
    if (service?.data?.token) {
      const flowAddress = flowAddressSansPrefix(user.addr);
      this._guestData = {
        flowAddress,
        token: service?.data?.token,
      };

      // normally we'd do this in ServiceIdentifier, but if the guest is initialized
      // after load, that won't trigger. This is just a quick solution to avoid an overhaul
      // as Sessionmachine in this app got a little bloated.
      setExceptionUser(flowAddress);
    }
    if (!this._guestData) throw new Error('Unable to acquire guest token');
  }

  /**
   * getData
   * @returns Promise<any> - the data blob from the session endpoint.
   * @todo properly type this
   * if there is an active _sessionReadyPromise in flight, this will wait for it to resolve
   */
  async getData(): Promise<any> {
    await this._sessionReadyPromise;
    return this.data;
  }

  /**
   * getIdToken
   * @returns Promise<string> - where the string is the current idToken
   * if there is an active _sessionReadyPromise in flight, this will wait for it to resolve
   */
  async getIdToken(): Promise<string> {
    await this._sessionReadyPromise;
    return this.idToken;
  }

  /**
   * getIsAuthenticated
   * @returns Promise<boolean> - true if there is a token
   * if there is an active _sessionReadyPromise in flight, this will wait for it to resolve
   */
  async getIsAuthenticated(): Promise<boolean> {
    await this._sessionReadyPromise;
    return !!this.idToken;
  }

  /**
   * getIsGuest
   * @returns Promise<GuestSession> - returns the GuestSession (just sub and flow_address)
   * if there is an active _sessionReadyPromise in flight, this will wait for it to resolve
   */
  async getGuestData() {
    await this._sessionReadyPromise;
    return this._guestData;
  }

  /**
   * isValid
   * @returns boolean - whether the session is valid or not
   *
   * A valid token is one of the follow conditions:
   * - No token at all. This is a valid session wherein the user is not authenticated.
   * - Has a token within the expiry time
   *
   * NOTE that this method does NOT wait for _sessionReadyPromise at this time.
   */
  isValid(): boolean {
    if (!this.idToken) return true; // guest user.

    const encodedPayload = this.idToken.split('.')[1];
    const { exp } = JSON.parse(base64url.decode(encodedPayload));
    return exp - this.TOKEN_EXPIRY_BUFFER_SECONDS > Date.now() / 1000;
  }

  /**
   * subscribe
   * @param callback Function - called whenever the class needs to send events
   * @returns Function - unsubscribe function that removes the callback from the list.
   */
  subscribe(callback: Function): Function {
    this.subs.push(callback);
    return (): void => {
      this.subs = this.subs.filter((c) => c !== callback);
    };
  }
}

let sharedSession: Session;
export const getSession = (): Session => {
  if (!sharedSession) sharedSession = new Session();
  return sharedSession;
};

export const deleteSession = (): void => {
  sharedSession = undefined;
};
