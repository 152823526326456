import round from 'lodash/round';
import React, { useCallback, useEffect, useState } from 'react';
import { DonutChart, DonutData } from 'src/general/components/DonutChart';
import { CirculationDescription } from 'src/general/components/MomentCirculation/CirculationDescription';
import { DESCRIPTION_COLORS } from 'src/general/components/MomentCirculation/themes/decription.theme';
import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Flex,
  Heading,
  IconInfo,
  Text,
  Tooltip,
  useMultiStyleConfig,
} from 'src/theme';

import { getFormattedCount } from '../../utils/stringFormatter';

type ChartData = DonutData & { percent: number };

type MomentCirculationProps = {
  [key: string]: unknown;
  burnedSize: number;
  burnedSizePercent: number;
  chartData: {
    id: string;
    percent: number;
    value: number;
  }[];
  circulationCount: number;
  circulationId: string;
  editionSize: number;
  sx?: unknown;
};

enum MomentStatus {
  FORSALE = 'forsale',
  HIDDEN = 'hidden',
  OWNED = 'owned',
  RESERVED = 'reserved',
}

export const MomentCirculation = ({
  chartData,
  circulationCount,
  editionSize,
  burnedSize,
  burnedSizePercent,
  circulationId,
  ...props
}: MomentCirculationProps): JSX.Element => {
  const { t } = useTranslation();
  const [donutData, setDonutData] = useState<DonutData[]>([]);
  const styles = useMultiStyleConfig('MomentCirculation/common');

  useEffect(() => {
    setDonutData(
      chartData.map((item) => {
        // For a more accurate representation of distribution,
        // owned will be mapped to represent items in a collection
        // that are NOT for sale
        if (item.id === MomentStatus.OWNED) {
          const forSaleItem = chartData.find(
            ({ id }) => id === MomentStatus.FORSALE,
          );
          const amountForSale = forSaleItem?.value || 0;
          const percentForSale = forSaleItem?.percent || 0;
          const value = item.value - amountForSale;
          const percent = round(item.percent - percentForSale, 3);
          item.value = value > 0 ? value : 0;
          item.percent = percent > 0 ? percent : 0;
        }
        return {
          ...item,
          strokeColor: DESCRIPTION_COLORS[item.id],
        };
      }),
    );
  }, [chartData]);

  const renderStatistics = useCallback(() => {
    return (
      <>
        <Text sx={styles.statisticsCount}>
          {getFormattedCount(circulationCount)}
        </Text>
        <Text sx={styles.statisticsTitle}>{t('moment.circulation.title')}</Text>
        {/*<Text sx={styles.statisticsMore}>
          {t('moment.circulation.learnMore')}
        </Text>*/}
      </>
    );
  }, [circulationCount, t, styles.statisticsCount, styles.statisticsTitle]);

  return (
    <Box sx={props.sx}>
      <Heading
        sx={styles.sectionHeader}
        display={{ base: 'block', md: 'none' }}
      >
        {t('moment.circulation.data')}
      </Heading>
      <Flex
        flexDirection={{ base: 'column', md: 'row' }}
        gap={{ base: '3.75rem', lg: '6.75rem' }}
      >
        <Box sx={styles.chartWrapper}>
          <DonutChart
            data={donutData}
            id={`${circulationId}-chart`}
            renderStatistics={renderStatistics}
            strokeWidth={1.5}
          />
        </Box>
        <Box flexGrow='1'>
          <Heading
            sx={styles.sectionHeader}
            display={{ base: 'none', md: 'block' }}
          >
            {t('moment.circulation.data')}
          </Heading>
          <Flex sx={styles.descriptionWrapper}>
            <Box sx={styles.descriptionItem}>
              <Text sx={styles.descriptionValue}>
                {getFormattedCount(editionSize)}
              </Text>
              <Text sx={styles.descriptionLabel}>
                {t('moment.circulation.editionSize')}
              </Text>
            </Box>
            <Box sx={styles.descriptionItem}>
              <Text sx={styles.descriptionValue} data-type='burned'>
                {getFormattedCount(burnedSize)}
              </Text>
              <Flex alignItems='center' justifyContent='end'>
                <Text sx={styles.descriptionLabel}>
                  {burnedSizePercent}% {t('moment.circulation.burned')}
                </Text>
                <Tooltip
                  label={t('moment.circulation.tooltip.burned')}
                  placement='top'
                >
                  <Box sx={styles.burnedTooltip}>
                    <IconInfo />
                  </Box>
                </Tooltip>
              </Flex>
            </Box>
          </Flex>
          {donutData.map(({ id, percent, value }: ChartData) => {
            return (
              <CirculationDescription
                key={`circulation-desc-${id}`}
                id={id}
                percent={percent}
                value={value}
              />
            );
          })}
        </Box>
      </Flex>
    </Box>
  );
};
