import React from 'react';
import { useMeasure } from 'react-use';

import { Box, BoxProps } from 'src/theme';

export const ProgressBarMarkers = ({ ...props }: BoxProps) => {
  const [ref, { width }] = useMeasure();

  return (
    <Box ref={ref} height='10px' {...props} position='relative' width='100%'>
      {[...Array(101)].map((_x, i) => {
        if (i % 5 === 0) {
          /* draws the tall (5 yard) hash marks */
          return (
            <Box
              // eslint-disable-next-line react/no-array-index-key
              key={i}
              position='absolute'
              top='0'
              left={`${i}%`}
              width='1px'
              height='100%'
              opacity='0.5'
              backgroundColor='white'
            />
          );
        } else {
          /* draws the short (1 yard) hash marks if the component is big enough*/
          if (width >= 500) {
            return (
              <Box
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                position='absolute'
                top='0'
                left={`${i}%`}
                width='1px'
                height='70%'
                opacity='0.2'
                backgroundColor='white'
              />
            );
          }
          return null;
        }
      })}
    </Box>
  );
};
