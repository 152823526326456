import {
  ArrowForwardIcon,
  CalendarIcon,
  ChevronDownIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ArrowBackIcon,
  CheckCircleIcon,
  WarningTwoIcon,
  LockIcon,
} from '@chakra-ui/icons';
import { Icon as ChakraIcon, IconProps, ChakraTheme } from '@chakra-ui/react';
import { InterpolationWithTheme } from '@emotion/core';
import {
  ChevronDown,
  Download,
  AtSign,
  Filter,
  User,
  Instagram,
  Linkedin,
  Twitter,
  Share2,
  Maximize2,
  X as Close,
  Check,
  ExternalLink,
  HelpCircle,
  Zap,
} from 'react-feather';

import AlertSvg from './svg/alertIcon.svg';
import BookmarkSvg from './svg/bookmarkIcon.svg';
import BornSvg from './svg/bornIcon.svg';
import BurnedSvg from './svg/burnedIcon.svg';
import BurnErrorSvg from './svg/burnError.svg';
import BurnSvg from './svg/burnV2.svg';
import CheckmarkSvg from './svg/checkmark.svg';
import ClockSvg from './svg/clock.svg';
import DiscordSvg from './svg/discord.svg';
import DraftSvg from './svg/draftIcon.svg';
import DropsAnnouncementIcon from './svg/dropsAnnouncementIcon.svg';
import FacebookSvg from './svg/facebook.svg';
import FilterIcon from './svg/filterIcon.svg';
import FireSvg from './svg/fireIcon.svg';
import FootballIconSvg from './svg/football-icon.svg';
import GearSvg from './svg/gearIcon.svg';
import GiftIconSvg from './svg/giftIcon.svg';
import GiftIconBlack from './svg/giftIconBlack.svg';
import HamburgerMenuSvg from './svg/hamburger-menu.svg';
import HiddenSvg from './svg/hiddenIcon.svg';
import HometownSvg from './svg/hometownIcon.svg';
import HourGlassRunning from './svg/hourGlassRunning.svg';
import InfoSvg from './svg/info.svg';
import InfoOutlineSvg from './svg/infoOutline.svg';
import InstagramSvg from './svg/instagram.svg';
import LockedSvg from './svg/locked.svg';
import LongArrowRightSvg from './svg/longArrowRight.svg';
import MarketIcon from './svg/marketIcon.svg';
import MomentsSVG from './svg/momentsIcon.svg';
import OwnedSvg from './svg/ownedIcon.svg';
import PacksSVG from './svg/packsIcon.svg';
import PaginationArrowLeftSVG from './svg/paginationArrowLeft.svg';
import PaginationArrowRightSVG from './svg/paginationArrowRight.svg';
import PencilSVG from './svg/pencilIcon.svg';
import PlaybookSVG from './svg/playbook.svg';
import PlusSVG from './svg/plusIcon.svg';
import RarityCommonSvg from './svg/rarityCommon.svg';
import RarityLegendarySvg from './svg/rarityLegendary.svg';
import RarityRareSvg from './svg/rarityRare.svg';
import RarityUltimateSvg from './svg/rarityUltimate.svg';
import RefreshSvg from './svg/refreshIcon.svg';
import RepeatSvg from './svg/repeatIcon.svg';
import RightArrow from './svg/rightArrow.svg';
import ScaleSvg from './svg/scale.svg';
import Spinner from './svg/spinner.svg';
import Stack from './svg/stack.svg';
import StarSvg from './svg/starV2.svg';
import StoreLine from './svg/StoreLine.svg';
import TradeIn from './svg/tradeInIcon.svg';
import TriangleSvg from './svg/triangle.svg';
import TrophySvg from './svg/trophyIcon.svg';
import TwitchSvg from './svg/twitch.svg';
import TwitterSvg from './svg/twitter.svg';
import UnavailableSvg from './svg/unavailable.svg';
import UnavailableEditionSvg from './svg/unavailableIcon.svg';
import UnlockedSvg from './svg/unlocked.svg';
import VolumeOffSvg from './svg/volumeOff.svg';
import VolumeOnSvg from './svg/volumeOn.svg';
import WalletSvg from './svg/wallet.svg';
import YouTubeSvg from './svg/youtube.svg';

type ChakraIconProps = IconProps & {
  css?: InterpolationWithTheme<ChakraTheme>;
};

export const ICON_ARROW_FORWARD = {
  alt: 'Next',
  as: ArrowForwardIcon,
  h: '20px',
  w: '20px',
};
// export const ICON_ARROW_BACK = {
//   as: ArrowBackwardIcon,
//   w: '20px',
//   h: '20px',
//   alt: 'Next',
// };
export const ICON_CHECK = {
  alt: 'Checkmark',
  as: Check,
  h: '16px',
  w: '16px',
};
export const ICON_CHEVRON_DOWN = {
  alt: 'Chevron Down',
  as: ChevronDown,
  h: '24px',
  w: '24px',
};
export const ICON_DOWNLOAD = {
  alt: 'Download it',
  as: Download,
  h: '34px',
  w: '34px',
};
export const ICON_ATSIGN = {
  alt: 'Share view Email',
  as: AtSign,
  h: '35px',
  w: '44px',
};
export const ICON_FILTER = {
  alt: 'Filter',
  as: Filter,
  h: '15px',
  w: '16px',
};
export const ICON_USER = {
  alt: 'Show user menu',
  as: User,
  h: '23px',
  w: '21px',
};
export const ICON_LINKEDIN = {
  alt: 'Show Instagram',
  as: Linkedin,
  h: '17px',
  w: '17px',
};
export const ICON_INSTAGRAM = {
  alt: 'Show Instagram',
  as: Instagram,
  h: '17px',
  w: '17px',
};
export const ICON_TWITTER = {
  alt: 'Show twitter',
  as: Twitter,
  h: '17px',
  w: '17px',
};
export const ICON_X = {
  alt: 'Close',
  as: Close,
  h: '18px',
  w: '18px',
};
export const ICON_HELP = {
  alt: 'Helpful information',
  as: HelpCircle,
  h: '16px',
  w: '16px',
};

export const Icon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} />;
};

export const IconPlus = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PlusSVG} />;
};

export const IconPencil = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PencilSVG} />;
};

export const IconPlaybook = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PlaybookSVG} />;
};

export const IconHamburgerMenu = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={HamburgerMenuSvg} />;
};

export const IconYouTubeLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={YouTubeSvg} />;
};

export const IconInstagramLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={InstagramSvg} />;
};

export const IconDiscordLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DiscordSvg} />;
};

export const IconTwitterLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TwitterSvg} />;
};

export const IconFacebookLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={FacebookSvg} />;
};

export const IconTwitchLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TwitchSvg} />;
};

export const IconClock = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ClockSvg} />;
};

export const IconInfo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={InfoSvg} />;
};
export const IconInfoOutline = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={InfoOutlineSvg} />;
};

export const OwnedEditionLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={OwnedSvg} />;
};

export const UnavailableEditionLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={UnavailableEditionSvg} />;
};

export const UnavailableIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={UnavailableSvg} />;
};

export const HiddenEditionLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={HiddenSvg} />;
};

export const HourGlassRunningIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={HourGlassRunning} />;
};

export const BurnedEditionLogo = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BurnedSvg} />;
};

export const IconFire = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={FireSvg} />;
};

export const IconLocked = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={LockedSvg} />;
};

export const IconUnlocked = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={UnlockedSvg} />;
};

export const IconBurnError = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BurnErrorSvg} />;
};

export const IconBorn = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BornSvg} />;
};

export const IconHometown = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={HometownSvg} />;
};

export const IconDraft = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DraftSvg} />;
};

export const IconMaximize = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Maximize2} />;
};

export const IconShare = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Share2} />;
};

export const LegendaryIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RarityLegendarySvg} />;
};

export const UltimateIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RarityUltimateSvg} />;
};

export const RareIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RarityRareSvg} />;
};

export const CommonIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RarityCommonSvg} />;
};

export const IconGear = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GearSvg} />;
};

export const IconFootball = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={FootballIconSvg} />;
};

export const IconDropsAnnouncement = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DropsAnnouncementIcon} />;
};

export const IconDropsMarket = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={MarketIcon} />;
};

export const IconClose = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Close} />;
};

export const IconAlert = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={AlertSvg} />;
};

export const IconCheckmark = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CheckmarkSvg} />;
};

export const IconChevronDown = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ChevronDownIcon} />;
};

export const IconChevronLeft = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ChevronLeftIcon} />;
};

export const IconBackArrow = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ArrowBackIcon} />;
};

export const IconForwardArrow = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ArrowForwardIcon} />;
};

export const IconChevronRight = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ChevronRightIcon} />;
};

export const IconCalendar = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CalendarIcon} />;
};

export const IconCheckCircle = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CheckCircleIcon} />;
};

export const IconCheck = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Check} />;
};

export const IconTriangle = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TriangleSvg} />;
};

export const IconRightArrow = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RightArrow} />;
};

export const IconRefresh = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RefreshSvg} />;
};
export const IconProfileMoments = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={MomentsSVG} />;
};
export const IconProfilePacks = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PacksSVG} />;
};

export const IconPaginationArrowLeft = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PaginationArrowLeftSVG} />;
};
export const IconPaginationArrowRight = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PaginationArrowRightSVG} />;
};

export const VolumeOn = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={VolumeOnSvg} />;
};
export const VolumeOff = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={VolumeOffSvg} />;
};
export const IconExternalLink = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ExternalLink} />;
};

export const IconFilter = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={FilterIcon} />;
};

export const RepeatIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RepeatSvg} />;
};

export const IconStoreLine = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={StoreLine} />;
};

export const ScaleIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ScaleSvg} />;
};

export const TradeInIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TradeIn} />;
};

export const SpinnerIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Spinner} />;
};

export const StackIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Stack} />;
};

export const LongArrowRightIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={LongArrowRightSvg} />;
};
export const WalletIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={WalletSvg} />;
};
export const BookmarkIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BookmarkSvg} />;
};
export const StarIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={StarSvg} />;
};
export const BurnIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BurnSvg} />;
};

export const Warning2Icon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={WarningTwoIcon} />;
};

export const GiftIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GiftIconSvg} />;
};

export const GiftIconB = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GiftIconBlack} />;
};

export const ZapIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={Zap} />;
};
export const Lock2Icon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={LockIcon} />;
};
export const TrophyIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TrophySvg} />;
};
