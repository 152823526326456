import {
  EVENTS as PAGINATION_EVENTS,
  FS as PAGINATION_FS,
} from 'src/edge/infiniteScroll';
import { getMachine } from 'src/lib/xstate';
import { MachineConfig, send } from 'xstate';

import { actions, guards, services } from './options';
import { FS, EVENTS, Context, Events } from './types';

// @NOTE: even though this machine has two states and those two states only exist to drive another machine
// as of right now it is cleaner to use xstate instead of moving all functionality to a `useSearch` hook
// exploration was done here https://github.com/dapperlabs/nfl-app/pull/2383
// https://stately.ai/viz/5683587d-be04-4cd4-92d2-4f34f0cb1971
export const definition: MachineConfig<Context, any, Events> = {
  context: {
    EVENTS,
    /**
     * Helpers
     */
    FS,

    // client: graphql client
    client: undefined,

    /**
     * Errors
     */
    errorMessage: undefined,

    errorType: undefined,

    /**
     * Filters data
     * filterOptions: options to display in UI
     * categoryLabels: a group name and a component type to display in UI
     * activeFilters: applied filters that will be send to API
     * frozenFilters: filters that are not part of the filtering UI, but needed to be send with the request
     */
    filters: {
      activeFilters: {},
      categoryLabels: undefined,
      expandedFiltersLocalStorageKey: undefined,
      filterOptions: undefined,
      frozenFilters: {},
    },

    /**
     * Pagination data
     * query: graphql query
     * queryDataPath: query data path (ex: 'searchMarketplaceEditions.marketplaceEditions')
     * variables: query variables
     * limit: items limit per request
     */
    pagination: {
      limit: undefined,
      query: undefined,
      queryDataPath: undefined,
      queryPaginationPath: undefined,
      variables: undefined,
    },
    // router: nextjs router instance
    router: undefined,

    /**
     * Search data, for apps using platform-services
     */
    search: undefined,

    /**
     * Sort data
     * sortBy: sorting option
     * sotrOptions: options to display in UI
     */
    sort: {
      sortBy: undefined,
      sortOptions: undefined,
    },

    // track: analytics tracking
    track: undefined,
  },
  id: 'search',
  initial: FS.IDLE,
  on: {
    [EVENTS.REMOVE_FILTER]: {
      actions: [
        'removeFilter',
        'updateRouter',
        'updateURL',
        'trackRemoveFilter',
        () => {
          send(PAGINATION_EVENTS.REFETCH, {
            to: 'infinite-scroll-machine',
          });
        },
      ],
      target: FS.IDLE,
    },
    [EVENTS.APPLY_FILTERS]: {
      actions: [
        'applyFilters',
        'updateRouter',
        'updateURL',
        'trackApplyFilters',
        () => {
          return send(PAGINATION_EVENTS.REFETCH, {
            to: 'infinite-scroll-machine',
          });
        },
      ],
      target: FS.IDLE,
    },
    /*
     * For search supporting text search input
     */
    [EVENTS.APPLY_SEARCH]: {
      actions: [
        'applySearch',
        'updateRouter',
        'updateURL',
        'trackApplySearch',
        () => {
          send(PAGINATION_EVENTS.REFETCH, {
            to: 'infinite-scroll-machine',
          });
        },
      ],
      target: FS.IDLE,
    },
    [EVENTS.CLEAR_FILTERS]: {
      actions: [
        'clearFilters',
        'updateRouter',
        'updateURL',
        'trackClearSearch',
        () => {
          send(PAGINATION_EVENTS.REFETCH, {
            to: 'infinite-scroll-machine',
          });
        },
      ],
      target: FS.IDLE,
    },
    [EVENTS.CLEAR_SEARCH]: {
      actions: [
        'clearSearch',
        'updateRouter',
        'updateURL',
        'trackClearSearch',
        () => {
          send(PAGINATION_EVENTS.REFETCH, {
            to: 'infinite-scroll-machine',
          });
        },
      ],
      target: FS.IDLE,
    },
    [EVENTS.UPDATE_SORTING]: {
      actions: [
        'updateSorting',
        'updateRouter',
        'updateURL',
        'trackUpdateSorting',
        () => {
          send(PAGINATION_EVENTS.REFETCH, {
            to: 'infinite-scroll-machine',
          });
        },
      ],
      target: FS.IDLE,
    },
  },
  states: {
    [FS.IDLE]: {
      always: { target: FS.GET_RESULTS },
    },
    [FS.GET_RESULTS]: {
      invoke: {
        data: {
          EVENTS: PAGINATION_EVENTS,
          FS: PAGINATION_FS,
          client: (context: Context) => context.client,
          limit: ({ pagination }: Context) => pagination.limit,
          options: ({ pagination }: Context) => ({
            context: { clientName: 'platformAPI' },
            fetchPolicy: 'network-only',
            ...pagination?.options,
          }),
          query: ({ pagination }: Context) => pagination.query,
          queryDataPath: ({ pagination }: Context) => pagination.queryDataPath,
          queryPaginationPath: ({ pagination }: Context) =>
            pagination.queryPaginationPath,
          skip: ({ skip }) => skip,
          variables: ({ filters, pagination, search, sort }: Context) => {
            const VARIABLES = pagination.variables({
              cursor: '',
              filters,
              first: pagination.limit,
              search,
              sortBy: sort.sortOptions?.[sort?.sortBy]?.value,
            });
            return VARIABLES;
          },
        },
        id: 'infinite-scroll-machine',
        src: 'infiniteScrollMachine',
      },
    },
  },
};

export const getFilterMachine = ({ custom = {} }: any = {}) => {
  return getMachine<Context>(
    definition,
    {
      context: custom.context,
    },
    {
      actions,
      custom,
      guards,
      services,
    },
  );
};
