// the default copy for the nft listing edition card.
// search component defaults are not necessary as it has defaults.
export const SearchDefaultCopy = {
  AvgSale: 'Avg Sale',
  BuyNow: 'Buy Now',
  HighestAsk: 'Highest Ask',
  LowestAsk: 'Lowest Ask',
  MomentsBurned: 'Moment burned',
  MomentsBurned_plural: 'Moments burned',
  ViewMomentDetails: 'View Moment Details',
};
