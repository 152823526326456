import { useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'src/lib/translations';
import { Button, Text, Flex, Box, useBreakpointValue } from 'src/theme';

import BrowseSortBy from './SelectSidebar.BrowseSort';
import { SelectSidebarColumnTitles } from './SelectSidebar.ColumnTitles';
import { SelectSidebarEditionInfo } from './SelectSidebar.EditionInfo';
import { SelectSidebarItem } from './SelectSidebar.Item';

export interface ItemI {
  askingPrice: string | number;
  nft_id: string;
  owner: string;
  resourceID: string;
  serial: string;
}

export interface EditionInfoI {
  editionSize: number;
  name: string | undefined;
  rarityType: string;
  src: string;
}

interface SelectSerialSidebarProps {
  btnLabel: string;
  disabledBtn: boolean;
  editionInfo: EditionInfoI;
  editionList: Array<ItemI>;
  flowAddress?: string | undefined;
  isOpen: boolean;
  onClick: () => void;
  onSelectItem: (index: number) => void;
  selectedItemIndex: number;
  setIsOpenBrowseListingModal: any;
  setSort: any;
  sort: any;
  sortOptions: any;
}

export const SelectSidebar = ({
  isOpen,
  setIsOpenBrowseListingModal,
  selectedItemIndex = -1,
  onSelectItem,
  editionList = [],
  sort,
  setSort,
  sortOptions,
  btnLabel,
  onClick,
  disabledBtn,
  editionInfo,
  flowAddress,
}: SelectSerialSidebarProps) => {
  const { t } = useTranslation();
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const styles = useMultiStyleConfig('SelectSidebar');

  const closeModal = () => {
    setIsOpenBrowseListingModal(false);
    onSelectItem(-1);
  };
  const handleSortChange = (item) => {
    setSort(item);
  };
  return (
    <Flex
      sx={styles.container}
      bg={`rgba(0, 0, 0, ${isOpen ? 0.8 : 0})`}
      visibility={isOpen ? 'visible' : 'hidden'}
      onClick={closeModal}
    >
      <Flex
        sx={styles.sidebarWrapper}
        transform={`translateX(${isOpen ? 0 : 100}%)`}
        onClick={(e) => e.stopPropagation()}
      >
        <Button
          sx={styles.btnClose}
          _hover={{}}
          _active={{ opacity: 0.8 }}
          onClick={closeModal}
        >
          {t('SelectSidebar.button.close')}
        </Button>
        {!isDesktop && (
          <SelectSidebarEditionInfo
            editionInfo={editionInfo}
            onClose={closeModal}
          />
        )}
        <Text sx={styles.title}>{t('SelectSidebar.title')}</Text>
        <Box margin='16px 0 20px 0'>
          <BrowseSortBy
            onChange={handleSortChange}
            options={sortOptions}
            value={sort}
          />
        </Box>
        <SelectSidebarColumnTitles isDesktop={isDesktop} />
        <Flex direction='column' overflow='auto' margin='8px 0 20px 0'>
          {editionList.map((item, index) => (
            <SelectSidebarItem
              key={item.nft_id}
              flowAddress={flowAddress}
              isDesktop={isDesktop}
              isSelected={selectedItemIndex === index}
              item={item}
              onClick={() => onSelectItem(index)}
            />
          ))}
        </Flex>
        <Button sx={styles.btnBuyNow} disabled={disabledBtn} onClick={onClick}>
          {btnLabel}
        </Button>
      </Flex>
    </Flex>
  );
};
