import { Box } from '@chakra-ui/react';
import React, { useContext } from 'react';
import { useTranslation } from 'src/lib/translations';
import {
  chakra,
  ChakraNextImage,
  Flex,
  Heading,
  Text,
  useBreakpointValue,
  useMultiStyleConfig,
} from 'src/theme';
import { PLAYER_PERFORMANCE_THEME_KEYS } from 'src/theme/dss-skeleton/components';

import { PlayerContext } from './PlayerPerformance';

export const PlayerPerformanceHeader = (): JSX.Element => {
  const { name, position, team, countryIcon, src } = useContext(PlayerContext);
  const isDesktop = useBreakpointValue({ base: false, md: true });
  const { t } = useTranslation();
  const styles = useMultiStyleConfig(PLAYER_PERFORMANCE_THEME_KEYS.HEADER);
  return (
    <Box sx={styles.playerImageContainer}>
      <ChakraNextImage
        sx={styles.playerImage}
        maxHeight={isDesktop ? '21rem' : '16rem'}
        alt={`performance-${name}`}
        src={src}
      />
      <chakra.div sx={styles.container}>
        <Flex sx={styles.nameContainer}>
          <Heading fontFamily='poster' fontWeight='400' size='2xl'>
            {name}
          </Heading>
          {countryIcon}
        </Flex>
        <Text sx={styles.playerPositionText}>
          {t(`playerPerformance.position.${position}`)}{' '}
          {t('playerPerformance.forTeam')} {team}
        </Text>
      </chakra.div>
    </Box>
  );
};
