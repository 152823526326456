import {
  createContext,
  FC,
  ReactElement,
  ReactNode,
  useContext,
  useState,
} from 'react';

interface Context {
  isOpen: boolean;
  modalContent: ReactElement[];
  setIsOpen: Function;
  setModalContent: Function;
}

export const ModalContext = createContext<Context>({} as Context);

export const ModalContextProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState([]);

  const modalContextValue = {
    isOpen,
    modalContent,
    setIsOpen: (value) => {
      setIsOpen(value);
    },
    setModalContent: (content) => setModalContent(content),
  };

  return (
    <ModalContext.Provider value={modalContextValue}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => useContext(ModalContext);
