import * as React from 'react';
import type { HTMLChakraProps, ThemingProps } from 'src/theme';
import {
  useMultiStyleConfig,
  chakra,
  forwardRef,
  omitThemingProps,
  StylesProvider,
  cx,
} from 'src/theme';

import { NAV_THEME_KEY, NavContextProvider } from './';

export interface NavProps extends HTMLChakraProps<'nav'>, ThemingProps<'nav'> {
  activeHref?: string;
  children: React.ReactNode;
  size?: 'sm' | 'md';
  variant?: 'global' | 'sub';
}

export const Nav = forwardRef<NavProps, 'nav'>((props: NavProps, ref) => {
  const { variant = 'sub', size = 'sm' } = props;
  const styles = useMultiStyleConfig(NAV_THEME_KEY, {
    size,
    variant,
  });

  const { children, className, activeHref, ...rest } = omitThemingProps(props);

  return (
    <NavContextProvider activeHref={activeHref}>
      <StylesProvider value={styles}>
        <chakra.nav
          className={cx('AD-nav', className)}
          ref={ref}
          sx={styles.nav}
          __css={styles.root}
          {...rest}
        >
          {children}
        </chakra.nav>
      </StylesProvider>
    </NavContextProvider>
  );
});

Nav.displayName = 'Nav';
