import 'core-js/proposals/promise-all-settled';
import isEmpty from 'lodash/isEmpty';
import omitBy from 'lodash/omitBy';
import { getInfiniteScrollMachine } from 'src/edge/infiniteScroll/machine';
import { assign } from 'xstate';

import { getURLParamsFromActiveFilters } from '../helpers';
import { Context, EVENTS } from './types';

export const actions = {
  applyFilters: assign((context: Context, event: any) => {
    return {
      filters: {
        ...context.filters,
        ...(event.data.activeFilters && {
          activeFilters: event.data.activeFilters,
        }),
        ...(event.data.filterOptions && {
          filterOptions: event.data.filterOptions,
        }),
      },
    };
  }),
  applySearch: assign((_context: Context, event: any) => {
    return {
      search: event.data,
    };
  }),
  clearFilters: assign((context: Context) => {
    return {
      filters: {
        ...context.filters,
        activeFilters: {},
      },
      sort: {
        ...context.sort,
      },
    };
  }),
  clearSearch: assign((context: Context) => {
    return {
      filters: {
        ...context.filters,
      },
      search: undefined,
      sort: {
        ...context.sort,
        sortBy: undefined,
      },
    };
  }),
  removeFilter: assign((context: Context, event: any) => {
    const group = context.filters.activeFilters[event.data.groupName];
    const newFilters = group?.filter(
      (value) => value !== event.data.optionName,
    );

    return {
      filters: {
        ...context.filters,
        activeFilters: {
          ...context.filters.activeFilters,
          [event.data.groupName]: newFilters,
        },
      },
    };
  }),
  trackApplyFilters: (context: Context, event: any) => {
    const { track, options } = context;
    // Do not send analytic event on page load. By default
    // activeFilters are undefined until user adds/removes filters.
    if (event.data.activeFilters) {
      track(`${options?.analyticsPrefix}-filter-panel-apply`, {
        selections: event.data.selectedFilters,
      });
    }
  },
  trackApplySearch: (context: Context, event: any) => {
    // Do not send analytic event on page load. By default
    // query is undefined.
    if (event.data.query) {
      const { track, options } = context;
      track(`${options?.analyticsPrefix}-search-apply`, {
        selections: event.data,
      });
    }
  },
  trackClearSearch: (context: Context) => {
    const { track, options } = context;
    track(`${options?.analyticsPrefix}-filter-panel-clear-all-filters`);
  },
  trackRemoveFilter: (context: Context, event: any) => {
    const { track, options } = context;
    track(`${options.analyticsPrefix}-filter-tag-clear`, {
      [event.data.groupName]: {
        label: event.data.label,
        value: event.data.value,
      },
    });
  },
  trackUpdateSorting: (context: Context, event: any) => {
    // Only send analytic on user action, not on page load.
    // By default on page load, sortBy will be undefined
    if (event.data.sortBy) {
      const { track, options } = context;
      track(`${options?.analyticsPrefix}-sortby-select`, {
        sortBy: event.data,
      });
    }
  },
  updateRouter: assign((context: Context, event: any) => {
    return {
      router: event.data?.router || context.router,
    };
  }),
  updateSorting: assign((context: Context, event: any) => {
    return {
      sort: {
        ...context.sort,
        ...(event.data.sortBy && {
          sortBy: event.data.sortBy,
        }),
        ...(event.data.browseSortOptions && {
          sortOptions: event.data.browseSortOptions,
        }),
      },
    };
  }),
  updateURL: (context: Context, event: any) => {
    const { router, filters, search, sort } = context;
    const isSortingEvent = event.type === EVENTS.UPDATE_SORTING;
    const sortBy = isSortingEvent ? event.data.sortBy : sort.sortBy;

    if (!router) return;

    // If route is `/user/[username]`, include `username`
    // to fulfill NextJS dynamic route requirements
    const username = router?.query?.username;

    // clear url, if no search, no filters and not search update.
    // Continues bug from NFL where only sort remaining is lost from the url but remains in the display & in query.
    // Not clearing the url when there is sorts manifests a bug where the filter is not correctly removed.
    if (
      isEmpty(filters.activeFilters) &&
      !event.data.sortBy &&
      !search?.query
    ) {
      router.push(
        {
          pathname: router.pathname,
          query: { ...(username ? { username } : {}) },
        },
        undefined,
        {
          shallow: true,
        },
      );

      return;
    }

    const newQuery = omitBy(
      {
        // It is important to include router.query because there is where the [dynamic] current value is included, so we need to keep it.
        // ref: https://github.com/vercel/next.js/blob/main/errors/href-interpolation-failed.md

        // note, if there is a filter in the router query and then it's no longer active,
        // then the filter remains.
        ...router.query,
        ...getURLParamsFromActiveFilters(filters.activeFilters, sortBy),
        ...(search?.query ? { ...search } : {}),
      },
      isEmpty,
    );

    router.push(
      {
        pathname: router.pathname,
        query: newQuery,
      },
      undefined,
      { shallow: true },
    );

    return;
  },
};

export const guards = {};

export const services = {
  infiniteScrollMachine: getInfiniteScrollMachine(),
};
