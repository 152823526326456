import React, { ChangeEvent, KeyboardEvent } from 'react';
import { Box, InputGroup, Text, Input, useMultiStyleConfig } from 'src/theme';
import { Label } from 'src/theme/FormControl/FormControl';

interface ListForSaleModalInputProps {
  error: string;
  handleInput: (event: KeyboardEvent<HTMLInputElement>) => void;
  handlePriceChange: (event: ChangeEvent<HTMLInputElement>) => void;
  inputLabel: string;
  price: number | string;
}

export const TransactionsModalInput = ({
  inputLabel,
  handlePriceChange,
  handleInput,
  price,
  error,
}: ListForSaleModalInputProps) => {
  const styles = useMultiStyleConfig('TransactionsModal/Input');
  return (
    <Box textAlign='left'>
      <Label sx={styles.label} htmlFor='price'>
        {inputLabel}
      </Label>
      <InputGroup>
        <Input
          sx={styles.input}
          id='price'
          inputMode='numeric'
          onChange={handlePriceChange}
          onKeyDown={handleInput}
          type='text'
          value={price}
        />
      </InputGroup>
      {!!(error && price) && (
        <Text color='alert' size='sm'>
          {error}
        </Text>
      )}
    </Box>
  );
};
