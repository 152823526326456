import React, { useContext } from 'react';
import { useTranslation } from 'src/lib/translations';
import { Text, Flex, Grid, GridItem, useMultiStyleConfig } from 'src/theme';
import { PLAYER_PERFORMANCE_THEME_KEYS } from 'src/theme/dss-skeleton/components';

import { PlayerContext } from './PlayerPerformance';

export const GameStats = ({ statistics }): JSX.Element => {
  const { playerID } = useContext(PlayerContext);

  const {
    highlightStats,
    otherStats,
    container,
    highlightedStatsLabel,
    highlightedStatsValue,
  } = useMultiStyleConfig(PLAYER_PERFORMANCE_THEME_KEYS.GAME_STATS);

  const { t } = useTranslation();

  return (
    <>
      <Grid sx={container}>
        {statistics.primary.map(({ label, value }) => (
          <GridItem key={`primary-${label}-${playerID}`} sx={highlightStats}>
            <Text sx={highlightedStatsLabel}>{t(label)}</Text>
            <Text sx={highlightedStatsValue}>{value}</Text>
          </GridItem>
        ))}
      </Grid>
      {statistics.secondary.map(({ value, label }) => (
        <Flex key={`secondary-${label}-${playerID}`} sx={otherStats}>
          <Text fontWeight='600'>{t(label)}</Text>
          <Text>{value}</Text>
        </Flex>
      ))}
    </>
  );
};
