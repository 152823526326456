import { NavItemI } from '@dapperlabs/core-fe';
import { useAnalytics } from '@dapperlabs/react-analytics';
import { useSession } from 'src/edge/session';
import { toSnakeCase } from 'src/general/hooks/useFooterAnalytics';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment';
import { languages } from 'src/lib/translations';

const useTopNavAnalytics = () => {
  const { track } = useAnalytics();
  const { state } = useSession();
  const userEmail = state?.context?.auth?.user?.email ?? null;
  const isSignedIn = !!userEmail;

  const handleTrackTopNavMainNavLinks = ({
    label = 'no_label',
    href = 'no_href',
  }: NavItemI) => {
    track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_ITEM_CLICK, {
      component_variant: isSignedIn ? 'authenticated' : 'visitor',
      item_label: toSnakeCase(label),
      item_url_to: href,
    });
  };

  const handleTrackTopNavLanguageClick = () => {
    track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_ITEM_CLICK, {
      component_variant: isSignedIn ? 'authenticated' : 'visitor',
      item_label: 'quicksettings_language',
      item_url_to: '',
    });
  };

  const handleTrackTopNavJoinBetaClick = () => {
    track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_ITEM_CLICK, {
      component_variant: isSignedIn ? 'authenticated' : 'visitor',
      item_label: 'join_waitlist',
      item_url_to: '',
    });
  };

  const handleTrackTopNavLanguageChange = (value: string) => {
    track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_QUICKSETTINGS_SET, {
      component_variant: isSignedIn ? 'authenticated' : 'visitor',
      item_label: 'language',
      previous_value: languages?.find(
        ({ value: langValue }) => langValue !== value,
      )?.label,
      selected_value: languages?.find(
        ({ value: langValue }) => langValue === value,
      )?.label,
    });
  };

  return {
    handleTrackTopNavJoinBetaClick,
    handleTrackTopNavLanguageChange,
    handleTrackTopNavLanguageClick,
    handleTrackTopNavMainNavLinks,
  };
};

export default useTopNavAnalytics;
