import debounce from 'lodash/debounce';
import { useState, useMemo } from 'react';

import { FS } from 'src/general/constants/finiteStates';
import { captureException } from 'src/lib/sentry';
export const useSubscribeEmail = () => {
  const [value, setValue] = useState(FS.IDLE);

  const resetState = () => {
    setValue(FS.IDLE);
  };

  const subscribeUser = useMemo(
    () =>
      async ({ userEmail }) => {
        try {
          const resp = await fetch(
            `${process.env.STATUSPAGE_BASE_URL}/v1/pages/${process.env.STATUSPAGE_PAGE_ID}/subscribers`,
            {
              body: JSON.stringify({ subscriber: { email: userEmail } }),
              headers: {
                Authorization: `${process.env.STATUSPAGE_API_KEY}`,
                'content-type': 'application/json',
              },
              method: 'POST',
            },
          );

          if (resp.status === 201 || resp.status === 200) {
            setValue(FS.SUCCESS);
            resetState();
          } else {
            throw new Error(await resp.json());
          }
        } catch (e) {
          setValue(FS.ERROR);
          resetState();
          captureException(e);
        }
      },
    [],
  );

  const debouncedSubscribe = debounce(subscribeUser, 1000, {
    maxWait: 2000,
  });

  return useMemo(
    () => ({
      resetState,
      state: {
        context: {
          FS,
        },
        value,
      },
      subscribeUser: debouncedSubscribe,
    }),
    [value, debouncedSubscribe],
  );
};
