import React from 'react';
import { IconButton } from 'src/theme/index';

export const TopNavIconButton = (props) => {
  return (
    <IconButton
      h={8}
      fontSize='24px'
      border='none'
      _hover={{ bg: 'transparent' }}
      _focus={{ bg: 'transparent', boxShadow: 'transparent' }}
      _expanded={{ bg: 'transparent' }}
      colorScheme='teal'
      variant='outline'
      {...props}
    />
  );
};
