import {
  AssetContentType,
  ImageMediaType,
  VideoMediaType,
} from '@dapperlabs/core-fe';
import { sansPrefix } from '@onflow/fcl';
import { extractValue } from 'src/general/utils/helpers';

const GCP_BUCKET_URL = 'https://assets.laligagolazos.com/editions';

const PLAYER_IMG_URL =
  'https://assets.laligagolazos.com/players/website_player_profile/[player_id].jpg';
const SHORT_VIDEO =
  'play_[play_data_id]__capture_Animated_Video_Idle_Black_1080_1080[language_id].mp4';
const FULL_VIDEO_MOBILE =
  'play_[play_data_id]__capture_Animated_Video_Popout_Black_720_1280[language_id].mp4';
const FULL_VIDEO_DESKTOP =
  'play_[play_data_id]__capture_Animated_Video_Popout_Black_1080_1080[language_id].mp4';

export const HERO_VIEW_IMG =
  'play_[play_data_id]__capture_Hero_Black_2880_2880[language_id].png';
const FRONT_VIEW_IMG =
  'play_[play_data_id]__capture_Front_Black_2880_2880[language_id].png';
const LEGAL_PANEL_VIEW_IMG =
  'play_[play_data_id]__capture_Legal_Black_2880_2880[language_id].png';
const MATCH_SCORE_VIEW_IMG =
  'play_[play_data_id]__capture_Details_Black_2880_2880[language_id].png';
const VIDEO_PANEL_VIEW_IMG =
  'play_[play_data_id]__capture_Video_Black_2880_2880[language_id].png';

export const getImage = (id, src): ImageMediaType => ({
  id,
  src,
  type: AssetContentType.IMAGE,
});

const getVideo = (id, src, fullscreenSrc, previewSrc): VideoMediaType => ({
  fullscreenSrc,
  id,
  previewSrc,
  src,
  type: AssetContentType.VIDEO,
});

export const getAssetUrl = (url, playDataId, lang = '') => {
  return `${GCP_BUCKET_URL}/${playDataId}/${url
    .replace('[play_data_id]', playDataId)
    .replace('[language_id]', `${lang}`)}`;
};

export const getPlayerImage = (playerId) => {
  return PLAYER_IMG_URL.replace('[player_id]', playerId);
};

export const getHeroImage = (playDataId, languages) => {
  const localisedItems = {};
  languages.forEach(({ urlValue, value }) => {
    localisedItems[value] = getAssetUrl(HERO_VIEW_IMG, playDataId, urlValue);
  });
  return localisedItems;
};

export const getCarouselItems = (
  playDataId,
  languages,
  showMobileVideos = false,
) => {
  const localisedItems = {};
  languages.forEach(({ urlValue, value }) => {
    localisedItems[value] = [
      getVideo(
        `moment-video-${playDataId}`,
        getAssetUrl(FULL_VIDEO_DESKTOP, playDataId, urlValue),
        showMobileVideos
          ? getAssetUrl(FULL_VIDEO_MOBILE, playDataId, urlValue)
          : getAssetUrl(FULL_VIDEO_DESKTOP, playDataId, urlValue),
        getAssetUrl(HERO_VIEW_IMG, playDataId, urlValue),
      ),
      getImage(
        `moment-hero-${playDataId}`,
        getAssetUrl(HERO_VIEW_IMG, playDataId, urlValue),
      ),
      getImage(
        `moment-front-${playDataId}`,
        getAssetUrl(FRONT_VIEW_IMG, playDataId, urlValue),
      ),
      getImage(
        `moment-legal-${playDataId}`,
        getAssetUrl(LEGAL_PANEL_VIEW_IMG, playDataId, urlValue),
      ),
      getImage(
        `moment-match-${playDataId}`,
        getAssetUrl(MATCH_SCORE_VIEW_IMG, playDataId, urlValue),
      ),
      getImage(
        `moment-video-panel-${playDataId}`,
        getAssetUrl(VIDEO_PANEL_VIEW_IMG, playDataId, urlValue),
      ),
    ];
  });
  return localisedItems;
};

export const getMainVideo = (playDataId) => {
  return getVideo(
    `moment-main-video-${playDataId}`,
    getAssetUrl(SHORT_VIDEO, playDataId, '_default'),
    getAssetUrl(FULL_VIDEO_DESKTOP, playDataId, '_default'),
    getAssetUrl(HERO_VIEW_IMG, playDataId, '_default'),
  );
};

export const getMomentType = (metadata, set, t) => {
  const type = [];
  if (extractValue(metadata?.play_type)) {
    type.push(
      t(`moment.type.${extractValue(metadata?.play_type).toLowerCase()}`),
    );
  }
  if (extractValue(metadata?.match_highlighted_team)) {
    type.push(extractValue(metadata?.match_highlighted_team));
  }
  if (metadata?.match_date && extractValue(metadata?.match_date)) {
    type.push(extractValue(metadata?.match_date));
  }
  if (set?.name && extractValue(set?.name) && extractValue(set?.name) !== '') {
    type.push(extractValue(set?.name));
  }
  return type.join(', ');
};

export const getOwner = (ownerId, sessionState, t, username = '') => {
  if (!ownerId) {
    return '';
  }
  const currentId = sessionState?.context?.profile?.flowAddress;
  if (sansPrefix(ownerId) === sansPrefix(currentId)) {
    return `${t('moment.owner.you')} (${username})`;
  }

  return username;
};
