import type { ComponentStyleConfig } from '@chakra-ui/react';

export const gameStats: ComponentStyleConfig = {
  baseStyle: {
    container: {
      gap: '4',
      gridTemplateColumns: {
        base: '1fr',
        md: 'repeat(3, 1fr)',
      },
    },
    highlightStats: {
      bg: 'gray.900',
      borderRadius: '12px',
      p: {
        _first: {
          color: 'gray.200', //Neutrals/200
          fontSize: 'xs',
          fontWeight: '600',
        },
        _last: {
          color: 'white',
          fontSize: '3xl',
        },
      },
      padding: '2',
    },
    highlightedStatsLabel: {
      fontFamily: 'mono',
      fontSize: 'xs',
    },
    highlightedStatsValue: {
      fontFamily: 'heading',
      fontSize: '3xl',
    },
    otherStats: {
      _last: {
        borderBottom: 'none',
      },
      borderBottom: '1px solid', // foundation/surface 2 (gray.800)
      borderColor: 'gray.800',
      justifyContent: 'space-between',
      p: {
        color: 'white',
        fontSize: 'lg',
      },
      paddingBottom: '4',
      paddingTop: '4',
    },
  },
  defaultProps: {
    variant: 'common',
  },
  parts: [
    'highlightStats',
    'otherStats',
    'container',
    'highlightedStatsLabel',
    'highlightedStatsValue',
  ],
};
