import NextHead from 'next/head';
import { ReactNode } from 'react';
import { useTranslation } from 'src/lib/translations';
import GenericShareImg from 'src/modules/Layout/assets/png/generic_share_image.png';

interface HeadProps {
  children?: ReactNode;
  description?: string;
  openGraphImage?: string;
  pageKeywords?: string;
  subtitle: string;
  title?: string;
}

const Head = (props: HeadProps) => {
  const { t } = useTranslation();

  const DEFAULT_DESCRIPTION = t('Head.Default.Description');
  const DEFAULT_KEYWORDS = t('Head.Default.Keywords');

  const subtitle = props.subtitle ? ` | ${props.subtitle}` : '';
  const title = `${t('Head.Default.Title')}${subtitle}`;
  const keywords = props.pageKeywords
    ? `${props.pageKeywords}, ${DEFAULT_KEYWORDS}`
    : DEFAULT_KEYWORDS;
  const description = props.description || DEFAULT_DESCRIPTION;
  const ogImage = props.openGraphImage || GenericShareImg.src;

  return (
    <NextHead>
      <title>{title}</title>
      <meta charSet='utf-8' />
      <meta
        key='viewport'
        content='width=device-width, initial-scale=1, maximum-scale=1'
        name='viewport'
      />

      <link href='/static/favicon/favicon-180x180.png' rel='apple-touch-icon' />
      <link
        href='/static/favicon/favicon.png'
        rel='icon'
        sizes='any'
        type='image/png'
      />

      {/* TODO: get svg version of favicon */}
      {/* <link
        href='/static/favicon/favicon.svg'
        rel='icon'
        sizes='any'
        type='image/svg+xml'
      /> */}

      <link href='/static/favicon/site.webmanifest' rel='manifest' />
      <link
        color='#000000'
        href='/static/favicon/favicon.png'
        rel='mask-icon'
      />

      <meta content={keywords} name='keywords' />
      <meta content={description} name='description' />
      <meta content='#ffffff' name='msapplication-TileColor' />
      <meta content='#ffffff' name='theme-color' />

      {/* Schema.org for Google */}
      <meta content={t('Head.title')} itemProp='name' />
      <meta content={description} itemProp='description' />

      {/* Open Graph general */}
      <meta content='website' property='og:type' />
      <meta content={title} property='og:title' />
      <meta content={description} property='og:description' />
      <meta content={ogImage} property='og:image' />
      <meta content='650' property='og:image:width' />
      <meta content='650' property='og:image:height' />

      {/* Twitter Card */}
      <meta content='summary_large_image' name='twitter:card' />
      <meta content={title} name='twitter:title' />
      <meta content={description} name='twitter:description' />
      <meta content={ogImage} name='twitter:image' />

      {/* Other dynamic meta tags */}
      {props?.children}
    </NextHead>
  );
};

export default Head;
