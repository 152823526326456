/* eslint-disable sort-keys-fix/sort-keys-fix */
import { Context as QueueContext } from 'src/edge/purchase';
import { getMachine } from 'src/lib/xstate/utils';
import { MachineConfig } from 'xstate';

import { actions, guards, services } from './options';
import { FS, EVENTS, Context, Schema, Event } from './types';

// needs stately diagram
export const definition: MachineConfig<Context, Schema, Event> = {
  id: 'queue',
  context: {
    EVENTS,
    FS,
    client: undefined,
    error: undefined,
  },
  initial: 'LOADING',
  states: {
    // Hopefully we can coalesce these staes in can join.
    //     CAN_JOIN_NO_RESERVATION: {},
    //  CAN_JOIN_EXPIRED_RESERVATION: {},
    LOADING: {
      on: {
        UPDATE: [
          { target: 'CAN_BUY', cond: 'canBuy' },
          { target: 'CAN_JOIN', cond: 'canJoin' },
          { target: 'WAITING_ROOM', cond: 'isInWaitingRoom' },
          { target: 'GETTING_POSITION', cond: 'isGettingPosition' },
          { target: 'IN_QUEUE', cond: 'inQueue' },
          { target: 'ENDED', cond: 'isEnded' },
        ],
      },
    },
    CAN_JOIN: {
      on: {
        JOIN: {
          actions: 'clearError',
          target: 'JOINING',
        },
      },
    },
    // joinDrop or queueStatus error
    JOIN_ERROR: {
      on: {
        UPDATE: [
          {
            target: 'CAN_JOIN',
          },
        ],
      },
    },
    JOINING: {
      on: {
        UPDATE: [
          {
            target: 'JOIN_ERROR',
            cond: 'isJoinError',
            actions: 'assignError',
          },
          {
            cond: 'isInWaitingRoom',
            target: 'WAITING_ROOM',
          },
          {
            cond: 'isGettingPosition',
            target: 'GETTING_POSITION',
          },
          {
            cond: 'inQueue',
            target: 'IN_QUEUE',
          },
          {
            // assumes its their lucky day:
            cond: 'canBuy',
            target: 'CAN_BUY',
          },
        ],
      },
    },
    WAITING_ROOM: {
      on: {
        UPDATE: [
          {
            cond: 'isGettingPosition',
            target: 'GETTING_POSITION',
          },
          {
            cond: 'inQueue',
            target: 'IN_QUEUE',
          },
        ],
      },
    },
    GETTING_POSITION: {
      on: {
        UPDATE: [
          {
            cond: 'canBuy',
            target: 'CAN_BUY',
          },
          {
            cond: 'inQueue',
            target: 'IN_QUEUE',
          },
        ],
      },
    },
    IN_QUEUE: {
      on: {
        UPDATE: [
          {
            cond: 'canBuy',
            target: 'CAN_BUY',
          },
        ],
      },
    },
    CAN_BUY: {
      on: {
        UPDATE: [
          {
            cond: 'isReservationExpired',
            target: 'CAN_JOIN',
          },
        ],
      },
    },
    ENDED: {
      type: 'final',
    },
    // hopefully we don't need BUYING as purchase FSM covers that.
  },
};

type QueueMachineParams = {
  custom?: any;
};
export const getQueueMachine = ({ custom = {} }: QueueMachineParams = {}) => {
  return getMachine<Partial<QueueContext>>(
    definition,
    {
      context: {
        EVENTS,
        FS,
        ...custom.context,
      },
    },
    {
      actions,
      custom,
      guards,
      services: {
        ...services,
        ...custom?.options?.services,
      },
    },
  );
};
