import merge from 'lodash/merge';
import { rest } from 'msw';
import type { PartialDeep } from 'type-fest';
export const queueStatusFactory = (
  overrides: PartialDeep<any> = {},
  options: PartialDeep<any> = {},
): any => {
  const entry: PartialDeep<any> = !options?.isNoEntry
    ? {
        __typename: 'Entry',
        eligibility: null,
        id: '167d927f-d690-4fb5-8a3d-bfece1aac106',
        joinedAt: '2022-10-20T20:38:54.131595401Z',
        key: '0c029c67c7c9584d',
        placeInLine: 2,
        queueId: '3d5bef9e-a22b-4cf7-a51a-3b2147bc49a4',
        startingPosition: 2,
        tier: '',
        turnStartAt: null,
        ...overrides?.entry,
      }
    : undefined;
  return merge(
    {},
    {
      getQueueStatus: {
        __typename: 'EntryStatus',
        entry,
        queue: {
          __typename: 'Queue',
          drawTime: '2022-10-20T20:00:00Z',
          name: 'Queue for David',
          pending: 0,
          processed: null,
          remaining: 10,
          startTime: '2022-10-20T16:30:00Z',
          state: 'ACTIVE',
          statusMessageTime: '1970-01-01T00:00:00Z',
          ...overrides?.queue,
        },
      },
    },
  );
};

export const queueStatusHandler = (overrides: any = {}, options: any = {}) =>
  rest.post('/api/queueService/queueStatus', (req, res, ctx) => {
    const func = options?.isOnce ? res.once : res;
    return func(
      ctx.status(200),
      ctx.json({
        data: queueStatusFactory(overrides, options),
        loading: false,
        networkStatus: 7,
      }),
    );
  });
