import type { ApolloClient } from '@apollo/client';
import {
  arg,
  args,
  authorizations,
  authz,
  limit,
  payer,
  proposer,
  sansPrefix,
  transaction,
  withPrefix,
} from '@onflow/fcl';
import { Address, UFix64, UInt64, Dictionary, String } from '@onflow/types';
import { DistributionImageType } from '__generated__/globalTypes';
import { getImageURL } from 'src/edge/Distribution';
import { Distribution } from 'src/edge/purchase';
import { captureException } from 'src/lib/sentry';

import {
  AUTHORIZE_RESERVATION_MUTATION,
  AUTHORIZE_RESERVATION_VARIABLES,
  AUTHORIZE_CUSTOMER_SUPPORT_RESERVATION_MUTATION,
  AUTHORIZE_CUSTOMER_SUPPORT_RESERVATION_VARIABLES,
} from './mutations';

const dapperAuthz = authz;

export enum dapperSportSignatureAPICallEnum {
  PACK_NFT,
}

interface Interaction {
  client: ApolloClient<any>;
  dapperSportSignatureAPICall?: dapperSportSignatureAPICallEnum;
  distribution: Distribution;
  isCustomerSupportPack: boolean;
  nftResourceIdentifer: number;
  packNFTID: number;
  price: string;
  purchaseTx: string;
  sellerAddress: string;
}
const PurchasePackSignatureAPIConfig = {
  dataPath: 'authorizePackPurchase',
  mutation: (signable) => ({
    mutation: AUTHORIZE_RESERVATION_MUTATION,
    variables: AUTHORIZE_RESERVATION_VARIABLES({ signable }),
  }),
};
const CustomerSupportPackSignatureAPIConfig = {
  dataPath: 'authorizeCustomerSupportReservation',
  mutation: (signable) => ({
    constex: { clientName: 'platformAPI' },
    mutation: AUTHORIZE_CUSTOMER_SUPPORT_RESERVATION_MUTATION,
    variables: AUTHORIZE_CUSTOMER_SUPPORT_RESERVATION_VARIABLES({
      signable,
    }),
  }),
};
/**
 * Handles auth/signing of the reservation
 *
 * @param props.sellerAddress - the sellers address
 * @param props.nftResourceIdentifer - listing flow ID
 * @param props.price - the price
 * @param props.client - apollo client
 * @param props.purchaseTx - ?
 * @param props.variablesFn - the variables function to use
 * @param props.packNFTID - NFT pack id
 * @returns async array?
 */
export function getInteraction({
  sellerAddress,
  nftResourceIdentifer,
  price,
  client,
  purchaseTx,
  dapperSportSignatureAPICall = dapperSportSignatureAPICallEnum.PACK_NFT,
  distribution,
  packNFTID,
  isCustomerSupportPack,
}: Interaction) {
  // Split signature call into two calls to stay wet
  const dapperSportSignatureAPICalls = {
    /**
     * Original call for authorizing the reservation of the pack
     *
     * @param signable - the token?
     * @returns an object to be used by authorization
     */
    [dapperSportSignatureAPICallEnum.PACK_NFT]: async (signable: string) => {
      const apiConfig = isCustomerSupportPack
        ? CustomerSupportPackSignatureAPIConfig
        : PurchasePackSignatureAPIConfig;

      const { errors, data } = await client.mutate<any, any>(
        apiConfig.mutation(signable),
      );

      if (errors) {
        captureException(errors);
        throw errors;
      }

      return {
        addr: withPrefix(process.env.NEXT_PUBLIC_APP_AUTHZ_ADDRESS) as string,
        keyId: process.env.NEXT_PUBLIC_APP_AUTHZ_KEYID,
        signature: data[apiConfig.dataPath].signature,
      };
    },
    /**
     * Customer support call for authorizing the reservation
     *
     * @param signable - the token?
     * @returns an object to be used by authorization
     */
  };

  /**
   * Handles getting authorization from our API
   *
   * @returns The addr and keyId values in the outer scope of the returned
   *          object needs to match the addr and keyId returned from the
   *          signingFunction
   */
  const dapperSportAuthz = async (ixAcct) => ({
    ...ixAcct,
    // seller of the NFT:
    addr: sansPrefix(process.env.NEXT_PUBLIC_APP_AUTHZ_ADDRESS),
    // this is how the seller proves its them:
    keyId: Number(process.env.NEXT_PUBLIC_APP_AUTHZ_KEYID),
    // how we get that proof
    signingFunction: async (signable) => {
      // addr, keyId needs to be the same value as the containing scope
      const { addr, keyId, signature } = await dapperSportSignatureAPICalls[
        dapperSportSignatureAPICall
      ](signable);

      return {
        addr, // with 0x prefix
        keyId,
        signature,
      };
    },
  });

  // remove non-latin characters
  const sanitize = (text) => text.replace(/[\u0250-\ue007]/g, '');

  const txName = sanitize(`${distribution?.title} #${packNFTID}`);

  const txDescription = ''; // @NOTE: if desired, we'd need something shorter than
  // `distribution.description` and to ensure we use the correct language

  const txImage = distribution?.images?.length
    ? sanitize(
        `https://assets.laligagolazos.com/cdn-cgi/image/format=webp,width=640,quality=50/${getImageURL(
          // @ts-ignore
          distribution,
          DistributionImageType.DEFAULT,
        )}`,
      )
    : '';

  const ix = async () => [
    transaction(purchaseTx),
    args([
      arg(process.env.NEXT_PUBLIC_MERCHANT_ADDRESS, Address),
      arg(sellerAddress, Address),
      arg(nftResourceIdentifer, UInt64),
      arg(price, UFix64),
      arg(
        [
          {
            key: 'name',
            value: txName,
          },
          {
            key: 'description',
            value: txDescription,
          },
          {
            key: 'imageUrl',
            value: txImage,
          },
        ],
        Dictionary({ key: String, value: String }),
      ),
    ]),
    proposer(authz),
    payer(authz),
    authorizations([dapperSportAuthz, dapperAuthz, authz]),
    limit(4999),
  ];

  return ix;
}

export const convertPrice = (UInt64Number) => {
  const priceNumber = UInt64Number * Math.pow(10, -8);
  return priceNumber.toString();
};
