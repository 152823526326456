export const languages = [
  {
    contentfulAbbreviation: 'en-GB',
    i18nAbbreviation: 'en',
    label: 'English',
    value: 'ENG',
  },
  {
    contentfulAbbreviation: 'es',
    i18nAbbreviation: 'es',
    label: 'Español',
    value: 'ESP',
  },
];
