import { ApolloProvider as ApolloClientProvider } from '@apollo/client';
import PropTypes from 'prop-types';

import { useApollo } from 'src/lib/apollo';

// eslint-disable-next-line react/prop-types
const ApolloProvider = ({ pageProps, ...props }) => {
  const apolloClient = useApollo(pageProps);

  return <ApolloClientProvider client={apolloClient} {...props} />;
};

ApolloProvider.propTypes = {
  initialState: PropTypes.object,
};

ApolloProvider.defaultProps = {
  initialState: undefined,
};

export { ApolloProvider };
