import { useEffect, useState } from 'react';
export const useIsPageVisible = () => {
  const [isVisible, setIsVisible] = useState(true);
  useEffect(() => {
    const handleChange = () => setIsVisible(!document.hidden);
    document.addEventListener('visibilitychange', handleChange, false);
    return () =>
      document.removeEventListener('visibilitychange', handleChange, false);
  }, []);
  return isVisible;
};
