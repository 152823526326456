import type { ComponentStyleConfig } from 'src/theme';

export const TransactionsModalTheme: ComponentStyleConfig = {
  baseStyle: {
    additionalInfo: {
      fontFamily: 'main',
      fontSize: '12px',
      fontWeight: '400',
      letterSpacing: '0.45px',
      lineHeight: '16px',
    },
    closeBtnWrapper: {
      backdropFilter: 'blur(15px)',
      bg: 'rgba(23, 23, 27, 0.36)',
      borderRadius: '48px',
      cursor: 'pointer',
      display: 'flex',
      height: '48px',
      mb: 4,
      width: '100%',
    },
    container: {
      borderBottomRadius: { base: '0!important', md: '24px!important' },
      borderRadius: 0,
      borderTopRadius: '24px!important',
      marginBottom: { base: '0!important', md: 'auto!important' },
      marginTop: { base: 'auto!important', md: 'auto!important' },
      maxWidth: '756px',
      minHeight: { base: '0!important', md: '540px!important' },
      p: 0,
      width: '100%',
    },
    contentWrapper: {
      ' svg > circle': {
        transition: 'all 3s linear',
      },
      display: 'flex',
      flexDirection: 'column',
      minHeight: '367px',
      padding: '24px',
      width: { base: '100%', md: '56.4%' },
    },
    crossIcon: {
      height: '24px',
      margin: 'auto 12px auto auto',
      width: '24px',
    },
    imageWrapper: {
      height: { base: '227px', md: 'auto' },
      width: { base: '100%', md: '43.6%' },
    },
    listingSuccessful: {
      color: '#F0F2FD',
      fontFamily: 'main',
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0.3px',
      lineHeight: '19px',
      marginLeft: '10px',
    },
    nameFootballer: {
      color: '#F0F2FD',
      fontFamily: 'main',
      fontSize: '14px',
      fontWeight: '600',
      letterSpacing: '0.3px',
      lineHeight: '19px',
    },
    payoutContainer: {
      fontFamily: 'heading',
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0.3px',
      lineHeight: '19px',
      my: 4,
      width: '100%',
    },
    processingHelper: {
      color: '#F0F2FD',
      fontFamily: 'main',
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0.3px',
      lineHeight: '19px',
      marginLeft: '10px',
    },
    title: {
      color: '#F8F8F8',
      fontFamily: 'heading',
      fontSize: '36px',
      fontWeight: '500',
      letterSpacing: '-1px',
      lineHeight: '36px',
    },
    totalPrice: {
      alignItems: 'center',
      color: '#F8F8F8',
      fontFamily: 'heading',
      fontSize: '18px',
      fontWeight: '500',
      letterSpacing: '0.16px',
      lineHeight: '18px',
    },
    totalPriceCurrency: {
      color: '#8A94A6',
      fontFamily: 'mono',
      fontSize: '14px',
      fontWeight: '400',
      lineHeight: '20px',
      marginLeft: '4px',
    },
    typeRarity: {
      backgroundClip: 'text',
      marginRight: '4px',
      textFillColor: 'transparent',
    },
    viewFlowLink: {
      color: '#F0F2FD',
      fontFamily: 'main',
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0.3px',
      lineHeight: '19px',
      marginTop: '16px',
      textDecoration: 'underline',
    },
  },
  parts: [],
};
