import {
  Link as ChakraLink,
  LinkProps as ChakraLinkProps,
} from '@chakra-ui/react';
// import imps from '@dapperlabs/react-analytics';
import { forwardRef, MouseEventHandler /* useCallback */ } from 'react';

export interface TrackingProps {
  trackingId?: string;
  trackingProperties?: any;
}

export const useTrackedOnClick = ({
  onClick: originalOnClick,
  trackingId,
  trackingProperties,
}: TrackingProps & { onClick?: MouseEventHandler<HTMLAnchorElement> }) => {
  // this hackery is due to support jest testing. react-analytics is returning undefined,
  // even in the face of a jest.mock('@dapperlabs/react-analytics',...)
  // It's not the circular dependency in the mocks
  // const useAnalytics =
  //   imps?.useAnalytics ??
  //   function () {
  //     return { track: () => {} };
  //   };
  // const { track } = useAnalytics();
  // const onClick: MouseEventHandler<HTMLAnchorElement> = useCallback(
  //   (e) => {
  //     if (trackingId) {
  //       track(trackingId, trackingProperties);
  //     }
  //     if (originalOnClick) {
  //       originalOnClick(e);
  //     }
  //   },
  //   [originalOnClick, track, trackingId, trackingProperties],
  // );
  return originalOnClick;
};

export interface LinkDeprecatedProps extends ChakraLinkProps, TrackingProps {}

// TODO: As we finish migration of the LinkDeprecated so that this is no longer used by components directly
// but instead used by Nextjs/link as a UI link, then this needs to be renamed to something more semantically
// correct like ChakraUILink or something along the lines of this

// We need to forward the ref so that this component can be wrapped by NextJS's `Link`
// https://nextjs.org/docs/api-reference/next/link#if-the-child-is-a-function-component
export const LinkDeprecated = forwardRef<
  HTMLAnchorElement,
  LinkDeprecatedProps
>(
  (
    { trackingId, trackingProperties, onClick: originalOnClick, ...props },
    ref,
  ) => {
    const onClick = useTrackedOnClick({
      onClick: originalOnClick,
      trackingId,
      trackingProperties,
    });

    return <ChakraLink {...props} ref={ref} onClick={onClick} />;
  },
);

LinkDeprecated.displayName = 'LinkDeprecated';
