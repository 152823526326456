import { captureException, SEVERITY_LEVELS } from '@dapperlabs/core-fe';
import PropTypes from 'prop-types';
import { Component } from 'react';

class ErrorBoundary extends Component {
  static getDerivedStateFromError() {
    // React Error Boundary here allows us to set state flagging the error (and
    // later render a fallback UI).
    return { hasError: true };
  }

  state = { hasError: false };

  componentDidCatch(error, errorExtra) {
    const { level, extra } = this.props;
    let errorInfo;
    try {
      errorInfo = { ...(errorExtra || {}), ...extra };
      // eslint-disable-next-line no-empty
    } catch (err) {}
    captureException(error, {
      errorInfo,
      level,
    });
  }

  render() {
    const { hasError } = this.state;
    const { renderError, children } = this.props;
    if (hasError) return renderError();
    return children;
  }
}

ErrorBoundary.displayName = 'ErrorBoundary';

ErrorBoundary.propTypes = {
  children: PropTypes.node,
  extra: PropTypes.object,
  level: PropTypes.oneOf(SEVERITY_LEVELS),
  renderError: PropTypes.func,
};

ErrorBoundary.defaultProps = {
  children: null,
  extra: {},
  level: undefined,
  renderError: () => null,
};

ErrorBoundary.SEVERITY = SEVERITY_LEVELS;

export { ErrorBoundary };
