import { gql } from '@apollo/client';

export const UserProfileFragment = gql`
  fragment UserProfileFragment on UserProfile {
    id
    email
    username
    flowAddress
    dapperID
    profileImageUrl
    isMarketingAllowed
    isCurrentTOSSigned
    hasCompletedFTUE
    isVerified
    createdAt
    favoriteTeamIDs
    playbookSeen
    selectedBannerID
    banners {
      id
      eventName
      name
      tier
      status
      description
      assetUrls {
        iconDesktopUrl
        iconMobileUrl
      }
      createdAt
      updatedAt
      enabledAt
      stoppedAt
    }
    verifiedUserProfile {
      name
      organization
      jerseyNumber
      createdAt
      updatedAt
    }
  }
`;

export const QUERY = gql`
  query getMyProfile {
    getMyProfile {
      ...UserProfileFragment
    }
  }
  ${UserProfileFragment}
`;
