import {
  myProfileFactory,
  myProfileHandler,
} from 'src/edge/getMyProfile/mocks';
import { searchGolazosNftAggregationHandler } from 'src/modules/searchGolazosNft/queries/mocks/mocks';
import {
  validSessionHandler,
  defaultSessionHandlers,
  flagsHandler,
} from 'src/test-utils/msw/handlers';
export const handlers = {
  basic: () => {
    return [
      validSessionHandler(),
      flagsHandler(),
      myProfileHandler(myProfileFactory()),
      searchGolazosNftAggregationHandler(),
    ];
  },
  closed: () => {
    return [
      ...defaultSessionHandlers,
      flagsHandler({ isMarketplaceEnabled: false }),
      myProfileHandler(myProfileFactory()),
      searchGolazosNftAggregationHandler(),
    ];
  },
};
