import { useCallback, useEffect, useRef, useState } from 'react';
import { useTimeoutFn } from 'react-use';
import { useLazyQuery } from 'src/lib/apollo';
import {
  searchGolazosNftByOwnerAddress,
  searchPackNftByOwnerAddress,
  SEARCH_GOLAZOS_NFT_TOTAL_COUNT,
  SEARCH_PACK_NFT_TOTAL_COUNT,
} from 'src/modules/Queries/searchNft';

const POLL_INTERVAL_SECONDS = 5;
const MAX_WAIT_TIME_SECONDS = 120;

export const useWaitForGuestTransfer = (guestAddress?: string) => {
  const startedRef = useRef(false);
  const [isActive, setIsActive] = useState(false);

  const packQuery = useLazyQuery(SEARCH_PACK_NFT_TOTAL_COUNT, {
    context: { clientName: 'platformAPI' },
    fetchPolicy: 'network-only',
    origin: 'WaitingForTransferModal',
    pollInterval: POLL_INTERVAL_SECONDS * 1000,
    variables: searchPackNftByOwnerAddress(guestAddress),
  });

  const momentQuery = useLazyQuery(SEARCH_GOLAZOS_NFT_TOTAL_COUNT, {
    context: { clientName: 'platformAPI' },
    fetchPolicy: 'network-only',
    origin: 'WaitingForTransferModal',
    pollInterval: POLL_INTERVAL_SECONDS * 1000,
    variables: searchGolazosNftByOwnerAddress(guestAddress),
  });

  const pollingDone = useCallback(() => {
    packQuery.stopPolling();
    momentQuery.stopPolling();
    setIsActive(false);
  }, [momentQuery, packQuery]);

  useTimeoutFn(pollingDone, MAX_WAIT_TIME_SECONDS * 1000);

  const isPacksEmpty = packQuery.data?.searchPackNft?.totalCount === 0;
  const isMomentsEmpty = momentQuery.data?.searchGolazosNft?.totalCount === 0;
  const isGuestEmpty = isMomentsEmpty && isPacksEmpty;

  // stop each polling when guest is empty
  useEffect(() => {
    if (isMomentsEmpty) momentQuery.stopPolling();
    if (isPacksEmpty) packQuery.stopPolling();
    if (isMomentsEmpty && isPacksEmpty) setIsActive(false);
    // stop polling on unmount
    return () => {
      momentQuery.stopPolling();
      packQuery.stopPolling();
    };
  }, [pollingDone, isMomentsEmpty, momentQuery, isPacksEmpty, packQuery]);

  const isDone = startedRef.current && !isActive;
  return {
    error: packQuery.state.context.error || momentQuery.state.context.error,
    isActive,
    isDone,
    isGuestEmpty: isDone && isGuestEmpty,
    start: (ms: number) => {
      if (startedRef.current) return;
      startedRef.current = true;
      setIsActive(true);
      momentQuery.startPolling(ms);
      packQuery.startPolling(ms);
    },
  };
};
