import type { ComponentStyleConfig } from 'src/theme/index';

const theme: ComponentStyleConfig = {
  baseStyle: {
    menuButton: {
      _expanded: { bg: 'transparent' },
      _focus: { bg: 'transparent', boxShadow: 'transparent' },
      _hover: { bg: 'transparent' },
      backgroundColor: 'transparent',
    },
    menuList: {
      backgroundColor: '#171715',
      border: '1px solid #1F1F1D',
      boxShadow: '0px 0px 24px rgba(240, 242, 253, 0.2)',
      mt: '2px',
    },
    selectLabel: {
      color: '#F8F8F8',
      fontSize: 14,
      fontWeight: 'normal',
      letterSpacing: '0.16px',
      marginLeft: '10px',
      marginRight: '33px',
    },
  },
  defaultProps: {},
  parts: ['menuList', 'menuButton', 'selectLabel'],
};

export const LANGUAGE_PICKER_THEME_KEY = 'LanguagePicker';
export const LanguagePickerTheme = {
  [LANGUAGE_PICKER_THEME_KEY]: theme,
};
