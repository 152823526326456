import React from 'react';
import { ChakraAlert, AlertIcon, AlertDescription, Text } from 'src/theme';

export const TopNavAlert = ({ message }) => {
  return (
    <ChakraAlert
      width='100%'
      bg='#FFF983'
      status='warning'
      variant='top-accent'
    >
      <AlertIcon color='black' />
      <AlertDescription>
        <Text color='black' size='md' variant='label'>
          {message}
        </Text>
      </AlertDescription>
    </ChakraAlert>
  );
};
