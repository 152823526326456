import type { ComponentStyleConfig } from '@chakra-ui/react';

export const common: ComponentStyleConfig = {
  baseStyle: {
    container: {
      alignItems: { md: 'flex-start' },
      flexDirection: { base: 'column', lg: 'row', md: 'row' },
      gap: '2.5rem',
    },
    statsContainer: {
      flexGrow: 1,
      minWidth: { md: '400px' },
    },
  },
  defaultProps: {
    variant: 'common',
  },
  parts: ['container', 'statsContainer'],
};
