/* eslint-disable sort-keys-fix/sort-keys-fix */
import merge from 'lodash/merge';
import { getMomentMedia, ASSET_TYPES } from 'src/edge/Edition';
import { getPreload } from 'src/general/utils/media';
import { MachineConfig, send, createMachine } from 'xstate';

import {
  UNIVERSAL_MEDIA,
  MAX_POLL_DURATION,
  MAX_PRELOAD_DURATION,
  MAX_INITIAL_PRELOAD_DURATION,
  MOMENT_MEDIA,
  TRANSACTION_QUERY_DELAY_DURATION,
} from './constants';
import { actions, guards, services } from './options';
import {
  FS,
  EVENTS,
  Context,
  Schema,
  Events,
  ERRORS,
  ERROR_MESSAGES,
  PROCESSING_MESSAGES,
} from './types';

// https://stately.ai/viz/0414d845-ca01-4447-93ed-9300394983f2
const definition: MachineConfig<Context, Schema, Events> = {
  id: 'pack-opening',
  context: {
    ERRORS,
    ERROR_MESSAGES,
    EVENTS,
    FS,
    PROCESSING_MESSAGES,
    MAX_POLL_DURATION,
    client: undefined,
    error: undefined,
    distribution: undefined,
    getPreload,
    isPreloadError: undefined,
    app: undefined,
    interaction: undefined,
    transactionID: undefined,
    media: {
      ASSET_TYPES,
      MOMENT: MOMENT_MEDIA,
      UNIVERSAL_MEDIA,
      getMomentMedia,
    },
    momentNFTActors: undefined,
    momentNFTs: undefined,
    nfts: undefined,
    packNFT: undefined,
    preloads: undefined,
  },
  initial: 'INITIAL_PRELOAD',
  on: {
    // @NOTE: just here for DEMO purposes for now:
    // @ts-ignore
    DEMO_POLLING: {
      target: 'POLLING',
    },
    DEMO_PRELOAD: {
      target: 'PRELOAD_ASSETS',
    },
  },
  states: {
    INITIAL_PRELOAD: {
      after: {
        [MAX_INITIAL_PRELOAD_DURATION]: {
          actions: 'assignPreloadError',
          target: 'IDLE',
        },
      },
      invoke: {
        id: 'initialPreload',
        onError: {
          actions: 'assignPreloadError',
          target: 'IDLE',
        },
        src: 'preloadInitialAssets',
      },
      on: {
        PRELOAD_SUCCESS: {
          actions: 'assignAssets',
          target: 'IDLE',
        },
      },
    },
    IDLE: {
      on: {
        CLAIM: {
          actions: 'getInteraction',
          target: 'TRANSACTION',
        },
        REPLAY: {
          target: 'LOAD_NFTS',
        },
      },
    },
    TRANSACTION: {
      initial: 'AUTHORIZING',
      states: {
        ERROR: {
          entry: 'assignError',
          on: {
            CLAIM: {
              target: `#pack-opening.TRANSACTION.AUTHORIZING`,
            },
          },
        },
        AUTHORIZING: {
          invoke: {
            onDone: {
              actions: ['assignTransactionID'],
              target: 'DELAY',
            },
            onError: {
              actions: 'assignError',
              target: `#pack-opening.TRANSACTION.ERROR`,
            },
            src: 'sendTransaction',
          },
        },
        DELAY: {
          after: {
            [TRANSACTION_QUERY_DELAY_DURATION]: {
              target: 'FINALIZING',
            },
          },
        },
        FINALIZING: {
          invoke: {
            onDone: { target: 'EXECUTING' },
            onError: {
              actions: 'assignError',
              target: `#pack-opening.TRANSACTION.ERROR`,
            },
            src: 'awaitTransactionFinalized',
          },
        },
        EXECUTING: {
          invoke: {
            onDone: { target: 'SEALING' },
            onError: {
              actions: 'assignError',
              target: `#pack-opening.TRANSACTION.ERROR`,
            },
            src: 'awaitTransactionExecuted',
          },
        },
        SEALING: {
          invoke: {
            onDone: {
              target: `#pack-opening.POLLING`,
            },
            onError: {
              actions: 'assignError',
              target: `#pack-opening.TRANSACTION.ERROR`,
            },
            src: 'awaitTransactionSealed',
          },
        },
      },
    },
    ERROR: {
      on: {
        RETRY: {
          target: 'POLLING',
        },
      },
    },
    POLLING: {
      after: {
        [MAX_POLL_DURATION]: {
          actions: send('POLL_STOP', { to: 'poll' }),
        },
      },
      invoke: {
        id: 'poll',
        onError: {
          actions: 'assignError',
          target: 'ERROR',
        },
        src: 'pollPack',
      },
      on: {
        POLL_SUCCESS: {
          actions: ['assignPackNFT'],
          target: 'LOAD_NFTS',
        },
        POLL_ERROR: {
          actions: 'assignError',
          target: 'ERROR',
        },
      },
    },
    LOAD_NFTS: {
      invoke: {
        id: 'load_nfts',
        src: 'loadNFTs',
        onDone: {
          actions: 'assignNFTs',
          target: 'PRELOAD_ASSETS',
        },
        onError: {
          actions: 'assignError',
          target: 'ERROR',
        },
      },
    },
    PRELOAD_ASSETS: {
      after: {
        [MAX_PRELOAD_DURATION]: {
          actions: 'assignPreloadError',
          target: 'IDLE',
        },
      },
      invoke: {
        id: 'preload',
        onError: {
          actions: 'assignPreloadError',
          target: 'READY_TO_OPEN',
        },
        src: 'preloadAssets',
      },
      on: {
        PRELOAD_SUCCESS: {
          actions: 'assignAssets',
          target: 'READY_TO_OPEN',
        },
      },
    },
    READY_TO_OPEN: {
      on: {
        OPEN_PACK: 'TEARING_OPEN',
      },
    },
    TEARING_OPEN: {
      on: {
        TORN_OPEN: 'REVEAL',
      },
    },
    REVEAL: {
      id: 'REVEAL',
      initial: 'REVEALING',
      states: {
        REVEALING: {
          entry: 'spawnMomentNFTs',
          on: {
            FORCE_REVEAL: {
              actions: 'forceRevealMomentNFTs',
              target: `#pack-opening.REVEAL.ALL_REVEALED`,
            },
            'xstate.update': {
              cond: 'isAllRevealed',
              target: `#pack-opening.REVEAL.ALL_REVEALED`,
            },
          },
        },
        ALL_REVEALED: {
          on: {
            DONE: [
              {
                target: `#pack-opening.FINISHED`,
              },
            ],
            REPLAY: {
              target: `#pack-opening.READY_TO_OPEN`,
            },
          },
        },
      },
    },
    FINISHED: {
      type: 'final',
    },
  },
};

export const getPackOpeningMachine = ({ custom = {} }: any = {}) => {
  return createMachine<Context>(
    merge(
      {},
      {
        ...definition,
      },
      custom?.definition,
    ),
    merge(
      {},
      {
        actions,
        guards,
        services,
      },
      custom?.options,
    ),
  );
};
