import { getItem, setItem } from 'src/general/utils/localStorage';
let isServerInitialized = false;
let server;

export function initMSW({ isServerSide, context } = {}) {
  try {
    if (process.env.NODE_ENV === 'development') {
      if (!isServerSide && typeof window !== 'undefined') {
        const useMSW = getItem('useMSW');
        const search = new URLSearchParams(window.location.search);
        const msqQuery = search.get('useMSW');
        const workflow = search.get('workflow');

        // Enable MSW if useMSW in the query parameters is explicitly "true", and store in local storage
        // that it will always useMSW.
        // Disable MSW if useMSW in the query parameters is explicitly "false"
        if ((msqQuery === 'true' || useMSW === true) && msqQuery !== 'false') {
          const { worker } = require('./browser');
          worker({ workflow }).start({ onUnhandledRequest: 'bypass' });
        } else {
          // clear the local storage if query param set to false
          setItem('useMSW', false);
        }
      } else if (isServerSide && typeof window === 'undefined') {
        const { useMSW, workflow } = context.query;
        if (useMSW === 'true' && !isServerInitialized) {
          // note, this turns on msw for all clients.
          // a client without MSW=true accessing server after initialization will see msw.
          isServerInitialized = true;
          require('./server').then((module) => {
            server = module.server;
            server({ workflow }).listen({ onUnhandledRequest: 'bypass' });
          });
        } else if (useMSW === 'false' && isServerInitialized) {
          isServerInitialized = false;
          server.close();
        }
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('error starting msw', e);
  }
}
