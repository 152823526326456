import { useEffect, useMemo, useState } from 'react';
import {
  eurFormatter,
  usdFormatterWholeNumber,
} from 'src/general/utils/currencyFormatter';
import { useQuery } from 'src/lib/apollo';
import { useTranslation } from 'src/lib/translations';
import {
  SEARCH_GOLAZOS_NFT,
  searchGolazosNftEditionListById,
} from 'src/modules/Queries/searchNft';

const rawSortOptions = [
  {
    key: 'lowestAsk',
    text: 'SelectSidebar.sort.lowestAsk',
    value: {
      listing: { price: { direction: 'ASC', priority: 1 } },
      serial_number: { direction: 'ASC', priority: 2 },
    },
  },
  {
    key: 'highestAsk',
    text: 'SelectSidebar.sort.highestAsk',
    value: {
      listing: { price: { direction: 'DESC', priority: 1 } },
      serial_number: { direction: 'ASC', priority: 2 },
    },
  },
  {
    key: 'lowestSerialNumber',
    text: 'SelectSidebar.sort.lowestSerialNumber',
    value: {
      listing: { price: { direction: 'ASC', priority: 2 } },
      serial_number: { direction: 'ASC', priority: 1 },
    },
  },
  {
    key: 'highestSerialNumber',
    text: 'SelectSidebar.sort.highestSerialNumber',
    value: {
      listing: { price: { direction: 'ASC', priority: 2 } },
      serial_number: { direction: 'DESC', priority: 1 },
    },
  },
];

const getSortOptions = (t) =>
  rawSortOptions.map((option) => {
    return {
      key: option.key,
      text: t(option.text),
      value: JSON.stringify(option.value),
    };
  });

export enum SERIAL_SIDEBAR_STAGES {
  INITIAL = 'INITIAL',
  PROCESSING = 'PROCESSING',
}

export const useSerialSidebar = ({
  editionID,
  preferredCurrency,
  isAuthed,
}) => {
  const { t } = useTranslation();
  const sortOptions = getSortOptions(t);
  const [sort, setSort] = useState(sortOptions[0]);
  const [stage] = useState(SERIAL_SIDEBAR_STAGES.INITIAL);

  const [isOpenBrowseListingModal, setIsOpenBrowseListingModal] =
    useState(false);
  const [selectedEditionId, setSelectedEditionId] = useState(-1);
  useEffect(() => {
    setSelectedEditionId(-1);
  }, [sort]);

  const variables = useMemo(() => {
    return searchGolazosNftEditionListById(editionID, JSON.parse(sort.value));
  }, [editionID, sort.value]);

  const { data } = useQuery(SEARCH_GOLAZOS_NFT(), {
    context: {
      clientName: 'platformAPI',
    },
    variables,
  });

  const editionList = useMemo(() => {
    return Array.isArray(data?.searchGolazosNft?.edges)
      ? data.searchGolazosNft.edges.map((res) => {
          const price = (res?.node?.listing?.price ?? 0) / 100000000;
          return {
            askingPrice:
              preferredCurrency === 'DOLLARS'
                ? usdFormatterWholeNumber.format(price).toString()
                : eurFormatter.format(price).toString(),
            nft_id: res.node.id.toString(),
            owner: res?.node?.user?.username,
            owner_address: '0x' + (res?.node?.owner_address ?? '0'),
            resourceID: (
              res?.node?.listing?.listing_resource_id ?? 0
            ).toString(),
            serial: res.node?.serial_number.toString(),
          };
        })
      : [];
  }, [preferredCurrency, data]);

  const btnLabel = useMemo(() => {
    if (!isAuthed) {
      return t('SelectSidebar.button.signInToBuy');
    }
    if (stage === SERIAL_SIDEBAR_STAGES.PROCESSING) {
      return t('SelectSidebar.button.processing');
    }
    return t('SelectSidebar.button.buyNow');
  }, [isAuthed, stage]);

  const disabledBtn = useMemo(() => {
    if (!isAuthed) {
      return false;
    }
    return !(
      selectedEditionId > -1 && stage !== SERIAL_SIDEBAR_STAGES.PROCESSING
    );
  }, [isAuthed, selectedEditionId, stage]);

  return {
    btnLabel,
    disabledBtn,
    editionList,
    isOpenBrowseListingModal,
    selectedEditionId,
    setIsOpenBrowseListingModal,
    setSelectedEditionId,
    setSort,
    sort,
    sortOptions,
  };
};
