import { gql } from '@apollo/client';
import { sansPrefix } from '@onflow/fcl';
import { useQuery } from 'src/lib/apollo';

// Add prop as needed
export const QUERY_PACK_NFTS_BY_DIST_ID = gql(`
  query QueryPackNftsByDistID($searchInput: SearchPackNftsInput) {
    searchPackNft(searchInput: $searchInput) {
      totalCount
    }
  }
`);

export const useSearchPackNftByDistId = (
  distId: string,
  query = QUERY_PACK_NFTS_BY_DIST_ID,
) => {
  const { data } = useQuery(query, {
    context: { clientName: 'platformAPI' },
    variables: {
      searchInput: {
        filters: {
          dist_id: { eq: distId },
          listing: {
            exists: true,
          },
          type_name: {
            eq: `A.${sansPrefix(
              process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS,
            )}.PackNFT.NFT`,
          },
        },
      },
    },
  });

  return data;
};
