import { isPast } from 'date-fns';
import { assign } from 'xstate';

import { Context, Event } from './types';

export const actions = {
  assignError: assign({
    error: (_, event: any) => {
      return event.dropFailure;
    },
  }),

  clearError: assign({ error: () => null }),

  updateData: assign<Context, Event>((context: Context, event: Event) => {
    // @todo: validations against data?
    return { ...context, ...event?.data };
  }),
};

const isExpired = (reservation) =>
  reservation && isPast(new Date(reservation?.expired_at));

// @NOTE: referenced elsewhere in codebase
export const canJoinNoEntry = ({ queueStatus }) =>
  queueStatus?.queue?.state !== 'IDLE' &&
  queueStatus?.queue?.state !== 'ENDED' &&
  !queueStatus?.entry;

// @NOTE: referenced elsewhere in codebase
export const canRejoinExpired = ({ queueStatus, reservation }) =>
  queueStatus?.entry?.turnStartAt &&
  reservation &&
  isPast(new Date(reservation?.expired_at));

// @NOTE: referenced elsewhere in codebase
export const isEnded = (_, { queueStatus, reservation }) => {
  return (
    queueStatus?.queue?.state &&
    queueStatus?.queue?.state === 'ENDED' &&
    !reservation
  );
};

const canRejoinNoReservation = ({ queueStatus, reservation }) =>
  queueStatus?.entry?.turnStartAt && !reservation;

const canRejoin = (event) =>
  canRejoinExpired(event) || canRejoinNoReservation(event);

const isQueueStateJoinable = ({ queueStatus }) =>
  queueStatus?.queue?.state === 'ACTIVE' ||
  queueStatus?.queue?.state === 'PREQUEUE' ||
  queueStatus?.queue?.state === 'DRAWING';

const isQueueStateAssigningNumbers = ({ queueStatus }) =>
  queueStatus?.queue?.state === 'ACTIVE' ||
  queueStatus?.queue?.state === 'DRAWING';

const canJoin = (_, event) =>
  isQueueStateJoinable(event) && (canJoinNoEntry(event) || canRejoin(event));

export const guards = {
  canBuy: (_, event) => {
    const { queueStatus, reservation } = event;
    return (
      queueStatus?.entry?.turnStartAt && reservation && !isExpired(reservation)
    );
  },
  canJoin,
  inQueue: (_, { queueStatus }) =>
    queueStatus?.entry?.startingPosition > 0 &&
    queueStatus?.entry?.placeInLine >= 0 &&
    queueStatus?.queue?.state !== 'ENDED',
  isEnded,
  isGettingPosition: (_, { queueStatus }) => {
    return (
      queueStatus?.entry?.startingPosition === 0 &&
      queueStatus?.entry?.placeInLine === 0 &&
      isQueueStateAssigningNumbers({ queueStatus })
    );
  },
  isInWaitingRoom: (_, { queueStatus }) =>
    queueStatus.entry &&
    (queueStatus?.queue?.state === 'PREQUEUE' ||
      queueStatus?.queue?.state === 'DRAWING'),
  isJoinError: (_, event) => event?.dropFailure,
  isReservationExpired: (_, { reservation }) => isExpired(reservation),
};

export const services = {};
