import {
  DocumentNode,
  QueryFunctionOptions,
  TypedDocumentNode,
} from '@apollo/client';
import map from 'lodash/map';
import { useQuery } from 'src/lib/apollo';

import { searchDistributionsV2_justDroppedCardVariables } from '../queries/__generated__/searchDistributionsV2_justDroppedCard';
import {
  QUERY_DISTRIBUTIONS,
  VARIABLES_DISTRIBUTIONS,
} from '../queries/searchDistributions';

type SearchDistributionParams = {
  options?: QueryFunctionOptions & { origin?: string };
  query?: DocumentNode | TypedDocumentNode;
  variables?: searchDistributionsV2_justDroppedCardVariables;
};

export function useSearchDistributions<T>({
  query = QUERY_DISTRIBUTIONS,
  variables = VARIABLES_DISTRIBUTIONS(),
  options = {},
}: SearchDistributionParams = {}) {
  const { data, state } = useQuery(query, {
    variables,
    ...options,
  });
  const distributions: T[] = map(
    data?.searchDistributions?.edges,
    (edge) => edge?.node,
  );

  return {
    distributions: distributions?.length > 0 ? distributions : [],
    state,
  };
}
