import { useMultiStyleConfig } from '@chakra-ui/react';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Text,
  Flex,
} from 'src/theme';
import { LANGUAGE_PICKER_THEME_KEY } from 'src/theme/dss-skeleton/components';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';

import { ArrowBottomIcon, CheckIcon } from '../../themes/icons';

type Value = {
  label: string;
  value: string;
};

export interface LanguagePickerProps {
  [key: string]: any;
  defaultValue?: Value;
  icon?: React.ReactNode;
  items?: Array<Value>;
  onChange: (value: Value) => void;
  renderLabel?: (item: Value) => JSX.Element;
}

export const LanguagePicker = ({
  items = [],
  defaultValue,
  icon,
  onChange,
  renderLabel,
  ...props
}: LanguagePickerProps) => {
  const styles = useMultiStyleConfig(LANGUAGE_PICKER_THEME_KEY);
  const { events, trackHandler } = useDssAnalytics();
  const [value, setValue] = useState(defaultValue.value);

  useEffect(() => {
    setValue(defaultValue.value);
  }, [defaultValue.value]);

  const trackMenuButtonClick = () => {
    trackHandler(events.LANGUAGE_MENU_CLICK, {
      item_label: 'quicksettings_language',
    });
  };

  const renderLabelByDefault = (item) => {
    return (
      <>
        {item?.label}
        {item.value === value && (
          <Flex alignItems='center' marginLeft='auto'>
            <CheckIcon width='16px' height='16px' />
          </Flex>
        )}
      </>
    );
  };

  const onMenuClick = useCallback(
    (event) => {
      const id = event.target.dataset.id;
      const selectedValue = items.find((item) => item.value === id);

      trackHandler(events.LANGUAGE_SETTINGS_CHANGE, {
        item_label: 'language',
        previous_value: String(value),
        selected_value: String(id),
      });

      setValue(id);
      onChange(selectedValue);
    },
    [items, onChange],
  );

  const renderLanguages = useCallback(() => {
    return (
      <MenuList sx={styles.menuList} onClick={onMenuClick}>
        {items.map((item) => (
          <MenuItem key={item?.value} data-id={item?.value}>
            {renderLabel ? renderLabel(item) : renderLabelByDefault(item)}
          </MenuItem>
        ))}
      </MenuList>
    );
  }, [items, renderLabel, renderLabelByDefault]);

  const selectedItem = useMemo(
    () => items.find((item) => item.value === value),
    [items, value],
  );

  return (
    <Menu>
      <MenuButton
        as={Button}
        sx={styles.menuButton}
        p={0}
        leftIcon={icon ? icon : null}
        onClick={trackMenuButtonClick}
        rightIcon={<ArrowBottomIcon ml='10px' />}
        {...props}
      >
        <Text sx={styles.selectLabel}>{selectedItem.label}</Text>
      </MenuButton>
      {renderLanguages()}
    </Menu>
  );
};
