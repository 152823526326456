import { gql } from '@apollo/client';
import { sansPrefix } from '@onflow/fcl';
import { CursorDirection } from '__generated__/globalTypes';
import { searchPackNFTsVariables } from '__generated__/searchPackNFTs';

export const VARIABLES = ({
  byIDs = [],
  cursor = '',
  limit = 100,
} = {}): searchPackNFTsVariables => {
  return {
    base: {
      pagination: {
        cursor,
        direction: 'RIGHT' as CursorDirection,
        limit,
      },
    },
    // @ts-ignore
    byIDs,
    typeName: `A.${sansPrefix(
      process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS,
    )}.PackNFT.NFT`,
  };
};

export const QUERY_PACK_NFTS = gql`
  query searchPackNft__ForPackOpening($byIDs: [UInt64!], $typeName: String) {
    searchPackNft(
      searchInput: {
        filters: { id: { in: $byIDs }, type_name: { eq: $typeName } }
      }
    ) {
      edges {
        node {
          dist_id
          hash
          id
          nfts
          owner_address
          salt
          status
          type_name
        }
      }
    }
  }
`;
