import { Player } from '@lottiefiles/react-lottie-player';
import React, { useEffect, useRef } from 'react';

import { EditionFragment_play_metadata_audio_narrator } from 'src/edge/NFT__deprecated/queries/fragments/__generated__/EditionFragment';
import CircleAvatar, {
  CircleProps,
} from 'src/general/components/CircleAvatar/CircleAvatar';
import { BUCKET_STATIC_URL } from 'src/general/constants/assets';
import {
  Box,
  Flex,
  shapiroFeather,
  TextDeprecated,
  TextProps,
} from 'src/theme';

type Props = {
  isAnimationPaused?: boolean;
  narrator: EditionFragment_play_metadata_audio_narrator;
  onClick: () => void;
  selected: boolean;
};

const ANIMATION_URL = `${BUCKET_STATIC_URL}/animations/narrator-voice.json`;

/**
 * Narrator component for moments with audio narration
 *
 * Unfortunately could not go wet for this due to performance constraints
 * so you get the if statement below. But it works much smoother
 *
 * @param props.narrator - the narrator object with name, position etc
 * @param props.onClick - the click handler, included in ...rest
 * @param props.selected - whether or not this track is currently playing
 */
export const MomentHeroNarrator = ({
  narrator: { name, organization, profilePicture, position },
  isAnimationPaused = false,
  selected,
  ...rest
}: Props) => {
  const playerRef = useRef<Player>(null);

  const textProps: TextProps = {
    fontFamily: shapiroFeather,
    fontSize: '12px',
    lineHeight: '11.6px',
    noOfLines: 1,
    overflow: 'visible',
    style: { transition: 'color 0.25s' },
    textAlign: 'center',
    width: '100px',
  };

  const circleAvatarProps: CircleProps = {
    name,
    size: { base: 'md' },
    src: profilePicture,
  };
  let nameColor = 'grey.text';
  let titleColor = 'grey.divider';

  // We are tweaking the values here both for selected states and hover (which is identical to selected)
  if (selected) {
    circleAvatarProps.size.base = 'lg';
    nameColor = 'white';
    titleColor = 'grey.text';
  } else {
    circleAvatarProps.opacity = '40%';
    circleAvatarProps.style = { transition: 'opacity 0.25s, transform 0.25s' };
    circleAvatarProps['_groupHover'] = {
      opacity: '100%',
      transform: 'scale(1.3)',
    };
  }

  // Pause animation
  useEffect(() => {
    if (isAnimationPaused) playerRef.current?.stop();
    else playerRef.current?.play();
  }, [isAnimationPaused]);

  return (
    <Flex
      key={name}
      align='center'
      direction='column'
      p={2}
      cursor='pointer'
      role='group'
      {...rest}
    >
      <Flex pos='relative' align='center' height='64px'>
        {/* Speaking animation */}
        {selected && (
          <Box pos='absolute' left='-68px'>
            <Player
              ref={playerRef}
              autoplay
              loop
              speed={0.75}
              src={ANIMATION_URL}
              style={{ height: '200px', width: '200px' }}
            />
          </Box>
        )}
        <CircleAvatar {...circleAvatarProps} />
      </Flex>
      <TextDeprecated
        mt={3}
        color={nameColor}
        _groupHover={{ color: 'white' }}
        {...textProps}
      >
        {name}
      </TextDeprecated>
      <TextDeprecated
        mt={2}
        color={titleColor}
        _groupHover={{ color: 'grey.text' }}
        {...textProps}
      >
        {[organization, position].filter(Boolean).join(', ')}
      </TextDeprecated>
    </Flex>
  );
};
