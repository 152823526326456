import { ComponentStyleConfig } from 'src/theme';

export const DonutChartTheme: ComponentStyleConfig = {
  baseStyle: {
    statistics: {
      left: '50%',
      position: 'absolute',
      textAlign: 'center',
      top: '50%',
      transform: 'translate(-50%, -50%)',
    },
    wrapper: {
      overflow: 'hidden',
      position: 'relative',
      svg: {
        height: '100%',
        objectFit: 'contain',
        transform: 'rotate(320deg)',
        width: '100%',
      },
    },
  },
  defaultProps: {},
  parts: ['wrapper', 'statistics'],
};
