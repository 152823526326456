import { format } from 'date-fns';
import numeral from 'numeral';

export const pluralizationFormatter = (string, num) =>
  num > 1 ? string + 's' : string;

export const addCommasToNumber = (x: string | number = '') =>
  numeral(x).format('0,0');

export const getFormattedCount = (count) => {
  const numberFormat = new Intl.NumberFormat('en-US');
  const parts = numberFormat.formatToParts(count).map(({ value }) => value);
  return parts.join('');
};

export const getFormattedDate = (timestamp) => {
  return format(new Date(timestamp), 'MM/dd/yyyy, h:mm:ss aa');
};
