import type { ComponentStyleConfig } from '@chakra-ui/react';

export const caret: ComponentStyleConfig = {
  baseStyle: {
    caret: {
      background: 'white',
      height: '120px',
      width: '1px',
    },
    container: {
      bottom: '25%',
      position: 'absolute',
      //TODO
      zIndex: '150',
    },
    info: {
      alignItems: 'center',
      background: 'transparent',
      bottom: '100%',
      display: 'flex',
      height: '30px',
      //TODO
      justifyContent: 'center',

      left: 'calc(50% - 40px)',
      position: 'absolute',

      width: '80px',
    },
  },
  defaultProps: {
    variant: 'common',
  },
  parts: ['container', 'info', 'caret'],
};

export default caret;
