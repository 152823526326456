import {
  DocumentNode,
  QueryFunctionOptions,
  TypedDocumentNode,
} from '@apollo/client';
import { useQuery } from 'src/lib/apollo';

type Params = {
  options?: QueryFunctionOptions & { origin?: string };
  query: DocumentNode | TypedDocumentNode<any, any>;
};

export function useSearchEditions({ query, options = {} }: Params) {
  const { data, state } = useQuery(query, {
    context: {
      clientName: 'platformAPI',
    },
    ...options,
  });

  const editions = [];

  if (data) {
    const editionIds = Object.keys(data);

    editionIds.forEach((id) => {
      editions.push(data[id].edges[0].node);
    });
  }

  return {
    editions,
    state,
  };
}
