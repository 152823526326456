import { common } from './common';
import { gameStats } from './gameStats';
import { header } from './header';

export enum PLAYER_PERFORMANCE_THEME_KEYS {
  COMMON = 'PlayerPerformance/common',
  GAME_STATS = 'PlayerPerformance/GameStats',
  HEADER = 'PlayerPerformance/header',
}

export const PlayerPerformanceTheme = {
  [PLAYER_PERFORMANCE_THEME_KEYS.GAME_STATS]: gameStats,
  [PLAYER_PERFORMANCE_THEME_KEYS.COMMON]: common,
  [PLAYER_PERFORMANCE_THEME_KEYS.HEADER]: header,
};
