import { gql } from '@apollo/client';

// queries data for a packs/pack page to display in:
// - FeaturedDrop
// - PastDrops
// - ThisWeekDrops
export const QUERY_DISTRIBUTIONS = gql`
  query searchDistributions(
    $byIDs: [Int!]
    $byEndTime: TimeRange
    $first: Int
    $after: String
    $productId: String
  ) {
    searchDistributions(
      input: {
        filters: {
          byIDs: $byIDs
          byEndTime: $byEndTime
          byProductID: $productId
        }
        first: $first
        after: $after
      }
    ) {
      pageInfo {
        endCursor
        hasNextPage
      }
      totalCount
      edges {
        cursor
        node {
          uuid
          id
          title
          code
          description
          txHash
          state
          tier
          price {
            value
            currency
          }
          numberOfPackSlots
          images {
            type
            url
          }
          videos {
            type
            url
          }
          startTime
          endTime
          packOdds {
            tier
            value
            displayValue
          }
          editionIds
          queueID
          availableSupply
          totalSupply
        }
      }
    }
  }
`;

export const VARIABLES_DISTRIBUTIONS = ({
  byIDs = [],
  first = 12,
  after = '',
  sortBy = undefined,
  timeRangeEnd = undefined,
  productId = 'Golazos',
} = {}) => {
  return {
    after,
    byEndTime: { timeRangeEnd },
    byIDs,
    first,
    productId,
    sortBy,
  };
};
