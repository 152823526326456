import { chakra, Flex } from '@chakra-ui/react';
import { useState } from 'react';
import {
  Box,
  IconChevronLeft,
  IconChevronRight,
  useMultiStyleConfig,
  ChakraNextImage,
} from 'src/theme';
import { CAROUSEL_THEME_KEY } from 'src/theme/dss-skeleton/components';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';

import PreviewBg from './previewbg.svg';

interface CarouselPaginationProps {
  carouselId: string;
  currentSlide: number;
  eventName?: string;
  numberOfSlides: number;
  onButtonClick: (targetSlide: number) => void;
  onNext: () => void;
  onPrev: () => void;
  previewSrcList?: string[];
  showNav?: boolean;
}

export const CarouselPagination: React.FC<CarouselPaginationProps> = ({
  numberOfSlides,
  previewSrcList,
  currentSlide,
  onNext,
  onPrev,
  onButtonClick,
  carouselId,
  showNav,
}: CarouselPaginationProps) => {
  const styles = useMultiStyleConfig(CAROUSEL_THEME_KEY);
  const { events, trackHandler } = useDssAnalytics();
  const [previewIndex, setPreviewIndex] = useState(null);

  const onMouseOver = (event) => {
    const index = Number(event.target.dataset?.id);
    if (previewSrcList[index]) {
      setPreviewIndex(index);
    }
  };
  const onMouseOut = () => {
    setPreviewIndex(null);
  };

  const onClick = (event) => {
    const index = Number(event.target.dataset?.id);
    trackHandler(events.CAROUSEL_ITEM_CLICK, {
      item_label: `carousel_${index}`,
    });
    onButtonClick(index);
  };

  const handleNavigation = (handler, itemLabel) => (event) => {
    if (event.nativeEvent instanceof KeyboardEvent) {
      if (event.code === 'Space' || event.code === 'Enter') {
        handler();
        event.preventDefault();
        trackHandler(events.CAROUSEL_ITEM_CLICK, {
          item_label: itemLabel,
        });
      }
      return;
    }
    handler();
    trackHandler(events.CAROUSEL_ITEM_CLICK, {
      item_label: itemLabel,
    });
  };

  const handlePrev = handleNavigation(onPrev, 'left_arrow');
  const handleNext = handleNavigation(onNext, 'right_arrow');

  return (
    <Box
      width={{ base: 'calc(100% - 4rem)', md: '80%' }}
      pb={{
        base: 'calc(7%)',
        md: 'min(4px,calc(0.5vh))',
      }}
    >
      <Flex sx={styles.paginationWrapper}>
        {showNav && (
          <IconChevronLeft
            sx={styles.paginationNav}
            aria-label='Previous slide'
            onClick={handlePrev}
            onKeyDown={handlePrev}
            onTouchStart={handlePrev}
            role='button'
            tabIndex={3}
          />
        )}
        {[...new Array(numberOfSlides)].map((_, index) => (
          <Box
            className={currentSlide === index ? 'activeSlide' : ''}
            key={`slide-pagination-chip-${carouselId}-${index}`}
            sx={styles.paginationItem}
            data-id={index}
            onClick={onClick}
            onMouseOut={onMouseOut}
            onMouseOver={onMouseOver}
          >
            {previewIndex === index && (
              <div style={{ position: 'relative' }}>
                <chakra.div sx={styles.preview}>
                  <PreviewBg style={styles.previewBg} />
                  <ChakraNextImage
                    sx={styles.previewImg}
                    src={previewSrcList[index]}
                  />
                </chakra.div>
              </div>
            )}
          </Box>
        ))}
        {showNav && (
          <IconChevronRight
            sx={styles.paginationNav}
            aria-label='Next slide'
            onClick={handleNext}
            onKeyDown={handleNext}
            onTouchStart={handleNext}
            role='button'
            tabIndex={1}
          />
        )}
      </Flex>
    </Box>
  );
};
