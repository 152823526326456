import { useMediaQuery } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import {
  useMultiStyleConfig,
  chakra,
  forwardRef,
  omitThemingProps,
  StylesProvider,
  Flex,
  Box,
  Tabs,
  useDisclosure,
  breakpoints,
} from 'src/theme';
import {
  TOP_NAV_THEME_KEY,
  TopNavAlert,
  TopNavAuth,
  TopNavIconButton,
  TopNavLink,
  TopNavList,
  TopNavListItem,
  TopNavMobile,
} from 'src/theme/dss-skeleton/components';
import {
  BurgerIcon,
  LaLigaLogoIcon,
} from 'src/theme/dss-skeleton/themes/icons';

import { TopNavContextProvider } from './TopNav.context';
import { TopNavProps } from './types';

export const TopNav = forwardRef<TopNavProps, 'nav'>(
  (props: TopNavProps, ref) => {
    const {
      alert,
      isBeta = false,
      isAuthed,
      isMdUp,
      isFlowMaintenanceEnabled,
      profile,
      onSignUp,
      textSignUp = 'Sign up',
      textLogin = 'Login',
      activeHref,
      navList,
      profileNav,
      additionalControls,
      socialMedias,
      onNavLinksClick = () => {},
      isSingleSignUpButton,
    } = props;
    const { isOpen, onToggle } = useDisclosure();
    const styles = useMultiStyleConfig(TOP_NAV_THEME_KEY);
    const [isTabletUp] = useMediaQuery(
      `screen and (min-width: ${breakpoints.tablet})`,
    );

    const { children } = omitThemingProps(props);

    const value = useMemo(
      () => ({
        activeHref,
        additionalControls,
        isAuthed,
        isBeta,
        isFlowMaintenanceEnabled,
        isMdUp,
        isSingleSignUpButton,
        onSignUp,
        profile,
        profileNav,
        textLogin,
        textSignUp,
      }),
      [
        activeHref,
        additionalControls,
        isAuthed,
        isBeta,
        isFlowMaintenanceEnabled,
        isMdUp,
        isSingleSignUpButton,
        onSignUp,
        profile,
        profileNav,
        textLogin,
        textSignUp,
      ],
    );

    return (
      <TopNavContextProvider value={value}>
        <StylesProvider value={styles}>
          <Flex sx={styles.container}>
            {alert && <TopNavAlert message={alert} />}
            <chakra.nav ref={ref} sx={styles.nav} pr={isBeta ? '21px' : '4px'}>
              {children ?? (
                <>
                  <Flex gridArea='start'>
                    {!isTabletUp && (
                      <TopNavIconButton
                        aria-label='burger'
                        icon={<BurgerIcon />}
                        onClick={onToggle}
                      />
                    )}
                    <TopNavLink justifyContent='center' display='flex' href='/'>
                      <LaLigaLogoIcon
                        w={isTabletUp ? '140px' : '105px'}
                        h='auto'
                      />
                    </TopNavLink>
                  </Flex>
                  <Tabs display={isTabletUp ? 'flex' : 'none'}>
                    <TopNavList gridArea='mid'>
                      {navList.map(({ href, label }) => (
                        <TopNavListItem
                          key={label}
                          isSelected={activeHref.includes(href)}
                          onClick={() => onNavLinksClick({ href, label })}
                        >
                          <TopNavLink href={href}>{label}</TopNavLink>
                        </TopNavListItem>
                      ))}
                    </TopNavList>
                  </Tabs>
                  <Flex align='center' gap={4} gridArea='end' mt='-5px'>
                    <Box display={isTabletUp ? 'flex' : 'none'}>
                      {additionalControls}
                    </Box>
                    <TopNavAuth />
                  </Flex>

                  {!isTabletUp && (
                    <TopNavMobile
                      isOpen={isOpen}
                      navList={navList}
                      onToggle={onToggle}
                      socialMedias={socialMedias}
                      trackHandler={onNavLinksClick}
                    />
                  )}
                </>
              )}
              {isBeta && <Box sx={styles.betaBlock}>BETA</Box>}
            </chakra.nav>
          </Flex>
        </StylesProvider>
      </TopNavContextProvider>
    );
  },
);

TopNav.displayName = 'Nav';
