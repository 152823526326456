enum Z_INDEX_TYPES {
  PRIMARY_BACKGROUND,
  SECONDARY_BACKGROUND,
  MAIN,
  OVERLAY,
  RIBBON,
  MENU,
  PACK_OPENING_EXPERIENCE,
  DROP_MISSES,
}

const zIndexOf = (key: Z_INDEX_TYPES) => {
  const items = {
    [Z_INDEX_TYPES.SECONDARY_BACKGROUND]: -2,
    [Z_INDEX_TYPES.PRIMARY_BACKGROUND]: -1,
    [Z_INDEX_TYPES.MAIN]: 0,
    [Z_INDEX_TYPES.OVERLAY]: 1,
    [Z_INDEX_TYPES.RIBBON]: 2,
    [Z_INDEX_TYPES.MENU]: 3,
    [Z_INDEX_TYPES.PACK_OPENING_EXPERIENCE]: 4,
    [Z_INDEX_TYPES.DROP_MISSES]: 5,
  };
  return items[key] || 0;
};

export { Z_INDEX_TYPES, zIndexOf };
