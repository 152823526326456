import {
  useStyles,
  chakra,
  useMultiStyleConfig,
  forwardRef,
  StylesProvider,
} from '@chakra-ui/react';
import type { ReactNode } from 'react';
import { COUNTER_THEME_KEY } from 'src/theme/dss-skeleton/components';

export type LogicalCounterPosition =
  | 'top-start'
  | 'top-end'
  | 'bottom-start'
  | 'bottom-end';

export type CounterPositionWithLogical =
  | LogicalCounterPosition
  | 'top'
  | 'top-left'
  | 'top-right'
  | 'bottom'
  | 'bottom-left'
  | 'bottom-right';

export type CounterPosition = Exclude<
  CounterPositionWithLogical,
  LogicalCounterPosition
>;

export type LogicalPlacementMap = Record<
  LogicalCounterPosition,
  { ltr: CounterPosition; rtl: CounterPosition }
>;

export interface CounterIndicatorProps {
  count: number;
}

export interface CounterProps {
  children: ReactNode;
  position: CounterPositionWithLogical;
  size?: 'sm';
  variant?: 'default';
}

const CounterIndicator = forwardRef<CounterIndicatorProps, 'div'>(
  ({ count, ...props }, ref) => {
    const styles = useStyles();

    return (
      <chakra.div
        className='skeleton-counter-indicator'
        ref={ref}
        __css={styles.indicator}
        children={count}
        {...props}
      />
    );
  },
);

export const Counter = forwardRef<CounterProps, 'div'>(
  ({ children, position, variant, size, ...props }, ref) => {
    const styles = useMultiStyleConfig(COUNTER_THEME_KEY, {
      size,
      variant,
    }); // const logicals: LogicalPlacementMap = {
    //   'top-start': { ltr: 'top-left', rtl: 'top-right' },
    //   'top-end': { ltr: 'top-right', rtl: 'top-left' },
    //   'bottom-start': { ltr: 'bottom-left', rtl: 'bottom-right' },
    //   'bottom-end': { ltr: 'bottom-right', rtl: 'bottom-left' },
    // };
    // const computedPosition = position ?? 'top-right';
    // const logical = logicals[computedPosition as keyof typeof logicals];

    return (
      <StylesProvider value={styles}>
        <chakra.div
          className='skeleton-counter'
          ref={ref}
          __css={styles.container}
          {...props}
        >
          <CounterIndicator count={6} />
          {children}
        </chakra.div>
      </StylesProvider>
    );
  },
);
