import { TeamTab } from 'src/modules/FTUEV2/components/TeamPicker';
import { getActiveTeamIDsByLeague } from 'src/modules/teams.golazos/utils';
import { Flex, Grid, Tabs, TabList } from 'src/theme';

export const TeamPicker = () => {
  const teams = getActiveTeamIDsByLeague();

  return (
    <Flex direction='column'>
      <Tabs defaultIndex={100}>
        <Grid
          justifyContent='stretch'
          gridRowGap={4}
          gridColumnGap={6}
          gridAutoRows='60px'
          templateColumns='repeat(auto-fit, minmax(48px, 1fr))'
          overflowX='auto'
          overflowY='hidden'
        >
          {teams.map((team) => {
            return (
              <TabList key={team} justifyContent='center' border='none'>
                <TeamTab team={team} />
              </TabList>
            );
          })}
        </Grid>
      </Tabs>
    </Flex>
  );
};
