import React from 'react';
import { Box, useMultiStyleConfig } from 'src/theme';
import { HERO_SECTION_THEME_KEYS } from 'src/theme/dss-skeleton/components';
import {
  ImageMediaType,
  VideoMediaType,
} from 'src/theme/dss-skeleton/components/types';

import { AssetCarousel } from '../Carousel';

export const HeroCarousel = ({
  items,
}: {
  items: (ImageMediaType | VideoMediaType)[];
}): JSX.Element => {
  const { carousel, slideContainer, assets } = useMultiStyleConfig(
    HERO_SECTION_THEME_KEYS.COMMON,
  );
  return (
    <Box sx={carousel}>
      <AssetCarousel
        assets={items}
        carouselId='hero-carousel'
        imageProps={assets}
        showControls
        showNav
        slideContainerProps={slideContainer}
        videoProps={assets}
      />
    </Box>
  );
};
