import { gql } from '@apollo/client';

export const QUERY_SEARCH_ALL_CHALLENGES = gql`
  query searchChallenges_allChallenges(
    $byIDs: [String]
    $byCategory: [ChallengeCategory]
    $byChallengeSubmissionStatus: [ChallengeSubmissionStatus]
    $byStartTime: TimeRangeOptional
    $byEndTime: TimeRangeOptional
    $sortBy: ChallengeSortType
    $first: Int
    $after: String
  ) {
    searchChallenges(
      input: {
        filters: {
          byIDs: $byIDs
          byCategory: $byCategory
          byChallengeSubmissionStatus: $byChallengeSubmissionStatus
          byStartTime: $byStartTime
          byEndTime: $byEndTime
        }
        sortBy: $sortBy
        first: $first
        after: $after
      }
    ) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          category
          summary
          startDate
          endDate
          images {
            url
            type
          }
          videos {
            url
            type
          }
          slots {
            id
          }
          rewardID
          communityCompletions
          relatedGames {
            id
            awayTeamID
            homeTeamID
          }
          userSubmissionMomentNFTIDs
          userSubmissionTeamNFTIDs
          userSubmissionStatus
          visibility
        }
        cursor
      }
    }
  }
`;

export const ChallengeSlotQueryFragment = gql`
  fragment ChallengeSlotQueryFragment on ChallengeSlotQuery {
    byEditionFlowIDs
    byPlayTypes
    byPlayerIDs
    bySetIDs
    byTeamIDs
    byTiers
    byPlayerPositions
    bySeries
  }
`;

export const QUERY_SEARCH_CHALLENGE = gql`
  query searchChallenges_challenge($byIDs: [String]) {
    searchChallenges(input: { filters: { byIDs: $byIDs }, first: 1 }) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          id
          name
          category
          summary
          description
          instructions {
            instructions
          }
          finePrint
          startDate
          endDate
          images {
            url
            type
          }
          videos {
            url
            type
          }
          slots {
            id
            title
            description
            query {
              ...ChallengeSlotQueryFragment
            }
            slotOrder
            isWildcard
            submission {
              id
              nftID
            }
          }
          rewardID
          communityCompletions
          relatedGames {
            id
            awayTeamID
            homeTeamID
          }
          userSubmissionMomentNFTIDs
          userSubmissionTeamNFTIDs
          userSubmissionStatus
          reward @client {
            rewardType
            id
            label
            claimExpiry
          }
          visibility
        }
        cursor
      }
    }
  }
  ${ChallengeSlotQueryFragment}
`;

export const SubmitChallengeFragment = gql`
  fragment SubmitChallengeFragment on Challenge {
    id
    name
    category
    startDate
    endDate
    slots {
      id
      title
      description
      query {
        ...ChallengeSlotQueryFragment
      }
      slotOrder
      isWildcard
      submission {
        id
        nftID
        nftFlowID
        momentNFTListing {
          id
          listingFlowID
        }
      }
    }
    images {
      url
      type
    }
    videos {
      url
      type
    }
    rewardID
    userSubmissionMomentNFTIDs
    userSubmissionTeamNFTIDs
    userSubmissionStatus
    visibility
  }
  ${ChallengeSlotQueryFragment}
`;

export const QUERY_SUBMIT_CHALLENGE = gql`
  query searchChallenges_submitChallenge($byIDs: [String]) {
    searchChallenges(input: { filters: { byIDs: $byIDs }, first: 1 }) {
      totalCount
      pageInfo {
        endCursor
        hasNextPage
      }
      edges {
        node {
          ...SubmitChallengeFragment
        }
        cursor
      }
    }
  }
  ${SubmitChallengeFragment}
`;

export const QUERY_CHALLENGE_PAGE_DATA = gql`
  query searchChallenges_challengePageData($byIDs: [String]) {
    searchChallenges(input: { filters: { byIDs: $byIDs }, first: 1 }) {
      edges {
        node {
          id
          category
          name
          pageData {
            completedTitle
            completedDescription
            incompletedTitle
            incompletedDescription
            successDescription
            successTitle
            successMedia {
              type
              url
            }
          }
          startDate
          endDate
          images {
            type
            url
          }
          videos {
            type
            url
          }
          slots {
            id
          }
          userSubmissionMomentNFTIDs
          visibility
        }
      }
    }
  }
`;

export const VARIABLES_SEARCH_CHALLENGES = ({
  byIDs = [],
  byCategory = [],
  byChallengeSubmissionStatus = [],
  byStartTime = null,
  byEndTime = null,
  first = 12,
  after = '',
  sortBy = undefined,
} = {}) => {
  return {
    after,
    byCategory,
    byChallengeSubmissionStatus,
    byEndTime,
    byIDs,
    byStartTime,
    first,
    sortBy,
  };
};

export const VARIABLES_SEARCH_CHALLENGE = ({ byIDs = [] } = {}) => {
  return {
    byIDs,
  };
};
