import { captureException } from '@sentry/nextjs';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from 'src/lib/translations/locale/en.json';
import es from 'src/lib/translations/locale/es.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init(
    {
      fallbackLng: ['en', 'es'],
      interpolation: {
        escapeValue: false,
      },
      keySeparator: false,
      resources: {
        en: {
          translation: en,
        },
        es: {
          translation: es,
        },
      },
      returnEmptyString: false,
    },
    (error) => {
      if (error) {
        captureException(error);
      }
    },
  );

export const i18nInstance = i18n;
