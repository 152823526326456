import merge from 'lodash/merge';
import { graphql } from 'msw';
import type {
  getMyProfile as GetMyProfileQuery,
  getMyProfile_getMyProfile as TUserProfile,
} from 'src/edge/getMyProfile/__generated__/getMyProfile';
import type { PartialDeep } from 'type-fest';

export const PROFILE = {
  banners: [],
  createdAt: null,
  dapperID: 'auth0|nicksalloum',
  email: 'nick@dapperlabs.com',
  favoriteTeamIDs: [],
  flowAddress: '0x123',
  hasCompletedFTUE: false,
  id: 'nick',
  isCurrentTOSSigned: false,
  isMarketingAllowed: true,
  isVerified: false,
  phoneNumber: '4161234567',
  playbookSeen: null,
  profileImageUrl: 'http://placehold.jp/150x150.png',
  selectedBanner: null,
  selectedBannerID: null,
  username: 'nicksalloum',
  verifiedUserProfile: null,
};

export const myProfileFactory = (
  overrides: PartialDeep<TUserProfile> = {},
): TUserProfile =>
  merge(
    {},
    {
      __typename: 'UserProfile',
      ...PROFILE,
    },
    overrides,
  );

export const myProfileHandler = (myProfile: TUserProfile) =>
  graphql.query<GetMyProfileQuery>('GetMyProfile', (req, res, ctx) => {
    return res(
      ctx.data({
        // @ts-ignore
        getMyProfile: { userProfile: myProfile },
      }),
    );
  });
