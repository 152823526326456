import React from 'react';
import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Flex,
  IconInfo,
  Text,
  Tooltip,
  useMultiStyleConfig,
} from 'src/theme';

import { getFormattedCount } from '../../utils/stringFormatter';

type CirculationDescriptionProps = {
  id: string;
  percent: number;
  value: number;
};
export const CirculationDescription = ({
  id,
  percent,
  value,
}: CirculationDescriptionProps) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig('MomentCirculation/description', {
    variant: id,
  });

  return (
    <Flex key={`chart-info-${id}`} sx={styles.wrapper}>
      <Box sx={styles.indicator} />
      <Box>
        <Text sx={styles.valueText}>{getFormattedCount(value)}</Text>
      </Box>
      <Box sx={styles.line} />
      <Flex alignItems='center' justifyContent='end'>
        <Box>
          <Text sx={styles.label}>
            {percent}% {t(`moment.circulation.${id}`)}
          </Text>
        </Box>
        <Tooltip
          sx={{ display: 'flex' }}
          label={t(`moment.circulation.tooltip.${id}`)}
          placement='top'
        >
          <Box sx={styles.tooltip}>
            <IconInfo />
          </Box>
        </Tooltip>
      </Flex>
    </Flex>
  );
};
