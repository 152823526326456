import { chakra, Flex, Grid, Text } from '@chakra-ui/react';
import { useTranslation } from 'src/lib/translations';
import { useMultiStyleConfig } from 'src/theme';
import { HERO_SECTION_THEME_KEYS } from 'src/theme/dss-skeleton/components';

import { RarityTypes } from './types';

type RarityTierProps = {
  burnedSize: number;
  editionSize: number;
  isShowForSale: boolean;
  listingSize?: number | undefined | null;
  serialNumber?: number | string;
  type: RarityTypes;
};
export const RarityTier = ({
  type,
  editionSize,
  serialNumber = '',
  listingSize = 0,
  isShowForSale,
}: RarityTierProps): JSX.Element => {
  const styles = useMultiStyleConfig(HERO_SECTION_THEME_KEYS.RARITY_TIER, {
    variant: type,
  });
  const { t } = useTranslation();

  return (
    <chakra.div sx={styles.container}>
      <Grid
        sx={styles.editionDetails}
        templateColumns={isShowForSale ? '2fr 3fr 1fr' : '1fr 2fr'}
      >
        <Flex direction='column'>
          <Text sx={styles.label}>{t('heroSection.rarityTier.tier')}</Text>
          <div>
            <chakra.span sx={styles.rarityText}>
              {t(`heroSection.rarityTier.${type.toLowerCase()}`)}
            </chakra.span>
          </div>
        </Flex>
        <Flex direction='column'>
          <Text sx={styles.label}>
            {serialNumber
              ? t('heroSection.rarityTier.serial')
              : t('heroSection.rarityTier.editionSize')}
          </Text>
          <Text sx={styles.text}>{`#${serialNumber}/${editionSize}`}</Text>
        </Flex>
        {isShowForSale ? (
          <Flex direction='column'>
            <Text sx={styles.label}>{t('heroSection.rarityTier.forSale')}</Text>
            <Text sx={{ ...styles.text, ...styles.listingText }}>
              {listingSize}
            </Text>
          </Flex>
        ) : null}
      </Grid>
      {/*<Box sx={styles.burnedContainer}>
        <Text>
          {burnedSize} {t('heroSection.rarityTier.burnedMoments')}
        </Text>
      </Box>*/}
    </chakra.div>
  );
};
