import React, { useMemo } from 'react';
import { Box } from 'src/theme';

const INITIAL_OFFSET = 250;
const FIT_OFFSET = 250;

export const TransactionsModalCircleTimer = ({
  progress = 0,
}: {
  progress?: number;
}) => {
  const strokeDashoffset = useMemo(
    () => FIT_OFFSET - progress * FIT_OFFSET,
    [progress],
  );

  const commonAttr: { [key: string]: string; strokeLinecap: 'round' } = {
    cx: '50',
    cy: '50',
    fill: 'none',
    r: '34',
    strokeDashoffset: '0',
    strokeLinecap: 'round',
    strokeWidth: '8',
  };
  return (
    <Box minWidth='50px'>
      <svg width='50' height='50' viewBox='0 0 100 100'>
        <circle
          {...commonAttr}
          stroke='#8A94A6'
          strokeDasharray={String(INITIAL_OFFSET)}
        />
        <circle
          {...commonAttr}
          stroke='white'
          strokeDasharray={String(FIT_OFFSET)}
          strokeDashoffset={strokeDashoffset}
        />
      </svg>
    </Box>
  );
};
