import axios from 'axios';
import { getSession, Session, useSession } from 'src/edge/session';
import { EVENTS } from 'src/edge/session/fsm/types';
import { UpsertUserProfileV3 } from 'src/edge/upsertUserProfile/__generated__/UpsertUserProfileV3';
import { TOKEN_HEADER } from 'src/lib/apollo/initApolloClient';
import { apiFavouriteTeam } from 'src/modules/routes';

/**
 * Hook for updating the favourite team
 *
 * Also updates the state with the response
 *
 * @returns {{ updateFavoriteTeam }}
 */
export const useUpdateFavoriteTeam = () => {
  const { send } = useSession();

  const updateFavoriteTeam = async (teamId: string) => {
    const { data: profile, session } = await updateFavouriteTeam(teamId);
    send({
      data: {
        profile,
        session: { user: session?.data?.user },
      },
      // @ts-ignore
      type: EVENTS.UPDATE_USER_DATA,
    });
  };

  return {
    updateFavoriteTeam,
  };
};

/**
 * Helper to call FEBE favourite team
 *
 * Does not update the state
 *
 * @param teamId - favourite team id
 */
export const updateFavouriteTeam = async (
  teamId: string,
): Promise<{ data: UpsertUserProfileV3; session: Session }> => {
  const session = getSession();
  const token = await session.getIdToken();

  const { data } = await axios.post(
    apiFavouriteTeam(),
    {
      teamId,
    },
    {
      headers: {
        [TOKEN_HEADER]: token,
      },
    },
  );

  return { data, session };
};
