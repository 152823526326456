import React from 'react';

import { discord, twitter, instagram } from 'src/modules/routes';
import {
  Flex,
  IconDiscordLogo,
  IconTwitterLogo,
  IconInstagramLogo,
  Link,
  Props,
} from 'src/theme';

const SOCIAL_LINKS: Props[] = [
  {
    children: <IconDiscordLogo boxSize={6} />,
    href: discord,
    rel: 'noreferrer',
    target: '_blank',
  },
  {
    children: <IconTwitterLogo boxSize={6} />,
    href: twitter,
    rel: 'noreferrer',
    target: '_blank',
  },
  {
    children: <IconInstagramLogo boxSize={6} />,
    href: instagram,
    rel: 'noreferrer',
    target: '_blank',
  },
];

export default function Socials() {
  return (
    <Flex gridGap={4}>
      {SOCIAL_LINKS.map((socialLink) => (
        <Link key={socialLink.href} boxSize={6} {...socialLink} />
      ))}
    </Flex>
  );
}
