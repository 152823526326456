import { useMultiStyleConfig } from '@chakra-ui/react';
import React, { useState } from 'react';
import { Flex } from 'src/theme';
import {
  ArrowBottomIcon,
  CheckIcon,
} from 'src/theme/dss-skeleton/themes/icons';

export interface Option {
  text: string;
  value: string | any;
}

export interface BrowseSortByProps {
  onChange: (item: Option) => void;
  options: Option[];
  value?: Option;
}

/**
 * Search Sort by dropdown
 */
const BrowseSortBy = ({ value, options, onChange }: BrowseSortByProps) => {
  const styles = useMultiStyleConfig('SelectSidebar');
  const [isOpen, setIsOpen] = useState(false);
  return (
    <Flex sx={styles.selectContainer} onClick={() => setIsOpen(!isOpen)}>
      {value?.text}
      <ArrowBottomIcon
        ml='auto'
        transform={isOpen ? 'rotate(180deg)' : 'none'}
        transition='transform 0.08s linear'
      />
      <Flex
        sx={styles.selectItemsWrapper}
        visibility={isOpen ? 'visible' : 'hidden'}
      >
        {options.map((item) => {
          return (
            <Flex
              key={item.text}
              sx={styles.selectItemWrapper}
              onClick={() => onChange(item)}
            >
              {item.text}
              {value.text === item.text && <CheckIcon ml='auto' />}
            </Flex>
          );
        })}
      </Flex>
    </Flex>
  );
};

export default BrowseSortBy;
