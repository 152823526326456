// @ts-nocheck
import fromEntries from 'object.fromentries';
import { captureException } from 'src/lib/sentry';

import { COUNTRY_DATA } from './constants/playerCountry';
import { POSITION_DATA } from './constants/playerPosition';
import { PLAYER_DATA } from './constants/players';
import {
  PLAY_TYPES_DATA,
  PLAY_TYPE_NOT_FOUND_KEY,
} from './constants/playTypes';
import { SEASONS_DATA } from './constants/seasons';
import { SETS_DATA } from './constants/sets';
import {
  TEAM_DATA,
  TEAM_LOGO_BUCKET_URL,
  CURRENT_SEASON,
  League,
  Branding,
  BrandingUpdate,
} from './constants/teamData';
import { getAllSets_getAllSets } from './getAllSets/__generated__/getAllSets';

// BEGIN PRIVATE HELPERS
//
// `getTeamData() and getAllTeamsData rely on the private functions below.
// They are the main functions that are leveraged in the other helpers to
// surface specific props. Please do not export these private functions

/**
 * @private
 * @param {string} teamId - team ID, eg. '2700'
 * @param {string} year - year of an nfl year
 */
function applyUpdateForTeamAndYear(teamId: string, year: string) {
  return function applyTeamUpdate(team: Branding, update: BrandingUpdate) {
    if (update.teamId !== teamId) return team; // Update is for a different team
    if (update.year < year) return team; // Update is before the desired year

    // merge current info with update
    return { ...team, ...update };
  };
}

/**
 * @private
 * @param {string} teamId - team ID, eg. '1610612737'
 * @returns {object} team - logoPath decorated
 */
function decorateTeamLogo(team: Branding): Branding {
  // TODOAM temp use of PNGs until we get real assets
  const fileType = 'svg';

  // if (team.teamId === NFLTeamIds.SLR_RAMS) fileType = 'png';

  return {
    ...team,
    logoPath: `${TEAM_LOGO_BUCKET_URL}${team.logoPath}/${team.teamId}.${fileType}`,
  };
}

/**
 * @private
 * @param {string} teamId - team ID, eg. '1610612737'
 * @returns {object} team - altLogoPath decorated
 */
function decorateTeamAltLogo(team: Branding): Branding {
  if (!team.altLogoPath) return team;

  return {
    ...team,
    altLogoPath: `${TEAM_LOGO_BUCKET_URL}${team.altLogoPath}/${team.teamId}.svg`,
  };
}

/**
 * @private
 * @param {string} teamId - team ID, eg. '1610612737'
 * @returns {object} team - decorated
 * note additional decorators can be chained:
 * eg. decorateFutureTeamProp(decorateTeamAltLogo(decorateTeamLogo(team)))
 */
function decorateTeam(team: Branding): Branding {
  return decorateTeamAltLogo(decorateTeamLogo(team));
}

// this helps make lookups quicker 🐰
const MEMO = {};

/**
 * @private
 * @param {string} teamId - team ID
 * @param {integer} year - eg 1999
 * @returns {string}
 */
function memoKey(teamId: string, year: string): string {
  return [teamId, year].join('|');
}

/**
 * This pulls the team IDs from the TEAM_DATA object
 */
const TEAM_IDS = Object.keys(TEAM_DATA);

// END OF PRIVATE HELPERS

/**
 * getTeamData - the main function in this utility, most other utilities rely on
 * it. Please be careful ⚠️
 * @param {string} teamId - team ID, eg. '1610612737'
 * @param {string} year - year, eg. '2020-21'
 * @returns {object} team branding data
 */
export function getTeamData(
  teamId: string,
  year: string = CURRENT_SEASON,
): Branding {
  if (!teamId) return null;
  const key = memoKey(teamId, year);
  if (MEMO[key] != null) return MEMO[key];
  if (!TEAM_DATA[teamId]) {
    captureException(`branding not found for ${teamId}`);
    return undefined;
  }
  MEMO[key] = decorateTeam(
    TEAM_DATA[teamId].reduce<Branding>(
      applyUpdateForTeamAndYear(teamId, year),
      TEAM_DATA[teamId][0],
    ),
  );
  return MEMO[key];
}
/**
 * getTeamBrandingSeasons - returns an array of years where the branding was
 * updated.
 * @param {string} teamId [description]
 * @return {string[]} [description]
 */
export function getTeamBrandingSeasons(teamId: string): string[] {
  if (!teamId) return null;

  return TEAM_DATA[teamId].map(({ year }) => year);
}

/**
 * getAllTeamsData - similar to getTeamData, but it returns all the teams
 * @param {string} year - year, eg. '2020-21'
 * @returns {object} - all teams branding data, keyed by teamId
 */
export function getAllTeamsData(year: string = CURRENT_SEASON): Branding[] {
  const key = year;
  if (MEMO[key]) return MEMO[key];
  MEMO[key] = fromEntries(TEAM_IDS.map((id) => [id, getTeamData(id, year)]));
  return MEMO[key];
}

export function getAllTeamsFilterData() {
  const teamsData = getAllTeamsData();

  // getAllTeamsData is an object with shape {[value]: {name, ...}}
  // skeleton-ux search requires shape {label, value}
  const teamsFilterData = Object.keys(teamsData).map((key) => ({
    label: teamsData[key].name,
    value: key,
  }));

  return teamsFilterData;
}

export function getAllPlayerCountryData() {
  return COUNTRY_DATA;
}

export function getAllSetsData() {
  return SETS_DATA;
}

export function getAllPlayersData() {
  return PLAYER_DATA;
}

export function getAllPositionsData(t) {
  // Passing down translate function
  return POSITION_DATA(t);
}

export function getAllSeasonsData() {
  return SEASONS_DATA;
}

export function getAllPlayTypesData(t) {
  const playTypesData = PLAY_TYPES_DATA(t);

  // Filter out entries that don't have a key in Lokalise.
  // So whenever new Play Types are created, add them!
  for (const [key, value] of Object.entries(playTypesData)) {
    if (value.name === PLAY_TYPE_NOT_FOUND_KEY) {
      delete playTypesData[key];
    }
  }

  return playTypesData;
}

type LeagueStrings = keyof typeof League;

/**
 * getIDsByLeague
 * @param {string} League
 * @returns [array] - team IDs
 */
const getIDsByLeague = (league: LeagueStrings): string[] =>
  Object.values(getAllTeamsData())
    .filter((team) => team.league === league)
    .map((team) => team.teamId);

export const getNFLTeamIds = (): string[] => getIDsByLeague(League.NFL);

/**
 * getTeamNamesByLeague
 * @param {string} League - NFL
 * @returns [array] - team names
 */
export const getTeamNamesByLeague = (league: LeagueStrings): string[] =>
  Object.values(getAllTeamsData())
    .filter((team) => team.league === league)
    .map((team) => team.name);

export const getActiveTeamNamesByLeague = (league: LeagueStrings): string[] =>
  Object.values(getAllTeamsData())
    .filter((team) => team.league === league)
    .filter((team) => team.isActive)
    .map((team) => team.name);

export const getActiveTeamIDsByLeague = (league = League.NFL): string[] =>
  Object.values(getAllTeamsData())
    .filter((team) => team.league === league)
    .filter((team) => team.isActive)
    .sort((a, b) => a.name.localeCompare(b.name))
    .map((team) => team.teamId);

export const getNFLTeamNames = (): string[] => getTeamNamesByLeague(League.NFL);

export const getActiveNFLTeamNames = (): string[] =>
  getActiveTeamNamesByLeague(League.NFL);
// UTILITIES TO GET SPECIFIC TEAM BRANDING DATA

/**
 * getTeamLeague
 * @param {string} teamId
 * @returns {string} league
 */
export const getTeamLeague = (teamId: string): string => {
  return getTeamData(teamId).league;
};

export function getTeamLogo(
  teamId: string,
  year: string = CURRENT_SEASON,
): string {
  return getTeamData(teamId, year)?.logoPath;
}

/**
 * - Some team's logos cannot be displayed on their primary color, so we add an
 *   `altLogoPath`. This logo can be displayed on the primary color. If there is
 *   no `altLogo` the `logoPath` will be returned.
 * - The reason this is not called `getTeamAltLogo` is that we want to make it
 *   clear that these alts exist to be used on the primary color
 */
export function getTeamLogoOnPrimaryColor(
  teamId: string,
  year: string = CURRENT_SEASON,
): string {
  const altLogo = getTeamData(teamId, year)?.altLogoPath;
  if (altLogo) return altLogo;
  return getTeamLogo(teamId, year);
}

export const getRandomNFLTeamData = (): Branding => {
  const allTeams = getNFLTeamIds();
  const randomTeam = allTeams[Math.floor(Math.random() * allTeams.length)];
  return getTeamData(randomTeam);
};

export const getTeamNameFromId = (
  teamId: string,
  year: string = CURRENT_SEASON,
): string => {
  return getTeamData(teamId, year)?.name;
};

export const getTeamLocationFromId = (
  teamId: string,
  year: string = CURRENT_SEASON,
): string => {
  return getTeamData(teamId, year)?.location;
};

export const getTeamShortNameFromId = (
  teamId: string,
  year: string = CURRENT_SEASON,
): string => {
  return getTeamData(teamId, year)?.shortName;
};

export const getTeamAbbreviationFromId = (
  teamId: string,
  year: string = CURRENT_SEASON,
): string => getTeamData(teamId, year)?.abbreviation;

export const getIdFromTeamName = (
  teamName: string,
  year: string = CURRENT_SEASON,
): string => {
  return Object.values(getAllTeamsData(year)).find((team) => {
    return team.name.toLowerCase() === teamName.toLowerCase();
  })?.teamId;
};

export const getTeamColorFromId = (
  teamId: string,
  year: string = CURRENT_SEASON,
): string => {
  return getTeamData(teamId, year)?.color;
};

export const getTextColorFromTeamId = (
  teamId: string,
  year: string = CURRENT_SEASON,
): string => {
  return getTeamData(teamId, year)?.textColor;
};

// @note slugs should always be from the lastest year
export const getIdFromTeamSlug = (teamSlug: string): string => {
  return Object.values(getAllTeamsData()).find((team) => {
    return team.slug === teamSlug;
  })?.teamId;
};

export const getSlugFromTeamName = (
  teamName: string,
  year: string = CURRENT_SEASON,
): string => {
  const teamId = getIdFromTeamName(teamName, year);
  return getTeamData(teamId, year)?.slug;
};

export const getSlugFromId = (teamId: string): string => {
  const teamName = getTeamNameFromId(teamId);
  return getSlugFromTeamName(teamName);
};

export const getTeamShortNameFromSlug = (
  teamSlug: string,
  year: string = CURRENT_SEASON,
): string => {
  const teamId = getIdFromTeamSlug(teamSlug);

  return getTeamData(teamId, year)?.shortName;
};

export const getTeamColorFromSlug = (teamSlug: string): string => {
  const teamId = getIdFromTeamSlug(teamSlug);

  return getTeamColorFromId(teamId);
};

/**
 * All logos are cropped to have some whitespace around the outside, if you want
 * to remove the whitespace, you'll need to increase the size of the logo by
 * 12.5%. For example, if the logo is being used as a background image, you can
 * add:
 * `background-size: ${LOGO_CROP_PERCENTAGE}%`
 */
export const LOGO_CROP_PERCENTAGE = 112.5;

/**
 * Converts Player height to feet and inches.
 */

export const convertPlayerHeightInFeetInches = (value: number) => {
  const inches = value % 12;
  const feet = Math.floor(value / 12);

  return {
    feet,
    inches,
  };
};

export const filterSetsFromTeam = (
  teamID: string,
  sets: getAllSets_getAllSets[],
) => {
  const filteredArray = sets.filter((set) => {
    return (
      set.totalEditions > 0 &&
      set.editions.find((edition) => {
        return edition?.play?.metadata?.teamID === teamID;
      })
    );
  });
  return filteredArray;
};
