import { gql } from '@apollo/client';

import { PlayFragment } from './Play';
import { SeriesFragment } from './Series';
import { SetFragment } from './Set';

export const EditionFragment = gql`
  fragment EditionFragment on Edition {
    id
    flowID
    series {
      ...SeriesFragment
    }
    set {
      ...SetFragment
    }
    play {
      ...PlayFragment
    }
    maxMintSize
    currentMintSize
    tier
    numMomentsOwned
    numMomentsInPacks
    numMomentsUnavailable
    numMomentsBurned
  }
  ${SeriesFragment}
  ${SetFragment}
  ${PlayFragment}
`;
