import { ImageLoaderProps } from 'next/image';
import { stringify } from 'qs';

type Props = {
  height?: number | string;
} & ImageLoaderProps;

const DEFAULT_IMAGE_QUALITY = 80;

export const imageOptimizerCDN = ({
  src,
  width,
  height = null,
  quality,
}: Props) => {
  let scheme = '';
  let sizeString = '';

  if (src.startsWith('//')) scheme = 'https:';
  if (!src) return null;
  if (width) sizeString = `width=${width},`;
  if (height) sizeString = sizeString + `height=${height},`;

  const imgOptimizerLink = `${
    process.env.NEXT_PUBLIC_EXTERNAL_ASSETS_URL
  }/${sizeString}quality=${quality ?? DEFAULT_IMAGE_QUALITY}/${scheme}${src}`;

  return imgOptimizerLink;
};

export const parseCdnParams = (params) => stringify(params, { delimiter: '&' });

export const removeBase = (url) =>
  url.replace(process.env.NEXT_PUBLIC_CONTENT_ASSETS_URL, '');

export const internalImageOptimizer = ({
  src,
  width,
  height,
  quality,
}: Props) => {
  if (!src) return;
  if (!width && !height && !height) return src;
  const cdnPrefix = `${process.env.NEXT_PUBLIC_CONTENT_ASSETS_URL}/resize/`;
  const cdnParams = `?${parseCdnParams({
    height,
    quality,
    width,
  })}`;
  const imgOptimizerLink = `${cdnPrefix}${removeBase(src)}${cdnParams}`;
  return imgOptimizerLink;
};
