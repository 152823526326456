import Almeria from './almeria.png';
import AthleticBilbao from './athletic-bilbao.png';
import AthleticoMadrid from './athletico-madrid.png';
import Barcelona from './barcelona.png';
import Cadiz from './cadiz.png';
import Celchef from './celchef.png';
import Celta from './celta.png';
import Espanyol from './espanyol.png';
import Getafe from './getafe.png';
import Girona from './girona.png';
import Osasuna from './osasuna.png';
import RayoVellaceno from './rayo-vallecano.png';
import RcdMallorca from './rcd-mallorca.png';
import RealBetis from './real-betis.png';
import RealMadrid from './real-madrid.png';
import RealSociedad from './real-sociedad.png';
import RealValladolid from './real-valladolid.png';
import Sevilla from './sevilla.png';
import Title from './title.png';
import Valencia from './valencia.png';
import Villarreal from './villarreal.png';

export const LALIGA_TEAMS_ICONS = [
  Barcelona,
  AthleticBilbao,
  Espanyol,
  RealMadrid,
  AthleticoMadrid,
  Sevilla,
  RealBetis,
  RealSociedad,
  Villarreal,
  Celchef,
  Celta,
  RayoVellaceno,
  RealValladolid,
  Girona,
  Getafe,
  Almeria,
  Cadiz,
  RcdMallorca,
  Valencia,
  Osasuna,
];

export const LALIGA_EMBLEM_ICON = Title;
