import { useEffect, useState } from 'react';
import { useAsyncFn } from 'react-use';
import { useSession, prepareFlowAddress } from 'src/edge/session';
import { useFeatureFlags } from 'src/lib/launchDarkly';
import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  Heading,
} from 'src/theme';

/**
 * PrepareFlowAddressModal
 *
 * Displays a non-closable modal that we are "preparing" their account.
 * This occurs when we have a profile for a user but we're still waiting to
 * get their flow address.
 */
export const PrepareFlowAddressModal = () => {
  const { t } = useTranslation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { send: sessionSend, state: sessionState } = useSession();
  const { isFlowMaintenanceEnabled } = useFeatureFlags();
  const [isRetrying, setIsRetrying] = useState(false);

  const [preparationState, prepare] = useAsyncFn(
    prepareFlowAddress(
      sessionSend,
      sessionState.context.profile,
      setIsRetrying,
    ),
    [sessionState],
  );
  const preparing = preparationState.loading;

  const hasFlowAddress: boolean =
    !!sessionState.context?.profile?.flowAddress || !!preparationState?.value;

  const needsFlowAddress: boolean =
    sessionState.matches('AUTHENTICATED') &&
    !hasFlowAddress &&
    !isFlowMaintenanceEnabled;

  useEffect(() => {
    if (isRetrying && !isModalOpen) {
      setIsModalOpen(true);
    }
    if (needsFlowAddress && !preparing) {
      prepare().then((flowAddress) => {
        if (!flowAddress) return;
        setIsRetrying(false);
        // if we do have a flow address, we want the modal to close on its own after a few seconds.
        setTimeout(() => setIsModalOpen(false), 3000);
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [needsFlowAddress]);

  const key = 'UserModal' + (preparing ? '.Preparing' : '.Prepared');
  const titleKey = key + '.Title';
  const messageKey = key + '.Message';

  return (
    <Modal isCentered isOpen={isModalOpen} onClose={null}>
      <ModalOverlay />
      <ModalContent>
        <ModalBody pt='16px'>
          <Flex alignItems='center' flexDirection='column' textAlign='center'>
            <Box maxWidth='350px' mb='24px'>
              <Heading size='sm' variant='tame'>
                {t(titleKey)}
              </Heading>
            </Box>
            {preparing && <Spinner mb='4' />}
            <Text>{t(messageKey)}</Text>
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
