import {
  allDayMarketplace,
  TRANSACTIONS_STAGES,
  LIST_FOR_SALE_MODAL_STAGES,
  TRANSACTIONS_TYPE_MODAL,
  TransactionsModalState,
} from '@dapperlabs/core-fe';
import { buy, delist, listForSale } from '@dapperlabs/core-fe/src/edge/flow';
import { useAnalytics } from '@dapperlabs/react-analytics';
import {
  useListForSale,
  useBuyNft,
  useDelist,
} from '@dapperlabs/skeleton-core';
import { sansPrefix } from '@onflow/fcl';
import { useRouter } from 'next/router';
import { useEffect, useMemo, useState } from 'react';
import { useSession } from 'src/edge/session';
import {
  eurFormatter,
  usdFormatterWholeNumber,
} from 'src/general/utils/currencyFormatter';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment';
import { useTranslation } from 'src/lib/translations';
import {
  SEARCH_NFT_WITH_LISTING,
  SEARCH_NFT_WITH_LISTING_VARIABLES,
} from 'src/modules/marketplace';

enum ANALYTICS_TX_TYPES {
  DELIST = 'delisting',
  LISTING = 'listing',
  PURCHASE = 'p2p_purchase',
}

const BUY_CONFIG = {
  searchNftQuery: {
    pollInterval: 2000,
    query: SEARCH_NFT_WITH_LISTING,
    queryName: 'searchGolazosNft',
    typeName: `A.${sansPrefix(
      process.env.NEXT_PUBLIC_NFT_ADDRESS,
    )}.Golazos.NFT`,
    variables: ({ nftID, typeName }) =>
      SEARCH_NFT_WITH_LISTING_VARIABLES({ nftID, typeName }),
  },
  transaction: buy,
};

const LIST_FOR_SALE_CONFIG = {
  searchNftQuery: {
    pollInterval: 2000,
    query: SEARCH_NFT_WITH_LISTING,
    queryName: 'searchGolazosNft',
    typeName: `A.${sansPrefix(
      process.env.NEXT_PUBLIC_NFT_ADDRESS,
    )}.Golazos.NFT`,
    variables: ({ nftID, typeName }) =>
      SEARCH_NFT_WITH_LISTING_VARIABLES({ nftID, typeName }),
  },
  transaction: listForSale,
};

const DELIST_CONFIG = {
  searchNftQuery: {
    pollInterval: 2000,
    query: SEARCH_NFT_WITH_LISTING,
    queryName: 'searchGolazosNft',
    typeName: `A.${sansPrefix(
      process.env.NEXT_PUBLIC_NFT_ADDRESS,
    )}.Golazos.NFT`,
    variables: ({ nftID, typeName }) =>
      SEARCH_NFT_WITH_LISTING_VARIABLES({ nftID, typeName }),
  },
  transaction: delist,
};

export const useTransactionsModal = ({
  isOwnerNFT,
  momentNFT,
  flowAddress,
  isLoggedIn,
  imageUrl,
  nftID,
  price,
}) => {
  const initialState = {
    error: null,
    price: '',
  };
  const { t } = useTranslation();
  const { state: sessionState, send } = useSession();
  const router = useRouter();
  const { track } = useAnalytics();

  const [analyticsCounter, setAnalyticsCounter] = useState(0);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [state, setState] = useState<TransactionsModalState>(initialState);
  const [stage, setStage] = useState(LIST_FOR_SALE_MODAL_STAGES.INIT);
  const [typeModal, setTypeModal] = useState(TRANSACTIONS_TYPE_MODAL.PURCHASE);
  const { onAuthorize: onAuthorizeBuyNft, state: stateBuyNft } = useBuyNft({
    config: BUY_CONFIG,
  });
  const { onAuthorize: onAuthorizeDelist, state: stateDelist } = useDelist({
    config: DELIST_CONFIG,
  });
  const {
    onAuthorize: onAuthorizeListForSale,
    state: stateListForSale,
    onReset: onResetListForSale,
  } = useListForSale({
    config: LIST_FOR_SALE_CONFIG,
  });
  const [transactionStage, setTransactionStage] = useState<any>('');
  const [txId, setTxId] = useState<any>('');

  const getInfoByTypeTransaction = (state) => {
    if (typeof state.value === 'object' && 'TRANSACTION' in state.value) {
      setTransactionStage(state.value['TRANSACTION']);
    }
    if (state.value === 'ERROR') {
      return setTransactionStage(
        TRANSACTIONS_STAGES.TOO_LONG_TRANSACTION_ERROR,
      );
    }
    if (typeof state.value === 'string') {
      setTransactionStage(state.value);
    }
    setTxId(state?.context?.transactionID ?? '');
  };

  useEffect(() => {
    if (isOpen) {
      setAnalyticsCounter(0);
    }
  }, [isOpen]);

  useEffect(() => {
    if (
      (transactionStage === TRANSACTIONS_STAGES.SUCCESS ||
        transactionStage === TRANSACTIONS_STAGES.ERROR ||
        transactionStage === TRANSACTIONS_STAGES.TOO_LONG_TRANSACTION_ERROR) &&
      analyticsCounter === 0
    ) {
      const props = {
        tx_id: txId,
        tx_type: ANALYTICS_TX_TYPES[typeModal],
        tx_value: state.price,
      };
      if (transactionStage === TRANSACTIONS_STAGES.ERROR) {
        props['error'] = txId
          ? 'Transaction error'
          : 'Authorization error or transaction was canceled';
      }
      track(SEGMENT_EVENTS_ID_ENUM.WALLET_TRANSACTION_RESPONSE, props);
      setAnalyticsCounter(1);
    }
  }, [transactionStage]);

  useEffect(() => {
    if (typeModal === TRANSACTIONS_TYPE_MODAL.LISTING) {
      getInfoByTypeTransaction(stateListForSale);
    }
    if (typeModal === TRANSACTIONS_TYPE_MODAL.PURCHASE) {
      getInfoByTypeTransaction(stateBuyNft);
    }
    if (typeModal === TRANSACTIONS_TYPE_MODAL.DELIST) {
      getInfoByTypeTransaction(stateDelist);
    }
  }, [stateBuyNft, stateDelist, stateListForSale, typeModal]);

  const additionalButtonHandler = () => {
    setAnalyticsCounter(0);
    if (stage === LIST_FOR_SALE_MODAL_STAGES.SUCCESS) {
      router.asPath.includes(`/moments/${momentNFT.id}`)
        ? router.reload()
        : router.replace(`/moments/${momentNFT.id}`);
    }
    if (stage === LIST_FOR_SALE_MODAL_STAGES.ERROR) {
      setStage(LIST_FOR_SALE_MODAL_STAGES.PROCESSING);
      setTransactionStage(TRANSACTIONS_STAGES.AUTHORIZING);
      if (typeModal === TRANSACTIONS_TYPE_MODAL.LISTING) {
        onAuthorizeListForSale({ nftID, price: state.price });
      }
      if (typeModal === TRANSACTIONS_TYPE_MODAL.DELIST) {
        onAuthorizeDelist({
          currentUserFlowAddress: flowAddress,
          listingResourceID: momentNFT.listing.listing_resource_id,
          nftID: momentNFT.id,
        });
      }
      if (typeModal === TRANSACTIONS_TYPE_MODAL.PURCHASE) {
        const priceItem = (momentNFT?.listing.price ?? 0) / 100000000;
        const buyerAddress = flowAddress.includes('0x')
          ? flowAddress
          : '0x' + flowAddress;
        const sellerAddress = momentNFT.owner_address.includes('0x')
          ? momentNFT.owner_address
          : '0x' + momentNFT.owner_address;
        onAuthorizeBuyNft({
          buyerAddress,
          imageURL: imageUrl,
          listingResourceID: momentNFT.listing.listing_resource_id,
          nftID: momentNFT.id,
          price: String(priceItem),
          sellerAddress,
          serialNumber: momentNFT.serial_number,
        });
      }
    }
  };

  const submitModal = () => {
    if (
      stage === LIST_FOR_SALE_MODAL_STAGES.SUCCESS &&
      (typeModal === TRANSACTIONS_TYPE_MODAL.LISTING ||
        typeModal === TRANSACTIONS_TYPE_MODAL.DELIST)
    ) {
      // return router.replace(`/moments/${momentNFT.id}`);
      return router.replace('/myCollection');
    }
    if (
      stage === LIST_FOR_SALE_MODAL_STAGES.ERROR ||
      stage === LIST_FOR_SALE_MODAL_STAGES.SUCCESS
    ) {
      router.replace(allDayMarketplace());
    }
    if (stage === LIST_FOR_SALE_MODAL_STAGES.INIT) {
      setStage(LIST_FOR_SALE_MODAL_STAGES.PROCESSING);
      onAuthorizeListForSale({ nftID, price: state.price });
    }
  };

  const prices = {
    avgSale: {
      helper: null,
      label: t('ListForSale.modal.avgSale'),
      price: price.avg,
    },
    lowestAsk: {
      helper: null,
      label: t('ListForSale.modal.lowestAsk'),
      price: price.min,
    },
    topSale: {
      helper: null,
      label: t('ListForSale.modal.topSale'),
      price: price.max,
    },
  };

  const heroSectionButton = useMemo(() => {
    if (isOwnerNFT) {
      if (momentNFT.listing) {
        const priceItem = (momentNFT?.listing.price ?? 0) / 100000000;
        return {
          callback: () => {
            setTypeModal(TRANSACTIONS_TYPE_MODAL.DELIST);
            setState({ ...state, price: String(priceItem) });
            setStage(LIST_FOR_SALE_MODAL_STAGES.PROCESSING);
            setIsOpen(true);
            onAuthorizeDelist({
              currentUserFlowAddress: flowAddress,
              listingResourceID: momentNFT.listing.listing_resource_id,
              nftID: momentNFT.id,
            });
          },
          label: t('HeroSection.button.removeListing'),
        };
      }
      return {
        callback: () => {
          onResetListForSale();
          setTypeModal(TRANSACTIONS_TYPE_MODAL.LISTING);
          setStage(LIST_FOR_SALE_MODAL_STAGES.INIT);
          setIsOpen(true);
        },
        label: t('ListForSale.button.listForSale'),
      };
    } else {
      if (momentNFT.listing) {
        const priceItem = (momentNFT?.listing.price ?? 0) / 100000000;
        const askingPrice =
          sessionState.context.preferredCurrency === 'DOLLARS'
            ? usdFormatterWholeNumber.format(priceItem).toString()
            : eurFormatter.format(priceItem).toString();
        if (isLoggedIn) {
          return {
            callback: () => {
              setTypeModal(TRANSACTIONS_TYPE_MODAL.PURCHASE);
              setState({ ...state, price: String(priceItem) });
              setStage(LIST_FOR_SALE_MODAL_STAGES.PROCESSING);
              setIsOpen(true);
              const buyerAddress = flowAddress.includes('0x')
                ? flowAddress
                : '0x' + flowAddress;
              const sellerAddress = momentNFT.owner_address.includes('0x')
                ? momentNFT.owner_address
                : '0x' + momentNFT.owner_address;
              onAuthorizeBuyNft({
                buyerAddress,
                imageURL: imageUrl,
                listingResourceID: momentNFT.listing.listing_resource_id,
                nftID: momentNFT.id,
                price: String(priceItem),
                sellerAddress,
                serialNumber: momentNFT.serial_number,
              });
            },
            label: `${t('HeroSection.button.buyFor')} ${askingPrice}`,
          };
        }
        return {
          callback: () => send('SIGN_IN'),
          label: t('HeroSection.button.signInToBuy'),
        };
      }
      return false;
    }
  }, [flowAddress, isLoggedIn, isOwnerNFT, state]);
  return {
    additionalButtonHandler,
    heroSectionButton,
    isOpen,
    prices,
    setIsOpen,
    setStage,
    setState,
    setTransactionStage,
    stage,
    state,
    submitModal,
    transactionStage,
    txId,
    typeModal,
  };
};
