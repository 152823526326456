import {
  forwardRef,
  useStyleConfig,
  TextProps as TextDeprecatedProps,
} from '@chakra-ui/react';
import { chakra } from '@chakra-ui/system';
import React from 'react';

export const TextDeprecated = forwardRef(
  ({ size, variant, as = 'p', color, ...rest }: TextDeprecatedProps, ref) => {
    const styles = useStyleConfig('TextDeprecated', { size, variant });
    const element = as as string;
    const Text = chakra[element];

    return <Text {...rest} ref={ref} sx={styles} color={color} />;
  },
);
