/* eslint-disable sort-keys-fix/sort-keys-fix, quotes, prettier/prettier */
// Autogenerated from graphql-golazos-team-names.js
type Teams = {
  [teamId: string]: [any];
};
export const TEAM_DATA: Teams = {
  'Athletic Club': [{ name: 'Athletic Club' }],
  'Atlético de Madrid': [{ name: 'Atlético de Madrid' }],
  'CA Osasuna': [{ name: 'CA Osasuna' }],
  'CD Leganés': [{ name: 'CD Leganés' }],
  'Cádiz CF': [{ name: 'Cádiz CF' }],
  'Deportivo de la Coruña': [{ name: 'Deportivo de la Coruña' }],
  'Elche CF': [{ name: 'Elche CF' }],
  'FC Barcelona': [{ name: 'FC Barcelona' }],
  'Getafe CF': [{ name: 'Getafe CF' }],
  'Girona FC': [{ name: 'Girona FC' }],
  'Granada CF': [{ name: 'Granada CF' }],
  'Hércules CF': [{ name: 'Hércules CF' }],
  'Levante UD': [{ name: 'Levante UD' }],
  'Málaga CF': [{ name: 'Málaga CF' }],
  'RC Celta': [{ name: 'RC Celta' }],
  'RCD Espanyol de Barcelona': [{ name: 'RCD Espanyol de Barcelona' }],
  'RCD Mallorca': [{ name: 'RCD Mallorca' }],
  'Rayo Vallecano': [{ name: 'Rayo Vallecano' }],
  'Real Betis': [{ name: 'Real Betis' }],
  'Real Madrid': [{ name: 'Real Madrid' }],
  'Real Sociedad': [{ name: 'Real Sociedad' }],
  'Real Valladolid CF': [{ name: 'Real Valladolid CF' }],
  'Real Zaragoza': [{ name: 'Real Zaragoza' }],
  'SD Eibar': [{ name: 'SD Eibar' }],
  'Sevilla FC': [{ name: 'Sevilla FC' }],
  'UD Almería': [{ name: 'UD Almería' }],
  'Valencia CF': [{ name: 'Valencia CF' }],
  'Villarreal CF': [{ name: 'Villarreal CF' }],
};
