import React from 'react';
import { Flex, Text, useStyles, ChakraNextImage } from 'src/theme';

import { DefaultAvatar } from '../DefaultAvatar';
import { useTopNavContext } from './TopNav.context';
import { TopNavDropdown } from './TopNavDropdown';
import { TopNavList } from './TopNavList';
import { TopNavListItem } from './TopNavListItem';

interface TopNavProfileProps {
  isMobile: boolean;
}

const Avatar = ({
  isMobileMenu = false,
  profileImageUrl,
}: {
  isMobileMenu?: boolean;
  profileImageUrl?: string;
}) => {
  if (!profileImageUrl) return <DefaultAvatar w='24px' h='24px' />;
  return (
    <ChakraNextImage
      sx={{
        boxSize: {
          base: isMobileMenu ? '50px!important' : '30px!important',
          md: '24px!important',
        },
      }}
      w='auto'
      h='auto'
      margin={{ base: 'auto 0', md: '0' }}
      alt='avatar'
      src={profileImageUrl}
    />
  );
};

export const TopNavProfile = ({ isMobile }: TopNavProfileProps) => {
  const styles = useStyles();
  const { profile, isMdUp, profileNav } = useTopNavContext();
  const { email, profileImageUrl, nickname } = profile;

  return isMobile ? (
    <>
      <Flex direction='row'>
        <Avatar isMobileMenu profileImageUrl={profileImageUrl} />
        <Flex
          justify='space-around'
          direction='column'
          width={{ base: '70%', md: 'auto' }}
          ml='17px'
        >
          <Text sx={styles.username}>{email}</Text>
          <Text sx={styles.shortcode}>@{nickname}</Text>
        </Flex>
      </Flex>
      <TopNavList gridArea='mid' mt={4}>
        {profileNav.map(({ href, onClick, label }) => {
          return (
            <a
              key={label}
              {...(href ? { href, target: '_blank' } : {})}
              {...(onClick ? { onClick } : {})}
            >
              <TopNavListItem sx={{ mt: 3 }}>
                <Text sx={styles.profileNavItem}>{label}</Text>
              </TopNavListItem>
            </a>
          );
        })}
      </TopNavList>
    </>
  ) : (
    <TopNavDropdown
      disabled={!isMdUp}
      items={profileNav}
      menuButtonComponent={<Avatar profileImageUrl={profileImageUrl} />}
    />
  );
};
