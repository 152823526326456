import type { ComponentStyleConfig } from 'src/theme';

export const TransactionsModalInputTheme: ComponentStyleConfig = {
  baseStyle: {
    input: {
      backgroundColor: '#17171B',
      border: '1px solid #393B45',
      borderRadius: '8px',
    },
    label: {
      color: '#8A94A6',
      fontFamily: 'mono',
      fontSize: '14px',
      fontWeight: '400',
      letterSpacing: '0.16px',
      lineHeight: '19px',
      mb: '4px',
    },
  },
  parts: [],
};
