import { ApolloClient } from '@apollo/client';
import { PackNFTFragment } from '__generated__/PackNFTFragment';
import { State } from 'xstate';

import { getInteraction } from '../utils';

export enum FS {
  BUYER_ADDRESS = 'BUYER_ADDRESS',
  ERROR = 'ERROR',
  IDLE = 'IDLE',
  INITIAL = 'INITIAL',
  POLLING = 'POLLING',
  RESERVING = 'RESERVING',
  SUCCESS = 'SUCCESS',
  TRANSACTION = 'TRANSACTION',
}
export type FStype = typeof FS;

export enum EVENTS {
  AUTHORIZE = 'AUTHORIZE',
  POLL_ERROR = 'POLL_ERROR',
  POLL_SUCCESS = 'POLL_SUCCESS',
}
export type EVENTStype = typeof EVENTS;

export type Events = { data?: any; type: EVENTS };

export interface Distribution {
  description: string;
  id: number;
  images: {
    type: 'DEFAULT';
    url: string;
  }[];
  price?: any;
  tier: string;
  title: string;
}

export interface Context {
  EVENTS: typeof EVENTS;
  FS: typeof FS;
  buyerAddress: string;
  client: ApolloClient<any>;
  distribution: Distribution;
  error: any;
  interaction: ReturnType<typeof getInteraction>;
  isCustomerSupportPack: boolean;
  nftResourceIdentifer?: number;
  packNFT: PackNFTFragment;
  packNFTID?: number;
  price: string;
  purchaseTx: string;
  sellerAddress: string;
  transactionID: string;
}
export interface Schema {
  states: {
    ERROR: {};
    IDLE: {};
    POLLING: {};
    RESERVING: {};
    SUCCESS: {};
    TRANSACTION: {
      states: {
        AUTHORIZING: {};
        ERROR: {};
        EXECUTING: {};
        FINALIZING: {};
        SEALING: {};
      };
    };
  };
}

export interface TypeState {
  context: Partial<Context>;
  value: FS;
}

export type PurchaseMachineState = State<
  Partial<Context>,
  Events,
  Schema,
  TypeState
>;
