import { useApolloClient } from '@apollo/client';
import { useRouter } from 'next/router';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSession } from 'src/edge/session';
import { TranslateKeys, useTranslation } from 'src/lib/translations';
import {
  searchPackNftByOwnerAddress,
  SEARCH_PACK_NFT_BY_OWNER_ADDRESS,
} from 'src/modules/Queries/searchNft';
import {
  Box,
  Divider,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  Text,
  Heading,
  Button,
} from 'src/theme';

import { useWaitForGuestTransfer } from './useWaitForGuestTransfer';

/**
 * PreparingAccount
 *
 * Displays a non-closable modal that we are "preparing" their account.
 * This occurs when we have a profile for a user but we're still waiting to
 * get their flow address.
 *
 * The logic to acquire the flow address occurs inside useSession.
 */
export const WaitingForTransferModal = ({
  redirectEnabled = true,
}: {
  redirectEnabled?: boolean;
}) => {
  const timerRef = useRef<ReturnType<typeof setTimeout>>(null);
  const { t } = useTranslation();
  const { state: sessionState } = useSession();
  const router = useRouter();
  const guestAddress = router.query?.guest as string;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const client = useApolloClient();
  const { isActive, isDone, isGuestEmpty, error, start } =
    useWaitForGuestTransfer(guestAddress);

  const finished = useCallback(async () => {
    // if the guest account is NOT empty, the modal probably timed out and we gave up
    // meaning we can't show them the pack to open, so just take to their profile for now.
    if (redirectEnabled) {
      let targetUrl = '/myCollection';

      if (isGuestEmpty) {
        // first check if they have a pack to open
        const { data, errors } = await client.query({
          context: { clientName: 'platformAPI' },
          query: SEARCH_PACK_NFT_BY_OWNER_ADDRESS(),
          variables: searchPackNftByOwnerAddress(
            sessionState.context.profile?.flowAddress,
          ),
        });
        if (errors) throw errors;

        /**
         * only requested 2, because if its > 1 we want just redirect to packs page.
         * if its 1 we can assume we want to prompt them to open for now
         * this can be enhanced more later.
         *
         * Main reason to do this right now is that if it's > 1 it is probably an
         * existing user that purchased as a guest and then upgraded, meaning we can't assume
         * we'd be redirecting to the correct pack. If it === 1, it's likely a true guest.
         */
        if (data?.searchPackNft?.totalCount === 1) {
          targetUrl =
            '/profile/packs/' + data?.searchPackNft?.edges?.[0]?.node?.id;
        }
      }
      router.push(targetUrl);
    }
    setIsModalOpen(false);
  }, [
    client,
    isGuestEmpty,
    redirectEnabled,
    router,
    sessionState.context.profile?.flowAddress,
  ]);

  // auto-closer effect
  // runs after we determined the user is ready to redirect
  useEffect(() => {
    if (
      !isActive &&
      isGuestEmpty &&
      sessionState.matches('AUTHENTICATED') &&
      !timerRef.current
    ) {
      timerRef.current = setTimeout(finished, isModalOpen ? 1500 : 0);
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [finished, sessionState, isActive, isGuestEmpty, isModalOpen]);

  // modal opener, opens the modal when the state becomes TRANSFERRING_GUEST_ASSETS
  // allows us to mount this component early and start the polling if able
  useEffect(() => {
    if (!sessionState.matches('AUTHENTICATED')) return;
    // only want to open the modal if we're still waiting, otherwise it'll just flash and redirect at same time
    if (isActive) setIsModalOpen(true);
  }, [sessionState, isActive]);

  // kick-off effect
  // this will start the polling in advance of being in the TRANSFERRING_GUEST_ASSETS
  // state. Reduces likelihood of this modal needing to even show.
  useEffect(() => {
    if (!guestAddress || isActive) return;
    start(10000);
  }, [guestAddress, isActive, start]);

  let messageKey: TranslateKeys = null;
  if (error) {
    messageKey = 'Guest.waitingForTransfer.error';
  } else if (isDone && !isGuestEmpty) {
    messageKey = 'Guest.waitingForTransfer.tookTooLong';
  } else if (isDone && isGuestEmpty) {
    messageKey = 'Guest.waitingForTransfer.ready';
  } else if (isActive) {
    messageKey = 'Guest.waitingForTransfer.waiting';
  } else {
    messageKey = 'Guest.waitingForTransfer.error';
  }

  return (
    <Modal
      isCentered
      isOpen={isModalOpen}
      onClose={() => {}}
      returnFocusOnClose={false}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalBody>
          <Flex alignItems='center' flexDirection='column' textAlign='center'>
            <Box maxWidth='350px'>
              <Heading size='sm' variant='tame'>
                {t('Guest.waitingForTransfer.title')}
              </Heading>
            </Box>
            <Divider my={5} />
            {isActive && <Spinner mb='4' />}
            <Text color='grey.0'>{t(messageKey as TranslateKeys)}</Text>
            {!isGuestEmpty && !isActive && (
              <Button onClick={finished}>Continue to your profile</Button>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
