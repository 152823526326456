import { Document } from '@contentful/rich-text-types';
import { Accordion } from 'src/theme';

import { LegalListItem } from './';

export interface LegalListProps {
  legalList: Legal[];
}

interface Sys {
  id: string;
}

interface Field {
  description: Document;
  title: string; // Document for rich text
}

export interface Legal {
  fields: Field;
  sys: Sys;
}

export const LegalList = ({ legalList }: LegalListProps): JSX.Element => {
  return (
    <Accordion allowMultiple defaultIndex={[0]}>
      {legalList?.map((legalItem) => {
        return (
          <LegalListItem
            key={legalItem.sys.id}
            description={legalItem.fields.description}
            title={legalItem.fields.title}
          />
        );
      })}
    </Accordion>
  );
};
