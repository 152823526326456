import type { ComponentStyleConfig } from '@chakra-ui/react';

export const CommonTheme: ComponentStyleConfig = {
  baseStyle: {
    assets: {
      margin: '0 1rem',
      maxHeight: '430px',
      objectFit: 'cover',
      width: 'calc(100% - 2rem)',
    },
    button: {
      fontFamily: 'mono',
      height: '48px',
      width: '100%',
    },
    carousel: {
      flexGrow: 1,
      maxWidth: '690px',
      minWidth: '300px',
    },
    container: {
      flexWrap: {
        base: 'wrap',
        md: 'nowrap',
      },
      gap: '3.375rem',
      justifyContent: 'center',
    },
    content: {
      margin: '1.5rem 0',
    },
    currencySuffix: {
      color: '#8A94A6',
      fontFamily: 'mono',
      fontSize: '14px',
      letterSpacing: '0.16px',
      marginLeft: '1',
    },
    details: {
      border: {
        md: '1px solid #393B45',
      },
      borderRadius: '12px',
      maxWidth: {
        base: '100%',
        md: '480px',
      },
      padding: {
        md: '1.5rem',
      },
    },
    header: {
      marginBottom: '4',
    },
    highlightedPrice: {
      color: 'white',
      fontFamily: 'heading',
      fontSize: '3xl',
    },
    label: {
      fontFamily: 'mono',
    },
    momentsBurnedCtn: {
      align: 'center',
      bg: '#17171B',
      borderBottomLeftRadius: '12px',
      borderBottomRightRadius: '12px',
      color: 'gray.300',
      px: 3,
      py: 2,
    },
    momentsBurnedLabel: {
      color: 'inherit',
      fontFamily: 'body',
      fontSize: '12px',
      letterSpacing: '0.45px',
      lineHeight: 1,
    },
    owner: {
      backgroundColor: '#5F5F5F4D',
      borderRadius: '44px',
      fontSize: 'sm',
      marginY: '1.5rem',
      padding: '6px 12px',
      width: 'fit-content',
    },
    ownerPrefix: {
      background: 'transparent',
      color: 'white',
      fontWeight: 'bold',
    },
    ownerValue: {
      background: 'transparent',
      color: '#D6DAEB',
      fontFamily: 'roboto',
      paddingLeft: '1',
    },
    price: {
      color: 'gray.300',
    },
    priceContainer: {
      alignItems: 'center',
      flexDirection: 'row',
      gap: '1rem',
      marginBottom: '4',
    },
    priceLine: {
      backgroundColor: '#2B2C33',
      flexGrow: 1,
      height: '1px',
    },
    warningContainer: {
      backgroundColor: '#4D4900',
      borderRadius: '8px',
      flexDirection: 'row',
      mt: 2,
      p: 2,
    },
    warningText: {
      color: '#F1E94E',
      fontFamily: 'main',
      fontSize: '12px',
      letterSpacing: '0.45px',
      lineHeight: '16px',
      ml: 2,
    },
  },
  defaultProps: {},
  parts: [
    'container',
    'content',
    'currencySuffix',
    'details',
    'header',
    'priceContainer',
    'priceLine',
    'carousel',
    'label',
    'price',
    'highlightedPrice',
    'slideContainer',
    'assets',
    'owner',
    'warningContainer',
  ],
};
