import { ChakraTheme, Icon as ChakraIcon, IconProps } from '@chakra-ui/react';
import { InterpolationWithTheme } from '@emotion/core';

import AD from './assets/flags/AD.svg';
import AL from './assets/flags/AL.svg';
import AR from './assets/flags/AR.svg';
import AT from './assets/flags/AT.svg';
import AU from './assets/flags/AU.svg';
import BA from './assets/flags/BA.svg';
import BE from './assets/flags/BE.svg';
import BR from './assets/flags/BR.svg';
import CA from './assets/flags/CA.svg';
import CD from './assets/flags/CD.svg';
import CF from './assets/flags/CF.svg';
import CH from './assets/flags/CH.svg';
import CI from './assets/flags/CI.svg';
import CL from './assets/flags/CL.svg';
import CM from './assets/flags/CM.svg';
import CN from './assets/flags/CN.svg';
import CO from './assets/flags/CO.svg';
import CR from './assets/flags/CR.svg';
import CV from './assets/flags/CV.svg';
import DE from './assets/flags/DE.svg';
import DK from './assets/flags/DK.svg';
import DO from './assets/flags/DO.svg';
import DZ from './assets/flags/DZ.svg';
import EA from './assets/flags/EA.svg';
import EC from './assets/flags/EC.svg';
import ES from './assets/flags/ES.svg';
import FR from './assets/flags/FR.svg';
import GA from './assets/flags/GA.svg';
import GB_CYM from './assets/flags/GB-CYM.svg';
import GB from './assets/flags/GB.svg';
import GE from './assets/flags/GE.svg';
import GH from './assets/flags/GH.svg';
import GN from './assets/flags/GN.svg';
import GP from './assets/flags/GP.svg';
import HN from './assets/flags/HN.svg';
import HR from './assets/flags/HR.svg';
import IE from './assets/flags/IE.svg';
import IL from './assets/flags/IL.svg';
import IT from './assets/flags/IT.svg';
import JP from './assets/flags/JP.svg';
import KR from './assets/flags/KR.svg';
import MA from './assets/flags/MA.svg';
import ME from './assets/flags/ME.svg';
import MK from './assets/flags/MK.svg';
import ML from './assets/flags/ML.svg';
import MQ from './assets/flags/MQ.svg';
import MX from './assets/flags/MX.svg';
import MZ from './assets/flags/MZ.svg';
import NG from './assets/flags/NG.svg';
import NL from './assets/flags/NL.svg';
import NO from './assets/flags/NO.svg';
import PE from './assets/flags/PE.svg';
import PL from './assets/flags/PL.svg';
import PT from './assets/flags/PT.svg';
import PY from './assets/flags/PY.svg';
import RS from './assets/flags/RS.svg';
import SE from './assets/flags/SE.svg';
import SI from './assets/flags/SI.svg';
import SK from './assets/flags/SK.svg';
import SN from './assets/flags/SN.svg';
import TG from './assets/flags/TG.svg';
import TR from './assets/flags/TR.svg';
import UA from './assets/flags/UA.svg';
import US from './assets/flags/US.svg';
import UY from './assets/flags/UY.svg';
import VE from './assets/flags/VE.svg';
import XK from './assets/flags/XK.svg';
import ZW from './assets/flags/ZW.svg';

export type ChakraIconProps = IconProps & {
  css?: InterpolationWithTheme<ChakraTheme>;
};

export const SpainFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ES} />;
};

export const ArgentinaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={AR} />;
};

export const SwedenFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={SE} />;
};

export const BrazilFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BR} />;
};

export const GermanyFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DE} />;
};

export const CameroonFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CM} />;
};

export const CoteDIvoireFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CI} />;
};

export const CeutaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={EA} />;
};

export const ChinaPRFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CN} />;
};

export const NigeriaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={NG} />;
};

export const CroatiaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={HR} />;
};

export const PortugalFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PT} />;
};

export const FranceFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={FR} />;
};

export const BelgiumFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BE} />;
};

export const MoroccoFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={MA} />;
};

export const DenmarkFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DK} />;
};

export const MexicoFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={MX} />;
};

export const UruguayFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={UY} />;
};

export const NetherlandsFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={NL} />;
};

export const JapanFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={JP} />;
};

export const KoreaRepublicFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={KR} />;
};

export const SerbiaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={RS} />;
};

export const USAFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={US} />;
};

export const SlovakiaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={SK} />;
};

export const MaliFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ML} />;
};

export const EnglandFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GB} />;
};

export const SloveniaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={SI} />;
};

export const MontenegroFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ME} />;
};

export const CentralAfricanRepublicFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CF} />;
};

export const MozambiqueFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={MZ} />;
};

export const ColombiaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CO} />;
};

export const PolandFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PL} />;
};

export const BosniaHerzegovinaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={BA} />;
};

export const GabonFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GA} />;
};

export const SenegalFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={SN} />;
};

export const ChileFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CL} />;
};

export const ItalyFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={IT} />;
};

export const HondurasFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={HN} />;
};

export const AustraliaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={AU} />;
};

export const CongoDRFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CD} />;
};

export const ParaguayFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PY} />;
};

export const GhanaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GH} />;
};

export const PeruFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={PE} />;
};

export const NorwayFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={NO} />;
};

export const CanadaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CA} />;
};

export const AlbaniaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={AL} />;
};

export const TurkeyFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TR} />;
};

export const TogoFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={TG} />;
};

export const IrelandFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={IE} />;
};

export const VenezuelaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={VE} />;
};

export const ZimbabweFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={ZW} />;
};

export const KosovoFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={XK} />;
};

export const EcuadorFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={EC} />;
};

export const GuineaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GN} />;
};

export const NorthMacedoniaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={MK} />;
};

export const CaboVerdeFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CV} />;
};

export const AustriaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={AT} />;
};

export const DominicanRepublicFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DO} />;
};

export const UkraineFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={UA} />;
};

export const GeorgiaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GE} />;
};

export const GuadeloupeFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GP} />;
};

export const SwitzerlandFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CH} />;
};

export const AndorraFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={AD} />;
};

export const AlgeriaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={DZ} />;
};

export const IsraelFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={IL} />;
};

export const MartiniqueFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={MQ} />;
};

export const CostaRicaFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={CR} />;
};

export const WalesFlagIcon = (props: ChakraIconProps) => {
  return <ChakraIcon {...props} as={GB_CYM} />;
};
