import { chakra, Flex } from '@chakra-ui/react';
import { useMultiStyleConfig } from 'src/theme';
import { GAME_HIGHLIGHTS_THEME_KEYS } from 'src/theme/dss-skeleton/components';
export type StatsLineProps = {
  align?: 'right' | null;
  mod: number;
  value: number;
  variant: string;
};
export const StatsLine = ({
  value,
  mod,
  variant = 'side',
  align,
}: StatsLineProps): JSX.Element => {
  const styles = useMultiStyleConfig(GAME_HIGHLIGHTS_THEME_KEYS.STATS_LINE, {
    align,
    variant,
  });
  const highlightLineWidth = mod ? (value * 100) / mod : 0;
  return (
    <Flex sx={styles.container}>
      <chakra.div className='common' sx={styles.line} />
      <chakra.div
        sx={styles.line}
        style={{
          width: `${highlightLineWidth}%`,
        }}
      />
    </Flex>
  );
};
