import { gql } from '@apollo/client';
export const SeriesMetadataFragment = gql`
  fragment SeriesMetadataFragment on OffChainSeriesMetadata {
    description
  }
`;
export const SeriesFragment = gql`
  fragment SeriesFragment on Series {
    id
    flowID
    name
    active
    offChainMetadata {
      ...SeriesMetadataFragment
    }
  }
  ${SeriesMetadataFragment}
`;
