import FutureImage from 'next/future/image';
import Image, { ImageProps } from 'next/image';
import React from 'react';
import { imageOptimizerCDN } from 'src/lib/ImageOptimizer';
import { Box } from 'src/theme';

/**
 * Image component provided by next/image. This component will
 * not optimize images served from localhost. these images will
 * be broken.
 *
 * @see {@link https://nextjs.org/docs/api-reference/next/image|Docs: next/image}
 */
export const NextImage = ({ ...rest }: ImageProps) => {
  return <Image loader={imageOptimizerCDN} {...rest} />;
};

export const NextFutureImage = (props: any) => {
  const { objectFit, layout, style, ...rest } = props;
  const styles = {
    ...style,
    objectFit,
  };
  return (
    <FutureImage loader={imageOptimizerCDN} {...rest} fill style={styles} />
  );
};

/**
 * This component is a wrapper for the NextImage component.
 * Made in order to be able to combine ChakraUI and optimized NextImage
 */
export const ChakraNextImage = (props) => {
  const {
    alt,
    src,
    imageStyle = {},
    sx = {},
    maxHeight,
    margin,
    ...rest
  } = props;
  /**
   * By default, if you add the fill attribute for img,
   * the position: absolute property is added, which
   * cannot be removed or redefined, this breaks the default
   * behavior. With this function, the priority is set higher
   * to the properties that the developer describes.
   */
  const styles = Object.fromEntries(
    Object.entries(imageStyle).map(([key, value]) => {
      return [key, (value += '!important')];
    }),
  );
  return (
    <Box
      pos='relative'
      overflow='hidden'
      w='100%'
      h='100%'
      {...rest}
      __css={{
        '>img': {
          position: 'initial!important',
          width: 'initial!important',
          ...styles,
          ...sx,
          margin,
          maxHeight,
        },
      }}
    >
      <NextFutureImage
        fill
        alt={alt}
        src={src}
        style={{ objectFit: 'cover' }}
      />
    </Box>
  );
};
