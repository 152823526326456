import { Box, Container } from 'src/theme';

export const Maintenance = () => {
  return (
    <Container
      justifyContent='center'
      display='flex'
      width='100%'
      height='100%'
    >
      <Box alignSelf='center' p={4} backgroundColor='gray.900'>
        Playbook is currently paused while we investigate an issue, check back
        again soon.
      </Box>
    </Container>
  );
};
