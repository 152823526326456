import { decode, send as fclSend, tx, sansPrefix } from '@onflow/fcl';
import { DistributionVideoType } from '__generated__/globalTypes';
import flattenDeep from 'lodash/flattenDeep';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import shuffle from 'lodash/shuffle';
import uniq from 'lodash/uniq';
import { getVideoURL } from 'src/edge/Distribution';
import { QUERY_PACK_NFTS } from 'src/edge/searchPackNft';
import { preloadMedia } from 'src/general/utils/media';
import { isPackOpen } from 'src/modules/PackOpeningExperience/fsm/packOpening/utils/utils';
import { SEARCH_NFTS_FROM_PACK as SEARCH_NFTS } from 'src/modules/searchGolazosNft';
import {
  actions as xstateActions,
  AnyEventObject,
  assign,
  send,
  spawn,
} from 'xstate';

import { getMomentNFTMachine } from '../momentNFT/machine';
import { Context, EVENTS } from './types';
import { getInteraction } from './utils/interaction';

// @todo, get proper data model
export enum EditionTier {
  COMMON = 'COMMON',
  FANDOM = 'FANDOM',
  LEGENDARY = 'LEGENDARY',
  RARE = 'RARE',
  UNCOMMON = 'UNCOMMON',
}

export const actions = {
  assignAssets: assign({
    preloads: (context: Context, event: any) => ({
      ...context.preloads,
      ...event.data.preload,
    }),
  }),
  assignError: assign({
    error: (_, event: any) => event.data,
  }),
  assignNFTs: assign({
    nfts: (_, event: any) => event.data.nfts,
  }),
  assignPackNFT: assign({
    packNFT: (_, event: any) => event.data.packNFT,
  }),
  assignPreloadError: assign({
    isPreloadError: true,
  }),
  assignTransactionID: assign({
    transactionID: (_: Context, event: AnyEventObject) => event.data,
  }),
  forceRevealMomentNFTs: xstateActions.pure((context: Context) => {
    return context.momentNFTActors.map((p) => {
      return send(EVENTS.FORCE_REVEAL, { to: p.ref });
    });
  }),
  getInteraction: assign<Context, AnyEventObject>({
    interaction: (context: Context, event) => {
      const interaction = getInteraction({
        nftResourceID: context.packNFT.id,
      });
      return interaction;
    },
  }),
  spawnMomentNFTs: assign({
    momentNFTActors: (context: Context) => {
      // Shuffle nfts before adding to context so order is not always the same.
      return shuffle(context.nfts).map((momentNFT: any, index: number) => {
        return {
          id: momentNFT.id,
          ref: spawn(
            getMomentNFTMachine({
              custom: {
                definition: {
                  context: {
                    autoAppearDelay: index * 1000,
                    getPreload: context.getPreload,
                    media: context.media,
                    momentNFT,
                    preloads: context.preloads,
                  },
                },
              },
            }),
            `momentNFT-${momentNFT.id}`,
          ),
        };
      });
    },
  }),
};

export const guards = {
  isAllRevealed: (context) =>
    context.momentNFTActors.every(
      (actor) =>
        actor.ref.state.matches('REVEALED') ||
        actor.ref.state.matches('REVEALING') ||
        actor.ref.state.matches('DETAIL_VIEW'),
    ),
};

export const services = {
  awaitTransactionExecuted: async (ctx: Context) => {
    return await tx(ctx.transactionID).onceExecuted();
  },
  awaitTransactionFinalized: async (ctx: Context) => {
    return await tx(ctx.transactionID).onceFinalized();
  },
  awaitTransactionSealed: async (ctx: Context) => {
    return await tx(ctx.transactionID).onceSealed();
  },
  loadNFTs: (context: Context) => async () => {
    const { client, packNFT } = context;
    const { nfts } = packNFT;
    const nftids = nfts.split(',');
    const ids = nftids.map((nftid) => nftid.split('.')[3]);
    const { errors, data } = await client.query({
      context: {
        capturePolicy: 'none',
        clientName: 'platformAPI',
      },
      fetchPolicy: 'network-only',
      query: SEARCH_NFTS,

      variables: {
        filters: {
          id: { in: ids },
          type_name: {
            eq: `A.${sansPrefix(
              process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS,
            )}.Golazos.NFT`,
          },
        },
      },
    });

    const nftData = map(data?.searchGolazosNft?.edges, (edge) => edge?.node);

    // @NOTE: aggressively displaying NFTs through errors.
    if (errors && !nftData) {
      throw errors?.[0]?.message;
    }

    return {
      nfts: nftData,
    };
  },
  pollPack: (context: Context) => (callback, onReceive) => {
    const q = context.client.watchQuery({
      context: { clientName: 'platformAPI' },
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      notifyOnNetworkStatusChange: true,
      pollInterval: 10000,
      query: QUERY_PACK_NFTS,
      variables: {
        byIDs: [context.packNFT?.id.toString()],
        typeName: `A.${sansPrefix(
          process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS,
        )}.PackNFT.NFT`,
      },
    });
    const subscription = q.subscribe(({ loading, data }) => {
      if (loading) return;

      const packNFT = data?.searchPackNft?.edges?.[0].node;

      const isPackOpened = isPackOpen({ packNFT });

      // Continue polling
      if (!isPackOpened) return;

      const isMomentNFTs = !isEmpty(packNFT?.nfts);

      // Continue polling
      if (!isMomentNFTs) return;

      // Success
      callback({
        data: {
          ok: true,
          packNFT,
        },
        type: EVENTS.POLL_SUCCESS,
      });
    });

    onReceive((event) => {
      if (event.type === EVENTS.POLL_STOP) {
        console.error(new Error('Pack Opening Experience: polling timed out'), {
          errorInfo: {
            momentNFTsLength: context.packNFT?.momentNFTs?.length,
            packNFTID: context.packNFT?.id,
            packNFTStatus: context.packNFT?.status,
          },
          tags: { packOpeningExperience: true },
        });

        return callback({
          type: EVENTS.POLL_ERROR,
        });
      }
    });

    return () => {
      q.stopPolling();
      subscription.unsubscribe();
    };
  },
  preloadAssets: (context: Context, event: any) => async (callback) => {
    // how does the media & momentNFTs get into context now?
    const { getMomentMedia, MOMENT } = context.media;

    const momentTiers = uniq(context.nfts.map((m) => m.edition?.tier));

    const { MOMENT_UNVEIL } = context.media.UNIVERSAL_MEDIA;

    const imageUrls = [
      ...uniq(
        flattenDeep(
          context.nfts.map((MomentNFT) => {
            return [
              getMomentMedia({
                Edition: MomentNFT.edition,
                assetType: MOMENT.HERO,
                height: 800,
                width: 800,
              }),
              getMomentMedia({
                Edition: MomentNFT.edition,
                assetType: MOMENT.SCORES,
                height: 800,
                width: 800,
              }),
              getMomentMedia({
                Edition: MomentNFT.edition,
                assetType: MOMENT.FRONT,
                height: 800,
                width: 800,
              }),
            ];
          }),
        ),
      ),
    ];

    // @todo: if we want to turn this back on we need to reference it here https://github.com/dapperlabs/platform-services/blob/7538bad2af135e31688bc9bc843a01a99cb65d4c/apps/laliga/src/modules/PackOpeningExperience/components/DetailView.tsx#L81
    // @NOTE: temporarily disabling videos from being preloaded
    // const momentVideos = uniq(
    //   context.packNFT.momentNFTs.map((MomentNFT) => {
    //     return getMomentMedia({
    //       Edition: MomentNFT.edition,
    //       assetType: ASSET_TYPES.VIDEO_SQUARE_LOGO_1080_1080_BLACK,
    //     });
    //   }),
    // );

    const packRipVideo = getVideoURL(
      context.distribution,
      DistributionVideoType.RIP,
    );

    const videoUrls = [
      ...momentTiers.map((tier: EditionTier) => MOMENT_UNVEIL[tier]),
      packRipVideo,
    ];

    // @todo, find out if we have sound effects
    const audioUrls = [];

    const preload = await preloadMedia({ audioUrls, imageUrls, videoUrls });

    callback({
      data: {
        ok: true,
        preload,
      },
      type: EVENTS.PRELOAD_SUCCESS,
    });
    return;
  },

  preloadInitialAssets: (context: Context, event: any) => async (callback) => {
    const { MOMENT_APPEAR, MOMENT_APPEAR_AUDIO } =
      context.media.UNIVERSAL_MEDIA;
    const PACK_RIP = context?.distribution?.node?.videos[0]?.url;

    const videoUrls = [MOMENT_APPEAR, PACK_RIP];
    const audioUrls = [MOMENT_APPEAR_AUDIO];

    const preload = await preloadMedia({ audioUrls, videoUrls });
    callback({
      data: {
        ok: true,
        preload,
      },
      type: EVENTS.PRELOAD_SUCCESS,
    });
    return;
  },

  sendTransaction: async (ctx: Context) => {
    const interaction = await ctx.interaction();
    const transactionID = await fclSend(interaction).then(decode);

    return transactionID;
  },
};
