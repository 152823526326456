import React from 'react';
import { useTranslation } from 'src/lib/translations';
import {
  Heading,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  chakra,
  Flex,
  useMultiStyleConfig,
} from 'src/theme';
import { PLAYER_PERFORMANCE_THEME_KEYS } from 'src/theme/dss-skeleton/components';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';

import { GameStats } from './GameStats';
import { PlayerPerformanceHeader } from './Header';
import { PlayerType } from './types';

export type PLayerContextType = PlayerType;
export const PlayerContext = React.createContext({} as PLayerContextType);

export type PlayerPerformanceProps = {
  [key: string]: any;
  player: PlayerType;
  season: string;
};
export const PlayerPerformance = ({
  player,
  season,
  ...props
}: PlayerPerformanceProps): JSX.Element => {
  const playerContextValue: PLayerContextType = {
    ...player,
  };

  const { t } = useTranslation();
  const { tab, tabList } = useMultiStyleConfig('Tab');
  const { events, trackHandler } = useDssAnalytics();
  const { container, statsContainer } = useMultiStyleConfig(
    PLAYER_PERFORMANCE_THEME_KEYS.COMMON,
  );

  if (!player.matchStatistics && !player.seasonStatistics) {
    return null;
  }

  const onTabChange = (event) => {
    if (!event.target.dataset.analyticId) {
      return;
    }
    trackHandler(events.PLAYER_PERFORMANCE_ITEM_CLICK, {
      item_label: `tab_${event.target.dataset.analyticId}`,
      tab_id: event.target.dataset.analyticId,
    });
  };

  return (
    <chakra.div {...props}>
      <PlayerContext.Provider value={playerContextValue}>
        <Heading color='#F8F8F8' paddingBottom='1rem' size='lg'>
          {t('playerPerformance.heading')}
        </Heading>
        <Flex sx={container}>
          <PlayerPerformanceHeader />
          <chakra.div sx={statsContainer}>
            <Tabs>
              <TabList sx={tabList} onClick={onTabChange}>
                {player.matchStatistics && (
                  <Tab sx={tab} data-analytic-id='match'>
                    {t('playerPerformance.gameTab')}
                  </Tab>
                )}
                {player.seasonStatistics && (
                  <Tab sx={tab} data-analytic-id={`${season}_season`}>
                    {season} {t('playerPerformance.seasonTab')}
                  </Tab>
                )}
              </TabList>
              <TabPanels>
                {player.matchStatistics && (
                  <TabPanel>
                    <GameStats statistics={player.matchStatistics} />
                  </TabPanel>
                )}
                {player.seasonStatistics && (
                  <TabPanel>
                    <GameStats statistics={player.seasonStatistics} />
                  </TabPanel>
                )}
              </TabPanels>
            </Tabs>
          </chakra.div>
        </Flex>
      </PlayerContext.Provider>
    </chakra.div>
  );
};
