/* eslint-disable sort-keys-fix/sort-keys-fix, quotes, prettier/prettier */
// Autogenerated from graphql-golazos-seasons.js
type Seasons = Array<{ label: string; value: string }>;

export const SEASONS_DATA: Seasons = [
  { value: "2022-2023", label: "2022-2023" },
  { value: "2021-2022", label: "2021-2022" },
  { value: "2020-2021", label: "2020-2021" },
  { value: "2019-2020", label: "2019-2020" },
  { value: "2018-2019", label: "2018-2019" },
  { value: "2017-2018", label: "2017-2018" },
  { value: "2016-2017", label: "2016-2017" },
  { value: "2015-2016", label: "2015-2016" },
  { value: "2014-2015", label: "2014-2015" },
  { value: "2013-2014", label: "2013-2014" },
  { value: "2012-2013", label: "2012-2013" },
  { value: "2011-2012", label: "2011-2012" },
  { value: "2010-2011", label: "2010-2011" },
  { value: "2009-2010", label: "2009-2010" },
  { value: "2008-2009", label: "2008-2009" },
  { value: "2007-2008", label: "2007-2008" },
  { value: "2006-2007", label: "2006-2007" },
  { value: "2005-2006", label: "2005-2006" },
];
