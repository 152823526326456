/* eslint-disable sort-keys-fix/sort-keys-fix, quotes, prettier/prettier */
// Autogenerated from graphql-golazos-sets.js
type Sets = Array<{ label: string; value: string }>;

export const SETS_DATA: Sets = [
  { value: "Aficionados", label: "Aficionados" },
  { value: "Airborne", label: "Airborne" },
  { value: "Capture the Flag", label: "Capture the Flag" },
  { value: "ElClásico", label: "ElClásico" },
  { value: "Equipo del Mundo", label: "Equipo del Mundo" },
  { value: "Estrellas", label: "Estrellas" },
  { value: "Eterno Rival", label: "Eterno Rival" },
  { value: "Jugones", label: "Jugones" },
  { value: "Knock Knock", label: "Knock Knock" },
  { value: "Last Gasp", label: "Last Gasp" },
  { value: "Lewandowski’s Strikers", label: "Lewandowski’s Strikers" },
  { value: "Me, Myself, and I", label: "Me, Myself, and I" },
  { value: "My City, My Rules", label: "My City, My Rules" },
  { value: "Not in My House", label: "Not in My House" },
  { value: "Off the Whiteboard", label: "Off the Whiteboard" },
  { value: "Special Delivery", label: "Special Delivery" },
  { value: "Talentos", label: "Talentos" },
  { value: "Team Croqueta", label: "Team Croqueta" },
  { value: "Team Europa", label: "Team Europa" },
  { value: "Team Roulette", label: "Team Roulette" },
  { value: "Tiki Taka", label: "Tiki Taka" },
  { value: "Veni, Vidi, Vici", label: "Veni, Vidi, Vici" },
  { value: "Ídolos", label: "Ídolos" },
];
