export const welcomeConsole = () => {
  try {
    if (!process.browser) return;
    const v = process.env.NEXT_PUBLIC_APP_VERSION;
    const opacities = [1, 0.85, 0.65, 0.45, 0.25, 0.75, 0.75];
    const colors = Array(5)
      .fill('')
      .map(
        (_, i) =>
          `background-color:#000; color:rgba(255,255,255,${opacities[i]});`,
      );
    // eslint-disable-next-line no-console
    console.log(
      ...[
        `
%c${Array(40).fill(' ').join('')}
%c ${v}${Array(39 - (v.length ?? 0))
          .fill(' ')
          .join('')}
%c${Array(40).fill(' ').join('')}
                                        
%c We're hiring, come join the team!      
%c https://www.dapperlabs.com/careers     
                                        `,
        ...colors,
      ],
    );
    // eslint-disable-next-line no-empty
  } catch (err) {} // This failing should never affect anything
};
