import React from 'react';
interface VideoArgs {
  [x: string]: any;
  controls?: boolean;
  mp4: string;
  webm?: string;
}

export const Video = React.forwardRef<HTMLVideoElement, VideoArgs>(
  ({ controls = false, mp4, webm, videoRef, ...rest }: VideoArgs, ref) => {
    return (
      <video
        ref={ref}
        controls={controls}
        style={{
          display: 'block',
          height: 'auto',
          maxWidth: '100%',
          width: '100%',
        }}
        {...rest}
      >
        {webm && <source src={webm} type='video/webm' />}
        {mp4 && <source src={mp4} type='video/mp4' />}
        <p>Your browser doesn’t support mp4 or webm html5 videos.</p>
      </video>
    );
  },
);

Video.displayName = 'Video';
