import { useMultiStyleConfig } from '@chakra-ui/react';
import React from 'react';
import { useTranslation } from 'src/lib/translations';
import { Box, Flex } from 'src/theme';

export const SelectSidebarColumnTitles = ({
  isDesktop,
}: {
  isDesktop: boolean;
}) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig('SelectSidebar');
  return (
    <Flex>
      <Box
        sx={styles.column}
        w={{ base: '120px', md: '100px' }}
        paddingLeft='8px'
      >
        {t('SelectSidebar.columnTitle.serial')}
      </Box>
      <Box
        sx={styles.column}
        w={{ base: '100%', md: '120px' }}
        paddingLeft={{ base: 0, md: '8px' }}
      >
        {t('SelectSidebar.columnTitle.price')}
      </Box>
      {isDesktop && (
        <Box
          sx={styles.column}
          w={{ base: '0', md: '120px' }}
          paddingLeft={{ base: 0, md: '8px' }}
        >
          {t('SelectSidebar.columnTitle.seller')}
        </Box>
      )}
    </Flex>
  );
};
