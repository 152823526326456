/* eslint-disable sort-keys-fix/sort-keys-fix, quotes, prettier/prettier */
// Autogenerated from graphql-golazos-play-types.js
type PlayTypes = Array<{ label: string; value: string }>;

const echoParamFunc = (key, _) => key;

// 'PLAY_TYPE_NOT_FOUND_KEY' is fallback used to hide it from
// the FE when its key doesn't exist in Lokalise  - meaning,
// whenever a new Play Type is created, create a key for it!
export const PLAY_TYPE_NOT_FOUND_KEY = '__NOT_FOUND__';

export const PLAY_TYPES_DATA = (t = echoParamFunc): PlayTypes => [
  { value: "ASSIST", label: t('MarketplaceSearch.PlayType.ASSIST', PLAY_TYPE_NOT_FOUND_KEY) },
  { value: "BRACE", label: t('MarketplaceSearch.PlayType.BRACE', PLAY_TYPE_NOT_FOUND_KEY) },
  { value: "DEFENSIVE", label: t('MarketplaceSearch.PlayType.DEFENSIVE', PLAY_TYPE_NOT_FOUND_KEY) },
  { value: "GOAL", label: t('MarketplaceSearch.PlayType.GOAL', PLAY_TYPE_NOT_FOUND_KEY) },
  { value: "HATTRICK", label: t('MarketplaceSearch.PlayType.HATTRICK', PLAY_TYPE_NOT_FOUND_KEY) },
  { value: "HIGHLIGHT", label: t('MarketplaceSearch.PlayType.HIGHLIGHT', PLAY_TYPE_NOT_FOUND_KEY) },
  { value: "POKER", label: t('MarketplaceSearch.PlayType.POKER', PLAY_TYPE_NOT_FOUND_KEY) },
  { value: "SAVE", label: t('MarketplaceSearch.PlayType.SAVE', PLAY_TYPE_NOT_FOUND_KEY) },
  { value: "SKILL", label: t('MarketplaceSearch.PlayType.SKILL', PLAY_TYPE_NOT_FOUND_KEY) },
];
