import React from 'react';
import { useTranslation } from 'src/lib/translations';
import { Box, NextImage, Flex, Text, useMultiStyleConfig } from 'src/theme';
import { EditionInfoI } from 'src/theme/dss-skeleton/components';
import { rarityBackgroundValues } from 'src/theme/dss-skeleton/components/HeroSection/theme/RarityTier';

enum RARITIES {
  FANDOM = 'Fandom',
  LEGENDARY = 'Legendary',
  RARE = 'Rare',
  ULTIMATE = 'Ultimate',
  UNCOMMON = 'Uncommon',
}

export const SelectSidebarEditionInfo = ({
  editionInfo,
  onClose,
}: {
  editionInfo: EditionInfoI;
  onClose: () => void;
}) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig('SelectSidebar');
  return (
    <Flex sx={styles.editionInfoContainer}>
      <NextImage
        width={56}
        height={56}
        alt='select-sidebar-img'
        src={editionInfo.src}
      />
      <Box>
        <Text sx={styles.editionInfoName}>{editionInfo.name}</Text>
        <Flex alignItems='center' direction='row'>
          <Text
            background={rarityBackgroundValues[editionInfo.rarityType]}
            backgroundClip='text'
            // @ts-ignore
            textFillColor='transparent'
          >
            {RARITIES[editionInfo.rarityType]}
          </Text>
          <Text sx={styles.editionInfoEditionSize}>
            #/{editionInfo.editionSize}
          </Text>
        </Flex>
        <Text sx={styles.editionInfoViewMore} onClick={onClose}>
          {t('SelectSidebar.button.viewMore')}
        </Text>
      </Box>
    </Flex>
  );
};
