import * as flags from 'src/theme/dss-skeleton/themes/flags';

export const FLAG_ICONS = {
  albania: <flags.AlbaniaFlagIcon />,
  algeria: <flags.AlgeriaFlagIcon />,
  andorra: <flags.AndorraFlagIcon />,
  argentina: <flags.ArgentinaFlagIcon />,
  australia: <flags.AustraliaFlagIcon />,
  austria: <flags.AustriaFlagIcon />,
  belgium: <flags.BelgiumFlagIcon />,
  'bosnia and herzegovina': <flags.BosniaHerzegovinaFlagIcon />,
  brazil: <flags.BrazilFlagIcon />,
  'cabo verde': <flags.CaboVerdeFlagIcon />,
  cameroon: <flags.CameroonFlagIcon />,
  canada: <flags.CanadaFlagIcon />,
  'central african republic': <flags.CentralAfricanRepublicFlagIcon />,
  ceuta: <flags.CeutaFlagIcon />,
  chile: <flags.ChileFlagIcon />,
  'china pr': <flags.ChinaPRFlagIcon />,
  colombia: <flags.ColombiaFlagIcon />,
  'congo dr': <flags.CongoDRFlagIcon />,
  'costa rica': <flags.CostaRicaFlagIcon />,
  coteDIvoire: <flags.CoteDIvoireFlagIcon />,
  croatia: <flags.CroatiaFlagIcon />,
  "côte d'ivoire": <flags.CoteDIvoireFlagIcon />,
  denmark: <flags.DenmarkFlagIcon />,
  'dominican republic': <flags.DominicanRepublicFlagIcon />,
  ecuador: <flags.EcuadorFlagIcon />,
  england: <flags.EnglandFlagIcon />,
  france: <flags.FranceFlagIcon />,
  gabon: <flags.GabonFlagIcon />,
  georgia: <flags.GeorgiaFlagIcon />,
  germany: <flags.GermanyFlagIcon />,
  ghana: <flags.GhanaFlagIcon />,
  guadeloupe: <flags.GuadeloupeFlagIcon />,
  guinea: <flags.GuineaFlagIcon />,
  honduras: <flags.HondurasFlagIcon />,
  israel: <flags.IsraelFlagIcon />,
  italy: <flags.ItalyFlagIcon />,
  japan: <flags.JapanFlagIcon />,
  'korea republic': <flags.KoreaRepublicFlagIcon />,
  kosovo: <flags.KosovoFlagIcon />,
  mali: <flags.MaliFlagIcon />,
  martinique: <flags.MartiniqueFlagIcon />,
  mexico: <flags.MexicoFlagIcon />,
  montenegro: <flags.MontenegroFlagIcon />,
  morocco: <flags.MoroccoFlagIcon />,
  mozambique: <flags.MozambiqueFlagIcon />,
  netherlands: <flags.NetherlandsFlagIcon />,
  nigeria: <flags.NigeriaFlagIcon />,
  'north macedonia': <flags.NorthMacedoniaFlagIcon />,
  norway: <flags.NorwayFlagIcon />,
  paraguay: <flags.ParaguayFlagIcon />,
  peru: <flags.PeruFlagIcon />,
  poland: <flags.PolandFlagIcon />,
  portugal: <flags.PortugalFlagIcon />,
  'republic of ireland': <flags.IrelandFlagIcon />,
  'republic of korea': <flags.KoreaRepublicFlagIcon />,
  senegal: <flags.SenegalFlagIcon />,
  serbia: <flags.SerbiaFlagIcon />,
  slovakia: <flags.SlovakiaFlagIcon />,
  slovenia: <flags.SloveniaFlagIcon />,
  spain: <flags.SpainFlagIcon />,
  sweden: <flags.SwedenFlagIcon />,
  switzerland: <flags.SwitzerlandFlagIcon />,
  togo: <flags.TogoFlagIcon />,
  turkey: <flags.TurkeyFlagIcon />,
  ukraine: <flags.UkraineFlagIcon />,
  uruguay: <flags.UruguayFlagIcon />,
  usa: <flags.USAFlagIcon />,
  venezuela: <flags.VenezuelaFlagIcon />,
  wales: <flags.WalesFlagIcon />,
  zimbabwe: <flags.ZimbabweFlagIcon />,
};
