import { cdc } from '@onflow/fcl';

const CODE = cdc`import FungibleToken from 0xFungibleTokenContractAddress
import NonFungibleToken from 0xNonFungibleTokenContractAddress
import DapperUtilityCoin from 0xDapperUtilityCoinContractAddress
import Golazos from 0xNFTContractAddress
import NFTStorefrontV2 from 0xNFTStorefrontContractAddress
import MetadataViews from 0xMetadataViews

/// Transaction facilitates the purchase of listed NFT.
/// It takes the storefront address, listing resource that need
/// to be purchased & a address that will takeaway the commission.
///
/// Buyer of the listing (,i.e. underling NFT) would authorize and sign the
/// transaction and if purchase happens then transacted NFT would store in
/// buyer's collection.

transaction(
    storefrontAddress: Address, 
    listingResourceID: UInt64, 
    expectedPrice: UFix64,
    metadata: {String: String}
) {
    let paymentVault: @{FungibleToken.Vault}
    let GolazosCollection: &{NonFungibleToken.Receiver}
    let storefront: &{NFTStorefrontV2.StorefrontPublic}
    let listing: &{NFTStorefrontV2.ListingPublic}
    var commissionRecipientCap: Capability<&{FungibleToken.Receiver}>?

    // the merchant account will be the only recipient
    let commissionRecipient: Address?

    prepare(universalDucPayer: auth(Storage) &Account, buyer: auth(Storage, Capabilities) &Account) {
        self.commissionRecipient = 0xMerchantAccountAddress

        self.commissionRecipientCap = nil
        assert(storefrontAddress != buyer.address, message : "Buyer and seller can not be same")
        // Access the storefront public resource of the seller to purchase the listing.
        self.storefront = getAccount(storefrontAddress)
            .capabilities.get<&NFTStorefrontV2.Storefront>(NFTStorefrontV2.StorefrontPublicPath)
            .borrow()
            ?? panic("Could not borrow Storefront from provided address")

        // Borrow the listing
        self.listing = self.storefront.borrowListing(listingResourceID: listingResourceID)
                    ?? panic("No Offer with that ID in Storefront")
        let price = self.listing.getDetails().salePrice

        // perform check to ensure price presented to the user matches the on-chain price
        if expectedPrice != price {
            panic("Sale price not expected value")
        }

        // Access the vault of the buyer to pay the sale price of the listing.
        let mainDucVault = universalDucPayer.storage.borrow<auth(FungibleToken.Withdraw) &DapperUtilityCoin.Vault>(from: /storage/dapperUtilityCoinVault)
            ?? panic("Cannot borrow DUC vault from universalDucPayer account storage")
        self.paymentVault <- mainDucVault.withdraw(amount: price)

        // Initialize the Golazos collection if the buyer does not already have one
        if buyer.storage.borrow<&Golazos.Collection>(from: Golazos.CollectionStoragePath) == nil {
            // save it to the account
            buyer.storage.save(<-Golazos.createEmptyCollection(nftType: Type<@Golazos.NFT>()), to: Golazos.CollectionStoragePath)
            // create a public capability for the collection
            buyer.capabilities.publish(
                buyer.capabilities.storage.issue<&Golazos.Collection>(Golazos.CollectionStoragePath),
                at: Golazos.CollectionPublicPath
            )
        }

        // Access the buyer's NFT collection to store the purchased NFT.
        self.GolazosCollection = buyer.storage.borrow<&Golazos.Collection>(
            from: Golazos.CollectionStoragePath
        )!

        // Fetch the commission amt.
        let commissionAmount = self.listing.getDetails().commissionAmount

        if self.commissionRecipient != nil && commissionAmount != 0.0 {
            // Access the capability to receive the commission.
            let _commissionRecipientCap = getAccount(self.commissionRecipient!).capabilities.get<&{FungibleToken.Receiver}>(/public/dapperUtilityCoinReceiver)
            assert(_commissionRecipientCap.check(), message: "Commission Recipient doesn't have DUC receiving capability")
            self.commissionRecipientCap = _commissionRecipientCap
        } else if commissionAmount == 0.0 {
            self.commissionRecipientCap = nil
        } else {
            panic("Commission recipient can not be empty when commission amount is non zero")
        }
    }

    execute {
        // Purchase the NFT
        let item <- self.listing.purchase(
            payment: <-self.paymentVault,
            commissionRecipient: self.commissionRecipientCap
        )
        // Deposit the NFT in the buyer's collection.
        self.GolazosCollection.deposit(token: <-item)
        // Be kind and recycle
        self.storefront.cleanupPurchasedListings(listingResourceID: listingResourceID)
    }
}`;

export { CODE };
