import React from 'react';
import { Image } from 'src/theme';
import {
  CarouselDeprecated as Carousel,
  CarouselSlide,
  useVideo,
} from 'src/theme/dss-skeleton/components';

import { AssetContentType, ImageMediaType, VideoMediaType } from '../types';

export type AssetCarouselProps = {
  [key: string]: any;
  assets: (ImageMediaType | VideoMediaType)[];
  carouselId: string;
  fullScreenVideoProps?: React.ComponentProps<any>;
  imageProps?: React.ComponentProps<any>;
  showControls?: boolean;
  showLanguagePicker?: boolean;
  showNav?: boolean;
  videoProps?: React.ComponentProps<any>;
};
export const AssetCarousel = ({
  carouselId,
  assets,
  imageProps,
  videoProps,
  fullScreenVideoProps,
  showControls,
  showLanguagePicker,
  ...props
}: AssetCarouselProps) => {
  const renderCarouselSlide = (asset, index) => {
    const { type, id, src, fullscreenSrc, previewSrc } = asset;
    switch (type) {
      case AssetContentType.IMAGE:
        return (
          <CarouselSlide key={`${carouselId}-image-${id}`} previewSrc={src}>
            <Image alt={`${carouselId}-image`} src={src} {...imageProps} />
          </CarouselSlide>
        );
      case AssetContentType.VIDEO:
        // eslint-disable-next-line react-hooks/rules-of-hooks,no-case-declarations
        const { view, play, stop } = useVideo({
          autoplay: index === 0,
          fullScreenVideoProps,
          fullScreenVideoSrc: fullscreenSrc,
          id: `${carouselId}-video-${id}`,
          previewSrc,
          showControls,
          showLanguagePicker,
          videoProps,
          videoSrc: src,
        });

        return (
          <CarouselSlide
            key={`${carouselId}-video-${id}`}
            onSlideLeave={stop}
            onSlideShow={play}
            previewSrc={previewSrc}
          >
            {view}
          </CarouselSlide>
        );
      default:
        return null;
    }
  };
  return (
    <Carousel carouselId={carouselId} {...props}>
      {assets.map((asset, index) => renderCarouselSlide(asset, index))}
    </Carousel>
  );
};
