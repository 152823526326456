/* eslint-disable sort-keys-fix/sort-keys-fix */
// import { DistributionTier, EditionTier } from '__generated__/globalTypes';
import { ASSET_TYPES } from 'src/edge/Edition';
import { BUCKET_STATIC_URL } from 'src/general/constants/assets';

export const MAX_POLL_DURATION = 600000;
export const MAX_PRELOAD_DURATION = 60000;
export const MAX_INITIAL_PRELOAD_DURATION = 30000;
export const TRANSACTION_QUERY_DELAY_DURATION = 10000;

export const MOMENT_MEDIA = {
  FRONT: ASSET_TYPES.FRONT_BLACK_2880_2880_DEFAULT,
  SCORES: ASSET_TYPES.DETAILS_BLACK_2880_2880_DEFAULT,
  HERO: ASSET_TYPES.HERO_BLACK_2880_2880_DEFAULT,
  VIDEO: ASSET_TYPES.ANIMATED_VIDEO_POPOUT_BLACK_1080_1080_ES,
};

const MOMENT_APPEAR = `${BUCKET_STATIC_URL}/LaLiga_APPEAR_GENERIC_muted.mp4`;
const MOMENT_APPEAR_AUDIO = `${BUCKET_STATIC_URL}/LaLiga_APPEAR_GENERIC.mp3`;

// @todo, these could be moved into a /pack-opening/ directory
export const MOMENT_UNVEIL = {
  COMMON: `${BUCKET_STATIC_URL}/LaLiga_REVEAL_01COMMON.mp4`,
  UNCOMMON: `${BUCKET_STATIC_URL}/LaLiga_REVEAL_02UNCOMMON.mp4`,
  FANDOM: `${BUCKET_STATIC_URL}/LaLiga_REVEAL_03FANDOM.mp4`,
  RARE: `${BUCKET_STATIC_URL}/LaLiga_REVEAL_04RARE.mp4`,
  LEGENDARY: `${BUCKET_STATIC_URL}/LaLiga_REVEAL_05LEGENDARY.mp4`,
};

/**
 * @note: the rest of these are in the NFL bucket, if we need em
 */
// const MOMENT_APPEAR = `${BUCKET_STATIC_URL}/images/pack-opening/appear-universal.mp4`;

// @note: this will be done with css
// export const MOMENT_UNVEIL = {
//   [EditionTier.COMMON]: `${BUCKET_STATIC_URL}/images/pack-opening/unveil-common-fandom.mp4`,
//   [EditionTier.FANDOM]: `${BUCKET_STATIC_URL}/images/pack-opening/unveil-common-fandom.mp4`,
//   [EditionTier.RARE]: `${BUCKET_STATIC_URL}/images/pack-opening/unveil-rare-up.mp4`,
//   [EditionTier.LEGENDARY]: `${BUCKET_STATIC_URL}/images/pack-opening/unveil-rare-up.mp4`,
//   [EditionTier.ULTIMATE]: `${BUCKET_STATIC_URL}/images/pack-opening/unveil-rare-up.mp4`,
// };

/**
 * These are the shapes that appear below the unopened moments on hover
 */
// export const MOMENT_HOVER = {
//   [EditionTier.COMMON]: `${BUCKET_STATIC_URL}/images/pack-opening/hover--common--crop.png`,
//   [EditionTier.FANDOM]: `${BUCKET_STATIC_URL}/images/pack-opening/hover--fandom--crop.png`,
//   [EditionTier.RARE]: `${BUCKET_STATIC_URL}/images/pack-opening/hover--rare--crop.png`,
//   [EditionTier.LEGENDARY]: `${BUCKET_STATIC_URL}/images/pack-opening/hover--legendary--crop.png`,
//   [EditionTier.ULTIMATE]: `${BUCKET_STATIC_URL}/images/pack-opening/hover--ultimate--crop.png`,
// };

// const PACK_OVERLAY = {
//   // @NOTE: revisit these assets:
//   [DistributionTier.STANDARD]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-overlay--common.mp4`,
//   [DistributionTier.PLAYOFFS]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-overlay--common.mp4`,
//   // @TODO: replace with rare overlay
//   [DistributionTier.PREMIUM]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-overlay--common.mp4`,
//   //
//   [DistributionTier.COMMON]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-overlay--common.mp4`,
//   [DistributionTier.FANDOM]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-overlay--common.mp4`,
//   [DistributionTier.RARE]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-overlay--common.mp4`,
//   [DistributionTier.LEGENDARY]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-overlay--common.mp4`,
//   [DistributionTier.ULTIMATE]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-overlay--common.mp4`,
// };

// const PACK_LOADING = `${BUCKET_STATIC_URL}/images/pack-opening/pack-loading.gif`;
// const LOADING_BAR = `${BUCKET_STATIC_URL}/images/pack-opening/loading-bar.gif`;
// const PACK_RIP_FLASH = `${BUCKET_STATIC_URL}/images/pack-opening/pack-rip-flash.mp4`;

/**
 * @note: for laliga, these will be pack specific
 */
// const PACK_RIP = {
//   // @NOTE: these need assets:
//   [DistributionTier.STANDARD]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-rip--standard--generic.mp4`,
//   [DistributionTier.PLAYOFFS]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-rip--standard--generic.mp4`,
//   [DistributionTier.PREMIUM]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-rip--premium--generic.mp4`,
//   //
//   [DistributionTier.COMMON]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-rip--standard--generic.mp4`,
//   [DistributionTier.FANDOM]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-rip--standard--generic.mp4`,
//   [DistributionTier.RARE]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-rip--premium--generic.mp4`,
//   [DistributionTier.LEGENDARY]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-rip--legendary--generic.mp4`,
//   [DistributionTier.ULTIMATE]: `${BUCKET_STATIC_URL}/images/pack-opening/pack-rip--legendary--generic.mp4`,
// };

// @NOTE: liga won't have these, the videos have sound
// const PACK_SOUNDTRACK = {
//   [DistributionTier.STANDARD]: `${BUCKET_STATIC_URL}/images/pack-opening/soundtrack--common.mp3`,
//   [DistributionTier.PLAYOFFS]: `${BUCKET_STATIC_URL}/images/pack-opening/soundtrack--common.mp3`,
//   [DistributionTier.PREMIUM]: `${BUCKET_STATIC_URL}/images/pack-opening/soundtrack--rare.mp3`,
//   //
//   [DistributionTier.COMMON]: `${BUCKET_STATIC_URL}/images/pack-opening/soundtrack--common.mp3`,
//   [DistributionTier.FANDOM]: `${BUCKET_STATIC_URL}/images/pack-opening/soundtrack--common.mp3`,
//   [DistributionTier.RARE]: `${BUCKET_STATIC_URL}/images/pack-opening/soundtrack--rare.mp3`,
//   [DistributionTier.LEGENDARY]: `${BUCKET_STATIC_URL}/images/pack-opening/soundtrack--legendary.mp3`,
//   [DistributionTier.ULTIMATE]: `${BUCKET_STATIC_URL}/images/pack-opening/soundtrack--legendary.mp3`,
// };

// @note: we may want to reintroduce the sounds on reveal
// const REVEAL_FACE = `${BUCKET_STATIC_URL}/images/pack-opening/reveal-face.mp3`;

// const REVEAL_FINAL = {
//   [EditionTier.COMMON]: `${BUCKET_STATIC_URL}/images/pack-opening/reveal-final--common.mp3`,
//   [EditionTier.FANDOM]: `${BUCKET_STATIC_URL}/images/pack-opening/reveal-final--common.mp3`,
//   [EditionTier.RARE]: `${BUCKET_STATIC_URL}/images/pack-opening/reveal-final--rare.mp3`,
//   [EditionTier.LEGENDARY]: `${BUCKET_STATIC_URL}/images/pack-opening/reveal-final--rare.mp3`,
//   [EditionTier.ULTIMATE]: `${BUCKET_STATIC_URL}/images/pack-opening/reveal-final--rare.mp3`,
// };

// @todo, remove these once they're confirmed
export const UNIVERSAL_MEDIA = {
  MOMENT_APPEAR,
  MOMENT_APPEAR_AUDIO,
  MOMENT_UNVEIL,
  // LOADING_BAR,
  // MOMENT_HOVER,
  // PACK_LOADING,
  // PACK_OVERLAY,
  // PACK_RIP,
  // PACK_RIP_FLASH,
  // PACK_SOUNDTRACK,
  // REVEAL_FACE,
  // REVEAL_FINAL,
};
