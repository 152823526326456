import dynamic from 'next/dynamic';
import React from 'react';

import { RewardPassTier } from '__generated__/globalTypes';
import { useIsAuthed } from 'src/edge/session';
import { CountdownProps } from 'src/general/components/Countdown/Countdown';
import { imageOptimizerCDN } from 'src/lib/ImageOptimizer';
import { useTranslation } from 'src/lib/translations';
import { ProgressBar } from 'src/modules/RewardPass/components/';
import { GetActiveRewardPass_getActiveRewardPass_levels as Level } from 'src/modules/RewardPass/queries/__generated__/GetActiveRewardPass';
import {
  Box,
  Flex,
  Grid,
  GridProps,
  Heading,
  Image,
  Text,
  useBreakpointValue,
} from 'src/theme';
import { IconUnlocked, IconPlaybook } from 'src/theme/Icon';

import { Auth } from './Auth';

const Countdown = dynamic<CountdownProps>(
  () =>
    import('src/general/components/Countdown/Countdown').then(
      (mod) => mod.Countdown,
    ),
  {
    ssr: false,
  },
);

interface RewardPassStatusProps extends GridProps {
  expiresAt?: string;
  handleRewardClick?: () => void;
  handleUpgradeClick?: () => void;
  levels: Level[];
  nextRewardImageUrl?: string;
  nextRewardRequiredPoints?: number;
  nextRewardTitle?: string;
  title: string;
  userCurrentPoints?: number;
  userTier: RewardPassTier;
}

/**
 * Renders the user's current progress towards the next reward
 *
 * @param props.handleRewardClick callback for when the user clicks the next reward image
 * @param props.nextRewardImageUrl path to the image representing the next reward
 * @param props.nextRewardTitle title of the next reward
 * @param props.expiresAt the time the reward pass expires, indicated with a countdown
 * @param props.title the title of the reward pass
 * @param props.userCurrentPoints the total amount of points the user has towards the reward pass
 */
export const RewardPassStatus = ({
  expiresAt,
  forceCompact,
  handleRewardClick,
  handleUpgradeClick,
  levels,
  nextRewardImageUrl,
  nextRewardRequiredPoints,
  nextRewardTitle,
  title,
  userCurrentPoints = 0,
  userTier,
  ...props
}: { forceCompact?: boolean } & RewardPassStatusProps) => {
  const { t } = useTranslation();
  const isAuthed = useIsAuthed();
  const isBreakpointCompact = useBreakpointValue({ base: true, lg: false });
  const isCompact = forceCompact || isBreakpointCompact;
  const showCountdown = !!expiresAt;

  // @todo move this to a util and add some tests
  const userPointsToNextLevel =
    Math.max(0, nextRewardRequiredPoints - userCurrentPoints) || 0;

  const showNextLevel = userPointsToNextLevel > 0;
  const showUpgrade = userTier !== RewardPassTier.PREMIUM;

  const nextRewardImage = imageOptimizerCDN({
    height: 40,
    quality: 80,
    src: nextRewardImageUrl,
    width: 40,
  });

  return (
    <Grid
      as='section'
      gridAutoRows='auto'
      gridTemplateColumns={
        showCountdown
          ? isCompact
            ? '1fr'
            : 'minmax(190px, auto) 1fr 285px'
          : isCompact
          ? '1fr'
          : 'minmax(190px, auto) 1fr'
      }
      gridTemplateAreas={
        isCompact
          ? `
            "header"
            "progress"
            "graph"
            "reward"
            "time"
          `
          : `
            "header header header" 
            "progress graph time"
            "progress reward time"
          `
      }
      borderWidth={!forceCompact && '1px'}
      borderColor='gray.600'
      borderRadius={!forceCompact && '8px 8px 0 0'}
      bgColor='black'
      {...props}
    >
      <Box
        gridArea='header'
        p={4}
        borderColor='gray.600'
        borderBottomWidth='1px'
        borderRadius={!forceCompact && '8px 8px 0 0'}
        bgColor='gray.900'
      >
        <Flex alignItems='center' gap='2'>
          <IconPlaybook flex='0 0 auto' boxSize='20px' />
          <Heading as='h1' size='xs' variant='tall'>
            {title}
          </Heading>
        </Flex>
      </Box>
      {isAuthed ? (
        <Box gridArea='progress' padding={isCompact ? 4 : 5}>
          <Text mb={2} color='gray.300' size='xs' variant='value'>
            {t('playbook.status.progressLabel')}
          </Text>
          <Heading fontStyle='italic' size='xl' variant='tall'>
            {t('playbook.status.progress', {
              count: userCurrentPoints,
            })}
          </Heading>
        </Box>
      ) : (
        <Box
          gridArea='progress'
          padding={isCompact ? 4 : 5}
          textAlign='center'
          borderColor='gray.600'
          borderRightWidth={isCompact ? '0' : '1px'}
          borderRightStyle='solid'
          borderRadius={isCompact ? '0 0 8px 8px' : '0'}
        >
          <Auth trackingProperties={{ context: 'status' }} />
        </Box>
      )}
      <ProgressBar
        gridArea='graph'
        w='100%'
        mb={isCompact ? 4 : 0}
        pt={isCompact ? 0 : 5}
        px={isCompact ? 4 : 6}
        levels={levels}
        userCurrentPoints={userCurrentPoints}
        userTier={userTier}
      />
      <Box
        flex='1 1 auto'
        gridArea='reward'
        w='100%'
        pt={isCompact ? 4 : 0}
        pb={isCompact ? 4 : 2}
        px={isCompact ? 4 : 6}
        borderStyle='solid'
        borderColor='gray.600'
        borderTopWidth={isCompact ? '1px' : '0'}
      >
        {showNextLevel ? (
          <Flex
            alignItems='center'
            justifyContent={isCompact ? 'space-between' : 'start'}
            gap={4}
          >
            <Box>
              <Text mb={1} color='gray.300' size='xs' variant='body'>
                {t('playbook.status.rewardIn', {
                  count: userPointsToNextLevel,
                })}
              </Text>
              <Text color='white' size='xs' variant='value'>
                {nextRewardTitle}
              </Text>
            </Box>
            <Flex
              as={handleRewardClick ? 'button' : 'div'}
              alignItems='center'
              justifyContent='center'
              width='40px'
              height='40px'
              p={1}
              borderWidth='1px'
              borderStyle='solid'
              borderColor='gray.600'
              _hover={
                handleRewardClick && {
                  borderColor: 'gray.300',
                }
              }
              transition='border-color 0.25s ease-in-out'
              onClick={handleRewardClick && ((_event) => handleRewardClick())}
            >
              <Image alt={nextRewardTitle} src={nextRewardImage} />
            </Flex>
          </Flex>
        ) : (
          <>
            {showUpgrade ? (
              <Flex
                alignItems='center'
                justifyContent={isCompact ? 'space-between' : 'start'}
                gap={4}
              >
                <Box>
                  <Text mb={1} color='gray.300' size='xs' variant='body'>
                    {t('playbook.status.upgrade.heading')}
                  </Text>
                  <Text color='white' size='xs' variant='value'>
                    {t('playbook.status.upgrade.description')}
                  </Text>
                </Box>
                <Flex
                  as={handleUpgradeClick ? 'button' : 'div'}
                  alignItems='center'
                  justifyContent='center'
                  flex='0 0 auto'
                  width='40px'
                  height='40px'
                  borderWidth='1px'
                  borderStyle='solid'
                  borderColor='gray.600'
                  _hover={
                    handleUpgradeClick && {
                      borderColor: 'gray.300',
                    }
                  }
                  transition='border-color 0.25s ease-in-out'
                  onClick={
                    handleUpgradeClick && ((_event) => handleUpgradeClick())
                  }
                >
                  <IconUnlocked />
                </Flex>
              </Flex>
            ) : (
              <Box>
                <Text mb={1} color='gray.300' size='xs' variant='body'>
                  {t('playbook.status.completed.heading')}
                </Text>
                <Text color='white' size='xs' variant='value'>
                  {t('playbook.status.completed.description')}
                </Text>
              </Box>
            )}
          </>
        )}
      </Box>
      {showCountdown && (
        /**
         * This piece is a little weird because it's narrower on wide screens
         * than narrow ones. If you adjust the responsive styling make sure it
         * looks good on all screens sizes and check the navigation dropdown
         * too. We want to ignore the responsive alignment overrides when
         * forceCompact is false.
         */
        <Flex
          alignItems={!forceCompact && isCompact ? 'center' : 'start'}
          justifyContent={
            !forceCompact && isCompact ? 'space-between' : 'center'
          }
          flexDirection={!forceCompact && isCompact ? 'row' : 'column'}
          gap={1}
          gridArea='time'
          padding={4}
          borderStyle='solid'
          borderColor='gray.600'
          borderTopWidth={isCompact ? '1px' : '0'}
          borderLeftWidth={isCompact ? '0' : '1px'}
          borderRadius={isCompact ? '0 0 8px 8px' : '0'}
        >
          <Text
            mb={!forceCompact && isCompact ? 0 : 2}
            color='gray.300'
            size='xs'
            variant='body'
          >
            {t('playbook.status.countdown.endsIn')}
          </Text>
          <Countdown
            flex='0 0 auto'
            date={expiresAt}
            numberProps={
              userCurrentPoints >= 100
                ? {
                    color: 'voltGreen',
                    textShadow: '0px 0px 14px',
                  }
                : {
                    0: {
                      color: 'red.500',
                      textShadow: '0px 0px 14px',
                    },
                    21600000: {
                      color: 'orange.300',
                      textShadow: '0px 0px 14px',
                    },
                    86400000: {
                      color: 'white',
                    },
                  }
            }
            textSize='sm'
          />
        </Flex>
      )}
    </Grid>
  );
};
