// centralized store for all local storage keys

export const FLAGS = 'flags';

export const IS_PACK_OPENING_MUTED = 'isMuted';

export const PROMO_LS_KEY = 'contentful-promo-banner-hidden-dynamic';

export const MARKETPLACE_EXPANDED_FILTERS = 'marketplaceExpandedFilters';

export const MY_COLLECTION_MOMENTS_EXPANDED_FILTERS =
  'myCollectionMomentsExpandedFilters';

export const CHALLENGES_MOMENTS_EXPANDED_FILTERS =
  'challengesMomentsExpandedFilters';

export const MARKETPLACE_QUERY = 'marketplace_query';

export const LANGUAGE_PREFERENCE_STORAGE = 'LanguagePreference';
