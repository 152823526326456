import { useRouter } from 'next/router';
import { useSession } from 'src/edge/session';
import { redirectToGuestSignUp } from 'src/lib/guest';
import { useTranslation } from 'src/lib/translations';
import { Box, Button } from 'src/theme';

const IGNORE_ROUTES = [
  '/redeem/[code]',
  '/profile/packs/[packId]',
  '/user/[username]/packs/[packID]',
];

export const FinishSignUpAlert = () => {
  const { t } = useTranslation();
  const router = useRouter();
  const { state: sessionState } = useSession();

  if (IGNORE_ROUTES.some((r) => r === router.pathname)) return null;
  if (!sessionState.context.guest?.flowAddress) return null;

  return (
    <Box mx={4} my={8} p={5} bg='whiteAlpha.300' rounded={4}>
      <Box mb={2}>{t('Guest.finishSignUpPrompt.body')}</Box>
      <Button onClick={redirectToGuestSignUp}>
        {t('Guest.finishSignUpPrompt.cta')}
      </Button>
    </Box>
  );
};
