import { useEffect, useMemo, useRef, useState } from 'react';
import { useSession } from 'src/edge/session';
import { getLevelByPoints } from 'src/modules/RewardPass/utils';
import { updatePlaybookSeenPath } from 'src/modules/routes';

import { useGetRewardPass } from './useGetRewardPass';

type RewardPassProgressState = {
  points?: number;
  rewardPassId?: string;
};

const updateUserProfile = async () => {
  const response = await fetch(updatePlaybookSeenPath(), {
    headers: {
      'Content-Type': 'application/json',
    },
    method: 'POST',
  });

  if (!response.ok) {
    // @TODO: Handle Error State
    return;
  }
};

export const useUserHasProgress = () => {
  const { data: rewardPass } = useGetRewardPass();
  const { state: sessionState } = useSession();
  const [newProgress, setNewProgress] = useState(false);
  const lastSeenRef = useRef<RewardPassProgressState>(null);
  // @ts-ignore
  const playbookSeen = sessionState.context?.profile?.playbookSeen;

  useEffect(() => {
    // if user progress isn't returned (yet), exit early.
    // playbookSeen can be assumed to be loaded as the user profile
    // is required for userProgress to be queried
    if (!rewardPass?.userProgress || !rewardPass.levels?.length) return;

    // parse the necessary ids out of the user.playbookSeen in order to compare
    // them to the new state we get from UserProgress
    lastSeenRef.current = playbookSeen?.match(
      /(?<rewardPassId>.*):(?<points>.*)/,
    )?.groups;

    const isSamePass = lastSeenRef.current?.rewardPassId === rewardPass.id;

    const currentLevel = getLevelByPoints(
      rewardPass.userProgress.currentPoints,
      rewardPass.levels,
    );

    // We want to update this any time:
    // 1. pass is same but points change
    // 2. pass has changed
    if (
      !isSamePass ||
      lastSeenRef.current.points !== rewardPass.userProgress.currentPoints
    ) {
      updateUserProfile();
    }

    // reward pass is different and the user has achieved at least one level
    // reward pass being different also applies to there not being a previous value
    if (!isSamePass && currentLevel) {
      setNewProgress(true);
    }

    // reward pass is same, but level is different
    // previouslySeenLevel can be null here
    const previouslySeenLevel =
      isSamePass &&
      getLevelByPoints(lastSeenRef.current.points, rewardPass.levels);
    if (
      isSamePass &&
      currentLevel &&
      previouslySeenLevel?.id !== currentLevel.id
    ) {
      setNewProgress(true);
    }
  }, [rewardPass, playbookSeen]);

  return useMemo(() => {
    return {
      clearProgress: () => setNewProgress(false),
      hasProgress: newProgress,
    };
  }, [newProgress]);
};
