import { useApolloClient } from '@apollo/client';
import { useMachine } from '@xstate/react';
import { useState, useMemo } from 'react';
import { IS_PACK_OPENING_MUTED } from 'src/general/constants/localStorage';
import { setItem, getItem } from 'src/general/utils/localStorage';

import { getPackOpeningMachine } from './fsm/packOpening/machine';
import { tApp } from './types';

interface tUsePackOpening {
  app: tApp;
  custom?: any; // @TODO: maybe get rid of this
  distribution: any; //@TODO: use a real type
  packNFT: any; // @TODO: use a real type
}

export const usePackOpening = ({
  app,
  custom = {},
  packNFT,
  distribution,
}: tUsePackOpening) => {
  const [isMuted, setIsMuted] = useState(!!getItem(IS_PACK_OPENING_MUTED));

  const toggleIsMuted = () => {
    setIsMuted((state) => {
      setItem(IS_PACK_OPENING_MUTED, !state);
      return !state;
    });
  };

  const client = useApolloClient();

  const packOpeningMachine = useMemo(
    () =>
      getPackOpeningMachine({
        custom: {
          definition: {
            ...custom.definition,
            context: {
              app,
              client,
              distribution,
              packNFT,
              ...custom.definition?.context,
            },
          },
          options: custom.options,
        },
      }),
    [],
  );

  const [state, send, actor] = useMachine(packOpeningMachine);

  return {
    actor, // @TODO: temporary
    audio: {
      isMuted,
      toggleIsMuted,
    },
    send,
    state: {
      context: state.context,
      value: state.value,
    },
  };
};
