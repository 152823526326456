import { useContext, createContext, FC, ReactNode } from 'react';

import { useFTUEV2Machine } from '../fsm';

type SessionMachineReturnType = ReturnType<typeof useFTUEV2Machine>;
interface Context {
  send: SessionMachineReturnType[1];
  state: SessionMachineReturnType[0] & { value: any };
}

export const FTUEV2Context = createContext<Context>({} as Context);

export const FTUEV2Provider: FC<{ children?: ReactNode }> = ({ children }) => {
  const [state, send] = useFTUEV2Machine();
  const FTUEV2MachineValue = {
    send,
    state,
  };

  return (
    <FTUEV2Context.Provider value={FTUEV2MachineValue}>
      {children}
    </FTUEV2Context.Provider>
  );
};

export const useFTUEV2 = () => useContext(FTUEV2Context);
