/**
 * Extract value from platform-services API call
 *
 * Example: { key: X } -> X
 * @param attr - attribute to extract
 * @returns it's value
 */
export const extractValue = (attr) => {
  return attr
    ? attr.key !== undefined
      ? attr.key
      : attr.value !== undefined
      ? attr.value
      : attr.lower_case_value !== undefined
      ? attr.lower_case_value
      : attr
    : attr;
};

/**
 * @NOTE: you probably don't need to use this function. the `react-i18next`
 * library has this functionality built in. Please see en.json and search for
 * _plural to see an example of how this can otherwise be implemented.
 */
export const pluralize = (noun: string, quantity: number) =>
  quantity === 1 ? noun : `${noun}s`;

/**
 * Delete all cookies
 *
 * THIS WILL NOT CLEAR HTTP COOKIES
 */
export const deleteAllCookies = () =>
  document.cookie.split(';').forEach((cookie) => {
    const eqPos = cookie.indexOf('=');
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
    document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
  });

// https://stackoverflow.com/questions/13627308/add-st-nd-rd-and-th-ordinal-suffix-to-a-number
export const getNumberWithOrdinal = (n: number): string => {
  const s = ['th', 'st', 'nd', 'rd'];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const calculateTotalPacks = (odds): number => {
  return odds.reduce((acc: number, curr) => (acc += curr.value), 0);
};
