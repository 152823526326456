import { ReactNode } from 'react';

import { Footer } from 'src/general/components/Footer';
import DefaultNavigation from 'src/general/components/Navigations/Default';
import { Box, Flex } from 'src/theme';

/**
 * Layout with a gradient that 'spills-over' from the nav to the page
 * @param children
 */
export const GradientSpillOver = ({ children }: { children: ReactNode }) => {
  return (
    <Flex flexDirection='column' h='100%' background='ftue.radialGradient'>
      <DefaultNavigation styles={{ background: 'transparent' }} />
      <Box
        flexGrow={1}
        flexShrink={0}
        alignSelf='center'
        w='100%'
        maxW='container.xl'
        minHeight={{ base: '40vh', md: '60vh' }}
      >
        {children}
      </Box>
      <Footer />
    </Flex>
  );
};
