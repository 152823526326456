import { signInPath, captureException } from '@dapperlabs/core-fe';
import Analytics from 'analytics-node';
import pick from 'lodash/pick';
import { LANGUAGE_PREFERENCE_STORAGE } from 'src/general/constants/localStorage';
import { deleteAllCookies } from 'src/general/utils/helpers';
import { getItem, setItem } from 'src/general/utils/localStorage';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment';
import { languages } from 'src/lib/translations';
import { GET_MY_PROFILE } from 'src/modules/Queries/profile';
import { GetMyProfileQuery } from 'src/modules/Queries/profile.generated';
import { assign } from 'xstate';

import { getSession } from '../session-class';
import { Context } from './types';

const analytics = new Analytics(process.env.NEXT_PUBLIC_SEGMENT_WRITE_TOKEN);

export const actions = {
  assignError: assign({
    error: (_, event: any) => event.data,
  }),
  assignGuestData: assign({
    guest: (_, event: any) => event?.data?.guest,
  }),
  assignLanguage: assign({
    preferredLanguage: (_, event: any) =>
      // from send
      event?.preferredLanguage ??
      // from preloadPreferences
      event?.data?.preferredLanguage ??
      // assign from BE profile
      event?.data?.profile?.preferredLanguage,
  }),
  assignMarketingEmail: assign({
    marketingEmail: (_, event: any) => event?.marketingEmail,
  }),
  assignProfile: assign({
    profile: (_, event: any) => event?.data?.profile,
  }),
  assignSession: assign({
    auth: (_, event: any) => event?.data?.session,
  }),
  assignfavoriteClub: assign({
    favoriteClub: (_, event: any) => event?.favoriteClub,
  }),
  i18nUpdate: (context) => {
    const { i18n, preferredLanguage } = context;
    const i18nLangAbbr = languages.find(
      (lang) => lang.value === preferredLanguage,
    )?.i18nAbbreviation;
    if (i18nLangAbbr) {
      i18n.changeLanguage(i18nLangAbbr, (err) => {
        if (err) {
          captureException(err);
          return;
        }
        setItem(LANGUAGE_PREFERENCE_STORAGE, preferredLanguage);
      });
    }
  },
};

export const guards = {
  gotValidSession: (_, event: any) =>
    !!event?.data?.session && !event?.data?.error,
  hasAuth: (ctx) => !!ctx.auth,
  hasProfile: (ctx) => !!ctx.profile,
};

export const services = {
  createProfile: async (context: Context) => {
    const { marketingEmail, preferredLanguage } = context;

    const response = await fetch('/api/auth/createProfile', {
      body: JSON.stringify({
        marketingEmail,
        preferredLanguage,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const result = await response.json();

    if (!response.ok) {
      const responseError = {
        code: result.code || '',
        data: result.data || '',
        message:
          result.message || 'Something went wrong while creating profile',
        type: 'Error',
      };

      const error = new Error(JSON.stringify(responseError));

      captureException(error);
      throw new Error('Failed to create user profile');
    }

    return {
      profile: result,
    };
  },
  loadProfile: async (context: Context) => {
    const { client } = context;
    const { errors, data } = await client.query<GetMyProfileQuery>({
      context: {
        capturePolicy: 'none',
        clientName: 'laligaAPI',
      },
      query: GET_MY_PROFILE,
    });

    if (errors) {
      captureException(errors);
      throw new Error('Unable to fetch user profile');
    }

    if (!data?.getMyProfile?.userProfile) {
      throw new Error("Profile of the current user doesn't exist");
    }

    return {
      profile: data?.getMyProfile?.userProfile,
    };
  },
  loadSession: async () => {
    const session = await getSession().getData();

    if (!session) {
      return {
        guest: await getSession().getGuestData(),
        session: null,
      };
    }

    return {
      session: pick(session, ['user']),
    };
  },
  preloadPreferences: async () => {
    // check local storage and browser language settings
    let preferredLanguage;
    const storedLanguage = await getItem(LANGUAGE_PREFERENCE_STORAGE);
    if (['es', 'ESP'].includes(storedLanguage)) {
      preferredLanguage = 'ESP';
    } else if (['en', 'ENG'].includes(storedLanguage)) {
      preferredLanguage = 'ENG';
    } else {
      const browserLocale =
        navigator.languages && navigator.languages.length
          ? navigator.languages[0]
          : navigator.language;
      preferredLanguage = browserLocale.startsWith('es') ? 'ESP' : 'ENG';
    }

    return { preferredLanguage };
  },

  /**
   * Sign in with redirect
   *
   * Redirects back to the same page you are on unless specified otherwise
   *
   * @param _context
   * @param event.returnTo - the path to redirect to after login
   *
   */
  signIn: async (_context, { returnTo = null }) => {
    const params: { returnTo?: string } = {};

    if (returnTo) {
      params.returnTo = returnTo;
    } else {
      const fullPath = window.location.href.replace(window.location.origin, '');
      if (fullPath !== '/') params.returnTo = fullPath;
    }

    return window?.location.replace(signInPath(params));
  },

  /**
   * Non-auth0 sign out
   *
   * also clears storage, cookies and analytics business
   */
  signOut: async (ctx) => {
    analytics.track({
      event: SEGMENT_EVENTS_ID_ENUM.WALLET_AUTH_RESPONSE,
      properties: {
        auth_result: 'success',
        auth_type: 'log_out',
      },
      userId: ctx?.auth?.user?.sub,
    });

    window?.analytics?.reset();
    localStorage?.clear();
    sessionStorage?.clear();
    deleteAllCookies();

    return window?.location.replace('/api/auth/logout');
  },

  upsertPreferences: async (context: Context) => {
    const { preferredLanguage } = context;

    const response = await fetch('/api/auth/preferences', {
      body: JSON.stringify({
        preferredLanguage,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    if (!response.ok) {
      const result = await response.json();
      const responseError = {
        code: result.code || '',
        data: result.data || '',
        message:
          result.message || 'Something went wrong while upserting preferences',
        type: 'Error',
      };

      const error = new Error(JSON.stringify(responseError));

      captureException(error);
      throw new Error('Failed upsert user preferences');
    }
  },
  upsertfavoriteClub: async (context: Context) => {
    const { favoriteClub } = context;
    const response = await fetch('/api/auth/favoriteClub', {
      body: JSON.stringify({
        favoriteClub,
      }),
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      method: 'POST',
    });

    const result = await response.json();

    if (!response.ok) {
      const responseError = {
        code: result.code || '',
        data: result.data || '',
        message:
          result.message ||
          'Something went wrong while upsering favourite club',
        type: 'Error',
      };

      const error = new Error(JSON.stringify(responseError));

      captureException(error);
      throw new Error('Failed to upsert favourite club');
    }

    const updatedProfile = {
      ...context.profile,
      favoriteClub: result.favoriteClub,
    };

    return {
      profile: updatedProfile,
    };
  },
};
