import useContentful from 'src/lib/contentful/useContentful';
import { getContentfulEntryID } from 'src/lib/contentful/utils';

function useGetEntries(id, params = {}) {
  const entryID = getContentfulEntryID(params['sys.id']);
  const parsedParams = {
    ...params,
    'sys.id': entryID,
  };

  return useContentful(id, 'getEntries', parsedParams);
}

export default useGetEntries;
