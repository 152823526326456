import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document } from '@contentful/rich-text-types';
import {
  useMultiStyleConfig,
  AccordionItem,
  AccordionButton,
  AccordionIcon,
  Heading,
  Box,
  AccordionPanel,
  Text,
  OrderedList,
  UnorderedList,
  ListItem,
} from 'src/theme';
import { LEGAL_LIST_THEME_KEYS } from 'src/theme/dss-skeleton/components';

// Style some nodes like paragraph, ul and ol and list item
const getBlockOptions = (styles) => {
  return {
    renderNode: {
      [BLOCKS.PARAGRAPH]: (node, children) => (
        <Text sx={styles.accordionPanel} pt={2}>
          {children}
        </Text>
      ),
      [BLOCKS.UL_LIST]: (node, children) => (
        <UnorderedList>{children}</UnorderedList>
      ),
      [BLOCKS.OL_LIST]: (node, children) => (
        <OrderedList>{children}</OrderedList>
      ),
      [BLOCKS.LIST_ITEM]: (node, children) => <ListItem>{children}</ListItem>,
    },
  };
};

interface LegalListItemProps {
  description: Document;
  title: string;
}
export const LegalListItem = ({
  title,
  description,
}: LegalListItemProps): JSX.Element => {
  const styles = useMultiStyleConfig(LEGAL_LIST_THEME_KEYS.ITEM);
  const blockOptions = getBlockOptions(styles);

  return (
    <AccordionItem>
      <AccordionButton sx={styles.accordionButton}>
        <Box sx={styles.boxContainer}>
          <Heading as='h3' sx={styles.headingText}>
            {title}
          </Heading>
        </Box>
        <AccordionIcon />
      </AccordionButton>
      <AccordionPanel>
        {documentToReactComponents(description, blockOptions)}
      </AccordionPanel>
    </AccordionItem>
  );
};
