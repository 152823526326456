import {
  blog,
  discord,
  help,
  instagram,
  privacy,
  statusPage,
  terms,
  twitter,
  AllDay,
  FootballGreatsLogo,
  NflLogo,
  NflpaLogo,
} from '@dapperlabs/core-fe';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';
import { useTranslation } from 'src/lib/translations';
import {
  Box,
  Container,
  Flex,
  Grid,
  HStack,
  IconDiscordLogo,
  IconInstagramLogo,
  IconTwitterLogo,
  Link,
  Stack,
  StackDivider,
} from 'src/theme';

export const Footer = (props) => {
  return (
    <Box padding={{ base: 6, md: 10 }} color='grey.8' bg='backgroundBlack'>
      <Container maxW='container.xl' padding={0}>
        <Stack
          direction={{ base: 'column', md: 'row' }}
          divider={<StackDivider />}
          spacing='40px'
        >
          <Stack
            justifyContent='space-between'
            direction={{ base: 'column', md: 'row' }}
            flexGrow={1}
            spacing='40px'
          >
            <LogoSection display={['flex', null, 'none']} />
            <InternalLinksSection />
            <SocialLinksSection />
          </Stack>
          <CopyrightSection />
        </Stack>
      </Container>
    </Box>
  );
};

const InternalLinksSection = () => {
  const { t } = useTranslation();

  const internalLinks = [
    {
      text: t('Footer.links.status'),
      // @ts-ignore
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_STATUS_CLICK,
      url: statusPage,
    },
    { text: t('Footer.links.blog'), url: blog },
    { text: t('Footer.links.help'), url: help },
    { text: t('Footer.links.privacy'), url: privacy },
    { text: t('Footer.links.terms'), url: terms },
  ];

  return (
    <Grid
      flexGrow={1}
      gap={4}
      gridAutoFlow='column'
      gridTemplateRows='repeat(3, 1.5rem)'
      placeItems='start'
    >
      {internalLinks.map(({ text, url, trackingId }) => (
        <Link
          key={text}
          padding={0}
          fontSize='sm'
          href={url}
          trackingId={trackingId}
        >
          {text}
        </Link>
      ))}
    </Grid>
  );
};

const SocialLinksSection = () => {
  const { t } = useTranslation();

  const socialLinks = [
    {
      SocialIcon: IconInstagramLogo,
      text: t('Footer.social.Instagram'),
      // @ts-ignore
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_INSTAGRAM_CLICK,
      url: instagram,
    },
    {
      SocialIcon: IconTwitterLogo,
      text: t('Footer.social.Twitter'),
      // @ts-ignore
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_TWITTER_CLICK,
      url: twitter,
    },
    {
      SocialIcon: IconDiscordLogo,
      text: t('Footer.social.Discord'),
      // @ts-ignore
      trackingId: SEGMENT_EVENTS_ID_ENUM.NAVIGATION_FOOTER_DISCORD_CLICK,
      url: discord,
    },
  ];

  return (
    <Grid
      justifyContent={{ base: 'start', md: 'stretch' }}
      flexGrow={1}
      gap={6}
      gridAutoFlow='column'
      gridTemplateRows={{ md: 'repeat(3, 1.5rem)' }}
      placeItems='start'
    >
      {socialLinks.map(({ text, url, SocialIcon, trackingId }) => (
        <Flex
          key={text}
          as={Link}
          alignItems='center'
          padding={0}
          fontSize='sm'
          whiteSpace='nowrap'
          href={url}
          trackingId={trackingId}
        >
          <SocialIcon boxSize={6} mr={{ md: 2 }} />
          <Box as='span' display={{ base: 'none', md: 'inline' }}>
            {text}
          </Box>
        </Flex>
      ))}
    </Grid>
  );
};

const CopyrightSection = () => {
  const currentYear = new Date().getFullYear();
  const { t } = useTranslation();
  return (
    <Stack
      direction='column'
      maxW={{ md: '300px' }}
      fontSize='xs'
      spacing='8px'
    >
      <p>{t('Footer.rights.dapper', { currentYear })}</p>
      <p>{t('Footer.rights.nfl', { currentYear })}</p>
      <p>{t('Footer.rights.licensed', { currentYear })}</p>
      <p>{t('Footer.rights.captcha')}</p>
      <LogoSection display={['none', null, 'flex']} pt={4} />
    </Stack>
  );
};

const LogoSection = ({ ...props }) => {
  return (
    <HStack gridGap={6} {...props}>
      <NflLogo width='33px' height='46px' />
      <AllDay width='60px' height='34px' />
      <NflpaLogo width='36px' height='42px' />
      <FootballGreatsLogo width='38px' height='42px' />
    </HStack>
  );
};
