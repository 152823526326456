import { ComponentStyleConfig } from 'src/theme';

export const FeaturedMomentsTheme: ComponentStyleConfig = {
  baseStyle: {
    grid: {
      gap: '1.5rem',
      gridTemplateColumns: {
        md: 'repeat(2, 1fr)',
        xl: 'repeat(4, 1fr)',
      },
      minWidth: {
        base: '100%',
        xl: '1236px',
      },
      paddingX: {
        base: '1rem',
        xl: '0',
      },
      paddingY: '1.5rem',
    },
    header: {
      alignItems: 'center',
      justifyContent: 'space-between',
      paddingX: {
        base: '1rem',
        xl: '0',
      },
      width: '100%',
    },
    link: {
      _hover: {
        textDecoration: 'none',
      },
      borderBottom: '2px solid #F0F2FD',
      color: '#F0F2FD',
      fontFamily: 'mono',
      fontSize: 'xs',
      lineHeight: '1rem',
      paddingY: '0.5rem',
      textDecoration: 'none',
    },
    title: {
      color: 'white',
      fontSize: '2.25rem',
      fontWeight: '500',
    },
  },
  parts: ['grid', 'header', 'link', 'title'],
};
