import React, { FC } from 'react';

import { Modal, ModalContent, ModalOverlay } from 'src/theme/';

import { useModal } from './ModalProvider';

export const CustomModal: FC = ({}) => {
  const { isOpen, setIsOpen, modalContent } = useModal();
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
      <ModalOverlay />
      <ModalContent>{modalContent}</ModalContent>
    </Modal>
  );
};
