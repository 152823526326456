export const RARITY_STATE = {
  // NOTE: THIS 2 ARE HERE AS A TEMPORARY WAY OF TRANSITION TO THE NEW TIERS
  BASE: 'BASE',
  //Rare
  // ULTIMATE: 'ULTIMATE', //Legendary
  COMMON: 'COMMON',
  FANDOM: 'FANDOM',

  LEGENDARY: 'LEGENDARY',

  RARE: 'RARE',
  SEASONAL: 'SEASONAL',
  //Common
  SPECIAL_EDITION: 'SPECIAL_EDITION',
  ULTIMATE: 'ULTIMATE',
};

export const DISTRIBUTION_RARITY_STATE = {
  COMMON: 'COMMON',
  LEGENDARY: 'LEGENDARY',
  PREMIUM: 'PREMIUM',
  RARE: 'RARE',
  STANDARD: 'STANDARD',
};

/**
 * For sorting by rarity
 */
export const ORDERED_RARITY = [
  RARITY_STATE.COMMON,
  RARITY_STATE.RARE,
  RARITY_STATE.LEGENDARY,
  RARITY_STATE.ULTIMATE,
];
