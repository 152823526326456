import Script from 'next/script';

interface OneTrustProps {
  token: string;
}

const OneTrustSdk = ({ token }: OneTrustProps) => {
  return (
    <Script
      data-domain-script={token}
      id='one-trust'
      src='https://cdn.cookielaw.org/scripttemplates/otSDKStub.js'
      strategy='afterInteractive'
      type='text/javascript'
    />
  );
};

const OneTrustHandler = () => {
  return (
    <Script id='one-trust-handler' type='text/javascript'>
      {`function OptanonWrapper() {}`}
    </Script>
  );
};

export const OneTrustScript = ({ token }: OneTrustProps) => {
  return (
    <>
      <OneTrustSdk token={token} />
      <OneTrustHandler />
    </>
  );
};
