const ignoreErrors = [
  'NetworkError when attempting to fetch resource', // firefox
  'TypeError: cancelled', // iOS
  'TypeError: Failed to fetch',
  'TypeError: NetworkError when attempting to fetch resource.',
  'TypeError: Cancelled',
  'The network connection was lost.',
  'oidc: token is expired',
  /**
   * happens frequently on IOS 15;
   * happens when a user tries to navigate away from a page that has some pending requests;
   */
  'TypeError: Load failed',
  // ignore bot:
  `Cannot read properties of null (reading 'click')`,
];

module.exports = ignoreErrors;
