import { TaskCategory } from '__generated__/globalTypes';
import { searchChallenges_allChallenges_searchChallenges_edges_node } from 'src/modules/Challenges/queries/__generated__/searchChallenges_allChallenges';
import {
  GetActiveRewardPass_getActiveRewardPass as RewardPass,
  GetActiveRewardPass_getActiveRewardPass_levels as Level,
  GetActiveRewardPass_getActiveRewardPass_levels_rewards as LevelReward,
  GetActiveRewardPass_getActiveRewardPass_tasks as Task,
} from 'src/modules/RewardPass/queries/__generated__/GetActiveRewardPass';
import {
  GetUserProgress_getUserProgress as UserRewardPassProgress,
  GetUserProgress_getUserProgress_tasks as UserTask,
} from 'src/modules/RewardPass/queries/__generated__/GetUserProgress';

export interface DecoratedUserRewardPass extends RewardPass {
  isPreview?: boolean;
  levels: Level[];
  tasks: DecoratedUserTask[];
  upgradeTask?: DecoratedUserTask;
  userProgress?: UserRewardPassProgress;
}

export enum TaskAvailability {
  AVAILABLE = 'AVAILABLE',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  UPCOMING = 'UPCOMING',
}

export interface DecoratedUserTask extends Task {
  availability?: TaskAvailability;
  challenge?: searchChallenges_allChallenges_searchChallenges_edges_node;
  userTask?: UserTask;
}

export interface NextEligibleRewardLevel {
  nextEligibleReward?: LevelReward;
  nextLevelWithEligibleReward?: Level;
}

export interface TaskGroup {
  availability?: TaskAvailability;
  category: TaskCategory;
  description: string;
  id: string;
  referenceID?: string;
  rewardPoints: number;
  subtasks: DecoratedUserTask[];
  title: string;
  userTask: {
    completedAt: string;
  };
}
