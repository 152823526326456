/* eslint-disable sort-keys-fix/sort-keys-fix */
export const theme = {
  colors: {
    brand: {
      base: 'white',
    },
    gray: {
      300: '#989896',
      600: '#1F1F1D',
      700: '#5E5E5E',
      800: '#313131',
      900: '#17171B',
    },
    purple: {
      300: '#F8F8F8',
    },
  },
  components: {
    Button: {
      baseStyle: {
        fontWeight: 400,
        fontFamily: 'mono',
      },
    },
    Modal: {
      baseStyle: {
        dialog: {
          bg: '#000',
          border: '1px solid #1F1F1D',
          borderRadius: ['20px', '24px'],
          boxShadow: '0px 0px 24px rgba(240, 242, 253, 0.2)',
          px: '16px',
          pb: '16px',
        },
        closeButton: {
          borderRadius: '100%',
          w: '48px',
          h: '48px',
        },
      },
    },
    BoxGlow: {
      baseStyle: {
        glow: {
          filter: 'blur(1.25em)',
        },
      },
      variants: {
        common: {
          border: {
            layerStyle: 'common',
          },
        },
        uncommon: {
          border: {
            layerStyle: 'uncommon',
          },
        },
        fandom: {
          border: {
            layerStyle: 'fandom',
          },
        },
      },
    },
    Counter: {
      baseStyle: {
        container: {},
        indicator: {
          fontFamily: 'main',
          fontWeight: 'normal',
          px: 0.5,
          py: 0.5,
        },
      },
      variants: {
        solid: {
          indicator: {
            bg: '#63DBE1',
            color: 'surface0',
            minW: '12px',
            right: `-${1.5}`,
            top: `-${1.5}`,
          },
        },
      },
    },
    DescriptionList: {
      baseStyle: {
        details: { color: 'gray.300', fontFamily: 'main' },
        term: { fontFamily: 'mono' },
      },
    },
    Drawer: {
      baseStyle: ({ size }) => {
        if (size === 'full') {
          return {
            dialog: {
              '@supports(max-height: -webkit-fill-available)': {
                maxH: '-webkit-fill-available',
              },
            },
          };
        }
      },
    },
    FormLabel: {
      baseStyle: {
        fontFamily: 'mono',
        fontSize: 'sm',
      },
    },
    Input: {
      variants: {
        outline: {
          field: {
            bg: 'gray.800',
            borderColor: 'gray.600',
            borderWidth: 1,
          },
        },
      },
    },
    Nav: {
      baseStyle: {
        list: {},
        listItem: {},
        nav: {
          bg: 'black',
          borderBottomColor: 'gray.600',
          borderBottomWidth: '1px',
          py: 0,
        },
      },
      variants: {
        global: {
          link: {
            _hover: {
              color: '#fff',
            },
            _selected: {
              bg: 'transparent',
              borderColor: 'white',
              color: 'white',
            },
            borderBottomWidth: '2px',
            borderColor: 'transparent',
            color: 'gray.300',
            px: 4,
            py: 2.5,
            transition: 'color .15s ease-in-out',
          },
        },
      },
    },
    NumberInput: {
      variants: {
        defaultProps: {
          size: 'sm',
        },
        outline: {
          field: {
            bg: 'gray.800',
            borderColor: 'gray.600',
            borderWidth: 1,
          },
        },
      },
    },
    Slider: {
      sizes: {
        md: {
          track: { h: '2px' },
        },
      },
    },
    Tab: {
      baseStyle: {
        tab: {
          fontFamily: 'mono',
          fontSize: 'sm',
          fontWeight: '500',
          letterSpacing: '0.3px',
        },
        tabList: {
          border: '0 0 1px 0',
          borderColor: '#2B2C33',
        },
      },
    },
    Tag: {
      defaultProps: {
        variant: 'solid',
      },
      variants: {
        solid: {
          container: {
            bg: 'brand.base',
            border: '2px solid',
            borderColor: 'gray.600',
            color: 'surface0',
          },
        },
      },
    },
  },

  fonts: {
    main: `'Roboto Flex', sans-serif`,
    body: `'Roboto Flex', sans-serif`,
    heading: `'Trim', sans-serif`,
    mono: `'Simplon Mono', sans-serif`,
    poster: `'Trim Poster', sans-serif`,
    roboto: `'Roboto Flex', sans-serif`,
  },
  layerStyles: {
    common: {
      bg: '#888888',
    },
    uncommon: {
      bgGradient: 'linear-gradient(94.05deg, #EBF2FC 1.53%, #A9B9CB 100%)',
    },
    fandom: {
      bgGradient: 'linear-gradient(95.26deg, #ADF85A 0%, #FDED45 100%)',
    },
    rare: {
      bgGradient: 'linear-gradient(84.74deg, #FFD771 0%, #F23EA1 100%)',
    },
    legendary: {
      bgGradient: 'linear-gradient(95.26deg, #F57FF8 0%, #5680EF 100%)',
    },
    ultimate: {
      bgGradient:
        'linear-gradient(90deg, #FA00FF 7.29%, #B400FF 43.75%, #00FCFF 95.83%))',
    },
    packUnopened: {
      boxShadow: '0px 0px 15px #888888',
    },
  },
  semanticTokens: {
    radii: {
      boxGlowBorderRadius: '12px',
    },
  },
  textStyles: {
    common: {
      bgClip: 'text',
      bgGradient: 'linear-gradient(#888888 0%, #888888 100%)',
    },
    uncommon: {
      bgClip: 'text',
      bgGradient: 'linear-gradient(94.05deg, #EBF2FC 1.53%, #A9B9CB 100%)',
    },
    fandom: {
      bgClip: 'text',
      bgGradient: 'linear-gradient(95.26deg, #ADF85A 0%, #FDED45 100%)',
    },
    rare: {
      bgClip: 'text',
      bgGradient: 'linear-gradient(84.74deg, #FFEAB5 0%, #DFA821 100%)',
    },
    legendary: {
      bgClip: 'text',
      bgGradient:
        'linear-gradient(95.26deg, #FFE7D1 0%, #00FFFF 45.83%, #5A7FFF 100%)',
    },
    ultimate: {
      bgClip: 'text',
      bgGradient:
        'linear-gradient(90deg, #FA00FF 7.29%, #B400FF 43.75%, #00FCFF 95.83%)',
    },
  },
};
