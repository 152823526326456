import React, { useMemo } from 'react';
import { Flex, useMultiStyleConfig } from 'src/theme';

import { BannerContent } from './BannerContent';
import { BANNER_SECTION_COMMON } from './themes';
import { BannerSectionProps } from './types';

const DEFAULT_MEDIA = {
  additionalMediaUrl: '',
  isVideo: false,
  placeholderImage: '',
  position: 'left',
  url: '',
};

const HEIGHT_BANNER = {
  large: '720px',
  medium: '640px',
  small: '504px',
};

export const BannerSection = ({
  bannerBackground = 'black',
  bannerBackgroundRightPart = bannerBackground,
  sizeBanner = 'medium',
  title = '',
  description,
  button,
  media = DEFAULT_MEDIA,
  additionalLink,
  announcement,
  signatureDapper = false,
  isMainBanner = false,
  copies,
  trackHandler,
  additionalLinkLabelEvent,
}: BannerSectionProps) => {
  const styles = useMultiStyleConfig(BANNER_SECTION_COMMON);
  const directionContainer = useMemo(() => {
    let base = 'column';
    const isLeftPosMedia = media?.position === 'left' ? media : null;
    if (!isLeftPosMedia && !isMainBanner) {
      base = 'column-reverse';
    }
    return { base, md: 'row' };
  }, [isMainBanner, media]);

  return (
    <Flex
      //@ts-ignore
      direction={directionContainer}
      width='100%'
      height={{ base: '100%', md: HEIGHT_BANNER[sizeBanner] }}
      mt={{ base: isMainBanner ? 0 : '84px', md: 0 }}
      bg={bannerBackground}
    >
      <Flex sx={styles.contentWrapper}>
        <BannerContent
          additionalLink={additionalLink}
          additionalLinkLabelEvent={additionalLinkLabelEvent}
          announcement={announcement}
          button={button}
          copies={copies}
          description={description}
          heightBanner={HEIGHT_BANNER[sizeBanner]}
          isMainBanner={isMainBanner}
          media={media?.position === 'left' ? media : null}
          signatureDapper={signatureDapper}
          title={title}
          trackHandler={trackHandler}
        />
      </Flex>
      <Flex sx={styles.contentWrapper} bg={bannerBackgroundRightPart}>
        <BannerContent
          additionalLink={additionalLink}
          additionalLinkLabelEvent={additionalLinkLabelEvent}
          announcement={announcement}
          button={button}
          copies={copies}
          description={description}
          heightBanner={HEIGHT_BANNER[sizeBanner]}
          isMainBanner={isMainBanner}
          media={media?.position === 'right' ? media : null}
          signatureDapper={signatureDapper}
          title={title}
          trackHandler={trackHandler}
        />
      </Flex>
    </Flex>
  );
};
