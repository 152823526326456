import { gql } from '@apollo/client';

export const UserProfileFragment = gql`
  fragment UserProfileFragment on UserProfile {
    email
    username
    flowAddress
    dapperID
    profileImageUrl
    phoneNumber
    createdAt
    signedTos
    preferredCurrency
    preferredLanguage
    marketingEmail
    favoriteClub
  }
`;

export const GET_MY_PROFILE = gql`
  query GetMyProfile {
    getMyProfile {
      userProfile {
        ...UserProfileFragment
      }
    }
  }
  ${UserProfileFragment}
`;

export const UPSERT_MY_PROFILE = gql`
  mutation upsertUserProfile($input: UpsertUserProfileInput!) {
    upsertUserProfile(input: $input) {
      isCreated
      userProfile {
        ...UserProfileFragment
      }
    }
  }
  ${UserProfileFragment}
`;
