import type { ComponentStyleConfig } from 'src/theme';

const theme: ComponentStyleConfig = {
  baseStyle: {
    link: {
      _focus: {
        boxShadow: 'outline',
        outline: 'none',
      },
      alignItems: 'center',
      display: 'flex',
    },
    list: {
      display: 'flex',
      gridTemplateColumns: 'minmax(min-content, max-content)',
    },
    listItem: {
      listStyleType: 'none',
    },
    nav: {
      alignItems: 'center',
      bg: 'surface1',
      display: 'grid',
      gridArea: 'actions',
      gridColumnGap: 4,
      gridTemplateAreas: "'start mid end'",
      gridTemplateColumns: 'max-content 1fr max-content',
      px: 8,
      py: 2,
      zIndex: 'docked',
    },
  },
  defaultProps: {
    variant: 'sub',
  },

  parts: ['nav', 'list', 'listItem', 'link'],
  variants: {
    global: {
      link: {
        _selected: {
          bg: 'surface3',
        },
        fontWeight: 'bold',
        mx: 3,
        p: 2,
      },
      nav: {
        px: 8,
      },
    },
    sub: {
      link: {
        _focus: { outlineOffset: '1em' },
        _selected: {
          _after: {
            borderWidth: 2,
            left: 4,
            right: 4,
          },

          color: '#fff',
        },
        color: 'grey.text',
        fontWeight: 'bold',
        height: '50px',
        px: 4,
      },
    },
  },
};

export const NAV_THEME_KEY = 'NAV';
export const NavTheme = {
  [NAV_THEME_KEY]: theme,
};
