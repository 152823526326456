import {
  FTUE_EVENTS as EVENTS,
  useFTUEV2,
  UserInfoCardCompact,
} from '@dapperlabs/core-fe';
import React from 'react';
import { useIsAuthed, useSession } from 'src/edge/session';
import { Z_INDEX_TYPES, zIndexOf } from 'src/general/utils/zIndex';
import { useFeatureFlags } from 'src/lib/launchDarkly';
import { useAnalytics } from 'src/lib/segment';
import { SEGMENT_EVENTS_ID_ENUM } from 'src/lib/segment/SegmentEventIDEnums';
import { useTranslation } from 'src/lib/translations';
import {
  Button,
  Box,
  Divider,
  Icon,
  ICON_USER,
  Flex,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  VisuallyHidden,
} from 'src/theme';
import { IconRightArrow } from 'src/theme/Icon';

import { AccountMenuItems, NavItem, SettingsMenuItems } from './navItems';

export const DesktopAuthedMenu = () => {
  const { t } = useTranslation();
  const { send, state: sessionState } = useSession();
  const isAuthed = useIsAuthed();
  const { track } = useAnalytics();

  const profile = sessionState?.context?.profile;

  const ACCOUNT_MENU_ITEMS: NavItem[] = AccountMenuItems(isAuthed);
  const SETTINGS_MENU_ITEMS: NavItem[] = SettingsMenuItems(isAuthed);

  return (
    <Box alignItems='center' flexDirection='row' display='flex'>
      <Menu>
        <MenuButton
          display={['none', 'none', 'inherit']}
          bg='transparent'
          _hover={{ background: 'black' }}
          paddingX='0'
        >
          <Icon {...ICON_USER} color='white' aria-describedby='user-icon' />
          <VisuallyHidden id='user-icon'>
            An icon representing a user
          </VisuallyHidden>
        </MenuButton>
        <MenuList
          zIndex={zIndexOf(Z_INDEX_TYPES.MENU)}
          w={!profile ? '330px' : 'auto'}
          p={10}
          bg='dark'
          border='none'
          borderRadius='8px'
        >
          {profile && (
            <>
              <UserInfoCardCompact
                marginBottom='24px'
                // @ts-ignore
                profileImageUrl={profile.profileImageUrl}
                // @ts-ignore
                username={profile.username}
              />
              <Divider />
            </>
          )}
          {ACCOUNT_MENU_ITEMS.map((menuItem, index) => (
            <MenuItem
              key={`${menuItem.href}-${index}`}
              mt={index % 2 === 0 ? '24px' : '12px'}
              mb={index % 2 !== 0 ? '24px' : '12px'}
              pr='0'
              pl='0'
            >
              <Link
                href={menuItem.href}
                target={menuItem.target}
                trackingId={menuItem.trackingId}
                variant='nav-link-menu-item'
              >
                {menuItem.children}
              </Link>
            </MenuItem>
          ))}
          <Divider />
          {SETTINGS_MENU_ITEMS.map((menuItem, index) => (
            <MenuItem
              key={`${menuItem.href}-${index}`}
              mt={index % 2 === 0 ? '24px' : '12px'}
              mb={index % 2 !== 0 ? '24px' : '12px'}
              pr='0'
              pl='0'
            >
              <Link
                href={menuItem.href}
                target={menuItem.target}
                trackingId={menuItem.trackingId}
                variant='nav-link-menu-item'
              >
                {menuItem.children}
              </Link>
            </MenuItem>
          ))}
          <MenuItem
            pr='0'
            pl='0'
            onClick={() => {
              send('SIGN_OUT');
              track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_SIGN_OUT_CLICK);
            }}
          >
            {t('NavigationBar.NavItem.signOut')}
            <IconRightArrow w='24px' h='24px' pl='4px' />
          </MenuItem>
        </MenuList>
      </Menu>
    </Box>
  );
};

export const DesktopUnauthedMenu = () => {
  const { t } = useTranslation();
  const { send, state } = useSession();
  const { send: sendFTUE } = useFTUEV2();
  const { authMaintenance, isFTUEV2Enabled } = useFeatureFlags();
  const { track } = useAnalytics();

  const handleSignIn = () => {
    send('SIGN_IN');
    track(SEGMENT_EVENTS_ID_ENUM.NAVIGATION_HEADER_SIGN_IN_CLICK);
  };

  return (
    <Flex alignItems='center' justifyContent='end' flex='1 0 auto' gap='2'>
      <Button
        disabled={authMaintenance}
        isLoading={state.value === 'LOADING'}
        onClick={handleSignIn}
        size='xs'
        variant='ghost'
      >
        {t('NavigationBar.Auth.login')}
      </Button>
      <Button
        disabled={authMaintenance}
        isLoading={state.value === 'LOADING'}
        onClick={isFTUEV2Enabled ? () => sendFTUE(EVENTS.START) : handleSignIn}
        size='xs'
        trackingId={isFTUEV2Enabled && SEGMENT_EVENTS_ID_ENUM.FTUEV2_NAV_CTA}
      >
        {t('NavigationBar.Auth.signUp')}
      </Button>
    </Flex>
  );
};
