import React, { ReactElement } from 'react';
import { Grid, GridProps } from 'src/theme';

export type CardGridProps = {
  children: ReactElement[];
};

export const CardGrid = ({ children, ...props }: CardGridProps & GridProps) => {
  return (
    <Grid
      gap={{
        base: 2,
        md: 6,
      }}
      templateColumns='repeat(auto-fill, minmax(288px, 1fr))'
      {...props}
    >
      {children}
    </Grid>
  );
};
