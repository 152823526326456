import { gql } from '@apollo/client';

export const PlayMetadataFragment = gql`
  fragment PlayMetadataFragment on PlayMetadata {
    classification
    state
    description
    league
    playType
    videos {
      type
      url
    }
    audio {
      url
      narrator {
        profilePicture
        name
        position
        organization
      }
    }
    images {
      type
      url
    }
    week
    season
    playerFullName
    playerFirstName
    playerLastName
    playerPosition
    playerNumber
    playerWeight
    playerHeight
    playerBirthdate
    playerBirthplace
    playerRookieYear
    playerDraftTeam
    playerDraftYear
    playerDraftRound
    playerDraftNumber
    playerCollege
    gameNflID
    gameDate
    homeTeamName
    homeTeamScore
    awayTeamName
    awayTeamScore
    gameTime
    gameQuarter
    gameDown
    gameDistance
    teamName
    teamID
    homeTeamID
    awayTeamID
  }
`;
export const PlayFragment = gql`
  fragment PlayFragment on Play {
    id
    flowID
    metadata {
      ...PlayMetadataFragment
    }
  }
  ${PlayMetadataFragment}
`;
