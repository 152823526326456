import { ComponentStyleConfig } from 'src/theme';

export enum DESCRIPTION_COLORS {
  forsale = '#0D25C2',
  hidden = '#FDED45',
  owned = '#DE378E',
  reserved = '#ADF85A',
}

export const DescriptionTheme: ComponentStyleConfig = {
  baseStyle: ({ variant }) => ({
    indicator: {
      background: DESCRIPTION_COLORS[variant],
      borderRadius: '0.75rem',
      height: '2rem',
      width: '10px',
    },
    label: {
      color: '#8A94A6',
      fontSize: 'lg',
    },
    line: {
      background: '#2B2C33',
      flex: 1,
      height: '1px',
    },
    tooltip: {
      alignItems: 'center',
      display: 'flex',
      height: '1rem',
      marginLeft: '0.5rem',
      minWidth: '1rem',
      svg: {
        cursor: 'pointer',
        fill: '#8A94A6',
      },
    },
    valueText: {
      color: 'white',
      fontFamily: 'heading',
      fontSize: '3xl',
    },
    wrapper: {
      alignItems: 'center',
      gap: '0.75rem',
      marginY: '2rem',
    },
  }),
  defaultProps: { variant: 'base' },
  parts: ['indicator', 'label', 'line', 'valueText', 'wrapper'],
};
