import { useMultiStyleConfig } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import { useTranslation } from 'src/lib/translations';
import { Flex } from 'src/theme';

import { ItemI } from './SelectSidebar';

interface SelectSidebarItemProps {
  flowAddress?: string | undefined;
  isDesktop: boolean;
  isSelected: boolean;
  item: ItemI;
  onClick: any;
}

export const SelectSidebarItem = ({
  item,
  onClick,
  isSelected,
  isDesktop,
  flowAddress,
}: SelectSidebarItemProps) => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig('SelectSidebar');
  const getStylesItemContainer = useMemo(() => {
    if (isSelected) {
      return { ...styles.itemContainer, ...styles.selectedItem };
    }
    return styles.itemContainer;
  }, [isSelected, styles.itemContainer, styles.selectedItem]);
  return (
    <Flex sx={getStylesItemContainer} onClick={onClick}>
      <Flex sx={{ ...styles.itemCommon, ...styles.itemNumber }}>
        #{item.serial}
      </Flex>
      <Flex sx={{ ...styles.itemCommon, ...styles.itemPrice }}>
        {item.askingPrice}
      </Flex>
      {isDesktop && (
        <Flex sx={{ ...styles.itemCommon, ...styles.itemId }}>
          {item.owner.includes(flowAddress)
            ? t('SerialSidebar.column.title.you')
            : item.owner}
        </Flex>
      )}
    </Flex>
  );
};
