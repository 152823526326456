export const enum SEGMENT_EVENTS_ID_ENUM {
  CHALLENGES_CATEGORY_SELECT_CLICK = 'challenge-hub-category-select',
  CHALLENGES_LOAD_MORE_CLICK = 'challenge-hub-load-more-click',
  CHALLENGES_SEARCH_MARKETPLACE_CLICK = 'challenge-hub-search-marketplace-click',
  CHALLENGES_STATUS_TAB_CLICK = 'challenge-hub-status-click',
  CHALLENGE_DETAIL_BACK_CLICK = 'challenge-detail-back-click',
  CHALLENGE_DETAIL_CREATE_ENTRY_LINK = 'challenge-builder-create-click',
  CHALLENGE_DETAIL_SEARCH_MARKETPLACE_CLICK = 'challenge-detail-search-marketplace-click',
  CHALLENGE_STATE_BACK_CLICK = 'challenge-state-back-click',
  CHALLENGE_SUBMIT_BACK_CLICK = 'challenge-builder-back-click',
  CHALLENGE_SUBMIT_BURN_CANCEL_CLICK = 'challenge-builder-burn-cancel-click',
  CHALLENGE_SUBMIT_BURN_CONFIRM_CLICK = 'challenge-builder-burn-confirm-click',
  CHALLENGE_SUBMIT_BURN_SUCCESS = 'challenge-builder-burn-view-success',
  CHALLENGE_SUBMIT_BURN_TRANSACTION_ERROR = 'challenge-builder-burn-nfts-transaction-error',
  CHALLENGE_SUBMIT_CHALLENGE_NAME_CLICK = 'challenge-builder-challenge-name-click',
  CHALLENGE_SUBMIT_FILTER = 'challenge-builder-filter',
  CHALLENGE_SUBMIT_NFT_SELECT = 'challenge-builder-slot-select',
  CHALLENGE_SUBMIT_REFRESH_NFTS = 'challenge-builder-slot-refresh',
  CHALLENGE_SUBMIT_SAVE_CLICK = 'challenge-builder-save-click',
  CHALLENGE_SUBMIT_SHOP_CLICK = 'challenge-builder-shop-click',
  CHALLENGE_SUBMIT_SLOT_CLICK = 'challenge-builder-slot-click',
  CHALLENGE_SUBMIT_SUBMIT_CLICK = 'challenge-builder-submit-click',
  CHALLENGE_SUBMIT_WARNING_CLOSE = 'challenge-builder-warning-close',
  CHALLENGE_VIEW_DETAILS_CLICK = 'challenge-detail-click',
  COMPLETED_CHALLENGES_AUTH = 'challenge-hub-log-in-click',
  DROP_MISSES_ACCEPT_OFFER = 'drop-misses-accept-offer',
  DROP_MISSES_CONFIRM_BURN = 'drop-misses-confirm-burn',
  DROP_MISSES_EXPLORE_MARKETPLACE = 'drop-misses-explore-marketplace',
  DROP_MISSES_FAILURE_OR_DAPPER_CANCELLATION = 'drop-misses-failure-or-dapper-cancellation',
  DROP_MISSES_OFFER_SHOWN = 'drop-misses-offer-shown',
  DROP_MISSES_RETURN_TO_POE = 'drop-misses-return-to-poe',
  DROP_MISSES_START = 'drop-misses-start',
  FTUEV2_COMPLETE = 'ftuev2-complete',
  FTUEV2_ERROR_SHOWN = 'ftuev2-error-shown',
  FTUEV2_HERO_CTA = 'ftuev2-hero-cta',
  FTUEV2_NAV_CTA = 'ftuev2-nav-cta',
  // these are being used but are interpolated
  FTUEV2_PROGRESS_MODAL_CLAIM_REWARD_CTA = 'ftuev2-progress-modal-claim-reward-cta',
  FTUEV2_PROGRESS_MODAL_CLAIM_REWARD_EXIT = 'ftuev2-progress-modal-claim-reward-exit',
  FTUEV2_PROGRESS_MODAL_SIGN_UP_CTA = 'ftuev2-progress-modal-sign-up-cta',
  FTUEV2_PROGRESS_MODAL_SIGN_UP_EXIT = 'ftuev2-progress-modal-sign-up-exit',
  FTUEV2_SUCCESS_MODAL_EXIT = 'ftuev2-success-modal-exit',
  FTUEV2_SUCCESS_MODAL_PRIMARY_CTA = 'ftuev2-success-modal-primary-cta',
  FTUEV2_SUCCESS_MODAL_SHARE = 'ftuev2-success-modal-share',
  FTUEV2_TEAM_PICKER_CTA = 'ftuev2-team-picker-cta',
  FTUEV2_TEAM_PICKER_EXIT = 'ftuev2-team-picker-exit',
  FTUEV2_TEAM_PICKER_TEAM_SELECT = 'ftuev2-team-picker-team-select',
  FTUEV2_USER_SIGNED_UP = 'ftuev2-user-signed-up',
  FTUEV2_VIDEO_PLAY = 'ftuev2-video-play',
  FTUE_TOS_AGREE_CLICK = 'ftue-tos-agree-click',
  FTUE_TOS_MARKETINGOPTIN_CHECK = 'ftue-tos-marketingoptin-check',
  FTUE_TOS_PAGEVIEW = 'ftue-tos-pageview',
  HOMEPAGE_CLICK_PROMO_BOX = 'homepage-click-promo-box',
  HOMEPAGE_CLICK_PROMO_READ_MORE = 'homepage-click-promo-read-more',
  HOMEPAGE_CLOSE_PROMO_BANNER = 'homepage-close-promo-banner',
  HOMEPAGE_FEATURED_PACKS_INTERACTION = 'homepage-featured-packs-interaction',
  LISTING_SIGN_IN_TO_BUY = 'listing-sign-in-to-buy',
  MAINTENANCE_ERROR_GETNOTIFIED = 'maintenance-error-getnotified',
  MAINTENANCE_LOGINS_DISPLAY = 'maintenance-logins-display',
  MARKETPLACE_MOMENTS_TAB = 'marketplace-moments-tab',
  MARKETPLACE_TEAM_NFTS_TAB = 'marketplace-team-nfts-tab',
  MOMENT_DETAILS_BADGE_TOOLTIP_VIEW = 'moment-detail-badge-tooltip-view',
  MOMENT_DETAILS_BADGE_TOOLTIP_VIEW_V2 = 'moment-detail-badge-tooltip-view-v2',
  MOMENT_DETAILS_LIST_FOR_SALE = 'moment-detail-list-for-sale',
  MOMENT_DETAILS_LIST_FOR_SALE_MODAL_LIST_FOR_SALE = 'moment-detail-list-for-sale-modal-list-for-sale',
  MOMENT_DETAILS_LIST_FOR_SALE_MODAL_VIEW = 'moment-detail-list-for-sale-modal-view',
  MOMENT_DETAILS_SHARE_CLICK = 'moment-share',
  MOMENT_DETAILS_SIGN_IN_TO_BUY = 'moment-detail-sign-in-to-buy',
  MOMENT_JUST_DROPPED_VIEW_PACK = 'moment-justdropped-viewpack',
  MOMENT_LISTING_DETAIL_BADGE_TOOLTIP_VIEW = 'moment-listing-detail-badge-tooltip-view',
  MOMENT_LISTING_DETAIL_BADGE_TOOLTIP_VIEW_V2 = 'moment-listing-detail-badge-tooltip-view-v2',
  MOMENT_MARKETPLACE_CARD_BUY_NOW = 'moment-marketplace-card-buy-now',
  MOMENT_MARKETPLACE_CARD_VIEW_DETAILS = 'moment-marketplace-card-view-details',
  NAVIGATION_FOOTER_ITEM_CLICK = 'navigation-footer-item-click',
  NAVIGATION_HEADER_BLOG_CLICK = 'navigation-header-blog-click',
  NAVIGATION_HEADER_CHALLENGES_CLICK = 'navigation-header-challenges-click',
  NAVIGATION_HEADER_COLLECTION_CLICK = 'navigation-header-collection-click',
  NAVIGATION_HEADER_DAPPER_WALLET_CLICK = 'navigation-header-dapperwallet-click',
  NAVIGATION_HEADER_DISCORD_CLICK = 'navigation-header-discord-click',
  NAVIGATION_HEADER_HELP_CLICK = 'navigation-header-help-click',
  NAVIGATION_HEADER_ITEM_CLICK = 'navigation-header-item-click',
  NAVIGATION_HEADER_MARKETPLACE_CLICK = 'navigation-header-marketplace-click',
  NAVIGATION_HEADER_MYPROFILE_CLICK = 'navigation-header-myprofile-click',
  NAVIGATION_HEADER_PACKS_CLICK = 'navigation-header-packs-click',
  NAVIGATION_HEADER_QUICKSETTINGS_SET = 'navigation-header-quicksettings-set',
  NAVIGATION_HEADER_SETTINGS_CLICK = 'navigation-header-settings-click',
  NAVIGATION_HEADER_SIGN_IN_CLICK = 'navigation-header-sign-in-click',
  NAVIGATION_HEADER_SIGN_OUT_CLICK = 'navigation-header-signout-click',
  PACKDETAILS_CALENDAR = 'packdetail-calendar',
  PACKDETAILS_EDITIONS_LOAD_MORE = 'packdetail-editions-loadmore',
  PACKDETAILS_ELIGIBILITY_VIEW = 'packdetail-eligibility-view',
  PACKDETAILS_ENTER_QUEUE = 'packdetail-enterqueue',
  PACKDETAILS_EXPLORE_PACKS = 'packdetail-explorepacks',
  PACKSTOREFRONT_HEADER_VIEW_PACK = 'packstorefront-header-viewpack',
  PACKSTOREFRONT_PREVIOUS_DROP_VIEW_PACK = 'packstorefront-previousdrop-viewpack',
  PACKSTOREFRONT_SOCIAL_DISCORD = 'packstorefront-social-discord',
  PACKSTOREFRONT_SOCIAL_INSTAGRAM = 'packstorefront-social-instagram',
  PACKSTOREFRONT_SOCIAL_TWITTER = 'packstorefront-social-twitter',
  PACKSTOREFRONT_WEEKLY_DROP_VIEW_PACK = 'packstorefront-weeklydrop-viewpack',
  PACKS_OPENING_DONE_ACTION = 'pack-opening-done-action',
  PACKS_OPENING_MOMENT_REVEAL = 'pack-opening-moment-reveal',
  PACKS_OPENING_OPEN_PACK = 'pack-opening-open-pack',
  PACKS_OPENING_REVEAL_ALL = 'pack-opening-reveal-all',
  PACKS_OWNED_MODAL_VIEW = 'pack-owned-modalview',
  PACKS_OWNED_OPEN_CLAIMED = 'pack-owned-open-claimed',
  PACKS_OWNED_OPEN_LATER = 'pack-owned-open-later',
  PACKS_OWNED_PROFILE = 'pack-owned-profile',
  PACKS_OWNED_REPLAYED = 'pack-owned-replayed',
  PACKS_PURCHASE_TRANSACTION_CANCEL = 'pack-purchase-transaction-cancel',
  PACKS_PURCHASE_TRANSACTION_CONFIRM = 'pack-purchase-transaction-confirm',
  PACKS_PURCHASE_TRANSACTION_MODAL_VIEW = 'pack-purchase-transaction-modalview',
  PACK_OPEN_SUCCESS = 'pack-open-success',
  PACK_SUMMARY_MARKETPLACE_CLICK = 'pack-summary-marketplace-click',
  PACK_SUMMARY_START_CLICK = 'pack-summary-start-click',
  PACK_TRANSACTION_SUCCESS = 'pack-transaction-success',
  PLAYBOOK_NEXT_REWARD_CLICK = 'playbook-next-reward-click',
  PLAYBOOK_PATH_REWARD_CLICK = 'playbook-path-reward-click',
  PLAYBOOK_PATH_UNLOCK_PREMIUM_CLICK = 'playbook-path-unlock-premium-click',
  PLAYBOOK_PERSISTENT_DESKTOP_CTA_CLICK = 'playbook-persistent-desktop-cta-click',
  PLAYBOOK_PERSISTENT_DESKTOP_OPEN = 'playbook-persistent-desktop-open',
  PLAYBOOK_PERSISTENT_MOBILE_CTA_CLICK = 'playbook-persistent-mobile-cta-click',
  PLAYBOOK_SIGN_IN_CLICK = 'playbook-sign-in-click',
  PLAYBOOK_TAB_CHANGE = 'playbook-tab-change',
  PLAYBOOK_TASK_BURN_CLICK = 'playbook-task-burn-click',
  PLAYBOOK_TASK_CHALLENGE_CLICK = 'playbook-task-challenge-click',
  PLAYBOOK_UPRGRADE_TASK_BURN_CLICK = 'playbook-upgrade-task-burn-click',
  PLAYBOOK_UPRGRADE_TASK_MARKETPLACE_CLICK = 'playbook-upgrade-task-marketplace-click',
  PLAYER_TAB_FANS = 'player-tab-player-leaderboard',
  PLAYER_TAB_MOMENT = 'player-tab-moment',
  PLAYER_TAB_PLAYER_INFO = 'player-tab-player-stats',
  PROFILE_ACCOLADES_LOAD_MORE = 'profile-accolades-loadmore',
  PROFILE_MOMENT_CLICK = 'profile-moment-click',
  PROFILE_MOMENT_EMPTY_CTA = 'profile-moment-empty-cta',
  PROFILE_MOMENT_LOAD_MORE = 'profile-moment-loadmore',
  PROFILE_MOMENT_SHARE = 'profile-moment-share',
  PROFILE_PACKS_CLICK = 'profile-packs-click',
  PROFILE_PACKS_EMPTY_CTA = 'profile-packs-empty-cta',
  PROFILE_PACKS_LOAD_MORE = 'profile-packs-loadmore',
  PROFILE_SETS_EMPTY_CTA = 'profile-sets-empty-cta',
  PROFILE_SETS_MY_SETS_LINK = 'profile-sets-my-sets-link',
  PROFILE_TAB_ACCOLADES = 'profile-tab-accolades',
  PROFILE_TAB_MOMENT = 'profile-tab-moment',
  PROFILE_TAB_PACKS = 'profile-tab-packs',
  PROFILE_TAB_SEASONAL_NFTS = 'profile-tab-seasonal-nfts',
  PROFILE_TAB_SETS = 'profile-tab-sets',
  PROFILE_TAB_TEAM_NFTS = 'profile-tab-teamnfts',
  PROFILE_UPDATE_FAVORITE_TEAM = 'profile-update-favorite-team',
  SEASONAL_NFT_CARD_MINI_CLICK = 'seasonal-nft-card-mini-click',
  SEASONAL_NFT_GO_BACK_TO_PROFILE = 'seasonal-nft-go-back-to-profile',
  SEASONAL_NFT_SHARE_CLICK = 'seasonal-nft-share-click',
  SETS_LEADERBOARD_USER_POSITION_MARKETPLACE_REDIRECT = 'sets-leaderboard-user-position-marketplace-redirect',
  SOCIAL_SHARE_FAIL = 'social-share-fail',
  SOCIAL_SHARE_SUCCESS = 'social-share-success',
  SUBMIT_CHALLENGE_AUTH = 'submit-challenge-sign-in',
  TEAMS_NFT_DETAILS_SHARE_CLICK = 'teams-nft-share',
  TEAMS_NFT_DETAILS_VIEW_ALL_RECENT_SALES_CLICK = 'teams-nft-view-all-recent-sales',
  TEAM_PAGE_LOAD_MORE_MOMENTS = 'team-page-load-more-moments',
  TEAM_TAB_FANS = 'team-tab-fans',
  TEAM_TAB_LEADERBOARD = 'team-tab-leaderboard',
  TEAM_TAB_MOMENT = 'team-tab-moment',
  TEAM_TAB_ROSTER = 'team-tab-roster',
  TEAM_TAB_TEAM_NFTS = 'team-tab-teamnfts',
  UNAUTH_HOMEPAGE_HERO_PRIMARY_CTA = 'unauth-homepage-hero-primary-cta',
  USER_SIGN_IN = 'user-sign-in',
  USER_SIGN_UP = 'user-sign-up',
  WALLET_TRANSACTION_RESPONSE = 'wallet-transaction-response',
}
