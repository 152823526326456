import React from 'react';
import {
  Heading,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalBody,
  ModalCloseButton,
  VStack,
} from 'src/theme';

interface Props {
  closeModal: () => void;
  isModalOpen: boolean;
  modalBodyText: string;
  modalTitle: string;
}

export const MomentsBurnedModal: React.FC<Props> = ({
  isModalOpen,
  closeModal,
  modalBodyText,
  modalTitle,
}) => {
  return (
    <Modal
      closeOnOverlayClick
      isCentered
      isOpen={isModalOpen}
      onClose={closeModal}
    >
      <ModalOverlay />
      <ModalContent>
        <ModalCloseButton />
        <ModalBody py={16}>
          <VStack>
            <Heading>{modalTitle}</Heading>
            <Text textAlign='center'>{modalBodyText}</Text>
          </VStack>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
