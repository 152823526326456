import {
  arg,
  args,
  authorizations,
  authz,
  limit,
  payer,
  proposer,
  transaction,
} from '@onflow/fcl';
import { UInt64 } from '@onflow/types';
import { openAndReveal } from 'src/edge/flow';

interface Interaction {
  nftResourceID: number;
}

export function getInteraction({ nftResourceID }: Interaction) {
  const ix = async () => [
    transaction(openAndReveal),
    args([arg(nftResourceID, UInt64)]),
    proposer(authz),
    payer(authz),
    authorizations([authz]),
    limit(999),
  ];

  return ix;
}
