import useGetEntries from 'src/lib/contentful/useGetEntries';

export const useFooterContentful = ({ locale }) => {
  const { data: footerData } = useGetEntries(null, {
    include: 5,
    locale,
    'sys.id': 'FOOTER',
  });

  const footerContentfulContent = footerData?.items?.[0]?.fields ?? {};

  const helpLinksArray = footerContentfulContent?.navLinks ?? [];

  return { helpLinks: helpLinksArray };
};
