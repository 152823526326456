import { gql } from '@apollo/client';

// playIDs for testing: play-test-1, play-test-2, play-test-3, play-test-4, play-test-5

export const GET_PLAY = gql`
  query getPlay($playID: String!) {
    getPlay(input: { playID: $playID }) {
      play {
        descriptionEN
        descriptionES
        playerID
        playerPosition
        playerGoals
        playerAssists
        playerShotsOnTarget
        playerShotsOffTarget
        playerShotsBlocked
        playerFouledFinalThird
        playerSuccessfulFinalThirdPasses
        playerAccuratePasses
        playerDuelsWon
        playerDuelsLost
        playerTotalPasses
        playerTotalShots
        playerEffectiveClearance
        playerInterception
        playerAerialDuelWon
        playerAerialDuelLost
        playerSaves
        playerSavesInTheBox
        playerPunches
        playerTotalHighClaim
        homeTeamGoals
        homeTeamShotsOnTarget
        homeTeamTotalShots
        homeTeamPossessionPercentage
        homeTeamAccuratePasses
        homeTeamTotalPasses
        homeTeamCornerTaken
        homeTeamFreekickFoulLost
        homeTeamTotalYellowCard
        homeTeamTotalRedCard
        awayTeamGoals
        awayTeamShotsOnTarget
        awayTeamTotalShots
        awayTeamPossessionPercentage
        awayTeamAccuratePasses
        awayTeamTotalPasses
        awayTeamCornerTaken
        awayTeamFreekickFoulLost
        awayTeamTotalYellowCard
        awayTeamTotalRedCard
        timeline {
          eventType
          min
          half
          scoredBy
          assistedBy
          subbedOn
          subbedOff
          reason
          player
          card
          cardType
          teamName
        }
        player {
          id
          position
          seasonStats {
            gamesPlayed
            goals
            assists
            gamesStarted
            shotsOnTarget
            totalShots
            successfulDribbles
            failedDribbles
            totalTimePlayed
            keyPasses
            accuratePasses
            totalPasses
            duelsWon
            groundDuelsWon
            totalGroundDuels
            aerialDuelsWon
            totalAerialDuels
            totalDuels
            cleanSheets
            interceptions
            goalsConceded
            saves
            penaltiesSaved
            penaltiesConceded
            season
          }
        }
      }
    }
  }
`;
