import { useContext, createContext, FC, ReactNode, useState } from 'react';

import { SEGMENT_EVENTS_ID_ENUM, useAnalytics } from 'src/lib/segment';
interface Context {
  storeTeamId: (id: string) => void;
  teamId: string;
}
export const TeamPickerContext = createContext<Context>({} as Context);

export const TeamPickerProvider: FC<{ children?: ReactNode }> = ({
  children,
}) => {
  const [teamId, setTeamId] = useState(null);
  const { track } = useAnalytics();

  const storeTeamId = (id: string) => {
    track(SEGMENT_EVENTS_ID_ENUM.FTUEV2_TEAM_PICKER_TEAM_SELECT, {
      teamId: id,
    });
    setTeamId(id);
  };

  const TeamPickerValue = {
    storeTeamId,
    teamId,
  };

  return (
    <TeamPickerContext.Provider value={TeamPickerValue}>
      {children}
    </TeamPickerContext.Provider>
  );
};

export const useTeamPicker = () => useContext(TeamPickerContext);
