import React, { useEffect, useState } from 'react';
import { useTranslation } from 'src/lib/translations';
import { Box, Flex, useMultiStyleConfig } from 'src/theme';
import { TIMELINE_THEME_KEY } from 'src/theme/dss-skeleton/components';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';
import {
  ArrowUpDownIcon,
  YellowCardIcon,
  RedCardIcon,
  BallOutlineIcon,
  PlayMomentIcon,
} from 'src/theme/dss-skeleton/themes/icons';

import { TEXT_MARKERS, TIMELINE_EVENT_TYPES } from './enums';
import {
  calcPositionRegardingOtherEvents,
  getMaxPosition,
  mergeSameEvents,
} from './helpers';
import { TimelineItem } from './TimelineItem';
import { TimelineTeams } from './TimelineTeams';
import { TimelineConfigI, TimelineItemI, TimelineProps } from './types';

const ICON_ITEM = {
  DISCIPLINE: { RED: RedCardIcon, YELLOW: YellowCardIcon },
  GOAL: BallOutlineIcon,
  MOMENT: PlayMomentIcon,
  SUBSTITUTION: ArrowUpDownIcon,
};

const TimelinePeriod = ({
  timelinePeriod,
  teamName,
  getIcon,
  momentSrc,
  isBottom = false,
}) => {
  return (
    <Flex
      position='relative'
      height={{ base: '52px', md: '62px' }}
      maxInlineSize='initial'
    >
      {timelinePeriod
        .filter((item) =>
          isBottom ? item.teamName === teamName : item.teamName !== teamName,
        )
        .map((timelineItem: TimelineItemI, index: number) => {
          const isGroup = Array.isArray(timelineItem);
          const item = isGroup ? timelineItem[0] : timelineItem;
          return (
            <TimelineItem
              key={item.min + index}
              groupItem={timelineItem}
              icon={getIcon(item.eventType, item.card)}
              isBottom={isBottom}
              isGroup={isGroup}
              item={item}
              momentSrc={momentSrc}
            />
          );
        })}
    </Flex>
  );
};

export const Timeline = ({ timeline, teams, momentSrc }: TimelineProps) => {
  const { t } = useTranslation();
  const [firstTime, setFirstTime] = useState<Array<TimelineConfigI>>([]);
  const [secondTime, setSecondTime] = useState<Array<TimelineConfigI>>([]);
  const [isExpanded, setIsExpanded] = useState(false);
  const [minWidthTimeline, setMinWidthTimeline] = useState(470);

  const styles = useMultiStyleConfig(TIMELINE_THEME_KEY);
  const { events, trackHandler } = useDssAnalytics();

  useEffect(() => {
    let timelineTemp = [...timeline];
    timelineTemp.sort((a, b) => a.min - b.min);
    const moment = timelineTemp.find(
      (item) => item.eventType === TIMELINE_EVENT_TYPES.MOMENT,
    );
    //exclude the same event as a moment
    timelineTemp = timelineTemp.filter(
      (item) =>
        !(
          (item.min + 2 === Number(moment.min) ||
            item.min - 2 === Number(moment.min) ||
            item.min === Number(moment.min)) &&
          item.eventType === TIMELINE_EVENT_TYPES.GOAL &&
          item.scoredBy === moment.scoredBy
        ),
    );
    const firstHalf = timelineTemp
      .filter((item) => item.half === 1)
      .map((item) => ({
        ...item,
        position: (item.min * 417.5) / 45,
      }));
    const secondHalf = timelineTemp
      .filter((item) => item.half === 2)
      .map((item) => ({
        ...item,
        position: ((item.min - 45) * 417.5) / 45,
      }));
    firstHalf.forEach(calcPositionRegardingOtherEvents);
    secondHalf.forEach(calcPositionRegardingOtherEvents);
    const widthFirstHalf = getMaxPosition(firstHalf);
    const widthSecondHalf = getMaxPosition(secondHalf);
    const minWidth = Math.max(widthFirstHalf, widthSecondHalf);
    setMinWidthTimeline(minWidth);
    setFirstTime(mergeSameEvents(firstHalf));
    setSecondTime(mergeSameEvents(secondHalf));
  }, []);

  const getIcon = (eventType: string, card: string) => {
    if (eventType === TIMELINE_EVENT_TYPES.DISCIPLINE) {
      return ICON_ITEM.DISCIPLINE[card];
    }
    return ICON_ITEM[eventType];
  };

  const toggleView = () => {
    trackHandler(events.GAME_HIGHLIGHTS_ITEM_CLICK, {
      item_label: isExpanded ? 'minimize' : 'expand',
    });
    setIsExpanded((state) => !state);
  };

  const _renderTimelinePeriod = (timelinePeriod: Array<TimelineItemI>) => {
    const timelinePeriodProps = {
      getIcon,
      momentSrc,
      teamName: teams.awayTeam.title,
      timelinePeriod,
    };
    return (
      <Flex
        sx={styles.timelinePeriodWrapper}
        height={{ base: timelinePeriod.length ? '500px' : '32px', md: 'auto' }}
        minHeight={{
          base: timelinePeriod.length
            ? minWidthTimeline === 450
              ? minWidthTimeline
              : minWidthTimeline + 40
            : '32px',
          md: 0,
        }}
      >
        <TimelinePeriod {...timelinePeriodProps} />
        <Flex
          sx={styles.timelinePeriod}
          minWidth={
            timelinePeriod.length
              ? minWidthTimeline === 450
                ? minWidthTimeline
                : minWidthTimeline + 40
              : '32px'
          }
        />
        <TimelinePeriod {...timelinePeriodProps} isBottom />
      </Flex>
    );
  };

  return (
    <Flex sx={styles.container}>
      <Flex sx={styles.mainWrapper}>
        <TimelineTeams teams={teams} />
        <Flex overflow={{ base: isExpanded ? 'none' : 'hidden', md: 'auto' }}>
          <Flex
            sx={styles.timelineWrapper}
            justifyContent={{
              base: isExpanded ? 'center' : 'normal',
              md: 'center',
            }}
            width={{ base: '100%', md: 'auto' }}
            height={{ base: isExpanded ? '100%' : '230px', md: '268px' }}
          >
            <Box padding={{ base: '25px 0 13px 0', md: '0 13px 0 25px' }}>
              {t(TEXT_MARKERS.KO)}
            </Box>
            {_renderTimelinePeriod(firstTime)}
            <Box p='20px'>{t(TEXT_MARKERS.HT)}</Box>
            {_renderTimelinePeriod(secondTime)}
            <Box padding={{ base: '13px 0 25px 0', md: '0 25px 0 13px' }}>
              {t(TEXT_MARKERS.FT)}
            </Box>
          </Flex>
        </Flex>
        <Box
          sx={styles.expandBlock}
          position={isExpanded ? 'relative' : 'absolute'}
          height={isExpanded ? '50px' : '90px'}
          lineHeight={isExpanded ? '20px' : '120px'}
          onClick={toggleView}
        >
          {isExpanded ? t('Minimize') : t('Timeline.Expand')}
        </Box>
      </Flex>
    </Flex>
  );
};
