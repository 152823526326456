import {
  DocumentNode,
  QueryFunctionOptions,
  TypedDocumentNode,
} from '@apollo/client';
import map from 'lodash/map';
import { useQuery } from 'src/lib/apollo';

import { SET_SEARCH_NFT_VARIABLES } from '../queries/searchNft';

type SearchNftParams = {
  aggregationResponseType: string;
  options?: QueryFunctionOptions & { origin?: string };
  query: DocumentNode | TypedDocumentNode<any, any>;
  // eslint-disable-next-line @typescript-eslint/ban-types
  variables?: any;
};

export function useSearchNft<T>({
  query,
  aggregationResponseType,
  variables = SET_SEARCH_NFT_VARIABLES,
  options = {},
}: SearchNftParams) {
  const { data, state } = useQuery(query, {
    context: {
      clientName: 'platformAPI',
    },
    variables,
    ...options,
  });
  const moments: T[] = map(
    data?.[aggregationResponseType]?.edges,
    (edge) => edge?.node,
  );
  const totalCount: number | null =
    data?.[aggregationResponseType]?.totalCount ?? null;

  return {
    moments: moments?.length > 0 ? moments : [],
    state,
    totalCount,
  };
}
