import { Box, Flex, IconButton } from '@chakra-ui/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { chakra, useMultiStyleConfig, VolumeOff, VolumeOn } from 'src/theme';
import {
  LanguagePicker,
  VIDEO_THEME_KEY,
} from 'src/theme/dss-skeleton/components';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';
import { LanguageContext } from 'src/theme/dss-skeleton/context/languageContext';
import {
  FullscreenIcon,
  LanguageIcon,
} from 'src/theme/dss-skeleton/themes/icons';

import { useCustomFullscreenVideo } from './customFullscreen';
import { useDefaultFullscreenVideo } from './defaultFullscreen';
import { isApplePortableDevice, isInViewport, VIDEO_EVENTS } from './helpers';

export type useVideoProps = {
  [key: string]: unknown;
  autoplay?: boolean;
  fullScreenVideoProps?: React.ComponentProps<any>;
  fullScreenVideoSrc?: string;
  id?: string;
  previewSrc?: string;
  showControls?: boolean;
  showLanguagePicker?: boolean;
  videoProps?: React.ComponentProps<any>;
  videoSrc: string;
};
export const useVideo = ({
  videoSrc,
  id,
  fullScreenVideoSrc,
  previewSrc,
  // @ts-ignore
  videoProps = {},
  // @ts-ignore
  fullScreenVideoProps = {},
  autoplay = true,
  showControls = false,
  showLanguagePicker = true,
  ...props
}: useVideoProps) => {
  const styles = useMultiStyleConfig(VIDEO_THEME_KEY);
  const { events, trackHandler } = useDssAnalytics();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(autoplay);
  const { selectedLanguage, languages, onChangeLanguage } =
    useContext(LanguageContext);

  const [useCustomFullscreen, setUseCustomFullscreen] = useState(false);

  useLayoutEffect(() => {
    setUseCustomFullscreen(!isApplePortableDevice());
  }, []);

  const { view: customFullscreen, openFullscreen: openCustomFullscreen } =
    useCustomFullscreenVideo({
      fullScreenVideoProps,
      fullScreenVideoSrc: fullScreenVideoSrc || videoSrc,
      id,
      previewSrc,
    });

  const { view: defaultFullscreen, openFullscreen: openDefaultFullscreen } =
    useDefaultFullscreenVideo({
      fullScreenVideoProps,
      fullScreenVideoSrc: fullScreenVideoSrc || videoSrc,
      id,
      previewSrc,
    });

  const pause = useCallback(() => {
    videoRef.current?.pause();
  }, [videoRef]);

  const play = useCallback(() => {
    setIsPlaying(true);
    videoRef.current?.play();
  }, [videoRef]);

  const stop = useCallback(() => {
    setIsPlaying(false);
    if (videoRef.current) {
      videoRef.current.pause();
      videoRef.current.currentTime = 0;
    }
  }, [videoRef]);

  const resetAutoplay = () => {
    if (!videoRef.current) {
      return;
    }

    const videoInViewport = isInViewport(videoRef.current);

    if (isPlaying && videoInViewport && !document.hidden) {
      play();
      return;
    }

    pause();
  };

  const handleFullscreenEnd = () => {
    trackHandler(events.VIDEO_CLOSE_BUTTON_CLICK, {
      item_label: 'close',
    });
    resetAutoplay();
  };

  useEffect(() => {
    window.addEventListener('DOMContentLoaded', resetAutoplay);
    window.addEventListener('load', resetAutoplay);
    window.addEventListener('scroll', resetAutoplay);
    window.addEventListener('resize', resetAutoplay);
    document.addEventListener('visibilitychange', resetAutoplay);
    window.addEventListener(VIDEO_EVENTS.FULLSCREEN_END, handleFullscreenEnd);
    window.addEventListener(VIDEO_EVENTS.FULLSCREEN_START, pause);

    return () => {
      window.removeEventListener('DOMContentLoaded', resetAutoplay);
      window.removeEventListener('load', resetAutoplay);
      window.removeEventListener('scroll', resetAutoplay);
      window.removeEventListener('resize', resetAutoplay);
      document.addEventListener('visibilitychange', resetAutoplay);
      window.addEventListener(VIDEO_EVENTS.FULLSCREEN_END, handleFullscreenEnd);
      window.addEventListener(VIDEO_EVENTS.FULLSCREEN_START, pause);
    };
  }, []);

  useEffect(() => {
    resetAutoplay();
  }, [videoSrc]);

  const [isMuted, setIsMuted] = useState(true);
  const handleMuteToggle = () => {
    setIsMuted((prevState) => !prevState);
  };

  const handleFullscreen = () => {
    trackHandler(events.VIDEO_OPEN_FULLSCREEN, {
      item_label: 'fullscreen',
    });
    if (useCustomFullscreen) {
      openCustomFullscreen();
      return;
    }
    openDefaultFullscreen();
  };

  return {
    enterFullScreen: useCustomFullscreen
      ? openCustomFullscreen
      : openDefaultFullscreen,
    pause,
    play,
    stop,
    view: (
      <Box pos='relative' pt='3rem' {...props}>
        {useCustomFullscreen ? customFullscreen : defaultFullscreen}
        {showControls && (
          <Flex sx={styles.controlWrapper}>
            <IconButton
              className={isMuted && 'muted'}
              sx={styles.propertiesButton}
              aria-label='mute control'
              icon={isMuted ? <VolumeOff /> : <VolumeOn />}
              onClick={handleMuteToggle}
              role='button'
              tabIndex={1}
            />
            {languages.length > 0 && showLanguagePicker && (
              <LanguagePicker
                alignSelf='center'
                width='10rem'
                defaultValue={selectedLanguage}
                icon={<LanguageIcon />}
                items={languages}
                onChange={onChangeLanguage}
              />
            )}
            <IconButton
              sx={styles.propertiesButton}
              aria-label='Fullscreen'
              icon={<FullscreenIcon />}
              onClick={handleFullscreen}
              onTouchEnd={handleFullscreen}
              variant='ghost'
            />
          </Flex>
        )}
        <chakra.video
          ref={videoRef}
          sx={styles.video}
          id={`autoplay-${id}`}
          loop
          muted={isMuted}
          playsInline
          poster={previewSrc}
          src={videoSrc}
          {...videoProps}
        />
      </Box>
    ),
  };
};
