import React from 'react';
import { Flex } from 'src/theme';

import { useTopNavContext } from './TopNav.context';
import { TopNavButton } from './TopNavButton';
import { TopNavProfile } from './TopNavProfile';

interface TopNavAuthBlockProps {
  isMobile?: boolean;
}

export const TopNavAuth = ({ isMobile = false }: TopNavAuthBlockProps) => {
  //const styles = useStyles();
  const {
    isAuthed,
    onSignUp,
    textSignUp,
    textLogin,
    profile,
    isSingleSignUpButton,
  } = useTopNavContext();

  // TECH DEBT: sx needs to be moved into theme. Currently making it here since components aren't updating with TopNav.theme.ts updates for unknown reason
  const sx = {
    fontFamily: 'mono',
    fontSize: 12,
    fontWeight: 400,
    letterSpacing: 0.6,
    marginY: isMobile ? 2 : 0,
  };

  if (isAuthed && profile) {
    return <TopNavProfile isMobile={isMobile} />;
  }

  return (
    <Flex
      alignItems={!isMobile && 'center'}
      direction={isMobile ? 'column' : 'row'}
    >
      {!isSingleSignUpButton && (
        <TopNavButton
          sx={sx}
          display={{ base: 'none', sm: 'inline-block' }}
          onClick={onSignUp}
          variant={isMobile ? 'outline' : 'ghost'}
        >
          {textLogin}
        </TopNavButton>
      )}
      <TopNavButton
        sx={sx}
        onClick={onSignUp}
        size={isMobile ? 'md' : 'sm'}
        variant='primary'
      >
        {textSignUp}
      </TopNavButton>
    </Flex>
  );
};
