import { useTranslation } from 'src/lib/translations';
import { chakra, useMultiStyleConfig, Text } from 'src/theme';
import { GAME_HIGHLIGHTS_THEME_KEYS } from 'src/theme/dss-skeleton/components';

import { StatsLine } from './StatsLine';

type StatsItemProps = {
  awayTeamTotalValue?: number;
  awayTeamValue: number;
  homeTeamTotalValue?: number;
  homeTeamValue: number;
  label: string;
};
export const StatsItem = ({
  label,
  homeTeamValue,
  awayTeamValue,
  homeTeamTotalValue,
  awayTeamTotalValue,
}: StatsItemProps): JSX.Element => {
  const { t } = useTranslation();
  const styles = useMultiStyleConfig(GAME_HIGHLIGHTS_THEME_KEYS.STATISTICS);
  const primaryValue = Math.max(homeTeamValue, awayTeamValue);
  const mod = homeTeamValue + awayTeamValue;

  return (
    <chakra.div sx={styles.statsItemWrapper}>
      <chakra.div sx={styles.statsItemHeader}>
        <Text
          sx={styles.statsItemText}
          data-winner={homeTeamValue === primaryValue}
        >
          {homeTeamValue}
        </Text>
        <Text sx={styles.statsItemLabel}>{t(label)}</Text>
        <Text
          sx={styles.statsItemText}
          data-winner={awayTeamValue === primaryValue}
        >
          {awayTeamValue}
        </Text>
      </chakra.div>
      <chakra.div sx={styles.statsItemLineWrapper}>
        <StatsLine
          align='right'
          mod={homeTeamTotalValue || mod}
          value={homeTeamValue}
          variant={homeTeamValue === primaryValue ? 'primary' : 'side'}
        />
        <StatsLine
          mod={awayTeamTotalValue || mod}
          value={awayTeamValue}
          variant={awayTeamValue === primaryValue ? 'primary' : 'side'}
        />
      </chakra.div>
    </chakra.div>
  );
};
