import { ComponentStyleConfig } from '@chakra-ui/react';

const theme: ComponentStyleConfig = {
  baseStyle: {
    container: {
      backgroundColor: 'black',
      borderRadius: '12px',
      flexDirection: 'column',
      height: { base: 'none', md: '420px' },
      overflow: 'hidden',
      width: '100%',
    },
    expandBlock: {
      background:
        'linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 40.51%)',
      bottom: '0',
      color: '#8A94A6',
      cursor: 'pointer',
      display: { base: 'block', md: 'none' },
      fontSize: '14px',
      textAlign: 'center',
      textDecoration: 'underline',
      width: '100%',
      zIndex: 9,
    },
    headerWrapper: {
      bg: '#17171B',
      flexDirection: { base: 'column', md: 'row' },
      height: 'auto',
      p: '0.75rem 1rem',
      width: '100%',
    },
    mainWrapper: {
      flexDirection: { base: 'column', md: 'row' },
      height: '100%',
      position: 'relative',
    },
    momentPreview: {
      background: '#000000',
      border: '1px solid #2B2C33',
      borderRadius: '12px',
      height: '150px',
      width: '150px',
    },
    pulse: {
      '&': {
        animation: 'pulse-black 2s infinite',
        background: 'black',
        borderRadius: ' 50%',
        boxShadow: '0 0 0 0 rgba(0, 0, 0, 1)',
        height: '24px',
        transform: 'scale(1)',
        width: '24px',
      },

      '&.transparent': {
        animation: 'pulse-white 2s infinite',
        background: 'transparent',
        boxShadow: '0 0 0 0 rgba(248, 248, 248, 1)',
      },

      '@keyframes pulse-white': {
        '0%': {
          boxShadow: '0 0 0 0 rgba(248, 248, 248, 0.3)',
          transform: 'scale(0.95)',
        },

        '100%': {
          boxShadow: '0 0 0 0 rgba(248, 248, 248, 0)',
          transform: 'scale(0.95)',
        },

        '70%': {
          boxShadow: '0 0 0 10px rgba(248, 248, 248, 0)',
          transform: 'scale(1)',
        },
      },
    },
    teamLogo: {
      margin: 'auto',
    },
    timelineGoalMin: {
      color: '#F8F8F8',
      fontFamily: 'mono',
      fontSize: '12px',
      fontWeight: '400',
      letterSpacing: '0.32px',
      margin: '0 auto',
      textAlign: 'center',
      whiteSpace: 'nowrap',
      width: '15px',
    },
    timelineItemContentWrapper: {
      alignItems: { base: 'center', md: 'none' },
      bg: 'black',
      display: 'flex',
      flexDirection: { base: 'row-reverse', md: 'column' },
      h: 'auto',
      padding: { base: '4px', md: 0 },
      position: 'absolute',
      transform: { base: 'rotate(-90deg)', md: 'none' },
      width: { base: '60px', md: 'auto' },
    },
    timelinePeriod: {
      alignItems: 'center',
      backgroundColor: '#1F1F1D',
      borderRadius: '4px',
      flexDirection: { base: 'column', md: 'row' },
      minHeight: '5px',
      width: { base: 'auto', md: '100%' },
    },
    timelinePeriodWrapper: {
      flexDirection: 'column',
      justifyContent: { base: 'center', md: 'none' },
      margin: 0,
      transform: { base: 'rotate(90deg)', md: 'none' },
      width: { base: 'fit-content', md: 'auto' },
    },
    timelineTeamsContainer: {
      alignItems: 'center',
      borderBottom: { base: '1px solid #2B2C33', md: 0 },
      borderRight: { base: 0, md: '1px solid #2B2C33' },
      flexDirection: { base: 'row', md: 'column' },
      height: { base: '64px', md: '100%' },
      justifyContent: 'center',
      minWidth: { base: 0, md: '110px' },
      width: { base: '100%', md: 0 },
    },
    timelineWrapper: {
      alignItems: 'center',
      color: '#2B2C33',
      flexDirection: { base: 'column', md: 'row' },
      fontFamily: 'heading',
      fontSize: '28px',
      fontWeight: '500',
      letterSpacing: '-0.64px',
      lineHeight: '28px',
      margin: 'auto',
    },
  },
  defaultProps: {},
  parts: ['container'],
};

export const TIMELINE_THEME_KEY = 'Timeline';
export const TimelineTheme = {
  [TIMELINE_THEME_KEY]: theme,
};
