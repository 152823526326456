import { ComponentStyleConfig } from '@chakra-ui/react';

const theme: ComponentStyleConfig = {
  baseStyle: {
    button: {
      fontFamily: 'mono',
      fontSize: '18px',
      fontWeight: '400',
      letterSpacing: '-0.16px',
    },
    closeButton: {
      backdropFilter: { base: 'blur(10px)', md: 'none' },
      background: '#17171B5C',
      borderRadius: '32px',
      height: '48px',
      position: 'absolute',
      right: '16px',
      size: 'lg',
      top: '16px',
      width: '48px',
    },
    img: {
      borderBottomLeftRadius: { base: 0, md: '20px' },
      borderTopLeftRadius: '20px',
      borderTopRightRadius: { base: '16px', md: '0px' },
      height: { base: '228px', md: 'auto' },
      objectFit: 'cover',
      width: '100%',
    },
  },
};

export const WAITLIST_MODAL_THEME_KEY = 'WaitlistModal';
export const WaitlistModalTheme = {
  [WAITLIST_MODAL_THEME_KEY]: theme,
};
