import { NflAllDayLogo } from '@dapperlabs/core-fe';
import { ReactElement } from 'react';
import { Flex } from 'src/theme';

const Takeover = ({ children }: { children: ReactElement }) => {
  return (
    <Flex direction='column' height='100%' p={4}>
      <NflAllDayLogo />
      <Flex direction='column' height='100%' mt={4}>
        {children}
      </Flex>
    </Flex>
  );
};

export default Takeover;
