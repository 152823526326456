import React, { useMemo } from 'react';
import { useTranslation } from 'src/lib/translations';
import { Box } from 'src/theme';
import {
  LIST_FOR_SALE_MODAL_STAGES,
  TRANSACTIONS_TYPE_MODAL,
  TransactionsModalButton,
} from 'src/theme/dss-skeleton/components';

export const TransactionsModalActions = ({
  stage,
  state,
  additionalButtonHandler,
  submitModal,
  typeModal,
}) => {
  const { t } = useTranslation();
  const { error, price } = state;

  const isListingSuccess = useMemo(
    () =>
      typeModal === TRANSACTIONS_TYPE_MODAL.LISTING &&
      stage === LIST_FOR_SALE_MODAL_STAGES.SUCCESS,
    [stage, typeModal],
  );

  const isListingInit = useMemo(
    () =>
      typeModal === TRANSACTIONS_TYPE_MODAL.LISTING &&
      stage === LIST_FOR_SALE_MODAL_STAGES.INIT,
    [stage, typeModal],
  );

  const isListingError = useMemo(
    () =>
      typeModal === TRANSACTIONS_TYPE_MODAL.LISTING &&
      stage === LIST_FOR_SALE_MODAL_STAGES.ERROR,
    [stage, typeModal],
  );

  const isDelistSuccess = useMemo(
    () =>
      typeModal === TRANSACTIONS_TYPE_MODAL.DELIST &&
      stage === LIST_FOR_SALE_MODAL_STAGES.SUCCESS,
    [stage, typeModal],
  );

  const isDelistError = useMemo(
    () =>
      typeModal === TRANSACTIONS_TYPE_MODAL.DELIST &&
      stage === LIST_FOR_SALE_MODAL_STAGES.ERROR,
    [stage, typeModal],
  );

  const isPurchaseSuccess = useMemo(
    () =>
      typeModal === TRANSACTIONS_TYPE_MODAL.PURCHASE &&
      stage === LIST_FOR_SALE_MODAL_STAGES.SUCCESS,
    [stage, typeModal],
  );

  const isPurchaseError = useMemo(
    () =>
      typeModal === TRANSACTIONS_TYPE_MODAL.PURCHASE &&
      stage === LIST_FOR_SALE_MODAL_STAGES.ERROR,
    [stage, typeModal],
  );

  const labelMainButton = useMemo(() => {
    if (isListingSuccess || isDelistSuccess) {
      return t('TransactionsModal.button.backToMyCollection');
    }
    if (isListingInit) {
      return t('ListForSale.button.listForSale');
    }
    return t('ListForSale.button.backToMarketplace');
  }, [isDelistSuccess, isListingSuccess, stage, typeModal, isListingInit]);

  const variantMainButton = useMemo(() => {
    if (isDelistSuccess || isListingSuccess || isListingInit) {
      return 'solid';
    }
    return 'outline';
  }, [isDelistSuccess, isListingInit, isListingSuccess]);

  const bgMainButton = useMemo(() => {
    if (isListingSuccess || isListingInit || isDelistSuccess) {
      return 'white';
    }
    return 'transparent';
  }, [isDelistSuccess, isListingInit, isListingSuccess]);

  const isShowAdditionalButton = useMemo(() => {
    return (
      isListingError || isPurchaseError || isPurchaseSuccess || isDelistError
    );
  }, [isDelistError, isListingError, isPurchaseError, isPurchaseSuccess]);

  return (
    (isShowAdditionalButton ||
      stage !== LIST_FOR_SALE_MODAL_STAGES.PROCESSING) && (
      <Box mt={isListingInit ? 0 : '62px'}>
        {isShowAdditionalButton && (
          <TransactionsModalButton
            buttonProps={{
              bgColor: LIST_FOR_SALE_MODAL_STAGES.INIT
                ? 'white'
                : 'transparent',
              color: LIST_FOR_SALE_MODAL_STAGES.INIT ? 'black' : 'white',
              variant: stage !== LIST_FOR_SALE_MODAL_STAGES.INIT ? 'solid' : '',
            }}
            error={error}
            label={
              stage === LIST_FOR_SALE_MODAL_STAGES.ERROR
                ? t('ListForSale.Modal.Button.tryAgain')
                : t('ListForSale.Modal.Button.viewMoment')
            }
            onClick={additionalButtonHandler}
            price={price}
            stage={stage}
          />
        )}
        {stage !== LIST_FOR_SALE_MODAL_STAGES.PROCESSING && (
          <TransactionsModalButton
            bg={bgMainButton}
            buttonProps={{
              mt:
                stage === LIST_FOR_SALE_MODAL_STAGES.SUCCESS ||
                stage === LIST_FOR_SALE_MODAL_STAGES.ERROR
                  ? '12px'
                  : 'auto',
              variant: variantMainButton,
            }}
            error={error}
            label={labelMainButton}
            onClick={submitModal}
            price={price}
            stage={stage}
          />
        )}
      </Box>
    )
  );
};
