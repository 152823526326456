import get from 'lodash/get';
import { captureException } from 'src/lib/sentry';

import { Context } from './types';

/**
 * The intention of this domain is that it will be able to act as an abstraction
 * for instances of infinite scroll throughout the app. As a first pass it will
 * be set up for searching NFTs. Queries/Variables/etc can be
 * passed in via context, or, alternatively you could write your own fetch
 * service as long as it has the same return signature.
 */

export const services = {
  fetch: async (context: Context, event) => {
    const {
      client,
      query,
      queryDataPath,
      queryPaginationPath,
      variables: originalVariables,
      options = {},
      limit,
      pageInfo,
    } = context;

    const isFetchingMore = event.type === 'FETCH_MORE';
    const variables = {
      searchInput: {
        ...originalVariables.searchInput,
        after: isFetchingMore ? pageInfo?.endCursor : null,
      },
    };
    try {
      const { errors, data } = await client.query({
        query,
        variables,
        ...options,
      });
      const queryData = get(data, queryDataPath);
      const edges = queryData?.edges;
      const totalCount = queryData?.totalCount;
      const newPageInfo = get(data, queryPaginationPath);

      if (errors) {
        captureException(errors);
      }

      return {
        edges,
        limit,
        pageInfo: newPageInfo,
        totalCount,
        type: event.type,
      };
    } catch (e) {
      captureException(e);
    }
  },
};
