import { gql } from '@apollo/client';
import { sansPrefix } from '@onflow/fcl';

import { SortDirection } from './generated';
import { SearchPackNftQueryVariables } from './searchNft.generated';

const DEFAULT_FIRST_AMOUNT = 1000;

export const GOLAZOS_NFT_EDITION_INFO_FRAGMENT = gql`
  fragment golazosNftEditionInfo on GolazosEdition {
    id
    max_mintings
    play {
      id
      classification
      metadata {
        match_away_score
        match_away_team
        match_day
        match_highlighted_team
        match_home_score
        match_home_team
        match_season
        play_data_id
        play_half
        play_time
        play_type
        player_country
        player_data_id
        player_first_name
        player_jersey_name
        player_known_name
        player_last_name
        player_number
        player_position
      }
    }
    series {
      id
      name
    }
    set {
      name
    }
    tier
    total_burned
    total_minted
    type_name
  }
`;
export const constructEditionIdListQueries = (editionIdList) => {
  if (!editionIdList || editionIdList.length === 0) {
    return `searchGolazosNft(searchInput: {first:1}) {
      edges {
        node {
          edition {
            ...golazosNftEditionInfo
          }
        }
      }
    }`;
  }
  return editionIdList.reduce((acc, editionId) => {
    return `${acc}
      s${editionId}: searchGolazosNft(searchInput: {filters:{edition:{id:{eq:${editionId}}}}, first:1}) {
        edges {
          node {
            edition {
              ...golazosNftEditionInfo
            }
          }
        }
      }
    `;
  }, '');
};
export const SEARCH_GOLAZOS_EDITIONS_BY_EDITION_IDS = (editionIdList) => {
  const queries = constructEditionIdListQueries(editionIdList || []);
  return gql`query searchGolazosEditionsByEditionIds {
        ${queries}
      }
      ${GOLAZOS_NFT_EDITION_INFO_FRAGMENT}
    `;
};
export const SEARCH_GOLAZOS_NFT = () =>
  gql`
    query searchGolazosNft($searchInput: SearchGolazosNftsInput) {
      searchGolazosNft(searchInput: $searchInput) {
        totalCount
        pageInfo {
          endCursor
          hasNextPage
        }
        edges {
          node {
            id
            owner_address
            serial_number
            user {
              username
              flowAddress
              source
              avatar
            }
            listing {
              expiry
              storefront_address
              listing_resource_id
              nft_type
              nft_id
              ft_vault_type
              price
              type_name
            }
            edition {
              ...golazosNftEditionInfo
            }
          }
        }
      }
    }
    ${GOLAZOS_NFT_EDITION_INFO_FRAGMENT}
  `;

export const searchGolazosNftByID = (momentID: string) => {
  return {
    searchInput: {
      filters: {
        id: {
          eq: momentID,
        },
      },
      first: 1,
    },
  };
};

export const searchGolazosNftEditionListById = (editionId: string, sortBy) => {
  const nextPublicDapperUtilityCoinAddress =
    process.env.NEXT_PUBLIC_DAPPER_UTILITY_COIN_ADDRESS.split('0x')[1];
  const vault_type = `A.${nextPublicDapperUtilityCoinAddress}.DapperUtilityCoin.Vault`;
  const currentUnixTimestamp = Math.floor(Date.now() / 1000);
  return {
    searchInput: {
      filters: {
        edition: {
          id: {
            eq: editionId,
          },
        },

        listing: {
          custom_id: { eq: 'DAPPER_MARKETPLACE', ignore_case: true },
          exists: true,
          expiry: {
            gt: currentUnixTimestamp,
          },
          ft_vault_type: {
            eq: vault_type,
          },
        },
        type_name: {
          eq: `A.${sansPrefix(
            process.env.NEXT_PUBLIC_NFT_ADDRESS,
          )}.Golazos.NFT`,
        },
      },
      first: 500,
      sortBy,
    },
  };
};

export const searchGolazosNftByOwnerAddress = (
  ownerAddress: string,
  sortBy?: any,
  first?: number,
) => {
  return {
    searchInput: {
      filters: {
        edition: { play: { metadata: { match_day: { exists: true } } } },
        owner_address: { eq: ownerAddress },
        type_name: {
          eq: `A.${sansPrefix(
            process.env.NEXT_PUBLIC_NFT_ADDRESS,
          )}.Golazos.NFT`,
        },
      },
      first: first ?? DEFAULT_FIRST_AMOUNT,
      ...(sortBy ? { sortBy } : {}),
    },
  };
};

export const SEARCH_PACK_NFT_BY_OWNER_ADDRESS = () =>
  gql`
    query searchPackNft($searchInput: SearchPackNftsInput) {
      searchPackNft(searchInput: $searchInput) {
        edges {
          node {
            id
            dist_id
            status
            owner_updated_at {
              block_time
            }
          }
        }
        totalCount
      }
    }
  `;

export const SEARCH_PACK_NFT_TOTAL_COUNT = gql`
  query SearchPackNft_Count($searchInput: SearchPackNftsInput) {
    searchPackNft(searchInput: $searchInput) {
      totalCount
    }
  }
`;
export const SEARCH_GOLAZOS_NFT_TOTAL_COUNT = gql`
  query searchGolazosNft($searchInput: SearchGolazosNftsInput) {
    searchGolazosNft(searchInput: $searchInput) {
      totalCount
    }
  }
`;

export const searchPackNftByDistId = (
  distId: number,
): SearchPackNftQueryVariables => ({
  searchInput: {
    filters: [
      {
        dist_id: { eq: distId },
        listing: { exists: true },
        type_name: {
          eq: `A.${sansPrefix(
            process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS,
          )}.PackNFT.NFT`,
        },
      },
    ],
  },
});

export const searchPackNftByOwnerAddress = (
  ownerAddress: string,
  first?: number,
): SearchPackNftQueryVariables => ({
  searchInput: {
    filters: [
      {
        owner_address: { eq: ownerAddress },
        type_name: {
          eq: `A.${sansPrefix(
            process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS,
          )}.PackNFT.NFT`,
        },
      },
    ],
    first: first || DEFAULT_FIRST_AMOUNT,
    sortBy: {
      owner_updated_at: {
        block_time: { direction: SortDirection.Desc, priority: 1 },
      },
    },
  },
});

export const searchPackNftByOwnerAddressAndDistId = (
  ownerAddress: string,
  distId: number,
): SearchPackNftQueryVariables => ({
  searchInput: {
    filters: [
      {
        dist_id: { eq: distId },
        owner_address: { eq: ownerAddress },
        type_name: {
          eq: `A.${sansPrefix(
            process.env.NEXT_PUBLIC_PACK_NFT_ADDRESS,
          )}.PackNFT.NFT`,
        },
      },
    ],
    first: DEFAULT_FIRST_AMOUNT,
    sortBy: {
      owner_updated_at: {
        block_time: { direction: SortDirection.Desc, priority: 1 },
      },
    },
  },
});
