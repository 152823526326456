import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@chakra-ui/react';
import React, { useCallback, useRef } from 'react';
import { chakra, useDisclosure, useMultiStyleConfig } from 'src/theme';
import { VIDEO_THEME_KEY } from 'src/theme/dss-skeleton/components';
import { VIDEO_EVENTS } from 'src/theme/dss-skeleton/components/Video/helpers';
import { VolumeSlider } from 'src/theme/dss-skeleton/components/VolumeSlider';

export type useVideoProps = {
  fullScreenVideoProps?: React.ComponentProps<any>;
  fullScreenVideoSrc?: string;
  id?: string;
  previewSrc?: string;
};
export const useCustomFullscreenVideo = ({
  id,
  fullScreenVideoSrc,
  previewSrc,
  // @ts-ignore
  fullScreenVideoProps = {},
}: useVideoProps) => {
  const styles = useMultiStyleConfig(VIDEO_THEME_KEY);
  const fullScreenVideoRef = useRef(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  const openFullscreen = useCallback(() => {
    window.dispatchEvent(new CustomEvent(VIDEO_EVENTS.FULLSCREEN_START));

    onOpen();
  }, [onOpen]);

  const closeFullscreen = useCallback(() => {
    window.dispatchEvent(new CustomEvent(VIDEO_EVENTS.FULLSCREEN_END));

    onClose();
  }, [onClose]);

  return {
    openFullscreen,
    view: (
      <Modal
        isOpen={isOpen}
        onClose={closeFullscreen}
        scrollBehavior='inside'
        size='full'
      >
        <ModalOverlay />
        <ModalContent bg='black'>
          <ModalCloseButton sx={styles.propertiesButton} />
          <ModalBody sx={styles.fullScreenBody} borderRadius='0'>
            <chakra.video
              ref={fullScreenVideoRef}
              sx={styles.fullScreenVideo}
              autoPlay
              id={`fullscreen-${id}`}
              loop
              playsInline
              poster={previewSrc}
              src={fullScreenVideoSrc}
              {...fullScreenVideoProps}
            />
            <VolumeSlider sx={styles.volumeSlider} video={fullScreenVideoRef} />
          </ModalBody>
        </ModalContent>
      </Modal>
    ),
  };
};
