import React from 'react';
import { useTranslation } from 'src/lib/translations';
import {
  Heading,
  chakra,
  Tabs,
  TabList,
  Tab,
  TabPanels,
  TabPanel,
  useMultiStyleConfig,
} from 'src/theme';

import { SalesDataItem, SalesTable } from './SalesTable';

type SalesHistoryProps = {
  editionId: string | number;
  headingStyle?: React.ComponentProps<any>;
  latestSales: {
    count: number;
    data: SalesDataItem[];
  };
  showAll?: boolean;
  topSales: {
    count: number;
    data: SalesDataItem[];
  };
};
export const SalesHistory = ({
  latestSales,
  topSales,
  headingStyle,
  editionId,
  showAll = false,
}: SalesHistoryProps) => {
  const { t } = useTranslation();

  const { sectionHeader } = useMultiStyleConfig('SalesHistory');
  const { tab, tabList } = useMultiStyleConfig('Tab');

  if (!latestSales.data.length && !topSales.data.length) {
    return null;
  }

  return (
    <chakra.div>
      <Heading sx={sectionHeader} {...headingStyle}>
        {t('moment.salesHistory.title')}
      </Heading>
      <Tabs>
        <TabList sx={tabList}>
          {latestSales.data.length ? (
            <Tab sx={tab} data-analytic-id='latest_sales'>
              {t('moment.salesHistory.latestSales')}
            </Tab>
          ) : null}
          {topSales.data.length ? (
            <Tab sx={tab} data-analytic-id='top_sales'>
              {t('moment.salesHistory.topSales')}
            </Tab>
          ) : null}
        </TabList>

        <TabPanels>
          <TabPanel pt={6} pb={0} px={0}>
            <SalesTable
              count={latestSales.count}
              data={latestSales.data}
              editionId={editionId}
              showAll={showAll}
              tableId='latest-sales'
            />
          </TabPanel>
          <TabPanel pt={6} pb={0} px={0}>
            <SalesTable
              count={topSales.count}
              data={topSales.data}
              editionId={editionId}
              showAll={showAll}
              tableId='top-sales'
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </chakra.div>
  );
};
