export const FILTER_DRAWER_THEME_KEY = 'FilterDrawer';

/* eslint-disable sort-keys-fix/sort-keys-fix */
const theme = {
  baseStyle: {
    drawer: {
      bg: 'surface0',
      bottom: 0,
      gridArea: 'drawer',
      height: '100vh',
      position: 'sticky',
      top: '72px',
      zIndex: 2,
    },
    drawerContent: {
      flexDirection: 'row-reverse',
      height: '100%',
      maxH: 'calc(100vh - 72px)',
      overflowY: 'scroll',
      p: 8,
      position: 'sticky',
      top: '72px',
    },
    shell: {
      display: 'grid',
      gridTemplateRows: { base: 'auto', lg: 'auto 1fr ' },
      gridTemplateColumns: { base: '1fr', lg: 'auto 2fr' },
      gridTemplateAreas: {
        base: `
          "topbar"
          "drawer"
          "content"
        `,
        lg: `
          "topbar topbar"
          "drawer content"
      `,
      },
    },
    topBar: {
      alignItems: 'center',
      bg: 'surface0',
      display: 'flex',
      gridArea: 'topbar',
      gridGap: 9,
      justifyContent: 'space-between',
      position: 'sticky',
      px: 8,
      top: '0',
      zIndex: 3,
    },
  },
};

export const FilterDrawerTheme = {
  [FILTER_DRAWER_THEME_KEY]: theme,
};
