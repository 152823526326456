import React, {
  Children,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { Box, BoxProps, useMultiStyleConfig } from 'src/theme';
import { CAROUSEL_THEME_KEY } from 'src/theme/dss-skeleton/components';
import { useDssAnalytics } from 'src/theme/dss-skeleton/context/dssAnalyticsContext';

import { CarouselPagination } from './Pagination';
import { CarouselSlideProps } from './Slide';

// percentage of carousel width user needs to drag in any direction before letting go to activate a navigation behaviour. values: 0 - 1
const dragSensitivity = 0.2;

export type CarouselProps = {
  carouselId: string;
  children?: ReactElement<CarouselSlideProps>[];
  onSlideChange?: (slideIndex: number) => void;
  showNav?: boolean;
  slideContainerProps?: any;
} & BoxProps;

export const CarouselDeprecated: React.FC<CarouselProps> = ({
  children,
  onSlideChange,
  showNav,
  carouselId,
  slideContainerProps = {},
  ...props
}: CarouselProps) => {
  const { events, trackHandler } = useDssAnalytics();
  const carouselRef = useRef<HTMLDivElement>(null);
  const slidesWrapperRef = useRef<HTMLDivElement>(null);
  const [offsetX, setOffsetX] = useState(0);
  const [currentSlide, setCurrentSlide] = useState(0);
  const [isAnimating, setIsAnimating] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const draggingRef = useRef(false);
  const [startOffset, setStartOffset] = useState(0);
  const [startPosX, setStartPosX] = useState(0);

  const numberOfSlides = useMemo(() => {
    return Children.toArray(children).length;
  }, [children]);

  const getClientWidth = useCallback(() => {
    const clientWidth = slidesWrapperRef.current.clientWidth;
    const computedStyle = getComputedStyle(slidesWrapperRef.current);
    return (
      clientWidth -
      parseFloat(computedStyle.paddingLeft) -
      parseFloat(computedStyle.paddingRight)
    );
  }, [slidesWrapperRef.current]);

  /**
   * @param speed
   * Moves the offsetX a number of pixels towards the correct resting offsetX for the current slide based on the container width and @speed
   */
  const animateToSlide = useCallback(
    (speed = 1) => {
      if (slidesWrapperRef.current) {
        setIsAnimating(true);
        const clientWidth = getClientWidth();
        // calculating the distance between current offsetX and the correct resting offsetX for the current slide based on the container width
        const distance = -currentSlide * clientWidth - offsetX;
        // getting the +/- sign of distance to determine the sign and therefore direction of the value to be added to offsetX
        const direction = distance / Math.abs(distance) || 0;
        // eases out by logarithmically reducing speed as the distance drops to 0
        const speedMultiplier = Math.abs(distance / clientWidth) * 10 + 1;
        speed = speed * speedMultiplier;
        // calculating the number of pixels to be moved in the next animation using speed and direction
        let pixelsPerFrame =
          Math.abs(distance) > speed ? direction * speed : direction;
        // to avoid flagpoling around zero distance
        if (Math.abs(pixelsPerFrame) > Math.abs(distance)) {
          pixelsPerFrame = distance;
        }
        setOffsetX(offsetX + pixelsPerFrame);
        setIsAnimating(false);
      }
    },
    [offsetX, setOffsetX, currentSlide, getClientWidth],
  );

  /**
   * @param slide
   * immediately sets the offsetX to the correct resting offsetX for the given @slide based on the container width
   */
  const jumpToSlide = useCallback(
    (slide) => {
      if (slidesWrapperRef.current) {
        setOffsetX(-slide * getClientWidth());
      }
    },
    [setOffsetX, getClientWidth],
  );

  const slideShowHandlers = useMemo(() => {
    return Children.toArray(children).map((child: ReactElement) => {
      return child.props.onSlideShow;
    });
  }, [children]);

  const slideLeaveHandlers = useMemo(() => {
    return Children.toArray(children).map((child: ReactElement) => {
      return child.props.onSlideLeave;
    });
  }, [children]);

  const previewSrcList = useMemo(() => {
    return Children.toArray(children).map((child: ReactElement) => {
      return child.props.previewSrc;
    });
  }, [children]);

  const handleNext = useCallback(() => {
    const targetSlide = currentSlide + 1;
    if (targetSlide < numberOfSlides) {
      slideLeaveHandlers[currentSlide]?.();
      setCurrentSlide(targetSlide);
      slideShowHandlers[targetSlide]?.();
      onSlideChange?.(targetSlide);
      return;
    }
    slideLeaveHandlers[currentSlide]?.();
    setCurrentSlide(0);
    slideShowHandlers[0]?.();
    onSlideChange?.(0);
  }, [
    currentSlide,
    numberOfSlides,
    onSlideChange,
    slideShowHandlers,
    slideLeaveHandlers,
  ]);

  const handlePrev = useCallback(() => {
    const targetSlide = currentSlide - 1;
    if (targetSlide >= 0) {
      slideLeaveHandlers[currentSlide]?.();
      setCurrentSlide(targetSlide);
      slideShowHandlers[targetSlide]?.();
      onSlideChange?.(targetSlide);
      return;
    }
    slideLeaveHandlers[currentSlide]?.();
    setCurrentSlide(numberOfSlides - 1);
    slideShowHandlers[numberOfSlides - 1]?.();
    onSlideChange?.(numberOfSlides - 1);
  }, [
    currentSlide,
    onSlideChange,
    slideShowHandlers,
    slideLeaveHandlers,
    numberOfSlides,
  ]);

  const handleGoToSlide = useCallback(
    (targetSlide) => {
      if (targetSlide >= 0 && targetSlide < numberOfSlides) {
        slideLeaveHandlers[currentSlide]?.();
        setCurrentSlide(targetSlide);
        slideShowHandlers[targetSlide]?.();
        onSlideChange?.(targetSlide);
      }
    },
    [numberOfSlides, onSlideChange, slideShowHandlers, slideLeaveHandlers],
  );

  const clampOffsetToNearestSlide = useCallback(
    (delta = 0) => {
      if (slidesWrapperRef.current) {
        const clientWidth = getClientWidth();
        const percentDelta = delta / clientWidth;
        if (Math.abs(percentDelta) > dragSensitivity) {
          const isDirectionNext = percentDelta < 0;
          if (isDirectionNext) {
            handleNext();
            trackHandler(events.CAROUSEL_ITEM_CLICK, {
              item_label: 'swipe_right',
            });
            return;
          }
          handlePrev();
          trackHandler(events.CAROUSEL_ITEM_CLICK, {
            item_label: 'swipe_left',
          });
        }
      }
    },
    [handleNext, handlePrev, getClientWidth],
  );

  useEffect(() => {
    if (numberOfSlides) {
      jumpToSlide(0);
    }
  }, [children, jumpToSlide, numberOfSlides]);

  useEffect(() => {
    let timeout: any;
    if (
      !isAnimating &&
      slidesWrapperRef.current &&
      !draggingRef.current &&
      -currentSlide * getClientWidth() - offsetX !== 0
    ) {
      timeout = setTimeout(() => {
        animateToSlide(10);
      }, 1);
    }
    return () => {
      clearTimeout(timeout);
    };
  }, [
    offsetX,
    animateToSlide,
    currentSlide,
    isAnimating,
    isDragging,
    getClientWidth,
  ]);

  useEffect(() => {
    const carouselEl = carouselRef.current;
    const handleMouseDown = (e: MouseEvent) => {
      setIsDragging(true);
      setStartOffset(offsetX);
      setStartPosX(e.offsetX);
      draggingRef.current = true;
    };
    const handleMouseUp = () => {
      if (draggingRef.current) {
        draggingRef.current = false;
        const delta = offsetX - startOffset;
        clampOffsetToNearestSlide(delta);
        setIsDragging(false);
      }
    };
    const handleMouseMove = (e: any) => {
      e.preventDefault();
      if (draggingRef.current) {
        let delta = e.offsetX - startPosX;
        const containerWidth = getClientWidth();
        // this prevents glitches from the mouse leaving the frame of the current target
        const clientWidth = getClientWidth();
        const percentDelta = delta / (clientWidth || 1);
        if (Math.abs(percentDelta) > 0.2) {
          delta = 0;
        }
        setOffsetX(
          Math.max(
            Math.min(offsetX + delta, 50),
            -(numberOfSlides - 1) * containerWidth - 50,
          ),
        );
      }
    };
    const handleTouchStart = (e: any) => {
      const touches = e.changedTouches;
      if (!draggingRef.current && touches.length === 1) {
        setIsDragging(true);
        setStartOffset(offsetX);
        setStartPosX(touches[0].clientX);
        draggingRef.current = true;
      }
    };
    const handleTouchEnd = (e: any) => {
      const touches = e.changedTouches;
      if (draggingRef.current && touches.length === 1) {
        draggingRef.current = false;
        const delta = touches[0].clientX - startPosX;
        clampOffsetToNearestSlide(delta);
        setIsDragging(false);
      }
    };
    const handleTouchMove = (e: any) => {
      const touches = e.changedTouches;
      if (draggingRef.current && touches.length === 1) {
        const delta = touches[0].clientX - startPosX;
        const containerWidth = getClientWidth();
        setOffsetX(
          Math.max(
            Math.min(startOffset + delta, 50),
            -(numberOfSlides - 1) * containerWidth - 50,
          ),
        );
      }
    };
    if (carouselEl) {
      carouselEl.addEventListener('mouseup', handleMouseUp);
      carouselEl.addEventListener('mousedown', handleMouseDown);
      carouselEl.addEventListener('mousemove', handleMouseMove);
      carouselEl.addEventListener('mouseleave', handleMouseUp);
      carouselEl.addEventListener('touchstart', handleTouchStart);
      carouselEl.addEventListener('touchmove', handleTouchMove);
      carouselEl.addEventListener('touchend', handleTouchEnd);
      window.onresize = () => {
        jumpToSlide(currentSlide);
      };
    }
    return () => {
      if (carouselEl) {
        carouselEl.removeEventListener('mousedown', handleMouseDown);
        carouselEl.removeEventListener('mouseup', handleMouseUp);
        carouselEl.removeEventListener('mousemove', handleMouseMove);
        carouselEl.removeEventListener('mouseleave', handleMouseUp);
        carouselEl.removeEventListener('touchstart', handleTouchStart);
        carouselEl.removeEventListener('touchmove', handleTouchMove);
        carouselEl.removeEventListener('touchend', handleTouchEnd);
        window.onresize = null;
      }
    };
  }, [
    startPosX,
    offsetX,
    clampOffsetToNearestSlide,
    setStartOffset,
    currentSlide,
    startOffset,
    jumpToSlide,
    numberOfSlides,
    getClientWidth,
  ]);

  const styles = useMultiStyleConfig(CAROUSEL_THEME_KEY);

  return (
    <Box
      ref={carouselRef}
      sx={styles.container}
      aria-live='polite'
      id={carouselId}
      {...props}
    >
      <Box
        ref={slidesWrapperRef}
        sx={styles.slidesWrapper}
        transform={`translateX(${offsetX}px)`}
        {...slideContainerProps}
      >
        {children?.map((slide) => slide)}
      </Box>
      <CarouselPagination
        carouselId={carouselId}
        currentSlide={currentSlide}
        numberOfSlides={numberOfSlides}
        onButtonClick={handleGoToSlide}
        onNext={() => {
          handleNext();
        }}
        onPrev={() => {
          handlePrev();
        }}
        previewSrcList={previewSrcList}
        showNav={showNav}
      />
    </Box>
  );
};
