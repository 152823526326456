export const SET_SEARCH_NFT_VARIABLES = ({
  sortBy = {},
  first = 1000,
  filters = {},
}) => {
  return {
    searchInput: {
      filters,
      first,
      sortBy,
    },
  };
};
